import React, { useContext, useEffect, useState } from "react";
import {
  fetchParameter,
  fetchParametersByPath,
  ParameterData,
} from "./awsService"; // Ensure this path is correct
import { APICore } from "../../helpers/api/apiCore"; // Ensure this path is correct
import {
  Box,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Grid,
  Backdrop,
  Snackbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs"; // Install dayjs if not already installed: npm install dayjs
import AuthContext from "../../contexts/AuthContext";
import { useIdToken } from "../../hooks/useIdToken";
import { logger } from '../../utils/logger';

interface DeviceData extends ParameterData {
  name: string;
  device_expiry?: string;
  key_expiry?: string;
  device_activation_code?: string;
  prodaOrgId?: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // MUI blue
    },
  },
});

const Admin: React.FC = () => {
  const [data, setData] = useState<DeviceData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [newDeviceName, setNewDeviceName] = useState<string>("");
  const [newDeviceCode, setNewDeviceCode] = useState<string>("");
  const [environment, setEnvironment] = useState<string>("");
  const [jksPassword, setJksPassword] = useState<string>("");
  const [jksKeyAlias, setJksKeyAlias] = useState<string>("");
  const [certLocation, setCertLocation] = useState<string>("");
  const [deviceActivationPassword, setDeviceActivationPassword] =
    useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const { deviceName, prodaOrgId } = useContext(AuthContext);
  const { idToken, isLoading, error: idTokenError } = useIdToken();

  const fetchData = async () => {
    setLoading(true);
    logger.log("Fetching data..." + prodaOrgId + "/" + deviceName);
    try {
      const parameterName = `/proda/air/token/${prodaOrgId}/${deviceName}`;
      const result = await fetchParameter(parameterName);
      const device = {
        name: parameterName.split("/").pop()!,
        ...result,
      };

      setData([device]);
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idToken) {
      fetchData();
    }
  }, [idToken]);

  const calculateDaysToExpiry = (expiryDate?: string): number => {
    if (!expiryDate) return -1;
    const now = dayjs();
    const expiry = dayjs(expiryDate);
    return expiry.diff(now, "day");
  };

  const extendExpiryDate = async (index: number) => {
    if (!idToken) {
      console.error("No authentication token available");
      return;
    }
    const apiRefreshDevice = process.env.REACT_APP_REFRESH_DEVICE;
    if (!apiRefreshDevice) {
      throw new Error("REACT_APP_REFRESH_DEVICE is not defined");
    }
    const device = data[index];
    const apiUrl = apiRefreshDevice;
    const requestBody = {
      deviceName: device.name,
      prodaOrgId: prodaOrgId,
    };

    setApiLoading(true);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      logger.log("Response", response);
      if (response.ok) {
        const responseData = await response.json();
        logger.log("Expiry date extended", responseData);
        fetchData(); // Reload the table data
        setSnackbarOpen(true);
      } else {
        console.error("Failed to extend expiry date", response.statusText);
      }
    } catch (error) {
      console.error("Error extending expiry date", error);
    } finally {
      setApiLoading(false);
    }
  };

  const handleAddDevice = () => {
    setShowForm(true);
  };

  const handleFormSubmit = async (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (!idToken) {
      console.error("No authentication token available");
      return;
    }
    const apiActivateDevice = process.env.REACT_APP_ACTIVATE_DEVICE;
    if (!apiActivateDevice) {
      throw new Error("REACT_APP_ACTIVATE_DEVICE is not defined");
    }
    const apiUrl = apiActivateDevice;
    const requestBody = {
      deviceName: newDeviceName,
      deviceActivationPassword: deviceActivationPassword,
      prodaOrgId: prodaOrgId,
    };

    setApiLoading(true);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      logger.log("Response", response);
      if (response.ok) {
        logger.log("Device added successfully");
        fetchData(); // Reload the table data
        setSnackbarOpen(true);
        setShowForm(false);
        setNewDeviceName("");
        setNewDeviceCode("");
        setEnvironment("");
        setJksPassword("");
        setJksKeyAlias("");
        setCertLocation("");
        setDeviceActivationPassword("");
      } else {
        console.error("Failed to add device", response.statusText);
      }
    } catch (error) {
      console.error("Error adding device", error);
    } finally {
      setApiLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (idTokenError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h6" color="error">
          Error loading authentication token: {idTokenError}
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={showForm ? 8 : 12}>
            <Paper
              elevation={3}
              style={{ padding: "2rem", position: "relative" }}
            >
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                PRODA Devices
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        <strong>Device Name</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        <strong>Channel</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        <strong>Device Expiry</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        <strong>Days to Expiry</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        <strong>Key Expiry</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        <strong>Key Days to Expiry</strong>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      >
                        <strong>Actions</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((device, index) => {
                      const deviceDaysToExpiry = calculateDaysToExpiry(
                        device.device_expiry
                      );
                      const keyDaysToExpiry = calculateDaysToExpiry(
                        device.key_expiry
                      );
                      return (
                        <TableRow key={index}>
                          <TableCell>{device.name}</TableCell>
                          <TableCell>AIR</TableCell>
                          <TableCell>{device.device_expiry || "N/A"}</TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              color: deviceDaysToExpiry <= 30 ? "red" : "black",
                            }}
                          >
                            {typeof deviceDaysToExpiry === "number"
                              ? `${deviceDaysToExpiry} days left`
                              : deviceDaysToExpiry}
                          </TableCell>
                          <TableCell>{device.key_expiry || "N/A"}</TableCell>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              color: keyDaysToExpiry <= 30 ? "red" : "black",
                            }}
                          >
                            {typeof keyDaysToExpiry === "number"
                              ? `${keyDaysToExpiry} days left`
                              : keyDaysToExpiry}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => extendExpiryDate(index)}
                            >
                              Extend Key Expiry
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={apiLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Paper>
            <Box marginTop={2} display="flex" justifyContent="flex-start">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddDevice}
              >
                Add New Device
              </Button>
            </Box>
          </Grid>
          {showForm && (
            <Grid item xs={4}>
              <Paper
                elevation={3}
                style={{ padding: "2rem", position: "relative" }}
              >
                <Typography variant="h6" gutterBottom>
                  Add New Device
                </Typography>
                <form onSubmit={handleFormSubmit}>
                  <TextField
                    label="Device Name"
                    variant="outlined"
                    fullWidth
                    value={newDeviceName}
                    onChange={(e) => setNewDeviceName(e.target.value)}
                    margin="normal"
                  />
                  {/* <TextField
                    label="Environment"
                    variant="outlined"
                    fullWidth
                    value={environment}
                    onChange={(e) => setEnvironment(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="JKS Password"
                    variant="outlined"
                    fullWidth
                    value={jksPassword}
                    onChange={(e) => setJksPassword(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="JKS Key Alias"
                    variant="outlined"
                    fullWidth
                    value={jksKeyAlias}
                    onChange={(e) => setJksKeyAlias(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    label="Cert Location"
                    variant="outlined"
                    fullWidth
                    value={certLocation}
                    onChange={(e) => setCertLocation(e.target.value)}
                    margin="normal"
                  /> */}
                  <TextField
                    label="Device Activation Password"
                    variant="outlined"
                    fullWidth
                    value={deviceActivationPassword}
                    onChange={(e) =>
                      setDeviceActivationPassword(e.target.value)
                    }
                    margin="normal"
                  />
                  {/* <TextField
                    label="PRODA RA (Organisation)"
                    name="prodaOrgId"
                    value={formData.prodaOrgId}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  /> */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<AddIcon />}
                    style={{ marginTop: "1rem" }}
                  >
                    Activate Device
                  </Button>
                </form>
              </Paper>
            </Grid>
          )}
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message="Expiry date extended successfully"
        />
      </Container>
    </ThemeProvider>
  );
};

export default Admin;
