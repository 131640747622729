import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import {
  PharmacyAvailability,
  PharmacyAvailabilityRoom,
} from '../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import { ControllerRenderProps } from 'react-hook-form';
import { IRoom } from 'interfaces/healthcare-reservation/IRoom';
import { logger } from 'utils/logger';

type TSelectRoomReservationProps = {
  onSelectedRoom?: (room: IRoom) => void;
  errorMessage?: string;
  availability: PharmacyAvailability | null;
  defaultRoom?: NonNullable<PharmacyAvailability['rooms']>[number];
  onChange?: ControllerRenderProps['onChange'];
  rooms?: IRoom[];
} & Omit<FormControlProps, 'onChange'>;

const SelectRoomReservationConnected = ({
  onSelectedRoom,
  errorMessage,
  availability,
  defaultRoom,
  rooms,
  ...rest
}: TSelectRoomReservationProps) => {
  const [selectedRoom, setSelectedRoom] = useState<IRoom | null>(null);
  // const { selectedService } = useAppointment();
  const [availabilityRooms, setAvailabilityRooms] = useState<IRoom[]>([]);
  const { onChange, ...restProps } = rest;

  const handleChange = (event: SelectChangeEvent<string>, _child: ReactNode) => {
    const selected = availabilityRooms.find((room) => room?.roomID === event.target.value);

    setSelectedRoom(selected || null);

    if (typeof onChange === 'function') {
      onChange(event);
    }

    if (typeof onSelectedRoom === 'function' && selected) {
      onSelectedRoom(selected);
    }
  };

  useEffect(() => {
    if (availability) {
      const availabilityRooms = availability.rooms as PharmacyAvailabilityRoom[];
      const pharmacyRooms = rooms?.filter((room) => availabilityRooms.map((item) => item.roomID).includes(room.roomID));

      if (pharmacyRooms?.length) {
        setAvailabilityRooms(pharmacyRooms);

        const defaultSelectedByAvailable = pharmacyRooms.find((room) => room?.isAvailable);
        const defaultSelectedByDefaultRoom = pharmacyRooms.find((room) => room?.roomID === defaultRoom?.roomID);

        if (typeof onSelectedRoom === 'function') {
          onSelectedRoom(defaultSelectedByDefaultRoom || defaultSelectedByAvailable || pharmacyRooms[0] || null);
        }
      }
    }
  }, [availability]);

  useEffect(() => {
    if (defaultRoom && rooms) {
      const selected = rooms.find((room) => room.roomID === defaultRoom.roomID);

      if (selected?.isActive) {
        setSelectedRoom(selected || null);
      }
    }
  }, [defaultRoom, rooms]);

  return (
    <Box width="100%">
      <FormControl fullWidth {...restProps} disabled={availability === null}>
        <InputLabel id="select-rooms">Select Rooms</InputLabel>
        <Select
          disabled={availability === null}
          labelId="select-rooms"
          value={selectedRoom?.roomID || ''}
          label="Select Rooms"
          onChange={handleChange}
        >
          {availabilityRooms.map((room) => (
            <MenuItem
              disabled={(room?.isAvailable !== undefined && room.isAvailable === false) || !room.isActive}
              value={room?.roomID as string}
              key={room?.roomID as string}
            >
              {room?.roomName}
            </MenuItem>
          ))}
        </Select>
        {restProps.error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default SelectRoomReservationConnected;
