import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { RoutesProps } from '../routes';
import { APICore } from '../helpers/api/apiCore';
// import { useModal } from "../contexts/ModalContext";
import ExpiredSessionModalContent from './ExpiredSessionModalContent';
import AuthContext from '../contexts/AuthContext';
import { useBookingSubscription, useUpdateBookingSubscription } from '../hooks/useBookingSubscriptions';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogContent, useTheme } from '@mui/material';
import moment from 'moment';
import { logger } from 'utils/logger';
import { useDispatch } from 'react-redux';
import { setAddNotification } from 'redux/notification/actions';
import { INotification } from 'interfaces/INotification';
import { uuid } from 'utils';
import { BOOKING_STATUS } from 'interfaces/IBooking';

type Props = {
  children: ReactNode;
} & Omit<RoutesProps, 'children'> &
  APICore;

// Function to register the service worker
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js');
      logger.log('Service Worker registered with scope:', registration.scope);
    } catch (error) {
      logger.error('Service Worker registration failed:', error);
    }
  }
};

// Function to send data to the service worker
const sendToServiceWorker = (title: string, message: string, url: string) => {
  // Ensure service worker is ready before sending message
  navigator.serviceWorker.ready
    .then((registration) => {
      if (registration.active) {
        registration.active.postMessage({
          title,
          message,
          url,
        });
        logger.log('Message sent to Service Worker:', { title, message, url });
      } else {
        logger.error('Service Worker is not active');
      }
    })
    .catch((error) => {
      logger.error('Service Worker error:', error);
    });
};

const PageWrapper = ({ children, ...rest }: Props) => {
  const { isUserAuthenticated, path, getLoggedInUser, startPeriodicRefresh } = rest;
  const authenticationStats = isUserAuthenticated();
  // const { openModal } = useModal();
  const [openModal, setOpenModal] = useState(false);
  const { pharmacyID } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const addNotification = (data: INotification) => {
    dispatch(
      setAddNotification({
        ...data,
      })
    );
  };

  useBookingSubscription({
    pharmacyID,
    async onData(data) {
      if (data) {
        sendToServiceWorker(
          'New Appointment',
          `You have a new appointment scheduled at ${moment(data.timeslot).format('DD MMMM YYYY, hh:mmA')}`,
          `${window.location.origin}/apps/appointments?_id=${data.bookingID}&_openDetail=true`
        );

        const message = 'There is a new appointment!';

        addNotification({
          id: uuid(),
          isRead: false,
          message,
          createdDate: moment().toISOString(),
          status: data.status as BOOKING_STATUS,
          bookingID: data.bookingID,
        });
      }
    },
  });

  useUpdateBookingSubscription({
    pharmacyID,
    async onData(data) {
      if (data) {

        const isCancel =
        data.status === BOOKING_STATUS.CANCELED ||
        data.status === BOOKING_STATUS.CANCELED_BY_PATIENT ||
        data.status === BOOKING_STATUS.CANCELED_BY_PHARMACY;

        const statusText = isCancel ? 'Canceled' : 'Updated';

        sendToServiceWorker(
          `${statusText} Appointment`,
          `Your appointment has been changed. Click to view details.`,
          `${window.location.origin}/apps/appointments?_id=${data.bookingID}&_openDetail=true`
        );


        const message = `There is a ${statusText} appointment!`;

        addNotification({
          id: uuid(),
          isRead: false,
          message,
          createdDate: moment().toISOString(),
          status: data.status as BOOKING_STATUS,
          bookingID: data.bookingID,
        });
      }
    },
  });

  // Register the service worker on component mount
  useEffect(() => {
    if (!('Notification' in window)) {
      logger.log('Browser does not support desktop notification');
    } else {
      Notification.requestPermission();
      registerServiceWorker();
    }
  }, []);

  useEffect(() => {
    // current session is expired
    if (authenticationStats === false && getLoggedInUser() !== null) {
      setOpenModal(true);
    }

    // no current session? redirect to the login
    if (authenticationStats === false && getLoggedInUser() === null) {
      navigate('/auth/login', {
        replace: true,
      });
    }
  }, [path]);
  useEffect(() => {
    // Invoke periodic refresh
    startPeriodicRefresh();
  }, [startPeriodicRefresh]);
  const worker = useMemo(() => new Worker(new URL('../services/events/inactive-page-worker.ts', import.meta.url)), []);

  useEffect(() => {
    // Function to reset the inactivity timer in the worker
    const resetInactivityTimer = () => {
      worker.postMessage('reset');
    };
    // Handle messages from the worker
    worker.onmessage = (event) => {
      if (event.data === 'check-session') {
        // Start hecking session status each 1 minute, this timming is configured on the worker...

        const expireTime = rest.getExpiryTokenTime();
        // should show the modal at least for inactive app:, expireTime.format("YYYY-MM-DD hh:mm:ss a")

        if (moment().isAfter(expireTime)) {
          if (!openModal) {
            setOpenModal(true);
          }
          resetInactivityTimer();
          worker.terminate(); // Stop the worker when token is expired
        }
      }
    };

    // Add event listeners for user interactions
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keypress', resetInactivityTimer);
    window.addEventListener('click', resetInactivityTimer);
    window.addEventListener('touchstart', resetInactivityTimer);

    // Initialize the inactivity timer
    resetInactivityTimer();

    // Cleanup when the component unmounts
    return () => {
      worker.terminate(); // Stop the worker
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keypress', resetInactivityTimer);
      window.removeEventListener('click', resetInactivityTimer);
      window.removeEventListener('touchstart', resetInactivityTimer);
    };
  }, []);

  return (
    <main>
      {children}
      <Dialog
        open={openModal}
        sx={{
          zIndex: theme.zIndex.modal + 100,
        }}
      >
        <DialogContent>
          <ExpiredSessionModalContent />
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default PageWrapper;
