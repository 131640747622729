export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/LOGIN_USER",
  LOGIN_STEPS = "@@auth/LOGIN_STEPS",
  LOGOUT_USER = "@@auth/LOGOUT_USER",
  SIGNUP_USER = "@@auth/SIGNUP_USER",
  FORGOT_PASSWORD = "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_CHANGE = "@@auth/FORGOT_PASSWORD_CHANGE",
  CONFIRM_RESET_PASSWORD = "@@auth/CONFIRM_RESET_PASSWORD",
  SET_OTP = "@@auth/SET_OTP",

  SIGNUP_USER_REQUEST = "@@auth/SIGNUP_USER_REQUEST", // Added for handling the loading state
  VERIFY_USER = "@@auth/VERIFY_USER",
  LOGIN_USER_WITH_EMAIL = "@@auth/LOGIN_USER_WITH_EMAIL",
  UPDATE_USER_ATTRIBUTES = "@@auth/UPDATE_USER_ATTRIBUTES",

  RESET = "@@auth/RESET",

  SET_REGISTRATION_STEP = '@@auth/SET_REGISTRATION_STEP',
  COMPLETE_STEP = '@@auth/COMPLETE_STEP',
  SET_AUTHENTICATION_STATUS = '@@auth/SET_AUTHENTICATION_STATUS',
  LOGIN_WITH_MFA = '@@auth/LOGIN_WITH_MFA',
  CONFIRM_LOGIN_USING_MFA = '@@auth/CONFIRM_LOGIN_USING_MFA',
}
