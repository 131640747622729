import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookExecOptions,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {
  CreateServiceInput,
  CreateServiceMutation,
  CreateServiceMutationVariables,
  Exact,
  GetServiceQuery,
  GetServiceQueryVariables,
  InputMaybe,
  ServiceByPharmacyIdQuery,
  ServiceByPharmacyIdQueryVariables,
  UpdateServiceInput,
  UpdateServiceMutation,
  UpdateServiceMutationVariables,
} from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import {
  GET_SERVICE,
  QUERY_SERVICE_BY_PHARMACY_ID,
} from "../codegens/HEALTCARE-RESERVATION/queries";
import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { GRAPHQL_DESTINATION } from "../ApolloClient";
import {
  MUTATION_CREATE_SERVICE,
  MUTATION_UPDATE_SERVICE,
} from "../codegens/HEALTCARE-RESERVATION/mutations";

export const useServices = () => {
  const [queryServices, { error, loading }] = useLazyQuery<
    ServiceByPharmacyIdQuery,
    ServiceByPharmacyIdQueryVariables
  >(QUERY_SERVICE_BY_PHARMACY_ID, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const [
    queryGetService,
    { error: errorGetService, loading: loadingGetService },
  ] = useLazyQuery<GetServiceQuery, GetServiceQueryVariables>(GET_SERVICE, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const [
    saveServiceMutation,
    {
      loading: loadingSaveService,
      error: errorSaveService,
      data: serviceSaved,
    },
  ] = useMutation<CreateServiceMutation, CreateServiceMutationVariables>(
    MUTATION_CREATE_SERVICE,
    {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    }
  );

  const [
    updateServiceMutation,
    {
      loading: loadingUpdateService,
      error: errorUpdateService,
      data: serviceUpdated,
    },
  ] = useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(
    MUTATION_UPDATE_SERVICE,
    {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    }
  );
  const { pharmacyID } = useContext(AuthContext);

  const fetchServices = (
    options: Partial<
      LazyQueryHookExecOptions<
        ServiceByPharmacyIdQuery,
        Exact<{
          pharmacyID: string;
          first?: InputMaybe<number> | undefined;
          after?: InputMaybe<string> | undefined;
        }>
      >
    >
  ) => {
    return queryServices({
      ...options,
      fetchPolicy: "network-only",
      variables: {
        pharmacyID,
      },
    });
  };

  const getService = (
    options: Partial<
      LazyQueryHookExecOptions<
        GetServiceQuery,
        Exact<{
          serviceId: string;
        }>
      >
    >
  ) => {
    return queryGetService({
      ...options,
      fetchPolicy: "network-only",
    });
  };

  const saveService = (
    options: MutationFunctionOptions<
      CreateServiceMutation,
      Exact<{
        input: CreateServiceInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return saveServiceMutation({
      ...options,
    });
  };

  const updateService = (
    options: MutationFunctionOptions<
      UpdateServiceMutation,
      Exact<{
        input: UpdateServiceInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return updateServiceMutation({
      ...options,
    });
  };

  return {
    fetchServices,
    loadingFetchServices: loading,
    errorFetchServices: error,
    getService,
    loadingGetService,
    errorGetService,
    saveService,
    errorSaveService,
    loadingSaveService,
    serviceSaved,
    updateService,
    loadingUpdateService,
    errorUpdateService,
    serviceUpdated,
  };
};
