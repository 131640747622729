import axios from "axios";
import config from "../../config";
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROVIDER_AUTHORIZATION } from "../../graphql-queries";
import { ELoginStep } from "../../redux/actions";
import { INotification } from "../../interfaces/INotification";
import moment from "moment";
import { signInUserWithEmail, refreshSession } from '../../redux/auth/services/cognitoService'; // Update this import
import { logger } from "../../utils/logger";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;
const stripeBillingPortalUrl = process.env.REACT_APP_API_STRIPE_BILLING_URL;
// intercepting to capture errors
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // Attempt to refresh the token
        const { idToken } = await refreshSession();
        if (idToken) {
          originalRequest.headers['Authorization'] = 'Bearer ' + idToken;
          return axios(originalRequest);
        }
      } catch (refreshError) {
        console.error('Session refresh failed:', refreshError);
        // Handle failed refresh (e.g., redirect to login)
        window.location.href = '/auth/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const AUTH_SESSION_KEY = "dragatron_user";
const PROVIDER_ID_KEY = "_pid";
const LOGIN_STEPS_KEY = "_ls";
const FORGET_PASSWORD_KEYS = "_fp";
const OTP_KEYS = "_otp";
const NOTIFICATIONS_KEY = "_notif";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = "JWT " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = localStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};
class APICore {
  expirySessionTime = moment();
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any) => {
    return axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  // createWithFile = (url: string, data: any) => {
  //   const formData = new FormData();
  //   for (const k in data) {
  //     formData.append(k, data[k]);
  //   }

  //   const config = {
  //     headers: {
  //       ...axios.defaults.headers,
  //       "content-type": "multipart/form-data",
  //     },
  //   };
  //   return axios.post(url, formData, config);
  // };

  /**
   * post given data to url with file
   */
  // updateWithFile = (url: string, data: any) => {
  //   const formData = new FormData();
  //   for (const k in data) {
  //     formData.append(k, data[k]);
  //   }

  //   const config = {
  //     headers: {
  //       ...axios.defaults.headers,
  //       "content-type": "multipart/form-data",
  //     },
  //   };
  //   return axios.patch(url, formData, config);
  // };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser(); // Make sure this function correctly retrieves the user object
    if (
      !user ||
      !user.tokens ||
      !user.tokens.idToken ||
      !user.tokens.idToken.payload
    ) {
      return false;
    }

    // // Example assumes you need to serialize the idToken object to get the JWT string
    // // This step depends on how your idToken is structured
    // const idTokenObject = user.tokens.idToken;

    // // You might need to adjust this depending on how you can extract the JWT string from the idToken object
    // const idTokenJwt = JSON.stringify(idTokenObject);

    try {
      const decoded = user.tokens.idToken.payload;
      // logger.log(decoded, "decoded");
      const currentTime = Date.now() / 1000;
      // logger.log(currentTime, "currentTime");
      // if (decoded.exp < currentTime) {
      //   localStorage.clear();
      //   console.warn("access token expired");
      //   return false;
      // } else {
      //   // logger.log("access token valid");
      //   return true;
      // }
      const extendedExp = decoded.exp + 3200;
      // logger.log(extendedExp, "extendedExp");

      const testAutoLogoutTime = decoded.exp - 3480; // only two minutes
      // logger.log("Should auto logout at ", testAutoLogoutTime);

      // return testAutoLogoutTime > currentTime;

      // return decoded.exp > currentTime;
      return extendedExp > currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  };

  getExpiryTokenTime = () => {
    const user = this.getLoggedInUser(); // Make sure this function correctly retrieves the user object
    if (
      !user ||
      !user.tokens ||
      !user.tokens.idToken ||
      !user.tokens.idToken.payload
    ) {
      return moment();
    }

    const decoded = user.tokens.idToken.payload;

    const extendedExp = decoded.exp + 3200;

    // logger.log(moment(decoded.exp * 1000), "original exp")
    // const testAutoLogoutTime = decoded.exp - 3480;

    // logger.log(moment(testAutoLogoutTime * 1000).format("YYYY-MM-DD hh:mm:ss a"))

    // return moment(testAutoLogoutTime * 1000)

    return moment(extendedExp * 1000);
  };

  isUserAuthorized = () => {
    const user = this.getLoggedInUser(); // Make sure this function correctly retrieves the user object
    if (
      !user ||
      !user.tokens ||
      !user.tokens.idToken ||
      !user.tokens.idToken.payload
    ) {
      return false;
    }

    // // Example assumes you need to serialize the idToken object to get the JWT string
    // // This step depends on how your idToken is structured
    // const idTokenObject = user.tokens.idToken;

    // // You might need to adjust this depending on how you can extract the JWT string from the idToken object
    // const idTokenJwt = JSON.stringify(idTokenObject);

    try {
      const decoded = user.tokens.idToken.payload;
      const { data } = useQuery(QUERY_GET_PROVIDER_AUTHORIZATION, {
        fetchPolicy: "network-only",
        variables: {
          pharmacistId: decoded["custom:pharmacistID"],
        },
      });

      const provider = data?.getProvider;

      if (!provider) {
        return false;
      }

      return provider.isAdministrator === true;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  };

  setLoggedInUser = (session: any, _pid?: string) => {
    if (session) {
      localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));

      if (_pid) {
        localStorage.setItem(PROVIDER_ID_KEY, _pid);
      }
    } else {
      localStorage.removeItem(AUTH_SESSION_KEY);
      localStorage.removeItem(PROVIDER_ID_KEY);

      localStorage.clear();
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  getLoginStep = () => {
    const step = localStorage.getItem(LOGIN_STEPS_KEY);

    return typeof step === "string"
      ? (step as ELoginStep)
      : ELoginStep.FILL_FORM;
  };

  setLoginStep = (step: ELoginStep) => {
    localStorage.setItem(LOGIN_STEPS_KEY, step);
  };

  getForgetPassword = () => {
    const forgetPasswordResponse = localStorage.getItem(FORGET_PASSWORD_KEYS);
    return typeof forgetPasswordResponse === "string"
      ? JSON.parse(forgetPasswordResponse)
      : forgetPasswordResponse;
  };

  setForgetPassword = (response: any) => {
    if (response === null) {
      localStorage.removeItem(FORGET_PASSWORD_KEYS);
      return;
    }

    localStorage.setItem(FORGET_PASSWORD_KEYS, JSON.stringify(response));
  };

  getOtp = () => {
    const otp = localStorage.getItem(OTP_KEYS);
    return otp;
  };

  setOtp = (otp: string | null) => {
    if (otp === null) {
      localStorage.removeItem(OTP_KEYS);
      return;
    }

    localStorage.setItem(OTP_KEYS, otp);
  };

  getNotifications = () => {
    const notification = localStorage.getItem(NOTIFICATIONS_KEY);
    const notifications =
      typeof notification === "string"
        ? JSON.parse(notification)
        : notification;
    return notifications as INotification[];
  };

  setNotifications = (notifications: INotification[]) => {
    if (notifications.length === 0) {
      localStorage.removeItem(NOTIFICATIONS_KEY);
      return;
    }

    localStorage.setItem(NOTIFICATIONS_KEY, JSON.stringify(notifications));
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };

  getStripeBillingPortalUrl = (): string | null => {
    const user = this.getLoggedInUser();
    if (!user) {
      return null;
    }
    const userEmail: string = user?.tokens?.idToken?.payload?.email; // Adjust this based on the actual structure of your user object
    return `${stripeBillingPortalUrl}?prefilled_email=${encodeURIComponent(
      userEmail
    )}`;
  };

  startPeriodicRefresh = (interval = 45 * 60 * 1000) => { // 45 minutes
    setInterval(async () => {
      try {
        if (this.isUserAuthenticated()) {
          const user = this.getLoggedInUser();
          if (user && user.username && user.password) {
            await signInUserWithEmail({ username: user.username, password: user.password });
            logger.log('Token refreshed successfully');
          }
        }
      } catch (error) {
        logger.error('Periodic refresh failed:', error);
      }
    }, interval);
  }
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
