import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  Box,
  Alert,
} from '@mui/material';
import { Controller, Control, FieldPath, useWatch } from 'react-hook-form';
import { FormData } from '../UTIProtocolForm';
import { MedicationOutlined, HealingOutlined, InfoOutlined, LocalPharmacyOutlined } from '@mui/icons-material';
import { orange } from '@mui/material/colors';

interface StepProps {
  control: Control<FormData>;
  step?: number;
}

const Step7Treatment: React.FC<StepProps> = ({ control, step }) => {
  const medicineProvided = useWatch({
    control,
    name: 'treatment.medicineProvided',
  });

  const isNitrofurantoin = medicineProvided === 'Nitrofurantoin';

  return (
    <Box sx={{ maxWidth: 900, margin: 'auto' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <LocalPharmacyOutlined sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Approved Medicines Provided</Typography>
            </Box>

            <Controller
              name="treatment.medicineProvided"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {[
                    { value: 'Trimethoprim', label: 'First Line - Trimethoprim 300mg orally at night for 3 nights' },
                    {
                      value: 'Nitrofurantoin',
                      label: 'Second Line - Nitrofurantoin 100mg orally every 6 hours for 5 days',
                    },
                    { value: 'Cefalexin', label: 'Third Line - Cefalexin 500mg orally every 12 hours for 5 days' },
                  ].map(({ value, label }) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio color="primary" size="small" />}
                      label={label}
                    />
                  ))}
                </RadioGroup>
              )}
            />

            <Controller
              name="treatment.dosageInstructions"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={2}
                  label="Dosage Instructions"
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                />
              )}
            />

            <Controller
              name="treatment.quantityConfirmation"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} color="primary" size="small" />}
                  label="Medicine supplied does not exceed the smallest available pack size"
                  sx={{ mt: 1 }}
                />
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <MedicationOutlined sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Conservative Management</Typography>
            </Box>

            <Typography variant="subtitle2" gutterBottom>
              Analgesia:
            </Typography>
            <Grid container spacing={1}>
              {['paracetamol', 'ibuprofen', 'naproxen'].map((med) => (
                <Grid item xs={12} sm={4} key={med}>
                  <Controller
                    name={`treatment.analgesia.${med}` as FieldPath<FormData>}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} color="primary" size="small" />}
                        label={med.charAt(0).toUpperCase() + med.slice(1)}
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>

            <Controller
              name="treatment.analgesia.dosageInstructions"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={2}
                  label="Analgesia Dosage Instructions"
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                />
              )}
            />

            <Box sx={{ mt: 2 }}>
              <Controller
                name="treatment.alkalinisingAgent"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} color="primary" size="small" disabled={isNitrofurantoin} />}
                    label="Urinary Alkalinising Agents provided"
                  />
                )}
              />
              <Typography variant="caption" display="block" sx={{ mt: 0.5, color: 'text.secondary' }}>
                Note: Safety and efficacy not established
              </Typography>
              {isNitrofurantoin && (
                <Alert
                  severity="warning"
                  sx={{
                    mt: 2,
                    mb: 2,
                    backgroundColor: orange[100],
                    color: orange[900],
                    '& .MuiAlert-icon': {
                      color: orange[900],
                    },
                  }}
                >
                  Urinary Alkalinising Agents should not be provided with Nitrofurantoin
                </Alert>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <HealingOutlined sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Non-Pharmacological Advice</Typography>
            </Box>

            <Controller
              name="treatment.selfCareAdvice"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} color="primary" size="small" />}
                  label="Provided self-care advice"
                />
              )}
            />
            <Controller
              name="treatment.utiProphylaxisEducation"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} color="primary" size="small" />}
                  label="Educated about UTI prophylaxis"
                />
              )}
            />
            <Controller
              name="treatment.adviceDetails"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={3}
                  label="Details of advice given"
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                />
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <InfoOutlined sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Dispensing Confirmation</Typography>
            </Box>

            <Controller
              name="treatment.dispensedViaSoftware"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} color="primary" size="small" />}
                  label="Medication dispensed via pharmacy dispensing software"
                />
              )}
            />
            <Controller
              name="treatment.labelingCompliance"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} color="primary" size="small" />}
                  label="Labeling meets legislative requirements outlined in the Poisons and Therapeutic Goods Regulation 2008"
                />
              )}
            />
            <Controller
              name="treatment.dispensingComments"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={2}
                  label="Additional Dispensing Comments"
                  variant="outlined"
                  size="small"
                  sx={{ mt: 1 }}
                />
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step7Treatment;
