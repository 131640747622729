import { ErrorOutline, MailOutline } from "@mui/icons-material";
import { Box, Paper, Typography, Container, Button } from "@mui/material";

type Props = {
  error: Error;
};

const ErrorReport = ({ error }: Props) => {
  return (
    <Box component="div" sx={{ p: 3 }}>
      <Paper
        sx={{
          p: "3rem",
          background: "white",
          minHeight: "80vh",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
            <ErrorOutline sx={{ fontSize: 100, color: "error.main", mb: 3 }} />
            <Typography variant="h4" gutterBottom>
              Sorry
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              We having a problem to process your request. Feel free to report
              this problem
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const bodyString = `logs: ${error} \n page: ${window.location.href}`;
                  window.open(
                    `mailto:admin@dragatron.com.au?subject=Air Vaccination Problem&body=${bodyString}`
                  );
                }}
                startIcon={<MailOutline />}
              >
                Report
              </Button>
            </Box>
          </Container>
        </Box>
      </Paper>
    </Box>
  );
};

export default ErrorReport;
