import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  Box,
  Paper,
  Alert,
  SelectChangeEvent,
  InputLabel,
} from '@mui/material';
import {
  Person as UserIcon,
  CalendarToday as CalendarIcon,
  Phone as PhoneIcon,
  Mail as MailIcon,
  Warning as AlertCircleIcon,
  LocalPharmacy as PillIcon,
  LocalHospital as StethoscopeIcon,
  Psychology as BrainIcon,
  Work as BriefcaseIcon,
  TrackChanges as TargetIcon,
  Healing as SyringeIcon,
  Assignment as ClipboardCheckIcon,
} from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { logger } from 'utils/logger';
import dayjs, { Dayjs } from 'dayjs';

interface FormData {
  [key: string]: string | boolean | number | null | Dayjs;
}

export default function ODTPatientIntakeForm() {
  const [formData, setFormData] = useState<FormData>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleDateChange = (name: string, date: Dayjs | null) => {
    setFormData((prevState) => ({ ...prevState, [name]: date }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    logger.log(formData);
    // Here you would typically send the data to a server
  };

  const SectionHeader = ({ icon, title }: { icon: React.ReactNode; title: string }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      {icon}
      <Typography variant="h6" sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit}>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h4" gutterBottom>
            ODT Patient Intake Form
          </Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            Please fill out all sections of this form accurately.
          </Alert>
          <Grid container spacing={3}>
            {/* 1. Personal Information */}
            <Grid item xs={12}>
              <SectionHeader icon={<UserIcon />} title="Personal Information" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Full Name" name="fullName" onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Date of Birth"
                    onChange={(date) => handleDateChange('dateOfBirth', date as Dayjs | null)}
                    slotProps={{ textField: { fullWidth: true, required: true } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      labelId="gender-label"
                      id="gender"
                      name="gender"
                      value={(formData.gender as string) || ''}
                      onChange={handleSelectChange}
                      label="Gender"
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Address" name="address" onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    type="tel"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Email" name="email" type="email" onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Emergency Contact (Name and Phone)"
                    name="emergencyContact"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 2. Opioid Use History */}
            <Grid item xs={12}>
              <SectionHeader icon={<PillIcon />} title="Opioid Use History" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Primary opioid of dependence"
                    name="primaryOpioid"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Duration of use"
                    name="durationOfUse"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Average daily use (amount and frequency)"
                    name="averageDailyUse"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Route of administration"
                    name="routeOfAdministration"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Date of last use"
                    onChange={(date) => handleDateChange('dateOfLastUse', date as Dayjs | null)}
                    slotProps={{ textField: { fullWidth: true, required: true } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Other substances used"
                    name="otherSubstances"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 3. Treatment History */}
            <Grid item xs={12}>
              <SectionHeader icon={<StethoscopeIcon />} title="Treatment History" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Previous opioid dependence treatments (including dates and outcomes)"
                    name="previousTreatments"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Current medications (including dosage and frequency)"
                    name="currentMedications"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 4. Medical History */}
            <Grid item xs={12}>
              <SectionHeader icon={<AlertCircleIcon />} title="Medical History" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Allergies" name="allergies" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Chronic medical conditions"
                    name="chronicConditions"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Past surgeries" name="pastSurgeries" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Hepatitis C status" name="hepatitisC" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="HIV status" name="hiv" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="TB test result and date" name="tbTest" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">For women:</FormLabel>
                    <FormControlLabel
                      control={<Checkbox onChange={handleCheckboxChange} name="pregnant" />}
                      label="Pregnant"
                    />
                    <FormControlLabel
                      control={<Checkbox onChange={handleCheckboxChange} name="breastfeeding" />}
                      label="Breastfeeding"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* 5. Mental Health History */}
            <Grid item xs={12}>
              <SectionHeader icon={<BrainIcon />} title="Mental Health History" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Diagnosed mental health conditions"
                    name="mentalHealthConditions"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Current mental health treatment"
                    name="currentMentalHealthTreatment"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="History of suicide attempts or self-harm"
                    name="suicideAttempts"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 6. Social History */}
            <Grid item xs={12}>
              <SectionHeader icon={<BriefcaseIcon />} title="Social History" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Employment status" name="employmentStatus" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Living situation" name="livingSituation" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Legal issues" name="legalIssues" onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Driving status" name="drivingStatus" onChange={handleInputChange} />
                </Grid>
              </Grid>
            </Grid>

            {/* 7. Treatment Goals */}
            <Grid item xs={12}>
              <SectionHeader icon={<TargetIcon />} title="Treatment Goals" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Short-term goals"
                    name="shortTermGoals"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Long-term goals"
                    name="longTermGoals"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 8. Depot Buprenorphine Treatment */}
            <Grid item xs={12}>
              <SectionHeader icon={<SyringeIcon />} title="Depot Buprenorphine Treatment" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Preferred product</FormLabel>
                    <RadioGroup name="preferredProduct" onChange={handleInputChange}>
                      <FormControlLabel value="buvidaWeekly" control={<Radio />} label="Buvidal® Weekly" />
                      <FormControlLabel value="buvidaMonthly" control={<Radio />} label="Buvidal® Monthly" />
                      <FormControlLabel value="sublocade" control={<Radio />} label="Sublocade®" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Current opioid agonist treatment</FormLabel>
                    <RadioGroup name="currentOpioidTreatment" onChange={handleInputChange}>
                      <FormControlLabel value="sublingual" control={<Radio />} label="Sublingual buprenorphine" />
                      <FormControlLabel value="methadone" control={<Radio />} label="Methadone" />
                      <FormControlLabel
                        value="none"
                        control={<Radio />}
                        label="None (opioid-dependent, not in treatment)"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Current Dose (if applicable)"
                    name="currentDose"
                    onChange={handleInputChange}
                    placeholder="e.g., 8mg/day for 7 days"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 9. Clinical Assessment */}
            <Grid item xs={12}>
              <SectionHeader
                icon={<ClipboardCheckIcon />}
                title="Clinical Assessment (to be completed by healthcare provider)"
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Opioid withdrawal score (COWS)"
                    name="cowsScore"
                    type="number"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Opioid cravings (0-10 scale)"
                    name="opioidCravings"
                    type="number"
                    inputProps={{ min: 0, max: 10 }}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Vital signs</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                      <TextField
                        fullWidth
                        label="BP Systolic"
                        name="bp_systolic"
                        type="number"
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        fullWidth
                        label="BP Diastolic"
                        name="bp_diastolic"
                        type="number"
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        fullWidth
                        label="Heart Rate"
                        name="heartRate"
                        type="number"
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        fullWidth
                        label="Respiratory Rate"
                        name="respiratoryRate"
                        type="number"
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        fullWidth
                        label="Temperature"
                        name="temperature"
                        type="number"
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Evidence of injection site infections"
                    name="injectionSiteInfections"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Mental status examination"
                    name="mentalStatusExam"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 10. Treatment Plan */}
            <Grid item xs={12}>
              <SectionHeader icon={<ClipboardCheckIcon />} title="Treatment Plan" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel id="initial-dose-label">Initial depot buprenorphine dose</FormLabel>
                    <Select
                      labelId="initial-dose-label"
                      name="initialDose"
                      value={(formData.initialDose as string) || ''}
                      onChange={handleSelectChange}
                      label="Initial depot buprenorphine dose"
                    >
                      <MenuItem value="buvidal_weekly_8mg">Buvidal® Weekly: 8mg</MenuItem>
                      <MenuItem value="buvidal_weekly_16mg">Buvidal® Weekly: 16mg</MenuItem>
                      <MenuItem value="buvidal_weekly_24mg">Buvidal® Weekly: 24mg</MenuItem>
                      <MenuItem value="buvidal_weekly_32mg">Buvidal® Weekly: 32mg</MenuItem>
                      <MenuItem value="buvidal_monthly_64mg">Buvidal® Monthly: 64mg</MenuItem>
                      <MenuItem value="buvidal_monthly_96mg">Buvidal® Monthly: 96mg</MenuItem>
                      <MenuItem value="buvidal_monthly_128mg">Buvidal® Monthly: 128mg</MenuItem>
                      <MenuItem value="sublocade_300mg">Sublocade®: 300mg</MenuItem>
                      <MenuItem value="sublocade_100mg">Sublocade®: 100mg</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Frequency of clinical reviews"
                    name="clinicalReviewFrequency"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Psychosocial interventions planned"
                    name="psychosocialInterventions"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Referrals made"
                    name="referralsMade"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 11. Informed Consent */}
            <Grid item xs={12}>
              <SectionHeader icon={<ClipboardCheckIcon />} title="Informed Consent" />
              <Typography variant="body1" paragraph>
                I have been informed about the nature of depot buprenorphine treatment, including potential benefits,
                risks, and alternative treatments. I understand that this medication must be administered by a
                healthcare professional and cannot be dispensed to me directly. I agree to attend all scheduled
                appointments and to inform my healthcare provider of any changes in my health status or medication use.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Patient Signature"
                    name="patientSignature"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Date"
                    onChange={(date) => handleDateChange('patientSignatureDate', date as Dayjs | null)}
                    slotProps={{ textField: { fullWidth: true, required: true } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Healthcare Provider Signature"
                    name="providerSignature"
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Date"
                    onChange={(date) => handleDateChange('providerSignatureDate', date as Dayjs | null)}
                    slotProps={{ textField: { fullWidth: true, required: true } }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 12. For Healthcare Provider Use */}
            <Grid item xs={12}>
              <SectionHeader icon={<ClipboardCheckIcon />} title="For Healthcare Provider Use" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Date of treatment initiation"
                    onChange={(date) => handleDateChange('treatmentInitiationDate', date as Dayjs | null)}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Prescriber name and contact details"
                    name="prescriberDetails"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Pharmacy/clinic for administration"
                    name="pharmacyClinic"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Next appointment date"
                    onChange={(date) => handleDateChange('nextAppointmentDate', date as Dayjs | null)}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained" color="primary" size="large">
            Create Patient
          </Button>
        </Box>
      </form>
    </LocalizationProvider>
  );
}
