import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Divider,
  Box,
  Avatar,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  Calendar,
  User,
  Home,
  Clock,
  Activity,
  Syringe,
  Hash,
  MapPin,
  ChevronDown,
  CheckCircle,
  XCircle,
  CircleAlert,
} from 'lucide-react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import VaccineInformationSheet from './VaccineInformationSheet';
import { logger } from '../utils/logger';
interface PatientDetailsModalProps {
  open: boolean;
  onClose: () => void;
  patientDetails: any; // Replace 'any' with a proper type for your patient details
}

const PatientDetailsModal: React.FC<PatientDetailsModalProps> = ({ open, onClose, patientDetails }) => {
  const pdfRef = useRef<HTMLDivElement>(null);

  if (!patientDetails) {
    return null; // or return a loading indicator
  }

  dayjs.extend(customParseFormat);

  const formatDate = (dateString: string) => {
    let formattedDate;
    if (dateString.includes('-')) {
      // Format: "2024-09-05"
      formattedDate = dayjs(dateString);
    } else {
      // Format: "05092024"
      formattedDate = dayjs(dateString, 'DDMMYYYY');
    }
    return formattedDate.isValid() ? formattedDate.format('MMMM D, YYYY') : 'Invalid Date';
  };

  const generatePDF = () => {
    const content = document.getElementById('pdf-content');
    if (content) {
      html2canvas(content, {
        scale: 2, // Increase scale for better quality
        logging: true, // Enable logging for debugging
        useCORS: true, // Try to load images with CORS if they are not on the same domain
      }).then((canvas) => {
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        const pdf = new jsPDF('p', 'mm');
        let position = 0;

        // Add image to first page
        pdf.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
        // Add subsequent pages with top margin

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.setFillColor(255, 255, 255);
          pdf.rect(0, 0, 210, 297, 'F');
          pdf.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight - 20; // Subtract the top margin from page height
        }

        pdf.save('vaccine_information_sheet.pdf');
      });
    }
  };

  const DetailItem = ({ icon, label, value }: { icon: React.ReactNode; label: string; value: string | number }) => (
    <Box display="flex" alignItems="center" mb={2}>
      <Box mr={2}>{icon}</Box>
      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    </Box>
  );

  const AssessmentItem = ({ question, answer }: { question: string; answer: boolean }) => (
    <Box display="flex" alignItems="center" mb={1}>
      {!answer ? ( // We've added the NOT (!) operator here
        <CheckCircle size={20} color="green" style={{ marginRight: 8 }} />
      ) : (
        <CircleAlert size={20} color="orange" style={{ marginRight: 8 }} />
      )}
      <Typography variant="body2">{question}</Typography>
    </Box>
  );

  const assessmentQuestions = [
    { key: 'isUnwellToday', question: 'Are you unwell today?' },
    {
      key: 'hasImmunityLoweringDisease',
      question: 'Do you have a disease that lowers immunity?',
    },
    {
      key: 'receivingImmunityLoweringTreatment',
      question: 'Are you receiving treatment that lowers immunity?',
    },
    {
      key: 'isInfantOfMotherWithImmunosuppressiveTherapy',
      question: 'Are you an infant of a mother who received immunosuppressive therapy?',
    },
    {
      key: 'hadSevereReactionToVaccine',
      question: 'Have you had a severe reaction following a vaccine?',
    },
    { key: 'hasSevereAllergies', question: 'Do you have severe allergies?' },
    {
      key: 'receivedVaccineInPastMonth',
      question: 'Have you received a vaccine in the past month?',
    },
    {
      key: 'receivedImmunoglobulinOrBloodProduct',
      question: 'Have you had an injection or blood products in the past year?',
    },
    { key: 'isPregnant', question: 'Are you pregnant?' },
    {
      key: 'hasGuillainBarreSyndrome',
      question: 'Do you have a history of Guillain-Barré syndrome?',
    },
    { key: 'wasPretermInfant', question: 'Were you a preterm infant?' },
    {
      key: 'hasSevereOrChronicIllness',
      question: 'Do you have a severe or chronic illness?',
    },
    {
      key: 'hasBleedingDisorder',
      question: 'Do you have a bleeding disorder?',
    },
    {
      key: 'isAboriginalOrTorresStraitIslander',
      question: 'Are you Aboriginal or Torres Strait Islander?',
    },
    {
      key: 'doesNotHaveFunctioningSpleen',
      question: 'Do you not have a functioning spleen?',
    },
    {
      key: 'planningPregnancyOrParenthood',
      question: 'Are you planning pregnancy or parenthood?',
    },
    {
      key: 'isParentGrandparentOrCarerOfInfant',
      question: 'Are you a parent, grandparent, or carer of an infant?',
    },
    {
      key: 'livesWithPersonWithImmunityLoweringCondition',
      question: 'Do you live with a person with an immunity lowering condition?',
    },
    { key: 'planningTravel', question: 'Are you planning to travel?' },
    {
      key: 'hasOccupationOrLifestyleRequiringVaccination',
      question: 'Do you have an occupation or lifestyle requiring vaccination?',
    },
  ];

  const hasAssessmentData = patientDetails.vaccinationAssessmentAnswers != null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
        <Box display="flex" alignItems="center">
          <Avatar sx={{ width: 60, height: 60, mr: 2, bgcolor: 'secondary.main' }}>
            {`${patientDetails.firstName[0]}${patientDetails.lastName[0]}`}
          </Avatar>
          <Box>
            <Typography variant="h5" sx={{ color: 'white' }}>
              {`${patientDetails.firstName} ${patientDetails.lastName}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'white' }}>
              Transaction ID: {patientDetails.transactionUUID}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Personal Information
              </Typography>
              <DetailItem
                icon={<Calendar size={24} />}
                label="Date of Birth"
                value={formatDate(patientDetails.dateOfBirth)}
              />
              <DetailItem icon={<User size={24} />} label="Gender" value={patientDetails.gender} />
              <DetailItem
                icon={<Home size={24} />}
                label="Address"
                value={`${patientDetails.addressLineOne}${
                  patientDetails.addressLineTwo ? `, ${patientDetails.addressLineTwo}` : ''
                }, ${patientDetails.locality}, ${patientDetails.postCode}`}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Encounter Details
              </Typography>
              <DetailItem
                icon={<Clock size={24} />}
                label="Encounter Date"
                value={formatDate(patientDetails.encounterDate)}
              />
              <DetailItem icon={<Activity size={24} />} label="Status" value={patientDetails.status} />
              <DetailItem icon={<Syringe size={24} />} label="Vaccine Brand" value={patientDetails.vaccineBrand} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Provider Information
              </Typography>
              <DetailItem
                icon={<User size={24} />}
                label="Provider Name"
                value={`${patientDetails.providerFirstName} ${patientDetails.providerLastName}`}
              />
              <DetailItem icon={<Hash size={24} />} label="Provider Number" value={patientDetails.providerNumber} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Additional Details
              </Typography>
              <DetailItem icon={<Hash size={24} />} label="Claim ID" value={patientDetails.claimId} />
              <DetailItem icon={<Hash size={24} />} label="Device Name" value={patientDetails.deviceName} />
              <DetailItem
                icon={<MapPin size={24} />}
                label="Site of Administration"
                value={patientDetails.siteOfAdministration}
              />
            </Paper>
          </Grid>
          {hasAssessmentData && (
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ChevronDown />}>
                  <Typography variant="h6">Vaccine Assessment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {assessmentQuestions.map((item) => (
                      <Grid item xs={12} md={6} key={item.key}>
                        <AssessmentItem
                          question={item.question}
                          answer={patientDetails.vaccinationAssessmentAnswers[item.key]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>

        {/* Hidden div for PDF generation */}
        <div style={{ display: 'none' }}>
          <VaccineInformationSheet ref={pdfRef} patientDetails={patientDetails} />
        </div>
      </DialogContent>

      <DialogActions>
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" color="primary">
              Discharge Summary
            </Button>
          )}
          content={() => pdfRef.current}
        />
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PatientDetailsModal;
