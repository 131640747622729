import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarEventComponent from './CalendarEventComponent';
import PatientPrescriptionModal from './PatientPrescriptionModal';
import { Star } from 'lucide-react'; // Import the Star icon

const localizer = momentLocalizer(moment);

interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  color?: string;
  isMultiDay?: boolean;
  hasSpecialEvent?: boolean;
}

interface EventProps {
  event: CalendarEvent;
  title: React.ReactNode;
}

interface SupplyCalendarProps {
  events?: Record<string, Array<{ name: string; time: string; color: string; endDate?: string }>>;
  currentDate: Date;
  onNavigate: (newDate: Date) => void;
  specialDates?: string[];
}

const SupplyCalendar: React.FC<SupplyCalendarProps> = ({ events = {}, currentDate, onNavigate, specialDates = [] }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const calendarEvents: CalendarEvent[] = Object.entries(events).flatMap(([date, dayEvents]) =>
    dayEvents.map((event, index) => {
      const startTime = moment(`${date}T${event.time}`);
      const endTime = event.endDate
        ? moment(`${event.endDate}T${event.time}`).add(30, 'minutes')
        : startTime.clone().add(30, 'minutes');

      return {
        id: `${date}-${index}`,
        title: `${event.name}`,
        start: startTime.toDate(),
        end: endTime.toDate(),
        color: event.color,
        isMultiDay: !!event.endDate,
        hasSpecialEvent: specialDates.includes(date),
      };
    })
  );

  const eventStyleGetter = (event: CalendarEvent) => {
    return {
      style: {
        backgroundColor: event.color || '#3174ad',
        borderRadius: '4px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
      },
    };
  };

  const handleEventClick = (event: CalendarEvent) => {
    setModalOpen(true);
  };

  return (
    <div style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        eventPropGetter={eventStyleGetter}
        components={{
          event: (props: EventProps) => (
            <CalendarEventComponent {...props} hasSpecialEvent={props.event.hasSpecialEvent} />
          ),
        }}
        date={currentDate}
        onNavigate={onNavigate}
        views={['month', 'week', 'day', 'agenda']}
        step={30}
        timeslots={2}
        min={new Date(0, 0, 0, 0, 0, 0)}
        max={new Date(0, 0, 0, 23, 59, 59)}
        onSelectEvent={handleEventClick}
      />
      <PatientPrescriptionModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};

export default SupplyCalendar;
