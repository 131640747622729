import React, { useContext, useEffect, useState } from "react";
import {
  ImmunisationStatementRequest,
  ImmunisationStatementResponse,
} from "./ImmuniStatement";
import AuthContext from "../../contexts/AuthContext";
import { IdentifyIndividualResponse } from "./identifyIndividual";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Snackbar,
  Box,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useIdToken } from "../../hooks/useIdToken";
import { logger } from '../../utils/logger';

interface ImmunisationStatementProps {
  patientIdentifier: string;
  individualDetails: IdentifyIndividualResponse;
}

const ImmunisationStatement: React.FC<ImmunisationStatementProps> = ({
  patientIdentifier,
  individualDetails,
}) => {
  const [pdfBase64, setPdfBase64] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const {
    idToken,
    isLoading: isTokenLoading,
    error: idTokenError,
  } = useIdToken();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const {
    providerNumber,
    providerStore,
    hpiiNumber,
    hpioNumber,
    minorId,
    deviceName,
    prodaOrgId,
  } = useContext(AuthContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertProps["severity"]>("success");

  const fetchImmunisationStatement = async (
    requestPayload: ImmunisationStatementRequest
  ): Promise<ImmunisationStatementResponse | null> => {
    try {
      logger.log("requestPayload", requestPayload);
      const apiImmuStatement = process.env.REACT_APP_IMMUNISATION_STATEMENT;
      if (!apiImmuStatement) {
        throw new Error("REACT_APP_IMMUNISATION_STATEMENT is not defined");
      }
      const response = await fetch(apiImmuStatement, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestPayload),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: ImmunisationStatementResponse = await response.json();
      logger.log("data", data);
      return data;
    } catch (error) {
      console.error("Error fetching immunisation statement:", error);
      return null;
    }
  };

  useEffect(() => {
    if (idToken) {
      setLoading(true);
      setErrorMessage("");
      const requestBody: ImmunisationStatementRequest = {
        individualIdentifier: patientIdentifier,
        informationProvider: {
          providerNumber: providerNumber,
          hpioNumber: hpioNumber,
          hpiiNumber: hpiiNumber,
        },
        dateOfBirth:
          individualDetails.data.individualDetails.individual.personalDetails
            .dateOfBirth,
        minorId: minorId,
        deviceName: deviceName,
        prodaOrgId: prodaOrgId,
      };

      fetchImmunisationStatement(requestBody)
        .then((response) => {
          setLoading(false);
          if (response && response.success) {
            const immunisationHistoryStatement =
              response.data?.immunisationHistoryStatement;
            if (immunisationHistoryStatement?.fileContent) {
              setPdfBase64(immunisationHistoryStatement.fileContent);
            } else if (response.data?.errors) {
              logger.log(response.data?.errors[0].message);
              setErrorMessage(response.data?.errors[0].message);
            }
          } else {
            console.error("Response was not successful or data is missing.");
          }
        })
        .catch((error) => {
          console.error("Failed to fetch immunisation statement", error);
          setLoading(false);
        });
    }
  }, [
    idToken,
    patientIdentifier,
    individualDetails,
    providerNumber,
    hpioNumber,
    hpiiNumber,
    minorId,
    deviceName,
    prodaOrgId,
  ]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEmail("");
    setError("");
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError("");
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSendEmail = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    const emailData = {
      toEmail: email,
      fromEmail: "admin@dragatron.com.au",
      subject: "Your Immunisation Statement",
      bodyText: "Please find your Immunisation statement attached.",
      pdfBase64: pdfBase64,
    };

    setSendingEmail(true);
    try {
      const apiSendCertViaEmail = process.env.REACT_APP_SENDEMAIL_CERT_API_URL;
      if (!apiSendCertViaEmail) {
        throw new Error("REACT_APP_SENDEMAIL_CERT_API_URL is not defined");
      }
      const response = await fetch(apiSendCertViaEmail, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(emailData),
      });
      if (response.ok) {
        setSnackbarMessage("Email sent successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleCloseDialog();
      } else {
        setSnackbarMessage("Failed to send email");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error sending email");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setSendingEmail(false);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  if (isTokenLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (idTokenError) {
    return <div>Error loading authentication token: {idTokenError}</div>;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!pdfBase64) {
    return <div>{errorMessage || "No immunisation statement available."}</div>;
  }

  return (
    <div style={{ position: "relative", minHeight: "550px" }}>
      <iframe
        src={`data:application/pdf;base64,${pdfBase64}`}
        width="100%"
        height="500px"
        style={{ border: "none" }}
        title="Immunisation Statement"
      >
        This browser does not support PDFs. Please download the PDF to view it.
      </iframe>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        startIcon={<SendIcon />}
        style={{ position: "absolute", bottom: -5, right: 16 }}
      >
        Send Statement
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address to send the PDF to:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSendEmail}
            color="primary"
            disabled={loading}
            startIcon={<SendIcon />}
          >
            {sendingEmail ? <CircularProgress size={24} /> : "Send"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ImmunisationStatement;
