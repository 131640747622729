import AWS from "aws-sdk";

AWS.config.update({
  region: "ap-southeast-2",
  accessKeyId: "AKIAVRUVURRXANHZGNKH",
  secretAccessKey: "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",
});

//TODO: Change this to fetch it from API

const ssm = new AWS.SSM();

export interface ParameterData {
  access_token: string;
  scope: string;
  device_expiry?: string;
  key_expiry?: string;
  token_type: string;
  expires_in: number;
  Name: string;
  Type: string;
  Value: string;
  Version: number;
  LastModifiedDate: Date;
  ARN: string;
  DataType: string;
}

export const fetchParameter = async (parameterName: string): Promise<ParameterData> => {
  const params = {
    Name: parameterName,
    WithDecryption: true,
  };

  try {
    const data = await ssm.getParameter(params).promise();
    if (data.Parameter && data.Parameter.Value) {
      return JSON.parse(data.Parameter.Value) as ParameterData;
    } else {
      throw new Error("Parameter not found or invalid");
    }
  } catch (error) {
    console.error("Error fetching parameter:", error);
    throw error;
  }
};

export const fetchParametersByPath = async (path: string): Promise<ParameterData[]> => {
  const params = {
    Path: path,
    Recursive: true,
    WithDecryption: true,
  };

  try {
    const data = await ssm.getParametersByPath(params).promise();

    if (data.Parameters) {
      return data.Parameters.map((param) => ({
        Name: param.Name,
        Type: param.Type,
        Value: param.Value,
        Version: param.Version,
        LastModifiedDate: param.LastModifiedDate,
        ARN: param.ARN,
        DataType: param.DataType,
      })) as ParameterData[];
    } else {
      throw new Error("No parameters found for the given path");
    }
  } catch (error) {
    console.error("Error fetching parameters by path:", error);
    throw error;
  }
};
