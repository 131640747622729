import { IProduct } from './IProduct';
import { ITransactionProduct } from './ITransactionProduct';

export enum SaleProductStatus {
  ALLOW_SALE = 'ALLOW_SALE',
  SAFETY_SALE = 'SAFETY_SALE',
  DENY_SALE = 'DENY_SALE',
  RETURNED = 'RETURNED',
}

export interface IUpdateProductCartPayload {
  customerId: string;
  productID: string;
  notes: string;
  saleStatus: string;
  quantity: number;
  directionOfUse: string;
  notARTG: boolean;
  informedConsent: boolean;
  nicotineReplacement: boolean;
  prescribedOptions: boolean;
  quitDiscussed: boolean;
  idVerified: boolean;
  assessedMonthSupply: boolean;
  explainedStrengthRestrictions: boolean;
  recordedInSAS: boolean;
  checkboxes?: {
    notARTG?: boolean;
    informedConsent?: boolean;
    nicotineReplacement?: boolean;
    prescribedOptions?: boolean;
    quitDiscussed?: boolean;
    idVerified?: boolean;
    assessedMonthSupply?: boolean;
    explainedStrengthRestrictions?: boolean;
    recordedInSAS?: boolean;
  };
}

export interface ICartItem extends IProduct, ITransactionProduct {
  productName: string;
  nicotineConcentration: string | undefined;
  volume: number;
  notificationNumber: string;
  quantity: number;
  notes: string;
  saleStatus: SaleProductStatus;
  directionOfUse: string;
  stock: number;
  informedConsent: boolean;
  nicotineReplacement: boolean;
  prescribedOptions: boolean;
  quitDiscussed: boolean;
  idVerified: boolean;
  assessedMonthSupply: boolean;
  explainedStrengthRestrictions: boolean;
  notARTG: boolean;
  recordedInSAS: boolean;
  checkboxes?: {
    notARTG?: boolean;
    informedConsent?: boolean;
    nicotineReplacement?: boolean;
    prescribedOptions?: boolean;
    quitDiscussed?: boolean;
    idVerified?: boolean;
    assessedMonthSupply?: boolean;
    explainedStrengthRestrictions?: boolean;
    recordedInSAS?: boolean;
  };
  productType?: string;
  flavour?: string;
}

export interface ICart {
  customerId: string;
  items: ICartItem[];
}

export interface ICartPayload {
  customerId: string;
  item: ICartItem;
}

export interface IRemoveItemFromCartPayload {
  customerId: string;
  item: ICartItem;
}

export interface IClearCartItemByCustomerId extends Pick<IRemoveItemFromCartPayload, 'customerId'> {}
