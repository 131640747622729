import {
  Typography,
  Button,
  ButtonProps,
  CircularProgress,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { useAppointment } from "../../../../contexts/AppointmentContext";

interface ICandelAppointmentModalProps {
  onOk?: () => void;
  onCancel?: () => void;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  open: boolean;
  onHide?: () => void;
}

const CancelAppointmentModal = ({
  open,
  onHide,
  onOk,
  onCancel,
  okButtonProps,
  cancelButtonProps,
}: ICandelAppointmentModalProps) => {
  const { loading } = useAppointment();
  return (
    <Modal show={open} onHide={onHide}>
      <Modal.Header className="py-3 px-4 border-bottom-0" closeButton>
        <Typography variant="h5" color="primary" id="modal-title">
          Cancel Appointment
        </Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        <Typography variant="body1" color="primary" id="modal-title">
          Are you sure you want to cancel this appointment?
        </Typography>

        <div className="d-flex gap-1" style={{ marginTop: "50px" }}>
          <Button
            disabled={loading}
            onClick={onCancel}
            variant="outlined"
            color="primary"
            className="flex-grow-1"
            {...cancelButtonProps}
          >
            No
          </Button>
          <Button
            disabled={loading}
            type="button"
            variant="contained"
            color="error"
            className="flex-grow-1"
            onClick={onOk}
            {...okButtonProps}
          >
            {loading ? <CircularProgress size={24} /> : "Yes"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelAppointmentModal;
