import { Box, Paper } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const PpaPageWrapper = ({ children }: Props) => {
  return (
    <Box component="div" sx={{ p: 3 }}>
      <Paper
        sx={{
          p: "2rem",
          background: "white",
          minHeight: "80vh",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default PpaPageWrapper;
