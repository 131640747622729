import { useContext } from 'react';
import PharmacyContext from '../contexts/PharmacyContext';

interface PharmacyContextProps {
  pharmacyData: any;
  loading: boolean;
  error: string | null;
  id: string | null;
  pharmacyId: string | null;
  providerNumber: string | null;
  userId: string | null;
  userKey: string | null;
  isActive: boolean;
  createdDate: string | null;
  token: string | null;
  userKeyExpiry: string | null;
  isAdministrator: boolean;
  providerIdPPA: number | null;
  businessNamePPA: string | null;
  providerTypePPA: string | null;
  providerAddressPPA: string | null;
  providerAddress2PPA: string | null;
  providerSuburbPPA: string | null;
  providerPostcodePPA: string | null;
  HMR: number | null;
  Medscheck: number | null;
  RMMR: string | null;
  CVCP: number | null;
  ODT: number | null;
  NIPVIP: number | null;
}

export const usePharmacyField = <T extends keyof PharmacyContextProps>(field: T): PharmacyContextProps[T] => {
  const context = useContext(PharmacyContext);
  if (!context) {
    throw new Error('usePharmacyField must be used within a PharmacyProvider');
  }
  return context[field];
};
