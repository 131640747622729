import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useIdToken } from './useIdToken';
import { logger } from 'utils/logger';

type ID = string;
type AWSDate = string;
type AWSDateTime = string;

interface ClinicPatient {
  clinicPatientID: ID;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: AWSDate | null;
  medicareNumber: string | null;
  medicareIRN: string | null;
  status: string | null;
  notes: string | null;
  service: string | null;
  clinicID: ID;
  pharmacyID: ID;
  createdAt: AWSDateTime;
  updatedAt: AWSDateTime;
}

interface ClinicPatientsResponse {
  items: ClinicPatient[];
  nextToken: string | null;
}

const useClinicPatients = (clinicID: string) => {
  const [patients, setPatients] = useState<ClinicPatient[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { idToken, isLoading: isTokenLoading } = useIdToken();

  const fetchAllPatients = useCallback(async () => {
    if (isTokenLoading || !idToken) {
      setError('Authentication token is missing');
      setLoading(false);
      return;
    }

    setLoading(true);
    let allPatients: ClinicPatient[] = [];
    let nextToken: string | null = null;

    try {
      do {
        const response: { data: ClinicPatientsResponse } = await axios.post(
          `${process.env.REACT_APP_CLINIC_PATIENTS_BY_CLINIC_ID_URL}`,
          { clinicID, nextToken },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        logger.log('response', response);
        allPatients = [...allPatients, ...response.data.items];
        nextToken = response.data.nextToken;
      } while (nextToken);

      setPatients(allPatients);
      setError(null);
    } catch (err) {
      setError('Failed to fetch clinic patients');
      console.error('Error fetching clinic patients:', err);
    } finally {
      setLoading(false);
    }
  }, [clinicID, idToken, isTokenLoading]);

  useEffect(() => {
    if (clinicID && idToken && !isTokenLoading) {
      fetchAllPatients();
    }
  }, [clinicID, idToken, isTokenLoading, fetchAllPatients]);

  const refetch = useCallback(() => {
    if (clinicID && idToken && !isTokenLoading) {
      return fetchAllPatients();
    }
    return Promise.resolve();
  }, [clinicID, idToken, isTokenLoading, fetchAllPatients]);

  return { patients, loading, error, refetch };
};

export default useClinicPatients;
