import { ApolloError } from "@apollo/client";
import { Fragment } from "react";
import { useSnackbar } from "../contexts/SnackbarContext";
import { Button, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

type Props = {
  error: Error | ApolloError;
};

const ReportSnackbar = (props: Props) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <Fragment>
      <Button
        size="small"
        onClick={() => {
          const bodyString = `logs: ${props.error} \n page: ${window.location.href}`;
          window.open(
            `mailto:admin@dragatron.com.au?subject=Air Vaccination Problem&body=${bodyString}`
          );
          closeSnackbar();
        }}
      >
        REPORT
      </Button>
      <IconButton
        aria-label="close"
        color="inherit"
        sx={{ p: 0.5 }}
        onClick={closeSnackbar}
      >
        <CloseOutlined />
      </IconButton>
    </Fragment>
  );
};

export default ReportSnackbar;
