import { SagaIterator } from '@redux-saga/core';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { logger } from '../../utils/logger';
// apicore
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

import {
  cognitoSignOut,
  handleConfirmResetPassword,
  sendCode,
  signInUserWithEmail,
  signUpUserWithEmail,
  updateUserAttributes,
  verifyCode,
  handleSignInConfirmation,
} from '../../redux/auth/services/cognitoService';

// helpers
import { logout as logoutApi } from '../../helpers/';

// actions
import { ELoginStep, authApiResponseError, authApiResponseSuccess, setRegistrationStep } from './actions';

// constants
import { ConfirmResetPasswordInput, ConfirmSignUpInput, SignInInput } from 'aws-amplify/auth';
// import { Auth } from "aws-amplify";
import { AuthActionTypes } from './constants';
import { IProvider } from '../../interfaces';

interface UserData {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
    code: string;
    provider?: IProvider;
  };
  type: string;
}

interface ConfirmResetPasswordPayload {
  payload: ConfirmResetPasswordInput;
  type: string;
}
type userAttributes = {
  payload: {
    name: string;
    email: string;
    value: string;
  };
  type: string;
};
type SignUpParameters = {
  username: string;
  password: string;
  email: string;
  phone_number?: string; // Making phone_number optional
};
const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
  try {
    const signInInput: SignInInput = {
      username, // Assuming fullname is used as the username
      password,
    };
    logger.log('start login');
    const session = yield call(signInUserWithEmail, signInInput);
    logger.log(session, 'session');

    if (!session.signInStep) {
      api.setLoggedInUser(session);
      yield put(
        authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
          username,
          session,
        })
      );
    } else {
      // handle mfa
      yield put(
        authApiResponseSuccess(AuthActionTypes.LOGIN_WITH_MFA, {
          username,
          session,
        })
      );
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* confirmMfaLogin({ payload }: any): SagaIterator {
  try {
    logger.log('start confirm login', payload);
    const session = yield call(handleSignInConfirmation, payload);
    logger.log(session, 'session');

    api.setLoggedInUser(session);
    yield put(
      authApiResponseSuccess(AuthActionTypes.CONFIRM_LOGIN_USING_MFA, {
        session,
      })
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.CONFIRM_LOGIN_USING_MFA, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    yield call(cognitoSignOut);
    api.setLoggedInUser(null);
    api.setLoginStep(ELoginStep.FILL_FORM);
    setAuthorization(null);
    localStorage.removeItem('userToken'); // Remove the user token
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* verifyUser({ payload: { username, code } }: UserData): SagaIterator {
  try {
    const signupSignupInput: ConfirmSignUpInput = {
      username, // Assuming fullname is used as the username
      confirmationCode: code,
    };
    const response = yield call(verifyCode, signupSignupInput);
    logger.log('VERIFY USER RESPONSE::', response);

    yield put(authApiResponseSuccess(AuthActionTypes.VERIFY_USER, response));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.VERIFY_USER, error));
  }
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
  try {
    const signUpParams: SignUpParameters = {
      username: fullname, // Assuming fullname is used as the username
      email,
      password,
    };
    const response = yield call(signUpUserWithEmail, signUpParams);
    logger.log('response from cognito Signup', response);
    const userData = {
      username: email, // assuming response.user.username holds the username
      password: password, // You're using the password from the saga's payload
      email: email, // Using the email from the saga's payload
      fullname: fullname, // Using the fullname from the saga's payload
    };
    logger.log('response from cognito Signup', userData);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, userData));
    yield put(setRegistrationStep(1)); // Move to the next step after successful signup
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
  try {
    const response = yield call(sendCode, username); // Pass the username directly instead of an object
    logger.log('response forget password ', response);
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, {
        ...response,
        username,
      })
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

function* confirmResetPassword({
  payload: { username, confirmationCode, newPassword },
  type,
}: ConfirmResetPasswordPayload): SagaIterator {
  try {
    logger.log('payload confirm forget password saga');
    const response = yield call(handleConfirmResetPassword, {
      username,
      confirmationCode,
      newPassword,
    }); // Pass the username directly instead of an object
    logger.log('response confirm forget password saga', response);
    yield put(
      authApiResponseSuccess(AuthActionTypes.CONFIRM_RESET_PASSWORD, {
        response,
      })
    );
  } catch (error: any) {
    console.error('Error confirm forgot password saga ', error);
    yield put(authApiResponseError(AuthActionTypes.CONFIRM_RESET_PASSWORD, error));
  }
}

function* updateAttributes({ payload: { attributes } }: any): SagaIterator {
  try {
    logger.log('attributes', attributes);
    const response = yield call(updateUserAttributes, attributes);
    yield put(authApiResponseSuccess(AuthActionTypes.UPDATE_USER_ATTRIBUTES, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.UPDATE_USER_ATTRIBUTES, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}
export function* watchVerify(): any {
  yield takeEvery(AuthActionTypes.VERIFY_USER, verifyUser);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchConfirmResetPassword(): any {
  yield takeEvery(AuthActionTypes.CONFIRM_RESET_PASSWORD, confirmResetPassword);
}

export function* watchUpdateAttributes(): any {
  yield takeEvery(AuthActionTypes.UPDATE_USER_ATTRIBUTES, updateAttributes);
}

export function* watchConfirmLoginUser(): any {
  yield takeEvery(AuthActionTypes.CONFIRM_LOGIN_USING_MFA, confirmMfaLogin);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchVerify),
    fork(watchUpdateAttributes),
    fork(watchConfirmResetPassword),
    fork(watchConfirmLoginUser),
  ]);
}

export default authSaga;
