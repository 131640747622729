import { useState, useEffect } from 'react';
import { Dayjs } from 'dayjs';
import axios from 'axios';
import { useCustomer } from '../contexts/CustomerContext';
import { logger } from '../utils/logger';
import { PatientFollowUp } from '../types/PatientFollowUp';

export const usePatientFollowUps = (pharmacyID: string, selectedDate: Dayjs) => {
  const [patientFollowUps, setPatientFollowUps] = useState<PatientFollowUp[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const { state } = useCustomer();

  const fetchData = async () => {
    if (!state.customer?.patientID) {
      setPatientFollowUps([]);
      setHasMore(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(process.env.REACT_APP_PATIENT_FOLLOWUPS_BY_PATIENT_ID_URL || '', {
        patientID: state.customer.patientID,
      });

      setPatientFollowUps(response.data);
      setHasMore(response.data.length > 0);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred'));
      logger.error('Error fetching patient follow-ups:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pharmacyID, selectedDate, state.customer?.patientID]);

  const fetchMore = () => {
    logger.log('Fetching more data...');
  };

  return {
    patientFollowUps,
    isLoading,
    error,
    fetchMore,
    hasMore,
    refetch: fetchData,
  };
};
