export const DISCLAIMER_TEXT =
  'This Certificate for Leave ("Document") has been created by Dragatron PTY LTD ("Dragatron") in an electronic format, adhering to the guidelines established by the Pharmaceutical Society of Australia and the Pharmacy Guild of Australia for pharmacists issuing certificates for absence from work (October 2010). The Document must be utilized in accordance with these guidelines, as well as other relevant industry standards, codes, regulations, and laws. Pharmacists are expected to exercise professional judgement when using this Document, including making necessary adaptations to suit specific situations. Dragatron disclaims any liability for any loss or damage incurred by any person relying on this Document or the information contained within it.';

export const PDF_STYLES = {
  a4Style: {
    width: '210mm',
    minHeight: '297mm',
    margin: 'auto',
    padding: '10mm',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  } as React.CSSProperties,
};

export const EMAIL_CONSTANTS = {
  FROM_EMAIL: 'admin@dragatron.com.au',
  SUBJECT: 'Your Leave Certificate',
  BODY_TEXT: 'Please find your leave certificate attached.',
};
