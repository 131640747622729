// src/hooks/useIdToken.ts
import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

export function useIdToken() {
    const [idToken, setIdToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        let isMounted = true;

        async function fetchToken() {
            try {
                const { tokens } = await fetchAuthSession();
                if (isMounted && tokens?.idToken) {
                    setIdToken(tokens.idToken.toString());
                    setIsLoading(false);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err instanceof Error ? err : new Error('An error occurred'));
                    setIsLoading(false);
                }
            }
        }

        fetchToken();

        return () => {
            isMounted = false;
        };
    }, []);

    return { idToken, isLoading, error };
}
