import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookExecOptions,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import {
  BookingsByPharmacyIdQuery,
  BookingsByPharmacyIdQueryVariables,
  CreateBookingMutation,
  CreateBookingMutationVariables,
  CreateBookingsInput,
  Exact,
  GetBookingQuery,
  GetBookingQueryVariables,
  InputMaybe,
  ListBookingQuery,
  ListBookingQueryVariables,
  TableBookingsFilterInput,
  UpdateBookingMutation,
  UpdateBookingMutationVariables,
  UpdateBookingsInput,
} from '../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import { GET_BOOKING, LIST_BOOKING, QUERY_BOOKINGS_BY_PHARMACY_ID } from '../codegens/HEALTCARE-RESERVATION/queries';
import { GRAPHQL_DESTINATION } from '../ApolloClient';
import { useContext } from 'react';
import AuthContext from '../contexts/AuthContext';
import { MUTATION_CREATE_BOOKING, MUTATION_UPDATE_BOOKING } from '../codegens/HEALTCARE-RESERVATION/mutations';
import { Moment } from 'moment';
import { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

export const useBookings = () => {
  const { pharmacyID } = useContext(AuthContext);
  const [queryBookings, { error, loading }] = useLazyQuery<
    BookingsByPharmacyIdQuery,
    BookingsByPharmacyIdQueryVariables
  >(QUERY_BOOKINGS_BY_PHARMACY_ID, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const [listBookings, { error: errorListBooking, loading: loadingListBooking }] = useLazyQuery<
    ListBookingQuery,
    ListBookingQueryVariables
  >(LIST_BOOKING, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const [mutationCreatebooking, { error: errorSaveBooking, loading: loadingSaveBooking, data: createdBooking }] =
    useMutation<CreateBookingMutation, CreateBookingMutationVariables>(MUTATION_CREATE_BOOKING, {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    });

  const [mutationUpdatebooking, { error: errorUpdateBooking, loading: loadingUpdateBooking, data: updatedBooking }] =
    useMutation<UpdateBookingMutation, UpdateBookingMutationVariables>(MUTATION_UPDATE_BOOKING, {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    });

  const [queryGetBooking, { error: errorGetBooking, loading: loadingGetBooking }] = useLazyQuery<
    GetBookingQuery,
    GetBookingQueryVariables
  >(GET_BOOKING, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const fetchBookings = (
    options?: Partial<
      LazyQueryHookExecOptions<
        BookingsByPharmacyIdQuery,
        Exact<{
          after?: InputMaybe<string> | undefined;
          first?: InputMaybe<number> | undefined;
          pharmacyID: string;
        }>
      >
    >
  ) => {
    return queryBookings({
      ...options,
      fetchPolicy: 'network-only',
      variables: {
        pharmacyID,
        ...options?.variables,
      },
    });
  };

  const getBooking = (
    options: Partial<
      LazyQueryHookExecOptions<
        GetBookingQuery,
        Exact<{
          bookingID: string;
        }>
      >
    >
  ) => {
    return queryGetBooking({
      ...options,
      fetchPolicy: 'network-only',
    });
  };

  const saveBooking = (
    options: MutationFunctionOptions<
      CreateBookingMutation,
      Exact<{
        input: CreateBookingsInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return mutationCreatebooking({
      ...options,
      fetchPolicy: 'network-only',
    });
  };

  const updateBooking = (
    options: MutationFunctionOptions<
      UpdateBookingMutation,
      Exact<{
        input: UpdateBookingsInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return mutationUpdatebooking({
      ...options,
      fetchPolicy: 'network-only',
    });
  };

  const getBookingsByDay = (
    date: Moment | Dayjs,
    options?: Partial<
      LazyQueryHookExecOptions<
        ListBookingQuery,
        Exact<{
          filter?: InputMaybe<TableBookingsFilterInput> | undefined;
          limit?: InputMaybe<number> | undefined;
          nextToken?: InputMaybe<string> | undefined;
        }>
      >
    >
  ) => {
    const convertedTimezoneDate = moment(date.toISOString()).clone();
    const startingDay = convertedTimezoneDate.startOf('day').format();
    const endOfDay = convertedTimezoneDate.endOf('day').format();

    return listBookings({
      fetchPolicy: 'network-only',
      ...options,
      variables: {
        filter: {
          pharmacyID: {
            eq: pharmacyID,
          },
          timeslot: {
            between: [startingDay, endOfDay],
          },
        },
      },
    });
  };

  const getBookingsByMonth = (
    startDate: Moment | Dayjs,
    options?: Partial<
      LazyQueryHookExecOptions<
        ListBookingQuery,
        Exact<{
          filter?: InputMaybe<TableBookingsFilterInput> | undefined;
          limit?: InputMaybe<number> | undefined;
          nextToken?: InputMaybe<string> | undefined;
        }>
      >
    >
  ) => {
    const convertedTimezoneDate = moment(startDate.toISOString()).clone().tz('Australia/Sydney');

    const startingMonth = convertedTimezoneDate.clone().startOf('month').subtract(1, 'day').toISOString();
    const endOfMonth = convertedTimezoneDate.clone().endOf('month').add(1, 'day').toISOString();

    return listBookings({
      fetchPolicy: 'network-only',
      ...options,
      variables: {
        filter: {
          pharmacyID: {
            eq: pharmacyID,
          },
          timeslot: {
            between: [startingMonth, endOfMonth],
          },
        },
      },
    });
  };

  return {
    fetchBookings,
    loadingFectBookings: loading,
    errorFetchBookings: error,
    getBooking,
    errorGetBooking,
    loadingGetBooking,
    saveBooking,
    loadingSaveBooking,
    errorSaveBooking,
    createdBooking,
    updateBooking,
    loadingUpdateBooking,
    errorUpdateBooking,
    updatedBooking,
    getBookingsByDay,
    errorGetBookingsByDay: errorListBooking,
    loadingGetBookingByDay: loadingListBooking,
    getBookingsByMonth,
    errorGetBookingsByMonth: errorListBooking,
    loadingGetBookingsByMonth: loadingListBooking,
  };
};
