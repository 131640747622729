import { HTMLAttributes, ReactNode, forwardRef, useEffect } from "react";
import { cn } from "../../utils/util";

type Props = {
  children: ReactNode;
  title?: string;
} & HTMLAttributes<HTMLElement>;

const PageWrapper = forwardRef<HTMLElement, Props>((props: Props, ref) => {
  const { children, title, className } = props;
  const prefix = "Dragatron MA | ";

  useEffect(() => {
    document.title = title ? `${prefix} ${title}` : `${prefix} Default Title`;
  }, [title]);
  return (
    <section className={cn(className)} ref={ref}>
      {children}
    </section>
  );
});

export default PageWrapper;
