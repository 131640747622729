import React, { useState, useContext } from 'react';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LicenseInfo } from '@mui/x-license-pro';
import { AddOutlined, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useWalkInAppointments } from '../../../hooks/useWalkInAppointments';
import { WalkInAppointment } from '../../../types/WalkInAppointment';
import AuthContext from '../../../contexts/AuthContext';

// Add this type definition
type CheckinStatus = 'New' | 'CheckedIn' | 'InProgress' | 'Cancelled' | 'Ready' | 'Completed' | 'NoShow';

// Update the WalkInAppointment type
type NewWalkInAppointment = Omit<WalkInAppointment, 'id' | 'service'> & {
  service?: WalkInAppointment['service'];
  status: CheckinStatus;
};

LicenseInfo.setLicenseKey(
  '54496d9a2f15ea1becbc1387a0b46140Tz04NTIwMSxFPTE3NDA2OTUwMDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const Walkins: React.FC = () => {
  const { appointments, loading, error, addAppointment, updateAppointment, deleteAppointment } =
    useWalkInAppointments();
  const { pharmacyID } = useContext(AuthContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState<string | null>(null);
  const [newAppointment, setNewAppointment] = useState<NewWalkInAppointment>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    date: dayjs().format('YYYY-MM-DD'),
    time: dayjs().format('HH:mm'),
    service: undefined,
    postcode: '',
    hasMedicare: 'No',
    medicareNumber: '',
    medicareIRN: '',
    status: 'New' as CheckinStatus,
    pharmacyID: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleAddOrUpdateAppointment = async () => {
    if (!newAppointment.service) {
      setErrorMessage('Please select a service');
      return;
    }

    const appointmentToSubmit: Omit<WalkInAppointment, 'id'> = {
      ...newAppointment,
      service: newAppointment.service,
      pharmacyID: pharmacyID,
    };

    setIsSubmitting(true);
    try {
      if (editingAppointment) {
        await updateAppointment(editingAppointment, appointmentToSubmit);
        setSnackbar({ open: true, message: 'Appointment updated successfully', severity: 'success' });
      } else {
        await addAppointment(appointmentToSubmit);
        setSnackbar({ open: true, message: 'Appointment added successfully', severity: 'success' });
      }
      setIsModalOpen(false);
      setEditingAppointment(null);
      setNewAppointment({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        date: dayjs().format('YYYY-MM-DD'),
        time: dayjs().format('HH:mm'),
        service: undefined,
        postcode: '',
        hasMedicare: 'No',
        medicareNumber: '',
        medicareIRN: '',
        status: 'New' as CheckinStatus,
        pharmacyID: pharmacyID,
      });
    } catch (error) {
      console.error('Error submitting appointment:', error);
      setSnackbar({ open: true, message: 'Failed to submit appointment', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditAppointment = (id: string) => {
    const appointmentToEdit = appointments.find((app) => app.id === id);
    if (appointmentToEdit) {
      setNewAppointment(appointmentToEdit);
      setEditingAppointment(id);
      setIsModalOpen(true);
    }
  };

  const handleDeleteAppointment = (id: string) => {
    deleteAppointment(id);
  };

  const handleStatusChange = async (id: string, newStatus: CheckinStatus) => {
    try {
      await updateAppointment(id, { status: newStatus });
    } catch (error) {
      console.error('Failed to update status:', error);
      // Optionally, show an error message to the user
    }
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
      flex: 1,
      valueFormatter: (params) => {
        return dayjs(params.value).format('DD/MM/YYYY');
      },
    },
    { field: 'time', headerName: 'Time', flex: 1 },
    { field: 'service', headerName: 'Service', flex: 1 },
    { field: 'postcode', headerName: 'Postcode', flex: 1 },
    { field: 'hasMedicare', headerName: 'Has Medicare', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Select
          value={params.value}
          onChange={(e) => handleStatusChange(params.row.id, e.target.value as CheckinStatus)}
          sx={{ width: '100%' }}
        >
          <MenuItem value="New">New</MenuItem>
          <MenuItem value="CheckedIn">Checked-In</MenuItem>
          <MenuItem value="InProgress">In Progress</MenuItem>
          <MenuItem value="Cancelled">Cancelled</MenuItem>
          <MenuItem value="Ready">Ready</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
          <MenuItem value="NoShow">No Show</MenuItem>
        </Select>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <IconButton onClick={() => handleEditAppointment(params.row.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteAppointment(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box>Error: {error.message}</Box>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper
        sx={{
          p: '2rem',
          background: 'white',
          minHeight: '80vh',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Walk-in Appointments</Typography>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<AddOutlined />}
            onClick={() => {
              setEditingAppointment(null);
              setNewAppointment({
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                date: dayjs().format('YYYY-MM-DD'),
                time: dayjs().format('HH:mm'),
                service: undefined,
                postcode: '',
                hasMedicare: 'No',
                medicareNumber: '',
                medicareIRN: '',
                status: 'New' as CheckinStatus,
                pharmacyID: pharmacyID,
              });
              setIsModalOpen(true);
            }}
          >
            Add New Walk-in
          </Button>
        </Box>

        <Box sx={{ height: 600, width: '100%' }}>
          <DataGridPro
            rows={appointments}
            columns={columns}
            disableRowSelectionOnClick
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#e2f1f0',
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#05153f',
                },
              },
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
            }}
          />
        </Box>
      </Paper>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {editingAppointment ? 'Edit Walk-in Appointment' : 'Add New Walk-in Appointment'}
          </Typography>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="First Name"
              value={newAppointment.firstName}
              onChange={(e) => setNewAppointment({ ...newAppointment, firstName: e.target.value })}
              fullWidth
            />
            <TextField
              label="Last Name"
              value={newAppointment.lastName}
              onChange={(e) => setNewAppointment({ ...newAppointment, lastName: e.target.value })}
              fullWidth
            />
            <DatePicker
              label="Date of Birth"
              value={newAppointment.dateOfBirth ? dayjs(newAppointment.dateOfBirth) : null}
              onChange={(date) =>
                setNewAppointment({ ...newAppointment, dateOfBirth: date?.format('YYYY-MM-DD') || '' })
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  inputProps: {
                    placeholder: 'DD/MM/YYYY',
                  },
                },
              }}
              format="DD/MM/YYYY"
            />
            <TimePicker
              label="Time"
              value={dayjs(newAppointment.time, 'HH:mm')}
              onChange={(time) => setNewAppointment({ ...newAppointment, time: time?.format('HH:mm') || '' })}
              slotProps={{ textField: { fullWidth: true } }}
            />
            <FormControl fullWidth>
              <InputLabel id="service-label">Service</InputLabel>
              <Select
                labelId="service-label"
                value={newAppointment.service || ''}
                onChange={(e) => {
                  setNewAppointment({
                    ...newAppointment,
                    service: e.target.value as WalkInAppointment['service'],
                  });
                  setErrorMessage(null); // Clear error message when a service is selected
                }}
                label="Service"
              >
                <MenuItem value="" disabled>
                  Select a service
                </MenuItem>
                <MenuItem value="Vaccination">Vaccination</MenuItem>
                <MenuItem value="Leave Certificate">Leave Certificate</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Postcode"
              value={newAppointment.postcode}
              onChange={(e) => setNewAppointment({ ...newAppointment, postcode: e.target.value })}
              fullWidth
            />
            <Divider />
            <FormControl component="fieldset">
              <Typography variant="subtitle1">Have Medicare Details?</Typography>
              <RadioGroup
                row
                value={newAppointment.hasMedicare}
                onChange={(e) => setNewAppointment({ ...newAppointment, hasMedicare: e.target.value as 'Yes' | 'No' })}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {newAppointment.hasMedicare === 'Yes' && (
              <>
                <TextField
                  label="Medicare Number"
                  value={newAppointment.medicareNumber}
                  onChange={(e) => setNewAppointment({ ...newAppointment, medicareNumber: e.target.value })}
                  fullWidth
                />
                <TextField
                  label="Medicare IRN"
                  value={newAppointment.medicareIRN}
                  onChange={(e) => setNewAppointment({ ...newAppointment, medicareIRN: e.target.value })}
                  fullWidth
                />
              </>
            )}
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                value={newAppointment.status}
                onChange={(e) =>
                  setNewAppointment({
                    ...newAppointment,
                    status: e.target.value as CheckinStatus,
                  })
                }
                label="Status"
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="CheckedIn">Checked-In</MenuItem>
                <MenuItem value="InProgress">In Progress</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
                <MenuItem value="Ready">Ready</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="NoShow">No Show</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleAddOrUpdateAppointment} disabled={isSubmitting}>
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : editingAppointment ? (
                'Update Appointment'
              ) : (
                'Add Appointment'
              )}
            </Button>
            {errorMessage && (
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Box>
      </Modal>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Walkins;
