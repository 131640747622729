// VaccineInformationSheet.tsx
import React, { forwardRef, useContext } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import AuthContext from '../contexts/AuthContext';
interface VaccineInformationSheetProps {
  patientDetails: any; // Replace with proper type
}

const VaccineInformationSheet = forwardRef<HTMLDivElement, VaccineInformationSheetProps>(({ patientDetails }, ref) => {
  dayjs.extend(customParseFormat);
  const { pharmacyID, pharmacyName, providerStore, providerFirstName, providerLastName, providerAHPRA } =
    useContext(AuthContext);

  const formatDate = (dateString: string) => {
    let formattedDate;
    if (dateString.includes('-')) {
      // Format: "2024-09-05"
      formattedDate = dayjs(dateString);
    } else {
      // Format: "05092024"
      formattedDate = dayjs(dateString, 'DDMMYYYY');
    }
    return formattedDate.isValid() ? formattedDate.format('MMMM D, YYYY') : 'Invalid Date';
  };
  return (
    <Box ref={ref} sx={{ fontFamily: 'Montserrat, sans-serif', padding: 0, backgroundColor: '#f4f4f4' }}>
      <Box sx={{ maxWidth: '600px', margin: 'auto', backgroundColor: '#fff' }}>
        <Box
          sx={{
            backgroundImage: `url('https://d26yxs6y39effq.cloudfront.net/eyJidWNrZXQiOiJzM3VwbG9hZGVyLXMzdXBsb2FkYnVja2V0LWtqNWt4aG92ZmhxYiIsImtleSI6ImJnLWVtYWlsaWZ5LXZhY2NpbmUucG5nIn0=')`,
            height: '286px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            padding: '2.3rem',
          }}
        >
          <Paper sx={{ backgroundColor: '#fff', height: '300px', borderRadius: '20px', padding: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <img
                src="https://d26yxs6y39effq.cloudfront.net/eyJidWNrZXQiOiJzM3VwbG9hZGVyLXMzdXBsb2FkYnVja2V0LWtqNWt4aG92ZmhxYiIsImtleSI6ImVtYWlsL2RyYWdhdHJvbi1pY29uLnBuZyJ9"
                alt="logo"
                style={{ width: '6rem' }}
              />
            </Box>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <img
                src="https://d26yxs6y39effq.cloudfront.net/eyJidWNrZXQiOiJzM3VwbG9hZGVyLXMzdXBsb2FkYnVja2V0LWtqNWt4aG92ZmhxYiIsImtleSI6IkVtYWlsaWZ5X3ZhY2NpbmVfaW5mb3JtYXRpb25fc2hlZXQucG5nIn0="
                alt=""
                style={{ width: '15rem' }}
              />
              {/* <Typography variant="h4" sx={{ color: '#000000', fontWeight: 700, mt: 1 }}>
                Vaccine Information Sheet
              </Typography> */}
            </Box>
          </Paper>
        </Box>

        <Box sx={{ padding: '20px 50px', mt: 10 }}>
          <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, mb: 1 }}>
            Dear{' '}
            <span style={{ fontWeight: 600 }}>
              {patientDetails.firstName} {patientDetails.lastName},
            </span>
          </Typography>

          <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, mb: 2 }}>
            Thank you for receiving your vaccination at <span style={{ fontWeight: 600 }}>{pharmacyName}. </span>
            This document contains important information about your recent vaccination.Please read all information
            carefully and keep it for your records.
            {/* including links to detailed Consumer Medicine Information (CMI) and Product
            Information (PI). Please read all information carefully and keep it for your records. */}
          </Typography>

          <Box className="card-info" sx={{ backgroundColor: '#D0E1FE', padding: 2, borderRadius: '20px', mb: 3 }}>
            <Typography sx={{ color: '#2196F3', fontSize: '1rem', fontWeight: 700, textAlign: 'center', mb: 2 }}>
              Vaccination Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400 }}>
                  Date of Vaccination
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 600 }}>
                  {formatDate(patientDetails.encounterDate)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400 }}>
                  Vaccine Administered
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 600 }}>
                  {patientDetails.vaccineBrand}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400 }}>
                  Next Dose Due (if applicable)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 600 }}>
                  {patientDetails.nextDoseDue || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Typography sx={{ color: '#2196F3', fontSize: '1rem', fontWeight: 700, textAlign: 'center', mt: 4, mb: 2 }}>
            Important Vaccine Information
          </Typography>

          <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, mb: 2 }}>
            For detailed information about your vaccine, please visit
            https://immunisationhandbook.health.gov.au/vaccines and search for : {patientDetails.vaccineBrand}
          </Typography>
          {/* 
          <ol style={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, paddingLeft: '20px' }}>
            <li>Consumer Medicine Information (CMI): [Insert link to CMI]</li>
            <li>Product Information (PI): [Insert link to PI]</li>
          </ol> */}

          {/* <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, mb: 2 }}>
            These documents provide comprehensive information about the vaccine, including its ingredients, potential
            side effects, and contraindications. We strongly encourage you to read them carefully.
          </Typography> */}

          <Typography sx={{ color: '#2196F3', fontSize: '1rem', fontWeight: 700, textAlign: 'center', mt: 4, mb: 2 }}>
            Post-Vaccination Information
          </Typography>

          <ol style={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, paddingLeft: '20px' }}>
            <li style={{ marginBottom: '1.0rem' }}>
              <span style={{ fontWeight: 600 }}>Observation Period:</span> You were observed for the recommended period
              after your vaccination. If you experience any immediate side effects after leaving the pharmacy, please
              seek medical attention.
            </li>
            <li style={{ marginBottom: '1.0rem' }}>
              <span style={{ fontWeight: 600 }}>Common Side Effects:</span> You may experience some side effects, which
              are usually mild and resolve within a few days. These may include pain at the injection site, mild fever,
              fatigue, headache, or muscle pain. Please refer to the CMI (Consumer Medicine Information) for a full list of potential side effects.
            </li>
            <li style={{ marginBottom: '1.0rem' }}>
              <span style={{ fontWeight: 600 }}>Serious Side Effects:</span> While rare, seek immediate medical
              attention if you experience any severe symptoms. These are detailed in the CMI (Consumer Medicine Information) , but may include:
              <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                <li>- Difficulty breathing</li>
                <li>- Swelling of the face or throat</li>
                <li>- Rapid heartbeat</li>
                <li>- Severe dizziness or weakness</li>
                <li>- Any other unusual or severe symptoms</li>
              </ul>
            </li>
            <li style={{ marginBottom: '1.0rem' }}>
              <span style={{ fontWeight: 600 }}>Follow-up Care:</span>
              <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                <li>- Apply a cold, wet cloth to the injection site if you experience discomfort</li>
                <li>- Rest and drink plenty of fluids</li>
                <li>- You may take paracetamol for pain or fever if needed</li>
                <li>- Follow any specific care instructions provided in the CMI (Consumer Medicine Information)</li>
              </ul>
            </li>
            <li style={{ marginBottom: '1.0rem' }}>
              <span style={{ fontWeight: 600 }}>Report Side Effects:</span> Report any side effects to the TGA
              (Therapeutic Goods Administration) via www.tga.gov.au/reporting-problems
            </li>
            <li style={{ marginBottom: '1.0rem' }}>
              <span style={{ fontWeight: 600 }}>Questions or Concerns:</span> If you have any questions or concerns
              after reviewing the CMI, please contact:
              <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                <li>- Our pharmacy: {patientDetails.pharmacyPhoneNumber}</li>
                <li>- Your GP</li>
                <li>- Health Direct (24/7): 1800 022 222</li>
              </ul>
            </li>
            <li style={{ marginBottom: '1.0rem' }}>
              <span style={{ fontWeight: 600 }}>Vaccination Record:</span> Your vaccination has been recorded in the
              Australian Immunisation Register (AIR).
            </li>
          </ol>

          <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, mt: 2 }}>
            Remember, it takes time for your body to build protection after vaccination. Continue to follow current
            health advice to protect yourself and others.
          </Typography>

          <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, mt: 2 }}>
            Thank you for choosing to vaccinate and contribute to community health!
          </Typography>

          <Typography sx={{ color: '#5E5E5E', fontSize: '0.875rem', fontWeight: 400, mt: 4 }}>
            Best regards,
            <br />
            {pharmacyName}
            <br />
            {providerStore}
            <br />
            {providerFirstName} {providerLastName}
            <br />
            {providerAHPRA}
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '20px 50px',
            textAlign: 'center',
            fontSize: '12px',
            color: '#333333',
          }}
        >
          <Typography sx={{ fontSize: '0.75rem', color: '#666666', fontStyle: 'italic' }}>
            Disclaimer: This Vaccine Information Sheet is generated using a technical service provided by Dragatron.
            Dragatron is not a healthcare provider and does not administer vaccines. All medical services, including
            vaccinations, are provided solely by {pharmacyName}. For any medical questions or concerns, please contact :{' '}
            {pharmacyName} directly or consult with your healthcare provider.
          </Typography>
          <Typography sx={{ fontSize: '12px', color: '#333333' }}>
            Note: This email is an automated notification. Please do not reply to this email. If you require assistance,
            please contact our support team at
            <span style={{ color: '#00A0AA' }}> support@dragatron.com</span>.
          </Typography>
        </Box>

        <Box
          sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#f4f4f4', fontSize: '14px', color: '#666666' }}
        >
          <Typography sx={{ fontSize: '14px', color: '#666666' }}>
            36/7 Narabang Way, Belrose NSW 2085, Australia
          </Typography>
          <Typography sx={{ fontSize: '14px', color: '#666666' }}>
            &copy; {new Date().getFullYear()} dragatron
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});

export default VaccineInformationSheet;
