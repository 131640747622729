import { ReactNode, useEffect, useState } from 'react';
import { IService } from '../../../../interfaces/IService';
import { useServices } from '../../../../hooks/useServices';
import {
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  Tooltip,
} from '@mui/material';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import { ISelectServiceProps } from './interfaces';
import { uuid } from '../../../../utils';
import { useAppointment } from '../../../../contexts/AppointmentContext';
import { logger } from 'utils/logger';

const SelectService = ({ onSelect, defaultValue }: ISelectServiceProps) => {
  const { fetchServices, errorFetchServices, loadingFetchServices } = useServices();
  const [service, setService] = useState<string>('');
  const [services, setServices] = useState<IService[]>([]);
  const [open, setOpen] = useState(false);
  const { setSelectedService, loading, setLoading } = useAppointment();

  useEffect(() => {
    fetchServices({
      onCompleted(data) {
        const services = data.queryServicesByPharmacyIDIndex?.items as IService[];
        // logger.log(services, 'services')
        const sortedServices = [...services].sort((a, b) => a.serviceName.localeCompare(b.serviceName));
        // logger.log(sortedServices, 'sortedServices')
        setServices(sortedServices);

        // set default value for edit mode
        if (defaultValue) {
          const activeService = services.find((service) => service.isActive);
          const defaultService = services.find((service) => service.serviceId === defaultValue);

          setService(defaultValue ? defaultValue : activeService ? activeService.serviceId : services[0].serviceId);
          onSelect(defaultService || activeService || services[0]);

          setSelectedService(defaultService || activeService || services[0]);
        }
      },
    });
  }, []);

  function handleChangeSelect(e: SelectChangeEvent<string>, _child: ReactNode) {
    const { value } = e.target;
    setService(value);

    const selected = services.find((service) => service.serviceId === value);

    onSelect(selected ? selected : null);
    setSelectedService(selected || null);
    setLoading(true);
  }

  // useEffect(() => {
  //   if (defaultValue) {
  //     setService(defaultValue || '');
  //   }
  // }, [defaultValue]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (loadingFetchServices) {
    return (
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="services">Services</InputLabel>
        <Select
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          fullWidth
          labelId="services"
          name="service"
          value=""
          IconComponent={() => (
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(!open)}>
                <CircularProgress size={24} />
              </IconButton>
            </InputAdornment>
          )}
          label="Services"
        >
          <MenuItem
            sx={{
              py: '1rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={24} />
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  if (errorFetchServices) {
    return (
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="services">Services</InputLabel>
        <Select
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          fullWidth
          labelId="services"
          name="service"
          value=""
          IconComponent={() => (
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(!open)}>
                <ArrowDropDownOutlined />
              </IconButton>
            </InputAdornment>
          )}
          label="Services"
        >
          <MenuItem
            sx={{
              py: '1rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            No service available
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  if (services.length === 0 && !loadingFetchServices) {
    return (
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="services">Services</InputLabel>
        <Select
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          fullWidth
          labelId="services"
          name="service"
          value=""
          IconComponent={() => (
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(!open)}>
                <ArrowDropDownOutlined />
              </IconButton>
            </InputAdornment>
          )}
          label="Services"
        >
          <MenuItem
            sx={{
              py: '1rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            No service available
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id="services">Services</InputLabel>
      <Select
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disabled={loading}
        fullWidth
        labelId="services"
        id="services"
        name="service"
        value={service}
        IconComponent={() => (
          <InputAdornment position="end">
            <IconButton color="primary" onClick={() => setOpen(!open)}>
              <ArrowDropDownOutlined />
            </IconButton>
          </InputAdornment>
        )}
        label="Services"
        onChange={handleChangeSelect}
      >
        {services.map((service) => (
          <MenuItem
            value={service.serviceId}
            disabled={!service.isActive || loading}
            sx={{ py: '1rem' }}
            key={service.serviceId}
          >
            <Tooltip title={!service.isActive ? 'Inactive service' : ''}>
              <Typography>{service.serviceName}</Typography>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectService;
