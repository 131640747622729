import { ReactNode, useContext, useEffect } from "react";
import { QUERY_GET_PROVIDER_AUTHORIZATION } from "../../graphql-queries";
import { useLazyQuery } from "@apollo/client";
import AuthContext from "../../contexts/AuthContext";
import UnauthorizeContent from "../../components/UnauthorizeContent";
import LoadingContent from "../../components/LoadingContent";
import { Box, Button, Container, Typography } from "@mui/material";
import { SearchOffOutlined } from "@mui/icons-material";
import ReportOutlined from "@mui/icons-material/ReportOutlined";
import { PurchaseProvider } from "../../contexts/ProductSaleContext";

type Props = {
  children: ReactNode;
};

interface AuthorizationResult {
  isActive: boolean;
  isAdministrator: boolean;
  ppaClaims: boolean;
  pseudoWatchAccess: boolean;
}

const AdministratorAuthorizationWrapper = ({ children }: Props) => {
  const { pharmacistID } = useContext(AuthContext);
  const [fetchAuthorization, { data, loading, error }] = useLazyQuery(
    QUERY_GET_PROVIDER_AUTHORIZATION,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (pharmacistID) {
      fetchAuthorization({
        variables: {
          pharmacistId: pharmacistID,
        },
        fetchPolicy: "no-cache",
      });
    }
  }, [pharmacistID, fetchAuthorization]);

  if (loading) return <LoadingContent text="Authorizing..." />;

  if (!data && !loading) return <UnauthorizeContent />;

  if (!data.getProvider && !loading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "60vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
          <SearchOffOutlined
            sx={{ fontSize: 40, color: "primary.main", mb: 3 }}
          />
          <Typography variant="h4" gutterBottom>
            Account Not Found
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Sorry, We could not find your account. Please contact your
            administrator if you believe this is a mistake, or Feel free to
            report this problem.
          </Typography>
          <Box mt={4}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ReportOutlined />}
              onClick={() => {
                const bodyString = `Error: ${JSON.stringify(error)} \n page: ${
                  window.location.href
                } \n Result Query: ${JSON.stringify(
                  data
                )} \n Query Used: ${JSON.stringify(
                  QUERY_GET_PROVIDER_AUTHORIZATION.loc?.source
                )}`;
                const encodedBodyString = encodeURIComponent(
                  bodyString
                ).replace(/%0A/g, "%0D%0A");

                window.open(
                  `mailto:admin@dragatron.com.au?subject=Air Vaccination Problem&body=${encodedBodyString}`
                );
              }}
            >
              Report
            </Button>
          </Box>
        </Container>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "60vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
          <SearchOffOutlined
            sx={{ fontSize: 40, color: "primary.main", mb: 3 }}
          />
          <Typography variant="h4" gutterBottom>
            Account Not Found
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Sorry, We having a problem autorizing your account. Feel free to
            report this problem.
          </Typography>
          <Box mt={4}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ReportOutlined />}
              onClick={() => {
                const bodyString = `Error: ${JSON.stringify(error)} \n page: ${
                  window.location.href
                } \n Result Query: ${JSON.stringify(
                  data
                )} \n Query Used: ${JSON.stringify(
                  QUERY_GET_PROVIDER_AUTHORIZATION.loc?.source
                )}`;
                const encodedBodyString = encodeURIComponent(
                  bodyString
                ).replace(/%0A/g, "%0D%0A");

                window.open(
                  `mailto:admin@dragatron.com.au?subject=Air Vaccination Problem&body=${encodedBodyString}`
                );
              }}
            >
              Report
            </Button>
          </Box>
        </Container>
      </Box>
    );
  }

  const result = data.getProvider as AuthorizationResult;

  if (!result.isAdministrator) return <UnauthorizeContent />;

  return <PurchaseProvider>{children}</PurchaseProvider>;
};

export default AdministratorAuthorizationWrapper;
