import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { logger } from "../../utils/logger";
import React, { useEffect, useState } from "react";
import { formatDate } from "./common/utils";
import { VaccineTrialHistoryResponse } from "./vaxHistory";
import { green } from "@mui/material/colors";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface PatientTrialVaccinationHistoryProps {
  vaxTrialHistory: VaccineTrialHistoryResponse | null;
}

interface Data {
  id: number;
  antigenCode: string;
  dose: string;
  endDate: string;
  startDate: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  { width: 100, label: "Antigen Code", dataKey: "antigenCode" },
  { width: 100, label: "Dose", dataKey: "dose" },
  { width: 50, label: "Start Date", dataKey: "startDate" },
  { width: 50, label: "End Date", dataKey: "endDate" },
  // { width: 300, label: "Comments", dataKey: "comments" },
];

const VaccineTrialHistory: React.FC<PatientTrialVaccinationHistoryProps> = ({ vaxTrialHistory }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedTrial, setSelectedTrial] = useState<Data | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [apiError, setApiError] = useState<string | null>(null);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<string | null>(null);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRowClick = (row: Data) => {
    setSelectedTrial(row);
  };

  const rows: Data[] = React.useMemo(() => {
    if (!vaxTrialHistory || !vaxTrialHistory.data?.vaccineTrialList) {
      if (vaxTrialHistory?.data?.statusCode === "AIR-W-1059") {
        // Data was successfully fetched but there are no vaccine trial entries
        setErrorMessage(vaxTrialHistory.data.message);
        return []; // Return an empty array if it's undefined
      } else {
        setErrorMessage("No active vaccine trial is recorded for this individual");
        return []; // Return an empty array if it's undefined
      }
    }
    return (
      vaxTrialHistory?.data?.vaccineTrialList.map((trial, index) => ({
        id: index, // Assuming you want to use the index as a unique key; otherwise, you might need a better unique identifier
        antigenCode: trial.antigenCode,
        dose: trial.dose,
        startDate: formatDate(trial.startDate),
        endDate: formatDate(trial.endDate), // Assuming you want to display "Ongoing" if endDate is empty
      })) || []
    );
  }, [vaxTrialHistory]);
  useEffect(() => {
    if (vaxTrialHistory) {
      setLoading(false);
      if (vaxTrialHistory.data?.errors && vaxTrialHistory.data.errors.length > 0) {
        const error = vaxTrialHistory.data.errors.map((err) => `${err.code}: ${err.message}`).join(", ");
        setApiError(error);
        setErrorMessage(error);
        setShowErrorDialog(true);
      }
    }
  }, [vaxTrialHistory]);
  logger.log("Vaccine Trial History", vaxTrialHistory);

  if (apiError) {
    return (
      <Box sx={{ width: "100%" }}>
        <div>{apiError}</div>
        <Dialog open={showErrorDialog} onClose={() => setApiError(null)}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent sx={{ minWidth: "600px", minHeight: "70px" }}>
            <DialogContentText>{apiError}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ backgroundColor: green[500], color: "#fff" }}
              onClick={() => setApiError(null)}
              color="primary"
              startIcon={<CancelOutlinedIcon />}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.dataKey}
                    align={column.numeric ? "right" : "left"}
                    style={{ width: column.width }}
                    sx={{ backgroundColor: "#1976d2", color: "white" }} // Custom color for header cells
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.dataKey];
                      return (
                        <TableCell key={column.dataKey} align={column.numeric ? "right" : "left"}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {errorMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default VaccineTrialHistory;
