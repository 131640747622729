import axios from 'axios';
import { BloodGlucoseReading, BloodGlucoseResponse, BloodGlucoseDataResponse } from '../types/bloodGlucose';

const API_URL = process.env.REACT_APP_DIABETES_MONITORING_CREATE_URL;
const GET_BY_PATIENT_ID_URL = process.env.REACT_APP_DIABETES_MONITORING_GET_BY_PATIENT_ID_URL;

export const createBloodGlucoseReading = async (
  formData: Omit<BloodGlucoseReading, 'id' | 'createdAt' | 'updatedAt'>
): Promise<BloodGlucoseResponse> => {
  const response = await axios.post<BloodGlucoseResponse>(API_URL!, formData);
  return response.data;
};

export const getBloodGlucoseReadingsByPatientId = async (requestBody: {
  body: {
    patientID: string;
    pharmacyID: string;
    sortDirection: string;
    limit: number;
    nextToken: string | null;
  };
}): Promise<BloodGlucoseDataResponse> => {
  const response = await axios.post<BloodGlucoseDataResponse>(GET_BY_PATIENT_ID_URL!, requestBody);
  return response.data;
};