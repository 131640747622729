// src/utils/apiUtils.ts
import * as Sentry from '@sentry/react';
import { logger } from '../../../utils/logger';
export interface CVCPClaimPayload {
  programRegistrationId: number;
  patientMedicareDVANumber: string;
  patientGivenNames: string;
  patientFamilyName: string;
  dose: string;
  patientDateOfBirth: string;
  patientConsentObtained: boolean;
  dateOfVaccination: string;
  vaccinatorGivenNames: string;
  vaccinatorFamilyName: string;
  vaccinatorAHPRA: string;
  agreeToTermsOfService: number;
}
export interface NipvipClaimPayload {
  programRegistrationId: number;
  patientMedicareDvaNumber: string;
  patientGivenName: string;
  patientFamilyName: string;
  patientDateOfBirth: string;
  dateOfService: string;
  offSiteVaccination?: number;
  vaccinationPremises?: number;
  airProviderNumber: string;
  patientPostcode?: string;
  patientLocality?: string;
  brandName: string;
  airCode: string;
  vaccinatorAHPRA: string;
  vaccinatorFamilyName: string;
  vaccinatorGivenName: string;
  vaccineBatchNumber: string;
  agreeToTermsOfService: number;
}

export interface UserDetails {
  userId: string;
  userKey: string;
  pharmacyId: string;
  providerNumber: string;
}

export interface NipvipClaimFailure {
  value: {
    error: string;
    submitted: boolean;
    validationErrors: string[];
    positionInCollection: number;
  };
  statusCode: number;
}

export interface NipvipClaimSuccess {
  value: {
    submitted: boolean;
    id: number;
    approval: number;
    approvalDate: string;
    positionInCollection: number;
  };
  statusCode: number;
}

export interface CVCPClaimFailure {
  value: {
    error: string;
    submitted: boolean;
    validationErrors: string[];
    positionInCollection: number;
  };
  statusCode: number;
}

export interface CVCPClaimSuccess {
  value: {
    submitted: boolean;
    id: number;
    approval: number;
    approvalDate: string;
    positionInCollection: number;
  };
  statusCode: number;
}

export interface NipvipClaimResponse {
  failureResponse: NipvipClaimFailure[];
  successResponse: NipvipClaimSuccess[];
}

export interface CVCPClaimResponse {
  failureResponse: CVCPClaimFailure[];
  successResponse: CVCPClaimSuccess[];
}

// New Interface for GetCVCPClaimsByPharmacyID response
export interface GetCVCPClaimsByPharmacyIDResponse {
  data: {
    queryCVCPClaimsByPharmacyIndex: {
      items: {
        id: string;
        providerNumber: string;
        pharmacyId: string;
        programRegistrationId: number;
        patientMedicareDVANumber: string;
        patientGivenNames: string;
        patientFamilyName: string;
        patientDateOfBirth: string;
        patientConsentObtained: boolean;
        dateOfVaccination: string;
        vaccinatorGivenNames: string;
        vaccinatorFamilyName: string;
        vaccinatorAHPRA: string;
        agreeToTermsOfService: number;
        createdDate: string;
        status: string;
        failureReason: string | null;
      }[];
      nextToken: string | null;
    };
  };
}

export interface GetNIPVIPClaimsByPharmacyIDResponse {
  data: {
    queryNIPVIPClaimsByPharmacyIdIndex: {
      items: {
        id: string;
        providerNumber: string;
        pharmacyId: string;
        programRegistrationId: number;
        patientMedicareDvaNumber: string;
        patientGivenName: string;
        patientFamilyName: string;
        patientDateOfBirth: string;
        dateOfService: string;
        airProviderNumber: string;
        patientPostcode: string;
        patientLocality: string;
        brandName: string;
        airCode: string;
        vaccinatorAHPRA: string;
        vaccinatorFamilyName: string;
        vaccinatorGivenName: string;
        vaccineBatchNumber: string;
        agreeToTermsOfService: number;
        createdDate: string;
        status: string;
        failureReason: string | null;
      }[];
      nextToken: string | null;
    };
  };
}

// New Interface for MedsCheckClaimPayload
export interface MedsCheckClaimPayload {
  programRegistrationId: number;
  patientMedicareDVANumber: string;
  patientFirstName: string;
  patientLastName: string;
  agreeToTermsOfService: number;
  patientConsent: number;
  patientDateOfBirth: string;
  dateOfServiceProvision: string;
  pharmacistAPHRA: string;
  pharmacistname: string;
}

/**
 * Submits a NIPVIP claim.
 *
 * @param {NipvipClaimPayload[]} payload - The payload for the NIPVIP claim.
 * @param {UserDetails} userDetails - The user details to include in the payload.
 * @returns {Promise<NipvipClaimResponse>} - A promise that resolves with the API response.
 */
export const submitNipvipClaim = async (
  payload: NipvipClaimPayload[],
  userDetails: UserDetails
): Promise<NipvipClaimResponse> => {
  try {
    const fullPayload = {
      ...userDetails,
      claims: payload,
    };
    const apiURL = process.env.REACT_APP_SUBMIT_NIPVIP_CLAIMS;
    if (!apiURL) {
      throw new Error('REACT_APP_SUBMIT_NIPVIP_CLAIMS is not defined');
    }
    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fullPayload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: NipvipClaimResponse = await response.json();
    logger.log('NIPVIP Claim Response:', data);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error submitting NIPVIP claim:', error);
    throw error;
  }
};

/**
 * Submits a NIPVIP claim.
 *
 * @param {CVCPClaimPayload[]} payload - The payload for the NIPVIP claim.
 * @param {UserDetails} userDetails - The user details to include in the payload.
 * @returns {Promise<CVCPClaimResponse>} - A promise that resolves with the API response.
 */
export const submitcovidVaxClaims = async (
  payload: CVCPClaimPayload[],
  userDetails: UserDetails
): Promise<CVCPClaimResponse> => {
  try {
    const fullPayload = {
      ...userDetails,
      claims: payload,
    };
    const apiURL = process.env.REACT_APP_SUBMIT_COVID_VAX_CLAIMS;
    if (!apiURL) {
      throw new Error('REACT_APP_SUBMIT_COVID_VAX_CLAIMS is not defined');
    }

    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fullPayload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: CVCPClaimResponse = await response.json();
    logger.log('🚀 ~ CVCPClaimResponse data:', data);
    return data;
  } catch (error) {
    console.error('Error submitting CVCPClaimResponse claim:', error);
    throw error;
  }
};

/**
 * Retrieves CVCP claims by pharmacy ID.
 *
 * @param {string} pharmacyId - The pharmacy ID to filter claims.
 * @param {number} first - The number of items to retrieve.
 * @param {string | null} after - The pagination token for the next set of results.
 * @returns {Promise<GetCVCPClaimsByPharmacyIDResponse>} - A promise that resolves with the API response.
 */
export const getCVCPClaimsByPharmacyID = async (
  pharmacyId: string,
  createdDate: string | null,
  first: number,
  after: string | null
): Promise<GetCVCPClaimsByPharmacyIDResponse> => {
  try {
    const payload = {
      operation: 'QUERY_CVCP_CLAIMS_BY_PHARMACY_INDEX',
      variables: {
        pharmacyId,
        createdDate,
        first,
        after,
      },
    };
    logger.log('🚀 ~ payload:', payload);

    const apiURL = process.env.REACT_APP_READ_CVCP_CLAIMS;
    if (!apiURL) {
      throw new Error('REACT_APP_READ_CVCP_CLAIMS is not defined');
    }
    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: GetCVCPClaimsByPharmacyIDResponse = await response.json();
    logger.log('CVCP Claims Response:', data);
    return data;
  } catch (error) {
    console.error('Error retrieving CVCP claims:', error);
    throw error;
  }
};

/**
 * Retrieves NIPVIP claims by pharmacy ID.
 *
 * @param {string} pharmacyId - The pharmacy ID to filter claims.
 * @param {string | null} createdDate - The created date to filter claims.
 * @param {number} first - The number of items to retrieve.
 * @param {string | null} after - The pagination token for the next set of results.
 * @returns {Promise<GetNIPVIPClaimsByPharmacyIDResponse>} - A promise that resolves with the API response.
 */
export const getNIPVIPClaimsByPharmacyID = async (
  pharmacyId: string,
  createdDate: string | null,
  first: number,
  after: string | null
): Promise<GetNIPVIPClaimsByPharmacyIDResponse> => {
  try {
    const payload = {
      operation: 'QUERY_NIPVIP_CLAIMS_BY_PHARMACY_ID_INDEX',
      variables: {
        pharmacyId,
        createdDate,
        first,
        after,
      },
    };
    logger.log('🚀 ~ payload:', payload);
    const apiURL = process.env.REACT_APP_READ_NIPVIP_CLAIMS;
    if (!apiURL) {
      throw new Error('REACT_APP_READ_NIPVIP_CLAIMS is not defined');
    }
    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: GetNIPVIPClaimsByPharmacyIDResponse = await response.json();
    logger.log('NIPVIP Claims Response:', data);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error retrieving NIPVIP claims:', error);
    throw error;
  }
};
