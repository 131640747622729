import React from 'react';
import { Paper, Typography, Grid, Button, Box } from '@mui/material';
import { Print } from '@mui/icons-material';
import { FormData } from './UTIProtocolForm';

interface SummaryScreenProps {
  formData: FormData;
  onPrint: () => void;
}

const SummaryScreen: React.FC<SummaryScreenProps> = ({ formData, onPrint }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        UTI Protocol Summary
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Patient Information</Typography>
          <Typography>
            Name: {formData.patientInfo.firstName} {formData.patientInfo.lastName}
          </Typography>
          <Typography>Date of Birth: {formData.patientInfo.dateOfBirth}</Typography>
          <Typography>Phone: {formData.patientInfo.phoneNumber}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Diagnosis</Typography>
          <Typography>{formData.diagnosis}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Treatment</Typography>
          <Typography>Medicine Provided: {formData.treatment.medicineProvided}</Typography>
          <Typography>Dosage Instructions: {formData.treatment.dosageInstructions}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Follow-up Plan</Typography>
          <Typography>Follow-up Date: {new Date(formData.pharmacistCare.followUpDate).toLocaleDateString()}</Typography>
          <Typography>Follow-up Method: {formData.pharmacistCare.followUpMethod.join(', ')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Referral</Typography>
          <Typography>{formData.referral === 'no_referral' ? 'No referral required' : formData.referral}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" startIcon={<Print />} onClick={onPrint}>
          Print Patient Handout
        </Button>
      </Box>
    </Paper>
  );
};

export default SummaryScreen;
