import { Typography, Box, Button } from '@mui/material';

import { APICore } from '../../helpers/api/apiCore';

const BillingPortal = () => {
  const apiCore = new APICore(); // Assuming you can instantiate like this, adjust based on your actual implementation
  const billingPortalUrl = apiCore.getStripeBillingPortalUrl();

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Manage Your Subscription
      </Typography>
      <Box marginTop={2}>
        <Typography variant="body1" gutterBottom>
          You can manage your subscriptions and invoices through our billing portal.
        </Typography>
        <Box marginTop={2}>
          {billingPortalUrl ? (
            <Button
              variant="contained"
              color="primary"
              href={billingPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Billing Portal
            </Button>
          ) : (
            <Typography variant="body2" color="error">
              Error: User email not found. Please log in again.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BillingPortal;
