import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Droplet, Heart, AlertTriangle, TrendingUp } from 'lucide-react';
import ReactApexChart from 'react-apexcharts';
import { useCustomer } from '../contexts/CustomerContext';
import AuthContext from '../contexts/AuthContext';
import { useCholesterolData } from '../hooks/useCholesterolData';
import { createCholesterolReading } from '../services/cholesterolService';
import { CholesterolReading } from '../types/cholesterol';
import { logger } from 'utils/logger';

interface CholesterolCardProps {
  title: string;
  value: string;
  icon: React.ElementType;
  interpretation: string;
  bgColor: string;
  textColor: string;
}

const CholesterolCard: React.FC<CholesterolCardProps> = ({
  title,
  value,
  icon: Icon,
  interpretation,
  bgColor,
  textColor,
}) => (
  <Paper elevation={3} sx={{ p: 2, height: '100%', bgcolor: bgColor, color: textColor }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6" fontWeight="bold">
        {title}
      </Typography>
      <Icon size={24} />
    </Box>
    <Typography variant="h4" sx={{ my: 2 }}>
      {value}
    </Typography>
    <Typography variant="body2">{interpretation}</Typography>
  </Paper>
);

const CholesterolManagement: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useCustomer();
  const customer = state.customer;
  const { pharmacyID } = useContext(AuthContext);
  const { historicalData, loading, error, refetch } = useCholesterolData(customer?.patientID, pharmacyID);

  const [measurementDate, setMeasurementDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [measurementTime, setMeasurementTime] = useState<dayjs.Dayjs | null>(dayjs());
  const [totalCholesterol, setTotalCholesterol] = useState<string>('');
  const [ldl, setLDL] = useState<string>('');
  const [hdl, setHDL] = useState<string>('');
  const [triglycerides, setTriglycerides] = useState<string>('');
  const [fastingStatus, setFastingStatus] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  useEffect(() => {
    if (!customer?.patientID) {
      logger.log('No customer selected');
      navigate('/pages/patients/search-patient');
    }
  }, [customer, navigate]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!customer || !customer.patientID) {
      setSnackbarMessage('No customer selected or patient ID is missing.');
      setSnackbarOpen(true);
      return;
    }

    const formData: CholesterolReading = {
      patientID: customer.patientID,
      pharmacyID: pharmacyID || '',
      createdDate: dayjs().format('YYYY-MM-DD'),
      measurementDate: measurementDate?.format('YYYY-MM-DD') || '',
      measurementTime: measurementTime?.format('HH:mm') || '',
      totalCholesterol: Number(totalCholesterol),
      ldl: Number(ldl),
      hdl: Number(hdl),
      triglycerides: Number(triglycerides),
      fastingStatus,
      notes,
    };

    try {
      logger.log('formData', formData);
      await createCholesterolReading(formData);
      setSnackbarMessage('Cholesterol reading saved successfully');
      setSnackbarOpen(true);
      refetch();
    } catch (error) {
      setSnackbarMessage('Error saving cholesterol reading');
      setSnackbarOpen(true);
    }
  };

  const getLatestReadings = () => {
    if (historicalData.length === 0) {
      return {
        totalCholesterol: 'N/A',
        ldl: 'N/A',
        hdl: 'N/A',
        triglycerides: 'N/A',
      };
    }
    return historicalData[0];
  };

  const latestReadings = getLatestReadings();

  const interpretCholesterol = (value: number | string) => {
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    if (numValue < 200) return 'Desirable';
    if (numValue < 240) return 'Borderline High';
    return 'High';
  };

  const interpretLDL = (value: number | string) => {
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    if (numValue < 100) return 'Optimal';
    if (numValue < 130) return 'Near Optimal';
    if (numValue < 160) return 'Borderline High';
    if (numValue < 190) return 'High';
    return 'Very High';
  };

  const interpretHDL = (value: number | string) => {
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    if (numValue < 40) return 'Low';
    if (numValue < 60) return 'Normal';
    return 'High (Protective)';
  };

  const interpretTriglycerides = (value: number | string) => {
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    if (numValue < 150) return 'Normal';
    if (numValue < 200) return 'Borderline High';
    if (numValue < 500) return 'High';
    return 'Very High';
  };

  const chartOptions = {
    chart: {
      type: 'bar' as const,
      height: 350,
    },
    xaxis: {
      categories: historicalData.map((reading) => reading.measurementDate),
    },
    yaxis: {
      title: {
        text: 'mg/dL',
      },
    },
    legend: {
      position: 'top' as const,
    },
  };

  const chartSeries = [
    {
      name: 'Total Cholesterol',
      data: historicalData.map((reading) => reading.totalCholesterol),
    },
    {
      name: 'LDL',
      data: historicalData.map((reading) => reading.ldl),
    },
    {
      name: 'HDL',
      data: historicalData.map((reading) => reading.hdl),
    },
    {
      name: 'Triglycerides',
      data: historicalData.map((reading) => reading.triglycerides),
    },
  ];

  return (
    <Box sx={{ bgcolor: 'grey.100', minHeight: '100vh', py: 3 }}>
      <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 3 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 4, color: 'primary.main' }}>
          Cholesterol Management
        </Typography>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6} md={3}>
            <CholesterolCard
              title="Total Cholesterol"
              value={`${latestReadings.totalCholesterol} mg/dL`}
              icon={Droplet}
              interpretation={interpretCholesterol(latestReadings.totalCholesterol)}
              bgColor="#FFF3E0"
              textColor="#E65100"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CholesterolCard
              title="LDL"
              value={`${latestReadings.ldl} mg/dL`}
              icon={AlertTriangle}
              interpretation={interpretLDL(latestReadings.ldl)}
              bgColor="#FFEBEE"
              textColor="#C62828"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CholesterolCard
              title="HDL"
              value={`${latestReadings.hdl} mg/dL`}
              icon={Heart}
              interpretation={interpretHDL(latestReadings.hdl)}
              bgColor="#E8F5E9"
              textColor="#2E7D32"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CholesterolCard
              title="Triglycerides"
              value={`${latestReadings.triglycerides} mg/dL`}
              icon={TrendingUp}
              interpretation={interpretTriglycerides(latestReadings.triglycerides)}
              bgColor="#E3F2FD"
              textColor="#1565C0"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                New Cholesterol Reading
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Measurement Date"
                        value={measurementDate}
                        onChange={(newValue) => setMeasurementDate(newValue)}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Measurement Time"
                        value={measurementTime}
                        onChange={(newValue) => setMeasurementTime(newValue)}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Total Cholesterol"
                      type="number"
                      value={totalCholesterol}
                      onChange={(e) => setTotalCholesterol(e.target.value)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="LDL"
                      type="number"
                      value={ldl}
                      onChange={(e) => setLDL(e.target.value)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="HDL"
                      type="number"
                      value={hdl}
                      onChange={(e) => setHDL(e.target.value)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Triglycerides"
                      type="number"
                      value={triglycerides}
                      onChange={(e) => setTriglycerides(e.target.value)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      value={fastingStatus}
                      onChange={(e) => setFastingStatus(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Fasting Status
                      </MenuItem>
                      <MenuItem value="fasting">Fasting</MenuItem>
                      <MenuItem value="non-fasting">Non-fasting</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Notes"
                      multiline
                      rows={3}
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      Save Reading
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Cholesterol Trends
              </Typography>
              <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={300} />
            </Paper>
          </Grid>
        </Grid>

        <Paper sx={{ mt: 3, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Recent Readings
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Total Cholesterol</TableCell>
                  <TableCell>LDL</TableCell>
                  <TableCell>HDL</TableCell>
                  <TableCell>Triglycerides</TableCell>
                  <TableCell>Fasting Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historicalData.map((reading) => (
                  <TableRow key={reading.id}>
                    <TableCell>{reading.measurementDate}</TableCell>
                    <TableCell>{reading.totalCholesterol} mg/dL</TableCell>
                    <TableCell>{reading.ldl} mg/dL</TableCell>
                    <TableCell>{reading.hdl} mg/dL</TableCell>
                    <TableCell>{reading.triglycerides} mg/dL</TableCell>
                    <TableCell>{reading.fastingStatus}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default CholesterolManagement;
