import { DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { DatePicker as MUIDatePickerPro } from "@mui/x-date-pickers/DatePicker";
import { Moment } from "moment";
type Props = DatePickerProps<Moment>;

/**
 * Renders a date picker component with the configuration.
 *
 * @template T - The type of the value selected by the date picker.
 * @param {Props<T>} props - The props for the date picker component.
 * @returns {JSX.Element} - The rendered date picker component.
 */
const DatePicker = (props: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MUIDatePickerPro {...props} />
    </LocalizationProvider>
  );
};

export default DatePicker;
