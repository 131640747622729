import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Grid,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    InputAdornment,
    CircularProgress,
    Snackbar,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Droplet, Activity, Zap, AlertTriangle } from 'lucide-react';
import ReactApexChart from 'react-apexcharts';
import { useCustomer } from '../contexts/CustomerContext';
import AuthContext from '../contexts/AuthContext';
import { useIronScreeningData } from '../hooks/useIronScreeningData';
import { createIronScreeningReading } from '../services/ironScreeningService';
import { IronScreeningReading } from '../types/ironScreening';
import { logger } from 'utils/logger';

interface IronCardProps {
    title: string;
    value: string;
    icon: React.ElementType;
    interpretation: string;
    bgColor: string;
    textColor: string;
}

const IronCard: React.FC<IronCardProps> = ({ title, value, icon: Icon, interpretation, bgColor, textColor }) => (
    <Paper elevation={3} sx={{ p: 2, height: '100%', bgcolor: bgColor, color: textColor, borderRadius: '8px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight="bold">
                {title}
            </Typography>
            <Icon size={24} />
        </Box>
        <Typography variant="h4" sx={{ my: 2 }}>
            {value}
        </Typography>
        <Typography variant="body2">{interpretation}</Typography>
    </Paper>
);

const IronScreening: React.FC = () => {
    const navigate = useNavigate();
    const { state } = useCustomer();
    const customer = state.customer;
    const { pharmacyID } = useContext(AuthContext);
    const { historicalData, loading, error, refetch } = useIronScreeningData(customer?.patientID, pharmacyID);

    const [measurementDate, setMeasurementDate] = useState<dayjs.Dayjs | null>(dayjs());
    const [measurementTime, setMeasurementTime] = useState<dayjs.Dayjs | null>(dayjs());
    const [serumIron, setSerumIron] = useState<string>('');
    const [ferritin, setFerritin] = useState<string>('');
    const [transferrinSaturation, setTransferrinSaturation] = useState<string>('');
    const [totalIronBindingCapacity, setTotalIronBindingCapacity] = useState<string>('');
    const [hemoglobin, setHemoglobin] = useState<string>('');
    const [hematocrit, setHematocrit] = useState<string>('');
    const [isPregnant, setIsPregnant] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>('');

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');

    useEffect(() => {
        if (!customer?.patientID) {
            navigate('/pages/patients/search-patient');
        }
        // Cleanup function to avoid memory leaks
        return () => {
            // Perform any necessary cleanup here
        };
    }, [customer, navigate]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!customer || !customer.patientID) {
            setSnackbarMessage('No customer selected or patient ID is missing.');
            setSnackbarOpen(true);
            return;
        }

        const formData: Partial<IronScreeningReading> = {
            patientID: customer.patientID,
            pharmacyID: pharmacyID || '',
            createdDate: dayjs().format('YYYY-MM-DD'),
            measurementDate: measurementDate?.format('YYYY-MM-DD') || '',
            measurementTime: measurementTime?.format('HH:mm') || '',
            serumIron: Number(serumIron),
            ferritin: Number(ferritin),
            transferrinSaturation: Number(transferrinSaturation),
            totalIronBindingCapacity: Number(totalIronBindingCapacity),
            hemoglobin: Number(hemoglobin),
            hematocrit: Number(hematocrit),
            isPregnant,
            notes,
        };

        try {
            logger.log('formData', formData);
            // Before calling createIronScreeningReading
            const readingData: Omit<IronScreeningReading, 'id' | 'createdAt' | 'updatedAt'> = {
                patientID: formData.patientID!,
                pharmacyID: formData.pharmacyID!,
                createdDate: formData.createdDate!,
                measurementDate: formData.measurementDate!,
                measurementTime: formData.measurementTime!,
                serumIron: formData.serumIron!,
                ferritin: formData.ferritin!,
                transferrinSaturation: formData.transferrinSaturation!,
                totalIronBindingCapacity: formData.totalIronBindingCapacity!,
                hemoglobin: formData.hemoglobin,
                hematocrit: formData.hematocrit,
                notes: formData.notes
            };

            await createIronScreeningReading(readingData);
            setSnackbarMessage('Iron screening reading saved successfully');
            setSnackbarOpen(true);
            refetch();
        } catch (error) {
            setSnackbarMessage('Error saving iron screening reading');
            setSnackbarOpen(true);
        }
    };

    const getLatestReading = () => {
        return historicalData.length > 0
            ? historicalData[0]
            : {
                serumIron: 0,
                ferritin: 0,
                transferrinSaturation: 0,
                hemoglobin: 0,
            };
    };

    const latestReading = getLatestReading();

    const interpretIronStatus = (serumIron: number, ferritin: number, transferrinSaturation: number) => {
        if (serumIron < 60 || ferritin < 30 || transferrinSaturation < 20) {
            return 'Iron Deficiency';
        } else if (serumIron > 170 || ferritin > 300 || transferrinSaturation > 50) {
            return 'Iron Overload';
        }
        return 'Normal';
    };

    const chartOptions = {
        chart: {
            type: 'line' as const,
            height: 350,
        },
        xaxis: {
            categories: historicalData.map((reading) => dayjs(reading.measurementDate).format('MMM D, YYYY')),
        },
        yaxis: {
            title: {
                text: 'Values',
            },
        },
        legend: {
            position: 'top' as const,
        },
    };

    const chartSeries = [
        {
            name: 'Serum Iron',
            data: historicalData.map(reading => reading.serumIron ?? null)
        },
        {
            name: 'Ferritin',
            data: historicalData.map(reading => reading.ferritin ?? null)
        },
        {
            name: 'Transferrin Saturation',
            data: historicalData.map(reading => reading.transferrinSaturation ?? null)
        },
        {
            name: 'Hemoglobin',
            data: historicalData.map(reading => reading.hemoglobin ?? null)
        }
    ];

    return (
        <Box sx={{ bgcolor: '#F3F4F6', minHeight: '100vh', py: 4 }}>
            <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 3 }}>
                <Typography variant="h4" component="h1" sx={{ mb: 4, color: 'primary.main', fontWeight: 'bold' }}>
                    Iron Screening
                </Typography>

                <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <IronCard
                            title="Serum Iron"
                            value={`${latestReading.serumIron} µg/dL`}
                            icon={Droplet}
                            interpretation={
                                Number(latestReading.serumIron) < 60 ? 'Low' : Number(latestReading.serumIron) > 170 ? 'High' : 'Normal'
                            }
                            bgColor="#FCA5A5"
                            textColor="#7F1D1D"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <IronCard
                            title="Ferritin"
                            value={`${latestReading.ferritin} ng/mL`}
                            icon={Zap}
                            interpretation={
                                Number(latestReading.ferritin) < 30 ? 'Low' : Number(latestReading.ferritin) > 300 ? 'High' : 'Normal'
                            }
                            bgColor="#FBBF24"
                            textColor="#78350F"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <IronCard
                            title="Transferrin Saturation"
                            value={`${latestReading.transferrinSaturation}%`}
                            icon={Activity}
                            interpretation={
                                Number(latestReading.transferrinSaturation) < 20
                                    ? 'Low'
                                    : Number(latestReading.transferrinSaturation) > 50
                                        ? 'High'
                                        : 'Normal'
                            }
                            bgColor="#60A5FA"
                            textColor="#1E3A8A"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <IronCard
                            title="Hemoglobin"
                            value={`${latestReading.hemoglobin} g/dL`}
                            icon={AlertTriangle}
                            interpretation={
                                Number(latestReading.hemoglobin) < 12
                                    ? 'Low'
                                    : Number(latestReading.hemoglobin) > 16
                                        ? 'High'
                                        : 'Normal'
                            }
                            bgColor="#34D399"
                            textColor="#064E3B"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                New Iron Screening Reading
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Measurement Date"
                                                value={measurementDate}
                                                onChange={(newValue) => setMeasurementDate(newValue)}
                                                slotProps={{ textField: { fullWidth: true } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                label="Measurement Time"
                                                value={measurementTime}
                                                onChange={(newValue) => setMeasurementTime(newValue)}
                                                slotProps={{ textField: { fullWidth: true } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Serum Iron"
                                            type="number"
                                            value={serumIron}
                                            onChange={(e) => setSerumIron(e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">µg/dL</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Ferritin"
                                            type="number"
                                            value={ferritin}
                                            onChange={(e) => setFerritin(e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">ng/mL</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Transferrin Saturation"
                                            type="number"
                                            value={transferrinSaturation}
                                            onChange={(e) => setTransferrinSaturation(e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Hemoglobin"
                                            type="number"
                                            value={hemoglobin}
                                            onChange={(e) => setHemoglobin(e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g/dL</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isPregnant}
                                                    onChange={(e) => setIsPregnant(e.target.checked)}
                                                    name="isPregnant"
                                                />
                                            }
                                            label="Pregnant"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Notes"
                                            multiline
                                            rows={3}
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary" fullWidth>
                                            Save Reading
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Iron Status Trends
                            </Typography>
                            <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={350} />
                        </Paper>
                    </Grid>
                </Grid>

                <Paper sx={{ mt: 3, p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Recent Readings
                    </Typography>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Serum Iron</TableCell>
                                    <TableCell>Ferritin</TableCell>
                                    <TableCell>Transferrin Saturation</TableCell>
                                    <TableCell>Hemoglobin</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historicalData.map((reading) => (
                                    <TableRow key={reading.id}>
                                        <TableCell>{reading.measurementDate}</TableCell>
                                        <TableCell>{reading.serumIron} µg/dL</TableCell>
                                        <TableCell>{reading.ferritin} ng/mL</TableCell>
                                        <TableCell>{reading.transferrinSaturation}%</TableCell>
                                        <TableCell>{reading.hemoglobin} g/dL</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default IronScreening;
