import React from 'react';
import { Grid, Paper, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { Controller, Control, FieldPath } from 'react-hook-form';
import { FormData } from '../UTIProtocolForm';
import { AlertTriangle } from 'lucide-react';
import { grey } from '@mui/material/colors';

const highRiskFactorsList = [
    { key: 'pregnancy', label: 'Pregnancy or 4-6 weeks post-partum' },
    { key: 'recurrentUTI', label: 'Recurrent UTI (2 or more within 6 months or 3 or more within 12 months)' },
    { key: 'persistentSymptoms', label: 'Symptoms of UTI persisting 48-72 hours after starting appropriate antibiotic treatment' },
    { key: 'reoccurringSymptoms', label: 'Symptoms of UTI reoccur within 2 weeks after finishing appropriate antibiotic treatment' },
    { key: 'visibleHaematuria', label: 'Visible haematuria (blood in urine)' },
    { key: 'pyuria', label: 'Pyuria (pus in urine)' },
    { key: 'renalDisease', label: 'Renal disease, impaired renal function and history of renal stones' },
    { key: 'catheterisation', label: 'Catheterisation' },
    { key: 'pyelonephritis', label: 'Previous episodes of pyelonephritis' },
    { key: 'immunocompromised', label: 'Immunocompromised or taking immunosuppressant medicines (e.g., azathioprine, cyclosporin, cyclophosphamide, methotrexate [including low dose])' },
    { key: 'bloodDisorder', label: 'History of blood disorder/porphyria' },
    { key: 'spinalCordInjury', label: 'Spinal cord injury' },
    { key: 'diabetes', label: 'Diabetes' },
    { key: 'recentIUD', label: 'Recent insertion of IUD within the last 3 months' },
];

interface StepProps {
    control: Control<FormData>;
}

const Step4HighRiskFactors: React.FC<StepProps> = ({ control }) => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <AlertTriangle size={20} style={{ marginRight: '10px' }} />
                    High Risk Factors
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    Does the patient have any of the following high-risk factors?
                </Typography>
                <Grid container spacing={1}>
                    {highRiskFactorsList.map((factor) => (
                        <Grid item xs={12} key={factor.key}>
                            <Controller
                                name={`riskFactors.highRisk.${factor.key}` as FieldPath<FormData>}
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={!!field.value} />}
                                        label={<Typography variant="body1">{factor.label}</Typography>}
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '1rem' } }}
                                    />
                                )}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    </Grid>
);

export default Step4HighRiskFactors;