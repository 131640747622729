import {
  createContext,
  useState,
  useContext,
  ReactNode,
} from "react";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import { Alert, AlertProps } from "@mui/material";

// Define the type for the context value
type SnackbarContextType = {
  openSnackbar: (props: IOpenSnackbarProps) => void;
  handleExited: () => void;
  closeSnackbar: () => void;
};

// Create the context
const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

// Define the type for the provider props
type SnackbarProviderProps = {
  children: ReactNode;
};

interface OmitedSnackbarProps
  extends Omit<SnackbarProps, "autoHideDuration" | "onClose" | "message"> {
  severity?: AlertProps["severity"];
}

interface IOpenSnackbarProps extends OmitedSnackbarProps {
  message: string;
}

interface SnackbarMessage {
  message: string;
  key: number;
}

// Create the provider
export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<
    SnackbarMessage | undefined
  >(undefined);
  const [_, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [snackbarProps, setSnackbarProps] = useState<OmitedSnackbarProps>({
    severity: "info",
  });

  const openSnackbar = (props: IOpenSnackbarProps) => {
    const { message, ...rest } = props;
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    setSnackbarMessage({ message, key: new Date().getTime() });
    setSnackbarProps((prev) => ({ ...prev, severity: "info", ...rest }));
    setSnackbarOpen(true);
  };

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleExited = () => {
    setSnackbarMessage(undefined);
  };

  return (
    <SnackbarContext.Provider
      value={{ openSnackbar, handleExited, closeSnackbar }}
    >
      {children}
      <Snackbar
        key={snackbarMessage ? snackbarMessage.key : undefined}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited, exit: false }}
        {...snackbarProps}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarProps.severity}
          variant="filled"
          action={snackbarProps.action}
          icon={snackbarProps.action && false}
          sx={{ width: "100%" }}
        >
          {snackbarMessage ? snackbarMessage.message : ""}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// Create a hook to use the context
export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
}
