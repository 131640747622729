import { useState, useEffect, useCallback } from 'react';
import { CholesterolReading, CholesterolDataResponse } from '../types/cholesterol';
import { getCholesterolReadingsByPatientId } from '../services/cholesterolService';

export const useCholesterolData = (patientID: string | undefined, pharmacyID: string | undefined) => {
  const [historicalData, setHistoricalData] = useState<CholesterolReading[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const fetchData = useCallback(async (reset: boolean = false) => {
    if (!patientID || !pharmacyID) {
      setLoading(false);
      return;
    }

    try {
      const requestBody = {
        body: {
          patientID,
          pharmacyID,
          sortDirection: 'DESC',
          limit: 10,
          nextToken: reset ? null : nextToken,
        },
      };

      const data = await getCholesterolReadingsByPatientId(requestBody);
      setHistoricalData(prevData => (reset ? data.items : [...prevData, ...data.items]));
      setNextToken(data.nextToken);
    } catch (err) {
      setError('Failed to fetch cholesterol data');
    } finally {
      setLoading(false);
    }
  }, [patientID, pharmacyID, nextToken]);

  useEffect(() => {
    fetchData(true);
  }, [patientID, pharmacyID]);

  const loadMore = () => {
    if (nextToken) {
      fetchData();
    }
  };

  return { historicalData, loading, error, loadMore, hasMore: !!nextToken, refetch: () => fetchData(true) };
};
