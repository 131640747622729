import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
// Third-party library imports
import { useLazyQuery } from "@apollo/client";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateValidationError } from "@mui/x-date-pickers/models";
import dayjs, { Dayjs } from "dayjs";
// MUI core imports
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "../../utils/logger";
// Local imports
import AuthContext from "../../contexts/AuthContext";
import { GET_VACCINE_DATA } from "../../graphql-queries";
import VaccineRecordForm from "./PatientMultipleVaccination"; // Adjust the import path as necessary
import { GraphQLVaxListResponse } from "./Vaccine";
import countryCodes from "./common/CountryCodes";
import { IdentifyIndividualResponse } from "./identifyIndividual";
import { PatientItem } from "./patient";
import { AIRRecordEncounterResponse, VaxHistoryResponse } from "./vaxHistory";
import { useIdToken } from "../../hooks/useIdToken";

interface Episode {
  id: number;
  vaccineBatch?: string;
  vaccineCode?: string;
  vaccineDose?: string; // Adjust types as necessary
  vaccineSerialNumber?: string;
  vaccineFundingType?: string;
  routeOfAdministration?: string;
  // Add more fields as necessary
}
interface PatientVaccinationProps {
  patientIdentifier: string;
  vaxHistory: VaxHistoryResponse | null;
  individualDetails: IdentifyIndividualResponse | null;
  patientItemDetails: PatientItem | undefined;
}

interface VaccineOption {
  label: string; // This will store the VaccineBrandName
  value: string; // This will store the VaccineCode
  isEligibleForNIP?: boolean; // Add this line
}
interface FormValue {
  immunisationDate: Dayjs | null; // Adjusted to match expected type, assuming Dayjs is used
  infectiousDisease: string;
  vaccine: string;
  vaccineBrandName: string; // Add this line
  routeOfAdministration: string;
  siteOfAdministration: string;
  dose: string;
  batchNumber: string;
  expiryDate: string;
  doseNumber: string;
  vaccineFundingType: string;
  comments: string;
  idealNextDoseDate: string;
  cmiProvided: boolean;
  deemedEligible: boolean;
  isOverseas: boolean;
  isOtherProvider: boolean;
  VaccineSerialNumber: string;
  countryCode: string;
  SchoolId: string;
  errorInfo?: any; // Define the type more specifically according to what errorInfo contains
  otherProviderNumber: string;
  filteredVaccines: VaccineOption[]; // Update the type here as well
  otherDiseases: VaccineOption[]; // Add this line
  status: string;
  nipClaim: boolean; // Added this line

  // Structure reflecting your form values
}

const initialFormState = {
  claimId: "",
  immunisationDate: dayjs(null), // Set the immunisationDate to be of type Dayjs | null
  infectiousDisease: "",
  vaccine: "",
  vaccineBrandName: "",
  routeOfAdministration: "",
  siteOfAdministration: "",
  dose: "",
  batchNumber: "",
  expiryDate: "",
  doseNumber: "1",
  vaccineFundingType: "",
  comments: "",
  idealNextDoseDate: "",
  cmiProvided: false,
  deemedEligible: false,
  isOverseas: false,
  isOtherProvider: false,
  VaccineSerialNumber: "",
  countryCode: "",
  SchoolId: "",
  otherProviderNumber: "",
  OtherHpioNumber: "",
  OtherHpiiNumber: "",
  filteredVaccines: [],
  otherDiseases: [], // Add this line
  status: "",
  nipClaim: false, // Added this line
};

const RecordHistoricalVaccinations: React.FC<PatientVaccinationProps> = ({
  patientIdentifier,
  vaxHistory,
  individualDetails,
  patientItemDetails,
}) => {
  const [individualIdentifierDetails, setIndividualIdentifierDetails] =
    useState<IdentifyIndividualResponse>();
  const [forms, setForms] = useState<FormValue[]>([{} as FormValue]); // Initialize with one empty form
  const addForm = () => {
    setForms([...forms, { ...initialFormState }]); // Use a deep copy of the initialFormState if it's not primitive
  };

  useEffect(() => {
    if (forms.length >= 1) {
      logger.log(forms); // Ensure forms[1] exists
    }
  }, [forms]); // This useEffect depends on `forms` and will run every time `forms` changes.

  const removeForm = (index: number) => {
    const updatedForms = forms.filter((_, i) => i !== index);
    setForms(updatedForms);
  };

  const [formValues, setFormValues] = useState(initialFormState);

  const [immunisationDate, setImmunisationDate] = useState<Dayjs | null>(
    dayjs()
  );
  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const [showDiseaseDropdown, setShowDiseaseDropdown] = useState(false);
  const [otherDiseases, setOtherDiseases] = useState<VaccineOption[]>([]);
  const [submissionStatus, setSubmissionStatus] = useState({
    submitted: false,
    success: false,
    message: "",
  });
  const [modalState, setModalState] = useState({
    open: false,
    loading: false,
    success: false,
    message: "",
  });
  const {
    providerNumber,
    providerStore,
    hpiiNumber,
    hpioNumber,
    minorId,
    deviceName,
    prodaOrgId,
  } = useContext(AuthContext);

  const calculateAge = (birthdate: string | undefined): number => {
    if (!birthdate) return 0; // Return 0 or handle undefined birthdate appropriately

    const birthdateDayjs = dayjs(birthdate, "DDMMYYYY");
    const now = dayjs();
    return now.diff(birthdateDayjs, "year");
  };
  const [encounters, setEncounters] = useState([
    {
      id: 1,
      status: "", // Initial status is empty
      claimSequenceNumber: "",
      formValues: {
        ...initialFormState,
        showDiseaseDropdown: false,
        filteredVaccines: [] as VaccineOption[], // Add this line
        otherDiseases: [] as VaccineOption[], // Add this line
      },
    },
  ]);
  // fetch the values from GraphQL API
  const [fetchDiseases, { data, loading, error }] =
    useLazyQuery<GraphQLVaxListResponse>(GET_VACCINE_DATA);

  const [isBirthDose, setIsBirthDose] = useState(false);

  const [isMultipleVaccines, setIsMultipleVaccines] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [acceptAndConfirm, setAcceptAndConfirm] = useState("N");
  const [claimId, setClaimId] = useState("");
  const [otherProviderNumber, setOtherProviderNumber] = useState("");
  const { idToken, isLoading, error: idTokenError } = useIdToken();

  // Fetch diseases when the component mounts
  useEffect(() => {
    const loadedIndividualIdentiferDetails =
      localStorage.getItem(patientIdentifier);
    if (loadedIndividualIdentiferDetails) {
      setIndividualIdentifierDetails(
        JSON.parse(loadedIndividualIdentiferDetails)
      );
    } else {
      // Handle the case where no details are found, if necessary
      logger.log("No patient details found for the provided identifier.");
    }

    const age = calculateAge(
      patientItemDetails?.dateOfBirth.split("-").reverse().join("")
    );
    logger.log("Age:", age);
    // if (isBirthDose) {
    //   setCalculatedDose("B");
    // } else if (age < 1) {
    //   setCalculatedDose("1");
    // } else {
    //   // Adjust the logic as per your requirement
    //   // For individuals over 20 years, set dose to 'V'
    //   // For individuals 20 years or under, set dose based on age
    //   const dose = age > 20 ? "V" : age.toString();
    //   setCalculatedDose(dose);
    // }
    fetchDiseases({ fetchPolicy: "cache-first" });
  }, [
    fetchDiseases,
    data,
    patientIdentifier,
    patientItemDetails?.dateOfBirth,
    isBirthDose,
  ]);
  // Handle change for form fields
  const handleBirthDoseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsBirthDose(event.target.checked);
  };

  const handleMultipleVaccines = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsMultipleVaccines(event.target.checked);
  };

  const handleOtherDiseaseChange = (
    event: SelectChangeEvent<string>,
    encounterId: number
  ) => {
    const selectedDisease = event.target.value as string;
    logger.log(
      `Selecting other disease: ${selectedDisease} for encounter ${encounterId}`
    );

    const updatedEncounters = encounters.map((encounter) => {
      if (encounter.id === encounterId) {
        const isValidOption = encounter.formValues.otherDiseases.some(
          (disease) => disease.value === selectedDisease
        );
        return {
          ...encounter,
          formValues: {
            ...encounter.formValues,
            infectiousDisease: isValidOption ? selectedDisease : "",
            // Make sure filterVaccines is called correctly and updates the state as expected
            filteredVaccines: isValidOption
              ? filterVaccines(selectedDisease)
              : [],
          },
        };
      }
      return encounter;
    });

    setEncounters(updatedEncounters);
    // Ensure the correct accordion/tab remains expanded
    setExpanded(`panel${encounterId}`);
    // setEncounters(updatedEncounters);
    filterVaccines(selectedDisease);
  };

  const filterVaccines = (disease: string) => {
    if (data && data.listVaxDataAIRS && data.listVaxDataAIRS.items) {
      const vaccinesForDisease = data.listVaxDataAIRS.items.filter(
        (item) => item.DiseaseAntigenName === disease
      );
      const vaccineOptions: VaccineOption[] = vaccinesForDisease.map(
        (vaccine) => ({
          label: vaccine.VaccineBrandName,
          value: vaccine.VaccineCode,
        })
      );
      return vaccineOptions;
    }
    return [];
  };
  const handleChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFormChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedForms = forms.map((form, i) =>
      i === index ? { ...form, [event.target.name]: event.target.value } : form
    );
    setForms(updatedForms);
  };
  // Handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.checked,
    });
  };

  const [filteredVaccines, setFilteredVaccines] = useState<VaccineOption[]>([]);

  const filterOtherDiseases = () => {
    if (data && data.listVaxDataAIRS && data.listVaxDataAIRS.items) {
      const otherDiseasesOptions = data.listVaxDataAIRS.items
        .filter(
          (item) =>
            item.DiseaseAntigenName !== "COVID-19" &&
            item.DiseaseAntigenName !== "Influenza"
        )
        .map((vaccine) => ({
          label: vaccine.DiseaseAntigenName,
          value: vaccine.DiseaseAntigenName,
        }))
        .filter(
          (value, index, self) =>
            self.findIndex((v) => v.label === value.label) === index
        );

      return otherDiseasesOptions;
    }
    return [];
  };

  const handleDiseaseSelection = (disease: string, encounterId: number) => {
    const updatedEncounters = encounters.map((encounter) => {
      if (encounter.id === encounterId) {
        if (disease === "Others") {
          // Show the "Others" diseases dropdown and reset vaccines
          return {
            ...encounter,
            formValues: {
              ...encounter.formValues,
              infectiousDisease: disease,
              showDiseaseDropdown: true,
              filteredVaccines: [],
              otherDiseases: filterOtherDiseases(), // Assume this function returns the list of "other" diseases
            },
          };
        } else {
          // For a direct disease selection, filter vaccines and hide the "Others" dropdown
          return {
            ...encounter,
            formValues: {
              ...encounter.formValues,
              infectiousDisease: disease,
              showDiseaseDropdown: false,
              filteredVaccines: filterVaccines(disease), // Filter vaccines for the selected disease
              otherDiseases: [],
            },
          };
        }
      }
      return encounter;
    });

    setEncounters(updatedEncounters);
  };

  // const [calculatedDose, setCalculatedDose] = useState("");

  // Mock data for dropdowns
  const diseases = ["COVID-19", "Influenza", "Others"];
  const routeOptions = [
    { label: "Oral", code: "PO" },
    { label: "Subcutaneous", code: "SC" },
    { label: "Intradermal", code: "ID" },
    { label: "Intramuscular", code: "IM" },
  ];
  const siteOptions = [
    { label: "Left Arm", code: "LA" },
    { label: "Right Arm", code: "RA" },
    { label: "Left Thigh", code: "LT" },
    { label: "Right Thigh", code: "RT" },
  ];

  const vaccineFundingTypes = [
    { label: "Antenatal", code: "AEN" },
    { label: "NIP/Commonwealth", code: "NIP" },
    { label: "Private", code: "PRV" },
    { label: "State Program", code: "STP" },
  ];

  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));

  const [isOverseas, setIsOverseas] = useState(false);
  const [isOtherProvider, setIsOtherProvider] = useState(false);

  const handleOverseasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOverseas(event.target.checked);
  };

  const hanldeOtherProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOtherProvider(event.target.checked);
  };

  function handleApiResponse(response: AIRRecordEncounterResponse) {
    // Success without any errors
    let formErrors: any = {}; // Object to store errors by episode ID
    if (response.success && response.data.statusCode === "AIR-W-1008") {
      logger.log("INSIDE 1");

      // check if response.data has claimDetails and if the claimDetails have claimId
      if (response.data.claimDetails && response.data.claimDetails.claimId) {
        // set claimId in localStorage
        setClaimId(response.data.claimDetails.claimId);
      }
    }
    if (
      response.success &&
      response.data.codeType.startsWith("AIRWBU") &&
      response.data.statusCode === "AIR-W-1004"
    ) {
      logger.log("INSIDE 2");
      // Show confirmation dialog
      setShowConfirmDialog(true);
      setModalState({ ...modalState, open: false }); // Close the existing modal if open
      return; // Prevent further execution until user makes a choice
    }
    if (
      response.success &&
      !response.data.codeType.startsWith("AIRWBU") &&
      !response.data.codeType.startsWith("AIREBU")
    ) {
      logger.log("INSIDE 3");
      setSubmissionStatus({
        submitted: true,
        success: true,
        message: "Vaccination record submitted successfully",
      });
      setModalState({
        open: true,
        loading: false,
        success: true,
        message: "Vaccination record submitted successfully",
      });
      return true;
    }

    // Success but with errors in the response
    else if (
      response.success &&
      response.data.errors &&
      response.data.errors.length > 0
    ) {
      logger.log("INSIDE 4");
      let invalidMessages: string[] = [];
      logger.log(response.data.message);
      response.data.errors.forEach((error: any, index: any) => {
        const formId = index + 1; // Example of mapping, adjust as necessary
        formErrors[formId] = error.message;
        invalidMessages.push(error.code + ":" + error.message);
      });

      const updatedForms = forms.map((form, index) => {
        // Assuming the form's id matches episode.id
        const formId = index + 1;
        return {
          ...form,
          errorInfo: formErrors[formId] || null, // Add the error message if it exists
        };
      });

      // const errorMessages = response.data.errors.map((error: { message: any }) => error.message);
      const errorMessages = invalidMessages.join(", ");
      setForms(updatedForms);
      setSubmissionStatus({
        submitted: true,
        success: false,
        message: errorMessages,
      });
      setModalState({
        open: true,
        loading: false,
        success: false,
        message: errorMessages,
      });
      return false;
    }

    // Success but with warnings or invalid episodes
    else if (
      response.success &&
      (response.data.codeType.startsWith("AIRWBU") ||
        response.data.codeType.startsWith("AIREBU"))
    ) {
      logger.log("INSIDE 5");
      logger.log("Success with warnings or invalid episodes");

      let invalidMessages: string[] = [];
      let formErrors: { [key: number]: string } = {};

      // Process each encounter from the response
      response.data.claimDetails.encounters.forEach((responseEncounter) => {
        // Find the index of the matching encounter by id
        const index = encounters.findIndex(
          (enc) => enc.id === Number(responseEncounter.id)
        );

        // Only proceed if a matching encounter is found
        if (index !== -1) {
          // Update encounters state directly if status is SUCCESS or WARNING/INVALID
          if (
            ["SUCCESS", "INVALID", "WARNING"].includes(
              responseEncounter.information.status
            )
          ) {
            setEncounters((prevEncounters) => {
              const updatedEncounters = [...prevEncounters];
              updatedEncounters[index].status =
                responseEncounter.information.status;
              updatedEncounters[index].claimSequenceNumber =
                responseEncounter.claimSequenceNumber;

              return updatedEncounters;
            });

            if (responseEncounter.information.status !== "SUCCESS") {
              invalidMessages.push(
                responseEncounter.information.code +
                ":" +
                responseEncounter.information.text
              );
            }
          }

          // Process episodes if any
          if (responseEncounter.episodes) {
            responseEncounter.episodes.forEach((episode) => {
              if (
                episode.information.status === "INVALID" &&
                episode.information.text !== null
              ) {
                formErrors[episode.id] = episode.information.text;
                invalidMessages.push(
                  responseEncounter.information.code +
                  ":" +
                  episode.information.text
                );
              }
            });
          }
        }
      });

      // Update forms with errors
      const updatedForms = forms.map((form, index) => {
        const formId = index + 1; // Ensure this matches how you relate forms to episodes
        return {
          ...form,
          errorInfo: formErrors[formId] || null,
        };
      });
      logger.log("updatedForms", updatedForms);
      setForms(updatedForms);

      // Combine all messages and display them
      const combinedMessage = invalidMessages.join(", ");
      logger.log(combinedMessage);
      setSubmissionStatus({
        submitted: true,
        success: false,
        message: combinedMessage,
      });
      setModalState({
        open: true,
        loading: false,
        success: false,
        message: combinedMessage,
      });

      return false;
    }

    // Handle other cases, such as when 'data.success' is not true
    else {
      logger.log("An unexpected error occurred");
      setSubmissionStatus({
        submitted: true,
        success: false,
        message: "An unexpected error occurred",
      });
      setModalState({
        open: true,
        loading: false,
        success: false,
        message: "An unexpected error occurred",
      });
      return false;
    }
  }

  // Usage
  // Assuming you get a response object from your API call, you would use the function like this:
  const isSubmitButtonDisabled = (): boolean => {
    // If recording multiple vaccines, check if all forms are valid
    // if (isMultipleVaccines) {
    //   logger.log("areAllFormsValid", areAllFormsValid());
    //   return !areAllFormsValid();
    // }
    // // If not recording multiple vaccines, check if the main form is valid
    // return !isFormValid();
    return false;
  };

  // Form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setModalState({ open: true, loading: true, success: false, message: "" });
    const individualEntity =
      individualIdentifierDetails?.data?.individualDetails;
    let encountersPayload: any = []; // This will hold all encounters, correctly structured
    logger.log("isMultipleVaccines", isMultipleVaccines);
    logger.log("encounters", encounters);
    const encountersToSubmit = encounters.filter(
      (encounter) => encounter.status !== "SUCCESS"
    );

    if (isMultipleVaccines) {
      // Construct episodes from the forms array for multiple vaccine records
      const encounter = encounters[0]; // Assuming a structure to fetch dateOfService or other encounter level details

      let episodes = forms.map((form, index) => ({
        id: index + 1,
        ...(form.batchNumber && { vaccineBatch: form.batchNumber }),
        vaccineCode: form.vaccine,
        vaccineDose: form.dose,
        ...(form.VaccineSerialNumber && {
          vaccineSerialNumber: form.VaccineSerialNumber,
        }),
        ...(form.vaccineFundingType && {
          vaccineFundingType: form.vaccineFundingType,
        }),
        ...(form.routeOfAdministration && {
          routeOfAdministration: form.routeOfAdministration,
        }),
      }));
      encountersPayload.push({
        id: 1, // Or any appropriate encounter ID logic
        ...(claimId && {
          claimSequenceNumber: "1",
          acceptAndConfirm: "Y", // Assuming you always set this
        }), // Use the claimId from the state
        dateOfService: immunisationDate
          ? immunisationDate.format("DDMMYYYY")
          : "",
        ...(formValues.SchoolId && { schoolId: formValues.SchoolId }),
        ...(!isOverseas &&
          isOtherProvider &&
          formValues.otherProviderNumber && {
          immunisationProvider: {
            providerNumber: formValues.otherProviderNumber, // Assuming this is always set
            ...(formValues.OtherHpioNumber && {
              hpioNumber: formValues.OtherHpioNumber,
            }),
            ...(formValues.OtherHpiiNumber && {
              hpiiNumber: formValues.OtherHpiiNumber,
            }),
          },
        }),
        ...(isOverseas && {
          administeredOverseas: isOverseas,
          ...(formValues.countryCode && {
            countryCode: formValues.countryCode,
          }),
        }),
        episodes, // All episodes from the forms for this encounter
        // Add other relevant encounter details here
      });
    } else {
      // Construct a single episode based on encounter.formValues`
      encountersToSubmit.forEach((encounter, index) => {
        if (encounter.status !== "SUCCESS") {
          let episode = {
            id: 1,
            ...(encounter.formValues.batchNumber && {
              vaccineBatch: encounter.formValues.batchNumber,
            }),
            vaccineCode: encounter.formValues.vaccine,
            vaccineDose: encounter.formValues.dose,
            ...(encounter.formValues.VaccineSerialNumber && {
              vaccineSerialNumber: encounter.formValues.VaccineSerialNumber,
            }),
            ...(encounter.formValues.vaccineFundingType && {
              vaccineFundingType: encounter.formValues.vaccineFundingType,
            }),
            ...(encounter.formValues.routeOfAdministration && {
              routeOfAdministration: encounter.formValues.routeOfAdministration,
            }),
          };
          encountersPayload.push({
            id: index + 1,
            ...(claimId && {
              claimSequenceNumber: encounter.claimSequenceNumber,
              acceptAndConfirm: "Y", // Assuming you always set this
            }), // Use the claimId from the state
            dateOfService: encounter.formValues.immunisationDate
              ? encounter.formValues.immunisationDate.format("DDMMYYYY")
              : "",
            ...(encounter.formValues.SchoolId && {
              schoolId: encounter.formValues.SchoolId,
            }),
            ...(!encounter.formValues.isOverseas &&
              encounter.formValues.isOtherProvider &&
              encounter.formValues.otherProviderNumber && {
              immunisationProvider: {
                providerNumber: encounter.formValues.otherProviderNumber, // Assuming this is always set
                ...(encounter.formValues.OtherHpioNumber && {
                  hpioNumber: encounter.formValues.OtherHpioNumber,
                }),
                ...(encounter.formValues.OtherHpiiNumber && {
                  hpiiNumber: encounter.formValues.OtherHpiiNumber,
                }),
              },
            }),
            ...(encounter.formValues.isOverseas && {
              administeredOverseas: encounter.formValues.isOverseas,
              ...(encounter.formValues.countryCode && {
                countryCode: encounter.formValues.countryCode,
              }),
            }),
            episodes: [episode], // Wrap the single episode in an array
            // Add other relevant encounter details here
          });
        }
      });
    }

    const buildPayload = () => {
      const individual = {
        ...(patientItemDetails?.medicareCardNumber && {
          medicareCard: {
            medicareCardNumber: patientItemDetails.medicareCardNumber,
            medicareIRN: patientItemDetails.medicareIRN,
          },
        }),
        personalDetails: {
          ...(patientItemDetails?.firstName && {
            firstName: patientItemDetails.firstName,
          }),
          ...(patientItemDetails?.lastName && {
            lastName: patientItemDetails.lastName,
          }),
          ...(patientItemDetails?.dateOfBirth && {
            dateOfBirth: patientItemDetails.dateOfBirth
              .split("-")
              .reverse()
              .join(""),
          }),
          ...(patientItemDetails?.gender && {
            gender: patientItemDetails.gender,
          }),
          ...(patientItemDetails?.onlyNameIndicator && {
            onlyNameIndicator: patientItemDetails.onlyNameIndicator,
          }),
        },
        address: {
          ...(patientItemDetails?.addressLineOne && {
            addressLineOne: patientItemDetails.addressLineOne,
          }),
          ...(patientItemDetails?.addressLineTwo && {
            addressLineTwo: patientItemDetails.addressLineTwo,
          }),
          ...(patientItemDetails?.locality && {
            locality: patientItemDetails.locality,
          }),
          ...(patientItemDetails?.postCode && {
            postCode: patientItemDetails.postCode,
          }),
        },
        ...(patientItemDetails?.atsiIndicator && {
          atsiIndicator: patientItemDetails.atsiIndicator,
        }),
        ...(patientItemDetails?.ihiNumber && {
          ihiNumber: patientItemDetails.ihiNumber,
        }),
        ...(acceptAndConfirm === "Y"
          ? {
            acceptAndConfirm: acceptAndConfirm,
          }
          : {}),
      };

      return {
        individualIdentifier: individualEntity?.individualIdentifier ?? "",
        individual,
        ...(claimId && { claimId }), // Use the claimId from the state
        informationProvider: {
          providerNumber: providerNumber, // Assuming this is always set
          ...(hpioNumber && { hpioNumber }),
          ...(hpiiNumber && { hpiiNumber }),
        },
        encounters: encountersPayload,
        minorId: minorId,
        deviceName: deviceName,
        prodaOrgId: prodaOrgId,
      };
    };

    // Call the API
    const payload: any = buildPayload();
    logger.log("payload:", payload);

    try {
      logger.log(payload);
      const apiAIRRercordEncounter = process.env.REACT_APP_RECORD_ENCOUNTER;
      if (!apiAIRRercordEncounter) {
        throw new Error("REACT_APP_RECORD_ENCOUNTER is not defined");
      }
      const response = await fetch(apiAIRRercordEncounter, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: AIRRecordEncounterResponse = await response.json();
      logger.log("Response:", data);
      const submissionStatus = handleApiResponse(data);
      logger.log("submissionStatus", submissionStatus);
      if (submissionStatus) {
        setFormValues(initialFormState);
        // Reset any other state related to form submission if needed
        // setSubmissionStatus({
        //   submitted: false,
        //   success: false,
        //   message: "",
        // });
      }
    } catch (error) {
      setSubmissionStatus({
        submitted: true,
        success: false,
        message: `Error submitting form: ${(error as Error).message}`,
      });
      setModalState({
        open: true,
        loading: false,
        success: false,
        message: "An unexpected error occurred",
      });
      console.error("Error submitting form:", error);
    }
  };

  const submitNewCustomer = async () => {
    // NEW CUSTOMER HAS SO MANY GOTCHAS,
    setModalState({ open: true, loading: true, success: false, message: "" });
    logger.log("patientItemDetails", patientItemDetails);
    const individualEntity =
      individualIdentifierDetails?.data?.individualDetails;
    logger.log("Individual Entity:", individualEntity);
    let episodes: Episode[] = [];
    logger.log("isMultipleVaccines", isMultipleVaccines);
    if (isMultipleVaccines) {
      // Construct episodes from the forms array for multiple vaccine records
      logger.log("FORMS INSIDE ", forms);
      episodes = forms.map((form, index) => ({
        id: index + 1,
        ...(form.batchNumber && { vaccineBatch: form.batchNumber }),
        vaccineCode: form.vaccine,
        vaccineDose: form.dose, // Use form.dose or a calculated/default value
        ...(form.VaccineSerialNumber && {
          vaccineSerialNumber: form.VaccineSerialNumber,
        }),
        ...(form.vaccineFundingType && {
          vaccineFundingType: form.vaccineFundingType,
        }),
        ...(form.routeOfAdministration && {
          routeOfAdministration: form.routeOfAdministration,
        }),
        // dateOfService: form.immunisationDate ? form.immunisationDate.format("DDMMYYYY") : "",
        // Add more fields as required
      }));
    } else {
      // Construct a single episode based on default/singular form values if Record Multiple Vaccines is not checked
      episodes = [
        {
          id: 1,
          ...(formValues.batchNumber && {
            vaccineBatch: formValues.batchNumber,
          }),
          vaccineCode: formValues.vaccine,
          vaccineDose: formValues.dose, // Adjust accordingly
          ...(formValues.VaccineSerialNumber && {
            vaccineSerialNumber: formValues.VaccineSerialNumber,
          }),
          ...(formValues.vaccineFundingType && {
            vaccineFundingType: formValues.vaccineFundingType,
          }),
          ...(formValues.routeOfAdministration && {
            routeOfAdministration: formValues.routeOfAdministration,
          }),
          // dateOfService: immunisationDate ? immunisationDate.format("DDMMYYYY") : "",
        },
      ];
    }
    const buildPayload = () => {
      const individual = {
        ...(patientItemDetails?.medicareCardNumber && {
          medicareCard: {
            medicareCardNumber: patientItemDetails.medicareCardNumber,
            medicareIRN: patientItemDetails.medicareIRN,
          },
        }),
        personalDetails: {
          ...(patientItemDetails?.firstName && {
            firstName: patientItemDetails.firstName,
          }),
          ...(patientItemDetails?.lastName && {
            lastName: patientItemDetails.lastName,
          }),
          ...(patientItemDetails?.dateOfBirth && {
            dateOfBirth: patientItemDetails.dateOfBirth
              .split("-")
              .reverse()
              .join(""),
          }),
          ...(patientItemDetails?.gender && {
            gender: patientItemDetails.gender,
          }),
          ...(patientItemDetails?.onlyNameIndicator && {
            onlyNameIndicator: patientItemDetails.onlyNameIndicator,
          }),
        },
        address: {
          ...(patientItemDetails?.addressLineOne && {
            addressLineOne: patientItemDetails.addressLineOne,
          }),
          ...(patientItemDetails?.addressLineTwo && {
            addressLineTwo: patientItemDetails.addressLineTwo,
          }),
          ...(patientItemDetails?.locality && {
            locality: patientItemDetails.locality,
          }),
          ...(patientItemDetails?.postCode && {
            postCode: patientItemDetails.postCode,
          }),
        },
        ...(patientItemDetails?.isAboriginal && {
          atsiIndicator: "Y",
        }),
        ...(patientItemDetails?.ihiNumber && {
          ihiNumber: patientItemDetails.ihiNumber,
        }),
        ...(acceptAndConfirm === "Y"
          ? {
            acceptAndConfirm: acceptAndConfirm,
          }
          : {}),
      };

      // const episodes = [
      //   {
      //     id: 1,
      //     ...(formValues.batchNumber && { vaccineBatch: formValues.batchNumber }),
      //     ...(formValues.vaccine && { vaccineCode: formValues.vaccine }),
      //     vaccineDose: calculatedDose, // Assuming this is always set
      //     ...(formValues.VaccineSerialNumber && { vaccineSerialNumber: formValues.VaccineSerialNumber }),
      //     ...(formValues.vaccineFundingType && { vaccineFundingType: formValues.vaccineFundingType }),
      //     ...(formValues.routeOfAdministration && { routeOfAdministration: formValues.routeOfAdministration }),
      //   },
      // ];

      const encounters = [
        {
          id: 1,
          dateOfService: immunisationDate
            ? immunisationDate.format("DDMMYYYY")
            : "",
          // ...(claimId && { claimId }), // Use the claimId from the state
          ...(!isOverseas &&
            isOtherProvider &&
            formValues.otherProviderNumber && {
            immunisationProvider: {
              providerNumber: formValues.otherProviderNumber, // Assuming this is always set
              ...(formValues.OtherHpioNumber && { hpioNumber }),
              ...(formValues.OtherHpiiNumber && { hpiiNumber }),
            },
          }),
          episodes,
          ...(isOverseas && {
            administeredOverseas: isOverseas,
            ...(formValues.countryCode && {
              countryCode: formValues.countryCode,
            }),
          }),
        },
      ];

      return {
        individualIdentifier: individualEntity?.individualIdentifier ?? "",
        individual,
        ...(claimId && { claimId }), // Use the claimId from the state
        informationProvider: {
          providerNumber: providerNumber, // Assuming this is always set
          ...(hpioNumber && { hpioNumber }),
          ...(hpiiNumber && { hpiiNumber }),
        },
        encounters,
        minorId: minorId,
        deviceName: deviceName,
        prodaOrgId: prodaOrgId,
      };
    };

    // Call the API
    const payload: any = buildPayload();
    logger.log("claimId:", formValues.claimId);
    logger.log("Payload:", payload);

    try {
      logger.log(payload);
      const apiAIRRercordEncounter = process.env.REACT_APP_RECORD_ENCOUNTER;
      if (!apiAIRRercordEncounter) {
        throw new Error("REACT_APP_RECORD_ENCOUNTER is not defined");
      }
      const response = await fetch(apiAIRRercordEncounter, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: AIRRecordEncounterResponse = await response.json();
      logger.log("Response:", data);
      handleApiResponse(data);
    } catch (error) {
      setSubmissionStatus({
        submitted: true,
        success: false,
        message: `Error submitting form: ${(error as Error).message}`,
      });
      console.error("Error submitting form:", error);
    }
  };
  interface StatusIndicator {
    key: keyof any; // Use 'keyof IndividualDetails' to ensure keys exist on the IndividualDetails interface
    label: string;
    message: string;
  }

  const statusIndicators: StatusIndicator[] = [
    {
      key: "indigenousStatus",
      label: "Indigenous Status",
      message: "This individual is identified as Indigenous.",
    },
    {
      key: "additionalVaccineIndicator",
      label: "Additional Vaccine Indicator",
      message: "Eligible for additional vaccines.",
    },
    {
      key: "medContraindicationIndicator",
      label: "Medical Contraindication Indicator",
      message: "Has medical contraindications.",
    },
    {
      key: "naturalImmunityIndicator",
      label: "Natural Immunity Indicator",
      message: "Identified to have natural immunity.",
    },
    {
      key: "vaccineTrialIndicator",
      label: "Vaccine Trial Indicator",
      message: "Participated in a vaccine trial.",
    },
    {
      key: "actionRequiredIndicator",
      label: "Action Required Indicator",
      message: "Action required.",
    },
  ];

  const areAllFormsValid = (): boolean => {
    return forms.every((form) => {
      // Example validation for a few fields, ensure to check for undefined or use optional chaining
      const vaccineValid = form.vaccine?.trim() !== "";
      const vaccineSerialNumberValid = form.VaccineSerialNumber?.trim() !== "";
      const routeOfAdministrationValid =
        form.routeOfAdministration?.trim() !== "";
      const batchNumberValid = form.batchNumber?.trim() !== "";

      // Add more validation checks as necessary
      return (
        vaccineValid &&
        vaccineSerialNumberValid &&
        routeOfAdministrationValid &&
        batchNumberValid
      );
    });
  };

  const isFormValid = () => {
    // Check if the immunisationDate is set and format it
    const dateOfServiceValid = immunisationDate ? true : false;

    // Basic checks for other fields
    const basicFieldsValid =
      formValues.vaccine.trim() !== "" &&
      formValues.VaccineSerialNumber.trim() !== "" &&
      formValues.routeOfAdministration.trim() !== "" &&
      formValues.batchNumber.trim() !== "" &&
      formValues.cmiProvided !== false;
    // Check for fields that are conditionally required
    const overseasValid =
      !isOverseas || (isOverseas && formValues.countryCode.trim() !== "");

    return dateOfServiceValid && basicFieldsValid && overseasValid;
  };
  const getTrueIndicatorMessages = (
    individualDetails: any | null
  ): string[] => {
    if (!individualDetails) return [];

    const messages: string[] = [];
    statusIndicators.forEach((indicator) => {
      if (individualDetails[indicator.key]) {
        // TypeScript knows `indicator.key` is a valid key of IndividualDetails
        messages.push(indicator.message);
      }
    });

    return messages;
  };

  useEffect(() => {
    // This effect listens for changes to acceptAndConfirm state.
    // When the user confirms the action, the state is set to "Y",
    // triggering this effect and calling submitNewCustomer.
    if (acceptAndConfirm === "Y") {
      submitNewCustomer();
      setAcceptAndConfirm(""); // Reset to default state or another appropriate value
    }
  }, [acceptAndConfirm]); // Depend on acceptAndConfirm state

  // Dialog Actions
  <DialogActions>
    <Button onClick={() => setShowConfirmDialog(false)} color="primary">
      No
    </Button>
    <Button
      onClick={() => {
        setAcceptAndConfirm("Y"); // Set state to indicate confirmation
        setShowConfirmDialog(false); // Close dialog
      }}
      color="primary"
      autoFocus
    >
      Yes
    </Button>
  </DialogActions>;

  const trueIndicatorMessages = getTrueIndicatorMessages(
    individualIdentifierDetails?.data?.individualDetails
  );

  const handleFormUpdate = (index: number, updatedFormValues: FormValue) => {
    const updatedForms = forms.map((form, formIndex) =>
      formIndex === index ? updatedFormValues : form
    );
    setForms(updatedForms);
  };

  const [expanded, setExpanded] = useState<string | false>(false);

  const addEncounter = () => {
    if (encounters.length < 5) {
      // Limit to 5 encounters
      const newId =
        encounters.length > 0 ? encounters[encounters.length - 1].id + 1 : 1;
      setEncounters([
        ...encounters,
        {
          id: newId,
          status: "", // Default status for a new encounter
          claimSequenceNumber: "1",
          formValues: { ...initialFormState, showDiseaseDropdown: false },
        },
      ]);
      setExpanded(`panel${newId}`); // Set the new tab as expanded
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (idTokenError) {
    return (
      <Typography color="error">
        Error loading authentication token: {idTokenError}
      </Typography>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3} alignItems="flex-start" sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            p={2}
            sx={{ mt: -1 }}
          >
            {encounters.map((encounter, index) => (
              <Grid item xs={12} md={7} key={encounter.id || index}>
                <Accordion
                  expanded={expanded === `panel${encounter.id}`}
                  sx={{
                    ...(encounter.status === "SUCCESS" && {
                      bgcolor: "#14a37f",
                    }),
                  }}
                  onChange={() =>
                    setExpanded(
                      expanded !== `panel${encounter.id}`
                        ? `panel${encounter.id}`
                        : false
                    )
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${encounter.id}-content`}
                    id={`panel${encounter.id}-header`}
                  >
                    <Typography>Vaccine Encounter {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper>
                      <Box p={2} sx={{ mt: -1 }}>
                        <FormControl
                          margin="normal"
                          variant="outlined"
                          size="small"
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container alignItems="center" spacing={2}>
                              {/* Adds spacing between grid items */}
                              <Grid item xs={12} sm>
                                {/* Adjusts size for different screen sizes */}
                                <DatePicker
                                  label="Date of Service"
                                  format="DD/MM/YYYY"
                                  value={encounter.formValues.immunisationDate}
                                  onChange={(date) => {
                                    const updatedEncounters = encounters.map(
                                      (e) =>
                                        e.id === encounter.id
                                          ? {
                                            ...e,
                                            formValues: {
                                              ...e.formValues,
                                              immunisationDate:
                                                date || dayjs(null),
                                            },
                                          }
                                          : e
                                    );
                                    setEncounters(updatedEncounters);
                                  }}
                                  disableFuture
                                  onError={(newError) => setDateError(newError)}
                                  slotProps={{ textField: { size: "small" } }}
                                />
                              </Grid>
                              <Grid item xs={12} sm>
                                {/* Adjusts size for different screen sizes */}
                                <Box
                                  sx={{ display: "flex", flexWrap: "nowrap" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isMultipleVaccines}
                                        onChange={handleMultipleVaccines}
                                      />
                                    }
                                    label="Record Multiple Vaccines for the same date?"
                                  />
                                </Box>
                              </Grid>
                              {/* {isMultipleVaccines ? (
                      <Grid item xs={12} sm>
                        <Box sx={{ display: "flex", justifyContent: "left", mt: 0 }}>
                          <Button variant="outlined" startIcon={<AddOutlinedIcon />} onClick={addForm} sx={{ mt: 0 }}>
                            Add Vaccine
                          </Button>
                        </Box>
                      </Grid>
                    ) : null} */}
                            </Grid>
                          </LocalizationProvider>
                        </FormControl>
                        {isMultipleVaccines ? (
                          // Render accordion forms for multiple vaccines

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {forms.map((form, index) => (
                              <VaccineRecordForm
                                key={index}
                                index={index}
                                formValues={form}
                                setFormValues={(newFormValues) =>
                                  handleFormUpdate(index, newFormValues)
                                }
                                removeForm={() => removeForm(index)}
                                handleFormUpdate={handleFormUpdate} // Add this line
                                errorInfo={form.errorInfo} // Pass the error info here
                              />
                            ))}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "left",
                                mt: 1,
                              }}
                            >
                              <Button
                                variant="outlined"
                                startIcon={<AddOutlinedIcon />}
                                onClick={addForm}
                                sx={{
                                  display: "flex",
                                  gap: 0,
                                  mt: 1,
                                  marginBottom: 0,
                                  fontWeight: "bold",
                                  mb: 1,
                                }}
                              >
                                Add Vaccine
                              </Button>
                            </Box>
                            {/* <Box sx={{ display: "flex", justifyContent: "left", mt: 0 }}>
                    <Fab size="small" color="primary" aria-label="add" onClick={addForm}>
                      <AddIcon />
                    </Fab>
                  </Box> */}
                          </LocalizationProvider>
                        ) : (
                          <div>
                            <FormControl
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              size="small"
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  marginBottom: 0,
                                  fontWeight: "bold",
                                }}
                              >
                                {diseases.map((disease) => (
                                  <Button
                                    key={disease}
                                    variant={
                                      encounter.formValues.infectiousDisease ===
                                        disease ||
                                        (disease === "Others" &&
                                          showDiseaseDropdown)
                                        ? "contained"
                                        : "outlined"
                                    }
                                    onClick={() =>
                                      handleDiseaseSelection(
                                        disease,
                                        encounter.id
                                      )
                                    }
                                    sx={{
                                      textTransform: "none",
                                      minWidth: "100px",
                                      flex: 1,
                                      justifyContent: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {disease}
                                  </Button>
                                ))}
                              </Box>
                              {encounter.formValues.showDiseaseDropdown && (
                                <FormControl
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  size="small"
                                >
                                  <InputLabel id="other-diseases-label">
                                    Other Diseases
                                  </InputLabel>
                                  <Select
                                    labelId="other-diseases-label"
                                    name="infectiousDisease"
                                    value={
                                      encounter.formValues.infectiousDisease
                                    }
                                    onChange={(event) =>
                                      handleOtherDiseaseChange(
                                        event,
                                        encounter.id
                                      )
                                    }
                                    label="Other Diseases"
                                  >
                                    {encounter.formValues.otherDiseases.map(
                                      (disease) => (
                                        <MenuItem
                                          key={disease.value}
                                          value={disease.value}
                                        >
                                          {disease.label}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            </FormControl>
                            <FormControl
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="vaccine-label">
                                Vaccines
                              </InputLabel>
                              <Select
                                labelId="vaccine-label"
                                name="vaccine"
                                value={encounter.formValues.vaccine}
                                onChange={(event) => {
                                  const updatedEncounters = encounters.map(
                                    (e) =>
                                      e.id === encounter.id
                                        ? {
                                          ...e,
                                          formValues: {
                                            ...e.formValues,
                                            vaccine: event.target.value,
                                          },
                                        }
                                        : e
                                  );
                                  setEncounters(updatedEncounters);
                                }}
                                label="Vaccine label"
                              >
                                {encounter.formValues.filteredVaccines.map(
                                  (vaccine) => (
                                    <MenuItem
                                      key={vaccine.value}
                                      value={vaccine.value}
                                    >
                                      {vaccine.label}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                            <FormControl
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="route-admin-label">
                                Route of Administration
                              </InputLabel>
                              <Select
                                labelId="route-admin-label"
                                id="routeOfAdministration"
                                name="routeOfAdministration"
                                value={
                                  encounter.formValues.routeOfAdministration
                                }
                                onChange={(event) => {
                                  const updatedEncounters = encounters.map(
                                    (e) =>
                                      e.id === encounter.id
                                        ? {
                                          ...e,
                                          formValues: {
                                            ...e.formValues,
                                            routeOfAdministration:
                                              event.target.value,
                                          },
                                        }
                                        : e
                                  );
                                  setEncounters(updatedEncounters);
                                }}
                                label="Route of Administration"
                              >
                                {routeOptions.map((option) => (
                                  <MenuItem
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="site-admin-label">
                                Site of Administration
                              </InputLabel>
                              <Select
                                labelId="site-admin-label"
                                id="siteOfAdministration"
                                name="siteOfAdministration"
                                value={
                                  encounter.formValues.siteOfAdministration
                                }
                                onChange={(event) => {
                                  const updatedEncounters = encounters.map(
                                    (e) =>
                                      e.id === encounter.id
                                        ? {
                                          ...e,
                                          formValues: {
                                            ...e.formValues,
                                            siteOfAdministration:
                                              event.target.value,
                                          },
                                        }
                                        : e
                                  );
                                  setEncounters(updatedEncounters);
                                }}
                                label="Site of Administration"
                              >
                                {siteOptions.map((option) => (
                                  <MenuItem
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <TextField
                              label="Batch Number"
                              type="text"
                              name="batchNumber"
                              size="small"
                              value={encounter.formValues.batchNumber}
                              onChange={(event) => {
                                const updatedEncounters = encounters.map((e) =>
                                  e.id === encounter.id
                                    ? {
                                      ...e,
                                      formValues: {
                                        ...e.formValues,
                                        batchNumber: event.target.value,
                                      },
                                    }
                                    : e
                                );
                                setEncounters(updatedEncounters);
                              }}
                              fullWidth
                              margin="normal"
                            />
                            <TextField
                              label="Vaccine Dose"
                              type="text"
                              name="dose"
                              size="small"
                              value={encounter.formValues.dose}
                              onChange={(event) => {
                                const updatedEncounters = encounters.map((e) =>
                                  e.id === encounter.id
                                    ? {
                                      ...e,
                                      formValues: {
                                        ...e.formValues,
                                        dose: event.target.value,
                                      },
                                    }
                                    : e
                                );
                                setEncounters(updatedEncounters);
                              }}
                              fullWidth
                              margin="normal"
                            />
                            <FormControl
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              size="small"
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                  label="Expiry Date"
                                  size="small"
                                  format="MM/YYYY"
                                  onChange={(newValue) => setValue(value)}
                                  fullWidth
                                />
                              </LocalizationProvider>
                            </FormControl>
                            <TextField
                              label="Vaccine Serial Number"
                              type="text"
                              name="VaccineSerialNumber"
                              size="small"
                              value={encounter.formValues.VaccineSerialNumber}
                              onChange={(event) => {
                                const updatedEncounters = encounters.map((e) =>
                                  e.id === encounter.id
                                    ? {
                                      ...e,
                                      formValues: {
                                        ...e.formValues,
                                        VaccineSerialNumber:
                                          event.target.value,
                                      },
                                    }
                                    : e
                                );
                                setEncounters(updatedEncounters);
                              }}
                              fullWidth
                              margin="normal"
                            />
                            <FormControl
                              fullWidth
                              margin="normal"
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="funding-type-label">
                                Vaccine Funding Type
                              </InputLabel>
                              <Select
                                labelId="funding-type-label"
                                id="vaccineFundingType"
                                name="vaccineFundingType"
                                value={encounter.formValues.vaccineFundingType}
                                onChange={(event) => {
                                  const updatedEncounters = encounters.map(
                                    (e) =>
                                      e.id === encounter.id
                                        ? {
                                          ...e,
                                          formValues: {
                                            ...e.formValues,
                                            vaccineFundingType:
                                              event.target.value,
                                          },
                                        }
                                        : e
                                  );
                                  setEncounters(updatedEncounters);
                                }}
                                label="Vaccine Funding Type"
                              >
                                {vaccineFundingTypes.map((option) => (
                                  <MenuItem
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {/* <TextField
                    label="School Id"
                    type="text"
                    name="SchoolId"
                    size="small"
                    value={formValues.SchoolId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<Switch checked={isOverseas} onChange={handleOverseasChange} />}
                      label="Administered Overseas"
                      labelPlacement="end"
                    />
                  </FormGroup>

                  {isOverseas && (
                    <Autocomplete
                      options={countryCodes}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" margin="normal" size="small" variant="outlined" />
                      )}
                      onChange={(event, newValue) => {
                        setFormValues({
                          ...formValues,
                          countryCode: newValue?.code ?? "",
                        });
                      }}
                      fullWidth
                    />
                  )} */}
                          </div>
                        )}
                        <TextField
                          label="School Id"
                          type="text"
                          name="SchoolId"
                          size="small"
                          value={encounter.formValues.SchoolId}
                          onChange={(event) => {
                            const updatedEncounters = encounters.map((e) =>
                              e.id === encounter.id
                                ? {
                                  ...e,
                                  formValues: {
                                    ...e.formValues,
                                    SchoolId: event.target.value,
                                  },
                                }
                                : e
                            );
                            setEncounters(updatedEncounters);
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={encounter.formValues.isOverseas}
                                onChange={(event) => {
                                  const updatedEncounters = encounters.map(
                                    (e) =>
                                      e.id === encounter.id
                                        ? {
                                          ...e,
                                          formValues: {
                                            ...e.formValues,
                                            isOverseas: event.target.checked,
                                          },
                                        }
                                        : e
                                  );
                                  setEncounters(updatedEncounters);
                                }}
                              />
                            }
                            label="Administered Overseas"
                            labelPlacement="end"
                          />
                        </FormGroup>
                        {encounter.formValues.isOverseas && (
                          <Autocomplete
                            options={countryCodes}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Country"
                                margin="normal"
                                size="small"
                                variant="outlined"
                              />
                            )}
                            onChange={(event, newValue) => {
                              const updatedEncounters = encounters.map((e) =>
                                e.id === encounter.id
                                  ? {
                                    ...e,
                                    formValues: {
                                      ...e.formValues,
                                      countryCode: newValue?.code ?? "",
                                    },
                                  }
                                  : e
                              );
                              setEncounters(updatedEncounters);
                            }}
                            fullWidth
                          />
                        )}
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={encounter.formValues.isOtherProvider}
                                onChange={(event) => {
                                  const updatedEncounters = encounters.map(
                                    (e) =>
                                      e.id === encounter.id
                                        ? {
                                          ...e,
                                          formValues: {
                                            ...e.formValues,
                                            isOtherProvider:
                                              event.target.checked,
                                          },
                                        }
                                        : e
                                  );
                                  setEncounters(updatedEncounters);
                                }}
                              />
                            }
                            label="Administered By Other Provider"
                            labelPlacement="end"
                          />
                          {encounter.formValues.isOtherProvider && (
                            <Grid container spacing={0}>
                              <TextField
                                label="Other Provider Number"
                                type="text"
                                name="otherProviderNumber"
                                size="small"
                                value={encounter.formValues.otherProviderNumber}
                                onChange={(event) => {
                                  const updatedEncounters = encounters.map(
                                    (e) =>
                                      e.id === encounter.id
                                        ? {
                                          ...e,
                                          formValues: {
                                            ...e.formValues,
                                            otherProviderNumber:
                                              event.target.value,
                                          },
                                        }
                                        : e
                                  );
                                  setEncounters(updatedEncounters);
                                }}
                                fullWidth
                                margin="normal"
                              />
                              <Grid>
                                <TextField
                                  label="Other Provider HPIO Number"
                                  type="text"
                                  name="OtherHpioNumber"
                                  size="small"
                                  value={encounter.formValues.OtherHpioNumber}
                                  onChange={(event) => {
                                    const updatedEncounters = encounters.map(
                                      (e) =>
                                        e.id === encounter.id
                                          ? {
                                            ...e,
                                            formValues: {
                                              ...e.formValues,
                                              OtherHpioNumber:
                                                event.target.value,
                                            },
                                          }
                                          : e
                                    );
                                    setEncounters(updatedEncounters);
                                  }}
                                  fullWidth
                                  margin="normal"
                                />
                                <TextField
                                  label="Other Provider HPIINumber"
                                  type="text"
                                  name="OtherHpiiNumber"
                                  size="small"
                                  value={encounter.formValues.OtherHpiiNumber}
                                  onChange={(event) => {
                                    const updatedEncounters = encounters.map(
                                      (e) =>
                                        e.id === encounter.id
                                          ? {
                                            ...e,
                                            formValues: {
                                              ...e.formValues,
                                              OtherHpiiNumber:
                                                event.target.value,
                                            },
                                          }
                                          : e
                                    );
                                    setEncounters(updatedEncounters);
                                  }}
                                  fullWidth
                                  margin="normal"
                                />
                              </Grid>
                            </Grid>
                          )}
                        </FormGroup>
                        <Button
                          variant="outlined"
                          startIcon={<AddOutlinedIcon />}
                          onClick={addEncounter}
                          sx={{
                            display: "flex",
                            gap: 0,
                            mt: 1,
                            marginBottom: 0,
                            fontWeight: "bold",
                            mb: 1,
                          }}
                        >
                          Add Encounter
                        </Button>
                      </Box>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
            <Grid item xs={12} md={7}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues.cmiProvided}
                      onChange={handleCheckboxChange}
                      name="cmiProvided"
                    />
                  }
                  label="The Patient has consented to receive the vaccination"
                />
              </FormGroup>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues.deemedEligible}
                      onChange={handleCheckboxChange}
                      name="deemedEligible"
                    />
                  }
                  label="The patient has been evaluated and deemed eligible for vaccination"
                />
              </FormGroup>

              {/* Submit Button */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitButtonDisabled()}
              >
                Submit With AIR
              </Button>
              {submissionStatus.submitted &&
                submissionStatus.message.split(", ").map((message, index) => (
                  <Alert
                    key={index}
                    severity={submissionStatus.success ? "success" : "error"}
                    style={{ marginBottom: "8px" }}
                  >
                    {message}
                  </Alert>
                ))}

              <Modal
                open={modalState.open}
                closeAfterTransition
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "400",
                    // maxWidth: "90%",
                    maxHeight: "auto",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {modalState.loading ? (
                    <>
                      <CircularProgress
                        size={70}
                        thickness={4.5}
                        sx={{ color: "primary.main" }}
                      />
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Vaccine submission to AIR in progress...
                      </Typography>
                    </>
                  ) : (
                    <>
                      {modalState.success ? (
                        <CheckCircleOutlineIcon
                          sx={{ color: "success.main", fontSize: 40 }}
                        />
                      ) : (
                        <ErrorOutlineIcon
                          sx={{ color: "error.main", fontSize: 40 }}
                        /> // This is the X icon for error
                      )}
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        {modalState.message}
                      </Typography>
                      <Button
                        variant="contained"
                        color={modalState.success ? "success" : "error"}
                        onClick={() =>
                          setModalState({ ...modalState, open: false })
                        }
                        sx={{
                          mt: 3,
                          borderRadius: 20,
                          paddingX: 3,
                          ":hover": {
                            backgroundColor: modalState.success
                              ? "success.dark"
                              : "error.dark",
                          },
                        }}
                      >
                        Close
                      </Button>
                    </>
                  )}
                </Box>
              </Modal>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Confirm Individual Details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Individual does not exist in AIR. Do you want to create an record
            the vaccination for a new individual?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              setAcceptAndConfirm("Y");
              setShowConfirmDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RecordHistoricalVaccinations;
