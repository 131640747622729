import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LogoDark from '../../assets/images/logo-dark.png';
import LogoLight from '../../assets/images/logo-light.png';

interface AccountLayoutProps {
  helpText?: string;
  bottomLinks?: any;
  isCombineForm?: boolean;
  children?: any;
}

const AuthLayout = ({ helpText, bottomLinks, children, isCombineForm }: AccountLayoutProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (document.body) {
      document.body.classList.remove('authentication-bg', 'authentication-bg-pattern');
      document.body.classList.add('auth-fluid-pages', 'pb-0');
    }

    return () => {
      if (document.body) document.body.classList.remove('auth-fluid-pages', 'pb-0');
    };
  }, []);

  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <div className="auth-content-wrapper">
          <div className="auth-brand text-center text-lg-start">
            <div className="auth-logo">
              <Link to="/" className="logo">
                <img src={LogoDark} alt="Dragatron" height="88" className="logo-dark" />
                <img src={LogoLight} alt="Dragatron" height="88" className="logo-light" />
              </Link>
            </div>
          </div>
          {children}
          {bottomLinks}
        </div>
      </div>

      <div className="auth-fluid-right">
        <div className="auth-user-testimonial">
          <h1 className="auth-tagline">{helpText}</h1>
          <div className="auth-icon-container"></div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
