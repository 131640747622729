import { InputLabel, Select, FormControl, MenuItem, FormControlProps, SelectProps } from '@mui/material';
import { GENDERS } from '../constants';
import { forwardRef, useEffect, useState } from 'react';

type Props = {
  defaultValue?: typeof GENDERS[0]['value'];
  onSelect?: (gender: typeof GENDERS[0]['value']) => void;
  formControlProps?: FormControlProps;
} & Omit<SelectProps<string>, 'onSelect' | 'defaultValue'>;

const GenderDropdown = forwardRef<HTMLInputElement, Props>(
  ({ defaultValue, onSelect, formControlProps, ...rest }, ref) => {
    const [gender, setGender] = useState(defaultValue || GENDERS[0].value);

    useEffect(() => {
      if (typeof onSelect === 'function') {
        onSelect(gender);
      }
    }, [gender]);

    useEffect(() => {
      if (defaultValue && defaultValue !== '') {
        setGender(defaultValue);
      }
    }, [defaultValue]);

    return (
      <FormControl fullWidth {...formControlProps}>
        <InputLabel id="gender-select-label">Gender</InputLabel>
        <Select
          labelId="gender-select-label"
          id="gender-simple-select"
          value={gender}
          label="gender"
          name="gender"
          onChange={(event, _child) => setGender(event.target.value as string)}
          {...rest}
          ref={ref}
        >
          <MenuItem value="" disabled>
            Select Gender
          </MenuItem>
          {GENDERS.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
);

export default GenderDropdown;
