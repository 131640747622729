import React from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
    Card,
    CardContent,
} from '@mui/material';
import { Calendar, Clock, FileText, Edit, Trash2, PlusCircle, Phone, Video, Mail, UserPlus, MessageSquare } from 'lucide-react';
import dayjs from 'dayjs';
import { PatientFollowUp } from '../types/PatientFollowUp'; // Updated import

interface ScheduledFollowUpsProps {
    followUps: PatientFollowUp[];
    handleEditFollowUp: (id: string) => void;
    handleDeleteFollowUp: (id: string) => void;
    fetchMore: () => void;
    hasMore: boolean;
    isLoading: boolean;
}

const FollowUpIcon: React.FC<{ followupType: string }> = ({ followupType }) => {
    const getIconProps = (type: string) => {
        const baseProps = { size: 24 };
        switch (type) {
            case 'phone':
                return { ...baseProps, color: '#4CAF50', bgColor: '#E8F5E9' };
            case 'video':
                return { ...baseProps, color: '#2196F3', bgColor: '#E3F2FD' };
            case 'email':
                return { ...baseProps, color: '#FFC107', bgColor: '#FFF8E1' };
            case 'in-person':
                return { ...baseProps, color: '#9C27B0', bgColor: '#F3E5F5' };
            case 'sms':
                return { ...baseProps, color: '#FF5722', bgColor: '#FBE9E7' };
            default:
                return { ...baseProps, color: '#757575', bgColor: '#F5F5F5' };
        }
    };

    const { color, bgColor } = getIconProps(followupType);

    const IconComponent = (() => {
        switch (followupType) {
            case 'phone': return Phone;
            case 'video': return Video;
            case 'email': return Mail;
            case 'in-person': return UserPlus;
            case 'sms': return MessageSquare;
            default: return null;
        }
    })();

    if (!IconComponent) return null;

    return (
        <Box
            sx={{
                mr: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                borderRadius: '50%',
                bgcolor: bgColor,
            }}
        >
            <IconComponent color={color} size={24} />
        </Box>
    );
};

const ScheduledFollowUps: React.FC<ScheduledFollowUpsProps> = ({
    followUps,
    handleEditFollowUp,
    handleDeleteFollowUp,
    fetchMore,
    hasMore,
    isLoading,
}) => {
    return (
        <Card elevation={3}>
            <CardContent>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" sx={{ color: '#1976d2', display: 'flex', alignItems: 'center' }}>
                        <Calendar size={24} style={{ marginRight: '10px' }} />
                        Scheduled Follow-ups
                    </Typography>
                </Box>
                <List dense>
                    {followUps.map((followUp) => (
                        <ListItem
                            key={followUp.id}
                            sx={{
                                bgcolor: '#fff',
                                mb: 2,
                                borderRadius: 1,
                                boxShadow: 1,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <FollowUpIcon followupType={followUp.followupType} />
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {followUp.visitReason}
                                    </Typography>
                                }
                                secondary={
                                    <Box>
                                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                            <Calendar size={14} style={{ marginRight: '5px' }} />
                                            {followUp.followupDate
                                                ? dayjs(followUp.followupDate).format('ddd, DD MMM YYYY')
                                                : 'Date not set'}
                                            {followUp.followupTime && (
                                                <>
                                                    <Clock size={14} style={{ marginLeft: '10px', marginRight: '5px' }} />
                                                    {dayjs(followUp.followupTime).format('HH:mm')}
                                                </>
                                            )}
                                        </Typography>
                                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <FileText size={14} style={{ marginRight: '5px' }} />
                                            {followUp.followupReason}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    size="small"
                                    onClick={() => handleEditFollowUp(followUp.id)}
                                >
                                    <Edit size={16} />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleDeleteFollowUp(followUp.id)}
                                    size="small"
                                >
                                    <Trash2 size={16} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                {hasMore && (
                    <Button onClick={fetchMore} disabled={isLoading} fullWidth startIcon={<PlusCircle size={18} />} sx={{ mt: 2 }}>
                        Load More
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default ScheduledFollowUps;