import React, { useState } from 'react';
import UTIProtocolForm, { FormData } from './UTIProtocolForm';
import SummaryScreen from './SummaryScreen';
import { UserPlus } from 'lucide-react';
import { Button } from '@mui/material';

const WAUrinaryTractInfections = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormData | null>(null);

  const handleSubmit = (data: FormData) => {
    setFormData(data);
    setIsSubmitted(true);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <h1>
        <UserPlus size={32} style={{ marginRight: '10px' }} /> Urinary Tract Infections Protocol - Western Australia
      </h1>
      {!isSubmitted ? (
        <UTIProtocolForm onSubmit={handleSubmit} />
      ) : (
        <>
          <SummaryScreen formData={formData!} onPrint={handlePrint} />
          <Button variant="outlined" onClick={() => setIsSubmitted(false)} sx={{ mt: 2 }}>
            Back to Form
          </Button>
        </>
      )}
    </div>
  );
};

export default WAUrinaryTractInfections;
