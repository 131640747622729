import React from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import { Print } from '@mui/icons-material';
import { FormData } from './UTIProtocolForm';
import UTIRecordSummary from './UTIRecordSummary';

interface SummaryScreenProps {
  formData: FormData;
  onPrint: () => void;
}

const SummaryScreen: React.FC<SummaryScreenProps> = ({ formData, onPrint }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom align="center">
        UTI Protocol Summary
      </Typography>

      <UTIRecordSummary formData={formData} />

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" startIcon={<Print />} onClick={onPrint}>
          Print Patient Handout
        </Button>
      </Box>
    </Paper>
  );
};

export default SummaryScreen;
