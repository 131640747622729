import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookExecOptions,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import {
  CreateServiceScheduleInput,
  CreateServiceScheduleMutationVariables,
  Exact,
  GetServiceScheduleQuery,
  GetServiceScheduleQueryVariables,
  InputMaybe,
  ListPharmacyScheduleQuery,
  ListPharmacyScheduleQueryVariables,
  ListServiceScheduleQuery,
  ListServiceScheduleQueryVariables,
  QueryQueryServiceSchedulesByPharmacyIdIndexArgs,
  ServiceScheduleByPharmacyIdQuery,
  ServiceScheduleByPharmacyIdQueryVariables,
  TablePharmacyScheduleFilterInput,
  TableServiceScheduleFilterInput,
  UpdateServiceScheduleInput,
  UpdateServiceScheduleMutation,
  UpdateServiceScheduleMutationVariables,
} from '../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import {
  GET_SERVICE_SCHEDULE,
  LIST_PHARMACY_SCHEDULE,
  LIST_SERVICE_SCHEDULE,
  QUERY_SERVICE_SCHEDULE_BY_PHARMACY_ID,
} from '../codegens/HEALTCARE-RESERVATION/queries';
import { GRAPHQL_DESTINATION } from '../ApolloClient';
import {
  MUTATION_CREATE_SERVICE_SCHEDULE,
  MUTATION_UPDATE_SERVICE_SCHEDULE,
} from '../codegens/HEALTCARE-RESERVATION/mutations';

export const useServiceSchedule = () => {
  const [queryGetServiceSchedule, { error: errorGetServiceSchedule, loading: loadingGetServiceSchedule }] =
    useLazyQuery<GetServiceScheduleQuery, GetServiceScheduleQueryVariables>(GET_SERVICE_SCHEDULE, {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    });

  const [fetchServiceSchedules, { error: errorfetchServiceSchedules, loading: loadingFetchServiceSchedules }] =
    useLazyQuery<ListServiceScheduleQuery, ListServiceScheduleQueryVariables>(LIST_SERVICE_SCHEDULE, {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    });

  const [
    fetchServiceSchedulesByPharmacyId,
    { error: errorfetchServiceSchedulesByPharmacyId, loading: loadingFetchServiceSchedulesByPharmacyId },
  ] = useLazyQuery<ServiceScheduleByPharmacyIdQuery, ServiceScheduleByPharmacyIdQueryVariables>(
    QUERY_SERVICE_SCHEDULE_BY_PHARMACY_ID,
    {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    }
  );

  const [
    saveServiceScheduleMutation,
    { loading: loadingSaveServiceSchedule, error: errorSaveServiceSchedule, data: serviceScheduleSaved },
  ] = useMutation<CreateServiceScheduleInput, CreateServiceScheduleMutationVariables>(
    MUTATION_CREATE_SERVICE_SCHEDULE,
    {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    }
  );

  const [
    updateServiceScheduleMutation,
    { loading: loadingUpdateServiceSchedule, error: errorUpdateServiceSchedule, data: serviceScheduleUpdated },
  ] = useMutation<UpdateServiceScheduleMutation, UpdateServiceScheduleMutationVariables>(
    MUTATION_UPDATE_SERVICE_SCHEDULE,
    {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    }
  );

  const getServiceSchedule = async (
    options: Partial<
      LazyQueryHookExecOptions<
        GetServiceScheduleQuery,
        Exact<{
          serviceScheduleID: string;
        }>
      >
    >
  ) => {
    return queryGetServiceSchedule({
      ...options,
      fetchPolicy: 'network-only',
    });
  };

  const saveServiceSchedule = (
    options?: MutationFunctionOptions<
      CreateServiceScheduleInput,
      Exact<{
        input: CreateServiceScheduleInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return saveServiceScheduleMutation({
      ...options,
    });
  };

  const updateServiceSchedule = (
    options?: MutationFunctionOptions<
      UpdateServiceScheduleMutation,
      Exact<{
        input: UpdateServiceScheduleInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return updateServiceScheduleMutation({
      ...options,
    });
  };

  const listServiceSchedule = (
    options: Partial<
      LazyQueryHookExecOptions<
        ListServiceScheduleQuery,
        Exact<{
          limit?: InputMaybe<number>;
          nextToken?: InputMaybe<string>;
          filter?: InputMaybe<TableServiceScheduleFilterInput>;
        }>
      >
    >
  ) => {
    return fetchServiceSchedules({
      ...options,
    });
  };

  const getServiceScheduledByPharmacyId = (
    options: Partial<
      LazyQueryHookExecOptions<
        ServiceScheduleByPharmacyIdQuery,
        Exact<{
          pharmacyID: string;
          after?: InputMaybe<string> | undefined;
          first?: InputMaybe<number> | undefined;
        }>
      >
    >
  ) => {
    return fetchServiceSchedulesByPharmacyId({
      ...options,
    });
  };

  return {
    getServiceSchedule,
    loadingGetServiceSchedule,
    errorGetServiceSchedule,
    saveServiceSchedule,
    loadingSaveServiceSchedule,
    errorSaveServiceSchedule,
    serviceScheduleSaved,
    updateServiceSchedule,
    errorUpdateServiceSchedule,
    loadingUpdateServiceSchedule,
    serviceScheduleUpdated,
    listServiceSchedule,
    loadingFetchServiceSchedules,
    errorfetchServiceSchedules,
    getServiceScheduledByPharmacyId,
    errorfetchServiceSchedulesByPharmacyId,
    loadingFetchServiceSchedulesByPharmacyId
  };
};
