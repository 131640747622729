import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Container,
  Box,
  Chip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LogoDark from '../../assets/images/logo-dark.png';
import PageWrapper from './PageWrapper';
import { createCheckoutSession } from '../../helpers/api/stripe/subscribe';
import { useLocation } from 'react-router-dom';
import { logger } from '../../utils/logger';
import { styled } from '@mui/material/styles';

const stripePublishableKey = process.env.REACT_APP_PUBLISHABLE_KEY as string;
const stripePromise = loadStripe(stripePublishableKey);
const stripePriceIdMonthly = process.env.REACT_APP_STRIPE_PRICE_ID_MONTHLY as string;
const stripePriceIdYearly = process.env.REACT_APP_STRIPE_PRICE_ID_YEARLY as string;
const stripePriceIdCustom = process.env.REACT_APP_STRIPE_PRICE_ID_CUSTOM as string;

const stripeTaxId = process.env.REACT_APP_STRIPE_TAX_ID as string;

interface Plan {
  name: string;
  description: string;
  price: number;
  billingCycle: 'monthly' | 'yearly';
  stripePriceId: string;
  stripeTaxId: string;
  features: string[];
}

const plans: Plan[] = [
  {
    name: 'Dragatron S3-R',
    description: 'Streamlined S3 Recording Service',
    price: 25,
    billingCycle: 'monthly',
    stripePriceId: stripePriceIdCustom,
    stripeTaxId,
    features: [
      'TGA-APPROVED PRODUCT LIST',
      'STREAMLINED S3 SUPPLY PROCESSES',
      'COMPREHENSIVE PATIENT INFORMATION HANDOUTS',
      'EFFICIENT STOCK MANAGEMENT AND REPORTING',
      'SIMPLIFIED RECORD-KEEPING',
      'EFFORTLESS REGULATORY COMPLIANCE',
      'TIME AND COST SAVINGS',
    ],
  },
  {
    name: 'Foundational Service Tier',
    description: 'Dragatron Professional Services Monthly Subscription',
    price: 249,
    billingCycle: 'monthly',
    stripePriceId: stripePriceIdMonthly,
    stripeTaxId,
    features: [
      'VACCINATIONS (NIPVIP/CVCP)',
      'DIGITAL CERTIFICATE OF LEAVE',
      '8CPA - MEDS CHECK AND DIABETES MEDS CHECK',
      'APPOINTMENTS AND RESERVATIONS',
      'HEALTH MONITORING',
      'FOLLOW-UPs',
      'ODT INJECTIONS AND STAGED SUPPLY',
      'REPORTS',
    ],
  },
  {
    name: 'Premium Service Tier',
    description: 'Dragatron Professional Services Yearly Subscription',
    price: 2499,
    billingCycle: 'yearly',
    stripePriceId: stripePriceIdYearly,
    stripeTaxId,
    features: [
      'All FOUNDATIONAL TIER FEATURES',
      'PRIORITY SUPPORT',
      'ADVANCED ANALYTICS',
      'CUSTOMIZABLE DASHBOARDS',
      'EARLY ACCESS TO NEW FEATURES',
      'DEDICATED ACCOUNT MANAGER',
      'EXTENDED REPORTING CAPABILITIES',
      'TAILORED ADDITIONAL REVENUE SUGGESTIONS',
    ],
  },
];

const PopularRibbon = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 20,
  right: -45,
  transform: 'rotate(45deg)',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: '6px 40px',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  zIndex: 1,
  boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 0.3)',
}));

function Pricing() {
  const location = useLocation();
  const { email } = location.state as { email: string };
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});

  const handleSubscribe = async (plan: Plan) => {
    logger.log(`Subscribing to ${plan.name} with ${plan.billingCycle} billing`);
    logger.log(`User email: ${email}`);
    setLoadingStates((prev) => ({ ...prev, [plan.stripePriceId]: true }));
    const stripe = await stripePromise;

    if (!stripe) {
      console.error('Stripe failed to load');
      setLoadingStates((prev) => ({ ...prev, [plan.stripePriceId]: false }));
      return;
    }

    try {
      const sessionId = await createCheckoutSession({
        priceId: plan.stripePriceId,
        taxId: plan.stripeTaxId,
        successUrl: `${window.location.origin}/auth/login`,
        cancelUrl: `${window.location.origin}/auth/login`,
        email, // Add this line to pass the email
      });

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoadingStates((prev) => ({ ...prev, [plan.stripePriceId]: false }));
    } finally {
      // Always reset the loading state, unless we've successfully redirected to Stripe
      if (window.location.href === window.location.origin) {
        setLoadingStates((prev) => ({ ...prev, [plan.stripePriceId]: false }));
      }
    }

    logger.log(`Subscribing to ${plan.name} with ${plan.billingCycle} billing`);
  };

  const calculateSavings = () => {
    const monthlyPrice = plans[0].price;
    const yearlyPrice = plans[1].price;
    const fullYearPrice = monthlyPrice * 12;
    const savingsAmount = fullYearPrice - yearlyPrice;
    const savingsPercentage = Math.round((savingsAmount / fullYearPrice) * 100);
    const freeWeeks = Math.round((savingsAmount / monthlyPrice) * 4);
    return { percentage: savingsPercentage, weeks: freeWeeks };
  };

  const savings = calculateSavings();

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-AU', { style: 'currency', currency: currency.toUpperCase() }).format(amount);
  };

  return (
    <PageWrapper title="Pricing">
      <Box component="header" sx={{ position: 'sticky', top: 0, bgcolor: 'background.paper', py: 2, zIndex: 1 }}>
        <Container maxWidth="md">
          <Box display="flex" justifyContent="center">
            <a href="https://www.dragatron.com.au" target="_blank" rel="noreferrer">
              <img src={LogoDark} alt="Dragatron Logo" height="100" />
            </a>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                raised
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {plan.name === 'Premium Service Tier' && <PopularRibbon>POPULAR</PopularRibbon>}
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', pt: 4 }}>
                  <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {plan.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                      {plan.description}
                    </Typography>
                    <Typography variant="h4" component="p" sx={{ mt: 2, mb: 1 }}>
                      {formatCurrency(plan.price, 'AUD')}
                      <Typography variant="subtitle1" component="span">
                        /{plan.billingCycle === 'monthly' ? 'month' : 'year'}
                      </Typography>
                    </Typography>
                    {plan.billingCycle === 'yearly' && (
                      <Chip
                        label={`Save ${savings.percentage}% (${savings.weeks} weeks free)`}
                        color="secondary"
                        size="small"
                      />
                    )}
                  </Box>
                  <Typography variant="subtitle2" component="h3" gutterBottom>
                    This includes:
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}>
                    {plan.features.map((feature, featureIndex) => (
                      <Box key={featureIndex} display="flex" alignItems="center" mb={1}>
                        <CheckIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                        <Typography variant="body2">{feature}</Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
                <CardContent>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => handleSubscribe(plan)}
                    disabled={loadingStates[plan.stripePriceId]}
                  >
                    {loadingStates[plan.stripePriceId] ? 'PROCESSING...' : 'SUBSCRIBE'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageWrapper>
  );
}

export default Pricing;
