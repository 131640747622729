import { useLazyQuery } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { GET_VACCINE_DATA } from '../../graphql-queries';
import { GraphQLVaxListResponse } from './Vaccine';
import { formatDate } from './common/utils';
import { VaxHistoryResponse } from './vaxHistory';
import dayjs, { Dayjs } from 'dayjs';
import AuthContext from '../../contexts/AuthContext';
import countryCodes from './common/CountryCodes';
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IdentifyIndividualResponse } from './identifyIndividual';
import { green } from '@mui/material/colors';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useIdToken } from '../../hooks/useIdToken';
import { logger } from '../../utils/logger';
interface PatientVaccinationHistoryProps {
  patientIdentifier: string;
  vaxHistory: VaxHistoryResponse | null;
  individualDetails: IdentifyIndividualResponse;
  refreshIndividualDetails: () => void;
  columnsToShow?: string[]; // Add this line
}

interface VaccineOption {
  label: string; // This will store the VaccineBrandName
  value: string; // This will store the VaccineCode
  isEligibleForNIP?: boolean; // Add this line
}

interface Data {
  OtherHpiiNumber: string;
  OtherHpioNumber: string;
  otherProviderNumber: string;
  id: number;
  encounterId: number;
  episodeId: number;
  date: string;
  dose: string;
  code: string;
  vaccine: string;
  vaccineBrand: string;
  comments: string;
  editable: boolean;
  routeOfAdministration: string;
  vaccineFundingType: string;
  vaccineBatch: string;
  vaccineSerialNumber: string;
  schoolId: string;
  administeredOverseas: boolean;
  countryCode: string;
  claimSeqNum: number;
  immEncSeqNum: number;
  claimId: string;
}

interface EpisodeFormData {
  OtherHpiiNumber: string;
  OtherHpioNumber: string;
  otherProviderNumber: string;
  id: number;
  encounterId: number;
  episodeId: number;
  date: string;
  vaccine: string;
  vaccineBrand: string;
  dose: string;
  code: string;
  comments: string;
  vaccineFundingType: string;
  routeOfAdministration: string;
  vaccineBatch: string;
  vaccineSerialNumber: string;
  schoolId: string;
  editable: boolean;
  administeredOverseas: boolean;
  countryCode: string;
  claimSeqNum: number;
  immEncSeqNum: number;
  claimId: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: string;
}

const columns: ColumnData[] = [
  { label: 'Date', dataKey: 'date', width: '10%' },
  { label: 'Vaccine', dataKey: 'vaccine', width: '10%' },
  { label: 'Brand', dataKey: 'vaccineBrand', width: '20%' },
  { label: 'Dose', dataKey: 'dose', width: '10%' },
  { label: 'Comments', dataKey: 'comments', width: '30%' },
  { label: 'Modify', dataKey: 'editable', width: '10%' },
];

const PatientVaccinationHistory: React.FC<PatientVaccinationHistoryProps> = ({
  patientIdentifier,
  vaxHistory,
  individualDetails,
  refreshIndividualDetails,
  columnsToShow = ['date', 'vaccine', 'vaccineBrand', 'dose', 'comments', 'editable'], // Default columns
}) => {
  // Filter the columns based on columnsToShow prop
  const filteredColumns: ColumnData[] = columns.filter((column) => columnsToShow.includes(column.dataKey));

  const [page, setPage] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'submitting' | 'error' | 'done'>('idle');
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { providerNumber, providerStore, hpiiNumber, hpioNumber, deviceName, minorId, prodaOrgId } =
    useContext(AuthContext);
  const [isOverseas, setIsOverseas] = useState(false);
  const [immunisationDate, setImmunisationDate] = useState<Dayjs | null>(dayjs());
  const [filteredVaccines, setFilteredVaccines] = useState<VaccineOption[]>([]);
  const [editingEncounterId, setEditingEncounterId] = useState<number | null>(null);
  const [activeEpisodes, setActiveEpisodes] = useState([]);
  const [isOtherProvider, setIsOtherProvider] = useState(false);
  const [formData, setFormData] = useState<EpisodeFormData[]>([]);
  const [encounterData, setEncounterData] = useState({
    schoolId: '',
    administeredOverseas: false,
    countryCode: 'AUS',
  });

  const handleEncounterChange = (field: any, value: any) => {
    setEncounterData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const [loading, setLoading] = useState(true); // Add a loading state
  const [apiError, setApiError] = useState<string | null>(null);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<string | null>(null);
  const { idToken, isLoading, error: idTokenError } = useIdToken();

  // const [fetchVaccineData, { data, loading, error }] = useLazyQuery<GraphQLVaxListResponse>(GET_VACCINE_DATA);

  // Define the lazy query with an onCompleted option
  const [fetchVaccineData, { data, loading: queryLoading, error }] = useLazyQuery<GraphQLVaxListResponse>(
    GET_VACCINE_DATA,
    {
      onCompleted: (data) => {
        // This function is called when the query successfully completes.
        logger.log('Data fetched successfully:', data);
        filterVaccines(); // Pass the fetched data directly to filterVaccines
      },
    }
  );

  useEffect(() => {
    // Call this function when you want to fetch the vaccine data.
    fetchVaccineData();
    logger.log('Fetching vaccine data...', data);
  }, [data, fetchVaccineData]);
  useEffect(() => {
    if (vaxHistory) {
      setLoading(false);
      if (vaxHistory.data?.errors && vaxHistory.data.errors.length > 0) {
        const error = vaxHistory.data.errors.map((err) => `${err.code}: ${err.message}`).join(', ');
        setApiError(error);
        setDisplayError(error);
        setShowErrorDialog(true);
      }
    }
  }, [vaxHistory]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const routeOptions = [
    { label: 'Oral', code: 'PO' },
    { label: 'Subcutaneous', code: 'SC' },
    { label: 'Intradermal', code: 'ID' },
    { label: 'Intramuscular', code: 'IM' },
  ];
  const vaccineFundingTypes = [
    { label: 'Antenatal', code: 'AEN' },
    { label: 'NIP/Commonwealth', code: 'NIP' },
    { label: 'Private', code: 'PRV' },
    { label: 'State Program', code: 'STP' },
  ];
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterVaccines = () => {
    if (data && data.listVaxDataAIRS && data.listVaxDataAIRS.items) {
      const vaccineOptions: VaccineOption[] = data.listVaxDataAIRS.items.map((vaccine) => ({
        label: vaccine.VaccineBrandName,
        value: vaccine.VaccineCode,
        isEligibleForNIP: vaccine.IsEligibleForNIP as boolean, // Change the type to boolean
      }));
      setFilteredVaccines(vaccineOptions);
    }
  };

  const rows: Data[] = React.useMemo(() => {
    let count = 0;
    if (!vaxHistory?.data?.immunisationDetails?.encounters) {
      return []; // Return an empty array if encounters is undefined
    }
    // Create a mapping of vaccine codes to brand names
    const vaccineCodeToBrandName = data?.listVaxDataAIRS?.items.reduce((acc, current) => {
      acc[current.VaccineCode] = current.VaccineBrandName;
      return acc;
    }, {} as Record<string, string>);

    return (
      vaxHistory?.data?.immunisationDetails?.encounters.flatMap((encounter, index) =>
        encounter.episodes.map((episode) => ({
          id: count++,
          encounterId: index,
          episodeId: episode.id,
          date: formatDate(encounter.dateOfService),
          vaccine: episode.vaccineCode,
          vaccineBrand: vaccineCodeToBrandName ? vaccineCodeToBrandName[episode.vaccineCode] : 'Unknown',
          dose: episode.vaccineDose,
          code: episode.information.code || '',
          comments: episode.information.text || '',
          editable: episode.editable,
          vaccineFundingType: episode.vaccineFundingType,
          routeOfAdministration: episode.routeOfAdministration,
          vaccineBatch: episode.vaccineBatch || '',
          vaccineSerialNumber: episode.vaccineSerialNumber || '',
          schoolId: encounter.schoolId,
          administeredOverseas: encounter.administeredOverseas,
          countryCode: encounter.countryCode,
          claimSeqNum: encounter.claimSeqNum,
          immEncSeqNum: encounter.immEncSeqNum,
          claimId: encounter.claimId,
          OtherHpiiNumber: encounter.OtherHpiiNumber || '',
          OtherHpioNumber: encounter.OtherHpioNumber || '',
          otherProviderNumber: encounter.otherProviderNumber || '',
        }))
      ) || []
    );
  }, [vaxHistory, data]);

  // useEffect(() => {
  //   if (editingRowId !== null) {
  //     // Find the row data based on editingRowId
  //     const editingRow = rows.find((row) => row.id === editingRowId);
  //     logger.log("Editing row:", editingRow);
  //     if (editingRow) {
  //       // Set form data with the editing row's data
  //       setFormData([
  //         {
  //           id: editingRow.id,
  //           encounterId: editingRow.encounterId,
  //           episodeId: editingRow.episodeId,
  //           date: editingRow.date,
  //           vaccine: editingRow.vaccine,
  //           vaccineBrand: editingRow.vaccineBrand,
  //           dose: editingRow.dose,
  //           code: editingRow.code,
  //           comments: editingRow.comments,
  //           vaccineFundingType: editingRow.vaccineFundingType,
  //           routeOfAdministration: editingRow.routeOfAdministration,
  //           vaccineBatch: editingRow.vaccineBatch,
  //           vaccineSerialNumber: editingRow.vaccineSerialNumber,
  //           schoolId: editingRow.schoolId,
  //           administeredOverseas: editingRow.administeredOverseas,
  //           countryCode: editingRow.countryCode,
  //           claimSeqNum: editingRow.claimSeqNum,
  //           immEncSeqNum: editingRow.immEncSeqNum,
  //           claimId: editingRow.claimId,
  //           editable: editingRow.editable,
  //         },
  //       ]);
  //     }
  //   } else {
  //     // Reset form data if no row is being edited
  //     setFormData([
  //       {
  //         id: 0,
  //         encounterId: 0,
  //         episodeId: 0,
  //         date: "",
  //         vaccine: "",
  //         vaccineBrand: "",
  //         dose: "",
  //         code: "",
  //         comments: "",
  //         vaccineFundingType: "",
  //         routeOfAdministration: "",
  //         vaccineBatch: "",
  //         vaccineSerialNumber: "",
  //         schoolId: "",
  //         administeredOverseas: false,
  //         countryCode: "",
  //         claimSeqNum: 0,
  //         immEncSeqNum: 0,
  //         claimId: "",
  //         editable: false,
  //       },
  //     ]);
  //   }
  // }, [editingRowId, rows]);
  useEffect(() => {
    if (editingEncounterId !== null) {
      // Fetch all episodes within the selected encounter
      const encounterEpisodes = rows.filter((row) => row.encounterId === editingEncounterId);
      logger.log('Encounter episodes:', encounterEpisodes);

      if (encounterEpisodes.length > 0) {
        // Set form data with the episodes' data
        const formattedData = encounterEpisodes.map((episode) => ({
          id: episode.id,
          encounterId: episode.encounterId,
          episodeId: episode.episodeId,
          date: episode.date,
          vaccine: episode.vaccine,
          vaccineBrand: episode.vaccineBrand,
          dose: episode.dose,
          code: episode.code,
          comments: episode.comments,
          vaccineFundingType: episode.vaccineFundingType,
          routeOfAdministration: episode.routeOfAdministration,
          vaccineBatch: episode.vaccineBatch,
          vaccineSerialNumber: episode.vaccineSerialNumber,
          schoolId: episode.schoolId,
          administeredOverseas: episode.administeredOverseas,
          countryCode: episode.countryCode,
          claimSeqNum: episode.claimSeqNum,
          immEncSeqNum: episode.immEncSeqNum,
          claimId: episode.claimId,
          editable: episode.editable,
          OtherHpiiNumber: episode.OtherHpiiNumber,
          OtherHpioNumber: episode.OtherHpiiNumber,
          otherProviderNumber: episode.OtherHpiiNumber,
        }));
        setFormData(formattedData);
      }
    } else {
      // Reset form data if no encounter is being edited
      setFormData([]);
    }
  }, [editingEncounterId, rows]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Handle error state
  // if (apiError) {
  //   return null; // Or some other fallback UI if necessary
  // }
  // Handle loading state
  if (!vaxHistory || !vaxHistory.data?.immunisationDetails) {
    if (vaxHistory?.data?.statusCode === 'AIR-W-1059') {
      return <div>AIR-W-1059 : {vaxHistory.data.message}</div>; // Display the specific message from the server
    }
    if (apiError) {
      return (
        <Box sx={{ width: '100%' }}>
          <div>{apiError}</div>
          <Dialog open={showErrorDialog} onClose={() => setApiError(null)}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent sx={{ minWidth: '600px', minHeight: '70px' }}>
              <DialogContentText>{apiError}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ backgroundColor: green[500], color: '#fff' }}
                onClick={() => setApiError(null)}
                color="primary"
                startIcon={<CancelOutlinedIcon />}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      );
    }
    // return <div>Loading vaccination history...</div>; // Fallback or default loading message
  }

  const handleOverseasChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked; // This will be true or false based on the switch position
    setIsOverseas(checked); // Update the isOverseas state based on the switch
    handleFormChange(index, 'administeredOverseas', checked);
  };

  // const handleFormChange = (index: number, field: keyof EpisodeFormData, value: any) => {
  //   logger.log("handleFormChange", index, field, value);
  //   const updatedFormData = formData.map((item, idx) => (idx === index ? { ...item, [field]: value } : item));
  //   setFormData(updatedFormData);
  //   logger.log("Updated form data:", updatedFormData);
  // };

  const handleFormChange = (index: number, field: keyof EpisodeFormData, value: any) => {
    logger.log(`Updating index: ${index}, field: ${field}, value: ${value}`);

    // Create a new array for formData with updated values
    const updatedFormData = formData.map((item, idx) => {
      logger.log('Item:', item);
      if (idx === index) {
        // Return a new object with the updated field
        return { ...item, [field]: value };
      }
      return item;
    });

    // Update the state with the new formData array
    setFormData(updatedFormData);
    logger.log('Updated form data:', updatedFormData);
  };
  const handleEditClick = (encounterId: number, id: number) => {
    if (showForm && encounterId === editingEncounterId) {
      // If the form is already open and the same row is clicked, close the form.
      setShowForm(false);
      setEditingRowId(null); // Reset editing ID
    } else {
      // Open the form with the new editing ID
      setShowForm(true);
      setEditingEncounterId(encounterId);
      setEditingRowId(id);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setSubmitStatus('submitting');

    setShowConfirmDialog(true);
    logger.log('Submitting form data...', formData);

    // const encounterEpisodes = rows.filter((row) => row.id === formData[0].id);
    // // FIX THIS LATER
    // const updatedFormData = encounterEpisodes.map((episode) => {
    //   const editedEpisode = formData.find((edited) => edited.episodeId === episode.episodeId);
    //   return editedEpisode ? { ...episode, ...editedEpisode } : episode;
    // });

    // Call the API with form data and check the response code
    let data = await makeApiCall(formData, setErrorMessage); // Using `value` for both dates as placeholder
    if (data) {
      setSubmitStatus('done');
    } else {
      setSubmitStatus('error');
    }
  };

  const hanldeOtherProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOtherProvider(event.target.checked);
  };
  const finallyCloseDialog = () => {
    if (submitStatus === 'done') {
      setShowForm(false);
      refreshIndividualDetails();
    }
    setShowConfirmDialog(false);
    // setShowForm(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleCloseDialog = () => {
    setShowConfirmDialog(false);
    // setShowForm(false);

    if (submitStatus !== 'submitting') {
      // Allow closing only if not submitting
      setSubmitStatus('idle');
      setErrorMessage(''); // Reset error message
    }
  };
  const handleConfirmChange = async () => {
    logger.log('Before API call, submitStatus:', submitStatus);
  };
  const makeApiCall = async (
    formData: EpisodeFormData[],
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (!idToken) {
      console.error('No authentication token available');
      setErrorMessage('Authentication error');
      return false;
    }
    setErrorMessage(''); // Reset error message
    logger.log('formData', formData[0].OtherHpioNumber);
    const requestData = {
      individualIdentifier: patientIdentifier,
      encounter: {
        claimId: formData[0].claimId,
        claimSeqNum: formData[0].claimSeqNum,
        immEncSeqNum: formData[0].immEncSeqNum,
        episodes: formData.map((episode) => ({
          id: episode.episodeId,
          vaccineCode: episode.vaccine,
          vaccineDose: episode.dose,
          vaccineBatch: episode.vaccineBatch || '',
          vaccineSerialNumber: episode.vaccineSerialNumber || '',
          vaccineFundingType: episode.vaccineFundingType || '',
          routeOfAdministration: episode.routeOfAdministration || '',
        })),
        ...(formData[0].schoolId && { schoolId: formData[0].schoolId }),
        dateOfService: dayjs(formData[0].date).format('DDMMYYYY'),
        ...(formData[0].administeredOverseas && {
          countryCode: formData[0].countryCode,
          administeredOverseas: formData[0].administeredOverseas,
        }),
        // ...(claimId && { claimId }), // Use the claimId from the state
        ...(isOtherProvider &&
          formData[0].otherProviderNumber && {
            immunisationProvider: {
              providerNumber: formData[0].otherProviderNumber, // Assuming this is always set
              ...(formData[0].OtherHpioNumber && {
                hpioNumber: formData[0].OtherHpioNumber,
              }),
              ...(formData[0].OtherHpiiNumber && {
                hpiiNumber: formData[0].OtherHpiiNumber,
              }),
            },
          }),
      },
      informationProvider: {
        providerNumber: providerNumber,
        ...(hpioNumber && { hpioNumber }),
        ...(hpiiNumber && { hpiiNumber }),
      },
      dateOfBirth: individualDetails.data.individualDetails.individual.personalDetails.dateOfBirth,
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
    };
    logger.log('requestData' + JSON.stringify(requestData, null, 2));
    try {
      const apiAIRUpdateEncounter = process.env.REACT_APP_UPDATE_ENCOUNTER;
      if (!apiAIRUpdateEncounter) {
        throw new Error('REACT_APP_UPDATE_ENCOUNTER is not defined');
      }
      const response = await fetch(apiAIRUpdateEncounter, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success && (!data.data.errors || data.data.errors.length === 0)) {
        logger.log('Success');
        return true;
      } else if (data.success && data.data.errors && data.data.errors.length > 0) {
        // If 'data.data.errors' exists and has elements, it means there are errors
        logger.log(data.data.errors[0].message);
        setErrorMessage(data.data.errors[0].code + ' : ' + data.data.errors[0].message);
        return false;
      } else {
        // Handle other cases, such as when 'data.success' is not true
        logger.log('An unexpected error occurred');
        setErrorMessage('An unexpected error occurred');
        return false;
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (idTokenError) {
    return <Typography color="error">Error loading authentication token: {idTokenError}</Typography>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={showForm ? 8 : 12}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {filteredColumns.map((column: ColumnData) => (
                      <TableCell
                        key={column.dataKey}
                        align={column.numeric ? 'right' : 'left'}
                        style={{ width: column.width }}
                        sx={{
                          backgroundColor: '#1976d2',
                          color: 'white',
                          flex: column.width ? `0 0 ${column.width}` : undefined,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayError ? (
                    <TableRow>
                      <TableCell colSpan={filteredColumns.length} sx={{ textAlign: 'center' }}>
                        {displayError}
                      </TableCell>
                    </TableRow>
                  ) : rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={filteredColumns.length} sx={{ textAlign: 'center' }}>
                        No immunisation history is recorded for this individual
                      </TableCell>
                    </TableRow>
                  ) : (
                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: Data) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {filteredColumns.map((column: ColumnData) => {
                          const value = row[column.dataKey as keyof Data];
                          return (
                            <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'}>
                              {column.dataKey === 'editable' ? (
                                row.editable ? (
                                  <EditIcon
                                    onClick={() => handleEditClick(row.encounterId, row.id)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ) : (
                                  <Tooltip title="You are not authorised to update this encounter">
                                    <span>
                                      <EditOffIcon />
                                    </span>
                                  </Tooltip>
                                )
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        {showForm && editingEncounterId !== null && (
          <Grid item xs={12} md={4}>
            {rows
              .filter((row) => row.encounterId === editingEncounterId)
              .map((episode, index) => (
                <Paper key={index}>
                  <Box p={2}>
                    <Typography variant="h6">Edit Immunisation {index + 1}</Typography>
                    {/* Example TextField for editing vaccine brand */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of Submission"
                        format="DD/MM/YYYY"
                        disableFuture
                        value={dayjs(episode.date)}
                        onChange={(newValue) => handleFormChange(index, 'date', dayjs(newValue).format())}
                        slotProps={{
                          textField: {
                            size: 'small',
                            margin: 'normal',
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <FormControl fullWidth margin="normal" variant="outlined" size="small">
                      <InputLabel id="vaccine-label">Vaccines</InputLabel>
                      <Select
                        labelId="vaccine-label"
                        name="vaccine"
                        value={formData[index]?.vaccine || ''}
                        onChange={(e) => handleFormChange(index, 'vaccine', e.target.value)}
                        label="Vaccine label"
                      >
                        {filteredVaccines.map((vaccine) => (
                          <MenuItem
                            key={vaccine.value}
                            value={vaccine.value}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {vaccine.label}
                            {vaccine.isEligibleForNIP && (
                              <Box
                                component="span"
                                sx={{
                                  ml: 1,
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center', // Center the 'NIP' text horizontally
                                  backgroundColor: 'green',
                                  color: 'white',
                                  borderRadius: '50%', // Make it circular
                                  width: 24, // Set a fixed width
                                  height: 24, // Ensure height equals width to form a circle
                                  fontSize: '0.75rem', // Adjust font size as needed
                                }}
                              >
                                NIP
                              </Box>
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Vaccine Dose"
                      fullWidth
                      size="small"
                      margin="normal"
                      value={formData[index]?.dose || ''}
                      onChange={(e) => handleFormChange(index, 'dose', e.target.value)}
                    />
                    <TextField
                      label="Batch Number"
                      fullWidth
                      size="small"
                      margin="normal"
                      value={formData[index]?.vaccineBatch || ''}
                      onChange={(e) => handleFormChange(index, 'vaccineBatch', e.target.value)}
                    />
                    <TextField
                      label="Serial Number"
                      fullWidth
                      size="small"
                      margin="normal"
                      value={formData[index]?.vaccineSerialNumber || ''}
                      onChange={(e) => handleFormChange(index, 'vaccineSerialNumber', e.target.value)}
                    />

                    <FormControl fullWidth margin="normal" variant="outlined" size="small">
                      <InputLabel id={`route-admin-label-${index}`}>Route of Administration</InputLabel>
                      <Select
                        labelId={`route-admin-label-${index}`}
                        id={`routeOfAdministration-${index}`}
                        name="routeOfAdministration"
                        value={formData[index]?.routeOfAdministration || ''}
                        onChange={(e) => handleFormChange(index, 'routeOfAdministration', e.target.value)}
                        label="Route of Administration"
                      >
                        {routeOptions.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal" variant="outlined" size="small">
                      <InputLabel id={`funding-type-label-${index}`}>Vaccine Type</InputLabel>
                      <Select
                        labelId={`funding-type-label-${index}`}
                        id={`vaccineFundingType-${index}`}
                        name="vaccineFundingType"
                        value={formData[index]?.vaccineFundingType || ''}
                        onChange={(e) => handleFormChange(index, 'vaccineFundingType', e.target.value)}
                        label="Vaccine Type"
                      >
                        {vaccineFundingTypes.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Dialog open={showConfirmDialog} onClose={handleCloseDialog}>
                    <DialogTitle>
                      {submitStatus === 'submitting' && 'Submitting'}
                      {submitStatus === 'done' && 'Success'}
                      {submitStatus === 'idle' && 'Confirm Change'}
                      {submitStatus === 'error' && 'Error'}
                    </DialogTitle>
                    <DialogContent sx={{ minWidth: '600px', minHeight: '70px' }}>
                      <DialogContentText>
                        {submitStatus === 'submitting' && 'Submitting with AIR...'}
                        {submitStatus === 'done' && 'Successfully submitted with AIR!'}
                        {submitStatus === 'idle' && `Are you sure you want to change `}
                        {submitStatus === 'error' && errorMessage}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      {submitStatus === 'idle' && (
                        <>
                          <Button onClick={handleCloseDialog} color="error" startIcon={<CancelOutlinedIcon />}>
                            Cancel
                          </Button>
                          <Button onClick={handleConfirmChange} color="primary" startIcon={<CheckOutlinedIcon />}>
                            Confirm
                          </Button>
                        </>
                      )}
                      {submitStatus === 'submitting' && <CircularProgress size={24} />}
                      {submitStatus === 'done' && (
                        <Button
                          onClick={finallyCloseDialog}
                          style={{ backgroundColor: green[500], color: '#fff' }}
                          startIcon={<CheckOutlinedIcon />}
                        >
                          Close
                        </Button>
                      )}
                      {submitStatus === 'error' && (
                        <Button
                          onClick={finallyCloseDialog}
                          color="error"
                          variant="contained"
                          startIcon={<CancelOutlinedIcon />}
                        >
                          Close
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                </Paper>
              ))}
            <Paper>
              <Box p={2}>
                <TextField
                  label="School Id"
                  fullWidth
                  size="small"
                  margin="normal"
                  value={formData[0]?.schoolId || ''}
                  onChange={(e) => handleFormChange(0, 'schoolId', e.target.value)}
                />
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData[0]?.administeredOverseas || false}
                        onChange={(event) => handleFormChange(0, 'administeredOverseas', event.target.checked)}
                      />
                    }
                    label="Administered Overseas"
                    labelPlacement="end"
                  />
                </FormGroup>
                {formData[0]?.administeredOverseas && (
                  <Autocomplete
                    options={countryCodes}
                    getOptionLabel={(option) => option.label}
                    value={countryCodes.find((option) => option.code === formData[0]?.countryCode) || null}
                    renderInput={(params) => (
                      <TextField {...params} label="Country" margin="normal" size="small" variant="outlined" />
                    )}
                    onChange={(event, newValue) => {
                      handleFormChange(0, 'countryCode', newValue?.code ?? '');
                    }}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    fullWidth
                  />
                )}
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Switch checked={isOtherProvider} onChange={hanldeOtherProvider} />}
                    label="Administered By Other Provider"
                    labelPlacement="end"
                  />
                  {isOtherProvider && (
                    <Grid container spacing={0}>
                      <TextField
                        label="Other Provider Number"
                        type="text"
                        name="otherProviderNumber"
                        size="small"
                        value={formData[0]?.otherProviderNumber}
                        onChange={(e) => handleFormChange(0, 'otherProviderNumber', e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <Grid>
                        <TextField
                          label="Other Provider HPIO Number"
                          type="text"
                          name="OtherHpioNumber"
                          size="small"
                          value={formData[0]?.OtherHpioNumber}
                          onChange={(e) => handleFormChange(0, 'OtherHpioNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Other Provider HPIINumber"
                          type="text"
                          name="OtherHpiiNumber"
                          size="small"
                          value={formData[0]?.OtherHpiiNumber}
                          onChange={(e) => handleFormChange(0, 'OtherHpiiNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  )}
                </FormGroup>
                <FormControl fullWidth margin="normal">
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit All Changes
                  </Button>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PatientVaccinationHistory;
