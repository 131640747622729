import React from 'react';
import { Typography, Box, Button, Card, CardContent, Grid } from '@mui/material';
import { Utensils, FileText, Clipboard } from 'lucide-react';
import { Link } from 'react-router-dom';

const NutritionPlanningIndex = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                <Utensils size={32} style={{ marginRight: '10px' }} />
                Nutrition Planning
            </Typography>
            <Typography variant="body1" paragraph>
                Welcome to the Nutrition Planning section. Here you can create new nutrition plans, assess dietary habits, and manage existing plans.
            </Typography>

            <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                                <FileText size={24} style={{ marginRight: '10px' }} />
                                New Nutrition Plan
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Create a new comprehensive nutrition plan for a patient.
                            </Typography>
                            <Button
                                component={Link}
                                to="/pages/nutrition-planning/form"
                                variant="contained"
                                color="primary"
                            >
                                Start New Plan
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                                <Clipboard size={24} style={{ marginRight: '10px' }} />
                                Dietary Assessment
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Perform a dietary assessment for a patient.
                            </Typography>
                            <Button
                                component={Link}
                                to="/pages/nutrition-planning/dietary-assessment"
                                variant="contained"
                                color="secondary"
                            >
                                Start Assessment
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Add more cards for other nutrition planning features as needed */}
            </Grid>
        </Box>
    );
};

export default NutritionPlanningIndex;
