import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Paper,
  Tabs,
  Tab,
  Card,
  CardHeader,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Box,
  IconButton,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import AppointmentCalendar from '../../components/AppointmentCalendar';
import { Appointment } from '../../components/types'; // Make sure to import the Appointment type
import { BOOKING_STATUS } from 'interfaces/IBooking';
import { logger } from 'utils/logger';
import SupplyCalendar from '../../components/SupplyCalendar'; // Add this import

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));

export default function PatientDashboard() {
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [tabValue, setTabValue] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date(2024, 9, 1)); // October 2024

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const patientInfo = {
    name: 'Jane Cooper',
    dob: 'May 31, 2015',
    address: '6391 Elgin St. Celina, Delaware 10299',
    medicareNumber: '4600',
    email: 'debbie.baker@example.com',
    phone: '(219) 555-0114',
  };

  const carerInfo = {
    name: 'Savannah Nguyen',
    phone: '(219) 555-0114',
    emergencyContact: '(207) 555-0119',
  };

  const pharmacyInfo = {
    name: 'Brooklyn Simmons',
    phone: '(307) 555-0133',
    email: 'willie.jennings@example.com',
    emergencyContact: '(219) 555-0114',
  };

  const prescriberInfo = {
    name: 'Darrell Steward',
    phone: '(252) 555-0126',
    emergencyContact: '(307) 555-0133',
  };

  const upcomingSessions: Appointment[] = [
    {
      id: '1',
      date: '2023-05-31',
      time: '14:08',
      duration: '30 min',
      patientName: 'Jane Cooper',
      VaccineBrandName: 'N/A',
      status: BOOKING_STATUS.CONFIRMED,
    },
    {
      id: '2',
      date: '2023-05-31',
      time: '14:08',
      duration: '30 min',
      patientName: 'Jane Cooper',
      VaccineBrandName: 'N/A',
      status: BOOKING_STATUS.CONFIRMED,
    },
    {
      id: '3',
      date: '2023-06-01',
      time: '14:08',
      duration: '30 min',
      patientName: 'Jane Cooper',
      VaccineBrandName: 'N/A',
      status: BOOKING_STATUS.CONFIRMED,
    },
    {
      id: '4',
      date: '2023-06-02',
      time: '14:08',
      duration: '30 min',
      patientName: 'Jane Cooper',
      VaccineBrandName: 'N/A',
      status: BOOKING_STATUS.CONFIRMED,
    },
    {
      id: '5',
      date: '2023-06-03',
      time: '14:08',
      duration: '30 min',
      patientName: 'Jane Cooper',
      VaccineBrandName: 'N/A',
      status: BOOKING_STATUS.CONFIRMED,
    },
  ];

  const events = {
    '2024-10-01': [{ name: 'Sublocade', time: '09:00', color: '#4CAF50', endDate: '2024-10-02' }],
    '2024-10-03': [
      {
        name: 'Sublocade - Can be given upto 42 days since the last injection',
        time: '14:00',
        color: '#4CAF50',
        endDate: '2024-10-16',
      },
    ],
  };

  const specialDates = ['2024-10-01']; // Add the date you want to show the star

  const handleDateSelect = (newDate: Dayjs | null) => {
    setDate(newDate);
    // You can add additional logic here if needed
  };

  const handleMonthChange = (newDate: Dayjs) => {
    // You can add logic here to fetch appointments for the new month if needed
    logger.log('Month changed:', newDate.format('YYYY-MM'));
  };

  const handleNavigate = (newDate: Date) => {
    setCurrentDate(newDate);
    logger.log('Navigated to:', newDate);
  };

  // Define an array of colors to use for the left border
  const borderColors = ['#4CAF50', '#2196F3', '#FFC107', '#9C27B0', '#F44336'];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        <IconButton edge="start" color="primary" aria-label="back">
          <ArrowBack />
        </IconButton>
        <Typography variant="h4" component="h1" sx={{ ml: 2 }}>
          Patient Detail
        </Typography>
      </Box> */}

      {/* <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          background: 'linear-gradient(to right, #1976d2, #64b5f6)', // Gradient from darker to lighter blue
          color: 'primary.contrastText',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Avatar alt={patientInfo.name} src="/placeholder.svg?height=100&width=100" sx={{ width: 80, height: 80 }} />
          <Box>
            <Typography variant="h4" component="h2" sx={{ color: 'white' }}>
              {patientInfo.name}
            </Typography>
            <Typography variant="body1">Date of Birth: {patientInfo.dob}</Typography>
          </Box>
        </Box>
      </Paper> */}

      <Grid container spacing={1}>
        <Grid item xs={12} md={9.5}>
          <Paper elevation={3}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="patient information tabs">
              <Tab label="ODT Injection" />
              <Tab label="Schedule" />
              <Tab label="History" />
              <Tab label="Information" />
            </Tabs>
            {tabValue === 0 && <Box sx={{ p: 3 }}>{/* ODT Injection content */}</Box>}
            {tabValue === 1 && (
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Schedule for {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </Typography>
                <SupplyCalendar
                  events={events}
                  currentDate={currentDate}
                  onNavigate={handleNavigate}
                  specialDates={specialDates}
                />
              </Box>
            )}
            {tabValue === 2 && <Box sx={{ p: 3 }}>{/* History content */}</Box>}
            {tabValue === 3 && (
              <Box sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <InfoSection title="Patient" data={patientInfo} />
                  <InfoSection title="Carer" data={carerInfo} />
                  <InfoSection title="Pharmacy providing the service" data={pharmacyInfo} />
                  <InfoSection title="Prescriber" data={prescriberInfo} />
                </Grid>
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={2.5}>
          <AppointmentCalendar
            appointments={upcomingSessions}
            isLoading={isLoading}
            onDateSelect={handleDateSelect}
            handleOnMonthChange={handleMonthChange}
          />
          <List>
            {upcomingSessions.map((session, index) => (
              <ListItem
                key={session.id}
                divider
                sx={{
                  borderLeft: 4,
                  borderLeftColor: borderColors[index % borderColors.length],
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" color="textPrimary">
                      {session.status === BOOKING_STATUS.CONFIRMED ? 'Scheduled Injection' : session.status}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" component="span">
                        {`${session.date} | ${session.time}`}
                      </Typography>
                      <br />
                      {/* <Typography variant="body2" component="span">
                        {session.VaccineBrandName !== 'N/A' ? session.VaccineBrandName : 'ODT Injection'}
                      </Typography> */}
                    </>
                  }
                />
                <Typography variant="body2" color="text.secondary">
                  {session.duration}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

function InfoSection({ title, data }: { title: string; data: Record<string, string> }) {
  return (
    <Grid item xs={12} sm={6}>
      <div
        style={{
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: '20px',
          textAlign: 'left',
          fontSize: '14px',
          color: '#6c757d',
        }}
      >
        <TextField
          value={title}
          InputProps={{
            readOnly: true,
          }}
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            alignSelf: 'stretch',
            height: '38px',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '16px',
            color: '#05153f',
          }}
          placeholder={title}
          variant="outlined"
          sx={{
            '& fieldset': { border: 'none' },
            '& .MuiInputBase-root': {
              height: '38px',
              backgroundColor: '#e2f1f0',
              borderRadius: '0px 0px 0px 0px',
            },
            '& .MuiInputBase-input': { color: '#05153f' },
          }}
        />
        <div
          style={{
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '13px',
          }}
        >
          {Object.entries(data).map(([key, value], index) => (
            <React.Fragment key={key}>
              <div
                style={{
                  alignSelf: 'stretch',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  padding: '0px 10px',
                  gap: '12px',
                }}
              >
                <div
                  style={{
                    width: '200px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                  }}
                >
                  {key}
                </div>
                <b
                  style={{
                    width: '240px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {value}
                </b>
              </div>
              {index < Object.entries(data).length - 1 && (
                <div
                  style={{
                    alignSelf: 'stretch',
                    height: '1px',
                    position: 'relative',
                    borderTop: '1px solid #e3e3e3',
                    boxSizing: 'border-box',
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Grid>
  );
}
