import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookExecOptions,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import {
  Exact,
  GetAvailabilityQuery,
  GetAvailabilityQueryVariables,
  PharmacyAvailability,
  UpdatePharmacyAvailabilityMutation,
  UpdatePharmacyAvailabilityMutationVariables,
} from '../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import { MUTATION_UPDATE_AVAILABILITY } from '../codegens/HEALTCARE-RESERVATION/mutations';
import { GRAPHQL_DESTINATION } from '../ApolloClient';
import { GET_AVAILABILITY } from '../codegens/HEALTCARE-RESERVATION/queries';
import { IAvailability } from '../interfaces/healthcare-reservation/IAvailability';
import { formatToAvailability } from '../utils/graphql-layers/reservations';

export const useAvailabilities = () => {
  const [updateMutation, { loading, error }] = useMutation<
    UpdatePharmacyAvailabilityMutation,
    UpdatePharmacyAvailabilityMutationVariables
  >(MUTATION_UPDATE_AVAILABILITY, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const [getAvailabilityQuery, { error: errorGetAvailability, loading: loadingGetAvailability }] = useLazyQuery<
    GetAvailabilityQuery,
    GetAvailabilityQueryVariables
  >(GET_AVAILABILITY, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const updateAvailability = async (
    options?: MutationFunctionOptions<
      UpdatePharmacyAvailabilityMutation,
      UpdatePharmacyAvailabilityMutationVariables,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return await updateMutation({
      ...options,
    });
  };

  const getAvailability = async (options: {
    onCompleted: (data: IAvailability | null) => void;
    availabilityID: string;
  }) => {
    return await getAvailabilityQuery({
      variables: {
        availabilityID: options.availabilityID,
      },
      onCompleted(data) {
        if (typeof options?.onCompleted === 'function') {
          options.onCompleted(formatToAvailability(data.getPharmacyAvailability as PharmacyAvailability));
        }
      },
    });
  };

  return {
    updateAvailability,
    loadingUpdateAvailability: loading,
    errorUpdateAvailability: error,
    getAvailability,
    loadingGetAvailability,
    errorGetAvailability,
  };
};
