const formatDate = (dateString: string): string => {
  if (!dateString || dateString.length !== 8) return "Ongoing"; // or return an empty string if you prefer

  // Assuming the date format is "DDMMYYYY"
  const day = dateString.substring(0, 2);
  const month = dateString.substring(2, 4);
  const year = dateString.substring(4);

  // Create a date object
  const date = new Date(`${year}-${month}-${day}`);

  // Options to pass for toLocaleDateString
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // Format the date
  return date.toLocaleDateString("en-GB", options);
};

export const clearLocalStorageByPrefix = (prefixes: string[]): void => {
  // Retrieve all keys from localStorage
  const keys = Object.keys(localStorage);

  // Iterate over all keys
  keys.forEach((key) => {
    // Check if the current key starts with any of the specified prefixes
    const shouldRemove = prefixes.some(prefix => key.startsWith(prefix));

    // If the key matches, remove it from localStorage
    if (shouldRemove) {
      localStorage.removeItem(key);
    }
  });
};

export { formatDate };
