import React, { useContext } from 'react';
import { Navigate, Route, RouteProps, useParams, useLocation } from 'react-router-dom';
import RecordHistoricalVaccinations from '../pages/patients/RecordHistoricalVaccinations';
import SubscriptionDashboard from '../pages/payments/SubscriptionDashboard';
import AdminDashboard from '../pages/administration/admin';

import IdentificationForm from '../pages/patients/IdentificationForm';
import { AppointmentsProvider } from '../contexts/AppointmentContext';
import PatientFollowups from '../components/PatientFollowups';
import PrivacyPolicyComponent from '../pages/legal/privacy';
import DisclaimerComponent from '../pages/legal/disclaimer';
import CookiePolicyComponent from '../pages/legal/cookie';
import AuthContext from '../contexts/AuthContext';

// components
const Analytics = React.lazy(() => import('../pages/analytics/analytics'));
const Claims = React.lazy(() => import('../pages/claims/claims'));
const PatientCreateForm = React.lazy(() => import('../pages/patients/PatientCreateForm'));
const PatientPage = React.lazy(() => import('../pages/patients/PatientPage'));
const ClinicPatientsPage = React.lazy(() => import('../pages/patients/ClinicPatientsPage'));
const SearchPatients = React.lazy(() => import('../pages/patients/SearchPatients'));
const Settings = React.lazy(() => import('../pages/settings/settings'));
const Tutorials = React.lazy(() => import('../pages/tutorials/tutorials'));
const PrivateRoute = React.lazy(() => import('./PrivateRoute'));

//scope of practice
import VICUrinaryTractInfections from '../pages/ScopeOfPractice/UrinaryTractInfections/VIC';
import PatientDashboard from '../components/PatientDashboard';
import BloodPressureManagement from '../components/BloodPressureManagement';
import CholesterolManagement from '../components/CholesterolManagement'; // Add this import
import OffsiteClinics from '../pages/vaccine/OffsiteClinics';
import NewClinic from '../pages/vaccine/NewClinic';
import Walkins from '../pages/apps/Appointments/Walkins';
import DiabetesMonitoring from '../components/DiabetesMonitoring';
import IronScreenings from 'components/IronScreenings';
import SearchScreen from '../components/SearchScreen';
import LaunchedClinic from '../pages/vaccine/LaunchedClinic'; // Add this import
import WAUrinaryTractInfections from 'pages/ScopeOfPractice/UrinaryTractInfections/WA';
import QLDUrinaryTractInfections from 'pages/ScopeOfPractice/UrinaryTractInfections/QLD';

// auth2
const Login2 = React.lazy(() => import('../pages/auth2/Login2'));
const MfaConfirmation = React.lazy(() => import('../pages/auth2/MfaConfirmation'));
const ChangePassword2 = React.lazy(() => import('../pages/auth2/ChangePassword2'));

const Logout2 = React.lazy(() => import('../pages/auth2/Logout2'));
const Register2 = React.lazy(() => import('../pages/auth2/Register2'));
const ForgetPassword2 = React.lazy(() => import('../pages/auth2/ForgetPassword2'));
const OtpForgetPassword = React.lazy(() => import('../pages/auth2/OtpForgetPassword'));

const SetPassword = React.lazy(() => import('../pages/auth2/ResetPassword'));
const LockScreen2 = React.lazy(() => import('../pages/auth2/LockScreen2'));

const ChooseAccount = React.lazy(() => import('../pages/auth2/ChooseAccount'));

// landing
const Landing = React.lazy(() => import('../pages/landing/'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));

// apps
// const AppointmentsApp = React.lazy(() => import('../pages/apps/Calendar/'));
const AppointmentsApp = React.lazy(() => import('../pages/apps/AppointmentCalendar/'));

// services
const Services = React.lazy(() => import('../pages/appointments/Service/Service'));

const EditService = React.lazy(() => import('../pages/appointments/Service/EditService'));

const NewService = React.lazy(() => import('../pages/appointments/Service/NewService'));

// rooms
const Rooms = React.lazy(() => import('../pages/rooms/Rooms'));
const EditRoom = React.lazy(() => import('../pages/rooms/EditRoom'));
const NewRoom = React.lazy(() => import('../pages/rooms/NewRoom'));

// extra pages
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error404Two = React.lazy(() => import('../pages/error/Error404Two'));
const Error404Alt = React.lazy(() => import('../pages/error/Error404Alt'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const Error500Two = React.lazy(() => import('../pages/error/Error500Two'));
// - other
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Upcoming = React.lazy(() => import('../pages/other/Upcoming'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const ContactUs = React.lazy(() => import('../pages/other/ContactUs'));
const Onboarding = React.lazy(() => import('../pages/onboarding/onboarding'));
const TermsAndConditions = React.lazy(() => import('../pages/legal/terms'));

// icons
const TwoToneIcons = React.lazy(() => import('../pages/icons/TwoToneIcons/'));
const FeatherIcons = React.lazy(() => import('../pages/icons/FeatherIcons/'));
const Dripicons = React.lazy(() => import('../pages/icons/Dripicons/'));
const MDIIcons = React.lazy(() => import('../pages/icons/MDIIcons/'));
const FontAwesomeIcons = React.lazy(() => import('../pages/icons/FontAwesomeIcons/'));
const ThemifyIcons = React.lazy(() => import('../pages/icons/ThemifyIcons/'));
const SimpleLineIcons = React.lazy(() => import('../pages/icons/SimpleLineIcons/'));
const WeatherIcons = React.lazy(() => import('../pages/icons/WeatherIcons/'));

const PatientAdministration = React.lazy(() => import('../pages/patients/PatientAdministration'));
const InjectionAdministration = React.lazy(() => import('../pages/injection/InjectionAdministration'));
const InfluenzaVaccinationForm = React.lazy(() => import('../pages/vaccine/InfluenzaVaccinationForm'));
const ProfessionalServices = React.lazy(() => import('../pages/services/ProfessionalServices'));

const CertificateHistory = React.lazy(() => import('../pages/services/CertificateHistory'));
// const ProjectStopSale = React.lazy(() => import("../pages/safe/sale"));
const ProjectStopSale = React.lazy(() => import('../pages/safe/sale/Sale'));

const ProjectStopPurchaserDetail = React.lazy(() => import('../pages/safe/sale/PurchaserDetail'));

const ProjectStopSaleHistory = React.lazy(() => import('../pages/safe/sale/SaleHistory'));

const ProjectStopSaleProduct = React.lazy(() => import('../pages/safe/sale/SaleProduct'));

const ProjectStopSaleProductReturn = React.lazy(() => import('../pages/safe/sale/ReturnProduct'));

const ProjectStopSaleCarts = React.lazy(() => import('../pages/safe/sale/SaleCart'));

const ProjectStopSaleCheckout = React.lazy(() => import('../pages/safe/sale/SaleCheckout'));

const ProjectStopSafeStocks = React.lazy(() => import('../pages/safe/stocks/Stocks'));

const ProjectStopSafeStockUpload = React.lazy(() => import('../pages/safe/stocks/UploadStock'));

const ProjectStopSafeStockNew = React.lazy(() => import('../pages/safe/stocks/NewStock'));

const ProjectStopSafeStockEdit = React.lazy(() => import('../pages/safe/stocks/EditStock'));

const ProjectStopSafeStockDetail = React.lazy(() => import('../pages/safe/stocks/DetailStock'));

const ProjectStopSafeHistoryTransaction = React.lazy(() => import('../pages/safe/history/HistoryTransaction'));

const ProjectStopSafeDetailTransaction = React.lazy(() => import('../pages/safe/history/DetailTransaction'));

const ProjectStopSafeReport = React.lazy(() => import('../pages/safe/report/Report'));

const ProjectStopSafeCustomerReport = React.lazy(() => import('../pages/safe/report/CustomerReport'));

const ProjectStopSafeUsersManagement = React.lazy(() => import('../pages/safe/user/Users'));

const ProjectStopSafeUserNew = React.lazy(() => import('../pages/safe/user/NewUser'));

const ProjectStopSafeUserEdit = React.lazy(() => import('../pages/safe/user/EditUser'));

const ProjectStopSafeHistoryTransactionsPrint = React.lazy(() => import('../pages/safe/history/PrintTransactions'));

const ProjectStopSafeHistoryTransactionPrint = React.lazy(() => import('../pages/safe/history/PrintTransaction'));

const ProjectStopSafeHistoryCustomerTransactionPrint = React.lazy(
  () => import('../pages/safe/history/CustomerPrintTransaction')
);

const PpaMedsCheck = React.lazy(() => import('../pages/ppa/Medscheck'));

const PpaDiabetesMedsCheck = React.lazy(() => import('../pages/ppa/DiabetesMedsCheck'));

const PpaNIPVIP = React.lazy(() => import('../pages/ppa/Nipvip'));

const PpaCovid = React.lazy(() => import('../pages/ppa/CovidNineteen'));

const CVCPClaims = React.lazy(() => import('../pages/ppa/CVCPClaims'));

const NIPVIPClaims = React.lazy(() => import('../pages/ppa/NIPVIPClaims'));

const PPAAdministration = React.lazy(() => import('../pages/administration/ppadmin'));

const PpaStagedSupply = React.lazy(() => import('../pages/ppa/StagedSupply'));

const StagedSupplyPatientIntakeForm = React.lazy(() => import('../pages/StagedSupply/StagedSupplyPatientIntakeForm'));

const StagedSupplyDashboard = React.lazy(() => import('../pages/StagedSupply/PatientDashboard'));

const PpaODTInjection = React.lazy(() => import('../pages/ppa/ODTInjection'));

const PpaODTStagedSupply = React.lazy(() => import('../pages/ppa/ODTStagedSupply'));

import ODTPatientList from '../pages/ODT/ODTPatientList';
import ODTDashboard from '../pages/ODT/Dashboard';
import ODTPatientIntakeForm from '../pages/ODT/ODTPatientIntakeForm';

// Import the PatientDashboard component
import ODTPatientDashboard from '../pages/ODT/PatientDashboard';
import NSWUrinaryTractInfections from 'pages/ScopeOfPractice/UrinaryTractInfections/NSW';
import NutritionPlanning from 'pages/NutritionPlanning';
import DietaryAssessment from 'pages/NutritionPlanning/DietaryAssessment';
import NutritionPlanningForm from 'pages/NutritionPlanning/NutritionPlanningForm';
import StagedSupplyPatientList from 'pages/StagedSupply/StagedSupplyPatientList';

// const Certificate = React.lazy(() => import("../pages/services/Certificate"));

export interface RoutesProps {
  path: RouteProps['path'];
  name?: string;
  element?: RouteProps['element'];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

const dashboardRoutes: RoutesProps = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'airplay',
  header: 'Navigation',
  children: [
    {
      path: '/',
      name: 'Root',
      element: <Navigate to="/dashboard-air" />,
      route: PrivateRoute,
    },
    {
      path: '/dashboard-air',
      name: 'Dashboard',
      element: <Dashboard />,
      route: PrivateRoute,
    },
    {
      path: '/subscription-dashboard',
      name: 'Payments',
      element: <SubscriptionDashboard />,
      route: PrivateRoute,
    },
    {
      path: '/pages/admin/devices',
      name: 'administration',
      element: <AdminDashboard />,
      route: PrivateRoute,
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      element: <ContactUs />,
      route: PrivateRoute,
    },
    {
      path: '/onboarding-air',
      name: 'Onboarding',
      element: <Onboarding />,
      route: PrivateRoute,
    },
  ],
};

// Create a new component to handle UTI routing
const UTIRouter: React.FC = () => {
  const { pharmacyState } = useContext(AuthContext);

  switch (pharmacyState) {
    case 'VIC':
      return <VICUrinaryTractInfections />;
    case 'WA':
      return <WAUrinaryTractInfections />;
    case 'QLD':
      return <QLDUrinaryTractInfections />;
    case 'NSW':
      return <NSWUrinaryTractInfections />;
    default:
      return <Navigate to="/error-404" />;
  }
};

const scopeOfPracticeRoutes: RoutesProps = {
  path: '/scope-of-practice',
  name: 'Scope Of Practice',
  icon: 'briefcase',
  header: 'Pharmacy Services',
  children: [
    {
      path: '/scope-of-practice/uti',
      name: 'Uncomplicated UTI',
      element: <UTIRouter />,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: '/apps/appointments',
  name: 'Appointments',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'calendar',
  element: (
    // <AppointmentsProvider>
    <AppointmentsApp />
    // </AppointmentsProvider>
  ),
  header: 'Apps',
};

const patientAppRoutes = {
  path: '/pages/patients',
  name: 'patients',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'users',
  children: [
    {
      path: '/pages/patients/search-patient',
      name: 'Search Patients',
      element: <SearchPatients />,
      route: PrivateRoute,
    },
    {
      path: '/pages/patients/patient-details',
      name: 'Patient Details',
      element: <PatientAdministration />,
      route: PrivateRoute,
    },
  ],
};

const servicesRoutes: RoutesProps = {
  path: '/pages/services',
  name: 'services',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'layers',
  element: <Services />,
  children: [
    {
      path: '/pages/services/:serviceId/:action',
      name: 'Edit Service',
      element: <EditService />,
      route: PrivateRoute,
    },
    {
      path: '/pages/services/new',
      name: 'New Service',
      element: <NewService />,
      route: PrivateRoute,
    },
  ],
};

const roomsRoutes: RoutesProps = {
  path: '/pages/rooms',
  name: 'rooms',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'grid',
  element: <Rooms />,
  children: [
    {
      path: '/pages/rooms/:roomID/edit',
      name: 'Edit Room',
      element: <EditRoom />,
      route: PrivateRoute,
    },
    {
      path: '/pages/rooms/new',
      name: 'New Room',
      element: <NewRoom />,
      route: PrivateRoute,
    },
  ],
};

const airPagesRoutes: RoutesProps = {
  path: '/pages',
  name: 'Pages',
  icon: 'package',
  header: 'AIR',
  children: [
    {
      path: '/pages/vaccine-administration',
      name: 'Vaccine Administration',
      element: <SearchPatients />,
      route: PrivateRoute,
    },
    {
      path: '/pages/vaccine-administration/offsite-clinics',
      name: 'Offsite Clinics',
      element: <OffsiteClinics />,
      route: PrivateRoute,
    },
    {
      path: '/pages/vaccine-administration/offsite-clinics/new',
      name: 'New Clinic',
      element: <NewClinic />,
      route: PrivateRoute,
    },
    {
      path: '/pages/vaccine-administration/offsite-clinics/edit/:clinicID',
      name: 'Edit Clinic',
      element: <NewClinic />,
      route: PrivateRoute,
    },
    // Add the new route for LaunchedClinic
    {
      path: '/pages/vaccine-administration/offsite-clinics/launch/:clinicID',
      name: 'Launch Clinic',
      element: <LaunchedClinic />,
      route: PrivateRoute,
    },
    {
      path: '/pages/vaccine-eligibility',
      name: 'Vaccine Eligibility',
      element: <InfluenzaVaccinationForm />,
      route: PrivateRoute,
    },
    {
      path: '/pages/historical-records',
      name: 'Vaccine Administration',
      element: (
        <RecordHistoricalVaccinations
          patientIdentifier={''}
          vaxHistory={null}
          individualDetails={null}
          patientItemDetails={undefined}
        />
      ),
      route: PrivateRoute,
    },
    {
      path: '/pages/injection-administration',
      name: 'Injection Administration',
      element: <InjectionAdministration />,
      route: PrivateRoute,
    },
    {
      path: '/pages/professional-services',
      name: 'Professional Services',
      element: <ProfessionalServices />,
      route: PrivateRoute,
    },

    {
      path: '/pages/certificate-history',
      name: 'Professional Services',
      element: <CertificateHistory />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/sale',
      name: 'Product Sale',
      element: <ProjectStopSale />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/sale/purchaser-detail',
      name: 'Purchaser Detail',
      element: <ProjectStopPurchaserDetail />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/sale/:identificationNumber',
      name: 'Transaction History',
      element: <ProjectStopSaleHistory />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/sale/:vapePatientID/new',
      name: 'New Sale',
      element: <ProjectStopSaleProduct />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/sale/:vapePatientID/return',
      name: 'Return Product',
      element: <ProjectStopSaleProductReturn />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/sale/carts',
      name: 'Carts',
      element: <ProjectStopSaleCarts />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/sale/carts/:vapePatientID/checkout',
      name: 'Checkout',
      element: <ProjectStopSaleCheckout />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/stocks',
      name: 'Products',
      element: <ProjectStopSafeStocks />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/stocks/upload',
      name: 'Stocks',
      element: <ProjectStopSafeStockUpload />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/stocks/:productId',
      name: 'Stock Detail',
      element: <ProjectStopSafeStockDetail />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/stocks/:productId/edit',
      name: 'Stock Edit',
      element: <ProjectStopSafeStockEdit />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/stocks/new',
      name: 'Stock New',
      element: <ProjectStopSafeStockNew />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/history',
      name: 'History Transaction',
      element: <ProjectStopSafeHistoryTransaction />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/history/transactions/:transactionID',
      name: 'Detail Transaction',
      element: <ProjectStopSafeDetailTransaction />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/report',
      name: 'Customers',
      element: <ProjectStopSafeReport />,
      route: PrivateRoute,
    },
    {
      path: '/pages/safe/report/:customerID',
      name: 'Customer Report',
      element: <ProjectStopSafeCustomerReport />,
      route: PrivateRoute,
    },
    {
      path: '/pages/admin/users',
      name: 'Users Management',
      element: <ProjectStopSafeUsersManagement />,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/pages/admin/users/:pharmacistId/edit',
      name: 'Edit User',
      element: <ProjectStopSafeUserEdit />,
      route: PrivateRoute,
    },
    {
      path: '/pages/admin/users/new',
      name: 'New User',
      element: <ProjectStopSafeUserNew />,
      route: PrivateRoute,
    },
    {
      path: '/pages/admin/ppa',
      name: 'New User',
      element: <PPAAdministration />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/medscheck',
      name: 'Meds Check',
      element: <PpaMedsCheck />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/diabetes',
      name: 'Diabetes Meds Check',
      element: <PpaDiabetesMedsCheck />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/nipvip',
      name: 'NIPVIP',
      element: <PpaNIPVIP />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/cvcp',
      name: 'Covid-19',
      element: <PpaCovid />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/nipvipClaims',
      name: 'CVCP-Claims',
      element: <NIPVIPClaims />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/cvcpClaims',
      name: 'CVCP-Claims',
      element: <CVCPClaims />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/stagedSupply',
      name: 'Staged Supply',
      element: <StagedSupplyPatientList />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/stagedSupply/new',
      name: 'New Staged Supply',
      element: <StagedSupplyPatientIntakeForm />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/stagedSupply/patient/:id',
      name: 'Staged Supply Patient',
      element: <StagedSupplyDashboard />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/odtInjection',
      name: 'ODT Injection',
      element: <ODTPatientList />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/odtInjection/new',
      name: 'New ODT Patient',
      element: <ODTPatientIntakeForm />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/odtInjection/patient/:id',
      name: 'ODT Patient Dashboard',
      element: <ODTPatientDashboard />,
      route: PrivateRoute,
    },
    {
      path: '/pages/ppa/odtStagedSupply',
      name: 'ODT Staged Supply',
      element: <PpaODTStagedSupply />,
      route: PrivateRoute,
    },
    {
      path: '/pages/nutrition-planning',
      name: 'Nutrition Planning',
      element: <NutritionPlanning />,
      route: PrivateRoute,
    },
    {
      path: '/pages/nutrition-planning/form',
      name: 'New Nutrition Plan',
      element: <NutritionPlanningForm />,
      route: PrivateRoute,
    },
    {
      path: '/pages/nutrition-planning/dietary-assessment',
      name: 'Dietary Assessment',
      element: <DietaryAssessment />,
      route: PrivateRoute,
    },
    // {
    //   path: "/pages/certificate",
    //   name: "Certificate",
    //   element: <Certificate />,
    //   route: PrivateRoute,
    // },
    {
      path: '/pages/settings',
      name: 'Settings',
      element: <Settings />,
      route: PrivateRoute,
    },
    {
      path: '/pages/analytics',
      name: 'Analytics',
      element: <Analytics />,
      route: PrivateRoute,
    },
    {
      path: '/pages/tutorials',
      name: 'Tutorials',
      element: <Tutorials />,
      route: PrivateRoute,
    },
    {
      path: '/pages/claims',
      name: 'Reports',
      element: <Claims />,
      route: PrivateRoute,
    },
    {
      path: '/patient/:patientId',
      name: 'Patient Page',
      element: <PatientPage />,
      route: PrivateRoute,
    },
    {
      path: '/patient/clinic/:patientId',
      name: 'Clinic Patients Page',
      element: (
        <React.Fragment>
          {() => {
            const { patientId } = useParams<{ patientId: string }>();
            const location = useLocation();
            const patientData = location.state?.patientData;

            if (!patientData) {
              // Handle the case where patientData is not available
              return <div>Error: Patient data not available</div>;
            }

            return <ClinicPatientsPage patientId={patientId || ''} patientData={patientData} />;
          }}
        </React.Fragment>
      ),
      route: PrivateRoute,
    },
    {
      path: '/patient/identify',
      name: 'Identify Page',
      element: <IdentificationForm />,
      route: PrivateRoute,
    },
    {
      path: '/pages/patients/add-patient',
      name: 'Add Patient',
      element: <PatientCreateForm />,
      route: PrivateRoute,
    },
    {
      path: '/pages/patients/wellness-pathways',
      name: 'Patient Wellness Pathways',
      element: <PatientFollowups />,
      route: PrivateRoute,
    },
    {
      path: '/pages/patients/dashboard-patient',
      name: 'Patient File',
      element: <PatientDashboard followUps={[]} />,
      route: PrivateRoute,
    },
    {
      path: '/health-monitoring/blood-pressure',
      name: 'Blood Pressure Recording',
      element: <BloodPressureManagement />,
      route: PrivateRoute,
    },
    {
      path: '/health-monitoring/cholesterol',
      name: 'Cholesterol Management',
      element: <CholesterolManagement />,
      route: PrivateRoute,
    },
    {
      path: '/health-monitoring/blood-glucose',
      name: 'Blood Glucose Management',
      element: <DiabetesMonitoring />,
      route: PrivateRoute,
    },
    {
      path: '/health-monitoring/iron-screenings',
      name: 'Iron Screenings',
      element: <IronScreenings />,
      route: PrivateRoute,
    },
    {
      path: '/apps/appointments/walkins',
      name: 'Walk-in Appointments',
      element: <Walkins />,
      route: PrivateRoute,
    },
    {
      path: '/pages/patients/ihisearch',
      name: 'Patient New Search',
      element: <SearchScreen />,
      route: PrivateRoute,
    },
  ],
};

const appRoutes = [
  calendarAppRoutes,
  airPagesRoutes,
  patientAppRoutes,
  servicesRoutes,
  roomsRoutes,
  scopeOfPracticeRoutes,
];

// pages
const extrapagesRoutes: RoutesProps = {
  path: '/pages',
  name: 'Pages',
  icon: 'package',
  header: 'Custom',
  children: [
    {
      path: '/pages/faq',
      name: 'FAQ',
      element: <FAQ />,
      route: PrivateRoute,
    },
    {
      path: '/pages/error-404-alt',
      name: 'Error - 404-alt',
      element: <Error404Alt />,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: '/ui',
  name: 'Components',
  icon: 'pocket',
  header: 'UI Elements',
  children: [
    {
      path: '/ui/icons',
      name: 'Icons',
      children: [
        {
          path: '/ui/icons/two-tone',
          name: 'Two Tone Icons',
          element: <TwoToneIcons />,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/feather',
          name: 'Feather Icons',
          element: <FeatherIcons />,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/dripicons',
          name: 'Dripicons',
          element: <Dripicons />,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/mdi',
          name: 'Material Design',
          element: <MDIIcons />,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/font-awesome',
          name: 'Font Awesome 5',
          element: <FontAwesomeIcons />,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/themify',
          name: 'Themify',
          element: <ThemifyIcons />,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/simple-line',
          name: 'Simple Line Icons',
          element: <SimpleLineIcons />,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/weather',
          name: 'Weather Icons',
          element: <WeatherIcons />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/ui/forms',
      name: 'Forms',
      children: [
        // {
        //   path: "/ui/forms/basic",
        //   name: "Basic Elements",
        //   element: <BasicForms />,
        //   route: PrivateRoute,
        // },
        // {
        //   path: "/ui/forms/advanced",
        //   name: "Form Advanced",
        //   element: <FormAdvanced />,
        //   route: PrivateRoute,
        // },
        // {
        //   path: "/ui/forms/validation",
        //   name: "Form Validation",
        //   element: <FormValidation />,
        //   route: PrivateRoute,
        // },
        // {
        //   path: "/ui/forms/wizard",
        //   name: "Form Wizard",
        //   element: <FormWizard />,
        //   route: PrivateRoute,
        // },
        // {
        //   path: "/ui/forms/upload",
        //   name: "File Upload",
        //   element: <FileUpload />,
        //   route: PrivateRoute,
        // },
        // {
        //   path: "/ui/forms/editors",
        //   name: "Editors",
        //   element: <Editors />,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/ui/charts',
      name: 'Charts',
      children: [
        // {
        //   path: "/ui/charts/apex",
        //   name: "Apex",
        //   element: <ApexChart />,
        //   route: PrivateRoute,
        // },
        // {
        //   path: "/ui/charts/chartjs",
        //   name: "Chartjs",
        //   element: <ChartJs />,
        //   route: PrivateRoute,
        // },
      ],
    },
    {
      path: '/ui/maps',
      name: 'Maps',
      children: [
        // {
        //   path: "/ui/googlemaps",
        //   name: "Google Maps",
        //   element: <GoogleMaps />,
        //   route: PrivateRoute,
        // },
        // {
        //   path: "/ui/vectorMaps",
        //   name: "Google Maps",
        //   element: <VectorMaps />,
        //   route: PrivateRoute,
        // },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: '/auth/login',
    name: 'Login',
    element: <Login2 />,
    route: Route,
  },
  {
    path: '/auth/login/otp',
    name: 'Login',
    element: <MfaConfirmation />,
    route: Route,
  },
  {
    path: '/auth/change-password',
    name: 'Change Password',
    element: <ChangePassword2 />,
    route: Route,
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    element: <Logout2 />,
    route: Route,
  },
  {
    path: '/auth/register',
    name: 'Register',
    element: <Register2 />,
    route: Route,
  },
  {
    path: '/auth/forget-password',
    name: 'Forget Password',
    element: <ForgetPassword2 />,
    route: Route,
  },
  {
    path: '/auth/forget-password/otp',
    name: 'Forget Password',
    element: <OtpForgetPassword />,
    route: Route,
  },
  {
    path: '/auth/forget-password/set-password',
    name: 'Set Password',
    element: <SetPassword />,
    route: Route,
  },
  {
    path: '/auth/lock-screen',
    name: 'Lock Screen2',
    element: <LockScreen2 />,
    route: Route,
  },
  {
    path: '/auth/login/accounts',
    name: 'Accounts',
    element: <ChooseAccount />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: '/landing',
    name: 'landing',
    element: <Landing />,
    route: Route,
  },
  {
    path: '/transactions/print',
    name: 'Print History Transaction',
    element: <ProjectStopSafeHistoryTransactionsPrint />,
    route: Route,
  },
  {
    path: '/invoices/:transactionID/print',
    name: 'Print History Transaction',
    element: <ProjectStopSafeHistoryTransactionPrint />,
    route: Route,
  },
  {
    path: '/customers/:customerID/print',
    name: 'Print Customer History Transaction',
    element: <ProjectStopSafeHistoryCustomerTransactionPrint />,
    route: Route,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    element: <Maintenance />,
    route: Route,
  },
  {
    path: '/error-404',
    name: 'Error - 404',
    element: <Error404 />,
    route: Route,
  },
  {
    path: '/error-404-two',
    name: 'Error - 404 Two',
    element: <Error404Two />,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    element: <Error500 />,
    route: Route,
  },
  {
    path: '/error-500-two',
    name: 'Error - 500 Two',
    element: <Error500Two />,
    route: Route,
  },
  {
    path: '/upcoming',
    name: 'Coming Soon',
    element: <Upcoming />,
    route: Route,
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    element: <TermsAndConditions />,
    route: Route,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    element: <PrivacyPolicyComponent />,
    route: Route,
  },
  {
    path: '/cookie-policy',
    name: 'Cookie Policy',
    element: <CookiePolicyComponent />,
    route: Route,
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    element: <DisclaimerComponent />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [dashboardRoutes, ...appRoutes, extrapagesRoutes, uiRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { authProtectedFlattenRoutes, authProtectedRoutes, publicProtectedFlattenRoutes, publicRoutes };
