// Request payload structure
export interface IdentifyIndividualRequest {
  individual: {
    medicareCard?: {
      medicareCardNumber: string;
      medicareIRN: string;
    };
    personalDetails: {
      firstName?: string;
      lastName: string;
      dateOfBirth: string;
      initial?: string;
      gender?: string;
      onlyNameIndicator?: boolean;
    };
    address?: {
      postCode?: string;
    };
  };
  informationProvider: {
    providerNumber: string;
    hpioNumber?: string;
    hpiiNumber?: string;
  };
  pharmacyID: string;
  deviceName: string;
  minorId: string;
  prodaOrgId: string;
}

// Expected response structure
export interface IdentifyIndividualResponse {
  success: boolean;
  data: {
    statusCode: string;
    codeType: string;
    message: string;
    errors?: Array<{
      code: string;
      field: string;
      message: string;
    }>;
    individualDetails: {
      individualIdentifier: string;
      individual: {
        personalDetails: {
          dateOfBirth: string;
          lastName: string;
          firstName?: string;
          initial: string | null;
          onlyNameIndicator: boolean;
        };
        medicareCard: {
          medicareCardNumber: string;
          medicareIRN: string;
        };
        address: {
          addressLineOne: string;
          addressLineTwo: string;
          locality: string;
          postCode: string;
        };
        atsiIndicator: string;
        ihiNumber: string;
      };
      catchupDate: string;
      indigenousStatus: boolean;
      additionalVaccineIndicator: boolean;
      medContraindicationIndicator: boolean;
      naturalImmunityIndicator: boolean;
      vaccineTrialIndicator: boolean;
      actionRequiredIndicator: boolean;
    };
  };
}

// Function to make the API request
export async function IdentifyIndividualAIR(
  requestData: IdentifyIndividualRequest,
  idToken: string | null | undefined
): Promise<IdentifyIndividualResponse> {
  const apiIdentifyIndividual = process.env.REACT_APP_IDENTIFY_INDIVIDUAL;
  if (!apiIdentifyIndividual) {
    throw new Error('REACT_APP_IDENTIFY_INDIVIDUAL is not defined');
  }
  const response = await fetch(apiIdentifyIndividual, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json() as Promise<IdentifyIndividualResponse>;
}

// Function to make the API request
export async function IdentifyIndividualAIRV2(
  requestData: IdentifyIndividualRequest,
  idToken: string | null | undefined
): Promise<IdentifyIndividualResponse> {
  const apiIdentifyIndividual = process.env.REACT_APP_AIR_VACCINATIONS_V2_API + '/identifyIndividualV2';
  if (!apiIdentifyIndividual) {
    throw new Error('REACT_APP_AIR_VACCINATIONS_V2_API is not defined');
  }
  const response = await fetch(apiIdentifyIndividual, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json() as Promise<IdentifyIndividualResponse>;
}
