import React, { useEffect, useState } from 'react';
import { DataGridProProps, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { IconButton, Tooltip, Typography, Button, TextField } from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import NoResultIcon from '../../assets/images/table-no-result.svg';
import MUITableLicensed from '../../components/MUITableLicensed';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../utils/logger';

interface ODTPatient {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  emailAddress: string;
  medicationType: string;
  nextAppointment: string;
  adherenceStatus: 'Good' | 'Fair' | 'Poor';
}

const columns: GridColDef<ODTPatient>[] = [
  { field: 'firstName', headerName: 'First Name', flex: 1 },
  { field: 'lastName', headerName: 'Last Name', flex: 1 },
  { field: 'dateOfBirth', headerName: 'Date of Birth', flex: 1 },
  { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
  { field: 'emailAddress', headerName: 'Email Address', flex: 1 },
  { field: 'medicationType', headerName: 'Medication Type', flex: 1 },
  { field: 'nextAppointment', headerName: 'Next Appointment', flex: 1 },
  {
    field: 'adherenceStatus',
    headerName: 'Adherence Status',
    flex: 1,
    renderCell: (params: GridRenderCellParams<ODTPatient>) => (
      <Tooltip title={params.value}>
        <span
          style={{
            padding: '4px 8px',
            borderRadius: '4px',
            backgroundColor: params.value === 'Good' ? '#4caf50' : params.value === 'Fair' ? '#ff9800' : '#f44336',
            color: 'white',
          }}
        >
          {params.value}
        </span>
      </Tooltip>
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<ODTPatient>) => {
      const handleEdit = (e: React.MouseEvent) => {
        e.stopPropagation();
        logger.log('Edit patient clicked', params.row.id);
      };

      const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        logger.log('Delete patient clicked', params.row.id);
      };

      return (
        <>
          <Tooltip title="Edit">
            <IconButton onClick={handleEdit}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={handleDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  },
];

const dummyODTPatients: ODTPatient[] = [
  {
    id: '1',
    firstName: 'John',
    lastName: 'Doe',
    dateOfBirth: '1980-05-15',
    phoneNumber: '0412345678',
    emailAddress: 'john.doe@example.com',
    medicationType: 'Methadone',
    nextAppointment: '2023-05-01',
    adherenceStatus: 'Good',
  },
  {
    id: '2',
    firstName: 'Jane',
    lastName: 'Smith',
    dateOfBirth: '1975-09-22',
    phoneNumber: '0423456789',
    emailAddress: 'jane.smith@example.com',
    medicationType: 'Buprenorphine',
    nextAppointment: '2023-05-07',
    adherenceStatus: 'Fair',
  },
  {
    id: '3',
    firstName: 'Bob',
    lastName: 'Johnson',
    dateOfBirth: '1990-12-03',
    phoneNumber: '0434567890',
    emailAddress: 'bob.johnson@example.com',
    medicationType: 'Naltrexone',
    nextAppointment: '2023-05-15',
    adherenceStatus: 'Poor',
  },
];

const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <img src={NoResultIcon} alt="No results" style={{ width: 60, height: 60 }} />
    <Box sx={{ mt: 1 }}>No ODT Patients</Box>
  </Box>
);

export default function ODTPatientList() {
  const navigate = useNavigate();
  const [patients, setPatients] = useState<ODTPatient[]>(dummyODTPatients);
  const [displayedPatients, setDisplayedPatients] = useState<ODTPatient[]>(dummyODTPatients);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm) {
      const filteredPatients = patients.filter((patient) =>
        Object.values(patient).some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setDisplayedPatients(filteredPatients);
    } else {
      setDisplayedPatients(patients);
    }
  }, [searchTerm, patients]);

  const handleAddNewPatient = () => {
    logger.log('Add new ODT patient clicked');
    navigate('/pages/ppa/odtInjection/new'); // Navigate to the new patient intake form
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/pages/ppa/odtInjection/patient/${params.id}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" fontWeight="bold" color="primary">
          ODT Patients
        </Typography>
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddNewPatient}>
          Add New ODT Patient
        </Button>
      </Box>
      <TextField
        placeholder="Search ODT patients..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{ mb: 3 }}
      />
      <MUITableLicensed
        disableRowSelectionOnClick
        loading={loading}
        rows={displayedPatients}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        onRowClick={handleRowClick}
        checkboxSelection={false}
        pagination
        pageSizeOptions={[25, 50, 100]}
        sx={{ height: 600 }}
      />
    </Box>
  );
}
