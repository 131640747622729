import { WalkInAppointment } from '../types/WalkInAppointment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const API_URL = process.env.REACT_APP_CUSTOMER_CHECKINS_URL;

export const walkInAppointmentService = {
  getAll: async (idToken: string, pharmacyID: string): Promise<WalkInAppointment[]> => {
    if (!API_URL) {
      throw new Error('REACT_APP_CUSTOMER_CHECKINS_URL is not defined');
    }

    const today = dayjs().tz('Australia/Sydney').format('YYYY-MM-DD');

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          operation: 'QUERY_BY_PHARMACY',
          pharmacyID,
          createdDate: today,
          sortDirection: 'DESC',
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.items;
    } catch (error) {
      console.error('Error fetching appointments:', error);
      throw error;
    }
  },

  getById: async (id: string, idToken: string): Promise<WalkInAppointment> => {
    if (!API_URL) {
      throw new Error('REACT_APP_CUSTOMER_CHECKINS_URL is not defined');
    }

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          operation: 'GET',
          id,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching appointment:', error);
      throw error;
    }
  },

  create: async (appointment: Omit<WalkInAppointment, 'id'>, idToken: string): Promise<WalkInAppointment> => {
    if (!API_URL) {
      throw new Error('REACT_APP_CUSTOMER_CHECKINS_URL is not defined');
    }

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          operation: 'CREATE',
          ...appointment,
          createdDate: dayjs().tz('Australia/Sydney').format('YYYY-MM-DD'),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error creating appointment:', error);
      throw error;
    }
  },

  update: async (id: string, appointment: Partial<WalkInAppointment>, idToken: string): Promise<WalkInAppointment> => {
    if (!API_URL) {
      throw new Error('REACT_APP_CUSTOMER_CHECKINS_URL is not defined');
    }

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          operation: 'UPDATE',
          id,
          ...appointment,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error updating appointment:', error);
      throw error;
    }
  },

  delete: async (id: string, idToken: string): Promise<void> => {
    if (!API_URL) {
      throw new Error('REACT_APP_CUSTOMER_CHECKINS_URL is not defined');
    }

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          operation: 'DELETE',
          id,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting appointment:', error);
      throw error;
    }
  },
};
