import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { BOOKING_STATUS } from '../../interfaces/IBooking';
import { useAppointment } from '../../contexts/AppointmentContext';
import AppointmentDetailMode from './AppointmentDetailMode';
import AppointmentForm from './AppointmentForm';

interface IAppointmentModalProps extends ModalProps {
  onCancelAppointment: () => void;
}

export enum EAppointmentModalMode {
  DETAIL = 'DETAIL',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

const generateTitle = (mode: EAppointmentModalMode, fallback?: string | null) => {
  if (fallback) {
    return fallback;
  }

  if (mode === EAppointmentModalMode.CREATE) {
    return 'Add New Appointment';
  }

  if (mode === EAppointmentModalMode.EDIT) {
    return 'Edit Appointment';
  }

  if (mode === EAppointmentModalMode.DETAIL) {
    return 'Detail Appointment';
  }
};

const AppointmentModal = ({ onHide, onCancelAppointment, ...rest }: IAppointmentModalProps) => {
  const [showMode, setShowMode] = useState<EAppointmentModalMode>(EAppointmentModalMode.DETAIL);
  const { selectedAppointment, services } = useAppointment();

  const isBookingCancelled =
    selectedAppointment?.status === BOOKING_STATUS.CANCELED ||
    selectedAppointment?.status === BOOKING_STATUS.CANCELED_BY_PATIENT ||
    selectedAppointment?.status === BOOKING_STATUS.CANCELED_BY_PHARMACY;

  const detailMode = showMode === EAppointmentModalMode.DETAIL;
  const currentService = services.find((service) => service.serviceId === selectedAppointment?.serviceId);
  const titleText = generateTitle(showMode, currentService?.serviceName);

  return (
    <Modal
      {...rest}
      size="lg"
      onHide={() => {
        if (typeof onHide === 'function') {
          onHide();
        }

        // reset
        setShowMode(EAppointmentModalMode.DETAIL);
      }}
    >
      <Modal.Header className="py-3 px-4 border-bottom-0" closeButton>
        <Typography
          variant="h5"
          // sx={(theme) => ({
          //   color: selectedEvent ? selectedEvent._def.extendedProps.backgroundColorData : theme.palette.primary.main,
          // })}
          id="modal-title"
        >
          {isBookingCancelled ? '' : titleText}
        </Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        {detailMode || isBookingCancelled ? (
          <AppointmentDetailMode
            appointment={selectedAppointment}
            onEdit={() => setShowMode(EAppointmentModalMode.EDIT)}
          />
        ) : (
          <AppointmentForm
            onClose={() => {
              onHide();
              setShowMode(EAppointmentModalMode.DETAIL);
            }}
            appointment={selectedAppointment}
            mode={EAppointmentModalMode.EDIT}
            onClickCancel={onCancelAppointment}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AppointmentModal;
