import { useLazyQuery } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_VACCINE_DATA } from "../../graphql-queries";
import { GraphQLVaxListResponse } from "./Vaccine";
import { formatDate } from "./common/utils";
import { VaxHistoryResponse } from "./vaxHistory";
import { logger } from "../../utils/logger";
interface PatientVaccinationsDueListProps {
  vaxHistory: VaxHistoryResponse | null;
}

interface Data {
  disease: string;
  vaccineDose: string;
  dueDate: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: string;
}

const columns: ColumnData[] = [
  { label: "Disease", dataKey: "disease", width: "20%" },
  { label: "Vaccine Dose", dataKey: "vaccineDose", width: "20%" },
  { label: "Due Date", dataKey: "dueDate", width: "20%" },
];

const PatientVaccinationsDueList: React.FC<PatientVaccinationsDueListProps> = ({ vaxHistory }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fetchVaccineData, { data, loading, error }] = useLazyQuery<GraphQLVaxListResponse>(GET_VACCINE_DATA);
  useEffect(() => {
    // Call this function when you want to fetch the vaccine data.
    fetchVaccineData();
    logger.log("Fetching vaccine due listdata...", data);
  }, [data, fetchVaccineData]);

  const rows: Data[] = React.useMemo(() => {
    // Create a mapping of vaccine codes to brand names
    // const vaccineCodeToBrandName = data?.listVaxDataAIRS?.items.reduce(
    //   (acc, current) => {
    //     acc[current.VaccineCode] = current.VaccineBrandName;
    //     return acc;
    //   },
    //   {} as Record<string, string>
    // );

    return (
      vaxHistory?.data?.immunisationDetails?.dueList?.map((dueItem) => ({
        dueDate: formatDate(dueItem.dueDate),
        vaccineDose: dueItem.vaccineDose,
        disease: dueItem.disease,
      })) || [] // Provide an empty array as default value
    );
  }, [vaxHistory]);

  // Handle loading state
  if (!vaxHistory || !vaxHistory.data?.immunisationDetails) {
    return <div>Loading vaccination due list...</div>;
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.dataKey}
                  align={column.numeric ? "right" : "left"}
                  style={{ width: column.width }}
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "white",
                    flex: column.width ? `0 0 ${column.width}` : undefined,
                  }} // Custom color for header cells
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={`row-${rowIndex}`}>
                {columns.map((column) => {
                  const value = row[column.dataKey];
                  return (
                    <TableCell key={column.dataKey} align={column.numeric ? "right" : "left"}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PatientVaccinationsDueList;
