import {
  ICartPayload,
  ICart,
  IUpdateProductCartPayload,
  IClearCartItemByCustomerId,
} from "../../interfaces/ICart";
import { CartActionTypes } from "./constants";

const INIT_STATE = {
  items: [],
};

type CartActionType =
  | {
      type: CartActionTypes.ADD_TO_CART | CartActionTypes.REMOVE_ITEM_FROM_CART;
      payload: ICartPayload;
    }
  | {
      type: CartActionTypes.UPDATE_CUSTOMER_CART_PRODUCT;
      payload: IUpdateProductCartPayload;
    }
  | {
      type: CartActionTypes.CLEAR_CUSTOMER_CART_BY_CUSTOMER_ID;
      payload: IClearCartItemByCustomerId;
    };

interface State {
  items: ICart[];
}

const Cart = (state: State = INIT_STATE, action: CartActionType): any => {
  switch (action.type) {
    case CartActionTypes.ADD_TO_CART: {
      // find current customer in carts
      const index = state.items.findIndex(
        (item) => item.customerId === action.payload.customerId
      );

      // if customer not found in carts, add new customer to carts
      if (index === -1) {
        return {
          ...state,
          items: [
            ...state.items,
            {
              customerId: action.payload.customerId,
              items: [action.payload.item],
            },
          ],
        };
      }

      // if customer found in carts, check if item already exists
      const currentCartProducts = state.items[index].items;
      const currentProductIndex = currentCartProducts.findIndex(
        (product) => action.payload.item.productID === product.productID
      );

      // if item already exists
      if (currentProductIndex !== -1) {

        // update quantity
        currentCartProducts[currentProductIndex].quantity +=
          action.payload.item.quantity;
        return {
          ...state,
          items: [
            ...state.items.slice(0, index),
            {
              ...state.items[index],
              items: currentCartProducts,
            },
            ...state.items.slice(index + 1),
          ],
        };
      }

      // if item not exists, add new item
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          {
            ...state.items[index],
            items: [...state.items[index].items, action.payload.item],
          },
          ...state.items.slice(index + 1),
        ],
      };
    }
    case CartActionTypes.REMOVE_ITEM_FROM_CART: {
      // find current customer in carts
      const index = state.items.findIndex(
        (item) => item.customerId === action.payload.customerId
      );

      // if customer not found in carts, return state
      if (index === -1) {
        return { ...state };
      }

      // if customer found, check if the product is exist
      const currentCartProduct = state.items[index].items.find(
        (product) => product.productID === action.payload.item.productID
      );

      // if product not found, return state
      if (!currentCartProduct) {
        return { ...state };
      }

      // if product found, remove the product in cart items
      const newItems = state.items.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          items: item.items.filter(
            (product) => product.productID !== action.payload.item.productID
          ),
        };
      });

      return { ...state, items: newItems };
    }

    case CartActionTypes.UPDATE_CUSTOMER_CART_PRODUCT: {
      // find current customer in carts
      const index = state.items.findIndex(
        (item) => item.customerId === action.payload.customerId
      );

      // if customer not found in carts, return state
      if (index === -1) {
        return { ...state };
      }

      // if customer found, check if the product is exist
      const currentCartProduct = state.items[index].items.find(
        (product) => product.productID === action.payload.productID
      );

      // if product not found, return state
      if (!currentCartProduct) {
        return { ...state };
      }

      // if product found, update the product in cart items
      const newItems = state.items.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          items: item.items.map((product) => {
            if (product.productID === action.payload.productID) {
              return {
                ...product,
                quantity: action.payload.quantity,
                notes: action.payload.notes,
                saleStatus: action.payload.saleStatus,
                directionOfUse: action.payload.directionOfUse,
              };
            }

            return product;
          }),
        };
      });

      return { ...state, items: newItems };
    }

    case CartActionTypes.CLEAR_CUSTOMER_CART_BY_CUSTOMER_ID: {
      const newItems = state.items.filter(
        (cart) => cart.customerId !== action.payload.customerId
      );

      return { ...state, items: newItems };
    }
    default:
      return { ...state };
  }
};

export default Cart;
