import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { v4 as uuidv4 } from "uuid";

// This arrow function combines class names using clsx and merges them using twMerge to handle Tailwind CSS specificity.
const cn = (...args: ClassValue[]): string => {
  return twMerge(clsx(...args));
};

/**
 * Removes the "__typename" property from an object recursively.
 *
 * @param data - The object from which to remove the "__typename" property.
 * @returns The object with the "__typename" property removed.
 * @template T - The type of the object.
 */
const removeTypename = <T>(data: T): T => {
  if (typeof data === "object" && data !== null) {
    const newData: T = {} as T;

    for (const key in data) {
      if (
        Object.prototype.hasOwnProperty.call(data, key) &&
        key !== "__typename"
      ) {
        newData[key] = removeTypename(data[key]);
      }
    }

    return newData;
  }

  return data;
};

/**
 * Capitalizes the first letter of each word in a given paragraph.
 *
 * @param paragraph - The paragraph to capitalize.
 * @returns The capitalized paragraph.
 */
const capitalize = (paragraph: string) => {
  if (!paragraph) {
    return null;
  }

  var capitalizedWords = paragraph
    .replaceAll("_", " ")
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

  return capitalizedWords.join(" ");
};

/**
 * Generates a unique identifier using the uuidv4 function.
 * @returns {string} The generated unique identifier.
 */
const uuid: () => string = () => {
  return uuidv4();
};

/**
 * Formats a price value into a currency string.
 *
 * @param price - The price value to format.
 * @returns The formatted price as a string.
 */
const formatPrice = (priceInCents: number): string => {
  const priceInDollars = priceInCents / 100;

  return new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",

    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  }).format(priceInDollars);
};

/**
 * Checks if the input value is a valid number.
 *
 * @param value - The input value to be checked.
 * @returns `true` if the input value is a valid number, `false` otherwise.
 */
const isInputANumber = (value: string): boolean => {
  const valueNumber = Number(value);

  return !isNaN(valueNumber) && valueNumber >= 0;
};

/**
 * Checks if a GTIN (Global Trade Item Number) is valid.
 * @param gtin - The GTIN to validate.
 * @returns `true` if the GTIN is valid, `false` otherwise.
 */
const isGtinValid = (gtin: string) => {
  if (!/^\d+$/.test(gtin)) {
    return { isValid: false, error: "GTIN must contain only digits." };
  }

  if (![8, 12, 13, 14].includes(gtin.length)) {
    return {
      isValid: false,
      error: "GTIN must be 8, 12, 13, or 14 digits long.",
    };
  }

  const digits = gtin.split("").map(Number);
  const checksum = digits
    .slice(0, -1)
    .reverse()
    .map((digit, index) => (index % 2 === 0 ? digit * 3 : digit))
    .reduce((acc, value) => acc + value, 0);
  const checkDigit = digits[digits.length - 1];
  const isValid = (10 - (checksum % 10)) % 10 === checkDigit;

  if (!isValid) {
    return { isValid: false, error: "Invalid GTIN checksum." };
  }

  return { isValid: true };
};

/**
 * Converts a dollar amount to cents.
 *
 * @param dollars - The dollar amount to convert.
 * @returns The equivalent amount in cents.
 */
const convertDollarsToCents = (dollars: unknown) => {
  const numberDollars = Number(dollars);
  if (isNaN(numberDollars)) {
    throw new Error(
      "Input must be a number or a string that can be converted to a number"
    );
  }

  return numberDollars * 100;
};

/**
 * Converts the given amount in cents to dollars.
 *
 * @param dollars - The amount in cents to be converted to dollars.
 * @returns The converted amount in dollars.
 */
const convertCentToDollar = (cents: unknown) => {
  const numberCents = Number(cents);

  if (isNaN(numberCents)) {
    throw new Error(
      "Input must be a number or a string that can be converted to a number"
    );
  }

  return (numberCents / 100).toFixed(2);
};

/**
 * Extracts the first name and last name from a full name.
 * If the full name contains more than two parts, the first part is considered as the first name,
 * and the last part is considered as the last name. If the full name contains only one part,
 * it is considered as the first name.
 * @param name - The full name to extract the first name and last name from.
 * @returns An object containing the first name and last name.
 */
const extractName = (name: string | null | undefined) => {
  let result: {
    firstName: string;
    lastName: string;
  } = {
    firstName: "",
    lastName: "",
  };

  if (!name) return result;
  const fullName = name.trim();

  const splitedName = fullName.split(" ");
  if (splitedName.length >= 2) {
    const firstName = fullName.split(" ")[0];
    const lastName = fullName.split(" ")[splitedName.length - 1];
    result.firstName = firstName;
    result.lastName = lastName;
  } else {
    result.firstName = fullName;
  }

  return result;
};

const getStateFromPlacesResult = (places: google.maps.places.PlaceResult) => {
  const addressComponents = places.address_components;
  const suburbComponent = addressComponents?.find((component) =>
    component.types.includes("administrative_area_level_1")
  );

  return suburbComponent?.long_name || null;
};

const getPostalCodeFromPlacesResult = (
  places: google.maps.places.PlaceResult
) => {
  const addressComponents = places.address_components;
  const suburbComponent = addressComponents?.find((component) =>
    component.types.includes("postal_code")
  );

  return suburbComponent?.long_name || null;
};

const getSuburbsFromPlacesResult = (places: google.maps.places.PlaceResult) => {
  const addressComponents = places.address_components;
  const suburbComponent = addressComponents?.find(
    (component) =>
      component.types.includes("sublocality") ||
      component.types.includes("locality")
  );

  return suburbComponent?.long_name || null;
};

export {
  cn,
  removeTypename,
  capitalize,
  uuid,
  formatPrice,
  isInputANumber,
  isGtinValid,
  convertDollarsToCents,
  convertCentToDollar,
  extractName,
  getStateFromPlacesResult,
  getPostalCodeFromPlacesResult,
  getSuburbsFromPlacesResult,
};
