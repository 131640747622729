import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState, useContext } from "react";
import { formatDate } from "./common/utils";
import { Autocomplete } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MedicalContraindicationHistoryResponse } from "./MedicalContradiction";
import { useLazyQuery } from "@apollo/client";
import { GET_VACCINE_DATA } from "../../graphql-queries";
import { GraphQLVaxListResponse } from "./Vaccine";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { green } from "@mui/material/colors";
import AuthContext from "../../contexts/AuthContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PatientItem } from "./patient";
import { useIdToken } from "../../hooks/useIdToken";
import { logger } from '../../utils/logger';

interface MedicalContradictionHistoryProps {
  medicalContradictionHistory: MedicalContraindicationHistoryResponse | null;
  patientIdentifier: string;
  patientItemDetails: PatientItem | undefined;
  refreshIndividualDetails: () => void;
}

interface Data {
  vaccineCode: string;
  vaccineBrandName: string; // Add this line
  typeCode: string;
  startDate: string; // Or Date, depending on how you process dates
  endDate: string | null; // Or Date | null
  reason: string;
  anaphylaxisDate: string | null; //
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: string;
}

type ReasonCode = "A" | "I" | "M" | "S" | "P";

const columns: ColumnData[] = [
  { width: "auto", label: "Vaccine Code", dataKey: "vaccineCode" },
  { width: "auto", label: "Brand Name", dataKey: "vaccineBrandName" },
  { width: "auto", label: "Type", dataKey: "typeCode" },
  { width: "auto", label: "Start Date", dataKey: "startDate" },
  { width: "auto", label: "End Date", dataKey: "endDate" },
  { width: "auto", label: "Reason", dataKey: "reason" },
  { width: "auto", label: "Anaphylaxis Date", dataKey: "anaphylaxisDate" },
];
const desiredFormat = "DD-MM-YYYY";

const MedicalContradictionHistory: React.FC<MedicalContradictionHistoryProps> =
  ({
    medicalContradictionHistory,
    patientIdentifier,
    patientItemDetails,
    refreshIndividualDetails,
  }) => {
    const {
      idToken,
      isLoading: isTokenLoading,
      error: idTokenError,
    } = useIdToken();

    const [fetchVaccineData, { data, loading, error }] =
      useLazyQuery<GraphQLVaxListResponse>(GET_VACCINE_DATA);

    useEffect(() => {
      // Call this function when you want to fetch the vaccine data.
      if (idToken) {
        fetchVaccineData();
        logger.log("Fetching Static Vaccine Codes...", data);
      }
    }, [data, fetchVaccineData, idToken]);

    const handleFetchVaccineData = () => {
      fetchVaccineData();
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selectedTrial, setSelectedTrial] = useState<Data | null>(null);
    const [value, setValue] = useState<Dayjs | null>(dayjs());
    const [reason, setReason] = React.useState("");
    const [submitStatus, setSubmitStatus] = useState<
      "idle" | "submitting" | "error" | "done"
    >("idle");
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [vaccineCode, setVaccineCode] = React.useState<string>("");
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [typeCode, setTypeCode] = React.useState<string>("");
    const [anaphylaxisDate, setAnaphylaxisDate] = useState<Dayjs | null>(
      dayjs()
    );
    const [showForm, setShowForm] = useState(false);
    const patientId = patientIdentifier;
    const {
      providerNumber,
      providerStore,
      hpiiNumber,
      hpioNumber,
      minorId,
      deviceName,
      prodaOrgId,
    } = useContext(AuthContext);

    const vaccineOptions =
      data?.listVaxDataAIRS.items.map((item) => ({
        label: `${item.VaccineBrandName} (${item.VaccineCode})`,
        code: item.VaccineCode,
      })) || [];

    const handleTypeCodeChange = (event: SelectChangeEvent<string>) => {
      const newTypeCode = event.target.value;
      setTypeCode(newTypeCode); // Update the typeCode state

      logger.log("newTypeCode:", newTypeCode);
      logger.log("reasonTypeMapping:", reasonTypeMapping);

      const filteredReasons = Object.entries(reasonTypeMapping)
        .filter(([code, type]): boolean => {
          const match =
            type === (newTypeCode === "T" ? "Temporary" : "Permanent");
          // logger.log(`Filtering ${code}: ${type} with ${newTypeCode}, match: ${match}`); // Debugging
          return match;
        })
        .reduce<{ [key: string]: string }>((acc, [code]) => {
          if (reasonOptions[code]) {
            acc[code] = reasonOptions[code];
          }
          return acc;
        }, {});

      logger.log("Filtered Reasons:", filteredReasons); // Should show filtered reasons
      setFilteredReasonOptions(filteredReasons);
    };

    const typeCodeOptions = {
      P: "Permanent",
      T: "Temporary",
    };
    const getReasonDescription = (code: ReasonCode | string) => {
      switch (code) {
        case "A":
          return "Previous anaphylaxis";
        case "I":
          return "Significant immunocompromise";
        case "M":
          return "Acute major medical illness";
        case "S":
          return "Significant immunocompromise of short duration";
        case "P":
          return "Individual is pregnant";
        default:
          return code; // Return the code itself if it doesn't match any case
      }
    };

    const reasonOptions: { [key: string]: string } = {
      A: "Previous anaphylaxis",
      I: "Significant immunocompromise",
      M: "Acute major medical illness",
      S: "Significant immunocompromise of short duration",
      P: "Individual is pregnant",
    };

    const reasonTypeMapping: { [key: string]: string } = {
      A: "Permanent",
      I: "Permanent",
      M: "Temporary",
      S: "Temporary",
      P: "Permanent",
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleCloseDialog = () => {
      setShowConfirmDialog(false);
      if (submitStatus !== "submitting") {
        // Allow closing only if not submitting
        setSubmitStatus("idle");
        setErrorMessage(""); // Reset error message
      }
    };

    const handleAddContradictionClick = () => {
      setShowForm(!showForm); // Toggle the form display
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleRowClick = (row: Data) => {
      setSelectedTrial(row);
    };
    const [filteredReasonOptions, setFilteredReasonOptions] =
      useState(reasonOptions);

    const rows: Data[] = React.useMemo(() => {
      // Ensure both sets of data are available
      if (
        medicalContradictionHistory?.data?.medContraindicationList &&
        data?.listVaxDataAIRS.items
      ) {
        return medicalContradictionHistory.data.medContraindicationList.map(
          (trial) => {
            // Find the matching vaccine data based on vaccineCode
            const vaccineData = data.listVaxDataAIRS.items.find(
              (vaccine) => vaccine.VaccineCode === trial.vaccineCode
            );
            // Extract vaccineBrandName from the matching vaccine data, if available
            const vaccineBrandName = vaccineData
              ? vaccineData.VaccineBrandName
              : "Unknown";

            return {
              vaccineCode: trial.vaccineCode,
              vaccineBrandName, // Use the found vaccineBrandName
              typeCode:
                trial.typeCode === "T"
                  ? "Temporary"
                  : trial.typeCode === "P"
                    ? "Permanent"
                    : trial.typeCode,
              startDate: formatDate(trial.startDate),
              endDate: trial.endDate ? formatDate(trial.endDate) : "Ongoing",
              reason: getReasonDescription(trial.reason as ReasonCode),
              anaphylaxisDate: trial.anaphylaxisDate
                ? formatDate(trial.anaphylaxisDate)
                : "",
            };
          }
        );
      } else {
        if (medicalContradictionHistory?.data?.statusCode === "AIR-W-1059") {
          setErrorMessage(
            medicalContradictionHistory?.data?.statusCode +
            ": " +
            medicalContradictionHistory?.data?.message ||
            "Failed to fetch data"
          );
        } else {
          setErrorMessage(
            "No active medical contraindication is recorded for this individual"
          );
        }
        return [];
      }
    }, [medicalContradictionHistory, data]); // Depend on both pieces of data

    // Add this after all your useState declarations, around line 130
    if (isTokenLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (idTokenError) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography color="error">
            Error loading authentication token: {idTokenError}
          </Typography>
        </Box>
      );
    }

    const finallyCloseDialog = () => {
      if (submitStatus === "done") {
        setShowForm(false);
        refreshIndividualDetails();
      }
      setShowConfirmDialog(false);
    };
    const handleConfirmChange = async () => {
      logger.log("Before API call, submitStatus:", submitStatus);
    };
    const handleSubmit = async () => {
      if (!vaccineCode || !startDate || !reason) {
        alert("Please fill in all required fields."); // Basic validation
        return;
      }
      setSubmitStatus("submitting");

      setShowConfirmDialog(true);

      // Call the API with form data and check the response code
      let data = await makeApiCall(
        vaccineCode,
        typeCode,
        startDate,
        endDate,
        patientId,
        reason,
        anaphylaxisDate,
        providerNumber,
        setErrorMessage // Add this parameter
      ); // Using `value` for both dates as placeholder
      if (data) {
        setSubmitStatus("done");
      } else {
        setSubmitStatus("error");
      }
    };

    const handleReasonChange = (event: SelectChangeEvent<string>) => {
      setReason(event.target.value);
    };

    const makeApiCall = async (
      vaccineCode: string,
      typeCode: string,
      startDate: dayjs.Dayjs,
      endDate: dayjs.Dayjs | null,
      patientId: string,
      reason: string,
      anaphylaxisDate: dayjs.Dayjs | null,
      providerNumber: string,
      setErrorMessage: React.Dispatch<React.SetStateAction<string>>
    ) => {
      if (!idToken) {
        console.error("No authentication token available");
        setErrorMessage("Authentication error");
        return false;
      }
      const formattedStartDate = startDate.format("DDMMYYYY");
      const formattedEndDate = endDate ? endDate.format("DDMMYYYY") : null;
      const formattedAnaphylaxisDate = anaphylaxisDate
        ? anaphylaxisDate.format("DDMMYYYY")
        : null;

      const requestData = {
        individualIdentifier: patientId,
        informationProvider: {
          providerNumber: providerNumber,
          hpiiNumber: hpiiNumber,
          hpioNumber: hpioNumber,
        },
        contraindication: {
          vaccineCode: vaccineCode,
          typeCode: typeCode,
          endDate: formattedEndDate,
          reason: reason,
          anaphylaxisDate: formattedAnaphylaxisDate,
        },
        dateOfBirth: patientItemDetails?.dateOfBirth,
        minorId: minorId,
        deviceName: deviceName,
        prodaOrgId: prodaOrgId,
      };

      logger.log(requestData);
      const apiUrl = process.env.REACT_APP_AIR_MEDICALEXCEMPTION_ADD;
      if (!apiUrl) {
        throw new Error("REACT_APP_AIR_MEDICALEXCEMPTION_ADD is not defined");
      }

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(requestData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (
          data.success &&
          (!data.data.errors || data.data.errors.length === 0)
        ) {
          logger.log("Success");
          return true;
        } else if (
          data.success &&
          data.data.errors &&
          data.data.errors.length > 0
        ) {
          // If 'data.data.errors' exists and has elements, it means there are errors
          logger.log(data.data.errors[0].message);
          setErrorMessage(
            data.data.errors[0].code + " : " + data.data.errors[0].message
          );
          return false;
        } else {
          // Handle other cases, such as when 'data.success' is not true
          logger.log("An unexpected error occurred");
          setErrorMessage("An unexpected error occurred");
          return false;
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    return (
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} md={showForm ? 8 : 12}>
            <Box sx={{ mb: { xs: 2, md: 3 } }}>
              {/* Responsive margin-bottom */}
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={handleAddContradictionClick}
                sx={{ position: "relative", fontWeight: "bold" }}
              >
                Add Contraindication
              </Button>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.dataKey}
                          align={column.numeric ? "right" : "left"}
                          style={{ width: `${column.width}px` }} // Convert the number to a pixel value
                          sx={{ backgroundColor: "#1976d2", color: "white" }} // Custom color for header cells
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length > 0 ? (
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column) => {
                              const value = row[column.dataKey];
                              return (
                                <TableCell
                                  key={column.dataKey}
                                  align={column.numeric ? "right" : "left"}
                                >
                                  {value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns.length} align="center">
                          {/* No active medical contraindication is recorded for this individual */}
                          {errorMessage}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          {showForm && (
            <Grid item xs={12} md={4}>
              {/* Form fields go here */}
              <Paper>
                <Box p={2}>
                  <Typography variant="h6">Add Contraindication</Typography>
                  {/* Form fields */}
                  {/* <TextField label="Vaccine Code" fullWidth margin="normal" /> */}
                  <FormControl fullWidth margin="normal">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Autocomplete
                        options={vaccineOptions}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.code === value.code
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Vaccine Code" />
                        )}
                        onChange={(event, newValue) => {
                          setVaccineCode(newValue?.code || "");
                        }}
                        fullWidth
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="type-code-select-label">Type</InputLabel>
                    <Select
                      labelId="type-code-select-label"
                      id="type-code-select"
                      value={typeCode}
                      label="Type"
                      onChange={handleTypeCodeChange}
                    >
                      {Object.entries(typeCodeOptions).map(
                        ([code, description]) => (
                          <MenuItem key={code} value={code}>
                            {description}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>{" "}
                  {typeCode === "T" && (
                    <FormControl fullWidth margin="normal">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="End Date"
                          format="DD/MM/YYYY"
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                  {typeCode === "P" && (
                    <FormControl fullWidth margin="normal">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Anaphylaxis Date"
                          format="DD/MM/YYYY"
                          value={anaphylaxisDate}
                          onChange={(newValue) => setAnaphylaxisDate(newValue)}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="reason-select-label">Reason</InputLabel>
                    <Select
                      labelId="reason-select-label"
                      id="reason-select"
                      value={reason}
                      label="Reason"
                      onChange={handleReasonChange}
                    >
                      {Object.entries(filteredReasonOptions).map(
                        ([code, description]) => (
                          <MenuItem key={code} value={code}>
                            {description}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>{" "}
                  <FormControl fullWidth margin="normal">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </FormControl>
                </Box>
                <Dialog open={showConfirmDialog} onClose={handleCloseDialog}>
                  <DialogTitle>
                    {submitStatus === "submitting" && "Submitting"}
                    {submitStatus === "done" && "Success"}
                    {submitStatus === "idle" && "Confirm Change"}
                    {submitStatus === "error" && "Error"}
                  </DialogTitle>
                  <DialogContent sx={{ minWidth: "600px", minHeight: "70px" }}>
                    <DialogContentText>
                      {submitStatus === "submitting" &&
                        "Submitting with AIR..."}
                      {submitStatus === "done" &&
                        "Successfully submitted with AIR!"}
                      {submitStatus === "idle" &&
                        `Are you sure you want to change `}
                      {submitStatus === "error" && errorMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {submitStatus === "idle" && (
                      <>
                        <Button
                          onClick={handleCloseDialog}
                          color="error"
                          startIcon={<CancelOutlinedIcon />}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleConfirmChange}
                          color="primary"
                          startIcon={<CheckOutlinedIcon />}
                        >
                          Confirm
                        </Button>
                      </>
                    )}
                    {submitStatus === "submitting" && (
                      <CircularProgress size={24} />
                    )}
                    {submitStatus === "done" && (
                      <Button
                        onClick={finallyCloseDialog}
                        style={{ backgroundColor: green[500], color: "#fff" }}
                        startIcon={<CheckOutlinedIcon />}
                      >
                        Close
                      </Button>
                    )}
                    {submitStatus === "error" && (
                      <Button
                        onClick={finallyCloseDialog}
                        color="error"
                        variant="contained"
                        startIcon={<CancelOutlinedIcon />}
                      >
                        Close
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

export default MedicalContradictionHistory;
