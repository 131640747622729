// apicore
import { ConfirmResetPasswordInput } from 'aws-amplify/auth';
import { APICore } from '../../helpers/api/apiCore';
import { ELoginStep, ILoginSteps } from './actions';
import { logger } from '../../utils/logger';
// constants
import { AuthActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(), // Assuming this method correctly retrieves the current user's info or null
  loading: false,
  loginError: null,
  signUpError: null,
  forgotPasswordError: null,
  userLoggedIn: false,
  completedSteps: [],
  registrationStep: 0,
  isAuthenticated: false,
  loginStep: {
    step: api.getLoginStep() || ELoginStep.INITIATE,
  },
  resetPasswordSuccess: api.getForgetPassword(),
  otp: api.getOtp(),
  confirmResetPassword: null,
  confirmResetPasswordError: null,
  // Added to explicitly track if the user is logged in
  // Consider adding additional state properties for signup, logout, and forgot password success states if needed
};

interface UserData {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  name: string;
  role: string;
  token: string;
}

type AuthActionType =
  | {
      type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.SIGNUP_USER
        | AuthActionTypes.VERIFY_USER
        | AuthActionTypes.LOGIN_USER_WITH_EMAIL
        | AuthActionTypes.UPDATE_USER_ATTRIBUTES
        | AuthActionTypes.SIGNUP_USER_REQUEST
        | AuthActionTypes.FORGOT_PASSWORD
        | AuthActionTypes.FORGOT_PASSWORD_CHANGE
        | AuthActionTypes.SET_REGISTRATION_STEP
        | AuthActionTypes.COMPLETE_STEP
        | AuthActionTypes.SET_AUTHENTICATION_STATUS
        | AuthActionTypes.CONFIRM_LOGIN_USING_MFA;
      payload: {
        actionType?: string;
        data?: UserData | {};
        error?: string;
      };
    }
  | {
      type: AuthActionTypes.LOGIN_STEPS;
      payload: {
        actionType?: string;
        step: ELoginStep;
      };
    }
  | {
      type: AuthActionTypes.SET_OTP;
      payload: {
        actionType?: string;
        otp: string | null;
      };
    }
  | {
      type: AuthActionTypes.CONFIRM_RESET_PASSWORD;
      payload: {
        actionType?: string;
        data: ConfirmResetPasswordInput | null;
        error: Error | null;
      };
    };

interface State {
  user?: UserData | {};
  loading?: boolean;
  value?: boolean;
  loginError?: string | null;
  signUpError?: string | null;
  forgotPasswordError?: string | null;
  userLoggedIn?: boolean;
  completedSteps?: number[]; // Add this line
  registrationStep?: number; // Ensure this line is present
  isAuthenticated?: boolean;
  loginStep: ILoginSteps;
  resetPasswordSuccess: any;
  otp: string | null;
  confirmResetPassword: ConfirmResetPasswordInput | null;
  confirmResetPasswordError: Error | null;
}

const Auth = (state: State = INIT_STATE, action: AuthActionType): any => {
  // logger.log("action", action);
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          logger.log(action, 'action login');

          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case AuthActionTypes.LOGIN_WITH_MFA: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.CONFIRM_LOGIN_USING_MFA: {
          logger.log(action, 'action');

          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          logger.log('userData', action.payload.data);
          return {
            ...state,
            user: action.payload.data,
            loading: false,
            userSignUp: true,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
            loginStep: {
              step: ELoginStep.FILL_FORM,
            },
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          api.setForgetPassword(action.payload.data);
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: true,
          };
        }
        case AuthActionTypes.VERIFY_USER: {
          return {
            ...state,
            user: action.payload.data,
            userVerified: true,
            loading: false,
          };
        }
        case AuthActionTypes.UPDATE_USER_ATTRIBUTES: {
          logger.log('Update User Attributes', state);
          return {
            ...state,
            user: action.payload.data,
            userAttributeUpdate: true,
            loading: false,
          };
        }
        case AuthActionTypes.CONFIRM_RESET_PASSWORD: {
          logger.log('Success response redux CONFIRM_RESET_PASSWORD', action);
          return {
            ...state,
            confirmResetPassword: action.payload.data,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    case AuthActionTypes.API_RESPONSE_ERROR:
      // logger.log("error", action.payload.error);
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.CONFIRM_LOGIN_USING_MFA: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          api.setForgetPassword(null);
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        case AuthActionTypes.UPDATE_USER_ATTRIBUTES: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            userAttributeUpdate: false,
          };
        }
        case AuthActionTypes.CONFIRM_RESET_PASSWORD: {
          logger.log('Error response redux CONFIRM_RESET_PASSWORD', action);
          return {
            ...state,
            confirmResetPasswordError: action.payload.error,
            loading: false,
          };
        }
        case AuthActionTypes.VERIFY_USER: {
          return {
            ...state,
            error: action.payload.error,
            userVerified: false,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    case AuthActionTypes.LOGIN_USER:
      logger.log('Login User Call', state);
      return {
        ...state,
        loading: true,
        userLoggedIn: false,
      };
    case AuthActionTypes.CONFIRM_LOGIN_USING_MFA:
      logger.log('Confirm Login User Call', state);
      return {
        ...state,
        loading: true,
        userLoggedIn: false,
      };
    case AuthActionTypes.FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.UPDATE_USER_ATTRIBUTES:
      return {
        ...state,
        loading: true,
        userAttributeUpdate: false,
      };
    case AuthActionTypes.SIGNUP_USER:
      return {
        ...state,
        loading: true,
        userSignUp: false,
      };
    case AuthActionTypes.VERIFY_USER:
      return {
        ...state,
        loading: true,
        userVerified: false,
      };
    case AuthActionTypes.LOGOUT_USER:
      return {
        ...state,
        loading: false,
        userLogout: false,
        loginStep: {
          step: ELoginStep.FILL_FORM,
        },
      };
    case AuthActionTypes.RESET:
      api.setForgetPassword(null);
      api.setOtp(null);
      api.setLoggedInUser(null);
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
        loginStep: {
          step: ELoginStep.FILL_FORM,
        },
        otp: null,
        confirmResetPassword: null,
        confirmResetPasswordError: null,
      };
    // case AuthActionTypes.VERIFY_USER: {
    //   logger.log("First time verify Call", state);
    //   return {
    //     ...state,
    //     user: action.payload.data,
    //     userLoggedIn: true,
    //     loading: false,
    //   };
    // }
    case AuthActionTypes.COMPLETE_STEP:
      // Add the step to the set of completed steps if not already included
      // const newCompletedSteps = [...(state.completedSteps || [])];
      // if (!newCompletedSteps.includes(action.payload)) {
      //   newCompletedSteps.push(action.payload);
      // }
      // return {
      //   ...state,
      //   completedSteps: newCompletedSteps,
      // };
      return {
        ...state,
        completedSteps: [...(state.completedSteps || []), action.payload],
      };
    case AuthActionTypes.SET_REGISTRATION_STEP:
      return {
        ...state,
        registrationStep: action.payload, // Ensure payload is a number representing the step
      };
    case AuthActionTypes.SET_AUTHENTICATION_STATUS:
      return {
        ...state,
        isAuthenticated: action.payload, // Ensure payload is a number representing the step
      };
    case AuthActionTypes.LOGIN_STEPS: {
      api.setLoginStep(action.payload.step);
      return {
        ...state,
        loginStep: {
          step: action.payload.step,
        },
      };
    }
    case AuthActionTypes.SET_OTP: {
      logger.log('Set otp action ', action);
      api.setOtp(action.payload.otp);
      return {
        ...state,
        otp: action.payload.otp,
      };
    }
    case AuthActionTypes.CONFIRM_RESET_PASSWORD: {
      logger.log('CONFIRM_RESET_PASSWORD action loader', action);
      return {
        ...state,
        loading: true,
      };
    }

    default:
      return { ...state };
  }
};

export default Auth;
