import React, { useState, ChangeEvent } from 'react';
import { Search, Clear } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Grid,
  Box,
  CircularProgress,
  Alert,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { logger } from 'utils/logger';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Error as ErrorIcon, Close as CloseIcon } from '@mui/icons-material';

dayjs.extend(utc);
dayjs.extend(timezone);

// Set the license key for MUI X
LicenseInfo.setLicenseKey(
  '54496d9a2f15ea1becbc1387a0b46140Tz04NTIwMSxFPTE3NDA2OTUwMDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

interface FormData {
  ihiNumber: string;
  medicareCardNumber: string;
  medicareIRN: string;
  dvaFileNumber: string;
  familyName: string;
  givenName: string;
  dateOfBirth: Date | null;
  sex: string;
}

type SearchType = 'ihi' | 'medicare' | 'dva';

const initialFormData: FormData = {
  ihiNumber: '',
  medicareCardNumber: '',
  medicareIRN: '',
  dvaFileNumber: '',
  familyName: '',
  givenName: '',
  dateOfBirth: null,
  sex: '',
};

interface IHISearchResponse {
  ihiNumber: string;
  familyName: string;
  givenName: string;
  dateOfBirth: string;
  sex: string;
  ihiStatus: string;
  ihiRecordStatus: string;
  errorMessage: string | null;
}

interface APIError {
  errorCode: string;
  errorReason: string;
}

interface ErrorDialogProps {
  apiError: APIError | null;
  onClose: () => void;
}
const SearchScreen: React.FC = () => {
  const [searchType, setSearchType] = useState<SearchType>('ihi');
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [searchResult, setSearchResult] = useState<IHISearchResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [apiError, setApiError] = useState<APIError | null>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    if (name) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: date ? date.toDate() : null,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    logger.log('Form submitted:', formData);

    setIsLoading(true);
    setSearchResult(null);
    setError(null);
    setApiError(null);

    try {
      let requestBody;
      const dateOfBirth = formData.dateOfBirth ? dayjs(formData.dateOfBirth).format('YYYY-MM-DD') : null;

      if (searchType === 'ihi') {
        requestBody = {
          searchType: 'basicSearch',
          ihiNumber: formData.ihiNumber,
          familyName: formData.familyName,
          givenName: formData.givenName,
          dateOfBirth,
          sex: formData.sex.charAt(0).toUpperCase(),
        };
      } else if (searchType === 'medicare') {
        requestBody = {
          searchType: 'basicMedicareSearch',
          medicareCardNumber: formData.medicareCardNumber,
          medicareIRN: parseInt(formData.medicareIRN) || 1,
          familyName: formData.familyName,
          givenName: formData.givenName,
          dateOfBirth,
          sex: formData.sex.charAt(0).toUpperCase(),
        };
      } else if (searchType === 'dva') {
        requestBody = {
          searchType: 'basicDvaSearch',
          dvaFileNumber: formData.dvaFileNumber,
          familyName: formData.familyName,
          givenName: formData.givenName,
          dateOfBirth,
          sex: formData.sex.charAt(0).toUpperCase(),
        };
      }

      if (requestBody) {
        logger.log('Request body:', requestBody);
        const response = await axios.post(process.env.REACT_APP_IHI_SEARCH_URL!, requestBody);
        if (response.data.errorCode) {
          setApiError(response.data);
        } else {
          setSearchResult(response.data);
        }
      }
    } catch (error) {
      logger.error(`Error fetching ${searchType.toUpperCase()} data:`, error);
      if (axios.isAxiosError(error) && error.response?.data) {
        setApiError(error.response.data);
      } else {
        setError(`Error fetching ${searchType.toUpperCase()} data. Please try again.`);
      }
      setSearchResult(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchTypeChange = (event: SelectChangeEvent<SearchType>) => {
    setSearchType(event.target.value as SearchType);
    setFormData(initialFormData);
    setSearchResult(null);
    setError(null);
  };

  const handleClearForm = () => {
    setFormData(initialFormData);
    setSearchResult(null);
    setError(null);
  };

  const renderSearchFields = () => {
    const fields: Record<SearchType, JSX.Element> = {
      ihi: (
        <>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="IHI Number (Mandatory)"
              name="ihiNumber"
              value={formData.ihiNumber}
              onChange={handleInputChange}
              required
              size="small"
              inputProps={{ 'aria-label': 'IHI Number' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Given Name (Optional)"
              name="givenName"
              value={formData.givenName}
              onChange={handleInputChange}
              size="small"
              inputProps={{ 'aria-label': 'Given Name' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Family Name (Mandatory)"
              name="familyName"
              value={formData.familyName}
              onChange={handleInputChange}
              required
              size="small"
              inputProps={{ 'aria-label': 'Family Name' }}
            />
          </Grid>
        </>
      ),
      medicare: (
        <>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Medicare Card Number (Mandatory)"
              name="medicareCardNumber"
              value={formData.medicareCardNumber}
              onChange={handleInputChange}
              required
              size="small"
              inputProps={{ 'aria-label': 'Medicare Card Number' }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Medicare IRN (Optional)"
              name="medicareIRN"
              value={formData.medicareIRN}
              onChange={handleInputChange}
              size="small"
              inputProps={{ 'aria-label': 'Medicare IRN' }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Family Name (Mandatory)"
              name="familyName"
              value={formData.familyName}
              onChange={handleInputChange}
              required
              size="small"
              inputProps={{ 'aria-label': 'Family Name' }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Given Name (Optional)"
              name="givenName"
              value={formData.givenName}
              onChange={handleInputChange}
              size="small"
              inputProps={{ 'aria-label': 'Given Name' }}
            />
          </Grid>
        </>
      ),
      dva: (
        <>
          <Grid item xs={12} md={4}>
            <Tooltip title="Format: 1 letter followed by 7-8 digits (e.g., N1234567)">
              <TextField
                fullWidth
                label="DVA File Number (Mandatory)"
                name="dvaFileNumber"
                value={formData.dvaFileNumber}
                onChange={handleInputChange}
                required
                size="small"
                inputProps={{
                  'aria-label': 'DVA File Number',
                  pattern: '^[A-Za-z] [0-9]{6,7}[A-Za-z]$',
                }}
                helperText="1 letter followed by 7-8 digits"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Family Name (Mandatory)"
              name="familyName"
              value={formData.familyName}
              onChange={handleInputChange}
              required
              size="small"
              inputProps={{ 'aria-label': 'Family Name' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Given Name (Optional)"
              name="givenName"
              value={formData.givenName}
              onChange={handleInputChange}
              size="small"
              inputProps={{ 'aria-label': 'Given Name' }}
            />
          </Grid>
        </>
      ),
    };

    return fields[searchType];
  };

  const columns: GridColDef[] = [
    { field: 'ihiNumber', headerName: 'IHI Number', flex: 1, minWidth: 150 },
    { field: 'familyName', headerName: 'Family Name', flex: 1, minWidth: 120 },
    { field: 'givenName', headerName: 'Given Name', flex: 1, minWidth: 120 },
    { field: 'dateOfBirth', headerName: 'Date of Birth', flex: 1, minWidth: 120 },
    { field: 'sex', headerName: 'Sex', flex: 1, minWidth: 80 },
    { field: 'ihiStatus', headerName: 'IHI Status', flex: 1, minWidth: 120 },
    { field: 'ihiRecordStatus', headerName: 'IHI Record Status', flex: 1, minWidth: 150 },
  ];

  const renderSearchResult = () => {
    if (!searchResult) return null;

    const rows = [searchResult].map((result, index) => ({
      id: index,
      ...result,
    }));

    return (
      <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#e2f1f0',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#05153f',
              },
            },
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
          }}
        />
      </Box>
    );
  };

  const handleCloseErrorDialog = () => {
    setApiError(null);
  };

  return (
    <Card sx={{ maxWidth: '100%', margin: 'auto' }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Patient Search Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="search-type-label">Search Type</InputLabel>
                <Select<SearchType>
                  labelId="search-type-label"
                  value={searchType}
                  onChange={handleSearchTypeChange}
                  label="Search Type"
                  inputProps={{ 'aria-label': 'Search Type' }}
                >
                  <MenuItem value="ihi">IHI Search</MenuItem>
                  <MenuItem value="medicare">Medicare Search</MenuItem>
                  <MenuItem value="dva">DVA Search</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                {renderSearchFields()}
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <DatePicker
                label="Date of Birth (Mandatory)"
                value={formData.dateOfBirth ? dayjs(formData.dateOfBirth) : null}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true,
                    size: 'small',
                    inputProps: { 'aria-label': 'Date of Birth' },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="sex-label">Sex (Mandatory)</InputLabel>
                <Select
                  labelId="sex-label"
                  name="sex"
                  value={formData.sex}
                  onChange={handleInputChange as (event: SelectChangeEvent<string>) => void}
                  label="Sex (Mandatory)"
                  required
                  inputProps={{ 'aria-label': 'Sex' }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <Search />}
                disabled={isLoading}
              >
                {isLoading ? 'Searching...' : 'Search'}
              </Button>
            </Grid>

            <Grid item xs={12} md={3}>
              <Button variant="outlined" fullWidth startIcon={<Clear />} onClick={handleClearForm} disabled={isLoading}>
                Clear Form
              </Button>
            </Grid>
          </Grid>
        </form>
        {error && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {error}
          </Alert>
        )}
        {isLoading ? (
          <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
            <CircularProgress />
          </Grid>
        ) : searchResult ? (
          renderSearchResult()
        ) : (
          <Alert severity="info" sx={{ marginTop: 2 }}>
            No results found. Please try a different search.
          </Alert>
        )}
      </CardContent>
      <Dialog open={!!apiError} onClose={handleCloseErrorDialog} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: '#ffebee', color: '#d32f2f' }}>
          <Box display="flex" alignItems="center">
            <ErrorIcon sx={{ mr: 1 }} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Error Occurred
            </Typography>
            <IconButton aria-label="close" onClick={handleCloseErrorDialog} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" gutterBottom>
            <strong>Error Code:</strong> {apiError?.errorCode}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Error Reason:</strong> {apiError?.errorReason}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            If this error persists, please contact the support team with the error details above.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end', px: 3, py: 2 }}>
          <Button variant="contained" color="primary" onClick={handleCloseErrorDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default SearchScreen;
