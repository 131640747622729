import { VapeCustomer } from '../interfaces/VapeCustomer';

export * from './auth/actions';
export * from './layout/actions';
export * from './sale/actions';

export const setCustomerSelected = (customer: VapeCustomer | null) => ({
  type: 'SET_CUSTOMER_SELECTED',
  payload: customer,
});
