import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useIdToken } from '../hooks/useIdToken';

export interface Clinic {
  clinicID: string;
  type: string;
  clinicName: string;
  date: string; // AWSDate
  startTime: string; // AWSTime
  endTime: string; // AWSTime
  location: string;
  capacity: number;
  notes: string;
  pharmacyID: string;
  createdDate: string; // AWSDateTime
  createdAt: string; // AWSDateTime
  updatedAt: string; // AWSDateTime
}

interface ClinicResponse {
  items: Clinic[];
  nextToken: string | null;
}

const useClinicData = (pharmacyID: string) => {
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { idToken, isLoading: isTokenLoading, error: idTokenError } = useIdToken();

  const fetchClinics = useCallback(async () => {
    if (isTokenLoading) {
      return; // Still waiting for the token, so we return early
    }

    if (!idToken) {
      console.error('ID token not available');
      setError(new Error('Authentication token is missing'));
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post<ClinicResponse>(
        `${process.env.REACT_APP_CLINICS_GET_BY_PHARMACY_ID_URL}`,
        { pharmacyID },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setClinics(response.data.items);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred while fetching clinics'));
    } finally {
      setLoading(false);
    }
  }, [pharmacyID, idToken, isTokenLoading]);

  useEffect(() => {
    fetchClinics();
  }, [fetchClinics]);

  return { clinics, loading, error, fetchClinics };
};

export default useClinicData;
