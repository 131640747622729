import { useState, useEffect, useCallback, useContext } from 'react';
import { walkInAppointmentService } from '../services/walkInAppointmentService';
import { WalkInAppointment } from '../types/WalkInAppointment';
import { useIdToken } from './useIdToken';
import AuthContext from '../contexts/AuthContext';

export const useWalkInAppointments = () => {
  const [appointments, setAppointments] = useState<WalkInAppointment[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { idToken } = useIdToken();
  const { pharmacyID } = useContext(AuthContext);

  const fetchAppointments = useCallback(async () => {
    if (!idToken || !pharmacyID) {
      setError(new Error('No authentication token or pharmacy ID available'));
      return;
    }

    setLoading(true);
    try {
      const data = await walkInAppointmentService.getAll(idToken, pharmacyID);
      setAppointments(data);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred'));
    } finally {
      setLoading(false);
    }
  }, [idToken, pharmacyID]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  const addAppointment = async (appointment: Omit<WalkInAppointment, 'id'>) => {
    if (!idToken) {
      setError(new Error('No authentication token available'));
      return;
    }

    try {
      const newAppointment = await walkInAppointmentService.create(appointment, idToken);
      setAppointments([...appointments, newAppointment]);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to add appointment'));
      console.error('Error adding appointment:', err);
    }
  };

  const updateAppointment = async (id: string, appointment: Partial<WalkInAppointment>) => {
    if (!idToken) {
      setError(new Error('No authentication token available'));
      return;
    }

    try {
      const updatedAppointment = await walkInAppointmentService.update(id, appointment, idToken);
      setAppointments(appointments.map((app) => (app.id === id ? updatedAppointment : app)));
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to update appointment'));
    }
  };

  const deleteAppointment = async (id: string) => {
    if (!idToken) {
      setError(new Error('No authentication token available'));
      return;
    }

    try {
      await walkInAppointmentService.delete(id, idToken);
      setAppointments(appointments.filter((app) => app.id !== id));
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to delete appointment'));
    }
  };

  return {
    appointments,
    loading,
    error,
    addAppointment,
    updateAppointment,
    deleteAppointment,
    refreshAppointments: fetchAppointments,
  };
};
