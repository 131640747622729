import QA from "./qa-config.json";
import PROD from "./prod-config.json";
import { logger } from "../../utils/logger";
// const currentENVS = process.env;

const envName = process.env.REACT_APP_STAGE || "prod";
const productionPrefix = "production" || "prod";

function getAmplifyConfig() {
  logger.log("executing get config...");
  logger.log("env name ", envName);

  if (envName.includes(productionPrefix)) {
    logger.log("loading production env");
    return PROD;
  } else {
    logger.log("loading qa env");
    return QA;
  }
}

export { getAmplifyConfig }
