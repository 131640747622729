import { useState, useEffect, useCallback } from 'react';
import { getBloodPressureReadingsByPatientId } from '../services/bloodPressureService';
import { BloodPressureReading, BloodPressureDataResponse } from '../types/bloodPressure';
import { logger } from '../utils/logger';

export const useBloodPressureData = (patientId: string | undefined, pharmacyId: string | undefined) => {
  const [historicalData, setHistoricalData] = useState<BloodPressureReading[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const fetchData = useCallback(
    async (reset: boolean = false) => {
      if (!patientId || !pharmacyId) {
        setError('No patient ID or pharmacy ID provided');
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const requestBody = {
          body: {
            patientID: patientId,
            pharmacyID: pharmacyId,
            sortDirection: 'DESC',
            sortKey: 'measurementDate', // Add this line to sort by measurementDate
            limit: 10,
            nextToken: reset ? null : nextToken,
          },
        };

        const response: BloodPressureDataResponse = await getBloodPressureReadingsByPatientId(requestBody);

        setHistoricalData((prevData) => {
          const newData = reset ? response.items : [...prevData, ...response.items];
          // Sort the data by measurementDate in descending order
          return newData.sort((a, b) => {
            const dateA = new Date(`${a.measurementDate} ${a.measurementTime}`);
            const dateB = new Date(`${b.measurementDate} ${b.measurementTime}`);
            return dateB.getTime() - dateA.getTime();
          });
        });
        setNextToken(response.nextToken);
      } catch (error) {
        logger.error('Error fetching historical data:', error);
        setError('Error fetching historical data');
      } finally {
        setLoading(false);
      }
    },
    [patientId, pharmacyId, nextToken]
  );

  useEffect(() => {
    fetchData(true);
  }, [patientId, pharmacyId]);

  const loadMore = () => {
    if (nextToken) {
      fetchData();
    }
  };

  return { historicalData, loading, error, loadMore, hasMore: !!nextToken, refetch: () => fetchData(true) };
};
