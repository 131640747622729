import React, { useState, FormEvent, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  InputAdornment,
  CircularProgress,
  Backdrop,
  Snackbar,
} from '@mui/material';
import { Activity, Heart, AlertCircle, FileText, History, User as PersonIcon, Printer } from 'lucide-react';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { logger } from '../utils/logger';
import { useCustomer } from '../contexts/CustomerContext';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { createBloodPressureReading } from '../services/bloodPressureService';
import { BloodPressureReading, BloodPressureResponse } from '../types/bloodPressure';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import { useBloodPressureData } from '../hooks/useBloodPressureData';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface SummaryCardProps {
  title: string;
  value: string;
  icon: React.ElementType;
  trend?: number;
  bgColor: string;
  textColor: string;
  borderColor: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  value,
  icon: Icon,
  trend,
  bgColor,
  textColor,
  borderColor,
}) => (
  <Paper
    elevation={1}
    sx={{
      p: 1.5,
      height: '100%',
      bgcolor: bgColor,
      border: 1,
      borderColor: borderColor,
    }}
  >
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography variant="body2" sx={{ color: textColor, opacity: 0.8 }}>
          {title}
        </Typography>
        <Typography variant="h6" fontWeight="bold" sx={{ color: textColor }}>
          {value}
        </Typography>
      </Box>
      <Icon size={24} color={textColor} style={{ opacity: 0.8 }} />
    </Box>
    {trend !== undefined && (
      <Typography variant="caption" color={trend > 0 ? 'error.main' : 'success.main'}>
        {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}% from last
      </Typography>
    )}
  </Paper>
);

const BloodPressureManagement: React.FC = () => {
  const { state } = useCustomer();
  const customer = state.customer;
  const { pharmacyID } = useContext(AuthContext);
  const { historicalData, loading, error, loadMore, hasMore, refetch } = useBloodPressureData(
    customer?.patientID,
    pharmacyID
  );
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [measurementDate, setMeasurementDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [measurementTime, setMeasurementTime] = useState<dayjs.Dayjs | null>(dayjs());
  const [systolic, setSystolic] = useState<number | ''>('');
  const [diastolic, setDiastolic] = useState<number | ''>('');
  const [pulse, setPulse] = useState<number | ''>('');
  const [position, setPosition] = useState<string>('');
  const [arm, setArm] = useState<string>('');
  const [cuffSize, setCuffSize] = useState<string>('');
  const [activityBefore, setActivityBefore] = useState<string>('');
  const [deviceUsed, setDeviceUsed] = useState<string>('');
  const [calibrationDate, setCalibrationDate] = useState<dayjs.Dayjs | null>(null);
  const [symptoms, setSymptoms] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>('');

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    if (!customer?.patientID) {
      logger.log('No customer selected');
      navigate('/pages/patients/search-patient');
    }
    // Cleanup function to avoid memory leaks
    return () => {
      // Perform any necessary cleanup here
    };
  }, [customer, navigate]);

  useEffect(() => {
    if (historicalData.length > 0) {
      updateChartData(historicalData);
    }
    // Cleanup function to avoid memory leaks
    return () => {
      // Perform any necessary cleanup here
    };
  }, [historicalData]);

  const updateChartData = (data: BloodPressureReading[]) => {
    // Reverse the data array to display the trend correctly
    const reversedData = [...data].reverse();

    const chartData = {
      labels: reversedData.map((item) =>
        dayjs(`${item.measurementDate} ${item.measurementTime}`).format('MMM D, YYYY HH:mm')
      ),
      datasets: [
        {
          label: 'Systolic',
          data: reversedData.map((item) => item.systolic),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Diastolic',
          data: reversedData.map((item) => item.diastolic),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Pulse',
          data: reversedData.map((item) => item.pulse),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
        },
      ],
    };
    setChartData(chartData);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!customer || !customer.patientID) {
      logger.error('No customer selected or patientID is missing');
      setSnackbarMessage('No customer selected or patient ID is missing. Please select a valid customer first.');
      setSnackbarOpen(true);
      return;
    }

    const formData = {
      patientID: customer.patientID,
      pharmacyID: pharmacyID || '',
      createdDate: dayjs().format('YYYY-MM-DD'),
      measurementDate: measurementDate?.format('YYYY-MM-DD') || '',
      measurementTime: measurementTime?.format('HH:mm') || '',
      systolic: Number(systolic),
      diastolic: Number(diastolic),
      pulse: Number(pulse),
      position,
      arm,
      cuffSize,
      activityBefore,
      symptoms,
      notes,
    };

    try {
      const response = await createBloodPressureReading(formData);
      logger.log('Blood pressure reading created:', response);
      setSnackbarMessage('Blood pressure reading saved successfully');
      setSnackbarOpen(true);
      // Refetch updated historical data after successful submission
      if (customer?.patientID) {
        refetch();
      }
    } catch (error) {
      logger.error('Error submitting blood pressure reading:', error);
      setSnackbarMessage('Error saving blood pressure reading');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Blood Pressure and Pulse Trends',
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        suggestedMin: 40,
        suggestedMax: 200,
      },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
    },
  };

  const getLatestReadings = () => {
    if (historicalData.length === 0) {
      return {
        systolic: 'N/A',
        diastolic: 'N/A',
        pulse: 'N/A',
        category: 'N/A',
        color: '#808080', // Gray for N/A
      };
    }

    const latestReading = historicalData[0];
    const systolic = latestReading.systolic;
    const diastolic = latestReading.diastolic;
    const pulse = latestReading.pulse;

    let category = 'Normal';
    let color = '#4CAF50'; // Green for Normal

    if (systolic >= 180 || diastolic >= 120) {
      category = 'Hypertensive Crisis';
      color = '#D32F2F'; // Red for Hypertensive Crisis
    } else if (systolic >= 140 || diastolic >= 90) {
      category = 'Hypertension Stage 2';
      color = '#F44336'; // Light Red for Stage 2
    } else if ((systolic >= 130 && systolic < 140) || (diastolic > 80 && diastolic < 90)) {
      category = 'Hypertension Stage 1';
      color = '#FF9800'; // Orange for Stage 1
    } else if (systolic > 120 && systolic < 130 && diastolic < 80) {
      category = 'Elevated';
      color = '#FFEB3B'; // Yellow for Elevated
    }
    // Normal is the default category if none of the above conditions are met

    return {
      systolic: `${systolic} mmHg`,
      diastolic: `${diastolic} mmHg`,
      pulse: `${pulse} bpm`,
      category,
      color,
    };
  };

  const latestReadings = getLatestReadings();

  // Add these new functions for printing
  const printLatestReadings = () => {
    const printContent = `
      <html>
        <head>
          <title>Blood Pressure Report for ${customer?.firstName} ${customer?.lastName}</title>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
            .container { max-width: 800px; margin: 0 auto; padding: 20px; }
            h1 { color: #0D47A1; border-bottom: 2px solid #0D47A1; padding-bottom: 10px; }
            h2 { color: #1976D2; margin-top: 20px; }
            .patient-info { background-color: #E3F2FD; padding: 15px; border-radius: 5px; margin-bottom: 20px; }
            .readings { display: flex; justify-content: space-between; margin-bottom: 20px; }
            .reading { flex: 1; padding: 10px; border: 1px solid #90CAF9; border-radius: 5px; margin: 0 10px; text-align: center; }
            .reading h3 { margin: 0; color: #1976D2; }
            .reading p { font-size: 1.2em; font-weight: bold; margin: 10px 0; }
            .category { background-color: ${
              latestReadings.color
            }; color: white; padding: 10px; border-radius: 5px; text-align: center; }
            .note { background-color: #FFF9C4; padding: 15px; border-radius: 5px; margin-top: 20px; }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>Blood Pressure Report</h1>
            <div class="patient-info">
              <h2>Patient Information</h2>
              <p><strong>Name:</strong> ${customer?.firstName} ${customer?.lastName}</p>
              <p><strong>Date of Birth:</strong> ${customer?.dateOfBirth}</p>
              <p><strong>Patient ID:</strong> ${customer?.patientID}</p>
            </div>
            <h2>Latest Blood Pressure Readings</h2>
            <div class="readings">
              <div class="reading">
                <h3>Systolic</h3>
                <p>${latestReadings.systolic}</p>
              </div>
              <div class="reading">
                <h3>Diastolic</h3>
                <p>${latestReadings.diastolic}</p>
              </div>
              <div class="reading">
                <h3>Pulse</h3>
                <p>${latestReadings.pulse}</p>
              </div>
            </div>
            <div class="category">
              <h3>Blood Pressure Category</h3>
              <p>${latestReadings.category}</p>
            </div>
            <div class="note">
              <h2>Note for Doctor</h2>
              <p>Dear Doctor,</p>
              <p>This report contains the latest blood pressure readings for your patient, ${customer?.firstName} ${
      customer?.lastName
    }. Please review these readings. If you have any concerns or if any adjustments to the patient's treatment plan are necessary, please contact the patient or our pharmacy.</p>
              <p>For a more detailed history of readings or any additional information, please don't hesitate to reach out to us.</p>
              <p>Thank you for your care and attention.</p>
            </div>
            <p><em>Report generated on ${new Date().toLocaleString()}</em></p>
          </div>
        </body>
      </html>
    `;

    const printWindow = window.open('', '_blank');
    printWindow?.document.write(printContent);
    printWindow?.document.close();
    printWindow?.print();
  };

  const printChart = () => {
    const canvas = document.querySelector('canvas');
    if (canvas) {
      const printWindow = window.open('', '_blank');
      printWindow?.document.write(`
        <html>
          <head>
            <title>Blood Pressure Chart</title>
            <style>
              body { font-family: Arial, sans-serif; }
              h1 { color: #0D47A1; }
            </style>
          </head>
          <body>
            <h1>Blood Pressure and Pulse Trends</h1>
            <img src="${canvas.toDataURL()}" />
          </body>
        </html>
      `);
      printWindow?.document.close();
      printWindow?.print();
    }
  };

  return (
    <Box sx={{ bgcolor: 'grey.50', minHeight: '100vh', py: 3 }}>
      <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 3 }}>
        <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs>
            <Typography variant="h4" component="h1" sx={{ color: 'primary.main' }}>
              Blood Pressure Management
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 1, color: 'text.main' }}>
              <PersonIcon size={24} />
            </Box>
            <Typography variant="body1" color="text.main" sx={{ fontSize: '1.1rem' }}>
              {customer?.firstName} {customer?.lastName} | {customer?.dateOfBirth}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          {[
            {
              title: 'Latest Systolic',
              value: latestReadings.systolic,
              icon: Activity,
              bgColor: latestReadings.color,
              textColor: '#FFFFFF',
              borderColor: 'rgba(255, 255, 255, 0.3)',
            },
            {
              title: 'Latest Diastolic',
              value: latestReadings.diastolic,
              icon: Activity,
              bgColor: latestReadings.color,
              textColor: '#FFFFFF',
              borderColor: 'rgba(255, 255, 255, 0.3)',
            },
            {
              title: 'Latest Pulse',
              value: latestReadings.pulse,
              icon: Heart,
              bgColor: '#E3F2FD',
              textColor: '#0D47A1',
              borderColor: '#90CAF9',
            },
            {
              title: 'BP Category',
              value: latestReadings.category,
              icon: AlertCircle,
              bgColor: latestReadings.color,
              textColor: '#FFFFFF',
              borderColor: 'rgba(255, 255, 255, 0.3)',
            },
          ].map((card, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <SummaryCard {...card} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="outlined" startIcon={<Printer />} onClick={printLatestReadings} sx={{ mt: 2 }}>
              Print Latest Readings
            </Button>
          </Grid>
        </Grid>

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="blood pressure tabs"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tab icon={<Activity />} iconPosition="start" label="New Reading" {...a11yProps(0)} />
            <Tab icon={<History />} iconPosition="start" label="History" {...a11yProps(1)} />
          </Tabs>
          <Box sx={{ p: 2 }}>
            <TabPanel value={tabValue} index={0}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {/* Measurement Details */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mb: 1, mt: -2 }}>
                      Measurement Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        disableFuture
                        value={measurementDate}
                        onChange={(newValue) => setMeasurementDate(newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                            required: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Time"
                        value={measurementTime}
                        onChange={(newValue) => setMeasurementTime(newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                            required: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* Blood Pressure and Pulse */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>
                      Blood Pressure and Pulse
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      id="systolic-pressure"
                      label="Systolic"
                      type="number"
                      value={systolic}
                      onChange={(e) => setSystolic(e.target.value ? Number(e.target.value) : '')}
                      InputProps={{
                        inputProps: { min: 50, max: 250 },
                        endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                      }}
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      id="diastolic-pressure"
                      label="Diastolic"
                      type="number"
                      value={diastolic}
                      onChange={(e) => setDiastolic(e.target.value ? Number(e.target.value) : '')}
                      InputProps={{
                        inputProps: { min: 30, max: 150 },
                        endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                      }}
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      id="pulse-rate"
                      label="Pulse"
                      type="number"
                      value={pulse}
                      onChange={(e) => setPulse(e.target.value ? Number(e.target.value) : '')}
                      InputProps={{
                        inputProps: { min: 30, max: 200 },
                        endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                      }}
                      required
                      size="small"
                    />
                  </Grid>

                  {/* Measurement Conditions */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>
                      Measurement Conditions
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Position</FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      >
                        <FormControlLabel value="Sitting" control={<Radio size="small" />} label="Sitting" />
                        <FormControlLabel value="Standing" control={<Radio size="small" />} label="Standing" />
                        <FormControlLabel value="Lying Down" control={<Radio size="small" />} label="Lying Down" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Arm Used</FormLabel>
                      <RadioGroup row aria-label="arm" name="arm" value={arm} onChange={(e) => setArm(e.target.value)}>
                        <FormControlLabel value="Left Arm" control={<Radio size="small" />} label="Left" />
                        <FormControlLabel value="Right Arm" control={<Radio size="small" />} label="Right" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                      <FormLabel component="legend">Cuff Size</FormLabel>
                      <Select
                        id="cuff-size"
                        name="cuff-size"
                        value={cuffSize}
                        onChange={(e) => setCuffSize(e.target.value)}
                        required
                      >
                        <MenuItem value="">Select Size</MenuItem>
                        <MenuItem value="Small">Standard</MenuItem>
                        <MenuItem value="Extra Large">Extra Large</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                      <FormLabel component="legend">Activity Before</FormLabel>
                      <Select
                        id="activity-before"
                        name="activity-before"
                        value={activityBefore}
                        onChange={(e) => setActivityBefore(e.target.value)}
                      >
                        <MenuItem value="">Select Activity</MenuItem>
                        <MenuItem value="Resting">Resting</MenuItem>
                        <MenuItem value="After Exercise">After Exercise</MenuItem>
                        <MenuItem value="After Eating">After Eating</MenuItem>
                        <MenuItem value="Post Medication">Post Medication</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Symptoms */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>
                      Symptoms
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        {['Headache', 'Dizziness', 'Shortness of Breath', 'Chest Pain', 'No presenting symptoms'].map(
                          (symptom) => (
                            <FormControlLabel
                              key={symptom}
                              control={
                                <Checkbox
                                  size="small"
                                  checked={symptoms.includes(symptom)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSymptoms([...symptoms, symptom]);
                                    } else {
                                      setSymptoms(symptoms.filter((s) => s !== symptom));
                                    }
                                  }}
                                />
                              }
                              label={symptom}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  {/* Notes */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>
                      Additional Notes
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="pharmacist-notes"
                      label="Notes"
                      multiline
                      rows={2}
                      placeholder="Enter observations or comments..."
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      size="small"
                    />
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="small"
                      disabled={loading}
                      sx={{
                        bgcolor: 'green.600',
                        '&:hover': { bgcolor: 'green.700' },
                        mt: 2,
                      }}
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Save Reading'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                  Blood Pressure Trends
                </Typography>
                <Paper
                  sx={{
                    p: 3,
                    bgcolor: 'background.paper',
                    boxShadow: 2,
                  }}
                >
                  {chartData && <Line options={chartOptions} data={chartData} />}
                </Paper>
                <Button variant="outlined" startIcon={<Printer />} onClick={printChart} sx={{ mt: 2 }}>
                  Print Chart
                </Button>
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                  Recent Readings
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : error ? (
                  <Typography color="error">{error}</Typography>
                ) : historicalData.length > 0 ? (
                  <TableContainer component={Paper} sx={{ maxHeight: 300, boxShadow: 2 }}>
                    <Table stickyHeader size="small" aria-label="blood pressure readings table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', bgcolor: 'background.paper' }}>
                            Measurement Date
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold', bgcolor: 'background.paper' }}>
                            Measurement Time
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold', bgcolor: 'background.paper' }}>Systolic</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', bgcolor: 'background.paper' }}>Diastolic</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', bgcolor: 'background.paper' }}>Pulse</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historicalData.map((item, index) => (
                          <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'grey.50' } }}>
                            <TableCell>{item.measurementDate}</TableCell>
                            <TableCell>{item.measurementTime}</TableCell>
                            <TableCell>{item.systolic} mmHg</TableCell>
                            <TableCell>{item.diastolic} mmHg</TableCell>
                            <TableCell>{item.pulse} bpm</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography>No data available</Typography>
                )}
              </Box>
              {hasMore && (
                <Button onClick={loadMore} disabled={loading}>
                  Load More
                </Button>
              )}
            </TabPanel>
          </Box>
        </Paper>
      </Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} message={snackbarMessage} />
    </Box>
  );
};

export default BloodPressureManagement;
