import { gql } from '@apollo/client';

// SERVICE
export const MUTATION_CREATE_SERVICE = gql`
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      createdDate
      durationInSeconds
      isActive
      pharmacyID
      pharmacyName
      serviceId
      serviceName
      serviceScheduleID
      suburb
      updatedDate
      rooms {
        roomID
        roomName
        pharmacyID
        description
        isAvailable
        createdDate
      }
      categoryID
    }
  }
`;

export const MUTATION_UPDATE_SERVICE = gql`
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      createdDate
      description
      durationInSeconds
      isActive
      pharmacyID
      pharmacyName
      serviceId
      serviceName
      serviceScheduleID
      suburb
      updatedDate
      rooms {
        roomID
        roomName
        pharmacyID
        description
        isAvailable
        createdDate
      }
      categoryID
    }
  }
`;

// SERVICE SCHEDULE
export const MUTATION_CREATE_SERVICE_SCHEDULE = gql`
  mutation CreateServiceSchedule($input: CreateServiceScheduleInput!) {
    createServiceSchedule(input: $input) {
      createdDate
      isActive
      pharmacyID
      serviceScheduleID
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

export const MUTATION_UPDATE_SERVICE_SCHEDULE = gql`
  mutation UpdateServiceSchedule($input: UpdateServiceScheduleInput!) {
    updateServiceSchedule(input: $input) {
      createdDate
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      isActive
      pharmacyID
      serviceId
      serviceScheduleID
      slot
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

// HOURS

export const MUTATION_CREATE_HOUR = gql`
  mutation CreateHour($input: CreateHoursInput!) {
    createHours(input: $input) {
      ID
      closingHour
      isClosed
      openingHour
      pharmacyID
    }
  }
`;

// PHARMACY SCHEDULE

export const MUTATION_CREATE_PHARMACY_SCHEDULE = gql`
  mutation CreatePharmacyScheduleMutation($input: CreatePharmacyScheduleInput!) {
    createPharmacySchedule(input: $input) {
      createdDate
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      pharmacyID
      isActive
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      id
    }
  }
`;

// AVAILABILITY

export const MUTATION_CREATE_AVAILABILITY = gql`
  mutation CreatePharmacyAvailability($input: CreatePharmacyAvailabilityInput!) {
    createPharmacyAvailability(input: $input) {
      availabilityID
      durationInSeconds
      expire_at
      isAvailable
      maxSlotsAvailable
      maxSlotsInStore
      pharmacyID
      rooms {
        roomID
        roomName
        isAvailable
      }
      serviceId
      serviceName
      timeslot
    }
  }
`;

export const MUTATION_UPDATE_AVAILABILITY = gql`
  mutation UpdatePharmacyAvailability($input: UpdatePharmacyAvailabilityInput!) {
    updatePharmacyAvailability(input: $input) {
      availabilityID
      durationInSeconds
      expire_at
      isAvailable
      maxSlotsAvailable
      maxSlotsInStore
      pharmacyID
      serviceId
      serviceName
      timeslot
      rooms {
        roomID
        roomName
        isAvailable
      }
    }
  }
`;

// BOOKING

export const MUTATION_CREATE_BOOKING = gql`
  mutation CreateBooking($input: CreateBookingsInput!) {
    createBookings(input: $input) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        clientSecret
        status
        token
      }
      room {
        roomID
        roomName
      }
    }
  }
`;

export const MUTATION_UPDATE_BOOKING = gql`
  mutation UpdateBooking($input: UpdateBookingsInput!) {
    updateBookings(input: $input) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        clientSecret
        status
        token
      }
      room {
        roomID
        roomName
      }
    }
  }
`;

// SERVICE CATEGORY

export const MUTATION_CREATE_SERVICE_CATEGORY = gql`
  mutation CreateServiceCategory($input: CreateServiceCategoryInput!) {
    createServiceCategory(input: $input) {
      categoryDescription
      categoryID
      categoryName
    }
  }
`;

// ROOM
export const MUTATION_CREATE_ROOM = gql`
  mutation CreateRoom($input: CreateRoomInput!) {
    createRoom(input: $input) {
      createdDate
      description
      isActive
      isAvailable
      pharmacyID
      roomID
      roomName
      snoozed
      snoozeTime
      schedule
      unavailableDates
    }
  }
`;

export const MUTATION_UPDATE_ROOM = gql`
  mutation UpdateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      createdDate
      description
      isActive
      isAvailable
      pharmacyID
      roomID
      roomName
      snoozed
      snoozeTime
      schedule
      unavailableDates
    }
  }
`;

export const MUTATION_DELETE_ROOM = gql`
  mutation DeleteRoom($input: DeleteRoomInput!) {
    deleteRoom(input: $input) {
      createdDate
      description
      isActive
      isAvailable
      pharmacyID
      roomID
      roomName
      snoozed
      snoozeTime
      schedule
      unavailableDates
    }
  }
`;

// PATIENT APPOINTMENT

export const MUTATION_CREATE_PATIENT_APPOINTMENT = gql`
  mutation CreatePatientAppointment($input: CreatePatientAppointmentsInput!) {
    createPatientAppointments(input: $input) {
      addressLineOne
      addressLineTwo
      createdDate
      dateOfBirth
      emailAddress
      firstName
      gender
      id
      initial
      isAboriginal
      ihiNumber
      lastName
      locality
      medicareCardNumber
      medicareExpiryDate
      medicareIRN
      middleName
      mobileNumber
      onlyNameIndicator
      patientID
      pharmacistID
      pharmacyID
      phoneNumber
      postCode
      providerNumber
      state
    }
  }
`;
