import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
// Assuming IdentifyIndividualResponse is correctly imported
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { green } from "@mui/material/colors"; // Import green color for success state
import AuthContext from "../../contexts/AuthContext";
import { IdentifyIndividualResponse } from "./identifyIndividual";
import { PatientItem } from "./patient";
import { AIRRecordEncounterResponse } from "./vaxHistory";
import * as Sentry from "@sentry/react";
import { useIdToken } from "../../hooks/useIdToken";
import { logger } from '../../utils/logger';

interface AlertsProps {
  individualDetails: IdentifyIndividualResponse | null;
  setTabValue: (newValue: number) => void; // Add this function definition
  patientItemDetails: PatientItem | undefined;
  refreshIndividualDetails: () => void;
}

interface DetailKey {
  key: keyof IdentifyIndividualResponse["data"]["individualDetails"];
  label: string;
  description: string; // Added description for tooltip
  boolean?: boolean;
}

interface LocalDetailsType
  extends Partial<IdentifyIndividualResponse["data"]["individualDetails"]> { }

const Alerts: React.FC<AlertsProps> = ({
  individualDetails,
  setTabValue,
  patientItemDetails,
  refreshIndividualDetails,
}) => {
  const [localDetails, setLocalDetails] = useState<LocalDetailsType>({});
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [detailToUpdate, setDetailToUpdate] = useState<DetailKey | null>(null);
  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "submitting" | "done" | "error"
  >("idle");
  const {
    providerNumber,
    providerStore,
    hpioNumber,
    hpiiNumber,
    minorId,
    deviceName,
    prodaOrgId,
  } = useContext(AuthContext);
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const {
    idToken,
    isLoading: isTokenLoading,
    error: idTokenError,
  } = useIdToken();

  useEffect(() => {
    if (apiError) {
      logger.log(`API Error: ${apiError}`);
    }
  }, [apiError]);

  useEffect(() => {
    logger.log("Individual details received:", individualDetails);
    if (idToken && individualDetails) {
      setLocalDetails(individualDetails?.data.individualDetails || {});
    }
  }, [idToken, individualDetails]);

  const detailKeys: DetailKey[] = [
    {
      key: "catchupDate",
      label: "Catch-Up Date",
      description:
        "Use this service to record a catch-up vaccination on the AIR to qualify an under-20 individual for family assistance payments, granting a temporary 'up-to-date' status for six months. Note: Only one planned catch-up for overdue immunisations is allowable per individual.",
    },
    {
      key: "indigenousStatus",
      label: "Indigenous Status",
      boolean: true,
      description: "Change Indigenous Status",
    },
    {
      key: "additionalVaccineIndicator",
      label: "Additional Vaccine Indicator",
      boolean: true,
      description: "Additional Vaccine Indicator",
    },
    {
      key: "medContraindicationIndicator",
      label: "Medical Contraindication Indicator",
      boolean: true,
      description: "Medical Contraindication Check",
    },
    {
      key: "naturalImmunityIndicator",
      label: "Natural Immunity Indicator",
      boolean: true,
      description: "Check Natural Immunity",
    },
    {
      key: "vaccineTrialIndicator",
      label: "Vaccine Trial Indicator",
      boolean: true,
      description: "Check Vaccine Trial.",
    },
    {
      key: "actionRequiredIndicator",
      label: "Action Required Indicator",
      boolean: true,
      description: "Action Required Indicator",
    },
  ];

  async function updateIndividualDetail(
    key: keyof IdentifyIndividualResponse["data"]["individualDetails"],
    value: boolean | string,
    individualIdentifier: string,
    patientItemDetails: PatientItem | undefined,
    providerNumber: string
    // refreshIndividualDetails: () => void
  ): Promise<boolean> {
    logger.log(`Updating ${key} to ${value}`);
    const status = await updateAlertsWithAir(
      key,
      value,
      individualIdentifier,
      patientItemDetails,
      providerNumber
      // refreshIndividualDetails
    );
    logger.log("status1", status);
    return status;
  }
  async function updateAlertsWithAir(
    key: string,
    value: string | boolean,
    individualIdentifier: string,
    patientItemDetails: PatientItem | undefined,
    providerNumber: string
    // refreshIndividualDetails: () => void
  ): Promise<boolean> {
    // switch case to update the alert with AIR the key values can be used to update the alert
    let endpoint = "";
    let status = false;
    switch (key) {
      case "catchupDate":
        // code block
        endpoint = "AIRPlannedCatchUpDate";

        status = await apiCallPlannedCatchupDate(
          "catchupDate",
          value,
          endpoint,
          individualIdentifier,
          patientItemDetails,
          providerNumber
          // refreshIndividualDetails
        );
        return status;
      case "indigenousStatus":
        endpoint = "IndigenousStatusUpdate";
        status = await apiCallToUpdateAirAlerts(
          "indigenousStatus",
          value,
          endpoint,
          individualIdentifier,
          patientItemDetails,
          providerNumber
          // refreshIndividualDetails
        );
        // code block
        return status;
      case "additionalVaccineIndicator":
        // code block
        if (value === true) {
          endpoint = "AddAdditionalVaccineIndicator";
        }
        if (value === false) {
          endpoint = "RemoveAdditionalVaccineIndicator";
        }
        status = await apiCallToUpdateAirAlerts(
          "acknowledgement",
          true,
          endpoint,
          individualIdentifier,
          patientItemDetails,
          providerNumber
          // refreshIndividualDetails
        );
        return status;
      case "medContraindicationIndicator":
        break;
      case "naturalImmunityIndicator":
        // Go to TAB with index 6

        break;
      case "vaccineTrialIndicator":
        endpoint = "IndigenousStatusUpdate";
        apiCallToUpdateAirAlerts(
          "indigenousStatus",
          value,
          endpoint,
          individualIdentifier,
          patientItemDetails,
          providerNumber

          // refreshIndividualDetails
        );
        break;
      case "actionRequiredIndicator":
        endpoint = "IndigenousStatusUpdate";
        apiCallToUpdateAirAlerts(
          "indigenousStatus",
          value,
          endpoint,
          individualIdentifier,
          patientItemDetails,
          providerNumber
          // refreshIndividualDetails
        );
        break;
      default:
      // code block
    }

    return false;
  }

  async function apiCallToUpdateAirAlerts(
    arg0: string,
    value: string | boolean,
    endpoint: string,
    individualIdentifier: string,
    patientItemDetails: PatientItem | undefined,
    providerNumber: string
  ): Promise<boolean> {
    if (!idToken) {
      console.error("No authentication token available");
      setApiError("Authentication error");
      return false;
    }
    const requestData = {
      individualIdentifier: individualIdentifier,
      informationProvider: {
        providerNumber: providerNumber,
        hpioNumber: hpioNumber,
        hpiiNumber: hpiiNumber,
      },
      [arg0]: value,
      dateOfBirth: patientItemDetails?.dateOfBirth,
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
    };
    let status = false;

    try {
      logger.log(requestData);
      const airAlertrsURL = process.env.REACT_APP_AIR_ALERTS;
      if (!airAlertrsURL) {
        throw new Error("REACT_APP_AIR_AlERTS is not defined");
      }

      const response = await fetch(airAlertrsURL + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      logger.log("API response:", data);
      if (data.success && !data.data.errors) {
        status = true;
        setApiSuccess(data.data.message);
      }
      const success = handleApiResponse(data, (errorMessage) => {
        logger.log("INSIDE handleApiResponse", errorMessage);
        setApiError(errorMessage); // This will update the state in your component
        if (errorMessage) {
          setSubmitStatus("error");
          status = false;
        } else {
          setSubmitStatus("idle");
          status = true;
        }
      });
      logger.log("status", status);
      return status;
    } catch (error) {
      Sentry.captureException(error); // Manually capture the error

      return false;
    }
  }

  async function apiCallPlannedCatchupDate(
    arg0: string,
    value: string | boolean,
    endpoint: string,
    individualIdentifier: string,
    patientItemDetails: PatientItem | undefined,
    providerNumber: string
    // onSuccess: () => void
  ): Promise<boolean> {
    if (!idToken) {
      console.error("No authentication token available");
      setApiError("Authentication error");
      return false;
    }

    const requestData = {
      individualIdentifier: individualIdentifier,
      informationProvider: {
        providerNumber: providerNumber,
        hpioNumber: hpioNumber,
        hpiiNumber: hpiiNumber,
      },
      individual: {
        medicareCard: {
          medicareCardNumber: patientItemDetails?.medicareCardNumber,
          medicareIRN: patientItemDetails?.medicareIRN,
        },
        personalDetails: {
          firstName: patientItemDetails?.firstName,
          lastName: patientItemDetails?.lastName,
          dateOfBirth: patientItemDetails?.dateOfBirth,
          gender: patientItemDetails?.gender,
        },
        address: {
          addressLineOne: patientItemDetails?.addressLineOne,
          addressLineTwo: patientItemDetails?.addressLineTwo,
          locality: patientItemDetails?.locality,
          postCode: patientItemDetails?.postCode,
        },
        ihiNumber: patientItemDetails?.ihiNumber,
      },
      minorId: minorId,
      deviceName: deviceName,
    };
    let status = false;

    try {
      logger.log(requestData);
      const airAlertrsURL = process.env.REACT_APP_AIR_ALERTS;
      if (!airAlertrsURL) {
        throw new Error("REACT_APP_AIR_AlERTS is not defined");
      }
      const response = await fetch(airAlertrsURL + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: any = await response.json();
      let formattedDate = "";
      logger.log("API response:", data);
      if (data.success && !data.data.errors) {
        if (data.data.catchupDate) {
          formattedDate = `${data.data.catchupDate.slice(
            0,
            2
          )}-${data.data.catchupDate.slice(2, 4)}-${data.data.catchupDate.slice(
            4
          )}`;
        }
        setApiSuccess(data.data.message + " " + formattedDate);
        status = true;
      } else if (
        data.success &&
        data.data.errors &&
        data.data.errors.length > 0
      ) {
        let invalidMessages: string[] = [];
        logger.log(data.data.message);
        data.data.errors.forEach((error: any, index: any) => {
          invalidMessages.push(error.code + ":" + error.message);
        });
        // const errorMessages = response.data.errors.map((error: { message: any }) => error.message);
        const errorMessages = invalidMessages.join(", ");
        setApiError(errorMessages); // This will update the state in your component
        setSubmitStatus("error"); //
        status = false;
      }
      return status;
    } catch (error) {
      Sentry.captureException(error); // Manually capture the error

      return false;
    }
  }

  function handleApiResponse(
    response: AIRRecordEncounterResponse,
    onError: (msg: string) => void
  ) {
    if (
      response.success &&
      response.data.errors &&
      response.data.errors.length > 0
    ) {
      let invalidMessages: string[] = [];
      logger.log(response.data.message);
      response.data.errors.forEach((error: any, index: any) => {
        const formId = index + 1; // Example of mapping, adjust as necessary
        invalidMessages.push(error.code + ":" + error.message);
      });
      // const errorMessages = response.data.errors.map((error: { message: any }) => error.message);
      const errorMessages = invalidMessages.join(", ");
      onError(errorMessages); // Call the passed callback function with error messages
      // logger.log("errorMessages", errorMessages);
      return false;
    } else {
      return true;
    }
  }

  const handleToggle = (
    detailKey: keyof IdentifyIndividualResponse["data"]["individualDetails"]
  ) => {
    const detail = detailKeys.find((dk) => dk.key === detailKey);
    if (detail) {
      setDetailToUpdate(detail);
      setShowConfirmDialog(true);
      if (detailKey === "naturalImmunityIndicator") {
        // When the natural immunity indicator is modified,
        // change to the tab with index 6.
        setTabValue(6);
      }
      if (detailKey === "medContraindicationIndicator") {
        // When the natural immunity indicator is modified,
        // change to the tab with index 6.
        setTabValue(7);
      }
      if (detailKey === "vaccineTrialIndicator") {
        // When the natural immunity indicator is modified,
        // change to the tab with index 6.
        setTabValue(5);
      }
      // if (detailKey === "actionRequiredIndicator") {
      //   // When the natural immunity indicator is modified,
      //   // change to the tab with index 6.
      //   alert("Action Required Indicator");
      // }
    }
  };

  const handleConfirmChange = async () => {
    logger.log("Before API call, submitStatus:", submitStatus);

    if (detailToUpdate) {
      setSubmitStatus("submitting"); // Set status to submitting
      const updatedValue = !localDetails[detailToUpdate.key];
      const individualIdentifier =
        individualDetails?.data?.individualDetails?.individualIdentifier;
      if (individualIdentifier) {
        const success = await updateIndividualDetail(
          detailToUpdate.key,
          updatedValue,
          individualIdentifier,
          patientItemDetails,
          providerNumber
        );
        logger.log("success", success);
        if (success) {
          logger.log("API call successful");
          setSubmitStatus("done"); // Set status to done if API call is successful
          setApiError(""); // Reset the apiError state on successful API call

          setLocalDetails((prevDetails) => ({
            ...prevDetails,
            [detailToUpdate.key]: updatedValue,
          }));
        } else {
          // Handle API call failure if necessary
          setSubmitStatus("error"); // Reset status on failure
        }
      }
    }
  };

  const handleCloseDialog = () => {
    setShowConfirmDialog(false);
    setDetailToUpdate(null);
    setApiError(""); // Reset the apiError state

    if (submitStatus !== "submitting") {
      // Allow closing only if not submitting
      setSubmitStatus("idle");
    }
  };

  const finallyCloseDialog = () => {
    setTimeout(() => {
      setShowConfirmDialog(false);
      setDetailToUpdate(null);
    }, 4000); // Delay closing the dialog by 1000ms (1 second)
  };

  if (isTokenLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (idTokenError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">
          Error loading authentication token: {idTokenError}
        </Typography>
      </Box>
    );
  }
  return (
    // <Box sx={{ flexGrow: 1 }}>
    //   <Box sx={{ width: "100%" }}>
    //     {detailKeys.map((detail) => (
    //       <Tooltip key={detail.key} title={detail.description} placement="top">
    //         <Card key={String(detail.key)} sx={{ mb: 2, minWidth: 275 }}>
    //           <CardContent>
    //             <ListItem>
    //               <ListItemText primary={detail.label} />
    //               {detail.key === "catchupDate" ? (
    //                 localDetails[detail.key] ? (
    //                   typeof localDetails[detail.key] === "string" ? (
    //                     <Typography>
    //                       {localDetails[detail.key]?.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3")}
    //                     </Typography>
    //                   ) : (
    //                     <Typography>{localDetails[detail.key]}</Typography>
    //                   )
    //                 ) : (
    //                   <Switch
    //                     checked={Boolean(localDetails[detail.key])}
    //                     onChange={() => handleToggle(detail.key)}
    //                     name={String(detail.key)}
    //                     inputProps={{ "aria-label": "toggle catchupDate" }}
    //                   />
    //                 )
    //               ) : detail.boolean ? (
    //                 <Switch
    //                   checked={Boolean(localDetails[detail.key])}
    //                   onChange={() => handleToggle(detail.key)}
    //                   name={String(detail.key)}
    //                   inputProps={{ "aria-label": detail.label }}
    //                 />
    //               ) : (
    //                 <Typography>{localDetails[detail.key]}</Typography>
    //               )}
    //             </ListItem>
    //           </CardContent>
    //         </Card>
    //       </Tooltip>
    //     ))}
    //   </Box>
    //   <Dialog open={showConfirmDialog} onClose={handleCloseDialog}>
    //     <DialogTitle>
    //       {submitStatus === "submitting" && "Submitting"}
    //       {submitStatus === "done" && "Success"}
    //       {submitStatus === "idle" && "Confirm Change"}
    //       {submitStatus === "error" && "Error"}
    //     </DialogTitle>
    //     <DialogContent sx={{ minWidth: "500px", minHeight: "50px" }}>
    //       <DialogContentText>
    //         {(() => {
    //           if (submitStatus === "submitting") {
    //             return "Submitting with AIR...";
    //           } else if (submitStatus === "done") {
    //             return <Typography color="green">{apiSuccess}</Typography>;
    //           } else if (submitStatus === "error") {
    //             return <Typography color="error">{apiError}</Typography>;
    //           } else if (submitStatus === "idle" && detailToUpdate?.key === "catchupDate") {
    //             return (
    //               <React.Fragment>
    //                 Select <b>CONFIRM</b>, if you:
    //                 <ul>
    //                   <li>were unable to administer all overdue vaccines today;</li>
    //                   <li>are waiting on results to support testing of natural immunity;</li>
    //                   <li>need to order in additional required vaccines.</li>
    //                 </ul>
    //                 Select <b>CANCEL</b>, if:
    //                 <ul>
    //                   <li>you have vaccinated the individual and they are no longer overdue for any vaccines, or</li>
    //                   <li>you feel the parent/guardian does not intend to vaccinate the individual.</li>
    //                 </ul>
    //               </React.Fragment>
    //             );
    //           } else {
    //             return `Are you sure you want to change the ${detailToUpdate?.label}?`;
    //           }
    //         })()}
    //       </DialogContentText>
    //     </DialogContent>
    //     <DialogActions>
    //       {(submitStatus === "idle" || submitStatus === "error") && (
    //         <Button variant="contained" color="error" onClick={handleCloseDialog} startIcon={<CancelOutlinedIcon />}>
    //           Cancel
    //         </Button>
    //       )}
    //       {submitStatus === "submitting" && <CircularProgress size={24} />}
    //       {submitStatus === "idle" && !apiError && (
    //         <Button variant="contained" color="primary" onClick={handleConfirmChange} startIcon={<CheckOutlinedIcon />}>
    //           Confirm
    //         </Button>
    //       )}
    //       {submitStatus === "done" && (
    //         <Button
    //           variant="contained"
    //           style={{ backgroundColor: green[500], color: "#fff" }}
    //           startIcon={<CheckOutlinedIcon />}
    //           onClick={() => {
    //             finallyCloseDialog(); // Close the dialog
    //             refreshIndividualDetails(); // Refresh the details after dialog is closed/user acknowledges success
    //           }}
    //         >
    //           Done
    //         </Button>
    //       )}
    //     </DialogActions>
    //   </Dialog>
    // </Box>
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ width: "100%" }}>
        {detailKeys.map((detail) => (
          <Tooltip key={detail.key} title={detail.description} placement="top">
            <Card sx={{ mb: 2, minWidth: 275 }}>
              <CardContent>
                <ListItem>
                  <ListItemText primary={detail.label} />
                  {detail.key === "catchupDate" ? (
                    localDetails[detail.key] ? (
                      typeof localDetails[detail.key] === "string" ? (
                        <Typography>
                          {localDetails[detail.key]?.replace(
                            /(\d{2})(\d{2})(\d{4})/,
                            "$1/$2/$3"
                          )}
                        </Typography>
                      ) : (
                        <Typography>{localDetails[detail.key]}</Typography>
                      )
                    ) : (
                      <Switch
                        checked={Boolean(localDetails[detail.key])}
                        onChange={() => handleToggle(detail.key)}
                        name={String(detail.key)}
                        inputProps={{ "aria-label": "toggle catchupDate" }}
                      />
                    )
                  ) : detail.boolean ? (
                    <Switch
                      checked={Boolean(localDetails[detail.key])}
                      onChange={() => handleToggle(detail.key)}
                      name={String(detail.key)}
                      inputProps={{ "aria-label": detail.label }}
                      disabled={detail.key === "actionRequiredIndicator"} // Disable the switch if the key is "actionRequiredIndicator"
                    />
                  ) : (
                    <Typography>{localDetails[detail.key]}</Typography>
                  )}
                </ListItem>
              </CardContent>
            </Card>
          </Tooltip>
        ))}
      </Box>
      <Dialog open={showConfirmDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {submitStatus === "submitting" && "Submitting"}
          {submitStatus === "done" && "Success"}
          {submitStatus === "idle" && "Confirm Change"}
          {submitStatus === "error" && "Error"}
        </DialogTitle>
        <DialogContent sx={{ minWidth: "500px", minHeight: "50px" }}>
          <DialogContentText>
            {(() => {
              if (submitStatus === "submitting") {
                return "Submitting with AIR...";
              } else if (submitStatus === "done") {
                return <Typography color="green">{apiSuccess}</Typography>;
              } else if (submitStatus === "error") {
                return <Typography color="error">{apiError}</Typography>;
              } else if (
                submitStatus === "idle" &&
                detailToUpdate?.key === "catchupDate"
              ) {
                return (
                  <React.Fragment>
                    Select <b>CONFIRM</b>, if you:
                    <ul>
                      <li>
                        were unable to administer all overdue vaccines today;
                      </li>
                      <li>
                        are waiting on results to support testing of natural
                        immunity;
                      </li>
                      <li>need to order in additional required vaccines.</li>
                    </ul>
                    Select <b>CANCEL</b>, if:
                    <ul>
                      <li>
                        you have vaccinated the individual and they are no
                        longer overdue for any vaccines, or
                      </li>
                      <li>
                        you feel the parent/guardian does not intend to
                        vaccinate the individual.
                      </li>
                    </ul>
                  </React.Fragment>
                );
              } else if (
                submitStatus === "idle" &&
                detailToUpdate?.key === "additionalVaccineIndicator"
              ) {
                return (
                  <React.Fragment>
                    <ul>
                      Use this indicator for individuals who may require
                      additional vaccines. Consult the Australian Immunisation
                      Handbook for advice and recommendations when vaccinating
                      special risk groups.
                    </ul>
                    <ul>
                      By adding or removing this indicator, you acknowledge the
                      individual has given consent to update their record.
                    </ul>
                  </React.Fragment>
                );
              } else {
                return `Are you sure you want to change the ${detailToUpdate?.label}?`;
              }
            })()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(submitStatus === "idle" || submitStatus === "error") && (
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseDialog}
              startIcon={<CancelOutlinedIcon />}
            >
              Cancel
            </Button>
          )}
          {submitStatus === "submitting" && <CircularProgress size={24} />}
          {submitStatus === "idle" && !apiError && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmChange}
              startIcon={<CheckOutlinedIcon />}
            >
              Confirm
            </Button>
          )}
          {submitStatus === "done" && (
            <Button
              variant="contained"
              style={{ backgroundColor: green[500], color: "#fff" }}
              startIcon={<CheckOutlinedIcon />}
              onClick={() => {
                finallyCloseDialog(); // Close the dialog
                refreshIndividualDetails(); // Refresh the details after dialog is closed/user acknowledges success
              }}
            >
              Done
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Alerts;
