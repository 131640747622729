import { gql } from '@apollo/client';

// SERVICES

export const LIST_SERVICE = gql`
  query ListService($limit: Int, $nextToken: String, $filter: TableServiceFilterInput) {
    listServices(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        createdDate
        durationInSeconds
        isActive
        categoryID
        pharmacyID
        pharmacyName
        serviceId
        serviceName
        serviceScheduleID
        suburb
        description
        rooms {
          roomID
          roomName
          pharmacyID
          description
          isAvailable
          createdDate
        }
        categoryID
        price {
          currency
          ammount
          includeGst
        }
        allowPayment
      }
    }
  }
`;

export const GET_SERVICE = gql`
  query GetService($serviceId: ID!) {
    getService(serviceId: $serviceId) {
      createdDate
      durationInSeconds
      isActive
      pharmacyID
      categoryID
      pharmacyName
      serviceId
      serviceName
      serviceScheduleID
      suburb
      updatedDate
      description
      rooms {
        roomID
        roomName
        pharmacyID
        description
        isAvailable
        createdDate
      }
      categoryID
      price {
        currency
        ammount
        includeGst
      }
      allowPayment
    }
  }
`;

export const QUERY_SERVICE_BY_PHARMACY_ID = gql`
  query ServiceByPharmacyID($pharmacyID: ID!, $first: Int, $after: String) {
    queryServicesByPharmacyIDIndex(pharmacyID: $pharmacyID, first: $first, after: $after) {
      nextToken
      items {
        createdDate
        durationInSeconds
        isActive
        categoryID
        pharmacyID
        pharmacyName
        serviceId
        serviceScheduleID
        serviceName
        suburb
        updatedDate
        description
        rooms {
          roomID
          roomName
          pharmacyID
          description
          isAvailable
          createdDate
        }
        categoryID
        price {
          currency
          ammount
          includeGst
        }
        allowPayment
      }
    }
  }
`;

// PHARMACY SCHEDULE

export const GET_PHARMACY_SCHEDULE = gql`
  query GetPharmacySchedule($id: ID!) {
    getPharmacySchedule(id: $id) {
      createdDate
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      id
      isActive
      pharmacyID
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

export const LIST_PHARMACY_SCHEDULE = gql`
  query ListPharmacySchedule($limit: Int, $nextToken: String, $filter: TablePharmacyScheduleFilterInput) {
    listPharmacySchedules(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        createdDate
        monday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        friday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        id
        isActive
        pharmacyID
        saturday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        sunday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        thursday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        tuesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        wednesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
      }
    }
  }
`;

export const QUERY_PHARMACY_SCHEDULE_BY_PHARMACY_ID = gql`
  query PharmacyScheduleByPharmacyID($after: String, $first: Int, $pharmacyID: ID!) {
    queryPharmacySchedulesByPharmacyIDIndex(pharmacyID: $pharmacyID, after: $after, first: $first) {
      nextToken
      items {
        createdDate
        monday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        friday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        id
        isActive
        pharmacyID
        saturday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        sunday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        thursday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        tuesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        wednesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
      }
    }
  }
`;

// HOURS

export const GET_HOUR = gql`
  query GetHour($ID: ID!) {
    getHours(ID: $ID) {
      ID
      closingHour
      isClosed
      openingHour
      pharmacyID
    }
  }
`;

export const LIST_HOUR = gql`
  query ListHour($limit: Int, $nextToken: String, $filter: TableHoursFilterInput) {
    listHours(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

export const QUERY_HOUR_BY_PHARMACY_ID = gql`
  query HourByPharmacyID($pharmacyID: ID!, $after: String, $first: Int) {
    queryHoursByPharmacyIDIndex(pharmacyID: $pharmacyID, after: $after, first: $first) {
      nextToken
      items {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

// SERVICE SCHEDULE

export const GET_SERVICE_SCHEDULE = gql`
  query GetServiceSchedule($serviceScheduleID: ID!) {
    getServiceSchedule(serviceScheduleID: $serviceScheduleID) {
      createdDate
      isActive
      pharmacyID
      serviceId
      serviceScheduleID
      lastAvailableDate
      slot
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
        blockList
      }
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
        blockList
      }
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
        blockList
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
        blockList
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
        blockList
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
        blockList
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
        blockList
      }
    }
  }
`;

export const LIST_SERVICE_SCHEDULE = gql`
  query ListServiceSchedule($limit: Int, $nextToken: String, $filter: TableServiceScheduleFilterInput) {
    listServiceSchedules(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        createdDate
        isActive
        pharmacyID
        serviceId
        serviceScheduleID
        slot
        friday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        monday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        saturday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        sunday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        thursday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        tuesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        wednesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
      }
    }
  }
`;

export const QUERY_SERVICE_SCHEDULE_BY_PHARMACY_ID = gql`
  query ServiceScheduleByPharmacyId($pharmacyID: ID!, $after: String, $first: Int) {
    queryServiceSchedulesByPharmacyIDIndex(pharmacyID: $pharmacyID, first: $first, after: $after) {
      nextToken
      items {
        createdDate
        isActive
        pharmacyID
        serviceId
        serviceScheduleID
        slot
        friday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        monday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        saturday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        sunday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        thursday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        tuesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
        wednesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
          blockList
        }
      }
    }
  }
`;

// AVAILABILITY

export const LIST_AVAILABILITY = gql`
  query ListAvailability($limit: Int, $nextToken: String, $filter: TablePharmacyAvailabilityFilterInput) {
    listPharmacyAvailabilities(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        availabilityID
        durationInSeconds
        expire_at
        isAvailable
        maxSlotsAvailable
        maxSlotsInStore
        pharmacyID
        serviceId
        serviceName
        timeslot
        rooms {
          roomID
          roomName
          isAvailable
        }
      }
    }
  }
`;

export const GET_AVAILABILITY = gql`
  query GetAvailability($availabilityID: ID!) {
    getPharmacyAvailability(availabilityID: $availabilityID) {
      availabilityID
      durationInSeconds
      expire_at
      isAvailable
      maxSlotsAvailable
      maxSlotsInStore
      pharmacyID
      serviceId
      serviceName
      timeslot
      rooms {
        roomID
        roomName
        isAvailable
      }
    }
  }
`;

export const GET_AVAILABILITY_BY_DATE_AND_SERVICE_ID = gql`
  query GetAvailabilitiesByDateServiceID(
    $appointmentDate: String!
    $serviceId: String!
    $first: Int = 500
    $after: String
  ) {
    queryAvailabiltyByServiceIdAppointmentDt(
      appointmentDate: $appointmentDate
      serviceId: $serviceId
      first: $first
      after: $after
    ) {
      nextToken
      items {
        availabilityID
        durationInSeconds
        expire_at
        isAvailable
        maxSlotsAvailable
        maxSlotsInStore
        pharmacyID
        serviceId
        serviceName
        timeslot
        rooms {
          roomID
          roomName
          isAvailable
        }
      }
    }
  }
`;

// BOOKING

export const GET_BOOKING = gql`
  query GetBooking($bookingID: ID!) {
    getBookings(bookingID: $bookingID) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      IsAssessmentCompleted
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        clientSecret
        status
        token
      }
      room {
        roomID
        roomName
      }
    }
  }
`;

export const QUERY_BOOKINGS_BY_PHARMACY_ID = gql`
  query BookingsByPharmacyId($after: String, $first: Int, $pharmacyID: ID!) {
    queryBookingsByPharmacyIDIndex(pharmacyID: $pharmacyID, after: $after, first: $first) {
      nextToken
      items {
        BookingMonth
        InjectionBrandName
        VaccineBrandName
        availabilityID
        bookingDate
        bookingID
        createdDate
        IsAssessmentCompleted
        durationInSeconds
        patientID
        pharmacyID
        serviceId
        status
        timeslot
        updatedDate
        patient {
          addressLineOne
          dateOfBirth
          emailAddress
          firstName
          gender
          lastName
          locality
          patientID
          pharmacyID
          phoneNumber
          postCode
          createdDate
          state
        }
        payment {
          channel
          clientSecret
          status
          token
        }
        room {
          roomID
          roomName
        }
      }
    }
  }
`;

export const LIST_BOOKING = gql`
  query ListBooking($filter: TableBookingsFilterInput, $limit: Int, $nextToken: String) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        BookingMonth
        InjectionBrandName
        VaccineBrandName
        availabilityID
        bookingDate
        bookingID
        createdDate
        durationInSeconds
        patientID
        pharmacyID
        IsAssessmentCompleted
        serviceId
        status
        timeslot
        updatedDate
        patient {
          addressLineOne
          dateOfBirth
          emailAddress
          firstName
          gender
          lastName
          locality
          patientID
          pharmacyID
          phoneNumber
          postCode
          createdDate
          state
        }
        payment {
          channel
          clientSecret
          status
          token
        }
        room {
          roomID
          roomName
        }
      }
    }
  }
`;

// SERVICE CATEGORY
export const LIST_SERVICE_CATEGORY = gql`
  query ListServiceCategory($limit: Int, $nextToken: String, $filter: TableServiceCategoryFilterInput) {
    listServiceCategories(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        categoryDescription
        categoryID
        categoryName
      }
    }
  }
`;

// ROOM
export const QUERY_ROOM_BY_PHARMACY_ID = gql`
  query RoomsByPharmacyId($pharmacyID: ID!, $after: String, $first: Int) {
    queryRoomsByPharmacyIDIndex(pharmacyID: $pharmacyID, first: $first, after: $after) {
      nextToken
      items {
        createdDate
        description
        isActive
        isAvailable
        pharmacyID
        roomID
        roomName
        snoozed
        snoozeTime
        schedule
        unavailableDates
      }
    }
  }
`;

export const GET_ROOM = gql`
  query GetRoom($roomID: ID!) {
    getRoom(roomID: $roomID) {
      createdDate
      description
      isActive
      isAvailable
      pharmacyID
      roomID
      roomName
      snoozed
      snoozeTime
      schedule
      unavailableDates
    }
  }
`;

// PATIENT PPOINTMENT
export const QUERY_PATIENT_BY_DOB_N_LAST_NAME = gql`
  query GetPatientByDOBnLastName($after: String, $first: Int = 10, $dateOfBirth: String!, $lastName: String!) {
    queryPatientAppointmentsByLastNameDOBIndex(
      dateOfBirth: $dateOfBirth
      lastName: $lastName
      after: $after
      first: $first
    ) {
      items {
        addressLineOne
        addressLineTwo
        createdDate
        dateOfBirth
        emailAddress
        firstName
        gender
        id
        ihiNumber
        initial
        isAboriginal
        lastName
        locality
        medicareCardNumber
        medicareExpiryDate
        medicareIRN
        middleName
        mobileNumber
        onlyNameIndicator
        patientID
        pharmacistID
        pharmacyID
        phoneNumber
        postCode
        providerNumber
        state
      }
    }
  }
`;

export const QUERY_PATIENT_BY_PATIENT_ID = gql`
  query GetPatientByPatientId($after: String, $first: Int, $patientID: ID!) {
    queryPatientAppointmentsByPatientIDIndex(patientID: $patientID, after: $after, first: $first) {
      items {
        addressLineOne
        addressLineTwo
        createdDate
        dateOfBirth
        emailAddress
        firstName
        gender
        id
        ihiNumber
        initial
        isAboriginal
        lastName
        locality
        medicareCardNumber
        medicareExpiryDate
        medicareIRN
        middleName
        mobileNumber
        onlyNameIndicator
        patientID
        pharmacistID
        pharmacyID
        phoneNumber
        postCode
        providerNumber
        state
      }
    }
  }
`;
