import React, { useState, useRef, useEffect } from 'react';
import {
  TextField,
  TextFieldProps,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  alpha,
  CircularProgress,
  Typography,
} from '@mui/material';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { uuid } from '../../utils';
import { SearchOffOutlined } from '@mui/icons-material';

type GooglePlacesInputProps = {
  apiKey: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
  label?: string;
} & TextFieldProps;

type Suggestion = {
  description: string;
  placeId: string;
};

const SelectAddress: React.FC<GooglePlacesInputProps> = ({
  apiKey,
  onPlaceSelected,
  label = 'Address',
  onChange,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [position, setPosition] = useState<'bottom' | 'top'>('bottom');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showPrediction, setShowPrediction] = useState(false);

  const { placesService, getPlacePredictions, placePredictions, autocompleteSessionToken, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey,
      sessionToken: true,
    });

  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Calculate available space
      const spaceAbove = rect.top;
      const spaceBelow = windowHeight - rect.bottom;

      // Determine if there's more space above or below the input
      if (spaceBelow < 200 && spaceAbove > spaceBelow) {
        setPosition('top');
      } else {
        setPosition('bottom');
      }
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (typeof onChange === 'function') onChange(event);
    setInputValue(value);

    if (value) {
      getPlacePredictions({
        input: value,
        types: ['address'],
        componentRestrictions: { country: 'au' },
        sessionToken: autocompleteSessionToken,
      });
    }

    if (!showPrediction && value.length > 0) setShowPrediction(true);
  };

  const handleSuggestionClick = (placeId: string) => {
    if (placesService) {
      placesService.getDetails({ placeId, sessionToken: autocompleteSessionToken }, (place, status) => {
        onPlaceSelected(place);
        setInputValue(place?.formatted_address || '');
      });
    }

    setShowPrediction(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowPrediction(false)}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: position === 'top' ? 'column-reverse' : 'column',
        }}
      >
        <TextField
          fullWidth
          color="primary"
          variant="outlined"
          inputRef={inputRef}
          label={label}
          size="small"
          value={inputValue}
          onChange={handleInputChange}
          {...rest}
        />
        {showPrediction && (
          <Paper
            sx={{
              position: 'absolute',
              top: position === 'top' ? 'auto' : '100%',
              bottom: position === 'top' ? '100%' : 'auto',
              left: 0,
              right: 0,
              zIndex: 10000,
            }}
            elevation={3}
          >
            <List
              sx={(theme) => ({
                cursor: 'pointer',
                'li:hover': {
                  background: alpha(theme.palette.primary.main, 0.1),
                },
              })}
            >
              {isPlacePredictionsLoading ? (
                <ListItem
                  key={uuid()}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress size={24} />
                </ListItem>
              ) : placePredictions.length === 0 ? (
                <Box display="flex" alignItems="center" gap=".5rem" justifyContent="center" width="100%" p=".5rem">
                  <Typography color="primary" textAlign="center">
                    No result
                  </Typography>
                  <SearchOffOutlined color="primary" />
                </Box>
              ) : (
                placePredictions.map((suggestion) => (
                  <ListItem key={suggestion.place_id} onClick={() => handleSuggestionClick(suggestion.place_id)}>
                    <ListItemText primary={suggestion.description} />
                  </ListItem>
                ))
              )}
            </List>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SelectAddress;
