// constants
import { APICore } from "../../helpers/api/apiCore";
import { INotification } from "../../interfaces/INotification";
import { IsReadPayload, removePlayload } from "./actions";
import { NotificationActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  notifications: api.getNotifications() || [],
};

type NotificationActionType =
  | { type: NotificationActionTypes.SET_CLEAR_ALL }
  | { type: NotificationActionTypes.SET_READ; payload: IsReadPayload }
  | { type: NotificationActionTypes.SET_REMOVE; payload: removePlayload }
  | { type: NotificationActionTypes.SET_ADD; payload: Notification };

interface State {
  notifications: INotification[] | [];
}

const Notification = (
  state: State = INIT_STATE,
  action: NotificationActionType
): any => {
  // logger.log("action", action);
  switch (action.type) {
    case NotificationActionTypes.SET_ADD: {
      const notif = [...state.notifications, action.payload];
      api.setNotifications(notif as INotification[]);
      return {
        ...state,
        notifications: notif,
      };
    }

    case NotificationActionTypes.SET_CLEAR_ALL: {
      api.setNotifications([]);

      return {
        ...state,
        notifications: [],
      };
    }

    case NotificationActionTypes.SET_REMOVE: {
      const notif = state.notifications.filter(
        (notification) => action.payload.id !== notification.id
      );

      api.setNotifications(notif);

      return {
        ...state,
        notifications: notif,
      };
    }

    case NotificationActionTypes.SET_READ: {
      const notif = state.notifications.map((notification) => {
        if (notification.id === action.payload.id) {
          return {
            ...notification,
            isRead: action.payload.isRead,
          };
        }

        return notification;
      });
      api.setNotifications(notif);

      return {
        ...state,
        notifications: notif,
      };
    }

    default:
      return { ...state };
  }
};

export default Notification;
