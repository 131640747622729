import React from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Theme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { privacyPolicyContent } from './privacyPolicyContent';
import { PrivacyPolicySection } from './policyTypes';

const StyledAccordion = styled(Accordion)(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(2),
}));

const PrivacyPolicyComponent: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h2" component="h1" gutterBottom>
          {privacyPolicyContent.title}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated: {privacyPolicyContent.lastUpdated}
        </Typography>
        {privacyPolicyContent.sections.map((section: PrivacyPolicySection, index: number) => (
          <StyledAccordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{section.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: section.content.replace(/\n/g, '<br>') }}
              />
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </Box>
    </Container>
  );
};

export default PrivacyPolicyComponent;
