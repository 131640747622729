import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Link from "@mui/material/Link";

// Extending TypographyProps to allow any props that Typography accepts
const Copyright: React.FC<TypographyProps> = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://www.dragatron.com.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Dragatron
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Copyright;
