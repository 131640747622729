import React from 'react';
import { Box, Typography } from '@mui/material';
import { Syringe, Star } from 'lucide-react'; // Import the vaccine icon

interface CalendarEventComponentProps {
  event: {
    title: string;
    start: Date;
    end: Date;
    color?: string;
    isMultiDay?: boolean;
  };
  hasSpecialEvent?: boolean;
}

const CalendarEventComponent: React.FC<CalendarEventComponentProps> = ({ event, hasSpecialEvent }) => {
  return (
    <Box
      sx={{
        backgroundColor: event.color || '#3174ad',
        color: 'white',
        borderRadius: '4px',
        padding: '2px 4px',
        fontSize: '0.85em',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        position: 'relative', // Add this for positioning the star
      }}
    >
      <Syringe style={{ marginRight: '4px', width: '14px', height: '14px' }} /> {/* Add the vaccine icon */}
      <Typography variant="caption" component="div">
        {event.title}
      </Typography>
      {!event.isMultiDay && (
        <Typography variant="caption" component="div" sx={{ marginLeft: 'auto' }}>
          {event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </Typography>
      )}
      {hasSpecialEvent && (
        <Star
          style={{
            position: 'absolute',
            top: '4px',
            right: '2px',
            width: '16px',
            height: '16px',
            color: 'yellow',
          }}
        />
      )}
    </Box>
  );
};

export default CalendarEventComponent;
