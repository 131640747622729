import axios from 'axios';
import { BloodPressureReading, BloodPressureResponse, BloodPressureDataResponse } from '../types/bloodPressure';

const API_URL = process.env.REACT_APP_BLOOD_PRESSURE_CREATE_URL;
const GET_BY_PATIENT_ID_URL = process.env.REACT_APP_BLOOD_PRESSURE_GET_BY_PATIENT_ID_URL;

export const createBloodPressureReading = async (
  formData: Omit<BloodPressureReading, 'id' | 'createdAt' | 'updatedAt'>
): Promise<BloodPressureResponse> => {
  const response = await axios.post<BloodPressureResponse>(API_URL!, formData);
  return response.data;
};

export const getBloodPressureReadingsByPatientId = async (requestBody: {
  body: {
    patientID: string;
    pharmacyID: string;
    sortDirection: string;
    limit: number;
    nextToken: string | null;
  };
}): Promise<BloodPressureDataResponse> => {
  const response = await axios.post<BloodPressureDataResponse>(GET_BY_PATIENT_ID_URL!, requestBody);
  return response.data;
};

// You can add more functions here for fetching, updating, or deleting readings
