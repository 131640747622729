import LockOutlined from "@mui/icons-material/LockOutlined";
import { Box, Container, Paper, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {};

const UnauthorizeContent = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Box component="div" sx={{ p: 3 }}>
      <Paper
        sx={{
          p: "2rem",
          background: "white",
          minHeight: "80vh",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
            <LockOutlined sx={{ fontSize: 100, color: "error.main", mb: 3 }} />
            <Typography variant="h4" gutterBottom>
              Unauthorized Access
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Sorry, you do not have permission to view this page. Please
              contact your administrator if you believe this is a mistake.
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
            </Box>
          </Container>
        </Box>
      </Paper>
    </Box>
  );
};

export default UnauthorizeContent;
