import React, { useEffect } from 'react';
import { Box, Typography, Modal, Button, CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VaccinationAID from '../pages/vaccine/VaccinationAID';
import { VaccinationScreeningInput } from '../pages/vaccine/VaccinationAID';
import { logger } from '../utils/logger';

interface SubmissionStatusModalProps {
  open: boolean;
  onClose: () => void;
  submissionState: {
    air: {
      loading: boolean;
      submitted: boolean;
      success: boolean;
      message: string | string[];
    };
    ppa: {
      loading: boolean;
      submitted: boolean;
      success: boolean;
      message: string | string[];
    };
  };
  wasPpaSubmissionAttempted: boolean;
  vaccinationAssessmentAnswers: VaccinationScreeningInput | null;
}

const SubmissionStatusModal: React.FC<SubmissionStatusModalProps> = ({
  open,
  onClose,
  submissionState,
  wasPpaSubmissionAttempted,
  vaccinationAssessmentAnswers,
}) => {
  useEffect(() => {
    logger.log('SubmissionStatusModal mounted or updated', {
      open,
      submissionState,
      wasPpaSubmissionAttempted,
      hasVaccinationAnswers: !!vaccinationAssessmentAnswers,
    });

    return () => {
      logger.log('SubmissionStatusModal unmounted');
    };
  }, [open, submissionState, wasPpaSubmissionAttempted, vaccinationAssessmentAnswers]);

  const renderSubmissionStatus = (type: 'AIR' | 'PPA') => {
    const state = type === 'AIR' ? submissionState.air : submissionState.ppa;
    logger.log(`Rendering ${type} status:`, state);

    return (
      <Box sx={{ width: '100%', mb: 2 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
          {type} Submission
        </Typography>
        {state.loading ? (
          <Box display="flex" alignItems="center">
            <CircularProgress size={24} sx={{ mr: 2 }} />
            <Typography>{`Submitting to ${type}...`}</Typography>
          </Box>
        ) : state.submitted ? (
          <Box display="flex" flexDirection="column">
            {state.success ? (
              <Box display="flex" alignItems="center">
                <CheckCircleOutlineIcon sx={{ color: 'success.main', fontSize: 40, mr: 2 }} />
                <Typography>{state.message}</Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" mb={1}>
                <ErrorOutlineIcon sx={{ color: 'error.main', fontSize: 40, mr: 2 }} />
                <ul>
                  {Array.isArray(state.message) ? (
                    state.message.map((msg, index) => (
                      <li key={index}>
                        <Typography>{msg}</Typography>
                      </li>
                    ))
                  ) : (
                    <li>
                      <Typography>{state.message}</Typography>
                    </li>
                  )}
                </ul>
              </Box>
            )}
          </Box>
        ) : (
          <Typography>Waiting for {type} submission to start...</Typography>
        )}
      </Box>
    );
  };

  // Add this new useEffect to log when wasPpaSubmissionAttempted doesn't match PPA state
  useEffect(() => {
    if (submissionState.ppa.loading || submissionState.ppa.submitted) {
      if (!wasPpaSubmissionAttempted) {
        logger.warn(
          'Inconsistency detected: PPA submission is in progress or completed, but wasPpaSubmissionAttempted is false',
          {
            ppaState: submissionState.ppa,
            wasPpaSubmissionAttempted,
          }
        );
      }
    }
  }, [submissionState.ppa, wasPpaSubmissionAttempted]);

  const shouldRenderPPA = wasPpaSubmissionAttempted || submissionState.ppa.loading || submissionState.ppa.submitted;

  logger.log('Rendering SubmissionStatusModal', {
    airState: submissionState.air,
    ppaState: submissionState.ppa,
    wasPpaSubmissionAttempted,
    shouldRenderPPA,
    showVaccinationAssessment: submissionState.air.success && (!shouldRenderPPA || submissionState.ppa.success),
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        logger.log('SubmissionStatusModal closing');
        onClose();
      }}
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500 } }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
        minWidth: '600px',
        minHeight: '70px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '400px',
          maxHeight: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Submission Status
        </Typography>

        {renderSubmissionStatus('AIR')}
        {shouldRenderPPA && renderSubmissionStatus('PPA')}

        <Button
          variant="contained"
          color={
            submissionState.air.success && (!shouldRenderPPA || submissionState.ppa.success) ? 'success' : 'primary'
          }
          onClick={() => {
            logger.log('Close button clicked in SubmissionStatusModal');
            onClose();
          }}
          disabled={submissionState.air.loading || submissionState.ppa.loading}
          sx={{
            mt: 2,
            borderRadius: 20,
            paddingX: 3,
            ':hover': {
              backgroundColor:
                submissionState.air.success && (!shouldRenderPPA || submissionState.ppa.success)
                  ? 'success.dark'
                  : 'primary.dark',
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default SubmissionStatusModal;
