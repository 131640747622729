import React from 'react';
import { Typography, Container, Paper, Theme, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(3),
}));

const Section = styled('div')(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(3),
}));

const CookiePolicyComponent: React.FC = () => {
  return (
    <Root>
      <Container maxWidth="md">
        <StyledPaper>
          <Typography variant="h4" gutterBottom>
            Cookie Policy
          </Typography>
          <Typography variant="body2" gutterBottom>
            Last updated: 02/09/2024
          </Typography>

          <Section>
            <Typography variant="h6" gutterBottom>
              1. What are cookies?
            </Typography>
            <Typography variant="body1" paragraph>
              [Explain what cookies are]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              2. How we use cookies
            </Typography>
            <Typography variant="body1" paragraph>
              We use cookies for the following purposes:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="[Purpose 1]" secondary="[Explanation]" />
              </ListItem>
              <ListItem>
                <ListItemText primary="[Purpose 2]" secondary="[Explanation]" />
              </ListItem>
              {/* Add more purposes as needed */}
            </List>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              3. Types of cookies we use
            </Typography>
            <Typography variant="body1" paragraph>
              [Explain the types of cookies used]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              4. How to manage cookies
            </Typography>
            <Typography variant="body1" paragraph>
              [Explain how users can manage or disable cookies]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              5. Changes to this Cookie Policy
            </Typography>
            <Typography variant="body1" paragraph>
              [Explain how and when you might update this policy]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              6. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions about this Cookie Policy, please contact us at [Your Contact Email].
            </Typography>
          </Section>
        </StyledPaper>
      </Container>
    </Root>
  );
};

export default CookiePolicyComponent;
