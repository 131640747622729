// constants
import { IProductSaleFilter } from '../../contexts/ProductSaleContext';
import { ICustomer } from '../../interfaces/ICustomer';
import { OpenCartPayload } from './actions';
import { SaleActionTypes } from './constants';
import { RecordingCategory } from '../../interfaces';
import { logger } from '../../utils/logger';
export const AVAILABLE_FILTER = [
  {
    label: 'Vapes',
    value: RecordingCategory.VAPE_RECORD,
  },
  // {
  //   label: "Pseudoepherine",
  //   value: RecordingCategory.PSEUDOEPHEDRINE_RECORD,
  // },
  // {
  //   label: "Naloxone",
  //   value: RecordingCategory.NALOXONE_RECORD,
  // },
];

const INIT_STATE = {
  selectedCustomer: null,
  openCart: true,
  filterType: AVAILABLE_FILTER[1],
};

type SaleActionType =
  | { type: SaleActionTypes.SET_CUSTOMER_SELECTED; payload: ICustomer | null }
  | { type: SaleActionTypes.SET_OPEN_CART; payload: OpenCartPayload }
  | { type: SaleActionTypes.SET_FILTER_TYPE; payload: IProductSaleFilter };
interface State {
  selectedCustomer: ICustomer | null;
  openCart: boolean;
  filterType: IProductSaleFilter;
}

const Sale = (state: State = INIT_STATE, action: SaleActionType): any => {
  logger.log('action', action);
  switch (action.type) {
    case SaleActionTypes.SET_CUSTOMER_SELECTED: {
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    }

    case SaleActionTypes.SET_OPEN_CART: {
      return {
        ...state,
        openCart: action.payload.openCart,
      };
    }
    case SaleActionTypes.SET_FILTER_TYPE: {
      return {
        ...state,
        filterType: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default Sale;
