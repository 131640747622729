import axios from 'axios';
import { logger } from '../../../../src/utils/logger';

const amplifyFunctionUrl = process.env.REACT_APP_SUBSCRIPTION_URL;

interface CheckoutSessionParams {
  priceId: string;
  taxId: string;
  successUrl: string;
  cancelUrl: string;
  email: string;
}

export const createCheckoutSession = async ({
  priceId,
  taxId,
  successUrl,
  cancelUrl,
  email,
}: CheckoutSessionParams) => {
  logger.log('Starting createCheckoutSession function');

  if (!amplifyFunctionUrl) {
    logger.error('REACT_APP_SUBSCRIPTION_URL is not defined');
    throw new Error('REACT_APP_SUBSCRIPTION_URL is not defined');
  }

  logger.log('Preparing request payload', { priceId, taxId, successUrl, cancelUrl, email });

  try {
    logger.log('Sending POST request to:', amplifyFunctionUrl);
    const response = await axios.post(
      amplifyFunctionUrl,
      {
        priceId,
        taxId,
        successUrl,
        cancelUrl,
        email,
      },
      {
        timeout: 30000, // Increased timeout to 30 seconds
      }
    );

    logger.log('Response received:', JSON.stringify(response.data, null, 2));

    if (!response.data.sessionId) {
      logger.error('No sessionId in response', JSON.stringify(response.data, null, 2));
      throw new Error('No sessionId returned from server');
    }

    logger.log('SessionId received:', response.data.sessionId);
    return response.data.sessionId;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      logger.error('Axios error:', error.response?.data || error.message);
      logger.error('Error status:', error.response?.status);
      logger.error('Error headers:', JSON.stringify(error.response?.headers, null, 2));
      logger.error('Error config:', JSON.stringify(error.config, null, 2));
    } else {
      logger.error('Non-Axios error:', error);
    }
    throw error;
  }
};
