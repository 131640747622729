import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Drawer,
  Select,
  SelectChangeEvent,
  InputAdornment,
  Tabs,
  Tab,
} from '@mui/material';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import AuthContext from '../../contexts/AuthContext';
import axios from 'axios';
import { useIdToken } from '../../hooks/useIdToken';
import { logger } from '../../utils/logger';
import useClinicPatients from '../../hooks/useClinicPatients';
import { Clinic } from '../../hooks/useClinicData';
import {
  EventNote,
  LocationOn,
  AccessTime,
  Group,
  Notes,
  Add as AddIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  IdentifyIndividualAIR,
  IdentifyIndividualRequest,
  IdentifyIndividualResponse,
} from '../../pages/patients/identifyIndividual';
import { useLazyQuery } from '@apollo/client';
import { GraphQLPatientsResponse } from 'pages/patients/patient';
import { QUERY_PATIENTS_BY_BIRTHDATE_AND_LASTNAME } from 'graphql-queries';
import { setCustomer, useCustomer } from 'contexts/CustomerContext';
import ClinicPatientsPage from '../patients/ClinicPatientsPage';
import { LoadingButton } from '@mui/lab';

interface Patient {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  medicareNumber: string;
  medicareIRN: string;
  postcode: string;
  status?: string;
  service?: string;
  notes?: string;
}

enum PatientStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE'
}

const LaunchedClinic: React.FC = () => {
  const { clinicID } = useParams<{ clinicID: string }>();
  const { dispatch } = useCustomer(); // Use the dispatch function from the context
  const [fetchPatients, { loading: fetchPatientsLoading, data: fetchPatientsData, error: fetchPatientsError }] =
    useLazyQuery<GraphQLPatientsResponse>(QUERY_PATIENTS_BY_BIRTHDATE_AND_LASTNAME);
  const navigate = useNavigate();
  const { pharmacyID, deviceName, minorId, prodaOrgId, providerNumber } = useContext(AuthContext);
  const { idToken, isLoading: isTokenLoading } = useIdToken();
  const [clinic, setClinic] = useState<Clinic | null>(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [newPatient, setNewPatient] = useState<Patient>({
    id: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    medicareNumber: '',
    medicareIRN: '',
    postcode: '',
    service: 'Vaccination',
    notes: '',
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
  const [selectedPatientData, setSelectedPatientData] = useState<any>(null);
  const [administeringVaccine, setAdministeringVaccine] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState<PatientStatus>(PatientStatus.NEW);

  const { patients, loading: patientsLoading, error: patientsError, refetch } = useClinicPatients(clinicID || '');

  useEffect(() => {
    if (clinicID && idToken && !isTokenLoading) {
      fetchClinic();
    }
  }, [clinicID, idToken, isTokenLoading]);

  const fetchClinic = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLINICS_GET_BY_ID_URL!,
        { clinicID },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setClinic(response.data);
    } catch (err) {
      logger.error('Error fetching clinic data:', err);
      setSnackbar({
        open: true,
        message: 'Failed to fetch clinic data',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
      flex: 1,
      valueFormatter: (params) => {
        return dayjs(params.value).format('DD/MM/YYYY');
      },
    },
    { field: 'medicareNumber', headerName: 'Medicare Number', flex: 1 },
    { field: 'medicareIRN', headerName: 'Medicare IRN', flex: 0.5 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Select
          value={params.value}
          onChange={(event: SelectChangeEvent) => handleStatusChange(event, params.row.clinicPatientID)}
          sx={{ width: '100%' }}
        >
          <MenuItem value="NEW">NEW</MenuItem>
          <MenuItem value="IN_PROGRESS">IN PROGRESS</MenuItem>
          <MenuItem value="COMPLETE">COMPLETE</MenuItem>
        </Select>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <LoadingButton
          variant="contained"
          color={params.row.status === 'COMPLETE' ? 'success' : 'primary'}
          onClick={() => handleAdministerVaccine(params.row.clinicPatientID)}
          disabled={params.row.status === 'COMPLETE' || administeringVaccine === params.row.clinicPatientID}
          loading={administeringVaccine === params.row.clinicPatientID}
        >
          {params.row.status === 'COMPLETE' ? 'Administered' : 'Administer Vaccine'}
        </LoadingButton>
      ),
    },
  ];

  const handleStatusChange = async (event: SelectChangeEvent, clinicPatientID: string) => {
    const newStatus = event.target.value;
    await updatePatientStatus(clinicPatientID, newStatus);
  };

  const updatePatientStatus = async (clinicPatientID: string, newStatus: string) => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLINIC_PATIENTS_UPDATE_URL!,
        {
          clinicPatientID,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: `Patient status updated to ${newStatus}`,
          severity: 'success',
        });
        await refetch(); // Refetch the patients list to update the UI
      } else {
        throw new Error('Failed to update patient status');
      }
    } catch (error) {
      console.error('Error updating patient status:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update patient status',
        severity: 'error',
      });
    }
  };

  const handleAdministerVaccine = async (clinicPatientID: string) => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }

    setAdministeringVaccine(clinicPatientID);

    try {
      // Fetch the patient details
      const patientResponse = await axios.post(
        process.env.REACT_APP_CLINIC_PATIENTS_GET_BY_ID_URL!,
        { clinicPatientID },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const patientData = patientResponse.data;

      // Process Medicare number and IRN
      let medicareNumber = patientData.medicareNumber;
      let medicareIRN = patientData.medicareIRN;

      if (medicareNumber && medicareNumber.length > 10) {
        // Extract the last digit from the Medicare number as the IRN
        const extractedIRN = medicareNumber.slice(-1);
        medicareNumber = medicareNumber.slice(0, 10);

        // Use the extracted IRN if it's not already present in the medicareIRN field
        if (!medicareIRN || medicareIRN === extractedIRN) {
          medicareIRN = extractedIRN;
        }
        // If medicareIRN is already set and different, keep the original medicareIRN
      }

      // Ensure medicareIRN is always a single digit
      medicareIRN = medicareIRN ? medicareIRN.slice(-1) : '';

      // Prepare the request for IdentifyIndividualAIR
      const requestData: IdentifyIndividualRequest = {
        individual: {
          medicareCard: {
            medicareCardNumber: medicareNumber,
            medicareIRN: medicareIRN,
          },
          personalDetails: {
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            dateOfBirth: patientData.dateOfBirth.split('-').reverse().join(''),
          },
          address: {
            postCode: patientData.postcode,
          },
        },
        informationProvider: {
          providerNumber: providerNumber, // You need to provide thi
        },
        pharmacyID: pharmacyID,
        deviceName: deviceName,
        minorId: minorId,
        prodaOrgId: prodaOrgId,
      };

      // Call IdentifyIndividualAIR
      const airResponse: IdentifyIndividualResponse = await IdentifyIndividualAIR(requestData, idToken);

      if (airResponse.success) {
        // Process the successful response
        logger.log('AIR identification successful:', airResponse.data);

        if (airResponse) {
          const queryResponse = await fetchPatients({
            variables: {
              lastName: patientData.lastName.toUpperCase(),
              dateOfBirth: patientData.dateOfBirth,
            },
            fetchPolicy: 'no-cache',
          });

          if (queryResponse.data && queryResponse.data.queryPatientsByLastNameDOBIndex.items.length > 0) {
            const patientData = queryResponse.data.queryPatientsByLastNameDOBIndex.items[0];
            const selectedPatient = {
              lastName: patientData.lastName,
              dateOfBirth: patientData.dateOfBirth,
              firstName: patientData.firstName,
              medicareCardNumber: patientData.medicareCardNumber || '',
              medicareIRN: patientData.medicareIRN || '',
              postCode: patientData.postCode || '',
              ihiNumber: patientData.ihiNumber || '',
              patientID: patientData.patientID || '',
              state: patientData.state || '',
              suburb: patientData.locality || '', // 'locality' in the query corresponds to 'suburb'
              address: `${patientData.addressLineOne || ''} ${patientData.addressLineTwo || ''}`.trim(),
              phone: patientData.phoneNumber || patientData.mobileNumber || '',
              email: patientData.emailAddress || '',
              // Additional fields from the query that might be useful
              gender: patientData.gender,
              initial: patientData.initial,
              middleName: patientData.middleName,
              pharmacistID: patientData.pharmacistID,
              pharmacyID: patientData.pharmacyID,
              isAboriginal: patientData.isAboriginal,
              onlyNameIndicator: patientData.onlyNameIndicator,
            };
            logger.log('selectedPatient', selectedPatient);
            setCustomer(dispatch, selectedPatient);
            const uniqueKey = `patientAshwinsData-${patientData.id}-${Date.now()}`;
            try {
              localStorage.setItem(uniqueKey, JSON.stringify(selectedPatient));
              setSelectedPatientData(selectedPatient);
              setSelectedPatientId(patientData.id);
              setDrawerOpen(true);

              // Update patient status to "IN_PROGRESS"
              await updatePatientStatus(clinicPatientID, 'IN_PROGRESS');
            } catch (error) {
              console.error('Failed to save patient data to localStorage:', error);
              // Optionally handle the error, e.g., show a notification
            }
          } else {
            logger.error('No patient data found in the GraphQL response');
            // Handle the case where no patient data is returned
          }
        }

        // Here you would typically update the patient's status or record the vaccination
        // For now, we'll just show a success message
        setSnackbar({
          open: true,
          message: 'Patient identified successfully. Ready to administer vaccine.',
          severity: 'success',
        });
      } else {
        // Handle the error
        logger.error('AIR identification failed:', airResponse.data);
        setSnackbar({
          open: true,
          message: 'Failed to identify patient in AIR. Please check the details and try again.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error administering vaccine:', error);
      setSnackbar({
        open: true,
        message: 'An error occurred while processing the vaccination.',
        severity: 'error',
      });
    } finally {
      setAdministeringVaccine(null);
      await refetch();
    }
  };

  const handleAddPatient = async () => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      const formattedDateOfBirth = newPatient.dateOfBirth
        ? dayjs(newPatient.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '';

      const patientData: any = {
        firstName: newPatient.firstName,
        lastName: newPatient.lastName,
        dateOfBirth: formattedDateOfBirth,
        medicareNumber: newPatient.medicareNumber,
        medicareIRN: newPatient.medicareIRN,
        postcode: newPatient.postcode,
        service: newPatient.service,
        notes: newPatient.notes,
        clinicID,
        pharmacyID,
        status: 'NEW',
      };

      await axios.post(process.env.REACT_APP_CLINIC_PATIENTS_CREATE_URL!, patientData, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      await refetch();
      setOpenDialog(false);
      setNewPatient({
        id: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        medicareNumber: '',
        medicareIRN: '',
        postcode: '',
        service: 'Vaccination',
        notes: '',
      });
      setSnackbar({
        open: true,
        message: 'Patient added successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding patient:', error);
      setSnackbar({
        open: true,
        message: 'Failed to add patient',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Update the getFilteredPatients function
  const getFilteredPatients = () => {
    const searchFiltered = patients.filter((patient) =>
      Object.values(patient).some((value) =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return searchFiltered.filter(patient =>
      activeTab === PatientStatus.NEW ?
        (!patient.status || patient.status === PatientStatus.NEW ||
          (patient.status !== PatientStatus.IN_PROGRESS && patient.status !== PatientStatus.COMPLETE)) :
        patient.status === activeTab
    );
  };

  // Update the tab counter to include any non-standard status
  const getTabCount = (status: PatientStatus) => {
    if (status === PatientStatus.NEW) {
      return patients.filter(p =>
        !p.status ||
        p.status === PatientStatus.NEW ||
        (p.status !== PatientStatus.IN_PROGRESS && p.status !== PatientStatus.COMPLETE)
      ).length;
    }
    return patients.filter(p => p.status === status).length;
  };

  const filteredPatients = getFilteredPatients();

  if (loading || patientsLoading) {
    return <CircularProgress />;
  }

  if (!clinic) {
    return <Typography color="error">Clinic not found</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          {clinic.clinicName}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Chip icon={<EventNote />} label={dayjs(clinic.date).format('DD/MM/YYYY')} />
          </Grid>
          <Grid item>
            <Chip icon={<AccessTime />} label={`${clinic.startTime} - ${clinic.endTime}`} />
          </Grid>
          <Grid item>
            <Chip icon={<LocationOn />} label={clinic.location} />
          </Grid>
          <Grid item>
            <Chip icon={<Group />} label={`Capacity: ${clinic.capacity}`} />
          </Grid>
          {clinic.notes && (
            <Grid item>
              <Chip icon={<Notes />} label={`Notes: ${clinic.notes}`} />
            </Grid>
          )}
        </Grid>
      </Paper>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Patients</Typography>
          <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
            Add Walk-in Patient
          </Button>
        </Box>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search patients..."
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab
            label={`New (${getTabCount(PatientStatus.NEW)})`}
            value={PatientStatus.NEW}
          />
          <Tab
            label={`In Progress (${getTabCount(PatientStatus.IN_PROGRESS)})`}
            value={PatientStatus.IN_PROGRESS}
          />
          <Tab
            label={`Complete (${getTabCount(PatientStatus.COMPLETE)})`}
            value={PatientStatus.COMPLETE}
          />
        </Tabs>

        <Typography variant="body2">
          Showing {filteredPatients.length} {activeTab.toLowerCase()} patients
        </Typography>
      </Box>

      <DataGridPro
        rows={filteredPatients}
        columns={columns}
        loading={patientsLoading}
        disableRowSelectionOnClick
        getRowId={(row) => row.clinicPatientID}
        sx={{
          height: 400,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#e2f1f0',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              color: '#05153f',
            },
          },
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
        }}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Patient</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                value={newPatient.firstName}
                onChange={(e) => setNewPatient({ ...newPatient, firstName: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                value={newPatient.lastName}
                onChange={(e) => setNewPatient({ ...newPatient, lastName: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Date of Birth"
                format="DD/MM/YYYY"
                value={newPatient.dateOfBirth ? dayjs(newPatient.dateOfBirth) : null}
                onChange={(date) =>
                  setNewPatient({
                    ...newPatient,
                    dateOfBirth: date ? date.format('DD/MM/YYYY') : '',
                  })
                }
                slotProps={{
                  textField: { fullWidth: true, margin: 'normal' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Medicare Number"
                value={newPatient.medicareNumber}
                onChange={(e) => setNewPatient({ ...newPatient, medicareNumber: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Medicare IRN"
                value={newPatient.medicareIRN}
                onChange={(e) => setNewPatient({ ...newPatient, medicareIRN: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Postcode"
                value={newPatient.postcode}
                onChange={(e) => setNewPatient({ ...newPatient, postcode: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Service"
                value={newPatient.service}
                onChange={(e) => setNewPatient({ ...newPatient, service: e.target.value })}
                fullWidth
                margin="normal"
              >
                <MenuItem value="Vaccination">Vaccination</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Notes"
                value={newPatient.notes}
                onChange={(e) => setNewPatient({ ...newPatient, notes: e.target.value })}
                fullWidth
                multiline
                rows={3}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddPatient} variant="contained" color="primary">
            Add Patient
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '80%',
            maxWidth: '1000px',
          },
        }}
      >
        {selectedPatientId && selectedPatientData && (
          <ClinicPatientsPage patientId={selectedPatientId} patientData={selectedPatientData} />
        )}
      </Drawer>
    </Box>
  );
};

export default LaunchedClinic;
