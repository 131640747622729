import React, { useState, useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  IdentifyIndividualAIRV2,
  IdentifyIndividualRequest,
  IdentifyIndividualResponse,
} from '../pages/patients/identifyIndividual';
import { useIdToken } from '../hooks/useIdToken';
import { logger } from '../utils/logger';
import AuthContext from '../contexts/AuthContext';
import dayjs from 'dayjs';
import axios from 'axios';

interface ValidatePatientButtonProps {
  patient: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    medicareNumber: string;
    medicareIRN: string;
    postcode?: string;
    status?: string;
  };
  pharmacyID: string;
  onValidationComplete?: (response: any) => void;
  onEditRequest: (validationErrors?: any[], updatedFields?: any) => void;
  clinicPatientID: string;
}

const ValidatePatientButton: React.FC<ValidatePatientButtonProps> = ({
  patient,
  pharmacyID,
  onValidationComplete,
  onEditRequest,
  clinicPatientID,
}) => {
  const [isValidating, setIsValidating] = useState(false);
  const { idToken } = useIdToken();
  const { providerNumber, minorId, prodaOrgId, deviceName } = useContext(AuthContext);
  const [isConfirmed, setIsConfirmed] = useState(patient.status === 'CONFIRMED');

  const updatePatientStatus = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_CLINIC_PATIENTS_UPDATE_URL!,
        {
          clinicPatientID,
          status: 'CONFIRMED',
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setIsConfirmed(true);
      if (onValidationComplete) {
        onValidationComplete({ success: true });
      }
    } catch (error) {
      logger.error('Error updating patient status:', error);
    }
  };

  const compareAndGetDifferences = (original: any, airData: any) => {
    logger.log('Comparing original data:', original);
    logger.log('Comparing AIR data:', airData);

    const differences: any = {};
    const updatedFields = {
      firstName: airData.individual.personalDetails.firstName,
      lastName: airData.individual.personalDetails.lastName,
      dateOfBirth: dayjs(airData.individual.personalDetails.dateOfBirth, 'DDMMYYYY').format('YYYY-MM-DD'),
      medicareNumber: airData.individual.medicareCard.medicareCardNumber,
      medicareIRN: airData.individual.medicareCard.medicareIRN,
      postcode: airData.individual.address.postCode,
      isAboriginal: airData.indigenousStatus,
      address: {
        line1: airData.individual.address.addressLineOne,
        line2: airData.individual.address.addressLineTwo,
        suburb: airData.individual.address.locality,
      },
    };

    logger.log('Processed updated fields:', updatedFields);

    // Compare fields
    if (original.firstName.toUpperCase() !== updatedFields.firstName) {
      differences.firstName = updatedFields.firstName;
      logger.log('First name difference detected:', { original: original.firstName, updated: updatedFields.firstName });
    }
    if (original.lastName.toUpperCase() !== updatedFields.lastName) {
      differences.lastName = updatedFields.lastName;
      logger.log('Last name difference detected:', { original: original.lastName, updated: updatedFields.lastName });
    }
    if (original.medicareNumber !== updatedFields.medicareNumber) {
      differences.medicareNumber = updatedFields.medicareNumber;
      logger.log('Medicare number difference detected:', {
        original: original.medicareNumber,
        updated: updatedFields.medicareNumber,
      });
    }
    if (original.medicareIRN !== updatedFields.medicareIRN) {
      differences.medicareIRN = updatedFields.medicareIRN;
      logger.log('Medicare IRN difference detected:', {
        original: original.medicareIRN,
        updated: updatedFields.medicareIRN,
      });
    }
    if (original.postcode !== updatedFields.postcode) {
      differences.postcode = updatedFields.postcode;
      logger.log('Postcode difference detected:', { original: original.postcode, updated: updatedFields.postcode });
    }

    // Always include these fields in differences if they exist in AIR data
    if (airData.indigenousStatus) {
      differences.isAboriginal = updatedFields.isAboriginal;
      logger.log('Aboriginal status from AIR:', updatedFields.isAboriginal);
    }
    if (airData.individual.address) {
      differences.address = updatedFields.address;
      logger.log('Address from AIR:', updatedFields.address);
    }

    const result = Object.keys(differences).length > 0 ? { differences, updatedFields } : null;
    logger.log('Final comparison result:', result);
    return result;
  };

  const handleValidate = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsValidating(true);
    logger.log('Starting validation for patient:', patient);

    try {
      const response = await performValidation(patient);
      logger.log('Validation response:', response);

      if (response.data?.errors?.length && response.data.errors.length > 0) {
        logger.log('Found validation errors:', response.data.errors);
        onEditRequest(response.data.errors);
      } else if (response.success && response.data?.individualDetails) {
        const airData = response.data.individualDetails;
        logger.log('AIR data:', airData);

        // Create message about differences
        const differences: Record<string, { original: string; air: string }> = {};

        // Compare firstName (case-insensitive)
        if (
          airData.individual.personalDetails.firstName &&
          patient.firstName.toUpperCase() !== airData.individual.personalDetails.firstName
        ) {
          differences.firstName = {
            original: patient.firstName,
            air: airData.individual.personalDetails.firstName || '',
          };
        }

        // Compare lastName (case-insensitive)
        if (
          airData.individual.personalDetails.lastName &&
          patient.lastName.toUpperCase() !== airData.individual.personalDetails.lastName
        ) {
          differences.lastName = {
            original: patient.lastName,
            air: airData.individual.personalDetails.lastName || '',
          };
        }

        // Compare dateOfBirth
        if (airData.individual.personalDetails.dateOfBirth) {
          const formattedPatientDOB = dayjs(patient.dateOfBirth).format('DDMMYYYY');
          if (formattedPatientDOB !== airData.individual.personalDetails.dateOfBirth) {
            differences.dateOfBirth = {
              original: dayjs(patient.dateOfBirth).format('DD/MM/YYYY'),
              air: dayjs(airData.individual.personalDetails.dateOfBirth, 'DDMMYYYY').format('DD/MM/YYYY'),
            };
          }
        }

        // Compare Medicare Number
        if (
          airData.individual.medicareCard?.medicareCardNumber &&
          patient.medicareNumber !== airData.individual.medicareCard.medicareCardNumber
        ) {
          differences.medicareNumber = {
            original: patient.medicareNumber,
            air: airData.individual.medicareCard.medicareCardNumber,
          };
        }

        // Compare Medicare IRN
        if (
          airData.individual.medicareCard?.medicareIRN &&
          patient.medicareIRN !== airData.individual.medicareCard.medicareIRN
        ) {
          differences.medicareIRN = {
            original: patient.medicareIRN,
            air: airData.individual.medicareCard.medicareIRN,
          };
        }

        // Compare postcode
        if (airData.individual.address?.postCode && patient.postcode !== airData.individual.address.postCode) {
          differences.postcode = {
            original: patient.postcode || 'Not provided',
            air: airData.individual.address.postCode,
          };
        }

        logger.log('Differences object:', differences);

        if (Object.keys(differences).length === 0) {
          // No differences found - update status to confirmed
          logger.log('No differences found, updating status to confirmed');
          await updatePatientStatus();
        } else {
          // Differences found - open edit dialog
          const updatedPatient = {
            firstName: airData.individual.personalDetails.firstName || patient.firstName,
            lastName: airData.individual.personalDetails.lastName || patient.lastName,
            dateOfBirth: airData.individual.personalDetails.dateOfBirth
              ? dayjs(airData.individual.personalDetails.dateOfBirth, 'DDMMYYYY').format('DD/MM/YYYY')
              : patient.dateOfBirth,
            medicareNumber: airData.individual.medicareCard?.medicareCardNumber || patient.medicareNumber,
            medicareIRN: airData.individual.medicareCard?.medicareIRN || patient.medicareIRN,
            postcode: airData.individual.address?.postCode || patient.postcode,
            isAboriginal: airData.indigenousStatus,
            address: airData.individual.address
              ? {
                  line1: airData.individual.address.addressLineOne || '',
                  line2: airData.individual.address.addressLineTwo || '',
                  suburb: airData.individual.address.locality || '',
                }
              : undefined,
          };
          logger.log('Updated patient object:', updatedPatient);

          logger.log('Calling onEditRequest with:', { differences, updatedPatient });
          onEditRequest([], { differences, updatedPatient });
        }
      }
    } catch (error) {
      logger.error('Validation error:', error);
      if (onValidationComplete) {
        onValidationComplete({ success: false, error });
      }
    } finally {
      setIsValidating(false);
    }
  };

  const performValidation = async (patientData: any) => {
    // Process Medicare number and IRN
    let medicareNumber = patientData.medicareNumber;
    let medicareIRN = patientData.medicareIRN;

    if (medicareNumber && medicareNumber.length > 10) {
      const extractedIRN = medicareNumber.slice(-1);
      medicareNumber = medicareNumber.slice(0, 10);
      if (!medicareIRN || medicareIRN === extractedIRN) {
        medicareIRN = extractedIRN;
      }
    }

    medicareIRN = medicareIRN ? medicareIRN.slice(-1) : '';
    const formattedDate = dayjs(patientData.dateOfBirth).format('DD/MM/YYYY').split('/').join('');

    const requestData: IdentifyIndividualRequest = {
      individual: {
        medicareCard: {
          medicareCardNumber: medicareNumber,
          medicareIRN: medicareIRN,
        },
        personalDetails: {
          firstName: patientData.firstName,
          lastName: patientData.lastName,
          dateOfBirth: formattedDate,
        },
        address: patientData.postcode
          ? {
              postCode: patientData.postcode,
            }
          : undefined,
      },
      informationProvider: {
        providerNumber: providerNumber,
      },
      pharmacyID: pharmacyID,
      deviceName: deviceName,
      minorId: minorId,
      prodaOrgId: prodaOrgId,
    };

    return await IdentifyIndividualAIRV2(requestData, idToken);
  };

  return (
    <LoadingButton
      variant="contained"
      size="small"
      onClick={handleValidate}
      loading={isValidating}
      disabled={isConfirmed}
      sx={{
        minWidth: '120px',
        height: '32px',
        ...(isConfirmed && {
          backgroundColor: 'success.main',
          '&:disabled': {
            backgroundColor: 'success.main',
            color: 'white',
          },
        }),
      }}
    >
      {isConfirmed ? 'Confirmed' : 'Validate'}
    </LoadingButton>
  );
};

export default ValidatePatientButton;
