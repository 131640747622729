import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { Alert, Backdrop, Button, CircularProgress, Grid, Snackbar, TextField } from "@mui/material";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { UPDATE_PATIENT_MUTATION } from "../../graphql-queries";
import { calculateAge, formatDateOfBirth } from "./common/transformations";
import { PatientItem } from "./patient";
import { logger } from "../../utils/logger";
dayjs.extend(customParseFormat);

interface PatientDetailsProps {
  patientDetails?: PatientItem;
}

const PatientDetailsForm: React.FC<PatientDetailsProps> = ({ patientDetails }) => {
  const formattedDateOfBirth = patientDetails?.dateOfBirth ? formatDateOfBirth(patientDetails.dateOfBirth) : null;

  const [age, setAge] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [updatePatient, { data, loading, error }] = useMutation(UPDATE_PATIENT_MUTATION);
  const [emailAddress, setEmailAddress] = useState(patientDetails?.emailAddress || "");
  const [mobileNumber, setMobileNumber] = useState(patientDetails?.mobileNumber || "");
  const [ihiNumber, setIhiNumber] = useState(patientDetails?.ihiNumber || "");
  const [medicareCardNumber, setMedicareCardNumber] = useState(patientDetails?.medicareCardNumber || "");
  const [medicareExpiryDate, setMedicareExpiryDate] = useState(patientDetails?.medicareExpiryDate || "");
  const [medicareIRN, setMedicareIRN] = useState(patientDetails?.medicareIRN || "");
  const [phoneNumber, setPhoneNumber] = useState(patientDetails?.phoneNumber || "");
  const [initial, setInitial] = useState(patientDetails?.initial || "");
  useEffect(() => {
    if (patientDetails?.dateOfBirth) {
      const formattedDateOfBirth = formatDateOfBirth(patientDetails.dateOfBirth);
      logger.log("formattedDateOfBirth", formattedDateOfBirth);
      const newAge = calculateAge(formattedDateOfBirth.split("-").reverse().join(""));
      logger.log(newAge);
      setAge(newAge.toString()); // Convert newAge to a string before setting it in the state
    }
  }, [patientDetails?.dateOfBirth]); // Recalculate age when patientDetails.dateOfBirth changes

  const handleUpdate = () => {
    const input = {
      addressLineOne: patientDetails?.addressLineOne || "",
      addressLineTwo: patientDetails?.addressLineTwo || "",
      dateOfBirth: formatDateOfBirth(patientDetails?.dateOfBirth || ""),
      emailAddress: emailAddress || "", // Use the actual state or refs for dynamic values
      firstName: patientDetails?.firstName || "",
      middleName: patientDetails?.middleName || "",
      initial: initial || "",
      gender: patientDetails?.gender || "",
      id: patientDetails?.id || "",
      createdDate: patientDetails?.createdDate || "", // Placeholder for missing createdDate
      mobileNumber: mobileNumber || "",
      phoneNumber: phoneNumber || "",
      ...(ihiNumber && { ihiNumber: ihiNumber }),
      lastName: patientDetails?.lastName || "",
      locality: patientDetails?.locality || "",
      ...(medicareCardNumber && { medicareCardNumber: medicareCardNumber }),
      ...(medicareExpiryDate && { medicareExpiryDate: medicareExpiryDate }),
      ...(medicareIRN && { medicareIRN: medicareIRN }),
      patientID: patientDetails?.patientID || "",
      pharmacistID: patientDetails?.pharmacistID || "", // Placeholder for missing pharmacistID
      pharmacyID: patientDetails?.pharmacyID || "", // Placeholder for missing pharmacyID
      postCode: patientDetails?.postCode || "",
      state: patientDetails?.state || "", // Placeholder for missing state    };
      onlyNameIndicator: patientDetails?.onlyNameIndicator || false,
    };
    logger.log("Input", input);
    updatePatient({ variables: { input: input } })
      .then((response) => {
        logger.log("Update successful", response.data);
        setIsLoading(false); // Stop loading
        setSnackbarMessage("Updated Successfully"); // Set success message
        // sleep for 3 seconds before resetting form
        setOpenSnackbar(true); // Show success message
        // Handle successful update here
      })
      .catch((error) => {
        console.error("Error updating patient", error);
        // Handle errors here
      });
  };

  return (
    <>
      <form>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={8000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={8000}
          onClose={() => setOpenErrorSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: "100%" }}>
            Error creating patient. Please try again.
          </Alert>
        </Snackbar>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            {/* First Name, Last Name, Middle Name */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  defaultValue={patientDetails?.firstName || ""}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  defaultValue={patientDetails?.lastName || ""}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Middle Name"
                  variant="outlined"
                  defaultValue={patientDetails?.middleName || ""}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  fullWidth
                  label="Initials"
                  variant="outlined"
                  defaultValue={patientDetails?.initial || ""}
                  size="small"
                  onChange={(e) => setInitial(e.target.value)} // Update state on change
                // InputProps={{
                //   readOnly: true,
                // }}
                />
              </Grid>
            </Grid>

            {/* Address Line 1 and Address Line 2 */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Address Line 1"
                  variant="outlined"
                  defaultValue={patientDetails?.addressLineOne || ""}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Address Line 2"
                  variant="outlined"
                  defaultValue={patientDetails?.addressLineTwo || ""}
                  size="small"
                />
              </Grid>

              {/* Suburb, State, Postcode */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Suburb"
                  variant="outlined"
                  defaultValue={patientDetails?.locality || ""}
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="State"
                  variant="outlined"
                  defaultValue={patientDetails?.state || ""}
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Postcode"
                  variant="outlined"
                  defaultValue={patientDetails?.postCode || ""}
                  size="small"
                />
              </Grid>
            </Grid>
            {/* Mobile and Phone */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Mobile No."
                  variant="outlined"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)} // Update state on change
                  size="small"
                />{" "}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone No."
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)} // Update state on change
                  size="small"
                />{" "}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)} // Update state on change
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="IHI Number"
                  variant="outlined"
                  value={ihiNumber}
                  onChange={(e) => setIhiNumber(e.target.value)} // Update state on change
                  size="small"
                />
              </Grid>
            </Grid>
            {/* DOB, Age, Sex */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Medicare No."
                variant="outlined"
                defaultValue={patientDetails?.medicareCardNumber || ""}
                size="small"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Medicare IRN"
                variant="outlined"
                defaultValue={patientDetails?.medicareIRN || ""}
                size="small"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Medicare Expiry Date"
                variant="outlined"
                defaultValue={patientDetails?.medicareExpiryDate || ""}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField fullWidth label="DVA" variant="outlined" defaultValue="" size="small" />
            </Grid>
            <Grid item xs={4}>
              <DateField
                label="Date of Birth"
                size="small"
                format="DD/MM/YYYY"
                value={formattedDateOfBirth ? dayjs(formattedDateOfBirth) : null}
                onChange={() => { }}
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Age"
                variant="outlined"
                value={age || ""}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Sex"
                variant="outlined"
                defaultValue={patientDetails?.gender || ""}
                size="small"
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                sx={{ fontSize: "1rem", padding: "10px 40px" }} // Customize button size and padding as needed
              >
                Update Patient
              </Button>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </form>
    </>
  );
};

export default PatientDetailsForm;
