import React, { useState } from 'react';
import { Box, Typography, Button, Avatar, Grid, Paper, Tabs, Tab, ThemeProvider, createTheme } from '@mui/material';
import { User, Syringe, FileText } from 'lucide-react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { PatientFollowUp } from '../types/PatientFollowUp';
import { useCustomer } from '../contexts/CustomerContext';

interface PatientDashboardProps {
  followUps: PatientFollowUp[];
}

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#1976d2',
          fontSize: '0.95rem',
          '&.Mui-selected': {
            color: '#0d47a1',
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#0d47a1',
        },
      },
    },
  },
});

const PatientDashboard: React.FC<PatientDashboardProps> = ({ followUps }) => {
  const {
    state: { customer },
  } = useCustomer();
  const [tabValue, setTabValue] = useState(0);

  const columns: GridColDef[] = [
    { field: 'medicationName', headerName: 'Medication', flex: 1 },
    { field: 'followupDate', headerName: 'Date', flex: 1 },
    { field: 'followupTime', headerName: 'Time', flex: 1 },
    { field: 'duration', headerName: 'Duration', flex: 1 },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: '#37afe2', color: 'white' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar sx={{ width: 50, height: 50, backgroundColor: 'white' }}>
                <User size={30} color="#37afe2" />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" sx={{ color: 'white' }}>
                {`${customer?.firstName || ''} ${customer?.lastName || ''}`}
              </Typography>
              <Typography variant="body2">Date of Birth: {customer?.dateOfBirth || 'N/A'}</Typography>
              <Typography variant="body2">Medicare: {customer?.medicareCardNumber || 'N/A'}</Typography>
            </Grid>
          </Grid>
        </Paper>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="patient dashboard tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{ '& .MuiTab-root': { minHeight: '64px' } }}
        >
          <Tab icon={<Syringe size={24} />} iconPosition="start" label="Vaccinations" />
          <Tab icon={<FileText size={24} />} iconPosition="start" label="Staged Supply" />
          <Tab icon={<User size={24} />} iconPosition="start" label="Patient Info" />
          <Tab icon={<Syringe size={24} />} iconPosition="start" label="Injections" />
          <Tab icon={<FileText size={24} />} iconPosition="start" label="Medications" />
        </Tabs>

        <Box sx={{ flexGrow: 1, display: 'flex', p: 2, gap: 2, overflow: 'hidden' }}>
          <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
              {tabValue === 0 && <Typography>Vaccination Content</Typography>}
              {tabValue === 1 && <DataGridPro rows={followUps} columns={columns} autoHeight={false} />}
              {tabValue === 2 && (
                <Box>
                  <Typography variant="body2">Phone: {customer?.phone || 'N/A'}</Typography>
                  {/* Add more patient details here */}
                </Box>
              )}
              {/* Add content for other tabs */}
            </Box>
          </Paper>

          <Paper elevation={3} sx={{ width: '30%', minWidth: 300, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom sx={{ p: 2 }}>
              Upcoming Sessions
            </Typography>
            <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
              {followUps.slice(0, 5).map((followUp, index) => (
                <Box key={index} sx={{ mb: 2, p: 1, borderLeft: '3px solid #5becc0' }}>
                  <Typography variant="body2" color="textSecondary">
                    {followUp.followupDate} | {followUp.followupTime}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Button variant="text" color="primary" sx={{ alignSelf: 'flex-start', m: 2 }}>
              View All
            </Button>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PatientDashboard;
