import { useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";

export const useAuth = () => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Awaited<
    ReturnType<typeof fetchAuthSession>
  > | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const getSession = async () => {
    try {
      const session = await fetchAuthSession();

      setSession(session);
      return session;
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    session,
    getSession,
    error,
  };
};
