// privacyPolicyContent.ts

import { PrivacyPolicyContent } from './policyTypes';

export const privacyPolicyContent: PrivacyPolicyContent = {
  title: 'Privacy Policy',
  lastUpdated: '2024-05-01',
  sections: [
    {
      title: '1. About this Privacy Policy',
      content: `Dragatron Pty Ltd ("Dragatron", "we", "us", or "our") is committed to protecting your privacy and ensuring the confidentiality and security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of personal information in connection with our pharmacy management software and related services.

This Privacy Policy explains:
- The kinds of personal information we collect and hold
- How we collect and hold your personal information
- The purposes for which we collect, hold, use, and disclose your personal information
- How you can access and seek correction of your personal information
- How you can complain about our handling of your personal information and how we will deal with such a complaint
- Whether we are likely to disclose personal information to overseas recipients`,
    },
    {
      title: '2. What we do',
      content: `Dragatron Pty Ltd provides software solutions for pharmacists:


Our purpose is to enhance the efficiency and effectiveness of pharmacy operations while ensuring the highest standards of patient care and data protection.`,
    },
    {
      title: '3. Our obligations under privacy laws',
      content: `Dragatron is bound by the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) ("Privacy Act"). We are committed to complying with these principles and any other applicable privacy laws in relation to the collection, storage, use, and disclosure of personal information.`,
    },
    {
      title: '4. What is personal information',
      content: `Personal information is defined in the Privacy Act as information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether the information or opinion is true or not, and whether the information or opinion is recorded in a material form or not.

Examples of personal information we may collect include:
- Name, address, and contact details
- Date of birth
- Medicare number
- Health information
- Prescription history
- Appointment details
- Payment information

Sensitive information is a subset of personal information that is given a higher level of protection under the Privacy Act. It includes information or opinion about an individual's racial or ethnic origin, political opinions, religious beliefs, sexual orientation, health information, and more.`,
    },
    {
      title: '5. Collection of your personal information',
      content: `We collect personal information through various channels, including:
- Our software applications
- Our website
- Email, phone, and written communications
- In-person interactions at pharmacies using our software

When collecting personal information, we take reasonable steps to notify you about the purposes for which we are collecting the information, the main consequences if you do not provide the information, our identity and contact details, and your rights to access and correct your personal information.

In some cases, we may collect personal information about you from third parties, such as healthcare providers, other pharmacies, or government agencies. We only do this where you have consented, where we are required or authorized by law to do so, or where it is unreasonable or impracticable to collect the information directly from you.`,
    },
    {
      title: '6. Remaining anonymous or using a pseudonym',
      content: `Where practicable, we will give you the option of interacting with us anonymously or by using a pseudonym. However, this may not be possible for most of our services due to the nature of pharmacy operations and health-related services.`,
    },
    {
      title: '7. Use and disclosure of your personal information',
      content: `We will only use or disclose your personal information for the primary purpose for which it was collected unless an exception applies under the Privacy Act. These exceptions include:
- You have consented to a secondary use or disclosure
- You would reasonably expect us to use or disclose your personal information for a secondary purpose that is related to the primary purpose
- The use or disclosure is required or authorized by or under an Australian law or a court/tribunal order
- A permitted general situation exists (e.g., to lessen or prevent a serious threat to life, health or safety)
- We reasonably believe that the use or disclosure is reasonably necessary for one or more enforcement related activities conducted by, or on behalf of, an enforcement body`,
    },
    {
      title: '8. Purposes for which we may collect, use or disclose your personal information',
      content: `We may collect, use, and disclose your personal information for the following purposes:
- Providing and improving our pharmacy management software and related services
- Processing and managing prescriptions and medication orders
- Scheduling and managing appointments
- Conducting health monitoring and follow-ups
- Generating reports for pharmacy management and regulatory compliance
- Billing and payment processing
- Communicating with you about your health and pharmacy-related matters
- Conducting research and statistical analysis (in a de-identified form)
- Complying with legal and regulatory obligations
- Responding to your inquiries or complaints
- Protecting our legal rights and preventing misuse of our services`,
    },
    {
      title: '9. Data linkage and integration',
      content: `We may create new datasets by linking data from different sources, including data collected from pharmacies using our software. This data linkage may involve de-identified information or your personal information. We will only engage in data linking in accordance with the Privacy Act and for purposes including:
- Improving our services
- Conducting research and statistical analysis
- Identifying trends in health and pharmacy management
- Assisting with public health initiatives

Any data linkage projects will be conducted in compliance with relevant privacy laws and ethical guidelines.`,
    },
    {
      title: '10. Disclosure of your personal information overseas',
      content: `We do not routinely disclose personal information to overseas recipients. However, in some cases, we may need to disclose personal information to overseas recipients, such as:
- When required by law
- With your consent
- To service providers that assist us in providing our services

If we disclose your personal information to overseas recipients, we will take reasonable steps to ensure that the overseas recipient does not breach the APPs in relation to the information.`,
    },
    {
      title: '11. Storage of your personal information',
      content: `We take reasonable steps to protect your personal information from misuse, interference, loss, and unauthorized access, modification, or disclosure. These steps include:
- Using secure, encrypted servers to store personal information
- Implementing access controls for our systems and databases
- Regular security audits and updates
- Employee training on privacy and data protection
- Physical security measures for our offices

We retain personal information only for as long as necessary to fulfill the purposes for which it was collected, or as required by law. When personal information is no longer needed, we take reasonable steps to destroy or permanently de-identify it.`,
    },
    {
      title: '12. Access and correction',
      content: `You have the right to request access to the personal information we hold about you and to request corrections if you believe the information is inaccurate, out of date, incomplete, irrelevant, or misleading.

To request access to or correction of your personal information, please contact us using the details provided at the end of this policy. We will respond to your request within a reasonable timeframe (usually within 30 days).

In some cases, we may refuse to provide access or make corrections to personal information as permitted by the Privacy Act. If we do so, we will provide you with written reasons for the refusal and information on how you can complain about the refusal.`,
    },
    {
      title: '13. The Notifiable Data Breaches Scheme',
      content: `We comply with the Notifiable Data Breaches (NDB) scheme under Part IIIC of the Privacy Act. If we experience a data breach that is likely to result in serious harm to individuals whose personal information is involved in the breach, we will:
1. Contain the data breach to prevent further compromise of personal information
2. Assess the data breach and determine whether serious harm is likely
3. Notify individuals and the Office of the Australian Information Commissioner (OAIC) if required
4. Review the incident and take action to prevent future breaches`,
    },
    {
      title: '14. Privacy Impact Assessments',
      content: `We conduct Privacy Impact Assessments (PIAs) for any new projects or substantial changes to existing projects that involve the collection, use, or disclosure of personal information. PIAs help us identify and manage privacy risks associated with our activities.`,
    },
    {
      title: '15. Complaints',
      content: `If you believe that we have breached the APPs or mishandled your personal information, you can make a complaint by contacting us using the details provided at the end of this policy. We will investigate your complaint and respond to you in writing within a reasonable time (usually within 30 days).

If you are not satisfied with our response, you can complain to the OAIC. The OAIC can be contacted at:

Office of the Australian Information Commissioner
GPO Box 5218
Sydney NSW 2001
Phone: 1300 363 992
Email: enquiries@oaic.gov.au`,
    },
    {
      title: '16. How to contact us',
      content: `If you have any questions about this Privacy Policy or our handling of your personal information, please contact us at:

Dragatron Pty Ltd
36/7 Narabang way, Belrose, NSW 2085
Email: support@dragatron.com.au
Phone: 0406213088

This Privacy Policy was last updated on 01 May 2024. We may change this Privacy Policy from time to time. Any updated versions of this Privacy Policy will be posted on our website.`,
    },
  ],
};
