export const GENDERS = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Prefer not to say",
    value: "Prefer not to say",
  },
];
