import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Paper,
  Link,
  Stepper,
  Step,
  StepLabel,
  Grid,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { AlertCircle, CheckCircle, Circle } from 'lucide-react';

export type VaccinationScreeningInput = {
  isUnwellToday: boolean;
  hasImmunityLoweringDisease: boolean;
  receivingImmunityLoweringTreatment: boolean;
  isInfantOfMotherWithImmunosuppressiveTherapy: boolean;
  hadSevereReactionToVaccine: boolean;
  hasSevereAllergies: boolean;
  receivedVaccineInPastMonth: boolean;
  receivedImmunoglobulinOrBloodProduct: boolean;
  isPregnant: boolean;
  hasGuillainBarreSyndrome: boolean;
  wasPretermInfant: boolean;
  hasSevereOrChronicIllness: boolean;
  hasBleedingDisorder: boolean;
  isAboriginalOrTorresStraitIslander: boolean;
  doesNotHaveFunctioningSpleen: boolean;
  planningPregnancyOrParenthood: boolean;
  isParentGrandparentOrCarerOfInfant: boolean;
  livesWithPersonWithImmunityLoweringCondition: boolean;
  planningTravel: boolean;
  hasOccupationOrLifestyleRequiringVaccination: boolean;
};

interface VaccinationAIDProps {
  onComplete?: (answers: VaccinationScreeningInput) => void;
  initialAnswers?: VaccinationScreeningInput;
  readOnly?: boolean;
}

const questions: Array<[keyof VaccinationScreeningInput, string]> = [
  ['isUnwellToday', 'Are you unwell today?'],
  ['hasImmunityLoweringDisease', 'Do you have a disease that lowers immunity (e.g., leukaemia, cancer, HIV)?'],
  [
    'receivingImmunityLoweringTreatment',
    'Are you currently receiving treatment that lowers immunity (e.g., oral steroid medicines like cortisone and prednisone, DMARDs, other biologics/monoclonal antibodies, radiotherapy, chemotherapy)?',
  ],
  [
    'isInfantOfMotherWithImmunosuppressiveTherapy',
    'Is the person to be vaccinated an infant of a mother who received highly immunosuppressive therapy during pregnancy?',
  ],
  ['hadSevereReactionToVaccine', 'Have you had a severe reaction following any vaccine?'],
  ['hasSevereAllergies', 'Do you have any severe allergies (to anything)?'],
  ['receivedVaccineInPastMonth', 'Have you received any vaccine in the past month?'],
  [
    'receivedImmunoglobulinOrBloodProduct',
    'Have you had an injection of immunoglobulin or received any blood products or a whole-blood transfusion within the past year?',
  ],
  ['isPregnant', 'Are you pregnant?'],
  ['hasGuillainBarreSyndrome', 'Do you have a history of Guillain-Barré syndrome?'],
  ['wasPretermInfant', 'Were you a preterm infant?'],
  ['hasSevereOrChronicIllness', 'Do you have a severe or chronic illness?'],
  ['hasBleedingDisorder', 'Do you have a bleeding disorder?'],
  ['isAboriginalOrTorresStraitIslander', 'Do you identify as an Aboriginal or Torres Strait Islander person?'],
  ['doesNotHaveFunctioningSpleen', 'Do you not have a functioning spleen?'],
  ['planningPregnancyOrParenthood', 'Are you planning a pregnancy or anticipating parenthood?'],
  ['isParentGrandparentOrCarerOfInfant', 'Are you a parent, grandparent, or carer of an infant ≤6 months of age?'],
  [
    'livesWithPersonWithImmunityLoweringCondition',
    'Do you live with someone who has a disease that lowers immunity or someone who is receiving treatment that lowers immunity?',
  ],
  ['planningTravel', 'Are you planning travel?'],
  [
    'hasOccupationOrLifestyleRequiringVaccination',
    'Do you have an occupation or lifestyle factor(s) for which vaccination may be needed?',
  ],
];

const steps = ['Personal Health', 'Medical History', 'Lifestyle Factors'];

const VaccinationAID: React.FC<VaccinationAIDProps> = ({ onComplete, initialAnswers, readOnly = false }) => {
  const [answers, setAnswers] = useState<Partial<VaccinationScreeningInput>>(initialAnswers || {});

  useEffect(() => {
    if (initialAnswers) {
      setAnswers(initialAnswers);
    }
  }, [initialAnswers]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, question: keyof VaccinationScreeningInput) => {
    const value = event.target.value;
    setAnswers((prev) => ({
      ...prev,
      [question]: value === '' ? undefined : value === 'yes',
    }));
  };

  const isComplete = Object.keys(answers).length === questions.length;
  const progress = (Object.keys(answers).length / questions.length) * 100;

  const handleSubmit = () => {
    if (isComplete && onComplete) {
      onComplete(answers as VaccinationScreeningInput);
    }
  };

  const renderIcon = (answer: boolean | undefined, question: string) => {
    if (answer === undefined) return <Circle size={20} color="#757575" />;

    const requiresAttention = answer && question !== 'Are you planning travel?';

    if (requiresAttention) {
      return (
        <Tooltip title="This answer may require further discussion">
          <AlertCircle size={20} color="#FFA500" />
        </Tooltip>
      );
    }

    return <CheckCircle size={20} color="#4CAF50" />;
  };

  const renderQuestions = () => {
    return questions.map(([key, question], index) => (
      <Box key={key} id={`question-${index}`} sx={{ mb: 1, py: 1, borderBottom: '1px solid #e0e0e0' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
          <Box
            sx={{
              flexShrink: 0,
              width: 24,
              height: 24,
              mr: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {renderIcon(answers[key], question)}
          </Box>
          <Typography variant="body2" sx={{ flex: 1 }}>
            {question}
          </Typography>
        </Box>
        {!readOnly && (
          <Box sx={{ pl: '32px' }}>
            <RadioGroup
              row
              value={answers[key] === undefined ? '' : answers[key] ? 'yes' : 'no'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, key)}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: '#757575',
                      '&.Mui-checked': { color: '#757575' },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: '#757575',
                      '&.Mui-checked': { color: '#757575' },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </Box>
        )}
        {readOnly && (
          <Box sx={{ pl: '32px' }}>
            <Typography variant="body2">
              {answers[key] === undefined ? 'Not answered' : answers[key] ? 'Yes' : 'No'}
            </Typography>
          </Box>
        )}
      </Box>
    ));
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 3, mt: 0, position: 'relative' }}>
      {!readOnly && (
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'background.paper',
            pb: 2,
          }}
        >
          <LinearProgress variant="determinate" value={progress} sx={{ mb: 2 }} />
          <Stepper activeStep={-1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={readOnly ? 12 : 8}>
          {renderQuestions()}
        </Grid>
        {!readOnly && (
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: 'sticky',
                top: '120px',
                zIndex: 1,
              }}
            >
              <Paper elevation={2} sx={{ p: 2, bgcolor: 'info.light', color: 'info.contrastText' }}>
                <Typography variant="h6" gutterBottom>
                  Assessment Progress
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {Object.keys(answers).length} of {questions.length} questions answered
                </Typography>
                <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
                <Typography variant="body2">Complete all questions to submit the assessment.</Typography>
              </Paper>
            </Box>
          </Grid>
        )}
      </Grid>
      {!readOnly && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isComplete}
          >
            Submit Assessment
          </Button>
        </Box>
      )}
      <Box mt={1}>
        <Typography variant="caption">
          Source:{' '}
          <Link
            href="https://immunisationhandbook.health.gov.au/resources/tables/table-pre-vaccination-screening-checklist"
            target="_blank"
            rel="noopener"
          >
            Australian Immunisation Handbook
          </Link>
        </Typography>
      </Box>
    </Paper>
  );
};

export default VaccinationAID;
