// VaccineRecordForm.tsx
import { useLazyQuery } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Dayjs } from "dayjs";
import React, { ChangeEvent, useEffect, useState } from "react";
import { GET_VACCINE_DATA } from "../../graphql-queries";
import { GraphQLVaxListResponse } from "./Vaccine";
import { logger } from "../../utils/logger";


interface VaccineOption {
  label: string; // This will store the VaccineBrandName
  value: string; // This will store the VaccineCode
  isEligibleForNIP?: boolean; // Add this line
}
interface FormValues {
  immunisationDate: Dayjs | null; // Adjusted to match expected type, assuming Dayjs is used
  infectiousDisease: string;
  vaccine: string;
  vaccineBrandName: string; // Add this line
  routeOfAdministration: string;
  siteOfAdministration: string;
  dose: string;
  batchNumber: string;
  expiryDate: string;
  doseNumber: string;
  vaccineFundingType: string;
  comments: string;
  idealNextDoseDate: string;
  cmiProvided: boolean;
  deemedEligible: boolean;
  VaccineSerialNumber: string;
  countryCode: string;
  SchoolId: string;
  otherProviderNumber: string;
  isOverseas: boolean;
  isOtherProvider: boolean;
  filteredVaccines: VaccineOption[]; // Update the type here as well
  otherDiseases: VaccineOption[]; // Add this line
  status: string;
  nipClaim: boolean; // Added this line
  // Add other fields as necessary
}

interface VaccineRecordFormProps {
  index: number;
  formValues: FormValues;
  setFormValues: (newFormValues: FormValues) => void; // Make sure this matches the function you pass in
  removeForm: () => void;
  handleFormUpdate: (index: number, updatedFormValues: FormValues) => void; // Added prop for the update function
}

const diseases = ["COVID-19", "Influenza", "Others"];
const routeOptions = [
  { label: "Oral", code: "PO" },
  { label: "Subcutaneous", code: "SC" },
  { label: "Intradermal", code: "ID" },
  { label: "Intramuscular", code: "IM" },
];
const siteOptions = [
  { label: "Left Arm", code: "LA" },
  { label: "Right Arm", code: "RA" },
  { label: "Left Thigh", code: "LT" },
  { label: "Right Thigh", code: "RT" },
];

const vaccineFundingTypes = [
  { label: "Antenatal", code: "AEN" },
  { label: "NIP/Commonwealth", code: "NIP" },
  { label: "Private", code: "PRV" },
  { label: "State Program", code: "STP" },
];

const VaccineRecordForm: React.FC<VaccineRecordFormProps & { errorInfo?: any }> = ({
  index,
  formValues,
  setFormValues,
  handleFormUpdate,
  removeForm,
  errorInfo,
}) => {
  const [filteredVaccines, setFilteredVaccines] = useState<VaccineOption[]>([]);
  const [isOverseas, setIsOverseas] = useState(false);
  const [showDiseaseDropdown, setShowDiseaseDropdown] = useState(false);
  const [fetchDiseases, { data, loading, error }] = useLazyQuery<GraphQLVaxListResponse>(GET_VACCINE_DATA);
  const [otherDiseases, setOtherDiseases] = useState<VaccineOption[]>([]);
  const [isOtherProvider, setIsOtherProvider] = useState(false);

  const handleOverseasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOverseas(event.target.checked);
  };
  useEffect(() => {
    fetchDiseases();
  }, [fetchDiseases]);
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedFormValues = { ...formValues, [name]: value };
    setFormValues(updatedFormValues);
    handleFormUpdate(index, updatedFormValues); // Notify parent component

    // setFormValuesCallback(index, updatedFormValues); // Assuming you have passed the callback as setFormValuesCallback
  };

  const handleDateChange = (name: string) => (date: Dayjs | null) => {
    const updatedFormValues = {
      ...formValues,
      [name]: date,
    };
    // Pass this object directly to setFormValues
    setFormValues(updatedFormValues);
  };

  const hanldeOtherProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOtherProvider(event.target.checked);
  };

  const handleOtherDiseaseChange = (event: SelectChangeEvent<string>) => {
    const selectedDisease = event.target.value as string;
    const updatedFormValues = {
      ...formValues,
      infectiousDisease: selectedDisease,
    };
    // Pass this object directly to setFormValues
    setFormValues(updatedFormValues);
    // Ensure the correct disease is being passed to the filter function
    filterVaccines(selectedDisease);
  };

  const filterVaccines = (disease: string) => {
    if (data && data.listVaxDataAIRS && data.listVaxDataAIRS.items) {
      const vaccinesForDisease = data.listVaxDataAIRS.items.filter((item) => item.DiseaseAntigenName === disease);
      const vaccineOptions: VaccineOption[] = vaccinesForDisease.map((vaccine) => ({
        label: vaccine.VaccineBrandName,
        value: vaccine.VaccineCode,
        isEligibleForNIP: vaccine.IsEligibleForNIP as boolean, // Ensure this information is included
      }));
      setFilteredVaccines(vaccineOptions);
    }
  };
  const handleDiseaseSelection = (disease: string) => {
    setShowDiseaseDropdown(false); // Always hide the dropdown initially
    setFormValues({ ...formValues, infectiousDisease: disease }); // Update the disease selection

    if (disease === "Others") {
      // Logic for "Others" remains the same
      logger.log("Show dropdown for other diseases");
      setShowDiseaseDropdown(true);
      setFilteredVaccines([]);
      if (data && data.listVaxDataAIRS && data.listVaxDataAIRS.items) {
        const otherDiseasesOptions = data.listVaxDataAIRS.items
          .filter((item) => item.DiseaseAntigenName !== "COVID-19" && item.DiseaseAntigenName !== "Influenza")
          .map((vaccine) => ({
            label: vaccine.DiseaseAntigenName,
            value: vaccine.DiseaseAntigenName,
            isEligibleForNIP: vaccine.IsEligibleForNIP as boolean, // Ensure this information is included
          }))
          .filter((value, index, self) => self.findIndex((v) => v.label === value.label) === index);

        setOtherDiseases(otherDiseasesOptions);
      }
    } else {
      // Reset otherDiseases to ensure dropdown is not populated when not needed
      setOtherDiseases([]);
      if (data && data.listVaxDataAIRS && data.listVaxDataAIRS.items) {
        let filteredVaccines = data.listVaxDataAIRS.items
          .filter((item) => item.DiseaseAntigenName === disease)
          .map((vaccine) => ({
            label: vaccine.VaccineBrandName,
            value: vaccine.VaccineCode,
            isEligibleForNIP: vaccine.IsEligibleForNIP as boolean, // Ensure this information is included
          }));
        setFilteredVaccines(filteredVaccines);
      }
    }
  };
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
        style={{ backgroundColor: errorInfo ? red[50] : "inherit" }} // This line changes the background color conditionally based on an error
      >
        <Typography>Vaccine Record {index + 1}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {errorInfo && (
          // Display the error if errorInfo exists
          <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
            {errorInfo} {/* Make sure you're displaying errorInfo or errorInfo.message based on your structure */}
          </Alert>
        )}
        <Grid container spacing={0.5}>
          <FormControl fullWidth margin="normal" variant="outlined" size="small">
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: 0,
                fontWeight: "bold",
              }}
            >
              {diseases.map((disease) => (
                <Button
                  key={disease}
                  variant={
                    formValues.infectiousDisease === disease || (disease === "Others" && showDiseaseDropdown)
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleDiseaseSelection(disease)}
                  sx={{
                    textTransform: "none",
                    minWidth: "100px",
                    flex: 1,
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  {disease}
                </Button>
              ))}
            </Box>
            {showDiseaseDropdown && (
              <FormControl fullWidth margin="normal" variant="outlined" size="small">
                <InputLabel id="other-diseases-label">Other Diseases</InputLabel>
                <Select
                  labelId="other-diseases-label"
                  name="infectiousDisease"
                  value={formValues.infectiousDisease}
                  onChange={handleOtherDiseaseChange}
                  label="Other Diseases"
                >
                  {otherDiseases.map((disease) => (
                    <MenuItem key={disease.value} value={disease.value}>
                      {disease.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined" size="small">
            <InputLabel id="vaccine-label">Vaccines</InputLabel>
            <Select
              labelId="vaccine-label"
              name="vaccine"
              value={formValues.vaccine || ""}
              onChange={handleChange}
              label="Vaccine label"
            >
              {filteredVaccines.map((vaccine) => (
                <MenuItem key={vaccine.value} value={vaccine.value}>
                  {vaccine.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined" size="small">
            <InputLabel id="route-admin-label">Route of Administration</InputLabel>
            <Select
              labelId="route-admin-label"
              id="routeOfAdministration"
              name="routeOfAdministration"
              value={formValues.routeOfAdministration || ""}
              onChange={handleChange}
              label="Route of Administration"
            >
              {routeOptions.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined" size="small">
            <InputLabel id="site-admin-label">Site of Administration</InputLabel>
            <Select
              labelId="site-admin-label"
              id="siteOfAdministration"
              name="siteOfAdministration"
              value={formValues.siteOfAdministration || ""}
              onChange={handleChange}
              label="Site of Administration"
            >
              {siteOptions.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Batch Number"
            type="text"
            name="batchNumber"
            size="small"
            value={formValues.batchNumber || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Vaccine Dose"
            type="text"
            name="dose"
            size="small"
            value={formValues.dose || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Expiry Date (DD/MM/YYYY or MM/YYYY)"
            type="text"
            name="expiryDate"
            size="small"
            value={formValues.expiryDate || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          {/* <DateField
                label="Expiry Date"
                size="small"
                format="MM/YYYY"
                // onChange={(newValue) => setValue(value)}
                fullWidth
              /> */}
          <TextField
            label="Vaccine Serial Number"
            type="text"
            name="VaccineSerialNumber"
            size="small"
            value={formValues.VaccineSerialNumber || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal" variant="outlined" size="small" required>
            <InputLabel id="funding-type-label">Vaccine Funding Type</InputLabel>
            <Select
              labelId="funding-type-label"
              id="vaccineFundingType"
              name="vaccineFundingType"
              value={formValues.vaccineFundingType || ""}
              onChange={handleChange}
              label="Vaccine Funding Type"
            >
              {vaccineFundingTypes.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField
            label="School Id"
            type="text"
            name="SchoolId"
            size="small"
            value={formValues.SchoolId || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          /> */}
          {/* <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Switch checked={isOverseas} onChange={handleOverseasChange} />}
              label="Administered Overseas"
              labelPlacement="end"
            />
          </FormGroup>
          {isOverseas && (
            <Autocomplete
              options={countryCodes}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Country" margin="normal" size="small" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                setFormValues({
                  ...formValues,
                  countryCode: newValue?.code ?? "",
                });
              }}
              fullWidth
            />
          )}
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Switch checked={isOtherProvider} onChange={hanldeOtherProvider} />}
              label="Administered By Other Provider"
              labelPlacement="end"
            />
            {isOtherProvider && (
              <TextField
                label="Other Provider Number"
                type="text"
                name="otherProviderNumber"
                size="small"
                value={formValues.otherProviderNumber || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            )}
          </FormGroup> */}
        </Grid>
        <Button onClick={removeForm}>Remove</Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default VaccineRecordForm;
