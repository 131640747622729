import { format } from 'date-fns';
import { logger } from './logger';
import moment from 'moment';

export const formatDateRange = (dateRange: string) => {
  const [start, end] = dateRange.split(' to ');
  const formatDate = (date: string) => format(new Date(date), 'dd/MM/yyyy');
  if (start === end) {
    return `on ${formatDate(start)}`;
  } else {
    return `for the period commencing ${formatDate(start)} until ${formatDate(end)}`;
  }
};

export const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const getHourFromTime = (time: string, ampm?: boolean) => {
  if (!time) return 0;

  const [hour, minute] = time.split(':');

  if (ampm) return Number(hour);

  const momentTime = moment().set('hour', Number(hour));

  const [_24HourFormatHour, _] = momentTime.format('HH:mm').split(':');

  return Number(_24HourFormatHour);
};

export const getMinuteFromTime = (time: string) => {
  if (!time) return 0;

  const [hour, minute] = time.split(':');
  const [minuteVlue, meridiem] = minute.split(' ');

  return Number(minuteVlue);
};
