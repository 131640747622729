import React from 'react';

// Define the PPA type
interface PPA {
  userId: string;
  userKey: string;
  providerIdPPA: number;
  programName: string;
  userKeyExpiry: string;
  HMR: number;
  Medscheck: number;
  CVCP: number;
  RMMR: number;
  ODT: number;
  NIPVIP: number;
}

// Define an interface for your context's value
// Define the AuthContextType
export interface AuthContextType extends AuthDetailsType {
  setAuthDetails: (details: Partial<AuthDetailsType>) => void;
}

// Define the AuthDetailsType
export interface AuthDetailsType {
  providerNumber: string;
  providerFirstName: string;
  providerLastName: string;
  providerStore: string;
  pharmacistID: string;
  pharmacyID: string;
  pharmacyName: string;
  hpioNumber: string;
  hpiiNumber: string;
  isAdministrator: boolean;
  deviceName: string;
  minorId: string;
  isPPAEligible: boolean;
  providerAHPRA: string;
  providerEmail: string;
  prodaOrgId: string;
  PPA?: PPA;
  isSubscribed?: boolean;
  planName?: string;
  pharmacyState?: string;
}

// Define the AuthContextType
export interface AuthContextType extends AuthDetailsType {
  setAuthDetails: (details: Partial<AuthDetailsType>) => void;
}

// Provide a default value matching the interface
const AuthContext = React.createContext<AuthContextType>({
  providerNumber: '',
  providerFirstName: '',
  providerLastName: '',
  providerStore: '',
  pharmacistID: '',
  pharmacyID: '',
  hpioNumber: '',
  hpiiNumber: '',
  pharmacyName: '',
  isAdministrator: false,
  deviceName: '',
  minorId: '',
  isPPAEligible: false,
  providerAHPRA: '',
  providerEmail: '',
  prodaOrgId: '',
  PPA: undefined,
  isSubscribed: false,
  planName: '',
  pharmacyState: '',
  setAuthDetails: () => {},
});

export default AuthContext;
