import React, { useState } from 'react';
import { Paper, Typography, CircularProgress, useTheme, useMediaQuery, Box } from '@mui/material';
import { LocalizationProvider, StaticDatePicker, PickersDay, StaticDatePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Appointment } from './types';

interface AppointmentCalendarProps {
  appointments: Appointment[];
  isLoading: boolean;
  onDateSelect: (date: Dayjs | null) => void;
  handleOnMonthChange: StaticDatePickerProps<Dayjs>['onMonthChange'];
}

const AppointmentCalendar: React.FC<AppointmentCalendarProps> = ({
  appointments,
  isLoading,
  onDateSelect,
  handleOnMonthChange,
}) => {
  const [currentDate, setCurrentDate] = useState<Dayjs | null>(dayjs());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const hasAppointment = (date: Dayjs) => {
    return appointments.some((apt) => dayjs(apt.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'));
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    setCurrentDate(newValue);
    onDateSelect(newValue);
  };

  return (
    <Paper
      sx={{
        p: { xs: 1, sm: 2 },
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: { xs: '100%', sm: '350px', md: '400px' },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: '#05153f',
          mb: 2,
          bgcolor: '#e2f1f0',
          p: 1,
          borderRadius: '5px',
          fontSize: { xs: '1rem', sm: '1.25rem' },
        }}
      >
        Schedule & Appointments
      </Typography>
      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            renderLoading={() => <CircularProgress />}
            loading={isLoading}
            displayStaticWrapperAs="desktop"
            value={currentDate}
            onChange={handleDateChange}
            onMonthChange={handleOnMonthChange}
            slots={{
              day: (props) => (
                <PickersDay
                  {...props}
                  sx={{
                    position: 'relative',
                    '&::after': hasAppointment(props.day) ? {
                      content: '""',
                      position: 'absolute',
                      top: '2px',
                      left: '2px',
                      right: '2px',
                      bottom: '2px',
                      border: '2px solid #5becc0',
                      borderRadius: '50%',
                      pointerEvents: 'none',
                    } : {},
                    '&:hover': {
                      backgroundColor: 'rgba(91, 236, 192, 0.1)',
                    },
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                    padding: { xs: '0.25rem', sm: '0.5rem' },
                  }}
                />
              ),
            }}
            sx={{
              width: '100%',
              '& .MuiPickersCalendarHeader-root': {
                paddingLeft: { xs: '0.5rem', sm: '1rem' },
                paddingRight: { xs: '0.5rem', sm: '1rem' },
              },
              '& .MuiDayPicker-header': {
                justifyContent: 'space-around',
              },
              '& .MuiDayPicker-weekContainer': {
                justifyContent: 'space-around',
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </Paper>
  );
};

export default AppointmentCalendar;
