import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  verifyUser,
  setRegistrationStep,
  completeStep,
  setAuthenticationStatus,
} from "../../redux/actions";
import { RootState } from "../../redux/store";
import AuthLayout from "./AuthLayout";
import {
  Typography,
  Box,
  TextField,
  Button,
  Container,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import mailSent from "../../assets/images/mail_sent.png";
import Copyright from "../../components/Copyright";
import CircularProgress from "@mui/material/CircularProgress";
const Confirm2 = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(120);
  const [localError, setLocalError] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => Math.max(prevTimeLeft - 1, 0));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleVerifyOTP = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLocalError("");
    try {
      dispatch(verifyUser(email, otp));
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Verification failed due to an unknown error";
      console.error("Verification failed:", error);
      setLocalError(errorMessage);
    }
  };

  const {
    loading,
    userVerified,
    error: reduxError,
  } = useSelector((state: RootState) => ({
    loading: state.Auth.loading,
    error: state.Auth.error,
    userVerified: state.Auth.userVerified,
  }));

  useEffect(() => {
    if (userVerified) {
      dispatch(completeStep(2));
      dispatch(setRegistrationStep(3));
      dispatch(setAuthenticationStatus(true));
      navigate("/register/step-3", { state: { email: email } });
    } else if (reduxError) {
      if (reduxError instanceof Error) {
        setLocalError(reduxError.message);
      }
    }
  }, [dispatch, userVerified, reduxError, email, navigate]);

  return (
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            {t("Verify OTP")}
          </Typography>
          <Box component="form" onSubmit={handleVerifyOTP} sx={{ mt: 1 }}>
            <Stack spacing={2} alignItems="center">
              <img src={mailSent} alt="mail sent" height="64" />
              <Typography>
                {t("We have sent you One Time Password to your email")} {email}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                label={t("OTP")}
                autoFocus
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                error={Boolean(localError)}
                helperText={localError || ""}
              />
              <Typography>
                {t("Time left:")}{" "}
                {`${Math.floor(timeLeft / 60)}:${`0${timeLeft % 60}`.slice(
                  -2
                )}`}
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
              >
                {loading ? (
                  <CircularProgress size={24} /> // Show loading indicator when loading
                ) : (
                  "Verify OTP"
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </AuthLayout>
  );
};

export default Confirm2;
