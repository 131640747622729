import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import { logger } from "../utils/logger";
interface PharmacyContextProps {
  pharmacyData: any;
  loading: boolean;
  error: string | null;
  id: string | null;
  pharmacyId: string | null;
  providerNumber: string | null;
  userId: string | null;
  userKey: string | null;
  isActive: boolean;
  createdDate: string | null;
  token: string | null;
  userKeyExpiry: string | null;
  isAdministrator: boolean;
  providerIdPPA: number | null;
  businessNamePPA: string | null;
  providerTypePPA: string | null;
  providerAddressPPA: string | null;
  providerAddress2PPA: string | null;
  providerSuburbPPA: string | null;
  providerPostcodePPA: string | null;
  HMR: string | null;
  Medscheck: string | null;
  RMMR: string | null;
  CVCP: string | null;
  ODT: string | null;
  NIPVIP: string | null;
}

const PharmacyContext = createContext<PharmacyContextProps | undefined>(
  undefined
);

export const PharmacyProvider: React.FC = ({ children }) => {
  const [pharmacyData, setPharmacyData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pharmacyState, setPharmacyState] = useState<PharmacyContextProps>({
    pharmacyData: null,
    loading: false,
    error: null,
    id: null,
    pharmacyId: null,
    providerNumber: null,
    userId: null,
    userKey: null,
    isActive: false,
    createdDate: null,
    token: null,
    userKeyExpiry: null,
    isAdministrator: false,
    providerIdPPA: null,
    businessNamePPA: null,
    providerTypePPA: null,
    providerAddressPPA: null,
    providerAddress2PPA: null,
    providerSuburbPPA: null,
    providerPostcodePPA: null,
    HMR: null,
    Medscheck: null,
    RMMR: null,
    CVCP: null,
    ODT: null,
    NIPVIP: null,
  });

  const { pharmacyID } = useContext(AuthContext);

  useEffect(() => {
    logger.log("🚀 ~ pharmacyID:", pharmacyID);

    const fetchPharmacyData = async () => {
      if (pharmacyID) {
        setLoading(true);
        try {
          const apiURL = process.env.REACT_APP_GET_PHARMACY_PPAID;
          if (!apiURL) {
            throw new Error("REACT_APP_GET_PHARMACY_PPAID is not defined");
          }
          const response = await axios.post(apiURL, {
            operation: "QUERY_PHARMACY_PPAS_BY_PHARMACY_ID_INDEX",
            variables: {
              pharmacyId: pharmacyID,
            },
          });

          logger.log("🚀 ~ fetchPharmacyData:", response.data);

          const items =
            response.data.data?.queryPharmacyPPASByPharmacyIdIndex?.items || [];
          if (items.length > 0) {
            const item = items[0];
            setPharmacyState({
              pharmacyData: item,
              loading: false,
              error: null,
              ...item,
            });
            setPharmacyData(item);
          } else {
            setPharmacyState({
              pharmacyData: null,
              loading: false,
              error: null,
              id: null,
              pharmacyId: null,
              providerNumber: null,
              userId: null,
              userKey: null,
              isActive: false,
              createdDate: null,
              token: null,
              userKeyExpiry: null,
              isAdministrator: false,
              providerIdPPA: null,
              businessNamePPA: null,
              providerTypePPA: null,
              providerAddressPPA: null,
              providerAddress2PPA: null,
              providerSuburbPPA: null,
              providerPostcodePPA: null,
              HMR: null,
              Medscheck: null,
              RMMR: null,
              CVCP: null,
              ODT: null,
              NIPVIP: null,
            });
            setPharmacyData(null);
          }
        } catch (err) {
          setError("Failed to fetch pharmacy data");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPharmacyData();
  }, [pharmacyID]);

  return (
    <PharmacyContext.Provider
      value={{ ...pharmacyState, pharmacyData, loading, error }}
    >
      {children}
    </PharmacyContext.Provider>
  );
};

export default PharmacyContext;
