import React from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
} from "@mui/material";

const OrDivider = () => (
  <Grid
    item
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <Typography variant="h5" color="textSecondary">
      OR
    </Typography>
  </Grid>
);

const IdentificationForm = () => {
  return (
    <Container maxWidth="lg">
      <form noValidate autoComplete="off">
        <Typography variant="h4" gutterBottom>
          Identification Information
        </Typography>

        {/* Mandatory Information */}
        <Card variant="outlined" sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Mandatory Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Date of Birth"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Scenario Sections with OR Dividers */}
        <Grid container spacing={2} justifyContent="center">
          {/* Scenario 1 */}
          <Grid item xs={12} sm={6} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Scenario 1
                </Typography>
                <TextField
                  fullWidth
                  label="Medicare Card Number"
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Medicare IRN"
                  variant="outlined"
                  margin="normal"
                />
              </CardContent>
            </Card>
          </Grid>

          <OrDivider />

          {/* Scenario 2 */}
          <Grid item xs={12} sm={6} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Scenario 2
                </Typography>
                <TextField
                  fullWidth
                  label="Post Code"
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  margin="normal"
                />
              </CardContent>
            </Card>
          </Grid>

          <OrDivider />

          {/* Scenario 3 */}
          <Grid item xs={12} sm={6} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Scenario 3
                </Typography>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="IHI Number"
                  variant="outlined"
                  margin="normal"
                />
              </CardContent>
            </Card>
          </Grid>

          <OrDivider />

          {/* Scenario 4 */}
          <Grid item xs={12} sm={6} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Scenario 4
                </Typography>
                <TextField
                  fullWidth
                  label="Post Code"
                  variant="outlined"
                  margin="normal"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Only Name Indicator"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Button variant="contained" color="primary" sx={{ mt: 4 }}>
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default IdentificationForm;
