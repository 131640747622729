import { LockOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { MouseEvent } from 'react';
import { Card } from 'react-bootstrap';

interface DashboardWidgetProps {
  variant: string;
  description: string;
  icon: string;
  path: string;
  planName?: string;
}

const DashboardWidget = (props: DashboardWidgetProps) => {
  const fullS3rPathPrefix = '/pages/safe/sale';
  const s3rPathPrefix = 'pages/safe/sale';
  const settingsPath = '/pages/settings';

  const isS3RPlanPath = props.path.startsWith(fullS3rPathPrefix) || props.path.startsWith(s3rPathPrefix);
  const isSettingsPath = props.path.startsWith(settingsPath);
  const isS3RPlan = props.planName === 'S3R';
  const isMonthlyPlan = props.planName === 'MONTHLY';
  const isYearlyPlan = props.planName === 'YEARLY';

  // Show lock only if it's not an S3R plan and not on S3R or settings path
  const shouldShowLock = !isS3RPlan && !isMonthlyPlan && !isYearlyPlan && !isS3RPlanPath && !isSettingsPath;

  // Determine if the link should be clickable
  const isClickable = isS3RPlan || isMonthlyPlan || isYearlyPlan || isS3RPlanPath || isSettingsPath;

  return (
    <>
      <Card
        className={classNames('mb-0', 'mt-3', 'widget-rounded-circle', 'border', {
          'border-success': true,
        })}
      >
        <Card.Body>
          <div className="row align-items-center">
            <div className="col-auto">
              <div
                className={classNames(
                  'avatar-lg',
                  'rounded-circle',
                  'bg-soft-' + props['variant'],
                  'border-' + props['variant'],
                  'border'
                )}
              >
                <i className={classNames(props['icon'], 'avatar-title', 'font-22', 'text-' + props['variant'])}></i>
              </div>
            </div>
            <div className="col">
              <Card.Link
                href={isClickable ? props.path : '#'}
                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                  if (!isClickable) {
                    e.preventDefault();
                  }
                }}
                className={classNames('text-custom', { 'pointer-events-none': !isClickable })}
              >
                <h5 className="mb-1 mt-2 font-16">{props.description}</h5>
              </Card.Link>
              {shouldShowLock && <LockOutlined />}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default DashboardWidget;
