import { LicenseInfo } from "@mui/x-license-pro";
import {
  DataGridPro,
  DataGridProProps,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey(
  "54496d9a2f15ea1becbc1387a0b46140Tz04NTIwMSxFPTE3NDA2OTUwMDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

type MUITableLicensedProps<T extends GridValidRowModel> = DataGridProProps<T>;

const MUITableLicensed = <T extends GridValidRowModel>(
  props: MUITableLicensedProps<T>
) => {
  const { sx, ...rest } = props;
  return (
    <DataGridPro
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#1976d2",
          color: "white",
        },
        "& .MuiDataGrid-columnHeaderTitleContainer .MuiSvgIcon-root": {
          color: "white",
        },
        ...sx,
      }}
      {...rest}
    />
  );
};

export default MUITableLicensed;
