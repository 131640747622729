// constants
import { ConfirmResetPasswordInput } from "aws-amplify/auth";
import { IProvider } from "../../interfaces";
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.SIGNUP_USER_REQUEST
    | AuthActionTypes.VERIFY_USER
    | AuthActionTypes.LOGIN_USER_WITH_EMAIL
    | AuthActionTypes.UPDATE_USER_ATTRIBUTES
    | AuthActionTypes.SET_REGISTRATION_STEP
    | AuthActionTypes.COMPLETE_STEP
    | AuthActionTypes.SET_AUTHENTICATION_STATUS
    | AuthActionTypes.LOGIN_STEPS
    | AuthActionTypes.CONFIRM_RESET_PASSWORD
    | AuthActionTypes.SET_OTP;
  payload: {} | string;
}

interface UserData {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  name: string;
  role: string;
  token: string;
}

interface CognitoTokenPayload {
  username: string;
  email: string;
  email_verified: boolean;
  // Include other fields as necessary
}

interface CognitoJwtToken {
  jwtToken: string;
  payload: CognitoTokenPayload;
}

export enum ELoginStep {
  "FILL_FORM" = "FILL_FORM",
  "CHOOSE_ACCOUNT" = "CHOOSE_ACCOUNT",
  "COMPLETE" = "COMPLETE",
  "INITIATE" = "INITIATE",
}

export interface ILoginSteps {
  step: ELoginStep;
}

interface CognitoSession {
  idToken: CognitoJwtToken;
  accessToken: CognitoJwtToken;
  refreshToken?: { token: string }; // Optional based on usage
  clockDrift: number;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const updateUserAttributes = (attributes: {
  email: string;
  name: string;
  value: string;
}): AuthActionType => ({
  type: AuthActionTypes.UPDATE_USER_ATTRIBUTES,
  payload: { attributes },
});

export const loginUser = (
  username: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { username, password },
});

export const signupUser = (
  fullname: string,
  email: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { fullname, email, password },
});
export const verifyUser = (username: string, code: string): AuthActionType => ({
  type: AuthActionTypes.VERIFY_USER,
  payload: { username, code },
});
export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const confirmResetPassword = (
  payload: ConfirmResetPasswordInput
): AuthActionType => ({
  type: AuthActionTypes.CONFIRM_RESET_PASSWORD,
  payload: { ...payload },
});

export const setOtp = (otp: string | null): AuthActionType => ({
  type: AuthActionTypes.SET_OTP,
  payload: { otp },
});

// export const signupUser =
//   (fullname: string, email: string, password: string) =>
//   async (dispatch: AppDispatch) => {
//     dispatch({ type: AuthActionTypes.SIGNUP_USER }); // Dispatching the request action
//     try {
//       const response = await signUpUserWithEmail(fullname, email, password); // Assuming email is used as username
//       dispatch(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, response));
//     } catch (error) {
//       const message = (error as Error).message;
//       dispatch(authApiResponseError(AuthActionTypes.SIGNUP_USER, message));
//     }
//   };

// export const verifyUser =
//   (username: string, code: string) => async (dispatch: AppDispatch) => {
//     dispatch({ type: AuthActionTypes.VERIFY_USER }); // Dispatching the request action
//     try {
//       const signupSignupInput: ConfirmSignUpInput = {
//         username, // Assuming fullname is used as the username
//         confirmationCode: code,
//       };
//       const response = await verifyCode(signupSignupInput); // Assuming email is used as username
//       logger.log("VERIFY USER RESPONSE::", response);
//       dispatch(authApiResponseSuccess(AuthActionTypes.VERIFY_USER, response));
//     } catch (error) {
//       const message = (error as Error).message;
//       dispatch(authApiResponseError(AuthActionTypes.VERIFY_USER, message));
//       throw error; // Ensure this error is thrown
//     }
//   };

export const forgotPassword = (username: string ): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { username },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});

export const completeStep = (step: number): AuthActionType => ({
  type: AuthActionTypes.COMPLETE_STEP,
  payload: step,
});
export const setRegistrationStep = (step: number): AuthActionType => ({
  type: AuthActionTypes.SET_REGISTRATION_STEP,
  payload: step,
});

export const setAuthenticationStatus = (status: boolean) => ({
  type: AuthActionTypes.SET_AUTHENTICATION_STATUS,
  payload: status,
});

export const setLoginSteps = (payload: ILoginSteps) => ({
  type: AuthActionTypes.LOGIN_STEPS,
  payload: payload,
});

export const confirmLoginUsingMfa = (payload: string) => ({
  type: AuthActionTypes.CONFIRM_LOGIN_USING_MFA,
  payload: payload,
});

