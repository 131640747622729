import axios from 'axios';
import { CholesterolReading, CholesterolResponse, CholesterolDataResponse } from '../types/cholesterol';

const API_URL = process.env.REACT_APP_CHOLESTEROL_CREATE_URL;
const GET_BY_PATIENT_ID_URL = process.env.REACT_APP_CHOLESTEROL_GET_BY_PATIENT_ID_URL;

export const createCholesterolReading = async (
  formData: Omit<CholesterolReading, 'id' | 'createdAt' | 'updatedAt'>
): Promise<CholesterolResponse> => {
  const response = await axios.post<CholesterolResponse>(API_URL!, formData);
  return response.data;
};

export const getCholesterolReadingsByPatientId = async (requestBody: {
  body: {
    patientID: string;
    pharmacyID: string;
    sortDirection: string;
    limit: number;
    nextToken: string | null;
  };
}): Promise<CholesterolDataResponse> => {
  const response = await axios.post<CholesterolDataResponse>(GET_BY_PATIENT_ID_URL!, requestBody);
  return response.data;
};
