import React, { Fragment, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { LayoutTypes } from '../constants/layout';
import { RootState } from '../redux/store';
import DefaultLayout from '../layouts/Default';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import TwoColumnLayout from '../layouts/TwoColumn/';
import VerticalLayout from '../layouts/Vertical';
import { APICore } from '../helpers/api/apiCore';
import Confirm2 from '../pages/auth2/Confirm2';
import PharmacyDetails from '../pages/auth2/PharmacyDetails';
import Pricing from '../pages/payments/Pricing';
import PPAAdmin from '../pages/administration/ppadmin';
import ProtectedRoute from './ProtectedRoute';
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import AdministratorAuthorizationWrapper from '../pages/safe/AdministratorAuthorizationWrapper';
import { ELoginStep } from '../redux/actions';
import PageWrapper from '../components/PageWrapper';
import { logger } from '../utils/logger';
import { AppointmentsProvider } from '../contexts/AppointmentContext';
import MandatePricing from '../pages/Pricing';
import SubscriptionCheck from '../components/SubscriptionCheck';
import ChooseAccount from '../pages/auth2/ChooseAccount';
import Onboarding from '../pages/onboarding/onboarding';
import { SubscriptionProvider } from '../contexts/SubscriptionContext';
import AuthContext from '../contexts/AuthContext';
import PlansAuthorizer from 'components/PlansAuthorizer';
import OnboardingS3R from 'pages/onboarding/onboardingS3R';

interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();
  const api = new APICore();
  const loginStep = api.getLoginStep();
  const { isSubscribed } = useContext(AuthContext);

  const { state } = useLocation();
  useEffect(() => {
    logger.log('Routes states', state);
  }, [state]);

  return (
    <SubscriptionProvider>
      <AppointmentsProvider>
        <Fragment>
          <Routes>
            {/* Public routes */}
            <Route>
              {publicProtectedFlattenRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={
                    <DefaultLayout {...props} layout={layout}>
                      {route.element}
                    </DefaultLayout>
                  }
                  key={idx}
                />
              ))}
              <Route
                path="/pricing"
                element={
                  <DefaultLayout {...props} layout={layout}>
                    <MandatePricing />
                  </DefaultLayout>
                }
              />
              <Route
                path="/auth/login/accounts"
                element={
                  <DefaultLayout {...props} layout={layout}>
                    <ChooseAccount />
                  </DefaultLayout>
                }
              />
              <Route
                path="/onboarding-air"
                element={
                  <SubscriptionCheck>
                    <Layout {...props}>
                      <Onboarding />
                    </Layout>
                  </SubscriptionCheck>
                }
              />
              <Route
                path="/onboarding-s3r"
                element={
                  <SubscriptionCheck>
                    <Layout {...props}>
                      <OnboardingS3R />
                    </Layout>
                  </SubscriptionCheck>
                }
              />
            </Route>

            {/* Auth protected routes */}
            <Route>
              {authProtectedFlattenRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  element={
                    <PageWrapper {...route} {...api} startPeriodicRefresh={api.startPeriodicRefresh}>
                      <SubscriptionCheck>
                        {route?.roles?.includes('Admin') ? (
                          <Layout {...props}>
                            <PlansAuthorizer>
                              <AdministratorAuthorizationWrapper>{route.element}</AdministratorAuthorizationWrapper>
                            </PlansAuthorizer>
                          </Layout>
                        ) : loginStep === ELoginStep.CHOOSE_ACCOUNT ? (
                          <Navigate
                            to={{
                              pathname: '/auth/login/accounts',
                            }}
                            state={{
                              ...(loginStep === ELoginStep.CHOOSE_ACCOUNT && {
                                loginStep,
                              }),
                            }}
                          />
                        ) : (
                          <Layout {...props} {...route}>
                            <PlansAuthorizer>{route.element}</PlansAuthorizer>
                          </Layout>
                        )}
                      </SubscriptionCheck>
                    </PageWrapper>
                  }
                  key={idx}
                />
              ))}
            </Route>

            {/* Registration steps */}
            <Route
              path="/register/step-2"
              element={
                <ProtectedRoute stepRequired={2} authenticationRequired={true}>
                  <Confirm2 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/register/step-3"
              element={
                <ProtectedRoute stepRequired={3} authenticationRequired={true}>
                  <Pricing />
                </ProtectedRoute>
              }
            />
            <Route
              path="/register/step-4"
              element={
                <ProtectedRoute stepRequired={4} authenticationRequired={true}>
                  <Pricing />
                </ProtectedRoute>
              }
            />
            <Route
              path="/register/step-5"
              element={
                <ProtectedRoute stepRequired={4} authenticationRequired={true}>
                  <PPAAdmin />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Fragment>
      </AppointmentsProvider>
    </SubscriptionProvider>
  );
};

export default React.memo(AllRoutes);
