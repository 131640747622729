import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_VACCINE_DATA } from '../graphql-queries';
import { GraphQLVaxListResponse } from '../pages/patients/Vaccine';

interface VaccineDataContextType {
    vaccineData: GraphQLVaxListResponse | null;
    loading: boolean;
    error: any;
}

const VaccineDataContext = createContext<VaccineDataContextType>({
    vaccineData: null,
    loading: false,
    error: null,
});

interface VaccineDataProviderProps {
    children: ReactNode;
}

export const VaccineDataProvider: React.FC<VaccineDataProviderProps> = ({ children }) => {
    const [vaccineData, setVaccineData] = useState<GraphQLVaxListResponse | null>(null);
    const [fetchVaccineData, { loading, error }] = useLazyQuery<GraphQLVaxListResponse>(GET_VACCINE_DATA);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await fetchVaccineData();
                if (data) {
                    setVaccineData(data);
                }
            } catch (err) {
                console.error('Error fetching vaccine data:', err);
            }
        };

        fetchData();
    }, [fetchVaccineData]);

    return (
        <VaccineDataContext.Provider value={{ vaccineData, loading, error }}>
            {children}
        </VaccineDataContext.Provider>
    );
};

export const useVaccineData = () => useContext(VaccineDataContext);