import React, { useEffect } from 'react';
import { Grid, Paper, Typography, TextField, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';
import { Controller, Control, useWatch } from 'react-hook-form';
import { FormData } from '../UTIProtocolForm';
import { grey } from '@mui/material/colors';
import { Thermometer, AlertCircle, Activity, AlertTriangle } from 'lucide-react';

interface StepProps {
    control: Control<FormData>;
    formData: FormData;
    handleChange: (section: keyof FormData, field: string, value: string | boolean) => void;
}

const Step3Symptoms: React.FC<StepProps> = ({ control, formData, handleChange }) => {
    const temperature = useWatch({
        control,
        name: 'symptoms.temperature',
    });

    useEffect(() => {
        const tempValue = parseFloat(temperature);
        if (!isNaN(tempValue) && tempValue > 38) {
            handleChange('symptoms', 'pyelonephritisSigns', true);
        }
    }, [temperature, handleChange]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <Thermometer size={20} style={{ marginRight: '10px' }} />
                        Patient's Temperature
                    </Typography>
                    <Controller
                        name="symptoms.temperature"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Temperature (°C)"
                                type="number"
                                size="small"
                                InputProps={{
                                    endAdornment: <Typography variant="body2">°C</Typography>,
                                }}
                                onChange={(e) => {
                                    field.onChange(e);
                                    handleChange('symptoms', 'temperature', e.target.value);
                                }}
                            />
                        )}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <AlertCircle size={20} style={{ marginRight: '10px' }} />
                        Pyelonephritis Signs
                    </Typography>
                    <Controller
                        name="symptoms.pyelonephritisSigns"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label="Patient has signs and symptoms indicating pyelonephritis"
                            />
                        )}
                    />
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        (e.g. fever {'>'}38°C, chills, nausea, vomiting, flank pain)
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <AlertCircle size={20} style={{ marginRight: '10px' }} />
                        Suspected Sepsis
                    </Typography>
                    <Controller
                        name="symptoms.suspectedSepsis"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label="Suspected sepsis (including fever, difficulty breathing, fast heart rate, low blood pressure and mental confusion)"
                            />
                        )}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <Activity size={20} style={{ marginRight: '10px' }} />
                        UTI Symptoms
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Is the patient presenting with 2 or more symptoms of acute uncomplicated UTI?
                    </Typography>
                    <Grid container spacing={2}>
                        {(['dysuria', 'frequency', 'urgency', 'suprapubicPainOrDiscomfort'] as const).map((symptom) => (
                            <Grid item xs={12} sm={6} key={symptom}>
                                <Controller
                                    name={`symptoms.${symptom}`}
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} checked={field.value} />}
                                            label={symptom.charAt(0).toUpperCase() + symptom.slice(1).replace(/([A-Z])/g, ' $1')}
                                        />
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <AlertTriangle size={20} style={{ marginRight: '10px' }} />
                        Other Causes
                    </Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Do symptoms or medical history suggest a cause other than acute UTI?</FormLabel>
                        <Controller
                            name="symptoms.otherCause"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    value={field.value ? 'yes' : 'no'}
                                    onChange={(e) => handleChange('symptoms', 'otherCause', e.target.value === 'yes')}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        (e.g. vaginal thrush or other)
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Step3Symptoms;