import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, Control } from 'react-hook-form';
import { FormData } from '../UTIProtocolForm';
import { grey } from '@mui/material/colors';
import { UserPlus, Calendar, Check, Phone } from 'lucide-react';
import dayjs, { Dayjs } from 'dayjs';

interface Step1Props {
  control: Control<FormData>;
  formData: FormData;
}

const Step1PatientInfo: React.FC<Step1Props> = ({ control, formData }) => {
  const [age, setAge] = useState<number | null>(null);

  const calculateAge = (dob: Dayjs | null) => {
    if (!dob) return null;
    return dayjs().diff(dob, 'year');
  };

  useEffect(() => {
    if (formData.patientInfo.dateOfBirth) {
      setAge(calculateAge(dayjs(formData.patientInfo.dateOfBirth)));
    }
  }, [formData.patientInfo.dateOfBirth]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <UserPlus size={20} style={{ marginRight: '10px' }} />
              Patient Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="patientInfo.firstName"
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth required label="First Name" size="small" />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="patientInfo.lastName"
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth required label="Last Name" size="small" />}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="patientInfo.dateOfBirth"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      label="Date of Birth"
                      disableFuture
                      format="DD/MM/YYYY"
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(newValue: Dayjs | null) => {
                        field.onChange(newValue ? newValue.format('YYYY-MM-DD') : '');
                        setAge(calculateAge(newValue));
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          required: true,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Age"
                  value={age !== null ? `${age} years` : ''}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Gender</InputLabel>
                  <Controller
                    name="patientInfo.gender"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} label="Gender">
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="patientInfo.phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label="Phone Number"
                      size="small"
                      InputProps={{
                        startAdornment: <Phone size={20} style={{ marginRight: '8px', color: grey[500] }} />,
                      }}
                    />
                  )}
                />
              </Grid>
              {['male', 'other'].includes(formData.patientInfo.gender) && (
                <Grid item xs={12}>
                  <Controller
                    name="patientInfo.genderReassignment"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} />}
                        label="Has undergone gender reassignment surgery"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              <Check size={20} style={{ marginRight: '10px' }} />
              Patient Consent
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="patientConsent.consentGiven"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Does the patient consent?"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="patientConsent.physicallyPresent"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Is the patient physically present in the pharmacy?"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default Step1PatientInfo;
