import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

type GooglePlacesInputProps = {
  apiKey: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
  label?: string;
  // defaultValue?: string;
  // [x: string]: any;
} & TextFieldProps;

const GooglePlacesInput: React.FC<GooglePlacesInputProps> = ({
  apiKey,
  onPlaceSelected,
  label = 'Address',
  // defaultValue = "",
  ...rest // Spread operator to collect any additional props
}) => {
  const { ref } = usePlacesWidget({
    apiKey: apiKey,
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ['address'],
      componentRestrictions: { country: 'au' }, // Adjust this as needed
    },
    inputAutocompleteValue: 'new-password', // Helps to avoid Chrome's autocomplete suggestions
  });

  return (
    <TextField
      fullWidth
      color="primary"
      variant="outlined"
      inputRef={ref}
      label={label}
      // defaultValue={defaultValue}
      size="small"
      {...rest} // Spread the rest of the props here
    />
  );
};

export default GooglePlacesInput;
