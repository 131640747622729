import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Snackbar,
  Paper,
  Backdrop,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AuthContext from '../../contexts/AuthContext';
import DashboardWidget from '../../components/DashboardWidget';
import Joyride, { Step as JoyrideStep } from 'react-joyride';
import PharmacyDetails from '../../pages/auth2/PharmacyDetails'; // Make sure to adjust the import path accordingly
import PPAAdmin from '../../pages/administration/ppadmin';
import PPADetails from '../../pages/auth2/PPADetails';
import { useIdToken } from '../../hooks/useIdToken';
import { logger } from '../../utils/logger';

interface MenuItems {
  id: number;
  description: string;
  variant: string;
  icon: string;
  path: string;
}

const Onboarding: React.FC = () => {
  const {
    providerNumber,
    providerFirstName,
    providerLastName,
    providerStore,
    pharmacistID,
    pharmacyID,
    hpioNumber,
    hpiiNumber,
    pharmacyName,
    isAdministrator,
    deviceName,
    minorId,
    providerAHPRA,
    providerEmail,
    isPPAEligible,
    prodaOrgId,
    setAuthDetails,
    planName,
  } = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    minorId: '',
    deviceName: '',
    newDeviceName: '',
    environment: '',
    jksPassword: '',
    jksKeyAlias: '',
    certLocation: '',
    deviceActivationPassword: '',
    prodaOrgId: '',
  });
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [isPPAStepComplete, setIsPPAStepComplete] = useState(false);
  const { idToken, isLoading, error: idTokenError } = useIdToken();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNextStep = () => {
    if (currentStep === 0) {
      setAuthDetails({
        providerNumber,
        providerFirstName,
        providerLastName,
        providerStore,
        pharmacistID,
        pharmacyID,
        pharmacyName,
        hpioNumber,
        hpiiNumber,
        isAdministrator,
        deviceName: formData.newDeviceName, // Use the new device name from formData
        minorId,
        providerAHPRA,
        providerEmail,
        isPPAEligible,
        prodaOrgId: formData.prodaOrgId, // Use the new prodaOrgId from formData
        PPA: undefined, // or the actual PPA data if available
      });
    }

    if (currentStep === 1) {
      setAuthDetails({
        providerNumber,
        providerFirstName,
        providerLastName,
        providerStore,
        pharmacistID,
        pharmacyID,
        pharmacyName,
        hpioNumber,
        hpiiNumber,
        isAdministrator,
        deviceName,
        minorId: formData.minorId, // Use the new minorId from formData
        providerAHPRA,
        providerEmail,
        isPPAEligible,
        prodaOrgId,
        PPA: undefined, // or the actual PPA data if available
      });
    }

    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePPAComplete = (isComplete: boolean) => {
    setIsPPAStepComplete(isComplete);
    // You can also call handleNextStep or any other function here
    // to move to the next step in your parent component if needed
  };
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!idToken) {
      console.error('No authentication token available');
      return;
    }
    const apiAIRActivateDevice = process.env.REACT_APP_ACTIVATE_DEVICE;
    if (!apiAIRActivateDevice) {
      throw new Error('REACT_APP_ACTIVATE_DEVICE is not defined');
    }
    const apiUrl = apiAIRActivateDevice;
    const requestBody = {
      deviceName: formData.newDeviceName,
      deviceActivationPassword: formData.deviceActivationPassword,
      prodaOrgId: formData.prodaOrgId,
    };

    setApiLoading(true);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      logger.log('Response', response);
      if (response.ok) {
        logger.log('Device added successfully');
        setSnackbarOpen(true);
        handleNextStep();
      } else {
        console.error('Failed to add device', response.statusText);
      }
    } catch (error) {
      console.error('Error adding device', error);
    } finally {
      setApiLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const steps = ['Add New Device', 'Enter Minor ID', 'Enter Provider Details', 'Pharmacy Programs Administrator (PPA)'];

  const [items] = useState<MenuItems[]>([
    // {
    //   id: 1,
    //   variant: "blue",
    //   description: "Analytics",
    //   icon: "fe-bar-chart-2",
    //   path: "/pages/analytics",
    // },
    {
      id: 6,
      variant: 'blue',
      description: 'Appointments',
      icon: 'fe-calendar',
      path: '/apps/appointments',
    },
    {
      id: 1,
      variant: 'blue',
      description: 'Vaccine Administration',
      icon: 'fe-thermometer',
      path: '/pages/vaccine-administration',
    },
    {
      id: 4,
      variant: 'blue',
      description: 'Professional Services',
      icon: 'fe-file-text',
      path: '/pages/professional-services',
    },
    {
      id: 5,
      variant: 'blue',
      description: 'Pseudo Watch',
      icon: 'fe-stop-circle',
      path: 'pages/safe/sale',
    },
    {
      id: 7,
      variant: 'blue',
      description: 'Injection Administration',
      icon: 'fe-feather',
      path: '/pages/injection-administration',
    },
    {
      id: 8,
      variant: 'blue',
      description: 'Patient Administration',
      icon: 'fe-users',
      path: '/pages/patients/search-patient',
    },
    {
      id: 3,
      variant: 'blue',
      description: 'Reports',
      icon: 'fe-aperture',
      path: '/pages/claims',
    },
    {
      id: 9,
      variant: 'blue',
      description: 'Settings',
      icon: 'fe-settings',
      path: '/pages/settings',
    },
    {
      id: 11,
      variant: 'blue',
      description: 'Payments',
      icon: 'fe-credit-card',
      path: '/subscription-dashboard',
    },
    {
      id: 10,
      variant: 'blue',
      description: 'Tutorials',
      icon: 'fe-youtube',
      path: '/pages/tutorials',
    },
  ]);

  const handlePharmacyDetailsComplete = (data: {
    providerNumber: string;
    providerFirstName: string;
    providerLastName: string;
    providerStore: string;
    pharmacistID: string;
    pharmacyID: string;
    hpioNumber: string;
    hpiiNumber: string;
    isAdministrator: boolean;
    providerAHPRA: string;
    pharmacyName: string;
    isPPAEligible: boolean;
    prodaOrgId: string;
  }) => {
    logger.log('🚀 ~ data:', data);
    setAuthDetails({
      providerNumber: data.providerNumber,
      providerFirstName: data.providerFirstName,
      providerLastName: data.providerLastName,
      providerStore: data.providerStore,
      pharmacistID: data.pharmacistID,
      pharmacyID: data.pharmacyID,
      pharmacyName: data.pharmacyName,
      hpioNumber: data.hpioNumber,
      hpiiNumber: data.hpiiNumber,
      isAdministrator: data.isAdministrator,
      deviceName: formData.newDeviceName, // This comes from the Dashboard state
      minorId: formData.minorId, // This comes from the Dashboard state
      providerAHPRA: data.providerAHPRA,
      providerEmail, // This likely comes from the current context
      isPPAEligible: data.isPPAEligible,
      prodaOrgId: data.prodaOrgId,
      PPA: undefined, // or data.PPA if it exists
    });

    setCurrentStep(currentStep + 1);
  };

  const joyrideSteps: JoyrideStep[] = [
    {
      target: '.joyride-device-name',
      content: 'Enter the Device Name provided by your provider.',
    },
    {
      target: '.joyride-device-password',
      content: 'Enter the Device Activation Password provided by your provider.',
    },
    {
      target: '.joyride-next-button',
      content: 'Click "Activate Device" to proceed.',
    },
    {
      target: '.joyride-minor-id',
      content: 'Enter the Minor ID assigned to your organization.',
    },
    {
      target: '.joyride-next-button',
      content: 'Click "Next" to proceed.',
    },
    {
      target: '.joyride-device-name-final',
      content: 'Enter a unique Device Name for your setup.',
    },
    {
      target: '.joyride-submit-button',
      content: 'Click "Submit" to complete the setup process.',
    },
  ];

  if (isLoading) {
    return <CircularProgress />;
  }

  if (idTokenError) {
    return <Typography color="error">Error loading authentication token: {idTokenError}</Typography>;
  }

  const getInstructions = () => {
    switch (currentStep) {
      case 0:
        return (
          <ol>
            <li>
              <Typography>
                Service Australia Requires every Provider to have their PRODA device activated & used with the Software
                Provider
              </Typography>
            </li>
            <li>
              <span>
                Register PRODA Device
                <ul>
                  <li>
                    Login to Proda{' '}
                    <a
                      href="https://proda.humanservices.gov.au/prodalogin/pages/public/login.jsf?TAM_OP=login&USER"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>{' '}
                    and Select Organisations
                  </li>
                  <li>Select which organisation name you want to register the B2B device to</li>
                  <li>
                    Register and activate the B2B device
                    <ul>
                      <li>Select B2B Devices and then Register New B2B device</li>
                      <li>
                        Enter a Device Name. The name needs to be descriptive enough so you can recognise the device
                        purpose or what service transactions it’s performing. The device name must be unique to your
                        organisation in PRODA. The name can contain the following characters:
                        <ul>
                          <li>alphabetic</li>
                          <li>numeric</li>
                          <li>underscores</li>
                          <li>hyphens</li>
                          <li>full stops</li>
                          <li>no spaces.</li>
                        </ul>
                      </li>
                      <li>
                        You can add further details to describe your device in Description. Under the Description,
                        please add “Dragatron Professional Services”
                      </li>
                      <li>Select Register Device.</li>
                      <li>
                        You have registered the device. You’ll get a unique Device Activation Code valid for 7 days.
                        Record this code as it’s not stored.
                      </li>
                    </ul>
                  </li>
                </ul>
              </span>
            </li>
            <li>
              <Typography>Enter the Device Name and Activation Password in the fields on the left.</Typography>
            </li>
            <li>
              <Typography>Click "Activate Device" to proceed.</Typography>
            </li>
            <li>
              <Typography>Watch the video below for a detailed guide:</Typography>
              <Box sx={{ mt: 2 }}>
                <iframe
                  width="560"
                  height="315"
                  src="https://player.vimeo.com/video/956266791?h=4294e5412f&badge=0&autopause=0&player_id=0&app_id=58479"
                  title="Device Registration"
                  style={{ border: '0' }}
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  allowFullScreen
                ></iframe>
              </Box>
            </li>
          </ol>
        );
      case 1:
        return (
          <ol>
            <li>
              <Typography>Step 2: Enter the Minor ID provided by your organization.</Typography>
            </li>
            <li>
              <Typography>Click "Next" to proceed to the final step.</Typography>
            </li>
          </ol>
        );
      case 2:
        return (
          <ol>
            <li>
              <Typography>Please provide the pharmacy & provider details.</Typography>
            </li>
            <li>
              <Typography>Click "Submit" to validate the details with AIR & Service Australia.</Typography>
            </li>
          </ol>
        );
      case 3:
        return (
          <ol>
            <li>
              <Typography>Please provide the pharmacy & provider details.</Typography>
            </li>
            <li>
              <Typography>Click "Submit" to validate the details with AIR & Service Australia.</Typography>
            </li>
          </ol>
        );
      default:
        return null;
    }
  };

  logger.log('currentStep', currentStep);
  logger.log('🚀 ~ isPPAEligible:', isPPAEligible);
  logger.log('🚀 ~ pharmacyID:', pharmacyID);
  logger.log('🚀 ~ providerNumber:', providerNumber);
  logger.log('🚀 ~ deviceName:', deviceName);
  logger.log('🚀 ~ minorId:', minorId);
  logger.log('🚀 ~ prodaOrgId:', prodaOrgId);

  if (!minorId || !deviceName || !providerNumber || !pharmacyID || !isPPAEligible) {
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        {/* <Joyride steps={joyrideSteps} continuous showSkipButton /> */}
        <Stepper activeStep={currentStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container spacing={3} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }}>
              {currentStep === 0 && (
                <Box component="form" onSubmit={handleFormSubmit}>
                  <TextField
                    className="joyride-device-name"
                    label="Device Name"
                    name="newDeviceName"
                    value={formData.newDeviceName}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    className="joyride-device-password"
                    label="Device Activation Password"
                    name="deviceActivationPassword"
                    value={formData.deviceActivationPassword}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    className="joyride-device-password"
                    label="PRODA RA (Organisation)"
                    name="prodaOrgId"
                    value={formData.prodaOrgId}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    className="joyride-next-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<AddIcon />}
                    sx={{ mt: 2 }}
                  >
                    Activate Device
                  </Button>
                </Box>
              )}
              {currentStep === 1 && (
                <Box>
                  <TextField
                    className="joyride-minor-id"
                    label="Minor ID"
                    name="minorId"
                    value={formData.minorId}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    className="joyride-next-button"
                    variant="contained"
                    color="primary"
                    onClick={handleNextStep}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Next
                  </Button>
                </Box>
              )}
              {currentStep === 2 && <PharmacyDetails onComplete={handlePharmacyDetailsComplete} />}
              {currentStep === 3 && <PPADetails onComplete={handlePPAComplete} />}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6">Instructions</Typography>
              {getInstructions()}
            </Paper>
          </Grid>
        </Grid>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={apiLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message="Device added successfully"
        />
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Dashboard
          </Typography>
        </Grid>
        {items.map((item) => (
          <Grid item md={6} xl={3} key={item.id}>
            <DashboardWidget
              planName={planName}
              variant={item.variant}
              description={item.description}
              icon={item.icon}
              path={item.path}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Onboarding;
