import React, { useContext, useState } from 'react';
import { Box, Button, TextField, Typography, Grid, Paper, CircularProgress, Alert } from '@mui/material';
import AuthContext from '../../contexts/AuthContext';
import { logger } from 'utils/logger';
import GooglePlacesInput from '../auth2/GooglePlacesInput';
import { Controller, useForm } from 'react-hook-form';
import { useIdToken } from '../../hooks/useIdToken';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { v4: uuidv4 } = require('uuid');
const pharmacistID = uuidv4(); // Generates a new UUID for dhs-messageId

const OnboardingS3R: React.FC = () => {
  const { setAuthDetails, deviceName, minorId, prodaOrgId } = useContext(AuthContext);
  const [loadingState, setLoadingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiResponseData, setApiResponseData] = useState('');
  const { idToken, isLoading, error: idTokenError } = useIdToken();
  const state = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  const email =
    state.Auth?.user?.tokens?.idToken?.payload?.email ?? state.Auth?.user?.session?.tokens?.idToken?.payload?.email;
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      ownerFirstName: '',
      ownerLastName: '',
      ownerAPHRANumber: '',
      pharmacyName: '',
      pharmacyAddress: '',
      phoneNumber: '',
    },
  });

  const updateAddress = (address: string) => {
    setValue('pharmacyAddress', address);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    setErrorMessage('');
    logger.log(data);

    try {
      const responseData = await updateUserAttributesViaAPI(data);

      // Update AuthContext with new information
      setAuthDetails({
        providerNumber: responseData.providerNumber,
        providerFirstName: data.ownerFirstName,
        providerLastName: data.ownerLastName,
        providerStore: responseData.providerStore,
        pharmacistID: pharmacistID,
        pharmacyID: responseData.pharmacyID,
        pharmacyName: data.pharmacyName,
        hpioNumber: responseData.hpioNumber,
        hpiiNumber: responseData.hpiiNumber,
        isAdministrator: true,
        deviceName,
        minorId,
        providerAHPRA: data.ownerAPHRANumber,
        providerEmail: email,
        isPPAEligible: true,
        prodaOrgId,
        PPA: undefined, // or actual PPA data if available
      });

      // Redirect to the sale page after successful submission
      navigate('/pages/safe/sale');
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('An error occurred while submitting the form.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateUserAttributesViaAPI = async (data: any) => {
    const requestData = {
      username: email, // Replace with actual user email
      pharmacyName: data.pharmacyName,
      pharmacyAddress: data.pharmacyAddress,
      pharmacyPhoneNumber: data.phoneNumber,
      pharmacyEmailAddress: email, // Replace with actual pharmacy email
      pharmacyLoginId: email, // Replace with actual user email
      ownerAHPRA: data.ownerAPHRANumber,
      ownerFirstName: data.ownerFirstName,
      ownerLastName: data.ownerLastName,
      ownerPhoneNumber: data.phoneNumber,
      providerAHPRA: data.ownerAPHRANumber,
      isActive: true,
      pharmacistID: pharmacistID,
      providerFirstName: data.ownerFirstName,
      providerLastName: data.ownerLastName,
      isPPAEligible: true,
      isAdministrator: true,
    };

    logger.log(requestData);

    if (!idToken) {
      console.error('No authentication token available');
      throw new Error('Authentication error');
    }

    const apiCreatePharmacy = process.env.REACT_APP_CREATE_PHARMACY;
    if (!apiCreatePharmacy) {
      throw new Error('REACT_APP_CREATE_PHARMACY is not defined');
    }

    const response = await fetch(apiCreatePharmacy, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    logger.log(responseData);
    setApiResponseData(responseData.pharmacyID); // Store the response data

    return responseData; // Return the response data so we can use it in onSubmit
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (idTokenError) {
    return <Typography color="error">Error loading authentication token: {idTokenError}</Typography>;
  }

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Pharmacy Onboarding For S3R
      </Typography>
      <Paper sx={{ p: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="ownerFirstName"
                control={control}
                rules={{ required: 'First name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Owner First Name"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="ownerLastName"
                control={control}
                rules={{ required: 'Last name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} fullWidth label="Owner Last Name" error={!!error} helperText={error?.message} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="ownerAPHRANumber"
                control={control}
                rules={{ required: 'APHRA Number is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Owner APHRA Number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="pharmacyName"
                control={control}
                rules={{ required: 'Pharmacy name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} fullWidth label="Pharmacy Name" error={!!error} helperText={error?.message} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="pharmacyAddress"
                control={control}
                rules={{ required: 'Pharmacy address is required' }}
                render={({ field, fieldState: { error } }) => (
                  <GooglePlacesInput
                    apiKey="AIzaSyAv2vANIvhPQU6wIOtn54KvfiQ8zFfCYBA"
                    onPlaceSelected={(place: google.maps.places.PlaceResult | null): void => {
                      if (place) {
                        const formattedAddress = place.formatted_address;
                        updateAddress(formattedAddress || '');
                      }
                    }}
                    {...field}
                    fullWidth
                    label="Pharmacy Address"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: 'Phone number is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} fullWidth label="Phone Number" error={!!error} helperText={error?.message} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

export default OnboardingS3R;
