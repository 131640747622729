import { Box, Button, Typography } from "@mui/material";
import { publish } from "../services/events/auto-logout";
import { EVENT_NAME } from "../constants/auto-logout";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "../contexts/ModalContext";
import { useDispatch } from "react-redux";
import {
  ELoginStep,
  logoutUser,
  resetAuth,
  setLoginSteps,
} from "../redux/actions";

type Props = {};

const ExpiredSessionModalContent = (props: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Typography>
        Your session has been expired, please re-login to continue.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={() => {
          dispatch(resetAuth());
          dispatch(logoutUser());
          dispatch(
            setLoginSteps({
              step: ELoginStep.FILL_FORM,
            })
          );

          navigate(
            {
              pathname: `/auth/login`,
              search: `next=${pathname}`,
            },
            {
              state: {
                next: pathname,
              },
              replace: true,
            }
          );
          closeModal();
          // publish(EVENT_NAME)
        }}
      >
        Reload
      </Button>
    </Box>
  );
};

export default ExpiredSessionModalContent;
