import React, { useState, useEffect, ChangeEvent, useContext } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Snackbar,
  CircularProgress,
  Backdrop,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PpaPageWrapper from "../../components/Ppa/PpaPageWrapper";
import AuthContext from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { differenceInMonths } from "date-fns";
import { usePharmacyField } from "../../hooks/usePharmacyField";
import { useIdToken } from "../../hooks/useIdToken";

interface Provider {
  providerId: number;
  pharmacyId: string | null;
  providerName: string;
  providerType: string;
  providerAddress: string;
  providerAddress2: string | null;
  providerSuburb: string;
  providerPostcode: string;
  providerState: string;
}

interface Program {
  programRegistrationId: number;
  programName: string;
}

interface ApiResponse<T> {
  message: string;
  data: T;
}

interface PPADetailsProps {
  onComplete: (isComplete: boolean) => void;
}
const steps = ["Enter User Information", "Select a Pharmacy", "View Programs"];

const programDescriptions: { [key: string]: string } = {
  HMR: "Home Medicines Review",
  CVCP: "COVID-19 Vaccination Community Pharmacy Program",
  RMMR: "Residential Medication Management Review Program",
  Medscheck: "MedsCheck & Diabetes MedsCheck",
  ODT: "Opioid Dependence Treatment Community Pharmacy Program",
  NIPVIP: "National Immunisation Program Vaccinations in Pharmacy",
};

const PPADetails: React.FC<PPADetailsProps> = ({ onComplete }) => {
  const { idToken, isLoading, error: idTokenError } = useIdToken();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(
    null
  );
  const [newPharmacyIds, setNewPharmacyIds] = useState<{
    [key: number]: string;
  }>({});
  const [contextPharmacyID, setContextPharmacyId] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    providerNumber,
    providerAHPRA,
    providerFirstName,
    providerLastName,
    providerStore,
    pharmacyID,
    pharmacistID,
    hpioNumber,
    pharmacyName,
    hpiiNumber,
    isAdministrator,
    deviceName,
    minorId,
    providerEmail,
    isPPAEligible,
    prodaOrgId,
    setAuthDetails,
  } = useContext(AuthContext);

  const state = useSelector((state: RootState) => state);
  const email =
    state.Auth?.user?.tokens?.idToken?.payload?.email ??
    state.Auth?.user?.session?.tokens?.idToken?.payload?.email;

  // const pharmacyID = usePharmacyField("pharmacyId");
  const businessNamePPA = usePharmacyField("businessNamePPA");
  const userKeyExpiry = usePharmacyField("userKeyExpiry");
  const pharmacyLoading = usePharmacyField("loading");
  const pharmacyError = usePharmacyField("error");
  const initialUserId = usePharmacyField("userId") || "";
  const initialUserKey = usePharmacyField("userKey") || "";
  const [userId, setUserId] = useState<string>(initialUserId);
  const [userKey, setUserKey] = useState<string>(initialUserKey);

  useEffect(() => {
    setUserId(initialUserId);
    setUserKey(initialUserKey);
  }, [initialUserId, initialUserKey]);

  useEffect(() => {
    if (pharmacyID) {
      const selectedProvider = providers.find(
        (provider) => provider.pharmacyId === pharmacyID
      );
      if (selectedProvider) {
        setSelectedProviderId(selectedProvider.providerId);
      }
    }
  }, [providers, pharmacyID]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUserSubmit = async () => {
    setLoading(true);
    try {
      const apiURL = process.env.REACT_APP_PPA_SERVICE_PROVIDERS;
      if (!apiURL) {
        throw new Error("REACT_APP_PPA_SERVICE_PROVIDERS is not defined");
      }
      const response = await axios.post<ApiResponse<Provider[]>>(apiURL, {
        userId,
        userKey,
        providerNumber,
        pharmacyId: pharmacyID,
      });
      const generatedPharmacyIds: { [key: number]: string } = {};

      const updatedProviders = response.data.data.map((provider) => {
        let pharmacyId = provider.pharmacyId;

        generatedPharmacyIds[provider.providerId] = pharmacyId ?? "";

        return {
          ...provider,
          pharmacyId,
        };
      });
      setNewPharmacyIds(generatedPharmacyIds);
      setProviders(updatedProviders);
      setLoading(false);
      handleNext();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message);
      } else {
        setError("An unexpected error occurred");
      }
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedProviderId(Number(event.target.value));
  };

  const updateUserAttributesViaAPI = async (data: any) => {
    const requestData = {
      username: email,
      pharmacyID: data.pharmacyID,
      isPPAEligible: data.isPPAEligible, // Add this line
    };
    try {
      const apiUpdateUserAttr = process.env.REACT_APP_UPDATE_USER_ATTRIBUTES;
      if (!apiUpdateUserAttr) {
        throw new Error("REACT_APP_UPDATE_USER_ATTRIBUTES is not defined");
      }
      const response = await fetch(apiUpdateUserAttr, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      return true;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      return false;
    }
  };

  const handleProviderSubmit = async (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (selectedProviderId !== null) {
      setLoading(true);
      try {
        const apiURL = process.env.REACT_APP_PPA_SERVICE_PROGRAMS;
        if (!apiURL) {
          throw new Error("REACT_APP_PPA_SERVICE_PROGRAMS is not defined");
        }
        const response = await axios.post<ApiResponse<Program[]>>(apiURL, {
          userId,
          userKey,
          providerNumber,
          pharmacyId: newPharmacyIds[selectedProviderId] || pharmacyID,
          serviceProviderId: selectedProviderId,
        });

        setPrograms(response.data.data);

        const newPharmacyId = newPharmacyIds[selectedProviderId];
        if (newPharmacyId) {
          await updateUserAttributesViaAPI({
            pharmacyID: newPharmacyId,
            isPPAEligible: true,
          });
        }
        setContextPharmacyId(newPharmacyId);
        setLoading(false);
        handleNext();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(err.response.data.message);
        } else {
          setError("An unexpected error occurred");
        }
        setLoading(false);
        setSnackbarOpen(true);
      }
    } else {
      alert("Please select a pharmacy");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleDetailsClick = () => {
    alert("Details clicked");
  };

  const isUserKeyExpiringSoon = (expiryDate: string) => {
    const expiry = new Date(expiryDate);
    return differenceInMonths(expiry, new Date()) < 3;
  };

  const handleFinish = () => {
    setAuthDetails({
      providerNumber,
      providerFirstName,
      providerLastName,
      providerStore,
      pharmacistID,
      pharmacyID,
      pharmacyName,
      hpioNumber,
      hpiiNumber,
      isAdministrator,
      deviceName,
      minorId,
      providerAHPRA,
      providerEmail,
      isPPAEligible: true, // Assuming you want to set this to true in PPADetails
      prodaOrgId,
      PPA: undefined, // Assuming you have ppaData available, otherwise use undefined
    });
    setEditMode(false);
    onComplete(true); // Call the onComplete callback with true
  };

  return (
    <>
      {editMode || !pharmacyID ? (
        <Box component="div" sx={{ p: 3 }}>
          <Backdrop
            open={loading}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {activeStep === 0 && (
            <Grid container spacing={2} sx={{ mt: -2 }}>
              <Grid item xs={12}>
                <TextField
                  label="User ID"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  label="User Key"
                  value={userKey}
                  onChange={(e) => setUserKey(e.target.value)}
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUserSubmit}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <>
              <FormControl component="fieldset" sx={{ mt: -2, width: "100%" }}>
                <FormLabel component="legend" sx={{ mb: 2 }}>
                  <Typography variant="h5">
                    Select the pharmacy that this account belongs to.
                  </Typography>
                </FormLabel>
                <RadioGroup
                  value={selectedProviderId?.toString() || ""}
                  onChange={handleRadioChange}
                >
                  {providers.map((provider) => (
                    <Card
                      key={provider.providerId}
                      variant="outlined"
                      sx={{ mb: 3, boxShadow: 3, width: "100%" }}
                    >
                      <CardContent sx={{ display: "flex", borderRadius: 3 }}>
                        <Box
                          sx={{
                            flex: 1,
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6" color="primary">
                            {provider.providerName}
                          </Typography>
                          <Typography variant="body2">{`${provider.providerAddress}, ${provider.providerSuburb}, ${provider.providerPostcode}, ${provider.providerState}`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            p: 2,
                          }}
                        >
                          <FormControlLabel
                            value={provider.providerId.toString()}
                            control={<Radio />}
                            label={
                              <Typography variant="body2">
                                Select this pharmacy
                              </Typography>
                            }
                            labelPlacement="end"
                            checked={
                              selectedProviderId?.toString() ===
                              provider.providerId.toString()
                            }
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </RadioGroup>
              </FormControl>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleProviderSubmit}
                  sx={{ mr: 1 }}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <Box sx={{ mt: -2 }}>
              <Typography variant="h5" gutterBottom>
                Programs your pharmacy can submit the claims via API Integration
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List>
                    {programs.map((program) => (
                      <ListItem
                        key={program.programRegistrationId}
                        sx={{
                          boxShadow: 3,
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          marginBottom: "16px",
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              <Typography
                                component="span"
                                color="primary"
                                variant="h6"
                              >
                                {program.programName}
                              </Typography>{" "}
                              -{" "}
                              {programDescriptions[program.programName] ||
                                program.programName}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFinish} // Change this to call handleFinish
                  sx={{ mr: 1 }}
                >
                  Finish
                </Button>
              </Box>
            </Box>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={error}
          />
        </Box>
      ) : (
        <Box sx={{ p: 3, mt: -2 }}>
          <Typography variant="h5" gutterBottom>
            Pharmacy Programs Administrator(PPA)- 8CPA
          </Typography>
          {pharmacyLoading ? (
            <CircularProgress />
          ) : pharmacyError ? (
            <Typography color="error">{pharmacyError}</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ backgroundColor: "#1976d2", color: "white" }}
                    >
                      User ID
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#1976d2", color: "white" }}
                    >
                      User Key
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#1976d2", color: "white" }}
                    >
                      User Key Expiry
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#1976d2", color: "white" }}
                    >
                      Business Name
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#1976d2", color: "white" }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{userId}</TableCell>
                    <TableCell>{"*".repeat(4 || 0)}</TableCell>
                    <TableCell
                      sx={{
                        color: isUserKeyExpiringSoon(userKeyExpiry || "N/A")
                          ? "red"
                          : "inherit",
                      }}
                    >
                      {userKeyExpiry}
                    </TableCell>
                    <TableCell>{businessNamePPA}</TableCell>
                    <TableCell>
                      <IconButton onClick={handleEditClick}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={handleDetailsClick}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {isUserKeyExpiringSoon(userKeyExpiry || "N/A") && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography color="error">
                          Your user key is expiring soon. Please renew it as
                          soon as possible. By click on the edit icon
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </>
  );
};

export default PPADetails;
