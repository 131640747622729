import Box from '@mui/material/Box';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAppointment } from '../../contexts/AppointmentContext';
import { IWeekSchedule, useBatchAvailabilityLookup } from '../../hooks/useBatchAvailabilityLookup';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

import moment, { Moment } from 'moment-timezone';
import { Dialog, TextField, Typography, alpha } from '@mui/material';
import { PharmacyAvailability, ServiceSchedule } from '../../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import { CheckOutlined } from '@mui/icons-material';
import { useServiceSchedule } from '../../hooks/useServiceSchedule';
import { weekdays } from '../../constants';
import axios from 'axios';
import AuthContext from 'contexts/AuthContext';
import { logger } from 'utils/logger';
import { EMode } from 'contexts/CalendarContext';
import { IBooking } from 'interfaces/healthcare-reservation/IBooking';

type TSelectDateProps = {
  onSelectDate: (availability: PharmacyAvailability) => void;
  defaultValue: IBooking | null;
  mode?: EMode;
};

const SelectDate = ({ onSelectDate, defaultValue, mode }: TSelectDateProps) => {
  const { pharmacyID } = useContext(AuthContext);
  const { selectedService, loading, setLoading } = useAppointment();
  const { getAvailabilityByDateAndService, loadingAvailabiltyByDateAndServiceId } = useBatchAvailabilityLookup();

  const [scheduleInAweek, setScheduleInAWeek] = useState<IWeekSchedule | null>(null);
  const [selectedAvailability, setSelectedavailability] = useState<PharmacyAvailability | null>(null);
  const [openTimePicker, setOpenTimepicker] = useState(false);
  const timePickerContainerRef = useRef(null);
  const [availabilities, setAvailabiities] = useState<PharmacyAvailability[]>([]);
  const { getServiceSchedule, loadingGetServiceSchedule } = useServiceSchedule();
  const [schedule, setSchedule] = useState<ServiceSchedule | null>(null);

  const [selectedDate, setSelectedDate] = useState<Moment | null>(
    defaultValue ? moment(defaultValue.timeslot).tz('Australia/Sydney') : moment().tz('Australia/Sydney')
  );

  const getRoomSchedule = async (date: Moment) => {
    const URL = process.env.REACT_APP_HEALTHCARE_RESERVATION_API || '';
    return await axios.get(`${URL}/availability-room?pharmacyID=${pharmacyID}&date=${date.format('YYYY-MM-DD')}`);
  };

  const shouldDisableAvailability = (availability: PharmacyAvailability) => {
    // wait for the init schedule is complete
    if (!scheduleInAweek) {
      return false;
    }

    const value = moment(availability.timeslot).tz('Australia/Sydney');

    // disable if the time has passed
    if (value.clone().isBefore(moment().tz('Australia/Sydney'), 'minute')) {
      return true;
    }

    //
    const todayScheduleTime = availabilities.find((availability) =>
      moment(availability.timeslot).tz('Australia/Sydney').isSame(value.clone().toISOString())
    );

    if (todayScheduleTime?.isAvailable === undefined) {
      return true;
    }

    return !todayScheduleTime.isAvailable;
  };

  // service schedule
  useEffect(() => {
    if (selectedService?.serviceScheduleID) {
      getServiceSchedule({
        variables: {
          serviceScheduleID: selectedService.serviceScheduleID,
        },
        onCompleted(data) {
          const schedule = data.getServiceSchedule as ServiceSchedule;
          setSchedule(schedule);

          const scheduleArray = Object.entries(schedule).filter(([key, _value]) => weekdays.includes(key));

          const scheduleInAweek: IWeekSchedule = Object.fromEntries(scheduleArray);
          setScheduleInAWeek(scheduleInAweek);
        },
      });
    }
  }, [selectedService]);

  useEffect(() => {
    if (selectedDate && selectedService && scheduleInAweek) {
      getRoomSchedule(selectedDate).then((response) => {
        logger.log('schedule room response ', response);

        if (!response.data.isAvailable) {
          logger.log('response.data.isAvailable ', response);

          return;
        }

        getAvailabilityByDateAndService(selectedService.serviceId, selectedDate, {
          onCompleted(data) {
            const availabilities = data.queryAvailabiltyByServiceIdAppointmentDt?.items as PharmacyAvailability[];
            logger.log(availabilities.length, 'Rows');

            const date = moment(selectedDate).tz('Australia/Sydney');
            const todaySchedule = scheduleInAweek[selectedDate.format('dddd').toLowerCase() as keyof IWeekSchedule];

            const getDefaultSelected = availabilities.find(
              (avail) => avail.availabilityID === defaultValue?.availabilityID
            );

            const sortedTimes: PharmacyAvailability[] = [...availabilities]
              .sort((a, b) => (moment(a.timeslot).isAfter(moment(b.timeslot)) ? 1 : -1))
              .map((available) => ({
                ...available,
                isAvailable:
                  todaySchedule?.isClosed !== undefined
                    ? !todaySchedule.isClosed
                    : getDefaultSelected?.availabilityID === available.availabilityID
                    ? true
                    : false,
              }));

            // populate thetoday schedule closed to the availability, if closed, then the availability should not available
            setAvailabiities(sortedTimes);

            // search for selected date on the availabilities

            const selectedTime = sortedTimes.find(
              (avail) => date.isSame(moment(avail.timeslot).tz('Australia/Sydney')) && avail.isAvailable
            );

            // default availability selected is when it's available and not the past time
            const availability = sortedTimes.find(
              (avail) =>
                avail.isAvailable &&
                moment(avail.timeslot).tz('Australia/Sydney').isAfter(moment().tz('Australia/Sydney'))
            );

            const availData = getDefaultSelected || selectedTime || availability || null;
            setSelectedavailability(availData);

            if (availData) {
              onSelectDate(availData);
            }
          },
        });
      });
    }
  }, [selectedDate, selectedService, scheduleInAweek, defaultValue]);

  // useEffect(() => {
  //   if (availabilities.length && defaultValue && mode === EMode.EDIT) {
  //     logger.log('availabilities ', availabilities);
  //     logger.log('defaultValue ', defaultValue);

  //     const getDefaultSelected = availabilities.find((avail) => avail.availabilityID === defaultValue.availabilityID);

  //     logger.log('getDefaultSelected ', getDefaultSelected);

  //     if (getDefaultSelected) {
  //       setSelectedavailability(getDefaultSelected);
  //     }
  //   }
  // }, [availabilities, defaultValue, mode]);

  
  useEffect(() => {
    if (!loadingAvailabiltyByDateAndServiceId && !loadingGetServiceSchedule) {
      setLoading(false);
    }
  }, [loadingGetServiceSchedule, loadingAvailabiltyByDateAndServiceId]);

  const isLoading = loadingGetServiceSchedule || loadingAvailabiltyByDateAndServiceId || loading;
  const lastAvailableDateService = moment(schedule?.lastAvailableDate);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
          flex: 1,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <DatePicker
            label="Booking Date"
            timezone="Australia/Sydney"
            disablePast
            format="DD/MM/YYYY"
            disabled={isLoading}
            onChange={(value) => {
              setSelectedDate(value);
            }}
            value={selectedDate}
            shouldDisableDate={(day) => {
              // if the shcedule is not initiate yet, don't disable
              if (!scheduleInAweek) {
                return false;
              }

              const todaySchedule = scheduleInAweek[day.format('dddd').toLowerCase() as keyof IWeekSchedule];

              if (todaySchedule?.isClosed === undefined || todaySchedule.isClosed === null) {
                return true;
              }

              if (lastAvailableDateService.isValid() && day.isAfter(lastAvailableDateService, 'date')) {
                return true;
              }

              return todaySchedule.isClosed;
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
              },
            }}
          />
        </Box>
        <Box sx={{ flex: 1, position: 'relative', width: '100%' }} ref={timePickerContainerRef}>
          <TimePicker
            open={openTimePicker}
            onOpen={() => setOpenTimepicker(true)}
            onClose={() => setOpenTimepicker(false)}
            disabled={isLoading}
            label="Booking Time"
            value={selectedAvailability ? moment(selectedAvailability.timeslot).tz('Australia/Sydney') : null}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
              },
              popper: {
                container: timePickerContainerRef.current,

                sx: {
                  width: '100%',
                },
              },
            }}
            slots={{
              dialog: Dialog,
              layout: (props) => {
                return <>{props.children}</>;
              },
            }}
            viewRenderers={{
              hours: (props) => {
                return (
                  <Box
                    component={'ul'}
                    sx={(theme) => ({
                      position: 'relative',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      maxHeight: '20rem',
                      height: '100%',
                      p: 0,
                      m: 0,
                      overflowY: 'scroll',
                      '& li': {
                        listStyle: 'none',
                        p: '1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      },
                    })}
                  >
                    {availabilities.length === 0 ? (
                      <Box
                        sx={(theme) => ({
                          textAlign: 'center',
                        })}
                        component={'li'}
                        key={'not-available'}
                      >
                        <Typography>Unavailable</Typography>
                      </Box>
                    ) : (
                      availabilities.map((availability) => {
                        const isSelected = availability.availabilityID === selectedAvailability?.availabilityID;

                        const isNotAvailable = shouldDisableAvailability(availability);
                        return (
                          <Box
                            sx={(theme) => ({
                              cursor: isNotAvailable ? 'not-allowed' : 'pointer',
                              textAlign: 'center',
                              background: isSelected ? alpha(theme.palette.primary.main, 0.1) : 'inherit',
                              '&:hover': {
                                background: isNotAvailable ? 'inherit' : alpha(theme.palette.primary.main, 0.1),
                              },
                            })}
                            component={'li'}
                            key={availability.availabilityID}
                            onClick={() => {
                              if (isNotAvailable) {
                                return;
                              }

                              setSelectedavailability(availability);
                              setOpenTimepicker(false);

                              // inform to the parent
                              onSelectDate(availability);
                            }}
                          >
                            <Typography>
                              {moment(availability.timeslot).tz('Australia/Sydney').format('hh:mm A')}
                            </Typography>
                            {isSelected && <CheckOutlined color="primary" />}
                          </Box>
                        );
                      })
                    )}
                  </Box>
                );
              },
              minutes: undefined,
              meridiem: undefined,
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <TextField
            fullWidth
            sx={{ flex: 1 }}
            label="Duration (in Minutes)"
            type="number"
            value={selectedAvailability?.durationInSeconds ? selectedAvailability.durationInSeconds / 60 : 0}
            name="durationInSeconds"
            disabled
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default SelectDate;
