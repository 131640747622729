import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Paper,
  Grid,
  Box,
  Checkbox,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormData } from '../UTIProtocolForm';
import { MedicationOutlined, CalendarMonth, Phone, Textsms } from '@mui/icons-material';
import { orange, green, red } from '@mui/material/colors';
import dayjs from 'dayjs';
import { PharmacistActionStatus } from '../../../../../utils/decisionTree';

interface Step9ReferralProps {
  control: Control<FormData>;
  pharmacistInfo: { message: string; status: PharmacistActionStatus };
}

const Step9Referral: React.FC<Step9ReferralProps> = ({ control, pharmacistInfo }) => {
  const followUpDate = dayjs().add(3, 'day');
  const followUpMethod = useWatch({
    control,
    name: 'pharmacistCare.followUpMethod',
  });
  const referral = useWatch({
    control,
    name: 'referral',
  });

  const hasConsentedToFollowUp = followUpMethod && followUpMethod.length > 0;

  const getAlertColor = (status: PharmacistActionStatus) => {
    switch (status) {
      case PharmacistActionStatus.Emergency:
        return red;
      case PharmacistActionStatus.ReferGP:
        return orange;
      case PharmacistActionStatus.Proceed:
        return green;
      default:
        return orange;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <MedicationOutlined sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Referral</Typography>
            </Box>
            <Controller
              name="referral"
              control={control}
              render={({ field }) => (
                <FormControl component="fieldset">
                  <RadioGroup {...field}>
                    <FormControlLabel value="no_referral" control={<Radio />} label="No referral required" />
                    <FormControlLabel value="gp_referral" control={<Radio />} label="Refer to GP" />
                    <FormControlLabel
                      value="emergency_referral"
                      control={<Radio />}
                      label="Refer to emergency department"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
            {pharmacistInfo.message && (
              <Alert
                severity={pharmacistInfo.status === PharmacistActionStatus.Proceed ? 'success' : 'warning'}
                sx={{
                  mt: 2,
                  mb: 2,
                  backgroundColor: getAlertColor(pharmacistInfo.status)[100],
                  color: getAlertColor(pharmacistInfo.status)[900],
                  '& .MuiAlert-icon': {
                    color: getAlertColor(pharmacistInfo.status)[900],
                  },
                }}
              >
                {pharmacistInfo.message}
              </Alert>
            )}
            <Controller
              name="referralNotes"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Additional Referral Notes" multiline rows={4} fullWidth margin="normal" />
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CalendarMonth sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Pharmacist Care</Typography>
            </Box>
            <Controller
              name="pharmacistCare.followUpDate"
              control={control}
              defaultValue={followUpDate.toISOString()}
              render={({ field }) => (
                <DatePicker
                  label="Follow-up Date"
                  format="DD/MM/YYYY"
                  value={dayjs(field.value)}
                  onChange={(newValue) => field.onChange(newValue?.toISOString())}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                    },
                  }}
                />
              )}
            />
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 2 }}>
              Follow-up date is set to 3 days from today by default.
            </Typography>
            <Controller
              name="pharmacistCare.followUpMethod"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <FormControl component="fieldset">
                  <Typography variant="subtitle1" gutterBottom>
                    Follow-up Method:
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes('phone')}
                        onChange={(e) => {
                          const newValue = e.target.checked
                            ? [...field.value, 'phone']
                            : field.value.filter((v) => v !== 'phone');
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Phone sx={{ mr: 1 }} /> Phone Call
                      </Box>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes('text')}
                        onChange={(e) => {
                          const newValue = e.target.checked
                            ? [...field.value, 'text']
                            : field.value.filter((v) => v !== 'text');
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Textsms sx={{ mr: 1 }} /> Text Message
                      </Box>
                    }
                  />
                </FormControl>
              )}
            />
            {hasConsentedToFollowUp && (
              <Alert severity="info" sx={{ mt: 2 }}>
                Patient has consented to receive follow-up {followUpMethod.join(' and ')} communication.
              </Alert>
            )}
            <Controller
              name="pharmacistCare.followUpNotes"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Follow-up Notes" multiline rows={4} fullWidth margin="normal" />
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default Step9Referral;
