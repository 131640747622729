import { ArrowCircleUpOutlined } from '@mui/icons-material';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { Box, Container, Paper, Typography, Button } from '@mui/material';
import AuthContext from 'contexts/AuthContext';
import { ReactNode, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logger } from 'utils/logger';

type PlansAuthorizerProps = {
  children: ReactNode;
};

const PlansAuthorizer = ({ children }: PlansAuthorizerProps) => {
  const { planName } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // logger.log('Current plan:', planName);
  // logger.log('Current pathname:', pathname);

  const isS3RPlan = planName && planName === 'S3R';
  const isMonthlyPlan = planName && planName === 'MONTHLY';
  const isYearlyPlan = planName && planName === 'YEARLY';

  // logger.log('Is S3R Plan:', isS3RPlan);
  // logger.log('Is Monthly Plan:', isMonthlyPlan);
  // logger.log('Is Yearly Plan:', isYearlyPlan);

  const s3rRoutesPrefix = '/pages/safe';
  const settingsRoutesPrefix = '/pages/settings';

  useEffect(() => {
    if (isS3RPlan && pathname === '/dashboard-air') {
      navigate('/pages/safe/sale');
    }
  }, [isS3RPlan, pathname, navigate]);

  if (isS3RPlan && (pathname.startsWith(s3rRoutesPrefix) || pathname.startsWith(settingsRoutesPrefix))) {
    return <>{children}</>;
  }

  if (isMonthlyPlan || isYearlyPlan) {
    return <>{children}</>;
  }

  return (
    <Box component="div" sx={{ p: 3 }}>
      <Paper
        sx={{
          p: '2rem',
          background: 'white',
          minHeight: '80vh',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5 }}>
            <LockOutlined sx={{ fontSize: 100, color: 'error.main', mb: 3 }} />
            <Typography variant="h4" gutterBottom>
              Unauthorized Access
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Sorry, you do not have permission to view this page. Please upgrade plan to access this page.
            </Typography>
            <Box mt={4}>
              <Button
                startIcon={<ArrowCircleUpOutlined />}
                variant="contained"
                color="primary"
                onClick={() => navigate('/pricing')}
              >
                Upgrade Plan
              </Button>
            </Box>
          </Container>
        </Box>
      </Paper>
    </Box>
  );
};

export default PlansAuthorizer;
