import React from 'react';
import { Grid, Paper, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { Controller, Control, FieldPath } from 'react-hook-form';
import { FormData } from '../UTIProtocolForm';
import { AlertCircle } from 'lucide-react';
import { grey } from '@mui/material/colors';

const moderateRiskFactorsList = [
    { key: 'recentAntibiotics', label: 'Recent (within the previous 3 months) or frequent treatment with antibiotics' },
    { key: 'recentHealthcareFacility', label: 'Recent (within the previous 3 months), frequent or long-term resident of a healthcare facility, history of being hospitalised in the last 4 weeks' },
    { key: 'recentOverseasTravel', label: 'Overseas travel within the previous 3 months, or overseas travel within previous 6 months to regions with high prevalence of antimicrobial resistance (e.g., South Asia, South-East Asia) particularly if the patient has received medical care or treatment with antibiotics in that region' },
    { key: 'multidrugResistantBacteria', label: 'Infection with multidrug-resistant bacteria within the previous 3 months' },
    { key: 'stiRiskAge', label: 'Age < 29 years or previous STI' },
    { key: 'stiRiskSexualContact', label: 'Sexual contact without a condom/dental dam outside a mutually monogamous relationship, a new sexual partner in last 60 days, a sex partner recently treated for an STI, sexual contact with a sex worker' },
    { key: 'sglt2Inhibitors', label: 'Currently taking medicines that increase the risk of UTI, such as sodium-glucose cotransporter-2 (SGLT2) inhibitors' },
    { key: 'iudInSitu', label: 'Intrauterine device (IUD) in situ (for more than 3 months)' },
];

interface StepProps {
    control: Control<FormData>;
}

const Step5ModerateRiskFactors: React.FC<StepProps> = ({ control }) => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <AlertCircle size={20} style={{ marginRight: '10px' }} />
                    Moderate Risk Factors
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    Does the patient have any of the following moderate-risk factors?
                </Typography>
                <Grid container spacing={1}>
                    {moderateRiskFactorsList.map((factor) => (
                        <Grid item xs={12} key={factor.key}>
                            <Controller
                                name={`riskFactors.moderateRisk.${factor.key}` as FieldPath<FormData>}
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={!!field.value} />}
                                        label={<Typography variant="body1">{factor.label}</Typography>}
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: '1rem' } }}
                                    />
                                )}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    </Grid>
);

export default Step5ModerateRiskFactors;