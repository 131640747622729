import AWS from 'aws-sdk';

const isDevelopment = process.env.NODE_ENV === 'development';

// Configure AWS SDK (make sure you have the necessary environment variables set)
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID as string,
  }),
});

const cloudWatchLogs = new AWS.CloudWatchLogs();

const sendToCloudWatch = async (level: string, ...args: any[]) => {
  const logGroupName = process.env.REACT_APP_CLOUDWATCH_LOG_GROUP_NAME;
  if (!logGroupName) {
    // eslint-disable-next-line no-console
    console.error('CloudWatch Log Group Name is not defined');
    return;
  }

  const logStreamName = `frontend-logs-${new Date().toISOString().split('T')[0]}`;

  try {
    // Check if the log stream exists
    const streams = await cloudWatchLogs
      .describeLogStreams({
        logGroupName,
        logStreamNamePrefix: logStreamName,
      })
      .promise();

    // Create the log stream only if it doesn't exist
    if (!streams.logStreams?.some((stream) => stream.logStreamName === logStreamName)) {
      await cloudWatchLogs
        .createLogStream({
          logGroupName,
          logStreamName,
        })
        .promise();
    }

    // Send log to CloudWatch
    await cloudWatchLogs
      .putLogEvents({
        logGroupName,
        logStreamName,
        logEvents: [
          {
            message: `[${level}] ${args.map((arg) => JSON.stringify(arg)).join(' ')}`,
            timestamp: new Date().getTime(),
          },
        ],
      })
      .promise();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to send log to CloudWatch:', error);
  }
};

export const logger = {
  log: (...args: any[]) => {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.log(...args);
    } else {
      sendToCloudWatch('INFO', ...args);
    }
  },
  warn: (...args: any[]) => {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.warn(...args);
    } else {
      sendToCloudWatch('WARN', ...args);
    }
  },
  error: (...args: any[]) => {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
    // Always send errors to CloudWatch, even in development
    sendToCloudWatch('ERROR', ...args);
  },
};
