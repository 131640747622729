import { IProduct } from "../interfaces/IProduct";
import { uuid } from "../utils";

export type StockFormErrors = Record<
  keyof IProduct,
  {
    error: boolean;
    message: string;
  }
>;

export const INIT_ERRORS: StockFormErrors = {
  productID: {
    error: false,
    message: ""
  },
  gtin: {
    error: false,
    message: ""
  },
  pharmacyID: {
    error: false,
    message: ""
  },
  genericName: {
    error: false,
    message: ""
  },
  description: {
    error: false,
    message: ""
  },
  uom: {
    error: false,
    message: ""
  },
  retailShelfPack: {
    error: false,
    message: ""
  },
  manufacturer: {
    error: false,
    message: ""
  },
  manufacturerCode: {
    error: false,
    message: ""
  },
  unitPriceExcludingGst: {
    error: false,
    message: ""
  },
  gstToPharmacy: {
    error: false,
    message: ""
  },
  gstToConsumer: {
    error: false,
    message: ""
  },
  drugSchedule: {
    error: false,
    message: ""
  },
  drugStrength: {
    error: false,
    message: ""
  },
  uomQuantity: {
    error: false,
    message: ""
  },
  minimumOrder: {
    error: false,
    message: ""
  },
  orderMultiple: {
    error: false,
    message: ""
  },
  supplier: {
    error: false,
    message: ""
  },
  metadata: {
    error: false,
    message: ""
  },
  createdAt: {
    error: false,
    message: ""
  },
  updatedAt: {
    error: false,
    message: ""
  },
  imageUrl: {
    error: false,
    message: ""
  }
};


export const getInitProduct = (): IProduct => ({
  productID: "",
  gtin: "",
  pharmacyID: "",
  genericName: "",
  description: "",
  uom: "",
  retailShelfPack: "",
  manufacturer: "",
  manufacturerCode: "",
  unitPriceExcludingGst: "0",
  gstToPharmacy: "",
  gstToConsumer: "",
  drugSchedule: "",
  drugStrength: "",
  uomQuantity: "",
  minimumOrder: "",
  orderMultiple: "",
  supplier: suppliers[3].supplierName,
  metadata: "",
  createdAt: "",
  updatedAt: ""
});

export const suppliers = [
  {
    supplierName: "Australian Pharma Co.",
  },
  {
    supplierName: "Melbourne Medical Supplies",
  },
  {
    supplierName: "Brisbane Bio Pharma",
  },
  {
    supplierName: "CH2",
  },
  {
    supplierName: "Sigma Healthcare",
  },
  {
    supplierName: "Symbion",
  },
  {
    supplierName: "API (Australian Pharmaceutical Industries)",
  },
  {
    supplierName: "EBOS Group",
  },
  {
    supplierName: "TerryWhite Chemmart",
  },
  {
    supplierName: "Good Price Pharmacy Warehouse",
  },
  {
    supplierName: "Medela Australia Pty Ltd",
  },
];

