import React from 'react';
import { Amplify } from 'aws-amplify';

import AllRoutes from './routes/Routes';

import { ApolloProvider } from '@apollo/client';
import client from './ApolloClient'; // Adjust the path according to where your Apollo Client instance is configured
import { CssBaseline } from '@mui/material';
import AuthProvider from './providers/AuthProvider'; // Path to your AuthProvider
import { CustomerProvider } from './contexts/CustomerContext';
import * as Sentry from '@sentry/react';
import { PharmacyProvider } from './contexts/PharmacyContext'; // Import the PharmacyProvider
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// For Default import Default.scss
import './assets/scss/Default.scss';

// Other
import './assets/scss/Icons.scss';
import './assets/scss/Landing.scss';
import { ModalProvider } from './contexts/ModalContext';
import GlobalMUIModal from './components/GlobalMUIModal';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { getAmplifyConfig } from './configs';
import { VaccineDataProvider } from './contexts/VaccineDataContext';

const amplifyConfig = getAmplifyConfig();

// configure fake backend
Amplify.configure(amplifyConfig);
const DSN = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  dsn: DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <CssBaseline />
      <AuthProvider>
        <PharmacyProvider>
          <CustomerProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                <SnackbarProvider>
                  <ModalProvider>
                    <VaccineDataProvider>
                      <React.Fragment>
                        <AllRoutes />
                      </React.Fragment>
                      <GlobalMUIModal />
                    </VaccineDataProvider>
                  </ModalProvider>
                </SnackbarProvider>
              </Sentry.ErrorBoundary>
            </LocalizationProvider>
          </CustomerProvider>
        </PharmacyProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
