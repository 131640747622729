import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountCircle from "@mui/icons-material/AccountCircleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import LocalPharmacyOutlinedIcon from "@mui/icons-material/LocalPharmacyOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  updateUserAttributes,
  completeStep,
  setAuthenticationStatus,
  setRegistrationStep,
} from "../../redux/actions";
import { AppDispatch, RootState } from "../../redux/store";
import GooglePlacesInput from "./GooglePlacesInput";
import AuthContext from "../../contexts/AuthContext";
import { CREATE_PROVIDER_MUTATION } from "../../graphql-queries";
import { useIdToken } from "../../hooks/useIdToken";
import { logger } from '../../utils/logger';

const schema = yup.object({
  providerNumber: yup.string().required("Provider number is required"),
});

const { v4: uuidv4 } = require("uuid");
const pharmacistID = uuidv4(); // Generates a new UUID for dhs-messageId

interface PharmacyDetailsProps {
  onComplete: (data: {
    providerNumber: string;
    providerFirstName: string;
    providerLastName: string;
    providerStore: string;
    pharmacistID: string;
    pharmacyID: string;
    hpioNumber: string;
    hpiiNumber: string;
    isAdministrator: boolean;
    providerAHPRA: string;
    pharmacyName: string;
    isPPAEligible: boolean;
    prodaOrgId: string;
  }) => void;
}
interface AccessListItem {
  code: any;
  name: string;
  hasAccess: boolean;
}

interface AccessDetail {
  Name: string;
  displayAs: string;
}

declare global {
  interface Window {
    initMap: () => void;
  }
}

const accessDetails: Record<string, AccessDetail> = {
  IDV: {
    Name: "Identify individual details",
    displayAs: "Identify Individual",
  },
  IDGST: {
    Name: "Update Indigenous status",
    displayAs: "Indigenous Status",
  },
  ADINDT: {
    Name: "Add/Remove additional vaccine indicator",
    displayAs: "Additional Vaccines Required",
  },
  NATIMM: {
    Name: "Record natural immunity",
    displayAs: "Natural Immunity",
  },
  MEDCON: {
    Name: "Record medical contraindication",
    displayAs: "Medical Contraindication",
  },
  RECUPD: {
    Name: "Update vaccination encounter",
    displayAs: "Update Encounter",
  },
  RECENC: {
    Name: "Record encounter",
    displayAs: "Record Encounter",
  },
  NXTDUE: {
    Name: "Record planned catch up date",
    displayAs: "Planned Catch Up",
  },
  IHSPDS: {
    Name: "Get immunisation history statement in PDF",
    displayAs: "Immunisation History Statement",
  },
  GETIHS: {
    Name: "Get immunisation history details",
    displayAs: "Immunisation History",
  },
  GETMEDCON: {
    Name: "Get medical contraindication history",
    displayAs: "Medical Contraindication History",
  },
  GETNATIMM: {
    Name: "Get natural immunity history",
    displayAs: "Natural Immunity History",
  },
  GETVACTRL: {
    Name: "Get vaccine trial history",
    displayAs: "Vaccine Trial History",
  },
};

const PharmacyDetails: React.FC<PharmacyDetailsProps> = ({ onComplete }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { loading, userAttributeUpdate, error } = useSelector(
    (state: RootState) => state.Auth
  );
  const [loadingState, setLoadingState] = useState(false);
  const [emailForRedirect, setEmailForRedirect] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [accessList, setAccessList] = useState<AccessListItem[]>([]);
  const inputRef = useRef(null);
  // const [createPharmacy, { data }] = useMutation(CREATE_PROVIDER_MUTATION);
  const [localError, setLocalError] = useState("");
  const { deviceName, minorId, prodaOrgId } = useContext(AuthContext);
  const [apiResponseData, setApiResponseData] = useState("");
  const { idToken, isLoading, error: idTokenError } = useIdToken();

  let formattedAddress;

  useEffect(() => {
    if (userAttributeUpdate) {
      logger.log(userAttributeUpdate);
    } else if (error) {
      setLocalError(error);
    }
  }, [dispatch, error, navigate, userAttributeUpdate]);

  useEffect(() => {
    logger.log("PharmacyDetails rendered");
    logger.log("Current error state:", error);
    logger.log("Current errorMessage state:", errorMessage);
  }, [error, errorMessage]);

  const handleContinueClick = () => {
    dispatch(completeStep(3));
    dispatch(setRegistrationStep(4));
    dispatch(setAuthenticationStatus(true));
    // navigate("/register/step-4");

    const dataToPass = {
      providerNumber: control._formValues.providerNumber,
      providerFirstName: control._formValues.FirstName,
      providerLastName: control._formValues.LastName,
      providerStore: control._formValues.pharmacyName,
      pharmacistID: pharmacistID,
      pharmacyName: control._formValues.pharmacyName,
      pharmacyID: apiResponseData, // Assuming responseData contains the pharmacyID
      hpioNumber: control._formValues.hpioNumber || "",
      hpiiNumber: control._formValues.hpiiNumber || "",
      isAdministrator: true,
      providerAHPRA: control._formValues.providerAHPRA,
      isPPAEligible: control._formValues.isPPAEligible,
      prodaOrgId: control._formValues.prodaOrgId,
    };
    logger.log("🚀 ~ handleContinueClick ~ dataToPass:", dataToPass);
    onComplete(dataToPass);
  };

  const state = useSelector((state: RootState) => state);
  const email =
    state.Auth?.user?.tokens?.idToken?.payload?.email ??
    state.Auth?.user?.session?.tokens?.idToken?.payload?.email;
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      providerNumber: "",
      pharmacyAddress: "",
      pharmacyName: "",
      providerAHPRA: "",
      FirstName: "",
      LastName: "",
      mobilePhone: "",
      termsAndConditions: true,
    },
  });

  const title =
    accessList.length > 0
      ? t("Access allowed by AIR")
      : t("Validate Details with AIR");

  const updateAddress = (formattedAddress: any) => {
    const address = formattedAddress;
    setValue("pharmacyAddress", address);
  };

  const onSubmit = async (data: any) => {
    setLoadingState(true);
    logger.log(data);

    let response = await makeApiCall(data.providerNumber, setErrorMessage);
    logger.log(response);
    if (response) {
      await updateData(data);
      await updateUserAttributesViaAPI(data);
    }

    const attributes = {
      name: "custom:providerNumber" || "",
      value: data.providerNumber || "",
      email: email || "",
    };
    logger.log("ATTRIBUTES", attributes);
    // dispatch(updateUserAttributes(attributes));
    setLoadingState(false);
  };

  const updateData = async (data: any) => {
    const input = {
      providerAHPRA: data.providerAHPRA || "",
      createdDate: data.createdDate || dayjs().format("YYYY-MM-DD"),
      isActive: true,
      pharmacyAddress: data.pharmacyAddress || "",
      // pharmacyID: ,
      minorId: minorId || "",
      deviceName: deviceName || "",
      pharmacistID: pharmacistID,
      pharmacyName: data.pharmacyName || "",
      providerNumber: data.providerNumber || "",
      pharmacyLoginId: email || "",
      pharmacyPhoneNumber: data.mobilePhone || "",
      pharmacyEmailAddress: data.pharmacyEmailAddress || "",
      subscription: data.subscription || "",
      providerFirstName: data.FirstName || "",
      providerLastName: data.LastName || "",
      prodaOrgId: prodaOrgId || "",
    };
    logger.log("🚀 ~ Skipping Create Pharmacy ~ input:", input);
    // createPharmacy({ variables: { input: input } })
    //   .then((response) => {
    //     logger.log("Update successful", response.data);
    //   })
    //   .catch((error) => {
    //     if (error.networkError) {
    //       console.error("Network error:", error.networkError);
    //     }
    //     if (error.graphQLErrors) {
    //       error.graphQLErrors.forEach((graphQLError: { message: any }) => {
    //         console.error("GraphQL error:", graphQLError.message);
    //       });
    //     } else {
    //       console.error("Error updating patient", error);
    //     }
    //   });
  };

  const makeApiCall = async (
    providerNumber: string,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const requestData = {
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
      informationProvider: {
        providerNumber: providerNumber,
      },
    };
    logger.log(requestData);
    try {
      if (!idToken) {
        console.error("No authentication token available");
        setErrorMessage("Authentication error");
        return false;
      }

      const airAuthorisationURL = process.env.REACT_APP_AIR_AUTHORISATION;
      if (!airAuthorisationURL) {
        throw new Error("REACT_APP_AIR_AUTHORISATION is not defined");
      }
      const response = await fetch(airAuthorisationURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (
        data.success &&
        (!data.data.errors || data.data.errors.length === 0)
      ) {
        logger.log(data);
        setAccessList(
          data.data.accessList.filter(
            (item: AccessListItem) => item.code !== "GETCOV19PDF"
          ) || []
        );
        setErrorMessage("");
        return true;
      } else if (
        data.success &&
        data.data.errors &&
        data.data.errors.length > 0
      ) {
        setErrorMessage(
          data.data.errors[0].code + ": " + data.data.errors[0].message
        );
        return false;
      } else {
        setErrorMessage("An unexpected error occurred");
        return false;
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const updateUserAttributesViaAPI = async (data: any) => {
    const requestData = {
      username: email,
      pharmacyName: data.pharmacyName,
      pharmacyAddress: data.pharmacyAddress,
      pharmacyPhoneNumber: data.mobilePhone,
      pharmacyEmailAddress: data.pharmacyEmailAddress || email,
      pharmacyLoginId: email,
      ownerAHPRA: data.providerAHPRA,
      ownerFirstName: data.FirstName,
      ownerLastName: data.LastName,
      ownerPhoneNumber: data.ownerPhoneNumber,
      providerNumber: data.providerNumber,
      providerAHPRA: data.providerAHPRA,
      isActive: true,
      deviceName: deviceName,
      minorId: minorId,
      pharmacistID: pharmacistID,
      providerFirstName: data.FirstName,
      providerLastName: data.LastName,
      isPPAEligible: true,
      hpiiNumber: data.hpiiNumber,
      hpioNumber: data.hpioNumber,
      isAdministrator: true,
      prodaOrgId: prodaOrgId,
    };

    logger.log(requestData);
    try {
      const apiCreatePharmacy = process.env.REACT_APP_CREATE_PHARMACY;
      if (!idToken) {
        console.error("No authentication token available");
        return false;
      }
      if (!apiCreatePharmacy) {
        throw new Error("REACT_APP_CREATE_PHARMACY is not defined");
      }
      const response = await fetch(apiCreatePharmacy, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      logger.log(responseData);
      setApiResponseData(responseData.pharmacyID); // Store the response data

      return true;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      return false;
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (idTokenError) {
    return (
      <Typography color="error">
        Error loading authentication token: {idTokenError}
      </Typography>
    );
  }

  return (
    <>
      <Backdrop open={loadingState} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h4" sx={{ mt: 0 }}>
        {title}
      </Typography>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 1,
        }}
      ></Grid>

      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {typeof error === "string" ? error : "An unknown error occurred"}
        </Alert>
      )}

      {accessList.length > 0 ? (
        <Box>
          {accessList.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" sx={{ mb: 1 }}>
                {accessDetails[item.code]?.displayAs}
              </Typography>
              <Switch
                checked={item.hasAccess}
                inputProps={{ "aria-label": "read only switch" }}
                disabled
              />
            </Box>
          ))}
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Typography variant="body2" sx={{ mb: 0 }}>
              {t("Contact AIR for additional permissions.")}
            </Typography>
          </Box>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={handleContinueClick}>
              {t("Continue")}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AccountCircle sx={{ color: "action.active", mr: 1, my: 2 }} />
            <Controller
              name="providerNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Provider Number")}
                  error={!!errors.providerNumber}
                  helperText={errors.providerNumber?.message}
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled={loadingState}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AccountCircle sx={{ color: "action.active", mr: 1, my: 2 }} />
            <Controller
              name="FirstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Owner First Name")}
                  error={!!errors.FirstName}
                  helperText={errors.FirstName?.message}
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled={loadingState}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AccountCircle sx={{ color: "action.active", mr: 1, my: 2 }} />
            <Controller
              name="LastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Owner Last Name")}
                  error={!!errors.LastName}
                  helperText={errors.LastName?.message}
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled={loadingState}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <HealthAndSafetyIcon
              sx={{ color: "action.active", mr: 1, my: 2 }}
            />
            <Controller
              name="providerAHPRA"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Owner APHRA Number")}
                  error={!!errors.providerAHPRA}
                  helperText={errors.providerAHPRA?.message}
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled={loadingState}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <LocalPharmacyOutlinedIcon
              sx={{ color: "action.active", mr: 1, my: 2 }}
            />
            <Controller
              name="pharmacyName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Pharmacy Name")}
                  error={!!errors.pharmacyName}
                  helperText={errors.pharmacyName?.message}
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled={loadingState}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <BusinessOutlinedIcon
              sx={{ color: "action.active", mr: 1, my: 2 }}
            />
            <Controller
              name="pharmacyAddress"
              control={control}
              render={({ field }) => (
                <GooglePlacesInput
                  apiKey="AIzaSyAv2vANIvhPQU6wIOtn54KvfiQ8zFfCYBA"
                  onPlaceSelected={(
                    place: google.maps.places.PlaceResult | null
                  ): void => {
                    if (place) {
                      formattedAddress = place.formatted_address;
                      updateAddress(formattedAddress);
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <LocalPhoneOutlinedIcon
              sx={{ color: "action.active", mr: 1, my: 2 }}
            />
            <Controller
              name="mobilePhone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Mobile Phone")}
                  error={!!errors.mobilePhone}
                  helperText={errors.mobilePhone?.message}
                  fullWidth
                  margin="normal"
                  size="small"
                  disabled={loadingState}
                />
              )}
            />
          </Box>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Button type="submit" variant="contained" disabled={loadingState}>
              {t("Submit")}
            </Button>
          </Box>
        </Box>
      )}

      {errorMessage && (
        <Alert severity="error">
          {typeof errorMessage === "string"
            ? errorMessage
            : "An unknown error occurred"}
        </Alert>
      )}
    </>
  );
};

export default PharmacyDetails;
