import { FormData } from '../pages/ScopeOfPractice/UrinaryTractInfections/VIC/UTIProtocolForm';

export enum PharmacistActionStatus {
  Emergency = 'emergency',
  ReferGP = 'refer_gp',
  Proceed = 'proceed',
  Info = 'info',
}

export interface PharmacistInfo {
  message: string;
  status: PharmacistActionStatus;
}

const calculateAge = (dateOfBirth: string): number => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getPharmacistInfo = (step: number, formData: FormData): PharmacistInfo => {
  if (step === 0) {
    const age = calculateAge(formData.patientInfo.dateOfBirth);
    if (formData.patientInfo.gender === 'male' || formData.patientInfo.genderReassignment || age < 16 || age > 65) {
      return {
        message: `Patient is not eligible for this service. Age is ${
          age < 16 ? '< 16' : '> 65'
        }. Please refer to a GP.`,
        status: PharmacistActionStatus.ReferGP,
      };
    }
    if (formData.patientConsent.consentGiven && formData.patientConsent.physicallyPresent) {
      return {
        message: 'Patient has given consent and is physically present. Proceed to next step.',
        status: PharmacistActionStatus.Proceed,
      };
    } else {
      return {
        message: 'Ensure patient consent is obtained and patient is physically present before proceeding.',
        status: PharmacistActionStatus.ReferGP,
      };
    }
  } else if (step === 1) {
    // Step 3: Symptoms
    const temperature = parseFloat(formData.symptoms.temperature);
    if (temperature > 38 || formData.symptoms.pyelonephritisSigns || formData.symptoms.suspectedSepsis) {
      return {
        message: 'Not eligible for antibiotic supply. Direct patient to attend emergency department immediately.',
        status: PharmacistActionStatus.Emergency,
      };
    }

    const { dysuria, frequency, urgency, suprapubicPainOrDiscomfort, otherCause } = formData.symptoms;
    const symptomCount = [dysuria, frequency, urgency, suprapubicPainOrDiscomfort].filter(Boolean).length;

    if (symptomCount < 2) {
      return {
        message:
          'Insufficient UTI symptoms. Consider other causes and provide symptomatic relief. Refer to GP if symptoms persist.',
        status: PharmacistActionStatus.ReferGP,
      };
    } else if (otherCause) {
      return {
        message:
          'Other causes suspected. Consider appropriateness of S3 Pharmacist Only thrush treatment or refer to GP for investigation.',
        status: PharmacistActionStatus.ReferGP,
      };
    } else {
      return {
        message: 'Patient eligible for UTI Protocol. Proceed to next step.',
        status: PharmacistActionStatus.Proceed,
      };
    }
  } else if (step === 2) {
    // Step 4: High Risk Factors
    const hasHighRiskFactors = Object.values(formData.riskFactors.highRisk).some((value) => value === true);
    if (hasHighRiskFactors) {
      return {
        message:
          'Direct patient to seek medical advice from GP. Only conservative management with non-prescription medicines (if appropriate) and self-care advice may be provided by pharmacist prior to GP review.',
        status: PharmacistActionStatus.ReferGP,
      };
    } else {
      return {
        message: 'No high risk factors identified. Continue to the next step.',
        status: PharmacistActionStatus.Proceed,
      };
    }
  } else if (step === 3) {
    // Step 5: Moderate Risk Factors
    const hasModerateRiskFactors = Object.values(formData.riskFactors.moderateRisk).some((value) => value === true);
    if (hasModerateRiskFactors) {
      return {
        message:
          'Treat using conservative measures or antibiotic treatment, if clinically appropriate. Refer the patient to a GP for review after provision of treatment by the pharmacist.',
        status: PharmacistActionStatus.Proceed,
      };
    } else {
      return {
        message: 'No moderate risk factors identified. Continue to the next step.',
        status: PharmacistActionStatus.Proceed,
      };
    }
  } else if (step === 4) {
    // Step 6: Diagnosis
    const { dysuria, frequency, urgency, suprapubicPainOrDiscomfort } = formData.symptoms;
    const symptomCount = [dysuria, frequency, urgency, suprapubicPainOrDiscomfort].filter(Boolean).length;

    if (symptomCount >= 2) {
      return {
        message: 'Patient has two or more UTI symptoms. Eligible for antibiotic supply.',
        status: PharmacistActionStatus.Proceed,
      };
    } else {
      return {
        message:
          'Not eligible for antibiotic supply. Provide conservative treatment only. Direct patient to seek medical advice if symptoms progress.',
        status: PharmacistActionStatus.ReferGP,
      };
    }
  } else if (step === 5) {
    // Step 7: Treatment
    const {
      analgesia,
      alkalinisingAgent,
      selfCareAdvice,
      utiProphylaxisEducation,
      medicineProvided,
      quantityConfirmation,
      dispensedViaSoftware,
      labelingCompliance,
    } = formData.treatment;

    const conservativeManagementProvided =
      analgesia.paracetamol || analgesia.ibuprofen || analgesia.naproxen || alkalinisingAgent;
    const nonPharmacologicalAdviceProvided = selfCareAdvice || utiProphylaxisEducation;
    const medicineProvidedCorrectly = medicineProvided && quantityConfirmation;
    const dispensingConfirmed = dispensedViaSoftware && labelingCompliance;

    if (
      conservativeManagementProvided &&
      nonPharmacologicalAdviceProvided &&
      medicineProvidedCorrectly &&
      dispensingConfirmed
    ) {
      return {
        message: 'Treatment plan is complete. Proceed to follow-up instructions.',
        status: PharmacistActionStatus.Proceed,
      };
    } else {
      let missingSteps = [];
      if (!conservativeManagementProvided) missingSteps.push('conservative management');
      if (!nonPharmacologicalAdviceProvided) missingSteps.push('non-pharmacological advice');
      if (!medicineProvidedCorrectly) missingSteps.push('approved medicine');
      if (!dispensingConfirmed) missingSteps.push('dispensing confirmation');

      return {
        message: `Please complete the following steps: ${missingSteps.join(', ')}.`,
        status: PharmacistActionStatus.Info,
      };
    }
  } else if (step === 6) {
    // Step 8: Follow-up
    const {
      symptomResolutionAdvised,
      urineSampleProvided,
      worseningSymptomAdvised,
      sideEffectsAdvised,
      recurrenceAdvised,
      shareConsultationRecord,
    } = formData.followUp;

    const allCheckboxesSelected =
      symptomResolutionAdvised &&
      urineSampleProvided &&
      worseningSymptomAdvised &&
      sideEffectsAdvised &&
      recurrenceAdvised &&
      shareConsultationRecord;

    if (allCheckboxesSelected) {
      return {
        message: 'Follow-up plan complete. Proceed to Referral.',
        status: PharmacistActionStatus.Proceed,
      };
    } else {
      const missingSteps = [];
      if (!symptomResolutionAdvised) missingSteps.push('Advise on symptom resolution');
      if (!urineSampleProvided) missingSteps.push('Provide urine sample jar');
      if (!worseningSymptomAdvised) missingSteps.push('Advise on worsening symptoms');
      if (!sideEffectsAdvised) missingSteps.push('Advise on side effects');
      if (!recurrenceAdvised) missingSteps.push('Advise on symptom recurrence');
      if (!shareConsultationRecord) missingSteps.push('Share consultation record');

      return {
        message: `Please complete the following steps: ${missingSteps.join(', ')}.`,
        status: PharmacistActionStatus.Info,
      };
    }
  } else if (step === 7) {
    // Step 9: Referral
    if (formData.referral === 'gp_referral') {
      return {
        message:
          'GP referral or follow-up is required. If the patient does not have a regular GP or that GP is unavailable (within an appropriate timeframe), contact Healthdirect on 1800 022 222 for advice on patient disposition.',
        status: PharmacistActionStatus.ReferGP,
      };
    } else if (formData.referral === 'emergency_referral') {
      return {
        message: 'Refer the patient to the emergency department immediately.',
        status: PharmacistActionStatus.Emergency,
      };
    } else {
      return {
        message: 'No referral required. Ensure all follow-up instructions are provided to the patient.',
        status: PharmacistActionStatus.Proceed,
      };
    }
  }

  // Default return for unhandled steps
  return {
    message: 'Complete the current step to see pharmacist recommendations.',
    status: PharmacistActionStatus.Info,
  };
};
