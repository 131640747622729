import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, Typography, Paper, Grid, TextField, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Select, MenuItem, InputAdornment, Snackbar, Chip
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Activity, Utensils, Droplet, AlertTriangle } from 'lucide-react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useCustomer } from '../contexts/CustomerContext';
import AuthContext from '../contexts/AuthContext';
import { useBloodGlucoseData } from '../hooks/useBloodGlucoseData';
import { createBloodGlucoseReading } from '../services/bloodGlucoseService';
import { BloodGlucoseReading } from '../types/bloodGlucose';
import { LucideIcon } from 'lucide-react';
import { logger } from 'utils/logger';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface GlucoseCardProps {
    title: string;
    value: string;
    icon: LucideIcon;
    interpretation: string;
    bgColor: string;
    textColor: string;
}

const GlucoseCard: React.FC<GlucoseCardProps> = ({
    title,
    value,
    icon: Icon,
    interpretation,
    bgColor,
    textColor,
}) => (
    <Paper elevation={3} sx={{ p: 2, height: '100%', bgcolor: bgColor, color: textColor }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight="bold">
                {title}
            </Typography>
            <Icon size={24} />
        </Box>
        <Typography variant="h4" sx={{ my: 2 }}>
            {value}
        </Typography>
        <Typography variant="body2">{interpretation}</Typography>
    </Paper>
);

const DiabetesMonitoring: React.FC = () => {
    const navigate = useNavigate();
    const { state } = useCustomer();
    const customer = state.customer;
    const { pharmacyID } = useContext(AuthContext);
    const { historicalData, loading, error, refetch } = useBloodGlucoseData(customer?.patientID, pharmacyID);

    const [measurementDate, setMeasurementDate] = useState<dayjs.Dayjs | null>(dayjs());
    const [measurementTime, setMeasurementTime] = useState<dayjs.Dayjs | null>(dayjs());
    const [glucoseLevel, setGlucoseLevel] = useState<string>('');
    const [measurementType, setMeasurementType] = useState<string>('');
    const [mealContext, setMealContext] = useState<string>('');
    const [notes, setNotes] = useState<string>('');

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');

    useEffect(() => {
        if (!customer?.patientID) {
            logger.log('No customer selected');
            navigate('/pages/patients/search-patient');
        }
    }, [customer, navigate]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!customer || !customer.patientID) {
            setSnackbarMessage('No customer selected or patient ID is missing.');
            setSnackbarOpen(true);
            return;
        }

        const formData: Partial<BloodGlucoseReading> = {
            patientID: customer.patientID,
            pharmacyID: pharmacyID || '',
            createdDate: dayjs().format('YYYY-MM-DD'),
            measurementDate: measurementDate?.format('YYYY-MM-DD') || '',
            measurementTime: measurementTime?.format('HH:mm') || '',
            glucoseLevel: Number(glucoseLevel),
            measurementType,
            mealContext,
            notes,
        };

        const readingData: Omit<BloodGlucoseReading, 'id' | 'createdAt' | 'updatedAt'> = {
            patientID: formData.patientID!,
            pharmacyID: formData.pharmacyID!,
            createdDate: formData.createdDate!,
            measurementDate: formData.measurementDate!,
            measurementTime: formData.measurementTime!,
            glucoseLevel: formData.glucoseLevel!,
            measurementType: formData.measurementType!,
            mealContext: formData.mealContext!,
            notes: formData.notes
        };

        try {
            logger.log('formData', formData);
            await createBloodGlucoseReading(readingData);
            setSnackbarMessage('Blood glucose reading saved successfully');
            setSnackbarOpen(true);
            refetch();
        } catch (error) {
            setSnackbarMessage('Error saving blood glucose reading');
            setSnackbarOpen(true);
        }
    };

    const getLatestReading = () => {
        return historicalData.length > 0 ? historicalData[0] : { glucoseLevel: 'N/A', measurementType: 'N/A' };
    };

    const latestReading = getLatestReading();

    const interpretGlucose = (value: number | string, type: string): string => {
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        if (type === 'fasting') {
            if (numValue < 70) return 'Low';
            if (numValue < 100) return 'Normal';
            if (numValue < 126) return 'Prediabetes';
            return 'Diabetes';
        } else {
            if (numValue < 70) return 'Low';
            if (numValue < 140) return 'Normal';
            if (numValue < 200) return 'Prediabetes';
            return 'Diabetes';
        }
    };

    const chartData = {
        labels: historicalData.map(reading => dayjs(`${reading.measurementDate} ${reading.measurementTime}`).format('MMM D, HH:mm')),
        datasets: [
            {
                label: 'Glucose Level',
                data: historicalData.map(reading => reading.glucoseLevel),
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Blood Glucose Trends',
            },
        },
        scales: {
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'mg/dL',
                },
            },
        },
    };

    const calculateAverageGlucose = (data: BloodGlucoseReading[], days: number): string => {
        const relevantData = data.slice(0, days);
        const sum = relevantData.reduce((sum, reading) => sum + reading.glucoseLevel, 0);
        const average = sum / Math.min(relevantData.length, days);
        return average.toFixed(1);
    };

    return (
        <Box sx={{ bgcolor: '#F3F4F6', minHeight: '100vh', py: 4 }}>
            <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 3 }}>
                <Typography variant="h4" component="h1" sx={{ mb: 4, color: 'primary.main', fontWeight: 'bold' }}>
                    Diabetes Monitoring
                </Typography>

                <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <GlucoseCard
                            title="Latest Reading"
                            value={`${latestReading.glucoseLevel} mg/dL`}
                            icon={Droplet}
                            interpretation={interpretGlucose(latestReading.glucoseLevel, latestReading.measurementType)}
                            bgColor="#FFF3E0"
                            textColor="#E65100"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <GlucoseCard
                            title="Measurement Type"
                            value={latestReading.measurementType}
                            icon={Activity}
                            interpretation={latestReading.measurementType === 'fasting' ? 'Fasting' : 'Post-meal'}
                            bgColor="#FFEBEE"
                            textColor="#C62828"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <GlucoseCard
                            title="Average (7 days)"
                            value={`${calculateAverageGlucose(historicalData, 7)} mg/dL`}
                            icon={AlertTriangle}
                            interpretation="7-day average"
                            bgColor="#E8F5E9"
                            textColor="#2E7D32"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <GlucoseCard
                            title="Readings (7 days)"
                            value={historicalData.filter(reading => dayjs().diff(dayjs(`${reading.measurementDate} ${reading.measurementTime}`), 'day') <= 7).length.toString()}
                            icon={Utensils}
                            interpretation="Last 7 days"
                            bgColor="#E3F2FD"
                            textColor="#1565C0"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3, mb: 3, borderRadius: '8px' }}>
                            <Typography variant="h6" gutterBottom fontWeight="bold">
                                New Blood Glucose Reading
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Measurement Date"
                                                value={measurementDate}
                                                onChange={(newValue) => setMeasurementDate(newValue)}
                                                slotProps={{ textField: { fullWidth: true } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                label="Measurement Time"
                                                value={measurementTime}
                                                onChange={(newValue) => setMeasurementTime(newValue)}
                                                slotProps={{ textField: { fullWidth: true } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Glucose Level"
                                            type="number"
                                            value={glucoseLevel}
                                            onChange={(e) => setGlucoseLevel(e.target.value)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            fullWidth
                                            value={measurementType}
                                            onChange={(e) => setMeasurementType(e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>Measurement Type</MenuItem>
                                            <MenuItem value="fasting">Fasting</MenuItem>
                                            <MenuItem value="post-meal">Post-meal</MenuItem>
                                            <MenuItem value="random">Random</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            fullWidth
                                            value={mealContext}
                                            onChange={(e) => setMealContext(e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>Meal Context</MenuItem>
                                            <MenuItem value="before_meal">Before Meal</MenuItem>
                                            <MenuItem value="after_meal">After Meal</MenuItem>
                                            <MenuItem value="bedtime">Bedtime</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Notes"
                                            multiline
                                            rows={3}
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{
                                                height: '48px',
                                            }}
                                        >
                                            Save Reading
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 3, height: '100%', borderRadius: '8px' }}>
                            <Typography variant="h6" gutterBottom fontWeight="bold">
                                Glucose Trends
                            </Typography>
                            <Line options={chartOptions} data={chartData} />
                        </Paper>
                    </Grid>
                </Grid>

                <Paper sx={{ mt: 3, p: 3, borderRadius: '8px' }}>
                    <Typography variant="h6" gutterBottom fontWeight="bold">
                        Recent Readings
                    </Typography>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date & Time</TableCell>
                                    <TableCell>Glucose Level</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Meal Context</TableCell>
                                    <TableCell>Notes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historicalData.map((reading) => (
                                    <TableRow key={reading.id}>
                                        <TableCell>{dayjs(`${reading.measurementDate} ${reading.measurementTime}`).format('MMM D, YYYY HH:mm')}</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={`${reading.glucoseLevel} mg/dL`}
                                                color={interpretGlucose(reading.glucoseLevel, reading.measurementType) === 'Normal' ? 'success' : 'warning'}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>{reading.measurementType}</TableCell>
                                        <TableCell>{reading.mealContext}</TableCell>
                                        <TableCell>{reading.notes}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default DiabetesMonitoring;