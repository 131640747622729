import { BOOKING_STATUS, IBooking } from '../../interfaces/IBooking';
import { BoxProps, Typography, Skeleton, Paper, Box, Alert, Button, Grid } from '@mui/material';
import moment from 'moment';
import { useAppointment } from '../../contexts/AppointmentContext';
import { Link } from 'react-router-dom';
import { logger } from 'utils/logger';

interface IDetailItemProps extends BoxProps {
  label?: string | null;
  value?: string | null;
  loading?: boolean;
  useLink?: 'PHONE' | 'ADDRESS' | 'EMAIL';
}

interface IAppontmentDetailModeProps {
  onEdit: () => void;
  appointment: IBooking | null;
}

const DetailItem = ({ label, value, loading, useLink, ...rest }: IDetailItemProps) => {
  if (loading) {
    return (
      <Box mb={2} {...rest}>
        <Typography variant="subtitle2" fontWeight="bold">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
      </Box>
    );
  }

  if (useLink === 'ADDRESS' && value?.length) {
    return (
      <Box mb={2} {...rest}>
        <Typography variant="subtitle2" fontWeight="bold">
          {label}
        </Typography>
        <a target="_blank" href={`https://maps.google.com/maps?q=${value}`} rel="noreferrer">
          <Typography variant="body1">{value}</Typography>
        </a>
      </Box>
    );
  }

  if (useLink === 'PHONE' && value?.length) {
    return (
      <Box mb={2} {...rest}>
        <Typography variant="subtitle2" fontWeight="bold">
          {label}
        </Typography>
        <a target="_blank" href={`tel:${value}`} rel="noreferrer">
          <Typography variant="body1">{value}</Typography>
        </a>
      </Box>
    );
  }

  if (useLink === 'EMAIL' && value?.length) {
    return (
      <Box mb={2} {...rest}>
        <Typography variant="subtitle2" fontWeight="bold">
          {label}
        </Typography>
        <a target="_blank" href={`mailto:${value}`} rel="noreferrer">
          <Typography variant="body1">{value}</Typography>
        </a>
      </Box>
    );
  }

  return (
    <Box mb={2} {...rest}>
      <Typography variant="subtitle2" fontWeight="bold">
        {label}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

const PatientDetail = () => {
  const { selectedAppointment, loading } = useAppointment();

  if (!selectedAppointment) {
    return (
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Appointment Details
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography>Please select an event!</Typography>
        </Box>
      </Paper>
    );
  }

  if (loading) {
    return (
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Patient Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DetailItem loading={loading} />
            <DetailItem loading={loading} />
            <DetailItem loading={loading} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DetailItem loading={loading} />
            <DetailItem loading={loading} />
            <DetailItem loading={loading} />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const booking = selectedAppointment as IBooking;

  const patient = booking.patient;
  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Patient Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DetailItem label="Full Names" value={`${patient?.firstName || ''} ${patient?.lastName || ''}`} />
          <DetailItem
            label="Date Of Birth"
            value={patient?.dateOfBirth ? moment(patient.dateOfBirth).format('DD-MM-YYYY') : 'N/A'}
          />
          <DetailItem label="Gender" value={patient?.gender || ''} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DetailItem label="Address" value={patient?.addressLineOne || ''} useLink="ADDRESS" />
          <DetailItem label="Email" value={patient?.emailAddress || ''} useLink="EMAIL" />
          <DetailItem label="Phone Number" value={patient?.phoneNumber || ''} useLink="PHONE" />
        </Grid>
      </Grid>
    </Paper>
  );
};

const AppointmentDetail = () => {
  const { selectedAppointment, loading } = useAppointment();
  logger.log(selectedAppointment, 'selectedAppointment');

  if (!selectedAppointment) {
    return (
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Appointment Details
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography>Please select an event!</Typography>
        </Box>
      </Paper>
    );
  }

  if (loading) {
    return (
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Appointment Details
        </Typography>
        <Box display="flex" gap={2}>
          <DetailItem
            loading={loading}
            sx={{
              textAlign: 'start',
              flex: 1,
            }}
          />
          <DetailItem
            loading={loading}
            sx={{
              textAlign: 'center',
              flex: 1,
            }}
          />

          <DetailItem
            loading={loading}
            sx={{
              textAlign: 'center',
              flex: 1,
            }}
          />
          <DetailItem
            loading={loading}
            sx={{
              textAlign: 'end',
              flex: 1,
            }}
          />
        </Box>
      </Paper>
    );
  }

  const booking = selectedAppointment as IBooking;
  const serviceLabel = booking.InjectionBrandName ? 'Injection Name' : 'Vaccination Name';
  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Appointment Details
      </Typography>
      <Box display="flex" gap={2}>
        <DetailItem
          label="Appointment Date"
          value={moment(booking.timeslot).tz('Australia/Sydney').format('DD/MM/YYYY HH:mm A')}
          sx={{
            textAlign: 'start',
            flex: 1,
          }}
        />
        <DetailItem
          label={serviceLabel}
          value={booking.InjectionBrandName || booking.VaccineBrandName || 'N/A'}
          sx={{
            textAlign: 'center',
            flex: 1,
          }}
        />

        <DetailItem
          label="Duration"
          value={`${booking.durationInSeconds / 60} mins`}
          sx={{
            textAlign: 'center',
            flex: 1,
          }}
        />

        <DetailItem
          label="Room"
          value={`${booking.room?.roomName}`}
          sx={{
            textAlign: 'end',
            flex: 1,
          }}
        />
      </Box>
    </Paper>
  );
};

const AppointmentDetailMode = ({ appointment, onEdit }: IAppontmentDetailModeProps) => {
  const isBookingCancelled =
    appointment?.status === BOOKING_STATUS.CANCELED ||
    appointment?.status === BOOKING_STATUS.CANCELED_BY_PATIENT ||
    appointment?.status === BOOKING_STATUS.CANCELED_BY_PHARMACY;

  return (
    <Box>
      {isBookingCancelled && (
        <Alert severity="error" sx={{ mb: 2 }}>
          This Appointment is canceled
        </Alert>
      )}
      <AppointmentDetail />
      <PatientDetail />
      <Box width="100%" display="flex" justifyContent="flex-end">
        {!isBookingCancelled && (
          <Button
            color="warning"
            onClick={() => {
              if (typeof onEdit === 'function') {
                onEdit();
              }
            }}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Edit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AppointmentDetailMode;
