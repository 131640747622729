// constants
import { INotification } from "../../interfaces/INotification";
import { NotificationActionTypes } from "./constants";

export interface IsReadPayload {
  id: string;
  isRead: boolean;
}

export interface removePlayload {
  id: string;
}

export interface NotificationActionType {
  type:
    | NotificationActionTypes.SET_ADD
    | NotificationActionTypes.SET_CLEAR_ALL
    | NotificationActionTypes.SET_READ
    | NotificationActionTypes.SET_REMOVE;
  payload?: INotification | IsReadPayload | removePlayload;
}

export const setAddNotification = (
  payload: INotification
): NotificationActionType => ({
  type: NotificationActionTypes.SET_ADD,
  payload: payload,
});

export const clearAllNotification = (): NotificationActionType => ({
  type: NotificationActionTypes.SET_CLEAR_ALL,
});

export const setMarkAsRead = (
  payload: IsReadPayload
): NotificationActionType => ({
  type: NotificationActionTypes.SET_READ,
  payload: payload,
});

export const removeNotification = (
  payload: removePlayload
): NotificationActionType => ({
  type: NotificationActionTypes.SET_REMOVE,
  payload: payload,
});
