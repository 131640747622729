import { useState, useEffect, ChangeEvent, ReactNode } from 'react';
import {
  TextField,
  CircularProgress,
  Box,
  Button,
  Typography,
  ClickAwayListener,
  InputAdornment,
  IconButton,
  Avatar,
  TextFieldProps,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  AssignmentIndOutlined,
  CakeOutlined,
  CallOutlined,
  CloseOutlined,
  DeleteForeverOutlined,
  HomeOutlined,
  MailOutlineOutlined,
  PersonAddOutlined,
  PersonOutlineOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { ISearchResponse, useSearchCustomer } from '../hooks/useSearchCustomer';
import useDebounce from '../hooks/useDebounce';
import moment from 'moment';
import { ICustomer } from '../interfaces';
import { uuid } from '../utils';

const StyledList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: '.75rem',
  maxHeight: '200px',
  overflowY: 'auto',
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: 'white',
  position: 'absolute',
  width: '100%',
  zIndex: 10,
  '& li:not(:last-child)': {
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  },
}));

const StyledListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '.5rem 1rem',
  my: '.5rem',
  width: '100%',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

const NoResult = ({ loading }: { loading: boolean }) => (
  <Box
    sx={{
      display: 'flex',
      whiteSpace: 'nowrap',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    }}
  >
    {loading ? (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flex: 1,
        }}
      >
        <CircularProgress
          sx={{
            width: 24,
            height: 24,
          }}
        />
      </Box>
    ) : (
      <Link
        to="purchaser-detail"
        style={{
          width: '100%',
          display: 'flex',
          flex: 1,
          pointerEvents: loading ? 'none' : 'inherit',
        }}
      >
        <Button
          size="large"
          fullWidth
          sx={{
            py: '1rem',
          }}
          disabled={loading}
          startIcon={<PersonAddOutlined />}
        >
          Create Customer
        </Button>
      </Link>
    )}
  </Box>
);

type AutocompleCustomerProps = {
  label: string;
  onSelect?: (customer: ICustomer) => void;
  onClear?: () => void;
  fieldsToShow?: (keyof Pick<ICustomer, 'dateOfBirth' | 'identificationNumber' | 'addressLineOne' | 'phoneNumber'>)[];
  onLoadingState?: (loading: boolean) => void;
  CustomNoResult?: ReactNode;
  defaultValue?: string;
  showDropdownWhenNoresult?: boolean;
} & Omit<TextFieldProps, 'onSelect' | 'defaultValue'>;

const IconField = ({
  field,
}: {
  field: keyof Pick<ICustomer, 'dateOfBirth' | 'identificationNumber' | 'addressLineOne' | 'phoneNumber'>;
}) => {
  if (field === 'addressLineOne') {
    return (
      <HomeOutlined
        sx={(theme) => ({
          width: 20,
          height: 20,
          color: alpha(theme.palette.common.black, 0.9),
        })}
      />
    );
  }

  if (field === 'dateOfBirth') {
    return (
      <CakeOutlined
        sx={(theme) => ({
          width: 20,
          height: 16,
          color: alpha(theme.palette.common.black, 0.9),
        })}
      />
    );
  }

  if (field === 'identificationNumber') {
    return (
      <AssignmentIndOutlined
        sx={(theme) => ({
          width: 20,
          height: 20,
          color: alpha(theme.palette.common.black, 0.9),
        })}
      />
    );
  }

  return (
    <CallOutlined
      sx={(theme) => ({
        width: 20,
        height: 20,
        color: alpha(theme.palette.common.black, 0.9),
      })}
    />
  );
};

const AutocompleteCustomer = ({
  label,
  fieldsToShow,
  onSelect,
  onLoadingState,
  CustomNoResult,
  defaultValue,
  showDropdownWhenNoresult = false,
  onClear,
  onChange,
  ...textfieldProps
}: AutocompleCustomerProps) => {
  const { loading, searchCustomer, customers } = useSearchCustomer();
  const [options, setOptions] = useState<ICustomer[]>([]);
  const [keyword, setKeyword] = useState(defaultValue || '');
  const debounceKeywordSearch = useDebounce(keyword, 700);
  const [showDropdown, setShowDropdown] = useState(false);
  const [customer, setcustomer] = useState<ICustomer | null>(null);

  // pass loading to the parent, if needed the parent can add some action for another component when loading state change
  useEffect(() => {
    if (typeof onLoadingState === 'function') {
      onLoadingState(loading);
    }
  }, [loading]);

  // search customer as long the value not empty string
  useEffect(() => {
    if (debounceKeywordSearch !== '') {
      searchCustomer(debounceKeywordSearch);
    }

    if (debounceKeywordSearch.trim().length === 0) {
      setShowDropdown(false);
    }
  }, [debounceKeywordSearch]);

  // set the value of the keyword when user select the dropdoen
  useEffect(() => {
    if (customer) {
      setKeyword(`${customer.firstName} ${customer.lastName}`.trim());
    }
  }, [customer]);

  useEffect(() => {
    setOptions(customers);
  }, [customers]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    setShowDropdown(true);

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  const handleSelect = (customer: ICustomer) => {
    setKeyword(`${customer.firstName} ${customer.lastName}`);
    if (onSelect) {
      onSelect(customer);
    }
  };

  const containsValidPhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^(\d[\d\s\-\(\)]{4,})$/;
    return phoneRegex.test(phoneNumber);
  };

  const useCustomeFieldsShow = Array.isArray(fieldsToShow) && fieldsToShow.length > 0;

  useEffect(() => {
    if (options.length === 0 && !showDropdownWhenNoresult) {
      setShowDropdown(false);
    }
  }, [options]);

  useEffect(() => {
    if (defaultValue) {
      setKeyword(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <TextField
          {...textfieldProps}
          label={label}
          autoComplete="new-password" // let the chrome confuse :D
          value={keyword}
          onChange={handleInputChange}
          placeholder="Type a customer name to search the existing one..."
          fullWidth
          InputProps={{
            endAdornment:
              // loading ? (
              //   <CircularProgress color="inherit" size={20} />
              // ) :
              keyword ? (
                <InputAdornment position="end">
                  <IconButton
                    color="error"
                    onClick={() => {
                      setKeyword('');
                      setcustomer(null);

                      if (typeof onClear === 'function') {
                        onClear();
                      }
                    }}
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <SearchOutlined />
                </InputAdornment>
              ),
          }}
        />
        {showDropdown &&
          (loading ? (
            <StyledList>
              <StyledListItem>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress size={28} />
                </Box>
              </StyledListItem>
            </StyledList>
          ) : (
            <StyledList>
              {options.length > 0 ? (
                options.map((customer: ICustomer) => (
                  <StyledListItem key={customer.customerID} onClick={() => handleSelect(customer)}>
                    <Box>
                      <Typography variant="subtitle1">
                        {customer.firstName} {customer.lastName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {customer.dateOfBirth}
                      </Typography>
                    </Box>
                    {/* ... other customer details ... */}
                  </StyledListItem>
                ))
              ) : (
                <Typography>No results found</Typography>
              )}
            </StyledList>
          ))}
      </Box>
    </ClickAwayListener>
  );
};

export default AutocompleteCustomer;
