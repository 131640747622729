import { Box, Button, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CardChooseAccounts from '../../components/Auth/CardChooseAccounts';
import { useAuth } from '../../hooks/useAuth';
import { IProvider } from '../../interfaces';
import { AuthSession } from 'aws-amplify/auth';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_PROVIDER_BY_EMAIL } from '../../graphql-queries';
import LoadingContent from '../../components/LoadingContent';
import ErrorReport from '../../components/ErrorReport';
import { removeTypename } from '../../utils';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ELoginStep, logoutUser, resetAuth, setLoginSteps } from '../../redux/actions';
import { APICore } from '../../helpers/api/apiCore';
import { RootState } from '../../redux/store';
import { IDragatronUser } from '../../interfaces/IDragatronUser';
import AuthContext from '../../contexts/AuthContext';
import { ReportOutlined } from '@mui/icons-material';
import { logger } from 'utils/logger';

const api = new APICore();

const ChooseAccount = () => {
  const { getSession, loading, error } = useAuth();
  const [providers, setProviders] = useState<IProvider[] | null>(null);

  const [account, setAccount] = useState<AuthSession['tokens'] | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const {
    loading: loadingProviders,
    error: errorProviders,
    data: providersData,
  } = useQuery(QUERY_LIST_PROVIDER_BY_EMAIL, {
    variables: { pharmacyLoginId: email },
    skip: !email,
  });
  const dispatch = useDispatch();
  const loginStep = useSelector((state: RootState) => state.Auth.loginStep.step);
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const params = new URLSearchParams(search);
  const nextUrl = state?.next || params.get('next');

  const { setAuthDetails } = useContext(AuthContext);
  const { planName } = useContext(AuthContext);

  // const resetLogin = () => {
  //   dispatch(
  //     setLoginSteps({
  //       step: ELoginStep.FILL_FORM,
  //     })
  //   );

  //   dispatch(resetAuth());
  //   dispatch(logoutUser());
  // };

  useEffect(() => {
    getSession()
      .then((session) => {
        setAccount(session?.tokens);
        setEmail(session?.tokens?.idToken?.payload.email as string);
      })
      .catch((reason) => console.error('Error getting account detail ', reason));

    // reset login state on click back navigation when acccount did not found or not active
    window.addEventListener('popstate', () => {
      // if (providers.length === 0) {
      //   resetLogin();
      // }
      // if (providers.length === 1) {
      //   const provider = providers[0];
      //   if (!provider.isActive) {
      //     resetLogin();
      //   }
      // }
      // navigate(`/auth/login`, {
      //   replace: true,
      // });
    });
  }, []);

  useEffect(() => {
    if (providersData) {
      const result = providersData?.queryProvidersByLoginId?.items || [];
      const listProvider = result as IProvider[];
      const providers = listProvider.map((provider) => removeTypename(provider));
      setProviders(providers);
    }
  }, [providersData]);

  const haveMultipleAccount = providers && providers.length >= 2;
  const onlyHaveOneAccount = providers && providers.length === 1;

  // handler to anticipate directly changing url directly to access private page
  useEffect(() => {
    if (loginStep !== ELoginStep.COMPLETE) {
      if (haveMultipleAccount) {
        dispatch(
          setLoginSteps({
            step: ELoginStep.CHOOSE_ACCOUNT,
          })
        );
      }

      if (onlyHaveOneAccount) {
        dispatch(
          setLoginSteps({
            step: ELoginStep.COMPLETE,
          })
        );
      }
    }
  }, [providers]);

  // listener to populate account data from database into cognito
  useEffect(() => {
    if (onlyHaveOneAccount) {
      const provider = providers[0];

      if (provider.isActive) {
        const session = api.getLoggedInUser() as IDragatronUser;
        const populatedPayload: IDragatronUser = {
          ...session,
          tokens: {
            ...session.tokens,
            idToken: {
              ...session.tokens.idToken,
              payload: {
                ...session.tokens.idToken.payload, // use default payload
                'custom:providerFirstName': provider.providerFirstName!,
                'custom:providerLastName': provider.providerLastName!,
                'custom:providerAHPRA': provider.providerAHPRA,
                'custom:pharmacyName': provider.pharmacyName,
                'custom:pharmacyID': provider.pharmacyID,
                'custom:pharmacistID': provider.pharmacistID,
                'custom:minorId': provider.minorId,
                'custom:providerNumber': provider.providerNumber,
                'custom:isPPAEligible': provider.ppaClaims,
                'custom:isAdministrator': provider.isAdministrator,
                'custom:hpioNumber': provider.hpioNumber,
                'custom:hpiiNumber': provider.hpiiNumber,
                isActive: provider.isActive,
                'custom:prodaOrgId': provider.prodaOrgId,
              },
            },
          },
        };

        // update the context auth
        // update the context auth
        setAuthDetails({
          providerNumber: provider.providerNumber,
          providerFirstName: provider.providerFirstName!,
          providerLastName: provider.providerLastName!,
          providerStore: provider.pharmacyAddress,
          pharmacistID: provider.pharmacistID,
          pharmacyID: provider.pharmacyID,
          pharmacyName: provider.pharmacyName,
          hpioNumber: provider.hpioNumber,
          hpiiNumber: provider.hpiiNumber,
          isAdministrator: provider.isAdministrator,
          deviceName: provider.deviceName,
          minorId: provider.minorId,
          providerAHPRA: provider.providerAHPRA,
          providerEmail: provider.pharmacyLoginId, // Assuming this is the correct field for email
          isPPAEligible: provider.ppaClaims, // Assuming this is the correct field for PPA eligibility
          prodaOrgId: provider.prodaOrgId,
          PPA: undefined, // or provider.PPA if it exists
        });

        api.setLoggedInUser(populatedPayload);
      } else {
        dispatch(resetAuth());
        dispatch(logoutUser());
      }
    }
  }, [onlyHaveOneAccount]);

  if (loading || loadingProviders || providers === null) {
    logger.log('loading or loadingProviders or providers === null');
    return <LoadingContent />;
  }

  if (error || errorProviders) {
    const isUnauthenticated = error?.message.includes(
      'Unauthenticated access is not supported for this identity pool.'
    );
    const message = isUnauthenticated
      ? 'You are not Authenticated to access this page!'
      : `We are having a problem trying to get providers detail`;

    if (!errorProviders || isUnauthenticated) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Paper
            sx={{
              width: '30%',
              p: '2rem',
            }}
          >
            {message}
          </Paper>
        </Box>
      );
    }

    return <ErrorReport error={errorProviders} />;
  }

  if (providers.length === 1) {
    const provider = providers[0];

    if (!provider.isActive) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '35%',
            }}
          >
            <Paper
              sx={{
                width: '100%',
                p: '2rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                textAlign: 'center',
              }}
            >
              <Typography>
                Sorry, Look's like you are Unable to access the app. Please contact your administrator to continue
              </Typography>

              <Button
                onClick={() => {
                  dispatch(
                    setLoginSteps({
                      step: ELoginStep.FILL_FORM,
                    })
                  );
                  navigate('/auth/login', {
                    replace: true,
                  });
                }}
                variant="contained"
              >
                Go to Login
              </Button>
            </Paper>
          </Box>
        </Box>
      );
    }

    return (
      <Navigate
        replace
        to={{
          pathname: nextUrl || '/dashboard-air',
        }}
      />
    );
  }

  if (providers.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '35%',
          }}
        >
          <Paper
            sx={{
              width: '100%',
              p: '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              textAlign: 'center',
            }}
          >
            <Typography>
              Welcome! It looks like this is your first time here. Let's set up your provider account.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                if (planName === 'S3R') {
                  navigate('/onboarding-s3r');
                } else {
                  navigate('/onboarding-air');
                }
              }}
            >
              Set Up Provider Account
            </Button>
            {/* <Typography variant="body2" sx={{ mt: 2 }}>
              If you believe you should already have an account, please contact support.
            </Typography>
            <Link to={"mailto:admin@dragatron.com.au"} target="_blank">
              <Button startIcon={<ReportOutlined />}>Contact Support</Button>
            </Link> */}
          </Paper>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '35%',
        }}
      >
        <CardChooseAccounts providers={providers} />
      </Box>
    </Box>
  );
};

export default ChooseAccount;
