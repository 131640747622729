import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
  Alert,
  AlertTitle,
  Snackbar,
  OutlinedInput,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  PlusCircle,
  Trash2,
  Edit,
  Calendar,
  Clock,
  Phone,
  Video,
  Mail,
  UserPlus,
  Pill,
  FileText,
  MessageCircle,
  MessageSquare,
  Info,
} from 'lucide-react';
import { usePatientFollowUps } from '../hooks/usePatientFollowUps';
import AuthContext from '../contexts/AuthContext';
import dayjs, { Dayjs } from 'dayjs';
import { useCustomer } from '../contexts/CustomerContext'; // Import the useCustomer hook
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { logger } from '../utils/logger';
import ScheduledFollowUps from './ScheduledFollowUps';
import { PatientFollowUp } from '../types/PatientFollowUp';
import { useQuery } from '@apollo/client';
import { QUERY_PROVIDERS_BY_PHARMACY_ID } from '../graphql-queries';
import { SelectChangeEvent } from '@mui/material/Select';

const MAX_MESSAGE_LENGTH = 160;

const SMS_TEMPLATES = [
  {
    id: 'wellness_check',
    text: "Hi, this is {pharmacistName}, your pharmacist from {pharmacyName}. We're checking in to see how you're doing. If you need anything, please call us on {phoneNumber}.",
  },
  {
    id: 'medication_followup',
    text: 'Hi, this is {pharmacistName}, your pharmacist from {pharmacyName}. Just following up about your recent medication. How are you going with it? Please call {phoneNumber} if you have any concerns.',
  },
  {
    id: 'appointment_reminder',
    text: 'Reminder: You have a follow-up appointment at {pharmacyName} on {followupDate} at {followupTime}. Please call {phoneNumber} if you need to reschedule.',
  },
  {
    id: 'custom',
    text: '', // Empty for custom message
  },
];

const PatientFollowups: React.FC = () => {
  const { pharmacyID, pharmacistID, providerFirstName, pharmacyName } = useContext(AuthContext);
  const { state } = useCustomer(); // Use the useCustomer hook
  const { customer } = state;
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [followUps, setFollowUps] = useState<PatientFollowUp[]>([]);
  const [newFollowUp, setNewFollowUp] = useState<Omit<PatientFollowUp, 'id'>>({
    patientID: customer?.patientID || '',
    pharmacistID: pharmacistID || '',
    pharmacyID: pharmacyID || '',
    patientName: '',
    contactPhone: '',
    contactEmail: '',
    preferredContactMethod: 'phone',
    visitDate: dayjs(),
    visitReason: '',
    medicationsPrescribed: '',
    followupDate: null,
    followupTime: dayjs().set('hour', 8).set('minute', 0),
    followupType: 'phone',
    followupReason: '',
    pharmacistNotes: '',
    customerConcerns: '',
    reminderSet: true,
    status: 'pending',
    assignedPharmacist: [],
  });
  const {
    patientFollowUps,
    isLoading,
    error,
    fetchMore,
    hasMore,
    refetch: followUpsRefetch,
  } = usePatientFollowUps(pharmacyID, selectedDate);
  const [editingFollowUp, setEditingFollowUp] = useState<string | null>(null);
  const [visitReasons, setVisitReasons] = useState([
    'Medical Equipment and Supplies',
    'Doctor Follow-up',
    'Over-the-Counter Advice',
    'Prescription Refill',
    'Vaccination',
    'Other',
  ]);

  const [otherVisitReason, setOtherVisitReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Add state for field error
  const [dateError, setDateError] = useState(false);

  // Add ref for the follow-up date input
  const followupDateRef = useRef<HTMLInputElement>(null);

  const [pharmacists, setPharmacists] = useState<
    Array<{
      givenName: string;
      familyName: string;
      ahpraNumber: string;
    }>
  >([]);

  const [pharmacyPhone, setPharmacyPhone] = useState('');

  const {
    loading: queryLoading,
    error: queryError,
    data,
    refetch,
  } = useQuery(QUERY_PROVIDERS_BY_PHARMACY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      pharmacyID: pharmacyID,
    },
  });

  useEffect(() => {
    if (!queryLoading && !queryError && data) {
      if (data.queryProvidersByPharmacyIDIndex?.items) {
        const fetchedPharmacists = data.queryProvidersByPharmacyIDIndex.items.map((item: any) => ({
          givenName: item.providerFirstName,
          familyName: item.providerLastName,
          ahpraNumber: item.providerAHPRA,
        }));
        setPharmacists(fetchedPharmacists);

        if (data.queryProvidersByPharmacyIDIndex.items[0]?.pharmacyPhoneNumber) {
          logger.log('Pharmacy phone:', data.queryProvidersByPharmacyIDIndex.items[0].pharmacyPhoneNumber);
          setPharmacyPhone(data.queryProvidersByPharmacyIDIndex.items[0].pharmacyPhoneNumber);
        }
      }
    }
  }, [queryLoading, queryError, data]);

  const [selectedPharmacists, setSelectedPharmacists] = useState<
    Array<{
      givenName: string;
      familyName: string;
      ahpraNumber: string;
    }>
  >([]);

  const handlePharmacistChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    const selected = pharmacists.filter((pharmacist) => selectedIds.includes(pharmacist.ahpraNumber));
    setSelectedPharmacists(selected);

    const assignedPharmacistData = selected.map((pharmacist) => ({
      name: `${pharmacist.givenName} ${pharmacist.familyName}`,
      ahpraNumber: pharmacist.ahpraNumber,
    }));

    setNewFollowUp((prev) => ({
      ...prev,
      assignedPharmacist: assignedPharmacistData,
    }));
  };

  useEffect(() => {
    if (!customer) {
      logger.log('No customer selected');
      navigate('/pages/patients/search-patient');
    } else {
      setNewFollowUp((prevState) => ({
        ...prevState,
        patientName: `${customer.firstName} ${customer.lastName}`,
        contactPhone: customer.phone || '',
        contactEmail: customer.email || '',
      }));
    }
  }, [customer, navigate]);

  useEffect(() => {
    if (patientFollowUps) {
      const convertedFollowUps = patientFollowUps.map((followUp) => ({
        ...followUp,
        visitDate: followUp.visitDate ? dayjs(followUp.visitDate) : null,
        followupDate: followUp.followupDate ? dayjs(followUp.followupDate) : null,
        followupTime: followUp.followupTime ? dayjs(`2000-01-01T${followUp.followupTime}`) : null,
        patientID: followUp.patientID,
        pharmacistID: followUp.pharmacistID,
        pharmacyID: followUp.pharmacyID
      }));
      setFollowUps(convertedFollowUps);
    }
  }, [patientFollowUps]);

  // Add new state for message error
  const [messageError, setMessageError] = useState(false);

  // Add new state for phone error near other error states
  const [phoneError, setPhoneError] = useState(false);

  // Add ref for the phone input
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const [selectedTemplate, setSelectedTemplate] = useState('custom');

  const formatTemplateMessage = (template: string) => {
    const pharmacy = pharmacyName || 'our pharmacy';
    const phone = pharmacyPhone || process.env.REACT_APP_PHARMACY_PHONE || '';

    return template
      .replace('{pharmacistName}', providerFirstName || 'your pharmacist')
      .replace('{pharmacyName}', pharmacy)
      .replace('{phoneNumber}', phone)
      .replace('{followupDate}', newFollowUp.followupDate?.format('DD/MM/YYYY') || '')
      .replace('{followupTime}', newFollowUp.followupTime?.format('h:mm A') || '');
  };

  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Add new state for visit reason error near other error states
  const [visitReasonError, setVisitReasonError] = useState(false);

  // Add ref for the visit reason input
  const visitReasonRef = useRef<HTMLInputElement>(null);

  const handleAddOrUpdateFollowUp = async () => {
    let hasError = false;

    // Check for required phone number
    if (!newFollowUp.contactPhone.trim()) {
      setPhoneError(true);
      hasError = true;
    }

    // Check for required visit reason
    if (!newFollowUp.visitReason) {
      setVisitReasonError(true);
      hasError = true;
    }

    // Check both existing conditions
    if (!newFollowUp.followupDate) {
      setDateError(true);
      hasError = true;
    }

    if (newFollowUp.followupType === 'sms' && !newFollowUp.followupReason.trim()) {
      setMessageError(true);
      hasError = true;
    }

    // If any validation failed, focus on the first error field and return
    if (hasError) {
      if (!newFollowUp.visitReason) {
        visitReasonRef.current?.focus();
      } else if (!newFollowUp.contactPhone.trim()) {
        phoneInputRef.current?.focus();
      } else if (!newFollowUp.followupDate) {
        followupDateRef.current?.focus();
      } else if (newFollowUp.followupType === 'sms' && !newFollowUp.followupReason.trim()) {
        const messageField = document.querySelector('input[name="followupReason"]');
        if (messageField instanceof HTMLElement) {
          messageField.focus();
        }
      }
      return;
    }

    setIsSubmitting(true);
    try {
      if (editingFollowUp) {
        // Update existing follow-up
        const response = await axios.put(process.env.REACT_APP_PATIENT_FOLLOWUPS_UPDATE_URL!, {
          id: editingFollowUp,
          patientID: customer?.patientID,
          pharmacistID: pharmacistID,
          pharmacyID: pharmacyID,
          patientName: newFollowUp.patientName,
          contactPhone: newFollowUp.contactPhone,
          contactEmail: newFollowUp.contactEmail,
          preferredContactMethod: newFollowUp.preferredContactMethod,
          visitDate: newFollowUp.visitDate?.format('YYYY-MM-DD'),
          visitReason: newFollowUp.visitReason,
          medicationsPrescribed: newFollowUp.medicationsPrescribed,
          followupDate: newFollowUp.followupDate?.format('YYYY-MM-DD'),
          followupTime: newFollowUp.followupTime?.format('HH:mm'),
          followupType: newFollowUp.followupType,
          followupReason: newFollowUp.followupReason,
          pharmacistNotes: newFollowUp.pharmacistNotes,
          customerConcerns: newFollowUp.customerConcerns,
          reminderSet: newFollowUp.reminderSet,
          status: newFollowUp.status,
          assignedPharmacist: newFollowUp.assignedPharmacist,
        });

        if (response.status === 200) {
          await followUpsRefetch();
          setEditingFollowUp(null);
          setSuccessMessage('Follow-up updated successfully');
          // Reset form except for patient info
          setNewFollowUp((prev) => ({
            ...prev,
            visitDate: dayjs(),
            visitReason: '',
            medicationsPrescribed: '',
            followupDate: null,
            followupTime: dayjs().set('hour', 8).set('minute', 0),
            followupType: 'phone',
            followupReason: '',
            pharmacistNotes: '',
            customerConcerns: '',
            reminderSet: true,
            status: 'pending',
            assignedPharmacist: [],
          }));
        } else {
          console.error('Failed to update follow-up');
          setErrorMessage('Failed to update follow-up');
        }
      } else {
        // Add new follow-up
        const newFollowUpId = uuidv4();
        const response = await axios.post(process.env.REACT_APP_PATIENT_FOLLOWUPS_CREATE_URL!, {
          id: newFollowUpId,
          patientID: customer?.patientID,
          pharmacistID: pharmacistID,
          pharmacyID: pharmacyID,
          patientName: newFollowUp.patientName,
          contactPhone: newFollowUp.contactPhone,
          contactEmail: newFollowUp.contactEmail,
          preferredContactMethod: newFollowUp.preferredContactMethod,
          visitDate: newFollowUp.visitDate?.format('YYYY-MM-DD'),
          visitReason: newFollowUp.visitReason,
          medicationsPrescribed: newFollowUp.medicationsPrescribed,
          followupDate: newFollowUp.followupDate?.format('YYYY-MM-DD'),
          followupTime: newFollowUp.followupTime?.format('HH:mm'),
          followupType: newFollowUp.followupType,
          followupReason: newFollowUp.followupReason,
          pharmacistNotes: newFollowUp.pharmacistNotes,
          customerConcerns: newFollowUp.customerConcerns,
          reminderSet: newFollowUp.reminderSet,
          status: newFollowUp.status,
          assignedPharmacist: newFollowUp.assignedPharmacist,
        });

        if (response.status === 200) {
          // Use the renamed refetch function
          await followUpsRefetch();
          setSuccessMessage('Follow-up created successfully');
          // Reset form except for patient info
          setNewFollowUp((prev) => ({
            ...prev,
            visitDate: dayjs(),
            visitReason: '',
            medicationsPrescribed: '',
            followupDate: null,
            followupTime: dayjs().set('hour', 8).set('minute', 0),
            followupType: 'phone',
            followupReason: '',
            pharmacistNotes: '',
            customerConcerns: '',
            reminderSet: true,
            status: 'pending',
            assignedPharmacist: [],
          }));
          setOtherVisitReason('');
        } else {
          console.error('Failed to create follow-up');
          setErrorMessage('Failed to create follow-up');
        }
      }
    } catch (error) {
      console.error('Error creating follow-up:', error);
      setErrorMessage('Error creating follow-up');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteFollowUp = async (id: string) => {
    try {
      const response = await axios.post(process.env.REACT_APP_PATIENT_FOLLOWUPS_DELETE_URL!, {
        id: id,
      });

      if (response.status === 200) {
        // Remove from local state after successful deletion from backend
        setFollowUps(followUps.filter((followUp) => followUp.id !== id));
        await followUpsRefetch(); // Refresh the list
      } else {
        console.error('Failed to delete follow-up');
        setErrorMessage('Failed to delete follow-up');
      }
    } catch (error) {
      console.error('Error deleting follow-up:', error);
      setErrorMessage('Error deleting follow-up');
    }
  };

  const handleEditFollowUp = (id: string) => {
    const followUpToEdit = followUps.find((followUp) => followUp.id === id);
    if (followUpToEdit) {
      setNewFollowUp(followUpToEdit);
      setEditingFollowUp(id);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'success';
      case 'cancelled':
        return 'error';
      case 'rescheduled':
        return 'warning';
      default:
        return 'default';
    }
  };

  const getIconProps = (type: string) => {
    const baseProps = { size: 24 };
    switch (type) {
      case 'phone':
        return { ...baseProps, color: '#4CAF50', bgColor: '#E8F5E9' };
      case 'video':
        return { ...baseProps, color: '#2196F3', bgColor: '#E3F2FD' };
      case 'email':
        return { ...baseProps, color: '#FFC107', bgColor: '#FFF8E1' };
      case 'in-person':
        return { ...baseProps, color: '#9C27B0', bgColor: '#F3E5F5' };
      case 'sms':
        return { ...baseProps, color: '#FF5722', bgColor: '#FBE9E7' };
      default:
        return { ...baseProps, color: '#757575', bgColor: '#F5F5F5' };
    }
  };

  const FollowUpIcon: React.FC<{ followupType: string }> = ({ followupType }) => {
    const { color, bgColor } = getIconProps(followupType);

    const IconComponent = (() => {
      switch (followupType) {
        case 'phone':
          return Phone;
        case 'video':
          return Video;
        case 'email':
          return Mail;
        case 'in-person':
          return UserPlus;
        case 'sms':
          return MessageSquare;
        default:
          return null;
      }
    })();

    if (!IconComponent) return null;

    return (
      <Box
        sx={{
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: bgColor,
        }}
      >
        <IconComponent color={color} size={24} />
      </Box>
    );
  };

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <Box sx={{ p: 3, minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, color: '#333', display: 'flex', alignItems: 'center' }}>
        <UserPlus size={32} style={{ marginRight: '10px' }} />
        Schedule Follow-up
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card elevation={3}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ mb: 3, color: '#1976d2', display: 'flex', alignItems: 'center' }}
              >
                <PlusCircle size={24} style={{ marginRight: '10px' }} />
                Add New Follow-up
              </Typography>
              <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Patient Name"
                      value={newFollowUp.patientName}
                      onChange={(e) => setNewFollowUp({ ...newFollowUp, patientName: e.target.value })}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Contact Phone"
                      value={newFollowUp.contactPhone}
                      onChange={(e) => {
                        setPhoneError(false);
                        setNewFollowUp({ ...newFollowUp, contactPhone: e.target.value });
                      }}
                      size="small"
                      fullWidth
                      error={phoneError}
                      helperText={phoneError ? 'Phone number is required' : ''}
                      inputRef={phoneInputRef}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Contact Email"
                      value={newFollowUp.contactEmail}
                      onChange={(e) => setNewFollowUp({ ...newFollowUp, contactEmail: e.target.value })}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="preferred-contact-label">Preferred Contact</InputLabel>
                      <Select
                        labelId="preferred-contact-label"
                        value={newFollowUp.preferredContactMethod}
                        onChange={(e) =>
                          setNewFollowUp({
                            ...newFollowUp,
                            preferredContactMethod: e.target.value as 'phone' | 'email',
                          })
                        }
                        label="Preferred Contact"
                      >
                        <MenuItem value="phone">Phone</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      label="Visit Date"
                      disableFuture
                      format="DD/MM/YYYY"
                      value={newFollowUp.visitDate}
                      onChange={(date: Dayjs | null) => setNewFollowUp({ ...newFollowUp, visitDate: date })}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      label="Follow-up Date"
                      disablePast
                      format="DD/MM/YYYY"
                      value={newFollowUp.followupDate}
                      onChange={(date: Dayjs | null) => {
                        setDateError(false);
                        setNewFollowUp({ ...newFollowUp, followupDate: date });
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          error: dateError,
                          helperText: dateError ? 'Follow-up date is required' : '',
                          inputRef: followupDateRef,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TimePicker
                      label="Follow-up Time"
                      value={newFollowUp.followupTime}
                      onChange={(time: Dayjs | null) => setNewFollowUp({ ...newFollowUp, followupTime: time })}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          InputProps: {
                            startAdornment: <Clock size={18} style={{ marginRight: '8px' }} />,
                          },
                        },
                      }}
                      defaultValue={dayjs().set('hour', 8).set('minute', 0)} // Set default time to 8:00 AM
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="followup-type-label">Follow-up Type</InputLabel>
                      <Select
                        labelId="followup-type-label"
                        value={newFollowUp.followupType}
                        onChange={(e) => {
                          const newType = e.target.value as 'phone' | 'in-person' | 'video' | 'sms';
                          setMessageError(false); // Clear message error when changing type
                          setNewFollowUp({
                            ...newFollowUp,
                            followupType: newType,
                            // Optionally clear the message when switching away from SMS
                            followupReason: newType !== 'sms' ? '' : newFollowUp.followupReason,
                          });
                        }}
                        label="Follow-up Type"
                      >
                        <MenuItem value="phone">Phone</MenuItem>
                        <MenuItem value="in-person">In-person</MenuItem>
                        <MenuItem value="video">Video</MenuItem>
                        <MenuItem value="sms">SMS</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="set-reminder-label">Set Reminder</InputLabel>
                      <Select
                        labelId="set-reminder-label"
                        value={newFollowUp.reminderSet ? 'yes' : 'no'}
                        onChange={(e) => setNewFollowUp({ ...newFollowUp, reminderSet: e.target.value === 'yes' })}
                        label="Set Reminder"
                      >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={newFollowUp.visitReason === 'Other' ? 6 : 12}>
                    <FormControl fullWidth size="small" error={visitReasonError}>
                      <InputLabel id="visit-reason-label">Visit Reason</InputLabel>
                      <Select
                        labelId="visit-reason-label"
                        value={newFollowUp.visitReason}
                        onChange={(e) => {
                          const value = e.target.value;
                          setVisitReasonError(false);
                          setNewFollowUp({ ...newFollowUp, visitReason: value });
                          if (value !== 'Other') {
                            setOtherVisitReason('');
                          }
                        }}
                        label="Visit Reason"
                        inputRef={visitReasonRef}
                      >
                        {visitReasons.map((reason) => (
                          <MenuItem key={reason} value={reason}>
                            {reason}
                          </MenuItem>
                        ))}
                      </Select>
                      {visitReasonError && <FormHelperText error>Visit reason is required</FormHelperText>}
                    </FormControl>
                  </Grid>
                  {newFollowUp.visitReason === 'Other' && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Specify Other Reason"
                        value={otherVisitReason}
                        onChange={(e) => {
                          setOtherVisitReason(e.target.value);
                          setNewFollowUp({ ...newFollowUp, visitReason: `Other: ${e.target.value}` });
                        }}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
                {newFollowUp.followupType === 'sms' ? (
                  <Box sx={{ width: '100%' }}>
                    <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
                      <FormLabel component="legend">Select Message Template</FormLabel>
                      <RadioGroup
                        value={selectedTemplate}
                        onChange={(e) => {
                          const templateId = e.target.value;
                          setSelectedTemplate(templateId);
                          if (templateId !== 'custom') {
                            const template = SMS_TEMPLATES.find((t) => t.id === templateId);
                            if (template) {
                              setMessageError(false);
                              setNewFollowUp({
                                ...newFollowUp,
                                followupReason: formatTemplateMessage(template.text),
                              });
                            }
                          }
                        }}
                      >
                        {SMS_TEMPLATES.map((template) => (
                          <FormControlLabel
                            key={template.id}
                            value={template.id}
                            control={<Radio />}
                            label={template.id === 'custom' ? 'Custom Message' : formatTemplateMessage(template.text)}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    {selectedTemplate === 'custom' && (
                      <TextField
                        label="Custom Message"
                        name="followupReason"
                        multiline
                        rows={3}
                        value={newFollowUp.followupReason}
                        onChange={(e) => {
                          setMessageError(false);
                          setNewFollowUp({ ...newFollowUp, followupReason: e.target.value });
                        }}
                        size="small"
                        fullWidth
                        error={messageError}
                        InputProps={{
                          startAdornment: (
                            <MessageSquare size={18} style={{ marginRight: '8px', alignSelf: 'flex-start' }} />
                          ),
                        }}
                        helperText={
                          messageError
                            ? 'Message is required for SMS follow-up'
                            : `${newFollowUp.followupReason.length} characters (${Math.ceil(
                              newFollowUp.followupReason.length / MAX_MESSAGE_LENGTH
                            )} SMS${Math.ceil(newFollowUp.followupReason.length / MAX_MESSAGE_LENGTH) > 1 ? 's' : ''
                            })`
                        }
                        FormHelperTextProps={{
                          sx: {
                            textAlign: 'right',
                            color: messageError ? 'error.main' : 'text.secondary',
                          },
                        }}
                      />
                    )}
                  </Box>
                ) : (
                  <TextField
                    label="Follow-up Reason"
                    name="followupReason"
                    multiline
                    rows={2}
                    value={newFollowUp.followupReason}
                    onChange={(e) => {
                      setMessageError(false);
                      setNewFollowUp({ ...newFollowUp, followupReason: e.target.value });
                    }}
                    size="small"
                    fullWidth
                    error={messageError}
                    InputProps={{
                      startAdornment: <FileText size={18} style={{ marginRight: '8px', alignSelf: 'flex-start' }} />,
                    }}
                  />
                )}
                <TextField
                  label="Pharmacist Notes"
                  multiline
                  rows={3}
                  value={newFollowUp.pharmacistNotes}
                  onChange={(e) => setNewFollowUp({ ...newFollowUp, pharmacistNotes: e.target.value })}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <Pill size={18} style={{ marginRight: '8px', alignSelf: 'flex-start', marginTop: '5px' }} />
                    ),
                  }}
                />
                <TextField
                  label="Customer Concerns"
                  multiline
                  rows={3}
                  value={newFollowUp.customerConcerns}
                  onChange={(e) => setNewFollowUp({ ...newFollowUp, customerConcerns: e.target.value })}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <MessageCircle
                        size={18}
                        style={{ marginRight: '8px', alignSelf: 'flex-start', marginTop: '5px' }}
                      />
                    ),
                  }}
                />
                <TextField
                  label="Products Supplied"
                  multiline
                  rows={3}
                  value={newFollowUp.medicationsPrescribed}
                  onChange={(e) => setNewFollowUp({ ...newFollowUp, medicationsPrescribed: e.target.value })}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <Pill size={18} style={{ marginRight: '8px', alignSelf: 'flex-start', marginTop: '5px' }} />
                    ),
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="pharmacist-label">Assigned Follow-up Pharmacist(s)</InputLabel>
                      <Select
                        labelId="pharmacist-label"
                        id="pharmacist-select"
                        multiple
                        value={selectedPharmacists.map((p) => p.ahpraNumber)}
                        input={<OutlinedInput label="Assigned Follow-up Pharmacist(s)" size="small" />}
                        onChange={handlePharmacistChange}
                        size="small"
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selectedPharmacists.map((pharmacist) => (
                              <Chip
                                key={pharmacist.ahpraNumber}
                                label={`${pharmacist.givenName} ${pharmacist.familyName}`}
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                }}
                                onDelete={(e) => {
                                  e.stopPropagation();
                                  const newSelected = selectedPharmacists.filter(
                                    (p) => p.ahpraNumber !== pharmacist.ahpraNumber
                                  );
                                  setSelectedPharmacists(newSelected);
                                }}
                                size="small"
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {pharmacists.map((pharmacist) => (
                          <MenuItem key={pharmacist.ahpraNumber} value={pharmacist.ahpraNumber}>
                            {`${pharmacist.givenName} ${pharmacist.familyName}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Alert severity="info" icon={<Info />} sx={{ mb: 2 }}>
                  <AlertTitle>Important Notice</AlertTitle>
                  This follow-up system is for basic patient communication and scheduling only. It is not intended for
                  recording medical outcomes, events, or sensitive health information. Please use appropriate clinical
                  systems for all medical record-keeping.
                </Alert>
                <Button
                  variant="contained"
                  startIcon={editingFollowUp ? <Edit size={18} /> : <PlusCircle size={18} />}
                  onClick={handleAddOrUpdateFollowUp}
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : editingFollowUp ? (
                    'Update Follow-up'
                  ) : (
                    'Add Follow-up'
                  )}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <ScheduledFollowUps
            followUps={followUps}
            handleEditFollowUp={handleEditFollowUp}
            handleDeleteFollowUp={handleDeleteFollowUp}
            fetchMore={fetchMore}
            hasMore={hasMore}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setErrorMessage(null)} severity="error" variant="filled" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSuccessMessage(null)} severity="success" variant="filled" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setErrorMessage(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PatientFollowups;
