import { LazyQueryHookExecOptions, useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { GET_AVAILABILITY_BY_DATE_AND_SERVICE_ID, LIST_AVAILABILITY } from '../codegens/HEALTCARE-RESERVATION/queries';
import {
  Exact,
  GetAvailabilitiesByDateServiceIdQuery,
  GetAvailabilitiesByDateServiceIdQueryVariables,
  InputMaybe,
  ListAvailabilityQuery,
  ListAvailabilityQueryVariables,
  PharmacySchedule,
  ServiceSchedule,
  TablePharmacyAvailabilityFilterInput,
} from '../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import AuthContext from '../contexts/AuthContext';
import { IService } from '../interfaces/IService';
import { GRAPHQL_DESTINATION } from '../ApolloClient';

export interface IWeekSchedule
  extends Pick<PharmacySchedule, 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'> {}

export const useBatchAvailabilityLookup = () => {
  const { pharmacyID } = useContext(AuthContext);
  // const {} = usePha
  const [loadingBatchAvailabilty, setLoadingBatchAvailability] = useState(false);

  const [fetchAvailability, { loading: loadingAvailabilty, error: errorAvailability }] = useLazyQuery<
    ListAvailabilityQuery,
    ListAvailabilityQueryVariables
  >(LIST_AVAILABILITY, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
  });

  const [
    queryAvailabilitiesByDateAndServiceId,
    { loading: loadingAvailabiltyByDateAndServiceId, error: errorAvailabilityByDateAndServiceId },
  ] = useLazyQuery<GetAvailabilitiesByDateServiceIdQuery, GetAvailabilitiesByDateServiceIdQueryVariables>(
    GET_AVAILABILITY_BY_DATE_AND_SERVICE_ID,
    {
      context: {
        clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      },
    }
  );

  useEffect(() => {
    setLoadingBatchAvailability(loadingAvailabilty);
  }, [loadingAvailabilty]);

  const getAvailability = (
    pharmacySchedule: PharmacySchedule | ServiceSchedule | null,
    serviceId: IService['serviceId'],
    selectedDate?: Moment,
    options?: Partial<
      LazyQueryHookExecOptions<
        ListAvailabilityQuery,
        Exact<{
          limit?: InputMaybe<number> | undefined;
          nextToken?: InputMaybe<string> | undefined;
          filter?: InputMaybe<TablePharmacyAvailabilityFilterInput> | undefined;
        }>
      >
    >
  ) => {
    const today = selectedDate ? moment(selectedDate) : moment();

    today.tz(process.env.REACT_APP_DEFAULT_TIMEZONE_PHARMACY || 'Australia/Sydney');

    return fetchAvailability({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          timeslot: {
            between: [today.startOf('day').toISOString(), today.endOf('day').toISOString()],
          },
          pharmacyID: {
            eq: pharmacyID,
          },
          serviceId: {
            eq: serviceId,
          },
        },
      },

      ...options,
    });
  };

  const getAvailabilityByDateAndService = (
    serviceId: IService['serviceId'],
    selectedDate: Moment,
    options?: Partial<
      LazyQueryHookExecOptions<
        GetAvailabilitiesByDateServiceIdQuery,
        Exact<{
          appointmentDate: string;
          serviceId: string;
        }>
      >
    >
  ) => {
    const today = selectedDate ? moment(selectedDate) : moment();

    today.tz(process.env.REACT_APP_DEFAULT_TIMEZONE_PHARMACY || 'Australia/Sydney');

    return queryAvailabilitiesByDateAndServiceId({
      fetchPolicy: 'network-only',
      variables: {
        serviceId,
        appointmentDate: selectedDate.format('YYYY-MM-DD'),
      },
      ...options,
    });
  };

  return {
    getAvailability,
    loadingBatchAvailabilty,
    errorAvailability,
    getAvailabilityByDateAndService,
    loadingAvailabiltyByDateAndServiceId,
    errorAvailabilityByDateAndServiceId,
  };
};
