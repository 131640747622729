// src/graphql-queries.ts or directly in your component file
import { gql } from '@apollo/client';

export const QUERY_PATIENTS_BY_BIRTHDATE_AND_LASTNAME = gql`
  query MyQuery($dateOfBirth: AWSDate!, $lastName: String!) {
    queryPatientsByLastNameDOBIndex(dateOfBirth: $dateOfBirth, lastName: $lastName) {
      nextToken
      items {
        addressLineOne
        addressLineTwo
        createdDate
        dateOfBirth
        emailAddress
        mobileNumber
        phoneNumber
        firstName
        initial
        middleName
        gender
        id
        ihiNumber
        lastName
        locality
        medicareCardNumber
        medicareIRN
        patientID
        pharmacistID
        providerNumber
        pharmacyID
        postCode
        state
        isAboriginal
        onlyNameIndicator
      }
    }
  }
`;

export const onCreateBookings = gql`
  subscription OnCreateBookingSubs($id_clinic: String) {
    onCreateBookings(id_clinic: $id_clinic) {
      id_booking
      id_patient
      id_clinic
      id_availability
      time_slot
    }
  }
`;

export const onUpdateBookings = gql`
  subscription OnUpdateBookingSubs($id_clinic: String) {
    onUpdateBookings(id_clinic: $id_clinic) {
      id_booking
      id_patient
      id_clinic
      id_availability
      time_slot
    }
  }
`;

export const LIST_NATURAL_IMMUNITY_CODES = gql`
  query ListNaturalImmunityCodes {
    listNaturalImmunityStatics {
      items {
        AntigenCode
        DiseaseOrAntigen
        activeDate
        createdDate
        id
        isActive
        updatedDate
      }
    }
  }
`;

export const GET_VACCINE_DATA = gql`
  query ListVaccineData {
    listVaxDataAIRS(filter: { EffectiveDate: { eq: "2024-03-01" } }) {
      items {
        AntigenCode
        EffectiveDate
        DiseaseAntigenName
        IsEligibleForAntenatal
        IsEligibleForNIP
        IsEligibleForPrivate
        IsEligibleForStateProgram
        IsEligibleIntradermal
        IsEligibleIntramuscular
        IsEligibleSubcutaneous
        IsMandatoryCovidVaccineBatchNumber
        IsMandatoryForRouteOfAdministration
        IsMandatoryRequiresBatchNumber
        IsMandatoryVaccineType
        IsVaxValidBatchNumber
        VaccineBrandName
        VaccineCode
        id
        isVaccineActive
      }
      nextToken
    }
  }
`;

export const UPDATE_PATIENT_MUTATION = gql`
  mutation UpdatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      addressLineOne
      addressLineTwo
      createdDate
      dateOfBirth
      emailAddress
      firstName
      initial
      middleName
      gender
      id
      ihiNumber
      lastName
      locality
      medicareCardNumber
      medicareExpiryDate
      medicareIRN
      mobileNumber
      patientID
      pharmacistID
      providerNumber
      pharmacyID
      phoneNumber
      postCode
      state
      isAboriginal
      onlyNameIndicator
    }
  }
`;

export const CREATE_PATIENT_MUTATION = gql`
  mutation createPatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      addressLineOne
      addressLineTwo
      createdDate
      dateOfBirth
      emailAddress
      firstName
      initial
      middleName
      gender
      patientID
      ihiNumber
      lastName
      locality
      medicareCardNumber
      medicareExpiryDate
      medicareIRN
      mobileNumber
      pharmacistID
      providerNumber
      pharmacyID
      phoneNumber
      postCode
      state
      isAboriginal
      onlyNameIndicator
    }
  }
`;

export const CREATE_PROVIDER_MUTATION = gql`
  mutation createProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      providerAHPRA
      createdDate
      isActive
      pharmacyAddress
      minorId
      deviceName
      pharmacyID
      pharmacistID
      abnNumber
      hpiiNumber
      hpioNumber
      pharmacyName
      pharmacyEmailAddress
      pharmacyPhoneNumber
      pharmacyLoginId
      providerNumber
      phoneNumber
      subscription
      providerFirstName
      providerLastName
      isAdministrator
      providerName
      ppaClaims
      pseudoWatchAccess
      prodaOrgId
    }
  }
`;

export const UPDATE_PROVIDER_MUTATION = gql`
  mutation updateProvider($input: UpdateProviderInput!) {
    updateProvider(input: $input) {
      pharmacistID
      pharmacyID
      abnNumber
      hpiiNumber
      hpioNumber
      pharmacyName
      pharmacyAddress
      pharmacyEmailAddress
      pharmacyPhoneNumber
      pharmacyLoginId
      subscription
      providerNumber
      providerAHPRA
      createdDate
      phoneNumber
      deviceName
      minorId
      isActive
      signature
      isAdministrator
      providerName
      ppaClaims
      pseudoWatchAccess
      prodaOrgId
    }
  }
`;

export const CREATE_CERTIFICATE_MUTATION = gql`
  mutation createLeaveCertificates($input: CreateLeaveCertificatesInput!) {
    createLeaveCertificates(input: $input) {
      address
      consultation
      consultationDate
      createdDate
      durationOfLeave
      evidence
      fullName
      hasConsultedPractitioner
      householdMemberName
      illnessDescription
      injuryLocation
      isCarersLeave
      isImmediateFamily
      isMedicalPractitionerReferred
      isNotMedicalTreatment
      isPharmacistOpinion
      isPharmacistSatisfied
      isPrescriptionRequired
      isPriorCertificate
      leaveDates
      leaveType
      lengthPersonOffWork
      medicationProvided
      memberType
      pharmacistID
      pharmacistName
      pharmacistSignature
      pharmacyID
      practitionerConsultationDate
      prescriptionDetails
      priorCertDetails
      reasonForReferral
      seeMedicalPractitionerAdvice
      symptoms
      symptomsStartDate
      opinion
      pharmacyAddress
      providerAHPRA
      pharmacyName
      pharmacyPhoneNumber
      pharmacyEmailAddress
      isInjured
    }
  }
`;

// PSEUDO WATCH
export const QUERY_LIST_CUSTOMERS = gql`
  query QueryListCustomers($filter: TablePseudoWatchCustomerFilterInput, $limit: Int, $nextToken: String) {
    listPseudoWatchCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        addressLineOne
        addressLineTwo
        createdDate
        customerId
        dateOfBirth
        email
        firstName
        gender
        identificationNumber
        identificationType
        lastName
        locality
        medicalHistory
        pharmacyId
        phoneNumber
        postCode
        state
      }
      nextToken
    }
  }
`;

export const QUERY_LIST_TRANSACTIONS = gql`
  query QueryListTransaction($filter: TablePseudoWatchTransactionFilterInput, $limit: Int, $nextToken: String) {
    listPseudoWatchTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdDate
        customerId
        pharmacistId
        metadata
        paymentAmount
        paymentChannel
        paymentStatus
        pharmacyId
        products
        totalAmount
        totalQuantity
        transactionId
        transactionStatus
        updatedDate
      }
    }
  }
`;

export const QUERY_GET_CUSTOMER_BY_IDENTIFICATION_NUMBER = gql`
  query QueryGetCuctomerByIdentificationNumber($identificationNumber: String!) {
    queryPseudoWatchCustomersByIdentificationNumberIndex(identificationNumber: $identificationNumber) {
      items {
        addressLineOne
        addressLineTwo
        createdDate
        customerId
        dateOfBirth
        email
        firstName
        gender
        identificationNumber
        identificationType
        lastName
        locality
        medicalHistory
        metadata
        pharmacyId
        phoneNumber
        postCode
        state
      }
    }
  }
`;

export const GET_PSEUDOWATCH_PRODUCTS = gql`
  query QuerySearchProducts($filter: TablePseudoWatchProductFilterInput, $limit: Int, $nextToken: String) {
    listPseudoWatchProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        productId
        createdDate
        drugSchedule
        genericName
        gstToConsumer
        gstToPharmacy
        gtin
        manufacturerCode
        manufacturerName
        metadata
        minimumOrderQuantity
        orderMultiple
        pharmacyId
        productDescription
        retailShelfPack
        unitPriceExcludeGst
        uom
        uomQuantity
      }
      nextToken
    }
  }
`;

export const QUERY_PRODUCT_BY_PRODUCT_ID = gql`
  query QueryProductByID($productId: ID!) {
    getPseudoWatchProduct(productId: $productId) {
      productId
      drugStrength
      createdDate
      drugSchedule
      genericName
      gstToConsumer
      gstToPharmacy
      gtin
      manufacturerCode
      manufacturerName
      metadata
      minimumOrderQuantity
      orderMultiple
      pharmacyId
      productDescription
      retailShelfPack
      unitPriceExcludeGst
      uom
      uomQuantity
    }
  }
`;

export const QUERY_PRODUCT_BY_GTIN = gql`
  query QueryProductByGTIN($gtin: ID!) {
    queryPseudoWatchProductsByGtinIndex(gtin: $gtin) {
      items {
        createdDate
        drugSchedule
        drugStrength
        genericName
        gstToConsumer
        gstToPharmacy
        gtin
        manufacturerName
        manufacturerCode
        metadata
        orderMultiple
        minimumOrderQuantity
        pharmacyId
        productDescription
        productId
        retailShelfPack
        unitPriceExcludeGst
        uom
        uomQuantity
      }
      nextToken
    }
  }
`;

export const QUERY_PROVIDERS_BY_PHARMACY_ID = gql`
  query QueryProvidersByPharmacyIDIndex($pharmacyID: ID!, $first: Int, $after: String) {
    queryProvidersByPharmacyIDIndex(pharmacyID: $pharmacyID, first: $first, after: $after) {
      items {
        providerAHPRA
        createdDate
        deviceName
        isActive
        minorId
        pharmacistID
        abnNumber
        hpiiNumber
        hpioNumber
        pharmacyAddress
        pharmacyEmailAddress
        pharmacyID
        pharmacyLoginId
        phoneNumber
        isAdministrator
        pharmacyName
        providerFirstName
        providerLastName
        pharmacyPhoneNumber
        providerNumber
        signature
        subscription
        ppaClaims
        pseudoWatchAccess
        prodaOrgId
      }
    }
  }
`;

export const QUERY_GET_PROVIDER = gql`
  query GetPharmacist($pharmacistId: ID!) {
    getProvider(pharmacistID: $pharmacistId) {
      providerAHPRA
      createdDate
      deviceName
      isActive
      minorId
      pharmacistID
      abnNumber
      hpiiNumber
      hpioNumber
      pharmacyAddress
      pharmacyEmailAddress
      pharmacyID
      pharmacyName
      pharmacyLoginId
      pharmacyPhoneNumber
      providerNumber
      signature
      subscription
      providerName
      phoneNumber
      isAdministrator
      providerFirstName
      providerLastName
      ppaClaims
      pseudoWatchAccess
      prodaOrgId
    }
  }
`;

export const QUERY_GET_PROVIDER_BY_EMAIL_PHARMACY_ID = gql`
  query QueryGetProviderByEmail($email: String!, $pharmacyID: ID!) {
    listProviders(filter: { pharmacyLoginId: { eq: $email }, pharmacyID: { eq: $pharmacyID } }) {
      items {
        providerAHPRA
        createdDate
        deviceName
        isActive
        isAdministrator
        minorId
        abnNumber
        hpiiNumber
        hpioNumber
        pharmacistID
        pharmacyAddress
        pharmacyEmailAddress
        pharmacyID
        pharmacyLoginId
        pharmacyName
        pharmacyPhoneNumber
        providerName
        providerNumber
        phoneNumber
        signature
        subscription
        pseudoWatchAccess
        ppaClaims
        prodaOrgId
      }
    }
  }
`;

export const LIST_PROVIDER = gql`
  query ListProvider($filter: TableProviderFilterInput, $limit: Int, $nextToken: String = null) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        createdDate
        deviceName
        isActive
        isAdministrator
        minorId
        abnNumber
        hpiiNumber
        hpioNumber
        pharmacistID
        pharmacyAddress
        pharmacyEmailAddress
        pharmacyID
        pharmacyLoginId
        pharmacyName
        pharmacyPhoneNumber
        phoneNumber
        ppaClaims
        providerAHPRA
        providerFirstName
        providerLastName
        providerName
        providerNumber
        pseudoWatchAccess
        signature
        subscription
        prodaOrgId
      }
    }
  }
`;

export const QUERY_LIST_PROVIDER_BY_EMAIL = gql`
  query QeryListProviderByEmail($pharmacyLoginId: String!, $after: String = null, $first: Int) {
    queryProvidersByLoginId(pharmacyLoginId: $pharmacyLoginId, first: $first, after: $after) {
      nextToken
      items {
        abnNumber
        createdDate
        deviceName
        hpiiNumber
        hpioNumber
        isActive
        isAdministrator
        metadata
        minorId
        pharmacistID
        pharmacyAddress
        pharmacyEmailAddress
        pharmacyID
        pharmacyLoginId
        pharmacyName
        pharmacyPhoneNumber
        phoneNumber
        ppaClaims
        providerAHPRA
        providerFirstName
        providerLastName
        providerName
        providerNumber
        pseudoWatchAccess
        signature
        subscription
        prodaOrgId
      }
    }
  }
`;

export const DELETE_PROVIDER_MUTATION = gql`
  mutation MutationDeleteProvider($pharmacistID: ID!) {
    deleteProvider(input: { pharmacistID: $pharmacistID }) {
      providerAHPRA
      createdDate
      deviceName
      isActive
      isAdministrator
      minorId
      abnNumber
      hpiiNumber
      hpioNumber
      pharmacistID
      pharmacyAddress
      pharmacyEmailAddress
      pharmacyID
      pharmacyLoginId
      pharmacyName
      pharmacyPhoneNumber
      phoneNumber
      providerName
      providerNumber
      signature
      subscription
      pseudoWatchAccess
      prodaOrgId
    }
  }
`;

export const QUERY_GET_PROVIDER_AUTHORIZATION = gql`
  query GetPharmacist($pharmacistId: ID!) {
    getProvider(pharmacistID: $pharmacistId) {
      pharmacistID
      isActive
      isAdministrator
      ppaClaims
      pseudoWatchAccess
    }
  }
`;

export const QUERY_GET_CERTIFICATE_HISTORY = gql`
  query GetCertificates($pharmacyID: String!, $createdDate: AWSDate!, $after: String) {
    queryLeaveCertificatesByPharmacyIDIndex(pharmacyID: $pharmacyID, createdDate: $createdDate, after: $after) {
      items {
        address
        consultationDate
        createdDate
        consultation
        durationOfLeave
        evidence
        fullName
        hasConsultedPractitioner
        householdMemberName
        illnessDescription
        isCarersLeave
        injuryLocation
        isImmediateFamily
        isNotMedicalTreatment
        isMedicalPractitionerReferred
        isPharmacistOpinion
        isPrescriptionRequired
        isPharmacistSatisfied
        isPriorCertificate
        leaveDates
        leaveType
        lengthPersonOffWork
        medicationProvided
        memberType
        pharmacistID
        pharmacistName
        pharmacistSignature
        pharmacyID
        pharmacyAddress
        practitionerConsultationDate
        priorCertDetails
        prescriptionDetails
        reasonForReferral
        seeMedicalPractitionerAdvice
        symptoms
        symptomsStartDate
        providerAHPRA
        pharmacyName
        pharmacyPhoneNumber
        pharmacyEmailAddress
        isInjured
      }
      nextToken
    }
  }
`;

export const CREATE_PHARMACY_MUTATION = gql`
  mutation createPharmacy($input: CreatePharmacyInput!) {
    createPharmacy(input: $input) {
      abnNumber
      createdDate
      id
      isActive
      minorId
      ownerAHPRA
      ownerFirstName
      ownerLastName
      ownerPhoneNumber
      pharmacists
      pharmacyAddress
      pharmacyEmailAddress
      pharmacyID
      pharmacyLoginId
      pharmacyName
      pharmacyPhoneNumber
      ppaClaims
      pseudoWatchAccess
      subscription
      prodaOrgId
    }
  }
`;
