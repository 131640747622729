import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../redux/store"; // Adjust the import path as necessary

interface ProtectedRouteProps {
  stepRequired?: number;
  authenticationRequired?: boolean;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  stepRequired,
  authenticationRequired,
}) => {
  const { registrationStep, isAuthenticated, completedSteps } = useSelector(
    (state: RootState) => state.Auth
  );

  if (authenticationRequired && !isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  // Assuming stepRequired is 1-based indexing
  if (
    stepRequired !== undefined &&
    (!completedSteps.includes(stepRequired - 1) ||
      registrationStep !== stepRequired)
  ) {
    return <Navigate to={`/register/step-${registrationStep}`} replace />;
  }

  return <>{children}</>; // Render children directly
};

export default ProtectedRoute;
