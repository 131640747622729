import { GetBookingQuery } from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";

export interface IBooking extends NonNullable<GetBookingQuery["getBookings"]>{}

export enum BOOKING_STATUS {
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  CREATED = "CREATED",
  CANCELED_BY_PHARMACY = "CANCELED_BY_PHARMACY",
  CANCELED_BY_PATIENT = "CANCELED_BY_PATIENT",
  RESCHEDULED = "RESCHEDULED" // Updated
}