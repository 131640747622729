import { gql } from '@apollo/client';

export const SUBSCRIPTION_ONCREATE_BOOKING = gql`
  subscription OnCreateBooking(
    $pharmacyID: ID
    $VaccineBrandName: String
    $patientID: ID
    $bookingID: ID
    $availabilityID: ID
  ) {
    onCreateBookings(
      availabilityID: $availabilityID
      bookingID: $bookingID
      patientID: $patientID
      pharmacyID: $pharmacyID
      VaccineBrandName: $VaccineBrandName
    ) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      IsAssessmentCompleted
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        clientSecret
        status
        token
      }
      room {
        roomID
        roomName
      }
    }
  }
`;

export const SUBSCRIPTION_ONUPDATE_BOOKING = gql`
  subscription OnUpdateBooking(
    $availabilityID: ID
    $bookingID: ID
    $patientID: ID
    $pharmacyID: ID
    $VaccineBrandName: String
  ) {
    onUpdateBookings(
      availabilityID: $availabilityID
      bookingID: $bookingID
      patientID: $patientID
      pharmacyID: $pharmacyID
      VaccineBrandName: $VaccineBrandName
    ) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      IsAssessmentCompleted
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        clientSecret
        status
        token
      }
      room {
        roomID
        roomName
      }
    }
  }
`;

export const SUBSCRIPTION_ONCREATE_SERVICE_CATEGORY = gql`
  subscription OnCreateServiceCategory($categoryDescription: String, $categoryID: ID, $categoryName: String) {
    onCreateServiceCategory(
      categoryDescription: $categoryDescription
      categoryID: $categoryID
      categoryName: $categoryName
    ) {
      categoryDescription
      categoryID
      categoryName
    }
  }
`;

// ROOM
export const SUBSCRIPTION_ONCREATE_ROOM = gql`
  subscription OnCreateRoom($pharmacyID: ID!) {
    onCreateRoom(pharmacyID: $pharmacyID) {
      createdDate
      description
      isActive
      isAvailable
      pharmacyID
      roomID
      roomName
      snoozed
      snoozeTime
      schedule 
      unavailableDates
    }
  }
`;

export const SUBSCRIPTION_ONUPDATE_ROOM = gql`
  subscription OnUpdateRoom($pharmacyID: ID!) {
    onUpdateRoom(pharmacyID: $pharmacyID) {
      createdDate
      description
      isActive
      isAvailable
      pharmacyID
      roomID
      roomName
      snoozed
      snoozeTime
      schedule 
      unavailableDates
    }
  }
`;

export const SUBSCRIPTION_DELETE_ROOM = gql`
  subscription OnDeleteRoom($pharmacyID: ID!) {
    onDeleteRoom(pharmacyID: $pharmacyID) {
      createdDate
      description
      isActive
      isAvailable
      pharmacyID
      roomID
      roomName
      snoozed
      snoozeTime
      schedule  
      unavailableDates
    }
  }
`;
