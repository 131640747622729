import React from 'react';
import { APICore } from '../../helpers/api/apiCore'; // Update the path according to your project structure
import { Box, Paper } from '@mui/material';
import BillingPortal from '../../components/Payments/BillingPortal';

const SubscriptionDashboard: React.FC = () => {
  return (
    <Box component="div" sx={{ py: 3 }}>
      <Paper
        sx={{
          px: '2rem',
          py: '1rem',
          background: 'white',
          minHeight: '80vh',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <BillingPortal />
      </Paper>
    </Box>
  );
};

export default SubscriptionDashboard;
