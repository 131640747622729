import ArrowBack from "@mui/icons-material/ArrowBack";
import Button, { ButtonProps } from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

/**
 * BackNavigation component.
 * Render back navigation for detail content administrator.
 *
 * @component
 * @param {ButtonProps} props - The props for the BackNavigation component.
 * @returns {JSX.Element} The rendered BackNavigation component.
 */
const BackNavigation = (
  props: { title?: string; onClickHandler?: () => void } & ButtonProps
) => {
  const { className, title, onClickHandler, ...rest } = props;
  const { search, state } = useLocation();

  const router = useNavigate();
  const searchParams = new URLSearchParams(search);
  const redirectTo = searchParams.get("backTo");

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, flexGrow: 1 }}>
      <Button
        variant="contained"
        color="primary"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 32,
          height: 32,
          minWidth: 0,
        }}
        {...rest}
        onClick={() => {
          if (typeof onClickHandler === "function") {
            onClickHandler();
          } else {
            if (redirectTo) {
              router(redirectTo);
            } else {
              // search on the state
              const backTo = state?.backTo;
              if (backTo) {
                router(backTo);
              } else {
                router(-1);
              }
            }
          }
        }}
      >
        <ArrowBack sx={{ mx: "auto", my: 0, width: 20, height: 20 }} />
      </Button>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};

export default BackNavigation;
