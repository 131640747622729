import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { IService } from '../interfaces/IService';
import { BOOKING_STATUS } from '../interfaces/IBooking';
import { IWeekSchedule } from '../hooks/useBatchAvailabilityLookup';
import {
  Bookings,
  Hours,
  PharmacyAvailability,
  ServiceSchedule,
} from '../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import { useServiceSchedule } from '../hooks/useServiceSchedule';
import AuthContext from './AuthContext';
import { useServices } from '../hooks/useServices';
import { Moment } from 'moment';
import { useLocation } from 'react-router-dom';
import { useBookings } from '../hooks/useBookings';
import { logger } from '../utils/logger';
import AppointmentModal from '../components/Appointments/AppointmentModal';
import CancelAppointmentModal from '../pages/apps/Calendar/Forms/CancelAppointmentModal';
import moment from 'moment';
import { IBooking } from 'interfaces/healthcare-reservation/IBooking';
import { formatToABooking } from 'utils/graphql-layers/reservations';

interface IAppointmentContextProps {
  services: IService[];
  // setServices: Dispatch<SetStateAction<IService[]>>;
  appointments: IBooking[];
  setAppointments: Dispatch<SetStateAction<IBooking[]>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  schedule: IWeekSchedule | null;
  setSchedule: Dispatch<SetStateAction<IWeekSchedule | null>>;
  getScheduleByDay: (day: keyof IWeekSchedule) => Hours | null;
  serviceSchedules: ServiceSchedule[];
  selectedService: IService | null;
  setSelectedService: Dispatch<SetStateAction<IService | null>>;
  selectedAppointment: IBooking | null;
  setSelectedAppointment: Dispatch<SetStateAction<IBooking | null>>;
  setOpenEvent: Dispatch<SetStateAction<boolean>>;
}

const AppointmentsContext = createContext<IAppointmentContextProps | undefined>(undefined);

interface ICalendarProviderProps {
  children: ReactNode;
}

interface IUnavailable {
  type: 'SERVICE' | 'AVAILABILITY' | 'SCHEDULE';
  status: boolean;
}

export const AppointmentsProvider = ({ children }: ICalendarProviderProps) => {
  const { pharmacyID } = useContext(AuthContext);
  const [services, setServices] = useState<IService[]>([]);
  const [appointments, setAppointments] = useState<IBooking[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<IBooking | null>(null);
  // const [selectedAppointment, setSelectedAppointment] = useState<IBooking | null | string>(null);
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState<IWeekSchedule | null>(null);
  const [serviceSchedules, setServiceSchedules] = useState<ServiceSchedule[]>([]);
  const [isUnavailable, setIsUnavailable] = useState<IUnavailable | null>(null);
  const [selectedService, setSelectedService] = useState<IService | null>(null);

  const { getServiceScheduledByPharmacyId, loadingFetchServiceSchedulesByPharmacyId } = useServiceSchedule();
  const { fetchServices, loadingFetchServices } = useServices();
  const { getBooking, loadingGetBooking, updateBooking } = useBookings();
  const [openEvent, setOpenEvent] = useState(false);
  const [showCancelAppointment, setShowCancelAppointment] = useState(false);

  const getScheduleByDay = (day: keyof IWeekSchedule) => {
    if (!schedule) {
      logger.log('schedule did not initiate yet on the getScheduleByDay');
      return null;
    }

    if (schedule[day] === undefined) {
      return null;
    }

    return schedule[day]!;
  };

  useEffect(() => {
    if (!pharmacyID) {
      // If pharmacyID is not available, we'll wait and not make the call yet
      return;
    }

    fetchServices({
      variables: {
        pharmacyID,
      },
      onCompleted(data) {
        const services = data.queryServicesByPharmacyIDIndex?.items as IService[];
        logger.log(services, 'services')
        const sortedServices = [...services].sort((a, b) => a.serviceName.localeCompare(b.serviceName));
        logger.log(sortedServices, 'sortedServices')

        setServices(sortedServices);

        // const activeService = services.find((service) => service.isActive);

        // if (activeService) {
        //   setSelectedService(activeService);
        // } else {
        //   setSelectedService(services[0] || null);
        // }
      },
      onError(error) {
        console.error('Error getting list services', error);
      },
    });
  }, [pharmacyID]);

  // get service schedules when services is initialized
  useEffect(() => {
    if (services) {
      if (services.length > 0) {
        getServiceScheduledByPharmacyId({
          variables: {
            pharmacyID,
          },
          onCompleted(data) {
            const serviceSchedules = data.queryServiceSchedulesByPharmacyIDIndex?.items as ServiceSchedule[];
            setServiceSchedules(serviceSchedules);
          },
          onError(error) {
            console.error('Error querying service schedule', error);
          },
        });
      }
    }
  }, [services]);

  useEffect(() => {
    if (typeof selectedAppointment === 'string') {
      logger.log('Track infinit loop');
      getBooking({
        variables: {
          bookingID: selectedAppointment,
        },
        onCompleted(data) {
          setSelectedAppointment(formatToABooking(data.getBookings as Bookings) || null);
        },
        onError(error) {
          logger.error('An error occurred getting booking data ', error);
        },
      });
    }
  }, [selectedAppointment]);

  const handleCancelAppointment = async () => {
    if (!selectedAppointment) {
      logger.log('There is no appointment selected to be cancelled!');
      return;
    }

    try {
      const { errors } = await updateBooking({
        variables: {
          input: {
            bookingID: selectedAppointment.bookingID,
            status: BOOKING_STATUS.CANCELED_BY_PHARMACY,
            updatedDate: moment().tz('Australia/Sydney').toISOString(),
          },
        },
      });

      if (errors && errors.length) {
        const error = new Error('Failed updating appointments');
        error.name = 'ErrorUpdateException';
        throw error;
      }
    } catch (error) {
      logger.error('handleCancelAppointment error', error);
    }
  };

  return (
    <AppointmentsContext.Provider
      value={{
        services,
        // setServices,
        appointments,
        setAppointments,
        setLoading,
        loading: loading || loadingFetchServiceSchedulesByPharmacyId || loadingFetchServices || loadingGetBooking,
        schedule,
        setSchedule,
        getScheduleByDay,
        serviceSchedules,
        selectedService,
        setSelectedService,
        selectedAppointment,
        setSelectedAppointment,
        setOpenEvent,
      }}
    >
      {children}
      <AppointmentModal
        onCancelAppointment={() => {
          setShowCancelAppointment(true);

          // close event modal
          setOpenEvent(false);
        }}
        show={openEvent}
        onHide={() => setOpenEvent(false)}
      />
      <CancelAppointmentModal
        onHide={() => setShowCancelAppointment(false)}
        onCancel={() => setShowCancelAppointment(false)}
        open={showCancelAppointment}
        onOk={handleCancelAppointment}
      />
    </AppointmentsContext.Provider>
  );
};

export function useAppointment() {
  const context = useContext(AppointmentsContext);

  if (!context) {
    throw new Error('useAppointment must be used within a AppointmentsProvider');
  }

  return context;
}
