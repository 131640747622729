// src/ApolloClient.ts
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

export enum GRAPHQL_DESTINATION {
  DEFAULT = "default",
  PSEUDO_WATCH = "pseudo-watch",
  HEALTHCATE_RESERVATION = "healthcare-reservation",
  // add another if needed
}

const httpLinkDefault = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_X_API_KEY || "",
  },
});

const httpLinkPseudoWatch = new HttpLink({
  uri: process.env.REACT_APP_PSEUDO_WATCH_GRAPHQL_API_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_PSEUDO_WATCH_X_API_KEY || "",
  },
});

const httpLinkHealthcareReservation = new HttpLink({
  uri: process.env.REACT_APP_API_HEALTHCARE_RESERVATION_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_API_HEALTHCARE_RESERVATION_KEY || "",
  },
});

const paramsHealthcareReservation: any = {
  url: process.env.REACT_APP_API_HEALTHCARE_RESERVATION_URL,
  region: "ap-southeast-2",
  auth: {
    type: "API_KEY",
    apiKey: process.env.REACT_APP_API_HEALTHCARE_RESERVATION_KEY,
  },
};

const wsLinkHealthcareReservation = ApolloLink.from([
  createAuthLink(paramsHealthcareReservation),
  createSubscriptionHandshakeLink(
    paramsHealthcareReservation,
    httpLinkHealthcareReservation
  ),
]);

// add more HttpLink if needed

const link = ApolloLink.split(
  (operation) =>
    operation.getContext().clientName === GRAPHQL_DESTINATION.PSEUDO_WATCH,
  httpLinkPseudoWatch, // if the test function returns true, the request will be forwarded to this link
  ApolloLink.split(
    ({ query, getContext }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription" &&
        getContext().clientName === GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION
      );
    },
    wsLinkHealthcareReservation,
    ApolloLink.split(
      ({ getContext }) =>
        getContext().clientName === GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
      httpLinkHealthcareReservation,
      httpLinkDefault
    )
  ) // if the test function returns false, the request will be forwarded to this link)

  // add more split if needed
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Provider: {
        keyFields: ["pharmacistID"],
      },
    },
  }),
});

export default client;
