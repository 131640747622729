import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { NaturalImmunityHistoryResponse } from "./Immunity";
import { formatDate } from "./common/utils";
import { logger } from '../../utils/logger';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { green } from "@mui/material/colors";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import AuthContext from "../../contexts/AuthContext";
import { PatientItem } from "./patient";
import { useIdToken } from "../../hooks/useIdToken";
interface PatientTrialVaccinationHistoryProps {
  naturalImmunityHistory: NaturalImmunityHistoryResponse | null;
  patientIdentifier: string;
  patientItemDetails: PatientItem | undefined;
  refreshIndividualDetails: () => void;
}

interface Data {
  disease: string;
  notificationDate: string;
  labTestDate?: string;
  diagnosisDate: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  { width: 100, label: "Disease", dataKey: "disease" },
  { width: 100, label: "Notification Date", dataKey: "notificationDate" },
  { width: 50, label: "LabTest Date", dataKey: "labTestDate" },
  { width: 50, label: "Diagnosis Date", dataKey: "diagnosisDate" },
  // { width: 300, label: "Comments", dataKey: "comments" },
];

const reasonOptions = {
  A: "Previous anaphylaxis",
  I: "Significant immunocompromise",
  M: "Acute major medical illness",
  S: "Significant immunocompromise of short duration",
  P: "Individual is pregnant",
};

const NaturalImmunityHistory: React.FC<PatientTrialVaccinationHistoryProps> = ({
  naturalImmunityHistory,
  patientIdentifier,
  patientItemDetails,
  refreshIndividualDetails,
}) => {
  const { idToken, isLoading, error: idTokenError } = useIdToken();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedTrial, setSelectedTrial] = useState<Data | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [diseaseCode, setDeseaseCode] = React.useState<string>("");
  const [reason, setReason] = React.useState("");
  const patientId = patientIdentifier;
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "submitting" | "error" | "done"
  >("idle");
  const [labTestDate, setLabTestDate] = useState<Dayjs | null>(dayjs());
  const [diagnosisDate, setDiagnosisDate] = useState<Dayjs | null>(dayjs());
  const [errorMessage, setErrorMessage] = useState("");
  const {
    providerNumber,
    providerStore,
    hpiiNumber,
    hpioNumber,
    minorId,
    deviceName,
    prodaOrgId,
  } = useContext(AuthContext);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleTypeCodeChange = (event: SelectChangeEvent<string>) => {
    setDeseaseCode(event.target.value);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRowClick = (row: Data) => {
    setSelectedTrial(row);
  };

  const typeCodeOptions: { [key: string]: string } = {
    HEP: "Hepatitis B",
    MEA: "Measles",
    MUM: "Mumps",
    RUB: "Rubella",
    VAR: "Varicella",
    QAX: "Q fever",
  };
  const handleAddImmunityClick = () => {
    setShowForm(!showForm); // Toggle the form display
  };
  const rows: Data[] = React.useMemo(() => {
    if (naturalImmunityHistory?.data?.naturalImmunityList) {
      return naturalImmunityHistory.data.naturalImmunityList.map((trial) => ({
        disease: trial.disease,
        notificationDate: formatDate(trial.notificationDate),
        labTestDate: trial.labTestDate ? formatDate(trial.labTestDate) : "",
        diagnosisDate: formatDate(trial.diagnosisDate),
      }));
    } else {
      if (naturalImmunityHistory?.data?.statusCode === "AIR-W-1059") {
        setErrorMessage(
          naturalImmunityHistory?.data?.statusCode +
          ": " +
          naturalImmunityHistory.data.message
        );
      } else {
        setErrorMessage(
          "No active natural immunity is recorded for this individual"
        );
      }
    }
    return []; // Return an empty array if there's no data
  }, [naturalImmunityHistory]);

  logger.log("Natural Immunity History", naturalImmunityHistory);

  const handleCloseDialog = () => {
    setShowConfirmDialog(false);
    if (submitStatus !== "submitting") {
      // Allow closing only if not submitting
      setSubmitStatus("idle");
      setErrorMessage(""); // Reset error message
    }
  };

  const finallyCloseDialog = () => {
    if (submitStatus === "done") {
      setShowForm(false);
      refreshIndividualDetails();
    }
    setShowConfirmDialog(false);
  };
  const handleReasonChange = (event: SelectChangeEvent<string>) => {
    setReason(event.target.value);
  };
  const handleConfirmChange = async () => {
    logger.log("Before API call, submitStatus:", submitStatus);
  };
  const makeApiCall = async (
    diseaseCode: string,
    diagnosticDate: dayjs.Dayjs,
    labTestDate: dayjs.Dayjs,
    patientId: string,
    providerNumber: string,
    hpioNumber: string,
    hpiiNumber: string,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (!idToken) {
      console.error("No authentication token available");
      setErrorMessage("Authentication error");
      return false;
    }
    const formattedLabTestDate = labTestDate.format("DDMMYYYY");
    const formattedDiagnosticDate = diagnosticDate.format("DDMMYYYY");
    const requestData = {
      individualIdentifier: patientId,
      informationProvider: {
        providerNumber: providerNumber,
        hpiiNumber: hpiiNumber,
        hpioNumber: hpioNumber,
      },
      immunity: {
        disease: diseaseCode,
        labTestDate: formattedLabTestDate,
        diagnosisDate: formattedDiagnosticDate,
      },
      dateOfBirth: patientItemDetails?.dateOfBirth,
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
    };
    logger.log(requestData);
    try {
      const apiRecordNatImmunity = process.env.REACT_APP_RECORD_NAT_IMMUNITY;
      if (!apiRecordNatImmunity) {
        throw new Error("REACT_APP_RECORD_NAT_IMMUNITY is not defined");
      }
      const response = await fetch(apiRecordNatImmunity, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (
        data.success &&
        (!data.data.errors || data.data.errors.length === 0)
      ) {
        logger.log("Success");
        return true;
      } else if (
        data.success &&
        data.data.errors &&
        data.data.errors.length > 0
      ) {
        // If 'data.data.errors' exists and has elements, it means there are errors
        logger.log(data.data.errors[0].message);
        setErrorMessage(data.data.errors[0].message);
        return false;
      } else {
        // Handle other cases, such as when 'data.success' is not true
        logger.log("An unexpected error occurred");
        setErrorMessage("An unexpected error occurred");
        return false;
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!idToken) {
        console.error("No authentication token available");
        setErrorMessage("Authentication error");
        return;
      }
      if (!diseaseCode || !labTestDate || !diagnosisDate) {
        setErrorMessage("Please fill in all required fields.");
        return;
      }
      setSubmitStatus("submitting");

      setShowConfirmDialog(true);

      // Call the API with form data and check the response code
      let data = await makeApiCall(
        diseaseCode,
        labTestDate,
        diagnosisDate,
        patientId,
        providerNumber,
        hpioNumber,
        hpiiNumber,
        setErrorMessage // Add this parameter
      ); // Using `value` for both dates as placeholder
      if (data) {
        setSubmitStatus("done");
        setSubmitStatus("done");
        setDeseaseCode("");
        setLabTestDate(null);
        setDiagnosisDate(null);
      } else {
        setSubmitStatus("error");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (idTokenError) {
    return (
      <Typography color="error">
        Error loading authentication token: {idTokenError}
      </Typography>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={showForm ? 8 : 12}>
          <Box sx={{ mb: { xs: 2, md: 3 } }}>
            {/* Responsive margin-bottom */}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddImmunityClick}
              sx={{ position: "relative", fontWeight: "bold" }}
            >
              Add Immunity
            </Button>
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.dataKey}
                        align={column.numeric ? "right" : "left"}
                        style={{ width: `${column.width}px` }} // Convert the number to a pixel value
                        sx={{ backgroundColor: "#1976d2", color: "white" }} // Custom color for header cells
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.disease}
                        >
                          {columns.map((column) => {
                            let value: string | undefined = row[column.dataKey];
                            if (
                              column.dataKey === "disease" &&
                              value !== undefined &&
                              typeCodeOptions[value]
                            ) {
                              value = typeCodeOptions[value]; // Map the code to the full disease name
                            }
                            // Fallback for undefined value to ensure safe indexing
                            value = value || "N/A"; // Use 'N/A' or any appropriate fallback
                            return (
                              <TableCell
                                key={column.dataKey}
                                align={column.numeric ? "right" : "left"}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        {errorMessage}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        {showForm && (
          <Grid item xs={12} md={4}>
            {/* Form fields go here */}
            <Paper>
              <Box p={2}>
                <Typography variant="h6">Add Immunity</Typography>
                {/* Form fields */}
                <FormControl fullWidth margin="normal">
                  <InputLabel id="disease-code-select-label">
                    Disease
                  </InputLabel>
                  <Select
                    labelId="disease-code-select-label"
                    id="disease-code-select"
                    value={diseaseCode}
                    label="Disease"
                    onChange={handleTypeCodeChange}
                  >
                    {Object.entries(typeCodeOptions).map(
                      ([code, description]) => (
                        <MenuItem key={code} value={code}>
                          {description}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>{" "}
                <FormControl fullWidth margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Lab Test Date"
                      value={labTestDate}
                      onChange={(newValue) => setLabTestDate(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>{" "}
                <FormControl fullWidth margin="normal">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Diagnostic Date"
                      value={diagnosisDate}
                      onChange={(newValue) => setDiagnosisDate(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>{" "}
                {/* More fields as needed */}
                <FormControl fullWidth margin="normal">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </FormControl>
              </Box>
              <Dialog open={showConfirmDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                  {submitStatus === "submitting" && "Submitting"}
                  {submitStatus === "done" && "Success"}
                  {submitStatus === "idle" && "Confirm Change"}
                  {submitStatus === "error" && "Error"}
                </DialogTitle>
                <DialogContent sx={{ minWidth: "600px", minHeight: "70px" }}>
                  <DialogContentText>
                    {submitStatus === "submitting" && "Submitting with AIR..."}
                    {submitStatus === "done" &&
                      "Successfully submitted with AIR!"}
                    {submitStatus === "idle" &&
                      `Are you sure you want to change `}
                    {submitStatus === "error" && errorMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {submitStatus === "idle" && (
                    <>
                      <Button
                        onClick={handleCloseDialog}
                        color="error"
                        startIcon={<CancelOutlinedIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleConfirmChange}
                        color="primary"
                        startIcon={<CheckOutlinedIcon />}
                      >
                        Confirm
                      </Button>
                    </>
                  )}
                  {submitStatus === "submitting" && (
                    <CircularProgress size={24} />
                  )}
                  {submitStatus === "done" && (
                    <Button
                      onClick={finallyCloseDialog}
                      style={{ backgroundColor: green[500], color: "#fff" }}
                      startIcon={<CheckOutlinedIcon />}
                    >
                      Close
                    </Button>
                  )}
                  {submitStatus === "error" && (
                    <Button
                      onClick={finallyCloseDialog}
                      color="error"
                      variant="contained"
                      startIcon={<CancelOutlinedIcon />}
                    >
                      Close
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default NaturalImmunityHistory;
