import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useAuth } from '../hooks/useAuth';
import { logger } from '../utils/logger';

interface SubscriptionContextType {
  isSubscribed: boolean | null;
}

const SubscriptionContext = createContext<SubscriptionContextType>({ isSubscribed: null });

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  const { getSession } = useAuth();
  const hasCheckedRef = useRef(false);

  useEffect(() => {
    const checkSubscription = async () => {
      if (hasCheckedRef.current) return;

      try {
        const session = await getSession();
        const subscriptionStatus = session?.tokens?.idToken?.payload?.['custom:isSubscribed'];
        logger.log('SubscriptionProvider - Subscription status:', subscriptionStatus);
        setIsSubscribed(subscriptionStatus === 'true');
      } catch (error) {
        logger.error('SubscriptionProvider - Error checking subscription:', error);
        setIsSubscribed(false);
      } finally {
        hasCheckedRef.current = true;
      }
    };

    checkSubscription();
  }, [getSession]);

  return <SubscriptionContext.Provider value={{ isSubscribed }}>{children}</SubscriptionContext.Provider>;
};

export const useSubscription = () => useContext(SubscriptionContext);
