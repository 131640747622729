import React, { useCallback, useContext, useEffect, useState } from 'react';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import Alerts from './Alerts';
import ImmunisationStatement from './ImmunisationStatement';
import { NaturalImmunityHistoryResponse } from './Immunity';
import { MedicalContraindicationHistoryResponse } from './MedicalContradiction';
import MedicalContradictionHistory from './MedicalContradictionHistory';
import NaturalImmunityHistory from './NaturalmmunityHistory';
import PatientDetailsForm from './PatientDetailsForm';
import PatientVaccination from './PatientVaccination';
import PatientVaccinationHistory from './PatientVaccinationHistory';
import RecordHistoricalVaccinations from './RecordHistoricalVaccinations';
import VaccineTrialHistory from './VaccineTrialHistory';
import { transformToPatientItem } from './common/transformations';
import { IdentifyIndividualAIR, IdentifyIndividualResponse } from './identifyIndividual';
import { PatientItem } from './patient';
import { VaccineTrialHistoryResponse, VaxHistoryResponse } from './vaxHistory';
import { MissingRowIdError } from '@mui/x-data-grid/hooks/features/rows/useGridParamsApi';
import { useIdToken } from '../../hooks/useIdToken';
import { VaccinationFormState } from './PatientVaccination';
import { logger } from '../../utils/logger';
interface RouteParams {
  patientId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const theme = createTheme({
  components: {
    // Style overrides for MuiTab
    MuiTab: {
      styleOverrides: {
        root: {
          // Set the color for the text of the tabs
          color: '#1976d2', // You can choose your color
          '&.Mui-selected': {
            // Set the color for the selected tab
            color: '#0d47a1', // You can choose your color
            fontWeight: 'bold',
          },
        },
      },
    },
    // Style overrides for MuiTabs indicator (the line under the active tab)
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#0d47a1', // You can choose your color
        },
      },
    },
  },
});

interface ClinicPatientsPageProps {
  patientId: string;
  patientData: any; // You might want to create a more specific type for this
}

const ClinicPatientsPage: React.FC<ClinicPatientsPageProps> = ({ patientId, patientData }) => {
  const { idToken, isLoading: isTokenLoading, error: idTokenError } = useIdToken();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabLoading, setTabLoading] = useState<{ [key: number]: boolean }>({}); // Add tab loading state
  const [error, setError] = useState<string | null>(null);
  const [individualDetails, setIndividualDetails] = useState<IdentifyIndividualResponse | null>(null);
  const [patientItemDetails, setPatientItemDetails] = useState<PatientItem | undefined>(undefined);
  const { providerNumber, providerStore, hpiiNumber, hpioNumber, deviceName, minorId, prodaOrgId } =
    useContext(AuthContext);
  const [vaxHistory, setVaxHistory] = useState<VaxHistoryResponse | null>(null);
  const [individualIdentifier, setIndividualIdentifier] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>('');
  const [vaxTrialHistory, setVaxTrialHistory] = useState<VaccineTrialHistoryResponse | null>(null);
  const [naturalImmunityHistory, setNaturalImmunityHistory] = useState<NaturalImmunityHistoryResponse | null>(null);
  const [medicalContradictionHistory, setMedicalContradictionHistory] =
    useState<MedicalContraindicationHistoryResponse | null>(null);
  const [vaccinationFormState, setVaccinationFormState] = useState<VaccinationFormState | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Assuming the index for PatientVaccinationHistory tab is 3
    if (value === 3 && individualIdentifier && dateOfBirth) {
      setTabLoading((prev) => ({ ...prev, 3: true })); // Set tab loading state
      fetchVaccinationHistory(individualIdentifier, dateOfBirth).then(() => {
        setTabLoading((prev) => ({ ...prev, 3: false })); // Unset tab loading state after fetch
      });
    }
  }, [value, individualIdentifier, dateOfBirth]);

  const handleSetTabValue = (newValue: number) => {
    setValue(newValue);
  };

  const fetchPatientDetails = useCallback(async () => {
    if (!patientData) {
      setError('No patient data available.');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      // Use the passed patientData instead of fetching from localStorage
      const graphQLPatientData: PatientItem = patientData;

      logger.log('patientData', graphQLPatientData);

      const formattedDateOfBirth = graphQLPatientData.dateOfBirth.split('-').reverse().join('');

      setDateOfBirth(formattedDateOfBirth);
      const requestData = {
        individual: {
          ...(graphQLPatientData.medicareCardNumber && {
            medicareCard: {
              medicareCardNumber: graphQLPatientData.medicareCardNumber,
              medicareIRN: graphQLPatientData.medicareIRN,
            },
          }),
          personalDetails: {
            ...(graphQLPatientData.firstName && {
              firstName: graphQLPatientData.firstName,
            }),
            lastName: graphQLPatientData.lastName,
            dateOfBirth: formattedDateOfBirth, // Assuming dateOfBirth format needs conversion
            ...(graphQLPatientData.gender && {
              gender: graphQLPatientData.gender,
            }),
            ...(graphQLPatientData.initial && {
              initial: graphQLPatientData.initial,
            }),
            ...(graphQLPatientData.onlyNameIndicator && {
              onlyNameIndicator: graphQLPatientData.onlyNameIndicator,
            }),
          },
          address: {
            postCode: graphQLPatientData.postCode,
          },
        },
        informationProvider: {
          providerNumber: providerNumber, // Use actual provider number
        },
        pharmacyID: providerStore,
        deviceName: deviceName,
        minorId: minorId,
        prodaOrgId: prodaOrgId,
      };

      logger.log(idToken);

      const response = await IdentifyIndividualAIR(requestData, idToken);

      logger.log('response', response);
      if (response.success && response.data.statusCode === 'AIR-E-1005') {
        logger.log('Individual not found.');
        const patientItem = graphQLPatientData;
        setPatientItemDetails(patientItem);
        logger.log('patientItem', patientItem);
        const uniqueKey = `NEW PATIENT`;

        localStorage.setItem(uniqueKey, JSON.stringify(response));
        setLoading(false);
      } else {
        setIndividualDetails(response);

        const patientItem = transformToPatientItem(response, graphQLPatientData);
        setPatientItemDetails(patientItem);

        logger.log('patientItem', patientItem);
        logger.log('response', response);

        const uniqueKey = `${response.data.individualDetails.individualIdentifier}`;
        localStorage.setItem(uniqueKey, JSON.stringify(response));
        // setPatientIdentifier(uniqueKey);
        setIndividualIdentifier(response.data.individualDetails.individualIdentifier);
        fetchVaccinationHistory(
          response.data.individualDetails.individualIdentifier,
          response.data.individualDetails.individual.personalDetails.dateOfBirth
        );
        fetchVaccineTrialHistory(
          response.data.individualDetails.individualIdentifier,
          response.data.individualDetails.individual.personalDetails.dateOfBirth
        );
        fetchNaturalImmunityHistory(
          response.data.individualDetails.individualIdentifier,
          response.data.individualDetails.individual.personalDetails.dateOfBirth
        );
        fetchMedicalContradictionHistory(
          response.data.individualDetails.individualIdentifier,
          response.data.individualDetails.individual.personalDetails.dateOfBirth
        );
        setLoading(false);
      }
      if (!response) {
        setError('Failed to fetch individual details.');
        return;
      }
    } catch (err) {
      logger.log(err);
      setError('Failed to fetch patient details.');
      setLoading(false);
    }
  }, [idToken, providerNumber, providerStore, deviceName, minorId, prodaOrgId, patientData]);

  useEffect(() => {
    if (idToken && patientId) {
      fetchPatientDetails();
    }
  }, [idToken, patientId, fetchPatientDetails]);

  const fetchVaccinationHistory = async (individualIdentifier: string, dateOfBirth: string) => {
    const requestData = {
      individualIdentifier: individualIdentifier,
      informationProvider: {
        providerNumber: providerNumber,
        hpiiNumber: hpiiNumber,
        hpioNumber: hpioNumber,
      },
      dateOfBirth: dateOfBirth,
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
    };

    try {
      logger.log('fetchVaccinationHistory', requestData);
      const apiVaxHistoryURL = process.env.REACT_APP_AIR_INDIVIDUAL_VAXHISTORY;
      if (!apiVaxHistoryURL) {
        throw new Error('REACT_APP_AIR_INDIVIDUAL_VAXHISTORY is not defined');
      }

      const response = await fetch(apiVaxHistoryURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      logger.log('setVaxHistory', data);
      setVaxHistory(data);
    } catch (error) {
      console.error('Failed to fetch vaccination history:', error);
      setError('Failed to fetch vaccination history.');
    }
  };

  const fetchVaccineTrialHistory = async (individualIdentifier: string, dateOfBirth: string) => {
    const requestData = {
      individualIdentifier: individualIdentifier,
      informationProvider: {
        providerNumber: providerNumber,
        hpiiNumber: hpiiNumber,
        hpioNumber: hpioNumber,
      },
      dateOfBirth: dateOfBirth,
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
    };

    try {
      const apiVaxTrialHistoryURL = process.env.REACT_APP_AIR_IND_VAX_TRIAL_HISTORY;
      if (!apiVaxTrialHistoryURL) {
        throw new Error('REACT_APP_AIR_IND_VAX_TRIAL_HISTORY is not defined');
      }
      const response = await fetch(apiVaxTrialHistoryURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      logger.log('Vaccine Trial History', data);
      setVaxTrialHistory(data);
    } catch (error) {
      console.error('Failed to fetch vaccination history:', error);
      setError('Failed to fetch vaccination history.');
    }
  };

  const fetchMedicalContradictionHistory = async (individualIdentifier: string, dateOfBirth: string) => {
    const requestData = {
      individualIdentifier: individualIdentifier,
      informationProvider: {
        providerNumber: providerNumber,
        hpiiNumber: hpiiNumber,
        hpioNumber: hpioNumber,
      },
      dateOfBirth: dateOfBirth,
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
    };

    try {
      const apiMedContraHistory = process.env.REACT_APP_AIR_MED_CONTRA_HISTORY;
      if (!apiMedContraHistory) {
        throw new Error('REACT_APP_AIR_MED_CONTRA_HISTORY is not defined');
      }
      const response = await fetch(apiMedContraHistory, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      logger.log('Medical Contradiction History', data);
      setMedicalContradictionHistory(data);
    } catch (error) {
      console.error('Failed to fetch vaccination history:', error);
      setError('Failed to fetch vaccination history.');
    }
  };

  const countAlerts = (individualDetails: any) => {
    const fields = [
      'indigenousStatus',
      'additionalVaccineIndicator',
      'medContraindicationIndicator',
      'naturalImmunityIndicator',
      'vaccineTrialIndicator',
      'actionRequiredIndicator',
    ];

    let count = fields.reduce((acc, field) => acc + (individualDetails[field] ? 1 : 0), 0);

    // Check if catchupDate is a non-empty string and increment the count if so
    if (individualDetails.catchupDate && individualDetails.catchupDate.trim() !== '') {
      count += 1;
    }

    return count;
  };

  const fetchNaturalImmunityHistory = async (individualIdentifier: string, dateOfBirth: string) => {
    const requestData = {
      individualIdentifier: individualIdentifier,
      informationProvider: {
        providerNumber: providerNumber,
        hpiiNumber: hpiiNumber,
        hpioNumber: hpioNumber,
      },
      dateOfBirth: dateOfBirth,
      minorId: minorId,
      deviceName: deviceName,
      prodaOrgId: prodaOrgId,
    };

    try {
      const apiNatImmunityHistory = process.env.REACT_APP_NATURAL_IMMU_HISTORY;
      if (!apiNatImmunityHistory) {
        throw new Error('REACT_APP_NATURAL_IMMU_HISTORY is not defined');
      }
      const response = await fetch(apiNatImmunityHistory, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      logger.log('NaturalImmunityHistory', data);
      setNaturalImmunityHistory(data);
    } catch (error) {
      console.error('Failed to fetch vaccination history:', error);
      setError('Failed to fetch vaccination history.');
    }
  };

  const refreshIndividualDetails = () => {
    fetchPatientDetails(); // Re-fetch details after update
  };

  const handleVaccinationFormStateChange = (newFormState: VaccinationFormState) => {
    setVaccinationFormState(newFormState);
    logger.log('Vaccination Form State Updated in PatientPage:', newFormState);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isTokenLoading) {
    return <CircularProgress />;
  }

  if (idTokenError) {
    return <div>Error loading authentication token</div>;
  }

  // if (error) {
  //   return <Box>{error}</Box>;
  // }

  // if (!individualDetails) {
  //   return <div>No patient data available.</div>;
  // }
  const additionalTabs = individualDetails
    ? [
        // <Tab
        //   key="record-history"
        //   icon={<WorkHistoryOutlinedIcon />}
        //   iconPosition="start"
        //   label="Record History"
        //   {...a11yProps(2)}
        // />,
        <Tab
          key="immunisation-history"
          icon={<WorkHistoryOutlinedIcon />}
          iconPosition="start"
          label="Immunisation History"
          {...a11yProps(2)}
        />,
        <Tab
          key="immunisation-statement"
          icon={<ArticleOutlinedIcon />}
          iconPosition="start"
          label="Immunisation Statement"
          {...a11yProps(3)}
        />,
        <Tab
          key="alerts"
          icon={
            <Badge
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={countAlerts(individualDetails?.data?.individualDetails)}
              color="error"
            >
              <ReportOutlinedIcon />
            </Badge>
          }
          iconPosition="start"
          label="Alerts"
          {...a11yProps(4)}
        />,
        <Tab
          key="medical-contraindication"
          icon={<MedicalInformationOutlinedIcon />}
          iconPosition="start"
          label="Medical Contraindication"
          {...a11yProps(5)}
        />,
        <Tab
          key="natural-immunity"
          icon={<CoronavirusOutlinedIcon />}
          iconPosition="start"
          label="Natural Immunity"
          {...a11yProps(6)}
        />,
        <Tab
          key="vaccine-trials"
          icon={<BiotechOutlinedIcon />}
          iconPosition="start"
          label="Vaccine Trials"
          {...a11yProps(7)}
        />,
      ]
    : [];
  return (
    <ThemeProvider theme={theme}>
      <Container fluid>
        <Box sx={{ width: '100%' }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box>{error}</Box>
          ) : (
            <>
              {/* <Typography variant="h6" gutterBottom component="div">
                  Draymond Green
                </Typography> */}
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="patient details tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab icon={<AccountCircleOutlinedIcon />} iconPosition="start" label="Patient" {...a11yProps(0)} />
                <Tab icon={<VaccinesOutlinedIcon />} iconPosition="start" label="Record Encounter" {...a11yProps(1)} />
                {additionalTabs}
              </Tabs>
              <TabPanel value={value} index={0}>
                <PatientDetailsForm patientDetails={patientItemDetails} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <PatientVaccination
                  patientIdentifier={individualIdentifier}
                  vaxHistory={vaxHistory}
                  individualDetails={individualDetails}
                  patientItemDetails={patientItemDetails}
                  formState={vaccinationFormState}
                  setFormState={handleVaccinationFormStateChange}
                />
              </TabPanel>
              {/* <TabPanel value={value} index={2}>
                <RecordHistoricalVaccinations
                  patientIdentifier={individualIdentifier}
                  vaxHistory={vaxHistory}
                  individualDetails={individualDetails}
                  patientItemDetails={patientItemDetails}
                />
              </TabPanel> */}
              {individualDetails && (
                <>
                  <TabPanel value={value} index={2}>
                    {tabLoading[3] ? ( // Check if the tab is loading
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <PatientVaccinationHistory
                        patientIdentifier={individualIdentifier}
                        vaxHistory={vaxHistory}
                        individualDetails={individualDetails}
                        refreshIndividualDetails={refreshIndividualDetails}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <ImmunisationStatement
                      patientIdentifier={individualIdentifier}
                      individualDetails={individualDetails}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Alerts
                      individualDetails={individualDetails}
                      setTabValue={handleSetTabValue}
                      patientItemDetails={patientItemDetails}
                      refreshIndividualDetails={refreshIndividualDetails}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <MedicalContradictionHistory
                      medicalContradictionHistory={medicalContradictionHistory}
                      patientIdentifier={individualIdentifier}
                      patientItemDetails={patientItemDetails}
                      refreshIndividualDetails={refreshIndividualDetails}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <NaturalImmunityHistory
                      naturalImmunityHistory={naturalImmunityHistory}
                      patientIdentifier={individualIdentifier}
                      patientItemDetails={patientItemDetails}
                      refreshIndividualDetails={refreshIndividualDetails}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={7}>
                    <VaccineTrialHistory vaxTrialHistory={vaxTrialHistory} />
                  </TabPanel>
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ClinicPatientsPage;
