// CountryCodes.ts

interface CountryCode {
  code: string;
  label: string;
}

const countryCodes: CountryCode[] = [
  { code: "AFG", label: "AFGHANISTAN" },
  { code: "ALB", label: "ALBANIA" },
  { code: "DZA", label: "ALGERIA" },
  { code: "ASM", label: "AMERICAN SAMOA" },
  { code: "AND", label: "ANDORRA" },
  { code: "AGO", label: "ANGOLA" },
  { code: "AIA", label: "ANGUILLA" },
  { code: "ATA", label: "ANTARCTICA" },
  { code: "ATG", label: "ANTIGUA AND BARBUDA" },
  { code: "ARG", label: "ARGENTINA" },
  { code: "ARM", label: "ARMENIA" },
  { code: "ABW", label: "ARUBA" },
  { code: "AUS", label: "AUSTRALIA" },
  { code: "AUT", label: "AUSTRIA" },
  { code: "AZE", label: "AZERBAIJAN" },
  { code: "BHS", label: "BAHAMAS" },
  { code: "BHR", label: "BAHRAIN" },
  { code: "BGD", label: "BANGLADESH, PEOPLES REPUBLIC OF" },
  { code: "BRB", label: "BARBADOS" },
  { code: "BLR", label: "BELARUS" },
  { code: "BYS", label: "BELARUSSIAN SSR" },
  { code: "BEL", label: "BELGIUM" },
  { code: "BLZ", label: "BELIZE" },
  { code: "BEN", label: "BENIN" },
  { code: "BMU", label: "BERMUDA" },
  { code: "BTN", label: "BHUTAN" },
  { code: "BOL", label: "BOLIVIA" },
  { code: "BIH", label: "BOSNIA HERZEGGOVINA" },
  { code: "BWA", label: "BOTSWANA" },
  { code: "BVT", label: "BOUVET ISLAND" },
  { code: "BRA", label: "BRAZIL" },
  { code: "GBD", label: "BRITISH DEPENDENT TERRITORIES" },
  { code: "IOT", label: "BRITISH INDIAN OCEAN TERRITORY" },
  { code: "GBN", label: "BRITISH NATIONAL" },
  { code: "GBO", label: "BRITISH OVERSEAS CITIZEN" },
  { code: "GBP", label: "BRITISH PROTECTED PERSON" },
  { code: "GBS", label: "BRITISH SUBJECT" },
  { code: "VGB", label: "BRITISH VIRGIN ISLANDS" },
  { code: "BRN", label: "BRUNEI" },
  { code: "BGR", label: "BULGARIA" },
  { code: "BFA", label: "BURKINA FASO" },
  { code: "BDI", label: "BURUNDI" },
  { code: "KHM", label: "CAMBODIA" },
  { code: "CMR", label: "CAMEROON, UNITED REPUBLIC OF" },
  { code: "CAN", label: "CANADA" },
  { code: "CTE", label: "CANTON AND ENDERBURY ISLANDS" },
  { code: "CPV", label: "CAPE VERDE" },
  { code: "CYM", label: "CAYMAN ISLANDS" },
  { code: "CAF", label: "CENTRAL AFRICAN REPUBLIC" },
  { code: "TCD", label: "CHAD" },
  { code: "CHL", label: "CHILE" },
  { code: "CHN", label: "CHINA" },
  { code: "CXR", label: "CHRISTMAS ISLAND" },
  { code: "CCK", label: "COCOS (KEELING) ISLANDS" },
  { code: "COL", label: "COLOMBIA" },
  { code: "COM", label: "COMOROS" },
  { code: "COD", label: "CONGO DEMOCRATIC REPUBLIC" },
  { code: "COG", label: "CONGO REPUBLIC OF" },
  { code: "COK", label: "COOK ISLANDS" },
  { code: "CRI", label: "COSTA RICA" },
  { code: "CIV", label: "COTE D'IVOIRE IVORY COAST" },
  { code: "HRV", label: "CROATIA, REPUBLIC OF" },
  { code: "CUB", label: "CUBA" },
  { code: "CYP", label: "CYPRUS" },
  { code: "CZE", label: "CZECH REPUBLIC" },
  { code: "DNK", label: "DENMARK" },
  { code: "DJI", label: "DJIBOUTI" },
  { code: "DMA", label: "DOMINICA, COMMONWEALTH OF" },
  { code: "DOM", label: "DOMINICAN REPUBLIC" },
  { code: "ATN", label: "DRONNING MAUD LAND" },
  { code: "TMP", label: "EAST TIMOR" },
  { code: "ECU", label: "ECUADOR" },
  { code: "EGY", label: "EGYPT" },
  { code: "SLV", label: "EL SALVADOR" },
  { code: "ENG", label: "ENGLAND" },
  { code: "GNQ", label: "EQUITORIAL GUINEA, REPUBLIC OF" },
  { code: "ERI", label: "ERITREA" },
  { code: "EST", label: "ESTONIA" },
  { code: "ETH", label: "ETHIOPIA" },
  { code: "FRO", label: "FAEROE ISLANDS" },
  { code: "FLK", label: "FALKLAND ISLANDS" },
  { code: "FJI", label: "FIJI" },
  { code: "FIN", label: "FINLAND" },
  { code: "FRA", label: "FRANCE" },
  { code: "FXX", label: "FRANCE, METROPOLITAN" },
  { code: "GUF", label: "FRENCH GUIANA" },
  { code: "PYF", label: "FRENCH POLYNESIA" },
  { code: "ATF", label: "FRENCH SOUTH TERRITORY" },
  { code: "GAB", label: "GABON" },
  { code: "GMB", label: "GAMBIA" },
  { code: "SGS", label: "GEORGIA/SANDWICH ISLANDS" },
  { code: "GEO", label: "GEORGIA, REPUBLIC OF" },
  { code: "DDR", label: "GERMAN DEMOCRATIC REPUBLIC" },
  { code: "DEU", label: "GERMANY, FEDERAL REPUBLIC OF" },
  { code: "GHA", label: "GHANA" },
  { code: "GIB", label: "GIBRALTAR" },
  { code: "GRC", label: "GREECE" },
  { code: "GRL", label: "GREENLAND" },
  { code: "GRD", label: "GRENADA" },
  { code: "GLP", label: "GUADELOUPE" },
  { code: "GUM", label: "GUAM" },
  { code: "GTM", label: "GUATEMALA" },
  { code: "GIN", label: "GUINEA" },
  { code: "GNB", label: "GUINEA-BISSAU" },
  { code: "GUY", label: "GUYANA" },
  { code: "HTI", label: "HAITI" },
  { code: "HMD", label: "HEARD AND MCDONALD ISLANDS" },
  { code: "HND", label: "HONDURAS" },
  { code: "HKG", label: "HONG KONG" },
  { code: "HUN", label: "HUNGARY" },
  { code: "ISL", label: "ICELAND" },
  { code: "IND", label: "INDIA" },
  { code: "IDN", label: "INDONESIA" },
  { code: "IRN", label: "IRAN" },
  { code: "IRQ", label: "IRAQ" },
  { code: "IRL", label: "IRELAND" },
  { code: "ISR", label: "ISRAEL" },
  { code: "ITA", label: "ITALY" },
  { code: "JAM", label: "JAMAICA" },
  { code: "JPN", label: "JAPAN" },
  { code: "JOR", label: "JORDAN" },
  { code: "KAZ", label: "KAZAKHSTAN, REPUBLIC OF" },
  { code: "KEN", label: "KENYA" },
  { code: "KIR", label: "KIRIBATI" },
  { code: "PRK", label: "KOREA DEMOCRATIC PEOPLES REPUB" },
  { code: "KOR", label: "KOREA, REPUBLIC OF" },
  { code: "KWT", label: "KUWAIT" },
  { code: "KGZ", label: "KYRGYZSTAN, REPUBLIC OF" },
  { code: "LAO", label: "LAO PEOPLE'S DEMOCRATIC" } as CountryCode, // Fix syntax error and provide initializer
  { code: "LVA", label: "LATVIA, REPUBLIC OF" },
  { code: "LBN", label: "LEBANON" },
  { code: "LSO", label: "LESOTHO" },
  { code: "LBR", label: "LIBERIA" },
  { code: "LBY", label: "LIBYAN ARAB JAMAHIRIYA" },
  { code: "LIE", label: "LIECHTENSTEIN" },
  { code: "LTU", label: "LITHUANIA, REPUBLIC OF" },
  { code: "LUX", label: "LUXEMBOURG" },
  { code: "MAC", label: "MACAU" },
  { code: "MKD", label: "MACEDONIA" },
  { code: "MDG", label: "MADAGASCAR" },
  { code: "MWI", label: "MALAWI" },
  { code: "MYS", label: "MALAYSIA" },
  { code: "MDV", label: "MALDIVES, REPUBLIC OF" },
  { code: "MLI", label: "MALI" },
  { code: "MLT", label: "MALTA" },
  { code: "MHL", label: "MARSHALL ISLANDS REPUBLIC OF" },
  { code: "MTQ", label: "MARTINIQUE" },
  { code: "MRT", label: "MAURITANIA" },
  { code: "MUS", label: "MAURITIUS" },
  { code: "MYT", label: "MAYOTTE, TERRITORIAL COLL OF" },
  { code: "MEX", label: "MEXICO" },
  { code: "FSM", label: "MICRONESIA FEDERATED STATES OF" },
  { code: "MDA", label: "MOLDOVA, REPUBLIC OF" },
  { code: "MCO", label: "MONACO" },
  { code: "MNG", label: "MONGOLIA" },
  { code: "MSR", label: "MONTSERRAT" },
  { code: "MAR", label: "MOROCCO" },
  { code: "MOZ", label: "MOZAMBIQUE" },
  { code: "MMR", label: "MYANMAR" },
  { code: "NAM", label: "NAMIBIA" },
  { code: "NRU", label: "NAURU" },
  { code: "NPL", label: "NEPAL" },
  { code: "NLD", label: "NETHERLANDS" },
  { code: "ANT", label: "NETHERLANDS ANTILLES" },
  { code: "NTZ", label: "NEUTRAL ZONE" },
  { code: "NCL", label: "NEW CALEDONIA" },
  { code: "NZL", label: "NEW ZEALAND" },
  { code: "NIC", label: "NICARAGUA" },
  { code: "NER", label: "NIGER" },
  { code: "NGA", label: "NIGERIA" },
  { code: "NIU", label: "NIUE" },
  { code: "NFK", label: "NORFOLK ISLAND" },
  { code: "MNP", label: "NORTHERN MARIANAS" },
  { code: "NOR", label: "NORWAY" },
  { code: "OMN", label: "OMAN" },
  { code: "PAK", label: "PAKISTAN" },
  { code: "PLW", label: "PALAU, REPUBLIC OF" },
  { code: "PSE", label: "PALESTINIAN AUTHORITY" },
  { code: "PAN", label: "PANAMA" },
  { code: "PNG", label: "PAPUA NUI GUINI" },
  { code: "PRY", label: "PARAGUAY" },
  { code: "PER", label: "PERU" },
  { code: "PHL", label: "PHILIPPINES" },
  { code: "PCN", label: "PITCAIRN ISLAND" },
  { code: "POL", label: "POLAND" },
  { code: "PRT", label: "PORTUGAL" },
  { code: "PRI", label: "PUERTO RICO" },
  { code: "QAT", label: "QATAR" },
  { code: "XXC", label: "REFUGEE OTHERS" },
  { code: "XXB", label: "REFUGEE STATUS" },
  { code: "REU", label: "REUNION" },
  { code: "ROM", label: "ROMANIA" },
  { code: "RUS", label: "RUSSIAN FEDERATION" },
  { code: "RWA", label: "RWANDA" },
  { code: "WSM", label: "SAMOA" },
  { code: "SMR", label: "SAN MARINO" },
  { code: "STP", label: "SAO TOME AND PRINCIPE" },
  { code: "SAU", label: "SAUDI ARABIA" },
  { code: "SCO", label: "SCOTLAND" },
  { code: "SEN", label: "SENEGAL" },
  { code: "SYC", label: "SEYCHELLES" },
  { code: "SLE", label: "SIERRA LEONE" },
  { code: "SGP", label: "SINGAPORE" },
  { code: "SVK", label: "SLOVAK REPUBLIC" },
  { code: "SVN", label: "SLOVENIA" },
  { code: "SLB", label: "SOLOMON ISLANDS" },
  { code: "SOM", label: "SOMALIA" },
  { code: "ZAF", label: "SOUTH AFRICA" },
  { code: "ESP", label: "SPAIN" },
  { code: "LKA", label: "SRI LANKA" },
  { code: "SHN", label: "ST HELENA" },
  { code: "KNA", label: "ST KITTS NEVIS ANGUILLA" },
  { code: "LCA", label: "ST LUCIA" },
  { code: "SPM", label: "ST PIERRE AND MIQUELON" },
  { code: "VCT", label: "ST VINCENT AND THE GRENADINES" },
  { code: "XXA", label: "STATELESS PERSON" },
  { code: "SDN", label: "SUDAN" },
  { code: "SUR", label: "SURINAME" },
  { code: "SJM", label: "SVALBARD AND JAN MAYEN ISLANDS" },
  { code: "SWZ", label: "SWAZILAND" },
  { code: "SWE", label: "SWEDEN" },
  { code: "CHE", label: "SWITZERLAND" },
  { code: "SYR", label: "SYRIAN ARAB REPUBLIC" },
  { code: "TWN", label: "TAIWAN" },
  { code: "TJK", label: "TAJIKISTAN REPUBLIC" },
  { code: "TZA", label: "TANZANIA, UNITED REPUBLIC OF" },
  { code: "THA", label: "THAILAND" },
  { code: "TGO", label: "TOGO" },
  { code: "TKL", label: "TOKELAU" },
  { code: "TON", label: "TONGA" },
  { code: "TTO", label: "TRINIDAD AND TOBAGO" },
  { code: "TUN", label: "TUNISIA" },
  { code: "TUR", label: "TURKEY" },
  { code: "TKM", label: "TURKMENISTAN" },
  { code: "TCA", label: "TURKS AND CAICOS ISLANDS" },
  { code: "TUV", label: "TUVALU" },
  { code: "UGA", label: "UGANDA" },
  { code: "UKR", label: "UKRAINIAN SSR" },
  { code: "ARE", label: "UNITED ARAB EMIRATES" },
  { code: "GBR", label: "UNITED KINGDOM" },
  { code: "UNA", label: "UNITED NATIONS AGENCY" },
  { code: "UNO", label: "UNITED NATIONS ORGANISATION" },
  { code: "USA", label: "UNITED STATES OF AMERICA" },
  { code: "VIR", label: "UNITED STATES VIRGIN ISLANDS" },
  { code: "XXX", label: "UNSPECIFIED NATIONALITY" },
  { code: "URY", label: "URUGUAY" },
  { code: "UMI", label: "US MINOR ISLANDS" },
  { code: "UZB", label: "UZBEKISTAN, REPUBLIC OF" },
  { code: "VUT", label: "VANUATU ACTIVE 09 JAN 2005" },
  { code: "VAT", label: "VATICAN CITY STATE (HOLY SEE)" },
  { code: "VEN", label: "VENEZUELA" },
  { code: "VNM", label: "VIETNAM" },
  { code: "WAL", label: "WALES" },
  { code: "WLF", label: "WALLIS AND FUTUNA ISLANDS" },
  { code: "ESH", label: "WESTERN SAHARA" },
  { code: "YEM", label: "YEMEN" },
  { code: "YMD", label: "YEMEN, DEMOCRATIC" },
  { code: "YUG", label: "YUGOSLAVIA" },
  { code: "ZAR", label: "ZAIRE" },
  { code: "ZMB", label: "ZAMBIA" },
  { code: "ZWE", label: "ZIMBABWE" },
  { code: "ZZZ", label: "UNKNOWN" },
];

export default countryCodes;
