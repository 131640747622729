import React, { createContext, useReducer, useContext, ReactNode, Dispatch } from 'react';

// Define the type for the customer
interface Customer {
  lastName: string;
  dateOfBirth: string;
  firstName?: string;
  medicareCardNumber?: string;
  medicareIRN?: string;
  postCode?: string;
  ihiNumber?: string;
  state?: string;
  suburb?: string;
  address?: string;
  phone?: string;
  email?: string;
  patientID?: string;
  gender?: string;
  initial?: string;
  middleName?: string;
  pharmacistID?: string;
  providerNumber?: string;
  pharmacyID?: string;
  isAboriginal?: boolean;
  onlyNameIndicator?: boolean;
}

// Define the type for the state
interface State {
  customer: Customer | null;
}

// Define the initial state
const initialState: State = {
  customer: null,
};

// Define the types for the actions
type Action = { type: 'SET_CUSTOMER'; payload: Customer } | { type: 'CLEAR_CUSTOMER' };

// Reducer function
const customerReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CUSTOMER':
      return { ...state, customer: action.payload };
    case 'CLEAR_CUSTOMER':
      return { ...state, customer: null };
    default:
      return state;
  }
};

// Create context with the initial type
const CustomerContext = createContext<
  | {
      state: State;
      dispatch: Dispatch<Action>;
    }
  | undefined
>(undefined);

// Custom hook to use the customer context
export const useCustomer = () => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error('useCustomer must be used within a CustomerProvider');
  }
  return context;
};

// Provider component
interface CustomerProviderProps {
  children: ReactNode;
}

export const CustomerProvider = ({ children }: CustomerProviderProps) => {
  const [state, dispatch] = useReducer(customerReducer, initialState);

  return <CustomerContext.Provider value={{ state, dispatch }}>{children}</CustomerContext.Provider>;
};

// Action creators for convenience
export const setCustomer = (dispatch: Dispatch<Action>, customer: Customer) => {
  dispatch({ type: 'SET_CUSTOMER', payload: customer });
};

export const clearCustomer = (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'CLEAR_CUSTOMER' });
};
