import { Avatar, Box, Button, Card, Chip, Paper, Typography, alpha, styled } from "@mui/material";
import { IProvider } from "../../interfaces";
import { green, purple, yellow, red, orange, blue } from "@mui/material/colors";
import AuthContext from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { APICore } from "../../helpers/api/apiCore";
import { IDragatronUser } from "../../interfaces/IDragatronUser";
import { ELoginStep, setLoginSteps } from "../../redux/actions";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import ReportOutlined from "@mui/icons-material/ReportOutlined";

type Props = {
  providers: IProvider[];
};

const api = new APICore();

const colors = [green, purple, yellow, red, orange, blue];

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.35rem",
  fontWeight: "bold",
  textAlign: "center",
}));

const CardDescription = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.4),
  textAlign: "center",
  fontSize: "1.1rem",
}));

const CardChooseAccounts = ({ providers }: Props) => {
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const params = new URLSearchParams(search);
  const { setAuthDetails } = useContext(AuthContext);
  let previousColorIndex = -1;
  let usedColors = new Set();
  const dispatch = useDispatch();

  const getRandomColor = () => {
    // If all colors have been used, reset the used colors set
    if (usedColors.size === colors.length) {
      usedColors.clear();
    }

    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * colors.length);
    } while (usedColors.has(randomIndex) && usedColors.size < colors.length);

    // Mark this color as used
    usedColors.add(randomIndex);
    previousColorIndex = randomIndex;

    return colors[randomIndex][500];
  };

  const onSelect = (provider: IProvider) => {
    const session = api.getLoggedInUser() as IDragatronUser;
    const populatedPayload: IDragatronUser = {
      ...session,
      tokens: {
        ...session.tokens,
        idToken: {
          ...session.tokens.idToken,
          payload: {
            ...session.tokens.idToken.payload, // use default payload
            "custom:providerFirstName": provider.providerFirstName!,
            "custom:providerLastName": provider.providerLastName!,
            "custom:providerAHPRA": provider.providerAHPRA,
            "custom:pharmacyName": provider.pharmacyName,
            "custom:pharmacyID": provider.pharmacyID,
            "custom:pharmacistID": provider.pharmacistID,
            "custom:minorId": provider.minorId,
            "custom:providerNumber": provider.providerNumber,
            "custom:isPPAEligible": provider.ppaClaims,
            "custom:isAdministrator": provider.isAdministrator,
            "custom:hpioNumber": provider.hpioNumber,
            "custom:hpiiNumber": provider.hpiiNumber,
            isActive: provider.isActive,
            "custom:prodaOrgId": provider.prodaOrgId,
          },
        },
      },
    };

    // update the context auth
    // update the context auth
    setAuthDetails({
      providerNumber: provider.providerNumber,
      providerFirstName: provider.providerFirstName!,
      providerLastName: provider.providerLastName!,
      providerStore: provider.pharmacyAddress,
      pharmacistID: provider.pharmacistID,
      pharmacyID: provider.pharmacyID,
      pharmacyName: provider.pharmacyName,
      hpioNumber: provider.hpioNumber,
      hpiiNumber: provider.hpiiNumber,
      isAdministrator: provider.isAdministrator,
      deviceName: provider.deviceName,
      minorId: provider.minorId,
      providerAHPRA: provider.providerAHPRA,
      providerEmail: provider.pharmacyLoginId,
      isPPAEligible: provider.ppaClaims,
      prodaOrgId: provider.prodaOrgId,
      PPA: undefined, // or provider.PPA if it exists
    });

    api.setLoggedInUser(populatedPayload, provider.pharmacistID);
    dispatch(
      setLoginSteps({
        step: ELoginStep.COMPLETE,
      })
    );

    const nextUrl = state?.next || params.get("next");

    navigate({
      pathname: nextUrl || "/dashboard-air",
    });
  };

  return (
    <Paper
      sx={{
        width: "100%",
        p: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardTitle gutterBottom>Which account do you want to log in to?</CardTitle>
        <CardDescription>Please choose one of the account below to log in</CardDescription>
      </Box>

      <Box
        component={"ul"}
        sx={{
          padding: 0,
          height: "100%",
          maxHeight: "70vh",
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {providers.map((provider) => {
          const color = getRandomColor();
          const access = provider.isAdministrator ? "Admin Access" : "User Access";
          const labelStatus = provider.isActive ? "Active" : "Inactive";
          return (
            <Box
              component={"li"}
              key={provider.pharmacistID}
              sx={{
                width: "100%",
              }}
              onClick={() => onSelect(provider)}
            >
              <Card
                sx={{
                  p: "1rem",
                  borderLeft: `4px solid ${color}`,
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  cursor: provider.isActive ? "pointer" : "initial",
                  "&:hover": {
                    background: provider.isActive ? alpha(color, 0.2) : "inherit",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    flex: 1,
                  }}
                >
                  <Avatar />
                  <Box>
                    <CardTitle
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem !important",
                        textAlign: "start",
                      }}
                    >
                      {provider.providerName}
                    </CardTitle>
                    <CardDescription
                      sx={{
                        fontSize: "1rem !important",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {access} |{" "}
                      <Box
                        component={"span"}
                        sx={{
                          color,
                          fontWeight: "bold",
                        }}
                      >
                        {provider.pharmacyName}
                      </Box>
                    </CardDescription>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Chip
                    label={labelStatus}
                    sx={(theme) => ({
                      background: provider.isActive
                        ? alpha(theme.palette.success.main, 0.1)
                        : alpha(theme.palette.error.main, 0.2),
                      fontWeight: "bold",
                      color: provider.isActive ? theme.palette.success.light : theme.palette.error.main,
                      maxWidth: "7rem",
                      width: "100%",
                    })}
                  />
                </Box>
              </Card>
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};

export default CardChooseAccounts;
