import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { IdentifyIndividualResponse } from "../identifyIndividual";
import { PatientItem } from "../patient";

dayjs.extend(customParseFormat);
export const transformToPatientItem = (
  response: IdentifyIndividualResponse | null,
  graphQLPatientData: PatientItem // Add the type of patientData here
): PatientItem | undefined => {
  if (!response) {
    // Return null or an empty object, depending on your requirements
    return undefined;
  }

  const details = response.data.individualDetails;
  const individual = details.individual;

  const placeholder = ""; // Placeholder for missing values

  const address = individual.address || {};
  const addressLineOne = address.addressLineOne || graphQLPatientData.addressLineOne || placeholder;
  const addressLineTwo = address.addressLineTwo || graphQLPatientData.addressLineTwo || placeholder;
  const locality = address.locality || graphQLPatientData.locality || placeholder;
  const postCode = address.postCode || graphQLPatientData.postCode || placeholder;
  const state = graphQLPatientData.state || placeholder; // Assuming state comes from graphQLPatientData
  const isAboriginalValue = details.indigenousStatus || graphQLPatientData.isAboriginal ? "Y" : "";

  const patientItem: PatientItem = {
    addressLineOne: addressLineOne,
    addressLineTwo: addressLineTwo,
    createdDate: graphQLPatientData.createdDate, // Placeholder for missing createdDate
    dateOfBirth: individual.personalDetails.dateOfBirth,
    emailAddress: graphQLPatientData.emailAddress || "",
    mobileNumber: graphQLPatientData.mobileNumber || "",
    phoneNumber: graphQLPatientData.phoneNumber || "",
    firstName: individual.personalDetails.firstName || "",
    initial: individual.personalDetails.initial || graphQLPatientData.initial || "",
    gender: graphQLPatientData.gender, // Placeholder for missing gender
    id: graphQLPatientData.id, // Placeholder for missing id
    ihiNumber: graphQLPatientData.ihiNumber || "", // Placeholder for missing ihiNumber
    lastName: graphQLPatientData.lastName,
    locality: locality,
    atsiIndicator: isAboriginalValue || "", // Placeholder for missing atsiIndicator
    medicareCardNumber: graphQLPatientData.medicareCardNumber,
    medicareExpiryDate: graphQLPatientData.medicareExpiryDate || "", // Placeholder for missing medicareExpiryDate
    medicareIRN: graphQLPatientData.medicareIRN,
    patientID: graphQLPatientData.patientID,
    pharmacistID: graphQLPatientData.pharmacistID, // Placeholder for missing pharmacistID
    pharmacyID: graphQLPatientData.pharmacistID, // Placeholder for missing pharmacyID
    postCode: postCode,
    state: state, // Placeholder for missing state
    isExpanded: false,
  };

  return patientItem;
};
export const formatDateOfBirth = (dateOfBirth: string): string => {
  // Check if the input date is already in the desired format (YYYY-MM-DD)
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateOfBirth)) {
    return dayjs(dateOfBirth).format("YYYY-MM-DD"); // If yes, just format it.
  }
  // If the date is in DDMMYYYY format without separators, we assume it's a continuous string of numbers
  else if (/^\d{8}$/.test(dateOfBirth)) {
    return dayjs(dateOfBirth, "DDMMYYYY").format("YYYY-MM-DD"); // Parse and format
  }
  // For safety, you can add more conditions here if there are more formats
  else {
    return ""; // Or handle invalid format appropriately
  }
};

export const calculateAge = (dateOfBirth: string): number => {
  if (!dateOfBirth || dateOfBirth.length !== 8) return 0;

  const formattedDateOfBirth = dayjs(dateOfBirth, "DDMMYYYY");
  return dayjs().diff(formattedDateOfBirth, "year");
};
