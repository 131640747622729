import axios from 'axios';
import { IronScreeningReading, IronScreeningResponse, IronScreeningDataResponse } from '../types/ironScreening';

const API_URL = process.env.REACT_APP_IRON_SCREENING_CREATE_URL;
const GET_BY_PATIENT_ID_URL = process.env.REACT_APP_IRON_SCREENING_GET_BY_PATIENT_ID_URL;

export const createIronScreeningReading = async (
  formData: Omit<IronScreeningReading, 'id' | 'createdAt' | 'updatedAt'>
): Promise<IronScreeningResponse> => {
  const response = await axios.post<IronScreeningResponse>(API_URL!, formData);
  return response.data;
};

export const getIronScreeningReadingsByPatientId = async (requestBody: {
  body: {
    patientID: string;
    pharmacyID: string;
    sortDirection: string;
    limit: number;
    nextToken: string | null;
  };
}): Promise<IronScreeningDataResponse> => {
  const response = await axios.post<IronScreeningDataResponse>(GET_BY_PATIENT_ID_URL!, requestBody);
  return response.data;
};