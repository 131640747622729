import { Moment } from 'moment';
import { ITransactionProduct } from './ITransactionProduct';
import { IProvider } from './IProvider';
import { ICustomer } from './ICustomer';
import { VapeCustomer } from './VapeCustomer';

export enum TransactionProductStatus {
  ALLOW_SALE = 'ALLOW_SALE',
  DENY_SALE = 'DENY_SALE',
  SAFETY_SALE = 'SAFETY_SALE',
  RETURNED = 'RETURNED',
}

export enum TransactionStatus {
  CREATED = 'CREATED',
  PARTIALLY_RETURNED = 'PARTIALLY_RETURNED',
  RETURNED = 'RETURNED',
}

export enum RecordingCategory {
  VAPE_RECORD = 'VAPE_RECORD',
  PSEUDOEPHEDRINE_RECORD = 'PSEUDOEPHEDRINE_RECORD',
  NALOXONE_RECORD = 'NALOXONE_RECORD',
}

export interface ITransaction {
  transactionID: string;
  pharmacyID: string;
  providerID: string;
  scriptID: string;
  customerID: string;
  totalQuantity: number;
  totalAmount: number;
  createdAt: string;
  updatedAt: string;
  transactionStatus: string;
  paymentStatus: string;
  paymentChannel: string;
  paymentAmount: string;
  products: ITransactionProduct[];
  sessionID: string;
  paymentDetail: string;
  metadata: string;
  providerAHPRA: string;
  provider: Pick<IProvider, 'providerName'> & {
    providerID: string;
    providerAddress: string;
    providerEmail: string;
  };
  customer: Pick<ICustomer, 'customerID' | 'identificationNumber'> & {
    customerName: string;
    customerEmail: string;
    customerAddress: string;
    customerPhone: string;
    identificationType: string;
  };
  RecordingCategory: RecordingCategory;
}

export type ProductsReturnModel = ITransaction['products'][number] & {
  transactionID: string;
};

export interface ITransactionTableModelWithTreeData {
  id: string;
  transactionID?: string;
  createdAt?: string;
  path: string[];
  customerID?: string;
  paymentChannel?: string;
  paymentStatus?: string;
  pharmacyID?: string;
  totalAmount?: number;
  totalQuantity?: number;
  isParent: boolean;
  saleStatus?: string;

  // Fields specific to products
  productId?: string;
  parentId?: string;
  gtin?: string;
  description?: string;
  directionOfUse?: string;
  drugStrength?: string;
  genericName?: string;
  quantity?: number;
  unitPriceExcludingGst?: string;
  manufacturer?: string;
  uom?: string;
}

export interface ITransactionNew {
  saleID: string;
  pharmacyID: string;
  vapePatientID: string;
  providerAHPRA: string;
  SASSubmissionID: string;
  productID: string;
  productType: string;
  productName: string;
  supplierName: string;
  nicConc: string;
  flavour: string;
  volume: number;
  notifNumber: string;
  informedNotARTG: boolean;
  informedConsent: boolean;
  discussedNRT: boolean;
  discussedGP: boolean;
  discussedQUIT: boolean;
  viewedID: boolean;
  assessedSupply: boolean;
  explainedRestrictions: boolean;
  createdAt: string;
  updatedAt: string;
  customer: VapeCustomer;
}
