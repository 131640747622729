// constants
import { IProductSaleFilter } from "../../contexts/ProductSaleContext";
import { ICustomer } from "../../interfaces/ICustomer";
import { SaleActionTypes } from "./constants";

export interface OpenCartPayload {
  openCart: boolean;
}
export interface SaleActionType {
  type:
    | SaleActionTypes.SET_CUSTOMER_SELECTED
    | SaleActionTypes.SET_OPEN_CART
    | SaleActionTypes.SET_FILTER_TYPE;
  payload?: ICustomer | OpenCartPayload | IProductSaleFilter | null;
}

export const setCustomerSelected = (
  payload: ICustomer | null
): SaleActionType => ({
  type: SaleActionTypes.SET_CUSTOMER_SELECTED,
  payload: payload,
});

export const setFilterType = (
  payload: IProductSaleFilter | null
): SaleActionType => ({
  type: SaleActionTypes.SET_FILTER_TYPE,
  payload: payload,
});

export const setOpenCart = (payload: OpenCartPayload): SaleActionType => ({
  type: SaleActionTypes.SET_OPEN_CART,
  payload: payload,
});
