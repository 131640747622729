import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';
import {
  ConfirmSignUpInput,
  ConfirmSignUpOutput,
  SignInInput,
  SignUpOutput,
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  updateUserAttribute,
  signIn,
  signOut,
  signUp,
  ResetPasswordOutput,
  resetPassword,
  confirmResetPassword,
  ConfirmResetPasswordInput,
  setUpTOTP,
  verifyTOTPSetup,
  fetchMFAPreference,
  updateMFAPreference,
  UpdateMFAPreferenceInput,
  confirmSignIn,
} from 'aws-amplify/auth';
import { logger } from '../../../utils/logger';

// Remove this line as it's already imported above
// import { fetchAuthSession } from 'aws-amplify/auth';

const AIR_VACCINATIONS_POOL_ID = process.env.REACT_APP_POOL_ID_AIR_VACCINATIONS || '';
const AIR_VACCINATIONS_CLIENT_ID = process.env.REACT_APP_CLIENT_ID_AIR_VACCINATIONS || '';
const AIR_VACCINATION_CLIENT_APP_NAME = 'Dragatron Professional Services';

// logger.log(AIR_VACCINATIONS_POOL_ID, AIR_VACCINATIONS_CLIENT_ID, "POOL CONFIGS");

type SignUpParameters = {
  username: string;
  password: string;
  email: string;
  phone_number?: string;
};

type userAttributes = {
  email: string;
  name: string;
  value: string;
};

// Assuming updateUserAttribute and getCurrentUser are defined and imported correctly.
export async function updateUserAttributes(attributes: userAttributes): Promise<void> {
  try {
    logger.log('Updating user attributes...', attributes);
    await updateUserAttribute({
      userAttribute: {
        attributeKey: attributes.name,
        value: attributes.value,
      },
    });
    logger.log('User attributes updated successfully.');
    return Promise.resolve();
  } catch (error) {
    logger.error('Error updating user attributes:', error);
  }
}

export async function verifyCode({ username, confirmationCode }: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> {
  try {
    // Confirm user sign-up with the provided confirmation code
    const confirmSignUpOutput = await confirmSignUp({
      username,
      confirmationCode,
    });

    // Attempt to get the current user, sign in automatically if not already signed in
    let currentUser;
    try {
      currentUser = await getCurrentUser();
      logger.log('Current user:', currentUser);
    } catch (error) {
      // Error caught here indicates no current user session exists, so auto sign-in
      // await autoSignIn();
      // currentUser = await getCurrentUser(); // Re-attempt to get current user after auto sign-in
    }

    return confirmSignUpOutput;
  } catch (error) {
    logger.log('Error confirming sign up or handling user session:', error);
    throw error;
  }
}

export async function signUpUserWithEmail({ username, email, password }: SignUpParameters): Promise<SignUpOutput> {
  return new Promise<SignUpOutput>(async (resolve, reject) => {
    try {
      const signUpResult = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            name: username,
          },
          // optional
          autoSignIn: false, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        },
      });
      logger.log(signUpResult);
      resolve(signUpResult); // Resolve the promise when the operation is successful
    } catch (error) {
      logger.log('error signing up:', error);
      reject(error); // Reject the promise on error
    }
  });
}

interface CognitoTokenPayload {
  username: string;
  email: string;
  email_verified: boolean;
  // Include other fields as necessary
}

interface CognitoJwtToken {
  jwtToken: string;
  payload: CognitoTokenPayload;
}

interface CognitoSession {
  idToken: CognitoJwtToken;
  accessToken: CognitoJwtToken;
  refreshToken?: { token: string }; // Optional based on usage
  clockDrift: number;
}

const airVacinationPoolData = {
  UserPoolId: AIR_VACCINATIONS_POOL_ID,
  ClientId: AIR_VACCINATIONS_CLIENT_ID,
};

const userPool = new CognitoUserPool(airVacinationPoolData);
const airVaccinationPool = new CognitoUserPool(airVacinationPoolData);

function getCognitoUser(username: string, pool?: CognitoUserPool): CognitoUser {
  const poolToUse = pool ? pool : userPool;
  return new CognitoUser({
    Username: username,
    Pool: poolToUse,
  });
}

export function getCURRENTUSER(): CognitoUser | null {
  return userPool.getCurrentUser();
}

export function getSession(): Promise<CognitoUserSession> {
  const currentUser = getCURRENTUSER();
  if (!currentUser) {
    return Promise.reject(new Error('No current user'));
  }

  return new Promise((resolve, reject) => {
    currentUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        reject(err);
      } else if (session) {
        resolve(session);
      } else {
        reject(new Error('Session is null'));
      }
    });
  });
}

export function signInUserWithEmail({ username, password }: SignInInput): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      logger.log('signing in user with email', username);
      let currentUserSession = await getCurrentUser().catch(() => null);

      if (currentUserSession) {
        const userAttributes = await fetchUserAttributes();
        const currentUserEmail = userAttributes.email;
        await signOut();

        // if (currentUserEmail === username) {
        //   logger.log(`User with email ${username} is already signed in. Signing out.`);
        //   await signOut();
        // }
      }

      const { isSignedIn, nextStep } = await signIn({ username, password });
      if (isSignedIn) {
        logger.log('isSignedIn', isSignedIn);
        logger.log('nextStep', nextStep);
        // if (!isSignedIn) {
        //   throw new Error("Failed to sign in");
        // }
        const newSession = await fetchAuthSession();

        logger.log('newSession', newSession);

        if (!newSession) {
          // throw new Error("Failed to establish a new session.")
        }

        resolve(newSession);
      } else {
        // handle 2 mfa
        resolve(nextStep);
      }
    } catch (error) {
      // logger.error("Error signing in:", error);
      reject(error);
    }
  });
}

// export function signInUserWithEmail(username: string, password: string): Promise<CognitoUserSession> {
//   const authenticationDetails = new AuthenticationDetails({
//     Username: username,
//     Password: password,
//   });

//   const cognitoUser = getCognitoUser(username);
//   return new Promise((resolve, reject) => {
//     cognitoUser.authenticateUser(authenticationDetails, {
//       onSuccess: (session: CognitoUserSession) => {
//         logger.log("signInUserWithEmail via Cognito", session);
//         resolve(session);
//       },
//       onFailure: (err: any) => {
//         reject(err.message);
//       },
//     });
//   });
// }

// export function signOut(): void {
//   const currentUser = getCURRENTUSER();
//   currentUser?.signOut();
//   logger.log("signOut via Cognito", true);
// }

export async function cognitoSignOut(): Promise<void> {
  try {
    await signOut();
    let currentUserSession;
    try {
      currentUserSession = await getCurrentUser();
    } catch (error) {
      // If no current user session exists, we proceed to sign in the user.
      logger.log('No current user session exists after sign-out:');
    }
  } catch (error) {
    logger.log('error signing out: ', error);
  }
}

export async function cognitoResetPassword(username: string): Promise<Awaited<ResetPasswordOutput> | Error> {
  try {
    return await resetPassword({
      username,
    });
  } catch (error) {
    logger.log('error requesting reset password', error);

    return error as Error;
  }
}

export async function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
  const { nextStep } = output;
  switch (nextStep.resetPasswordStep) {
    case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
      const codeDeliveryDetails = nextStep.codeDeliveryDetails;
      logger.log(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
      // Collect the confirmation code from the user and pass to confirmResetPassword.
      break;
    case 'DONE':
      logger.log('Successfully reset password.');
      break;
  }
}

export function getAttributes(): Promise<ICognitoUserAttributeData[]> {
  const currentUser = getCURRENTUSER();
  if (!currentUser) {
    return Promise.reject('No current user');
  }

  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, result) => (err ? reject(err) : resolve(result!)));
  });
}

export function setAttribute(attribute: ICognitoUserAttributeData): Promise<'SUCCESS'> {
  const currentUser = getCURRENTUSER();
  if (!currentUser) {
    return Promise.reject('No current user');
  }

  return new Promise((resolve, reject) => {
    const attributeList = [new CognitoUserAttribute(attribute)];
    currentUser.updateAttributes(attributeList, (err) => (err ? reject(err) : resolve('SUCCESS')));
  });
}

export function sendCode(username: string): Promise<{ message: string; response: any }> {
  const cognitoUser = getCognitoUser(username, airVaccinationPool);
  logger.log('Send code', cognitoUser);

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => resolve({ message: 'Code sent successfully', response: data }),
      onFailure: (error) => reject(error),
    });
  });
}

export function forgotPasswordCognito(
  username: string,
  code: string,
  newPassword: string
): Promise<'password updated'> {
  const cognitoUser = getCognitoUser(username);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: () => resolve('password updated'),
      onFailure: reject,
    });
  });
}

export function changePassword(oldPassword: string, newPassword: string): Promise<'SUCCESS'> {
  const currentUser = getCURRENTUSER();
  if (!currentUser) {
    return Promise.reject('No current user');
  }

  return new Promise((resolve, reject) => {
    currentUser.changePassword(oldPassword, newPassword, (err) => (err ? reject(err) : resolve('SUCCESS')));
  });
}

export async function handleConfirmResetPassword({
  username,
  confirmationCode,
  newPassword,
}: ConfirmResetPasswordInput) {
  return new Promise(async (resolve, reject) => {
    confirmResetPassword({ username, confirmationCode, newPassword })
      .then(() => resolve('SUCCESS'))
      .catch((error) => reject(error));
  });
}

export async function refreshSession() {
  try {
    const { tokens } = await fetchAuthSession();
    if (!tokens || !tokens.idToken) {
      throw new Error('No tokens returned from fetchAuthSession');
    }
    const expirationTime = tokens.idToken.payload.exp;
    if (typeof expirationTime !== 'number') {
      throw new Error('Invalid expiration time in token');
    }
    return {
      tokens,
      expirationTime: expirationTime * 1000, // Convert to milliseconds
      idToken: tokens.idToken.toString(), // Add this line to include idToken directly
    };
  } catch (error) {
    logger.error('Error refreshing session:', error);
    throw error;
  }
}

export async function setupMFA() {
  try {
    const user = await fetchAuthSession();
    const totpCode = await setUpTOTP();

    logger.log('TOTP setup code:', totpCode);
    logger.log('TOTP setup code user:', user);

    // Convert TOTP code to a QR code for scanning (e.g., using QRCode library)
    return totpCode
      .getSetupUri(AIR_VACCINATION_CLIENT_APP_NAME, user.tokens?.idToken?.payload.email?.toString())
      .toString();
  } catch (error) {
    logger.error('Error setting up TOTP:', error);
  }
}

export async function handleTOTPVerification(totpCode: string) {
  try {
    await verifyTOTPSetup({ code: totpCode });
  } catch (error) {
    logger.log(error);
  }
}

export async function handleFetchMFAPreference() {
  try {
    const output = await fetchMFAPreference();
    return output;
  } catch (error) {
    logger.log(error);
  }
}

export async function handleUpdateMFAPreference(input: UpdateMFAPreferenceInput) {
  try {
    await updateMFAPreference(input);
  } catch (error) {
    logger.error(error);
  }
}

export async function handleSignInConfirmation(totpCode: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const { isSignedIn } = await confirmSignIn({ challengeResponse: totpCode });
      if (isSignedIn) {
        logger.log('isSignedIn', isSignedIn);
 
        const newSession = await fetchAuthSession();

        logger.log('newSession', newSession);

        resolve(newSession);
      }
    } catch (error) {
      logger.error(error);
      reject(error);
    }
  });
}
