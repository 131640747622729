import { Box, CircularProgress, Typography } from "@mui/material";

const LoadingContent = ({ text }: { text?: string }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: "60vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <CircularProgress />
      <Typography>{text || "Please wait..."}</Typography>
    </Box>
  );
};

export default LoadingContent;
