import React from 'react';
import { Grid, Paper, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { FormData } from '../UTIProtocolForm';
import { Activity } from 'lucide-react';
import { grey } from '@mui/material/colors';

interface StepProps {
    control: Control<FormData>;
}

const Step6Diagnosis: React.FC<StepProps> = ({ control }) => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 2, backgroundColor: grey[50] }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                    <Activity size={20} style={{ marginRight: '10px' }} />
                    UTI Symptoms
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Please confirm the patient's symptoms:
                </Typography>
                <Grid container spacing={2}>
                    {(['dysuria', 'frequency', 'urgency', 'suprapubicPainOrDiscomfort'] as const).map((symptom) => (
                        <Grid item xs={12} sm={6} key={symptom}>
                            <Controller
                                name={`symptoms.${symptom}`}
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label={symptom.charAt(0).toUpperCase() + symptom.slice(1).replace(/([A-Z])/g, ' $1')}
                                    />
                                )}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    </Grid>
);

export default Step6Diagnosis;