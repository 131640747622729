import { useState } from 'react';
import { ICustomer } from '../interfaces';
import { useIdToken } from './useIdToken';

interface Source extends ICustomer {
  suggest: Suggest;
  suggest_ngram: string[];
}

interface MedicalHistory {}

interface Suggest {
  input: string[];
  weight: number;
}

interface ApiResponse {
  items: ICustomer[];
  nextToken: string | null;
}

export interface ISearchResponse {
  text: string;
  _id: string;
  _score: number;
  _source: Source;
}

const apiURL = process.env.REACT_APP_VAPE_SEARCH_CUSTOMER;
if (!apiURL) {
  throw new Error('REACT_APP_VAPE_SEARCH_CUSTOMER is not defined');
}

export const useSearchCustomer = () => {
  const endpoint = apiURL;
  const { idToken, isLoading: isTokenLoading, error: idTokenError } = useIdToken();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [results, setResults] = useState<ISearchResponse[]>([]);

  const searchCustomer = async (keyword: string) => {
    if (isTokenLoading) {
      setLoading(true);
      return;
    }

    if (idTokenError) {
      setError(new Error('Failed to load authentication token'));
      return;
    }

    if (!idToken) {
      setError(new Error('No authentication token available'));
      return;
    }

    try {
      setLoading(true);
      const request = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ query: keyword }),
      });

      const response: ApiResponse = await request.json();

      if (request.status !== 200) {
        const err = new Error(`${response}`);
        err.name = 'UnexpectedError';
        throw err;
      }

      setCustomers(response.items);
      // Note: We're not setting results anymore as it's not applicable to the new response format
      return response.items;
    } catch (error) {
      const err = error as Error;
      console.error('Error using search customer', err);
      setError(err);
      setCustomers([]);
    } finally {
      setLoading(false);
    }
  };

  return {
    searchCustomer,
    error,
    loading,
    customers,
    // Note: We're not returning results anymore as it's not applicable to the new response format
  };
};
