import React from 'react';
import { Box, Divider, Grid, Paper, Typography, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormData } from './UTIProtocolForm';

interface UTIRecordSummaryProps {
  formData: FormData;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

const FieldValue = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const UTIRecordSummary: React.FC<UTIRecordSummaryProps> = ({ formData }) => {
  const renderField = (label: string, value: string | undefined) => (
    <Box mb={2}>
      <FieldLabel variant="subtitle2">{label}:</FieldLabel>
      <FieldValue>{value || 'N/A'}</FieldValue>
    </Box>
  );

  const renderYesNo = (label: string, value: boolean) => (
    <InfoChip
      icon={value ? <CheckCircleIcon /> : <CancelIcon />}
      label={label}
      color={value ? 'success' : 'error'}
      variant="outlined"
    />
  );

  return (
    <StyledPaper elevation={3}>
      <SectionTitle variant="h5" gutterBottom>
        UTI Record Summary
      </SectionTitle>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box bgcolor="background.paper" p={3} borderRadius={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Patient Information
            </Typography>
            {renderField('First Name', formData.patientInfo.firstName)}
            {renderField('Last Name', formData.patientInfo.lastName)}
            {renderField('Date of Birth', formData.patientInfo.dateOfBirth)}
            {renderField('Gender', formData.patientInfo.gender)}
            {renderField('Phone Number', formData.patientInfo.phoneNumber)}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box bgcolor="background.paper" p={3} borderRadius={2}>
            <Typography variant="h6" gutterBottom color="primary">
              Symptoms
            </Typography>
            {renderField('Temperature', formData.symptoms.temperature)}
            {renderYesNo('Pyelonephritis Signs', formData.symptoms.pyelonephritisSigns)}
            {renderYesNo('Suspected Sepsis', formData.symptoms.suspectedSepsis)}
            {renderYesNo('Dysuria', formData.symptoms.dysuria)}
            {renderYesNo('Frequency', formData.symptoms.frequency)}
            {renderYesNo('Urgency', formData.symptoms.urgency)}
            {renderYesNo('Suprapubic Pain', formData.symptoms.suprapubicPainOrDiscomfort)}
          </Box>
        </Grid>
      </Grid>

      <StyledDivider />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom color="primary">
            Diagnosis
          </Typography>
          {renderField('Diagnosis', formData.diagnosis)}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom color="primary">
            Treatment
          </Typography>
          {renderField('Medicine Provided', formData.treatment.medicineProvided)}
          {renderField('Dosage Instructions', formData.treatment.dosageInstructions)}
          {renderYesNo('Self-Care Advice Given', formData.treatment.selfCareAdvice)}
          {renderYesNo('UTI Prophylaxis Education', formData.treatment.utiProphylaxisEducation)}
        </Grid>
      </Grid>

      <StyledDivider />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom color="primary">
            Follow-up
          </Typography>
          {renderYesNo('Symptom Resolution Advised', formData.followUp.symptomResolutionAdvised)}
          {renderYesNo('Urine Sample Provided', formData.followUp.urineSampleProvided)}
          {renderYesNo('Worsening Symptom Advised', formData.followUp.worseningSymptomAdvised)}
          {renderYesNo('Side Effects Advised', formData.followUp.sideEffectsAdvised)}
          {renderYesNo('Recurrence Advised', formData.followUp.recurrenceAdvised)}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom color="primary">
            Referral
          </Typography>
          {renderField('Referral', formData.referral)}
          {renderField('Referral Notes', formData.referralNotes)}
        </Grid>
      </Grid>

      <StyledDivider />

      <Box>
        <Typography variant="h6" gutterBottom color="primary">
          Pharmacist Care
        </Typography>
        {renderField('Follow-up Date', formData.pharmacistCare.followUpDate)}
        {renderField('Follow-up Method', formData.pharmacistCare.followUpMethod.join(', '))}
        {renderField('Follow-up Notes', formData.pharmacistCare.followUpNotes)}
      </Box>
    </StyledPaper>
  );
};

export default UTIRecordSummary;
