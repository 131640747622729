import { useState, useEffect } from 'react';
import { IProduct } from '../interfaces/IProduct';

const sampleUTIProducts: IProduct[] = [
  {
    productID: '1',
    description: "Chemists' Own® Cystitis Relief Sachets 10s & 28s",
    genericName: 'Sodium citro-tartrate',
    drugStrength: '4g',
    unitPriceExcludingGst: '15.99',
    uomQuantity: '28',
    imageUrl: 'https://www.chemistsown.com.au/wp-content/uploads/2020/11/CO-Cystitis-Relief.png',
    gtin: '1234567890123',
    pharmacyID: 'PHARM001',
    uom: 'sachet',
    retailShelfPack: '1',
    manufacturer: 'Ural',
    manufacturerCode: 'URAL001',
    gstToPharmacy: '10%',
    gstToConsumer: '10%',
    drugSchedule: 'S2',
    minimumOrder: '1',
    orderMultiple: '1',
    supplier: 'Ural Supplier',
    metadata: '{}',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    productID: '1',
    description: "Ural Cranberry Effervescent Powder 28s",
    genericName: 'Sodium citro-tartrate',
    drugStrength: '4g',
    unitPriceExcludingGst: '14.99',
    uomQuantity: '28',
    imageUrl: 'https://cdn.terrywhitechemmart.com.au/product/1001359/f_2d.jpg?size=small',
    gtin: '1234567890123',
    pharmacyID: 'PHARM001',
    uom: 'sachet',
    retailShelfPack: '1',
    manufacturer: 'Ural',
    manufacturerCode: 'URAL001',
    gstToPharmacy: '10%',
    gstToConsumer: '10%',
    drugSchedule: 'S2',
    minimumOrder: '1',
    orderMultiple: '1',
    supplier: 'Ural Supplier',
    metadata: '{}',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    productID: '1',
    description: "Chemists' Own® Cystitis Relief Sachets 10s & 28s",
    genericName: 'Sodium citro-tartrate',
    drugStrength: '4g',
    unitPriceExcludingGst: '15.99',
    uomQuantity: '28',
    imageUrl: 'https://cdn.terrywhitechemmart.com.au/product/1001359/f_2d.jpg?size=small',
    gtin: '1234567890123',
    pharmacyID: 'PHARM001',
    uom: 'sachet',
    retailShelfPack: '1',
    manufacturer: 'Ural',
    manufacturerCode: 'URAL001',
    gstToPharmacy: '10%',
    gstToConsumer: '10%',
    drugSchedule: 'S2',
    minimumOrder: '1',
    orderMultiple: '1',
    supplier: 'Ural Supplier',
    metadata: '{}',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  // ... other products
];

export const useUTIProducts = (limit: number = 2) => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = () => {
      try {
        // Simulate API call delay
        setTimeout(() => {
          setProducts(sampleUTIProducts.slice(0, limit));
          setLoading(false);
        }, 1000);
      } catch (err) {
        setError('Error fetching UTI products');
        setLoading(false);
      }
    };

    fetchProducts();
  }, [limit]);

  return { loading, error, products };
};