import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  MenuItem,
  CircularProgress,
  IconButton,
  LinearProgress,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import BackNavigation from '../../components/BackNavigation';
import { logger } from '../../utils/logger';
import GooglePlacesInput from '../../pages/auth2/GooglePlacesInput';
import { Controller, useForm } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import AuthContext from '../../contexts/AuthContext';
import axios from 'axios';
import { Clinic } from '../../hooks/useClinicData';
import useClinicPatients from '../../hooks/useClinicPatients';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useIdToken } from '../../hooks/useIdToken'; // Add this import
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import ValidatePatientButton from '../../components/ValidatePatientButton';
import aboriginalIcon from '../../assets/images/aboriginal.png';

dayjs.extend(customParseFormat);

LicenseInfo.setLicenseKey(
  '54496d9a2f15ea1becbc1387a0b46140Tz04NTIwMSxFPTE3NDA2OTUwMDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

type NewClinicData = Omit<Clinic, 'clinicID' | 'createdDate' | 'createdAt' | 'updatedAt'>;

interface Patient {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  medicareNumber: string;
  medicareIRN: string;
  postcode: string; // Changed from postCode to postcode
  status?: string;
  service?: string;
  notes?: string;
  isAboriginal?: boolean;
  address?: {
    line1?: string;
    line2?: string;
    suburb?: string;
  };
}

const NewClinic: React.FC = () => {
  const { clinicID } = useParams<{ clinicID: string }>();
  const navigate = useNavigate();
  const { pharmacyID } = useContext(AuthContext);
  const { idToken, isLoading: isTokenLoading } = useIdToken(); // Add this line
  const { control, handleSubmit, setValue, watch } = useForm<NewClinicData>();
  const [clinicCreated, setClinicCreated] = useState(false);
  const [clinicExpanded, setClinicExpanded] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [newPatient, setNewPatient] = useState<Patient>({
    id: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    medicareNumber: '',
    medicareIRN: '',
    postcode: '', // Changed from postCode to postcode
    service: 'Vaccination',
    notes: '',
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });
  const clinicName = watch('clinicName');
  const [editingPatient, setEditingPatient] = useState<string | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [csvData, setCsvData] = useState<Patient[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [validationErrors, setValidationErrors] = useState<any[]>([]);
  const [updatedFields, setUpdatedFields] = useState<any>(null);

  // Use the useClinicPatients hook
  const { patients, loading: patientsLoading, error: patientsError, refetch } = useClinicPatients(clinicID || '');

  useEffect(() => {
    logger.log('useEffect triggered, clinicID:', clinicID);
    if (clinicID && idToken && !isTokenLoading) {
      fetchClinic();
    } else {
      setInitialLoading(false);
    }
  }, [clinicID, idToken, isTokenLoading]);

  const fetchClinic = async () => {
    logger.log('Fetching clinic data for ID:', clinicID);
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLINICS_GET_BY_ID_URL!,
        { clinicID },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      logger.log('Fetched clinic data:', response.data);
      const { clinicID: _, createdDate, createdAt, updatedAt, ...clinicData } = response.data;
      Object.keys(clinicData).forEach((key) => {
        setValue(key as keyof NewClinicData, clinicData[key as keyof NewClinicData]);
      });
      setClinicCreated(true);
    } catch (err) {
      logger.error('Error fetching clinic data:', err);
      setSnackbar({
        open: true,
        message: 'Failed to fetch clinic data',
        severity: 'error',
      });
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const onSubmit = async (data: NewClinicData) => {
    logger.log('Submitting clinic data:', data);
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      const clinicData = {
        ...data,
        pharmacyID,
      };
      const headers = {
        Authorization: `Bearer ${idToken}`,
      };
      if (clinicID) {
        // Update existing clinic
        await axios.post(
          process.env.REACT_APP_CLINICS_UPDATE_URL!,
          {
            ...clinicData,
            clinicID: clinicID,
          },
          { headers }
        );
        setSnackbar({
          open: true,
          message: 'Clinic updated successfully',
          severity: 'success',
        });
      } else {
        // Create new clinic
        const response = await axios.post(process.env.REACT_APP_CLINICS_CREATE_URL!, clinicData, { headers });
        logger.log('Clinic created:', response.data);
        setClinicCreated(true);
        setSnackbar({
          open: true,
          message: 'Clinic created successfully',
          severity: 'success',
        });
      }
      setClinicExpanded(false);
      setTimeout(() => navigate('/pages/vaccine-administration/offsite-clinics'), 2000);
    } catch (error) {
      logger.error('Error submitting clinic:', error);
      setSnackbar({
        open: true,
        message: `Failed to ${clinicID ? 'update' : 'create'} clinic`,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditPatient = async (clinicPatientID: string, errors?: any[], updates?: any) => {
    logger.log('handleEditPatient called with:', { clinicPatientID, errors, updates });

    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLINIC_PATIENTS_GET_BY_ID_URL!,
        { clinicPatientID },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const patientToEdit = response.data;
      logger.log('Original patient data:', patientToEdit);

      if (updates) {
        logger.log('Updates received:', updates);
        setUpdatedFields(updates.differences);
        logger.log('Setting updatedFields to:', updates.differences);

        setNewPatient({
          id: patientToEdit.clinicPatientID,
          ...updates.updatedPatient,
        });
        logger.log('Setting newPatient with AIR data');
      } else {
        setNewPatient({
          id: patientToEdit.clinicPatientID,
          firstName: patientToEdit.firstName || '',
          lastName: patientToEdit.lastName || '',
          dateOfBirth: dayjs(patientToEdit.dateOfBirth).format('DD/MM/YYYY'),
          medicareNumber: patientToEdit.medicareNumber || '',
          medicareIRN: patientToEdit.medicareIRN || '',
          postcode: patientToEdit.postcode || '',
          service: patientToEdit.service || 'Vaccination',
          notes: patientToEdit.notes || '',
          isAboriginal: patientToEdit.isAboriginal || false,
        });
      }

      setEditingPatient(clinicPatientID);
      setValidationErrors(errors || []);
      setOpenDialog(true);
    } catch (error) {
      console.error('Error in handleEditPatient:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch patient details',
        severity: 'error',
      });
    }
  };

  const handleDeletePatient = async (clinicPatientID: string) => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }
    try {
      await axios.post(
        process.env.REACT_APP_CLINIC_PATIENTS_DELETE_URL!,
        { clinicPatientID },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      await refetch();
      setSnackbar({
        open: true,
        message: 'Patient deleted successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting patient:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete patient',
        severity: 'error',
      });
    }
  };

  const addOrUpdatePatient = async () => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      const formattedDateOfBirth = dayjs(newPatient.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const patientData = {
        firstName: newPatient.firstName,
        lastName: newPatient.lastName,
        dateOfBirth: formattedDateOfBirth,
        medicareNumber: newPatient.medicareNumber,
        medicareIRN: newPatient.medicareIRN,
        postcode: newPatient.postcode,
        service: newPatient.service,
        notes: newPatient.notes,
        isAboriginal: newPatient.isAboriginal,
        clinicID,
        pharmacyID,
        status: editingPatient && updatedFields ? 'CONFIRMED' : 'NEW',
      };

      const headers = {
        Authorization: `Bearer ${idToken}`,
      };

      if (editingPatient) {
        // Update existing patient
        await axios.post(
          process.env.REACT_APP_CLINIC_PATIENTS_UPDATE_URL!,
          {
            clinicPatientID: editingPatient,
            ...patientData,
          },
          { headers }
        );
      } else {
        // Create new patient
        await axios.post(process.env.REACT_APP_CLINIC_PATIENTS_CREATE_URL!, patientData, { headers });
      }

      await refetch();
      setOpenDialog(false);
      setEditingPatient(null);
      setUpdatedFields(null);
      setNewPatient({
        id: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        medicareNumber: '',
        medicareIRN: '',
        postcode: '',
        service: 'Vaccination',
        notes: '',
      });
      setSnackbar({
        open: true,
        message: `Patient ${editingPatient ? 'updated and confirmed' : 'added'} successfully`,
        severity: 'success',
      });
    } catch (error) {
      console.error(`Error ${editingPatient ? 'updating' : 'adding'} patient:`, error);
      setSnackbar({
        open: true,
        message: `Failed to ${editingPatient ? 'update' : 'add'} patient`,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    Papa.parse(file, {
      complete: (result) => {
        const parsedData = result.data.slice(1).map((row: any, index) => {
          // Try parsing the date in multiple formats
          const dateFormats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM-DD-YYYY'];
          let parsedDate = null;
          for (const format of dateFormats) {
            const attemptedParse = dayjs(row[2], format);
            if (attemptedParse.isValid()) {
              parsedDate = attemptedParse;
              break;
            }
          }

          return {
            id: `temp-${index}`,
            firstName: row[0],
            lastName: row[1],
            dateOfBirth: parsedDate ? parsedDate.format('DD/MM/YYYY') : 'Invalid Date',
            medicareNumber: row[3],
            medicareIRN: row[4],
            postcode: row[5],
            service: row[6] || 'Vaccination',
            notes: row[7] || '',
          };
        });
        setCsvData(parsedData);
      },
      header: false,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleBulkUpload = async () => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({
        open: true,
        message: 'Authentication error',
        severity: 'error',
      });
      return;
    }
    setIsUploading(true);
    setUploadProgress(0);
    const totalPatients = csvData.length;

    try {
      const headers = {
        Authorization: `Bearer ${idToken}`,
      };
      for (let i = 0; i < totalPatients; i++) {
        const patient = csvData[i];
        if (patient.dateOfBirth === 'Invalid Date') {
          throw new Error(`Invalid date format for patient: ${patient.firstName} ${patient.lastName}`);
        }
        const formattedDateOfBirth = dayjs(patient.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const patientData = {
          firstName: patient.firstName,
          lastName: patient.lastName,
          dateOfBirth: formattedDateOfBirth,
          medicareNumber: patient.medicareNumber,
          medicareIRN: patient.medicareIRN,
          postcode: patient.postcode,
          service: patient.service,
          notes: patient.notes,
          clinicID,
          pharmacyID,
          status: 'NEW',
        };
        await axios.post(process.env.REACT_APP_CLINIC_PATIENTS_CREATE_URL!, patientData, { headers });

        // Update progress
        setUploadProgress(Math.round(((i + 1) / totalPatients) * 100));
      }
      await refetch();
      setCsvData([]);
      setSnackbar({
        open: true,
        message: 'Patients uploaded successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error uploading patients:', error);
      if (error instanceof Error) {
        setSnackbar({
          open: true,
          message: error.message || 'Failed to upload patients',
          severity: 'error',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to upload patients',
          severity: 'error',
        });
      }
    } finally {
      setIsUploading(false);
    }
  };

  // Add this function to generate and download a sample CSV
  const downloadSampleCSV = () => {
    const headers = [
      'First Name',
      'Last Name',
      'Date of Birth (DD/MM/YYYY)',
      'Medicare Number',
      'Medicare IRN',
      'Postcode',
      'Service',
      'Notes',
    ];
    const sampleData = ['John,Doe,01/01/1980,1234567890,1,2000,Vaccination,Sample patient note'];
    const csvContent = [headers.join(','), ...sampleData].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'sample_patients.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredPatients = patients.filter((patient) =>
    Object.values(patient).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography>{params.value}</Typography>
          {params.row.isAboriginal && (
            <Tooltip title="The person identifies as Aboriginal">
              <Box
                component="img"
                src={aboriginalIcon}
                alt="Aboriginal"
                sx={{
                  width: 20,
                  height: 20,
                  display: 'block', // Ensure image is always displayed
                }}
              />
            </Tooltip>
          )}
        </Box>
      ),
    },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'dateOfBirth', headerName: 'Date of Birth', flex: 1 },
    { field: 'medicareNumber', headerName: 'Medicare Number', flex: 1 },
    { field: 'medicareIRN', headerName: 'Medicare IRN', flex: 1 },
    { field: 'postcode', headerName: 'Post Code', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'service', headerName: 'Service', flex: 1 },
    { field: 'notes', headerName: 'Notes', flex: 1 },
    {
      field: 'validate',
      headerName: 'Validate',
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <ValidatePatientButton
            patient={{
              firstName: params.row.firstName,
              lastName: params.row.lastName,
              dateOfBirth: dayjs(params.row.dateOfBirth).format('YYYY-MM-DD'),
              medicareNumber: params.row.medicareNumber,
              medicareIRN: params.row.medicareIRN,
              postcode: params.row.postcode,
              status: params.row.status,
            }}
            pharmacyID={pharmacyID}
            clinicPatientID={params.row.clinicPatientID}
            onValidationComplete={(response) => {
              if (response.success) {
                setSnackbar({
                  open: true,
                  message: 'Patient validated with Medicare successfully',
                  severity: 'success',
                });
                refetch(); // Refresh the grid to show updated status
              }
            }}
            onEditRequest={(errors, updates) => handleEditPatient(params.row.clinicPatientID, errors, updates)}
          />
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <IconButton onClick={() => handleEditPatient(params.row.clinicPatientID)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeletePatient(params.row.clinicPatientID)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (initialLoading) {
    return <CircularProgress />;
  }

  if (patientsError) {
    return <Typography color="error">Error loading patients: {patientsError}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box component="div" sx={{ p: 3, flex: 3 }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <BackNavigation title={clinicID ? 'Edit Clinic' : 'New Clinic'} />
          <Paper
            sx={{
              p: '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              flex: 3,
            }}
          >
            <Accordion expanded={clinicExpanded} onChange={() => setClinicExpanded(!clinicExpanded)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="clinic-form-content"
                id="clinic-form-header"
              >
                <Typography variant="h6">
                  {clinicCreated || clinicID ? `Clinic Details: ${clinicName}` : 'Add New Clinic'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        name="clinicName"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Clinic Name is required' }}
                        render={({ field }) => <TextField {...field} fullWidth label="Clinic Name" required />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="date"
                        control={control}
                        defaultValue={dayjs().format('YYYY-MM-DD')}
                        rules={{ required: 'Date is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Date"
                              value={dayjs(field.value)}
                              onChange={(newValue) => field.onChange(newValue?.format('YYYY-MM-DD'))}
                              slotProps={{
                                textField: { fullWidth: true, required: true },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controller
                        name="startTime"
                        control={control}
                        defaultValue={dayjs().format('HH:mm')}
                        rules={{ required: 'Start Time is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Start Time"
                              value={dayjs(field.value, 'HH:mm')}
                              onChange={(newValue) => field.onChange(newValue?.format('HH:mm'))}
                              slotProps={{
                                textField: { fullWidth: true, required: true },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controller
                        name="endTime"
                        control={control}
                        defaultValue={dayjs().add(1, 'hour').format('HH:mm')}
                        rules={{ required: 'End Time is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="End Time"
                              value={dayjs(field.value, 'HH:mm')}
                              onChange={(newValue) => field.onChange(newValue?.format('HH:mm'))}
                              slotProps={{
                                textField: { fullWidth: true, required: true },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="location"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Location is required' }}
                        render={({ field }) => (
                          <GooglePlacesInput
                            apiKey="AIzaSyAv2vANIvhPQU6wIOtn54KvfiQ8zFfCYBA"
                            label="Location"
                            value={field.value}
                            onChange={field.onChange}
                            onPlaceSelected={(place) => {
                              if (place) {
                                let address = place.formatted_address || '';
                                field.onChange(address);
                              }
                            }}
                            margin="normal"
                            size="medium"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue="Vaccinations"
                        rules={{ required: 'Clinic Type is required' }}
                        render={({ field }) => (
                          <TextField {...field} select fullWidth label="Clinic Type" required>
                            <MenuItem value="Vaccinations">Vaccinations</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="capacity"
                        control={control}
                        defaultValue={0}
                        rules={{ required: 'Capacity is required' }}
                        render={({ field }) => (
                          <TextField {...field} fullWidth label="Capacity" type="number" required />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="notes"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <TextField {...field} fullWidth label="Notes" multiline rows={4} />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : clinicID ? 'Update Clinic' : 'Create Clinic'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={clinicCreated || !!clinicID}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="patient-list-content"
                id="patient-list-header"
              >
                <Typography variant="h6">Patients</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search patients..."
                    value={searchTerm}
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2">Total Patients: {filteredPatients.length}</Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => setOpenDialog(true)}
                        variant="outlined"
                        color="primary"
                        disabled={!clinicCreated && !clinicID}
                      >
                        Add Patient
                      </Button>
                      <Button startIcon={<DownloadIcon />} onClick={downloadSampleCSV} variant="outlined" color="info">
                        Download Sample CSV
                      </Button>
                    </Box>
                  </Box>

                  <Box sx={{ height: 400, width: '100%' }}>
                    {patientsLoading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                      </Box>
                    ) : patientsError ? (
                      <Typography color="error">Error loading patients: {patientsError}</Typography>
                    ) : filteredPatients.length > 0 ? (
                      <DataGridPro
                        rows={filteredPatients}
                        columns={columns}
                        disableRowSelectionOnClick
                        getRowId={(row) => row.clinicPatientID}
                        sx={{
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#e2f1f0',
                            '& .MuiDataGrid-columnHeaderTitle': {
                              fontWeight: 'bold',
                              color: '#05153f',
                            },
                          },
                          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                          borderRadius: '5px',
                        }}
                      />
                    ) : (
                      <Typography>No patients found for this clinic.</Typography>
                    )}
                  </Box>

                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Box {...getRootProps()} sx={{ flex: 1 }}>
                      <input {...getInputProps()} />
                      <Button
                        startIcon={<CloudUploadIcon />}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        disabled={!clinicCreated && !clinicID}
                      >
                        {isDragActive ? 'Drop CSV file here' : 'Upload CSV'}
                      </Button>
                    </Box>
                    {csvData.length > 0 && (
                      <Button onClick={handleBulkUpload} variant="contained" color="primary" disabled={isUploading}>
                        {isUploading ? 'Uploading...' : 'Bulk Upload'}
                      </Button>
                    )}
                  </Box>

                  {isUploading && (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress variant="determinate" value={uploadProgress} />
                      <Typography variant="body2" color="text.secondary">{`${uploadProgress}% Uploaded`}</Typography>
                    </Box>
                  )}
                  {csvData.length > 0 && (
                    <Typography variant="body2">{csvData.length} patients ready for upload</Typography>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setEditingPatient(null);
          setValidationErrors([]);
          setUpdatedFields(null);
        }}
      >
        <DialogTitle>{editingPatient ? 'Edit Patient' : 'Add New Patient'}</DialogTitle>
        <DialogContent>
          {validationErrors.length > 0 && (
            <Box sx={{ mb: 2 }}>
              {validationErrors.map((error, index) => (
                <Alert severity="error" key={index} sx={{ mb: 1 }}>
                  {error.field}: {error.message}
                </Alert>
              ))}
            </Box>
          )}

          {updatedFields && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                The following information differs from IHI/Medicare records:
              </Typography>
              {Object.entries(updatedFields).map(([field, values]: [string, any]) => (
                <Typography key={field} variant="body2">
                  • {field}: {values.original} → {values.air}
                </Typography>
              ))}
            </Alert>
          )}

          {updatedFields?.updatedPatient &&
            setNewPatient((prev) => ({
              ...prev,
              ...updatedFields.updatedPatient,
            }))}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                value={newPatient.firstName}
                onChange={(e) => setNewPatient({ ...newPatient, firstName: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: updatedFields?.firstName ? 'error.main' : undefined,
                      borderWidth: updatedFields?.firstName ? 2 : 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                value={newPatient.lastName}
                onChange={(e) => setNewPatient({ ...newPatient, lastName: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: updatedFields?.lastName ? 'error.main' : undefined,
                      borderWidth: updatedFields?.lastName ? 2 : 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  value={
                    dayjs(newPatient.dateOfBirth, 'DD/MM/YYYY').isValid()
                      ? dayjs(newPatient.dateOfBirth, 'DD/MM/YYYY')
                      : null
                  }
                  onChange={(newValue) =>
                    setNewPatient({ ...newPatient, dateOfBirth: newValue?.format('DD/MM/YYYY') || '' })
                  }
                  slotProps={{
                    textField: { fullWidth: true, margin: 'dense' },
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Medicare Number"
                fullWidth
                value={newPatient.medicareNumber}
                onChange={(e) => setNewPatient({ ...newPatient, medicareNumber: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: updatedFields?.medicareNumber ? 'error.main' : undefined,
                      borderWidth: updatedFields?.medicareNumber ? 2 : 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Medicare IRN"
                fullWidth
                value={newPatient.medicareIRN}
                onChange={(e) => setNewPatient({ ...newPatient, medicareIRN: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: updatedFields?.medicareIRN ? 'error.main' : undefined,
                      borderWidth: updatedFields?.medicareIRN ? 2 : 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Post Code"
                fullWidth
                value={newPatient.postcode}
                onChange={(e) => setNewPatient({ ...newPatient, postcode: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: updatedFields?.postcode ? 'error.main' : undefined,
                      borderWidth: updatedFields?.postcode ? 2 : 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                margin="dense"
                label="Service"
                fullWidth
                value={newPatient.service}
                onChange={(e) => setNewPatient({ ...newPatient, service: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: updatedFields?.service ? 'error.main' : undefined,
                      borderWidth: updatedFields?.service ? 2 : 1,
                    },
                  },
                }}
              >
                <MenuItem value="Vaccination">Vaccination</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                multiline
                rows={4}
                value={newPatient.notes}
                onChange={(e) => setNewPatient({ ...newPatient, notes: e.target.value })}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: updatedFields?.notes ? 'error.main' : undefined,
                      borderWidth: updatedFields?.notes ? 2 : 1,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setEditingPatient(null);
              setValidationErrors([]);
              setUpdatedFields(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={addOrUpdatePatient}>
            {editingPatient ? (updatedFields ? 'Accept & Update' : 'Update') : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewClinic;
