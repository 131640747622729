import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Notification from "./notification/reducers";
import Sale from "./sale/reducers";
import Layout from "./layout/reducers";
import Cart from "./cart/reducers";
import { submissionReducer } from "./vax/reducers";

export default combineReducers({
  Auth,
  Layout,
  Sale,
  Cart,
  Notification,
  submission: submissionReducer, // Changed this line
});
