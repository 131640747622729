import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AuthContext, { AuthDetailsType, AuthContextType } from '../contexts/AuthContext';
import { logger } from '../utils/logger';
import { refreshSession } from '../redux/auth/services/cognitoService';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const {
    providerNumber,
    providerFirstName,
    providerLastName,
    providerStore,
    pharmacistID,
    pharmacyID,
    pharmacyName,
    hpioNumber,
    hpiiNumber,
    isAdministrator,
    deviceName,
    minorId,
    isPPAEligible,
    providerEmail,
    providerAHPRA,
    prodaOrgId,
    PPA,
    isSubscribed,
    planName,
    pharmacyState,
  } = useSelector((state: any) => ({
    providerNumber:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:providerNumber'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:providerNumber'],
    providerStore:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:pharmacyAddress'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:pharmacyAddress'],
    pharmacistID:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:pharmacistID'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:pharmacistID'],
    hpiiNumber:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:hpiiNumber'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:hpiiNumber'],
    hpioNumber:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:hpioNumber'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:hpioNumber'],
    isAdministrator:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:isAdministrator'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:isAdministrator'],
    deviceName:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:deviceName'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:deviceName'],
    minorId:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:minorId'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:minorId'],
    isPPAEligible:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:isPPAEligible'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:isPPAEligible'],
    providerAHPRA:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:providerAHPRA'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:providerAHPRA'],
    pharmacyID:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:pharmacyID'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:pharmacyID'],
    pharmacyName:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:pharmacyName'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:pharmacyName'],
    providerFirstName:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:providerFirstName'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:providerFirstName'],
    providerLastName:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:providerLastName'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:providerLastName'],
    providerEmail:
      state.Auth?.user?.tokens?.idToken?.payload?.['email'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['email'],
    prodaOrgId:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:prodaOrgId'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:prodaOrgId'],
    PPA: state.Auth?.user?.PPA ?? state.Auth?.user?.session?.PPA,
    isSubscribed:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:isSubscribed'] === 'true' ||
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:isSubscribed'] === 'true',
    planName:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:planName'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:planName'],
    pharmacyState:
      state.Auth?.user?.tokens?.idToken?.payload?.['custom:pharmacyState'] ??
      state.Auth?.user?.session?.tokens?.idToken?.payload?.['custom:pharmacyState'],
  }));

  const [authDetails, setAuthDetailsState] = useState<AuthDetailsType>({
    providerNumber,
    providerFirstName,
    providerLastName,
    providerStore,
    pharmacistID,
    pharmacyID,
    pharmacyName,
    hpiiNumber,
    hpioNumber,
    isAdministrator,
    deviceName,
    minorId,
    providerEmail,
    isPPAEligible,
    providerAHPRA,
    prodaOrgId,
    PPA,
    isSubscribed,
    planName,
    pharmacyState,
  });

  useEffect(() => {
    logger.log('Updating Auth Details:', {
      providerNumber,
      providerFirstName,
      providerLastName,
      providerStore,
      pharmacistID,
      pharmacyID,
      pharmacyName,
      hpiiNumber,
      hpioNumber,
      isAdministrator,
      deviceName,
      minorId,
      providerEmail,
      isPPAEligible,
      providerAHPRA,
      prodaOrgId,
      PPA,
      isSubscribed,
      planName,
      pharmacyState,
    });
    logger.log('INVOKING_AUTH_PROVIDER');
    setAuthDetailsState({
      providerNumber,
      providerFirstName,
      providerLastName,
      providerStore,
      pharmacistID,
      pharmacyID,
      pharmacyName,
      hpiiNumber,
      hpioNumber,
      isAdministrator,
      deviceName,
      minorId,
      providerEmail,
      isPPAEligible,
      providerAHPRA,
      prodaOrgId,
      PPA,
      isSubscribed,
      planName,
      pharmacyState,
    });
  }, [
    providerNumber,
    providerFirstName,
    providerLastName,
    providerStore,
    pharmacistID,
    pharmacyID,
    pharmacyName,
    hpiiNumber,
    hpioNumber,
    isAdministrator,
    deviceName,
    minorId,
    isPPAEligible,
    providerAHPRA,
    prodaOrgId,
    providerEmail,
    PPA,
    isSubscribed,
    planName,
    pharmacyState,
  ]);

  const setAuthDetails: AuthContextType['setAuthDetails'] = (details) => {
    logger.log('Updating Auth Details in setAuthDetails:', details);
    setAuthDetailsState((prevState) => ({ ...prevState, ...details }));
  };

  const contextValue: AuthContextType = {
    ...authDetails,
    setAuthDetails,
  };

  useEffect(() => {
    let refreshTimer: NodeJS.Timeout;

    const scheduleTokenRefresh = async () => {
      try {
        const { tokens, expirationTime } = await refreshSession();
        // Update your app state with the new token here
        // For example: dispatch(updateToken(tokens.idToken));

        // Schedule the next refresh
        const currentTime = Date.now();
        const timeUntilExpiry = expirationTime - currentTime;
        const refreshTime = Math.max(timeUntilExpiry - 5 * 60 * 1000, 0); // Refresh 5 minutes before expiry or immediately if less than 5 minutes left

        refreshTimer = setTimeout(scheduleTokenRefresh, refreshTime);
      } catch (error) {
        console.error('Failed to refresh token:', error);
        // Handle refresh failure (e.g., redirect to login)
        // You might want to implement a more sophisticated error handling here
        // For example, you could try to refresh again after a short delay, or redirect to login if multiple attempts fail
      }
    };

    scheduleTokenRefresh();

    return () => {
      if (refreshTimer) clearTimeout(refreshTimer);
    };
  }, []);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
