import React, { useState, useContext } from 'react';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  AddOutlined,
  Edit as EditIcon,
  Delete as DeleteIcon,
  RocketLaunch as RocketLaunchIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import AuthContext from '../../contexts/AuthContext';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import useClinicData, { Clinic } from '../../hooks/useClinicData';
import { logger } from 'utils/logger';
import { useIdToken } from '../../hooks/useIdToken'; // Add this import

// Update the NewClinic type to match the Clinic type
type NewClinic = Omit<Clinic, 'clinicID' | 'createdDate' | 'createdAt' | 'updatedAt'>;

LicenseInfo.setLicenseKey(
  '54496d9a2f15ea1becbc1387a0b46140Tz04NTIwMSxFPTE3NDA2OTUwMDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const OffsiteClinics: React.FC = () => {
  const { pharmacyID } = useContext(AuthContext);
  const { clinics, loading, error, fetchClinics } = useClinicData(pharmacyID);
  const navigate = useNavigate();
  const { idToken, isLoading: isTokenLoading } = useIdToken(); // Add this line

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingClinic, setEditingClinic] = useState<string | null>(null);
  const [newClinic, setNewClinic] = useState<NewClinic>({
    type: '',
    clinicName: '',
    date: dayjs().format('YYYY-MM-DD'),
    startTime: dayjs().format('HH:mm'),
    endTime: dayjs().add(1, 'hour').format('HH:mm'),
    location: '',
    capacity: 0,
    notes: '',
    pharmacyID: pharmacyID,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleAddOrUpdateClinic = async () => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({ open: true, message: 'Authentication error', severity: 'error' });
      return;
    }

    setIsSubmitting(true);
    try {
      const headers = {
        Authorization: `Bearer ${idToken}`,
      };

      if (editingClinic) {
        await axios.put(`${process.env.REACT_APP_CLINICS_UPDATE_URL}/${editingClinic}`, newClinic, { headers });
        setSnackbar({ open: true, message: 'Clinic updated successfully', severity: 'success' });
      } else {
        await axios.post(process.env.REACT_APP_CLINICS_CREATE_URL!, newClinic, { headers });
        setSnackbar({ open: true, message: 'Clinic added successfully', severity: 'success' });
      }
      setIsModalOpen(false);
      setEditingClinic(null);
      setNewClinic({
        type: '',
        clinicName: '',
        date: dayjs().format('YYYY-MM-DD'),
        startTime: dayjs().format('HH:mm'),
        endTime: dayjs().add(1, 'hour').format('HH:mm'),
        location: '',
        capacity: 0,
        notes: '',
        pharmacyID: pharmacyID,
      });
      fetchClinics();
    } catch (error) {
      logger.error('Error submitting clinic:', error);
      setSnackbar({ open: true, message: 'Failed to submit clinic', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditClinic = (id: string) => {
    const clinicToEdit = clinics.find((clinic) => clinic.clinicID === id);
    if (clinicToEdit) {
      const { clinicID, createdDate, createdAt, updatedAt, ...editableFields } = clinicToEdit;
      setNewClinic(editableFields);
      setEditingClinic(id);
      setIsModalOpen(true);
    }
  };

  const handleDeleteClinic = async (id: string) => {
    if (isTokenLoading || !idToken) {
      logger.error('ID token not available');
      setSnackbar({ open: true, message: 'Authentication error', severity: 'error' });
      return;
    }

    try {
      await axios.post(
        process.env.REACT_APP_CLINICS_DELETE_URL!,
        { clinicID: id },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      setSnackbar({ open: true, message: 'Clinic deleted successfully', severity: 'success' });
      fetchClinics();
    } catch (error) {
      logger.error('Failed to delete clinic:', error);
      setSnackbar({ open: true, message: 'Failed to delete clinic', severity: 'error' });
    }
  };

  const columns: GridColDef[] = [
    { field: 'clinicName', headerName: 'Clinic Name', flex: 1 },
    { field: 'location', headerName: 'Address', flex: 1 },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
    },
    { field: 'startTime', headerName: 'Start Time', flex: 0.5 },
    { field: 'endTime', headerName: 'End Time', flex: 0.5 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      valueGetter: (params) => {
        const clinicDate = dayjs(params.row.date);
        const today = dayjs();
        if (clinicDate.isBefore(today, 'day')) {
          return 'Completed';
        } else if (clinicDate.isAfter(today, 'day')) {
          return 'Upcoming';
        } else {
          const startTime = dayjs(`${params.row.date} ${params.row.startTime}`);
          const endTime = dayjs(`${params.row.date} ${params.row.endTime}`);
          if (today.isBefore(startTime)) {
            return 'Today (Not Started)';
          } else if (today.isAfter(endTime)) {
            return 'Completed';
          } else {
            return 'In Progress';
          }
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        logger.log('Row data:', params.row);
        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              onClick={() => {
                logger.log(
                  'Navigating to:',
                  `/pages/vaccine-administration/offsite-clinics/edit/${params.row.clinicID}`
                );
                navigate(`/pages/vaccine-administration/offsite-clinics/edit/${params.row.clinicID}`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteClinic(params.row.clinicID)}>
              <DeleteIcon />
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<RocketLaunchIcon />}
              onClick={() => {
                logger.log(
                  'Launching clinic:',
                  `/pages/vaccine-administration/offsite-clinics/launch/${params.row.clinicID}`
                );
                navigate(`/pages/vaccine-administration/offsite-clinics/launch/${params.row.clinicID}`);
              }}
            >
              Launch
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Paper
        sx={{
          p: '2rem',
          background: 'white',
          minHeight: '80vh',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Offsite Clinics</Typography>
          <Link to="/pages/vaccine-administration/offsite-clinics/new" style={{ textDecoration: 'none' }}>
            <Button size="large" variant="contained" color="primary" startIcon={<AddOutlined />}>
              Add New Clinic
            </Button>
          </Link>
        </Box>

        <Box sx={{ height: 600, width: '100%' }}>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">Error loading clinics: {error.message}</Typography>
          ) : (
            <DataGridPro
              rows={clinics}
              columns={columns}
              disableRowSelectionOnClick
              getRowId={(row) => row.clinicID}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#e2f1f0',
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    color: '#05153f',
                  },
                },
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
              }}
            />
          )}
        </Box>

        <Typography fontStyle="italic">
          <strong>Notes*</strong>: To add or update patients for a clinic, please click the edit button for the
          respective clinic.
        </Typography>
      </Paper>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {editingClinic ? 'Edit Clinic' : 'Add New Clinic'}
          </Typography>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Clinic Name"
              value={newClinic.clinicName}
              onChange={(e) => setNewClinic({ ...newClinic, clinicName: e.target.value })}
              fullWidth
            />
            <TextField
              label="Address"
              value={newClinic.location}
              onChange={(e) => setNewClinic({ ...newClinic, location: e.target.value })}
              fullWidth
            />
            <DatePicker
              label="Date"
              value={dayjs(newClinic.date)}
              onChange={(date) => setNewClinic({ ...newClinic, date: date?.format('YYYY-MM-DD') || '' })}
              slotProps={{ textField: { fullWidth: true } }}
            />
            <TimePicker
              label="Start Time"
              value={dayjs(newClinic.startTime, 'HH:mm')}
              onChange={(time) => setNewClinic({ ...newClinic, startTime: time?.format('HH:mm') || '' })}
              slotProps={{ textField: { fullWidth: true } }}
            />
            <TimePicker
              label="End Time"
              value={dayjs(newClinic.endTime, 'HH:mm')}
              onChange={(time) => setNewClinic({ ...newClinic, endTime: time?.format('HH:mm') || '' })}
              slotProps={{ textField: { fullWidth: true } }}
            />
            <TextField
              label="Capacity"
              type="number"
              value={newClinic.capacity}
              onChange={(e) => setNewClinic({ ...newClinic, capacity: parseInt(e.target.value) || 0 })}
              fullWidth
            />
            <TextField
              label="Notes"
              value={newClinic.notes}
              onChange={(e) => setNewClinic({ ...newClinic, notes: e.target.value })}
              fullWidth
              multiline
              rows={3}
            />
            <Button
              variant="contained"
              onClick={handleAddOrUpdateClinic}
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isSubmitting ? 'Submitting...' : editingClinic ? 'Update Clinic' : 'Add Clinic'}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OffsiteClinics;
