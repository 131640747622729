import { DocumentNode, useSubscription } from '@apollo/client';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { GRAPHQL_DESTINATION } from '../ApolloClient';
import { useSnackbar } from '../contexts/SnackbarContext';
import { BOOKING_STATUS } from '../interfaces/IBooking';
import { INotification } from '../interfaces/INotification';
import { setAddNotification } from '../redux/notification/actions';
import { uuid } from '../utils';
import {
  OnCreateBookingSubscription,
  OnCreateBookingSubscriptionVariables,
  OnUpdateBookingSubscription,
  OnUpdateBookingSubscriptionVariables,
} from '../codegens/HEALTCARE-RESERVATION/__generated__/graphql';
import {
  SUBSCRIPTION_ONCREATE_BOOKING,
  SUBSCRIPTION_ONUPDATE_BOOKING,
} from '../codegens/HEALTCARE-RESERVATION/subscriptions';
import { useNavigate } from 'react-router-dom';
import { logger } from '../utils/logger';
import { useAppointment } from '../contexts/AppointmentContext';
import { formatToABooking } from 'utils/graphql-layers/reservations';
import { IBooking } from 'interfaces/healthcare-reservation/IBooking';
interface UseBookingSubscriptionOptions {
  pharmacyID: string;
  onData?: (data: IBooking) => Promise<void>;
  onError?: (error: any) => void;
  onComplete?: () => void;
}

export const useBookingSubscription = ({ pharmacyID, onData, onError, onComplete }: UseBookingSubscriptionOptions) => {
  const { openSnackbar } = useSnackbar();
  const { setOpenEvent, setSelectedAppointment } = useAppointment();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNotification = (data: INotification) => {
    dispatch(
      setAddNotification({
        ...data,
      })
    );
  };

  useSubscription<OnCreateBookingSubscription, OnCreateBookingSubscriptionVariables>(SUBSCRIPTION_ONCREATE_BOOKING, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
    variables: {
      pharmacyID,
    },
    onData(options) {
      const data = options.data.data;
      const booking = data?.onCreateBookings as IBooking;

      if (onData) {
        onData(booking);
      } else {
        const message = 'There is a new appointment!';

        addNotification({
          id: uuid(),
          isRead: false,
          message,
          createdDate: moment().toISOString(),
          status: booking.status as BOOKING_STATUS,
          bookingID: booking.bookingID,
        });

        openSnackbar({
          message: message,
          severity: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          onClick: (event) => {
            logger.log(event);

            if (booking) {
              setSelectedAppointment(formatToABooking(booking));
              setOpenEvent(true);
            }
          },
          sx: {
            cursor: 'pointer',
          },
        });
      }
    },
    onError(error) {
      console.error('Failed to subscribe to create booking', error);
      if (onError) {
        onError(error);
      }
    },
    onComplete() {
      logger.log('Subscription onCreate complete');
      if (onComplete) {
        onComplete();
      }
    },
  });
};

export const useUpdateBookingSubscription = ({
  pharmacyID,
  onData,
  onError,
  onComplete,
}: UseBookingSubscriptionOptions) => {
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOpenEvent, setSelectedAppointment } = useAppointment();

  const addNotification = (data: INotification) => {
    dispatch(
      setAddNotification({
        ...data,
      })
    );
  };

  useSubscription<OnUpdateBookingSubscription, OnUpdateBookingSubscriptionVariables>(SUBSCRIPTION_ONUPDATE_BOOKING, {
    context: {
      clientName: GRAPHQL_DESTINATION.HEALTHCATE_RESERVATION,
    },
    variables: {
      pharmacyID,
    },
    onData(options) {
      const data = options.data.data;
      const booking = data?.onUpdateBookings as IBooking;

      if (onData) {
        onData(booking);
      } else {
        const isCancel =
          booking.status === BOOKING_STATUS.CANCELED ||
          booking.status === BOOKING_STATUS.CANCELED_BY_PATIENT ||
          booking.status === BOOKING_STATUS.CANCELED_BY_PHARMACY;
        const statusText = isCancel ? 'Canceled' : 'Updated';
        const message = `There is a ${statusText} appointment!`;

        addNotification({
          id: uuid(),
          isRead: false,
          message,
          createdDate: moment().toISOString(),
          status: booking.status as BOOKING_STATUS,
          bookingID: booking.bookingID,
        });

        openSnackbar({
          message: message,
          severity: isCancel ? 'warning' : 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          onClick: (event) => {
            logger.log(event);
            //   navigate(
            //   {
            //     pathname: '/apps/appointments',
            //     search: `_eid=${booking.bookingID}`,
            //   },
            //   {
            //     state: {
            //       _eid: booking.bookingID,
            //     },
            //   }
            // )

            if (booking) {
              setSelectedAppointment(formatToABooking(booking));
              setOpenEvent(true);
            }
          },
          sx: {
            cursor: 'pointer',
          },
        });
      }
    },
    onError(error) {
      console.error('Failed to subscribe to create booking', error);
      if (onError) {
        onError(error);
      }
    },
    onComplete() {
      logger.log('Subscription onCreate complete');
      if (onComplete) {
        onComplete();
      }
    },
  });
};
