import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
  FormControlLabel,
  Divider,
  Paper,
} from '@mui/material';
import {
  TimelineOutlined,
  ScienceOutlined,
  WarningOutlined,
  LocalHospitalOutlined,
  RepeatOutlined,
  ShareOutlined,
} from '@mui/icons-material';
import { Controller, Control, FieldPath } from 'react-hook-form';
import { FormData } from '../UTIProtocolForm';

interface StepProps {
  control: Control<FormData>;
}

const Step8FollowUp: React.FC<StepProps> = ({ control }) => {
  const followUpItems = [
    {
      icon: <TimelineOutlined />,
      primary: 'Symptom Resolution',
      secondary: 'Advise the patient that symptoms should respond to appropriate antibiotic treatment within 48 hours.',
      checkboxName: 'symptomResolutionAdvised',
    },
    {
      icon: <ScienceOutlined />,
      primary: 'Urine Sample',
      secondary:
        'Provide the patient with a urine specimen jar, and advise them to take a urine sample before commencing first dose of antibiotic treatment and keep the sample refrigerated.',
      checkboxName: 'urineSampleProvided',
    },
    {
      icon: <WarningOutlined />,
      primary: 'Worsening Symptoms',
      secondary:
        'Advise the patient to seek care from a general practitioner if symptoms worsen or do not improve within 48 hours, and to take this sample with them.',
      checkboxName: 'worseningSymptomAdvised',
    },
    {
      icon: <LocalHospitalOutlined />,
      primary: 'Side Effects',
      secondary:
        'Advise on what to do if side effects of medication occur (e.g. when to seek emergency care, medical review or return to pharmacy)',
      checkboxName: 'sideEffectsAdvised',
    },
    {
      icon: <RepeatOutlined />,
      primary: 'Symptom Recurrence',
      secondary:
        'Advise the patient to seek care from a GP if symptoms reoccur within 2 weeks after finishing antibiotic treatment, or symptoms develop that are not symptoms of an acute UTI.',
      checkboxName: 'recurrenceAdvised',
    },
    {
      icon: <ShareOutlined />,
      primary: 'Share Consultation Record',
      secondary:
        "Where patient has a regular GP or general practice service, share a record of the consultation, within a week, with the patient's consent.",
      checkboxName: 'shareConsultationRecord',
    },
  ];

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto' }}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Follow-up Plan
        </Typography>
        <List disablePadding>
          {followUpItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start" sx={{ py: 1 }}>
                <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
                <Controller
                  name={`followUp.${item.checkboxName}` as FieldPath<FormData>}
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={!!field.value} color="primary" size="small" />}
                      label="Completed"
                      sx={{ ml: 1 }}
                    />
                  )}
                />
              </ListItem>
              {index < followUpItems.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Paper>

      <Paper elevation={3} sx={{ p: 2 }}>
        <Controller
          name="followUp.additionalNotes"
          control={control}
          render={({ field }) => (
            <TextField {...field} fullWidth multiline rows={3} label="Additional Follow-up Notes" variant="outlined" />
          )}
        />
      </Paper>
    </Box>
  );
};

export default Step8FollowUp;
