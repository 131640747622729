// src/redux/vax/reducers.ts
import { logger } from "../../utils/logger";
// Define and export the SubmissionState type
export type SubmissionState = {
  air: {
    loading: boolean;
    submitted: boolean;
    success: boolean;
    message: string[];
  };
  ppa: {
    loading: boolean;
    submitted: boolean;
    success: boolean;
    message: string[];
  };
};

// Define and export the SubmissionAction type
export type SubmissionAction =
  | { type: "START_AIR_SUBMISSION" }
  | { type: "COMPLETE_AIR_SUBMISSION"; success: boolean; message: string }
  | { type: "START_PPA_SUBMISSION" }
  | { type: "COMPLETE_PPA_SUBMISSION"; success: boolean; message: string }
  | { type: "RESET" };

// Define the initial state
const initialSubmissionState: SubmissionState = {
  air: { loading: false, submitted: false, success: false, message: [] },
  ppa: { loading: false, submitted: false, success: false, message: [] },
};

// Define and export the reducer function
export const submissionReducer = (
  state: SubmissionState = initialSubmissionState,
  action: SubmissionAction
): SubmissionState => {
  switch (action.type) {
    case "START_AIR_SUBMISSION":
      logger.log("🚀 ~ submissionReducer ~ START_AIR_SUBMISSION:");
      return { ...state, air: { ...state.air, loading: true } };
    case "COMPLETE_AIR_SUBMISSION":
      return {
        ...state,
        air: {
          loading: false,
          submitted: true,
          success: action.success,
          message: Array.isArray(action.message) ? action.message : [action.message],
        },
      };
    case "START_PPA_SUBMISSION":
      return { ...state, ppa: { ...state.ppa, loading: true } };
    case "COMPLETE_PPA_SUBMISSION":
      return {
        ...state,
        ppa: {
          loading: false,
          submitted: true,
          success: action.success,
          message: Array.isArray(action.message) ? action.message : [action.message],
        },
      };
    case "RESET":
      return initialSubmissionState;
    default:
      return state;
  }
};

// Export the initial state if needed elsewhere
export { initialSubmissionState };
