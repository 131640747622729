import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { removeTypename } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerSelected, setFilterType } from '../redux/actions';
import { getFilterType, getSelectedCustomer } from '../redux/sale/selectors';
import { RecordingCategory } from '../interfaces';
import { VapeCustomer } from '../interfaces/VapeCustomer';

export interface IProductSaleFilter {
  label: string;
  value: RecordingCategory;
}

interface PurchaseContextProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchResults: VapeCustomer[];
  setSearchResults: React.Dispatch<React.SetStateAction<VapeCustomer[]>>;
  selectedResult: VapeCustomer | null;
  setSelectedResult: (customer: VapeCustomer | null) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilter: IProductSaleFilter | null;
  setSelectedFilter: (filter: IProductSaleFilter | null) => void;
}

const ProductSaleContext = createContext<PurchaseContextProps | undefined>(undefined);

interface PurchaseProviderProps {
  children: ReactNode;
}

export const PurchaseProvider: React.FC<PurchaseProviderProps> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<VapeCustomer[]>([]);
  const [selectedResult, setSelectedResultLocal] = useState<VapeCustomer | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilterLocal] = useState<IProductSaleFilter | null>(null);

  const dispatch = useDispatch();

  const customer = useSelector(getSelectedCustomer);

  const setSelectedResult = (customer: VapeCustomer | null) => {
    setSelectedResultLocal(customer ? (removeTypename(customer) as VapeCustomer) : null);
    dispatch(setCustomerSelected(customer ? (removeTypename(customer) as VapeCustomer) : null));
  };

  useEffect(() => {
    if (customer) setSelectedResultLocal(customer as VapeCustomer);
  }, [customer]);

  const filterType = useSelector(getFilterType);

  useEffect(() => {
    if (filterType) setSelectedFilterLocal(filterType as IProductSaleFilter);
  }, [filterType]);

  const setSelectedFilter = (filter: IProductSaleFilter | null) => {
    setSelectedFilterLocal(filter);
    dispatch(setFilterType(filter));
  };

  const contextValue: PurchaseContextProps = {
    searchTerm,
    setSearchTerm,
    searchResults,
    setSearchResults,
    selectedResult,
    setSelectedResult,
    loading,
    setLoading,
    selectedFilter,
    setSelectedFilter,
  };

  return <ProductSaleContext.Provider value={contextValue}>{children}</ProductSaleContext.Provider>;
};

export function usePurchase() {
  const context = useContext(ProductSaleContext);
  if (!context) {
    throw new Error('usePurchase must be used within an PurchaseProvider');
  }
  return context;
}
