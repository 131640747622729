import React from 'react';
import { Typography, Container, Paper, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(3),
}));

const Section = styled('div')(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(3),
}));

const DisclaimerComponent: React.FC = () => {
  return (
    <Root>
      <Container maxWidth="md">
        <StyledPaper>
          <Typography variant="h4" gutterBottom>
            Disclaimer
          </Typography>
          <Typography variant="body2" gutterBottom>
            Last updated: 02/09/2024
          </Typography>

          <Section>
            <Typography variant="h6" gutterBottom>
              1. Interpretation and Definitions
            </Typography>
            <Typography variant="body1" paragraph>
              [Define key terms used in the disclaimer]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              2. Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
              [Your main disclaimer statement]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              3. External Links Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
              [Disclaimer about external links on your website]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              4. Professional Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
              [Disclaimer about professional advice, if applicable]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              5. Errors and Omissions Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
              [Disclaimer about potential errors or omissions in your content]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              6. Views Expressed Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
              [Disclaimer about views expressed on your website]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              7. Fair Use Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
              [Disclaimer about fair use of copyrighted material, if applicable]
            </Typography>
          </Section>

          <Section>
            <Typography variant="h6" gutterBottom>
              8. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions about this Disclaimer, please contact us at [Your Contact Email].
            </Typography>
          </Section>
        </StyledPaper>
      </Container>
    </Root>
  );
};

export default DisclaimerComponent;
