import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { logger } from '../utils/logger';
import AuthContext from 'contexts/AuthContext';

interface SubscriptionCheckProps {
  children: React.ReactNode;
}

const SubscriptionCheck: React.FC<SubscriptionCheckProps> = ({ children }) => {
  const { isSubscribed } = useContext(AuthContext);
  const location = useLocation();

  const bypassPaths = [
    '/auth/login',
    '/auth/login/accounts',
    '/pricing',
    '/register/step-2',
    '/register/step-3',
    '/register/step-4',
    '/register/step-5',
  ];

  if (bypassPaths.includes(location.pathname)) {
    return <>{children}</>;
  }

  if (isSubscribed === null) {
    return <div>Loading...</div>;
  }

  if (isSubscribed === false) {
    logger.log('SubscriptionCheck - Not subscribed, redirecting to pricing');
    return <Navigate to="/pricing" replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default React.memo(SubscriptionCheck);
