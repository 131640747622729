import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  Chip,
  Grid,
  Paper,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Modal,
} from '@mui/material';
import { Close, LocationOn, Cake, Check, Clear } from '@mui/icons-material';

interface PatientPrescriptionModalProps {
  open: boolean;
  onClose: () => void;
}

const PatientPrescriptionModal: React.FC<PatientPrescriptionModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="patient-prescription-modal"
      aria-describedby="patient-prescription-details"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" component="h2">
            Monday, 21 November
          </Typography>
          <Close sx={{ cursor: 'pointer' }} onClick={onClose} />
        </Box>

        <Paper sx={{ bgcolor: '#F0F4F8', p: 2, mb: 2 }}>
          <Box display="flex" alignItems="center">
            <Avatar src="/api/placeholder/80/80" alt="Jane Cooper" sx={{ width: 60, height: 60, mr: 2 }} />
            <Box>
              <Typography variant="h6">Jane Cooper</Typography>
              <Box display="flex" alignItems="center" mb={0.5}>
                <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">6391 Elgin St. Celina, Delaware 10299</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Cake fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">May 31, 1990</Typography>
              </Box>
            </Box>
            <Chip label="Active" color="success" size="small" sx={{ ml: 'auto' }} />
          </Box>
        </Paper>

        <Paper sx={{ bgcolor: '#F0F4F8', p: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Script Details
          </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Drug name
                  </TableCell>
                  <TableCell>Sublocade</TableCell>
                  <TableCell>Additional Notes</TableCell>
                  <TableCell>N/A</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Drug Strength
                  </TableCell>
                  <TableCell>100 mg/0.5 mL</TableCell>
                  <TableCell>Drug Wallet Balance</TableCell>
                  <TableCell>53</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Dosage (Start Date)
                  </TableCell>
                  <TableCell>May 31, 2015</TableCell>
                  <TableCell>Total Qty</TableCell>
                  <TableCell>53</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Dosage (End Date)
                  </TableCell>
                  <TableCell colSpan={3}>May 31, 2015</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Paper sx={{ bgcolor: '#F0F4F8', p: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Collection frequency
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Daily</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Once a Day</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Weekly</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">-</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Fortnightly</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">-</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Monthly</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">-</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Alt days</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">-</Typography>
            </Grid>
          </Grid>
        </Paper>

        <Box display="flex" justifyContent="space-between">
          <Button variant="outlined" startIcon={<Check />} sx={{ flex: 1, mr: 1 }}>
            Administered Vaccine
          </Button>
          <Button variant="outlined" color="error" startIcon={<Clear />} sx={{ flex: 1, ml: 1 }}>
            Dose Missed
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PatientPrescriptionModal;
