import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { logger } from 'utils/logger';
import {
    User,
    History,
    Apple,
    BookOpen,
    Activity,
    Heart,
    AlertCircle,
    Home,
    Phone,
    Mail,
    Calendar,
    Clock,
    Weight,
    Check,
    ChevronRight,
    ChevronLeft
} from 'lucide-react';
interface PersonalInfo {
    name: string;
    dob: Dayjs | null;
    email: string;
    phone: string;
    address: string;
}

export default function NutritionPlanningForm() {
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
        name: '',
        dob: null,
        email: '',
        phone: '',
        address: '',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPersonalInfo(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date: Dayjs | null) => {
        setPersonalInfo(prev => ({ ...prev, dob: date }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        logger.log('Form submitted:', personalInfo);
        // Here you would typically send the data to a server
    };

    return (
        <Container maxWidth="md">
            <Card>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Personal Information
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Full Name"
                                    name="name"
                                    value={personalInfo.name}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: <User size={20} className="text-gray-400 mr-2" />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date of Birth"
                                        value={personalInfo.dob}
                                        onChange={handleDateChange}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                required: true,
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={personalInfo.email}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: <User size={20} className="text-gray-400 mr-2" />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    value={personalInfo.phone}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: <Phone size={20} className="text-gray-400 mr-2" />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    name="address"
                                    multiline
                                    rows={3}
                                    value={personalInfo.address}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: <Home size={20} className="text-gray-400 mr-2" />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Container>
    );
}
// import React, { useState } from 'react';
// import {
//     Box,
//     Button,
//     Card,
//     CardContent,
//     Checkbox,
//     Container,
//     FormControl,
//     FormControlLabel,
//     Grid,
//     InputLabel,
//     LinearProgress,
//     MenuItem,
//     Paper,
//     Select,
//     SelectChangeEvent,
//     Stack,
//     Step,
//     StepLabel,
//     Stepper,
//     TextField,
//     Typography,
//     Alert
// } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import {
//     User,
//     History,
//     Apple,
//     BookOpen,
//     Activity,
//     Heart,
//     AlertCircle,
//     Home,
//     Phone,
//     Mail,
//     Calendar,
//     Clock,
//     Weight,
//     Check,
//     ChevronRight,
//     ChevronLeft
// } from 'lucide-react';
// import { logger } from 'utils/logger';

// interface FormData {
//     [key: string]: any;
// }

// interface FoodDiaryEntry {
//     time: string;
//     food: string;
//     symptoms: string;
// }

// interface DayEntry {
//     day: number;
//     meals: FoodDiaryEntry[];
// }

// const steps = [
//     { label: 'Personal Info', icon: <User size={24} /> },
//     { label: 'Health History', icon: <History size={24} /> },
//     { label: 'Diet', icon: <Apple size={24} /> },
//     { label: 'Food Diary', icon: <BookOpen size={24} /> },
//     { label: 'Digestive Health', icon: <Activity size={24} /> }
// ];

// const SPECIAL_DIETS = [
//     'GFCF',
//     'SCD/GAPS',
//     'Paleo',
//     'Autoimmune Paleo',
//     'Feingold/Failsafe',
//     'Body Ecology Diet',
//     'Low oxalate',
//     'Low FODMAPS',
//     'Low Histamine',
//     'Low Carbohydrate',
//     'Ketogenic Diet',
//     'Vegetarian',
//     'Vegan'
// ];

// const MEAL_TIMES = [
//     'Breakfast',
//     'Morning Snack',
//     'Lunch',
//     'Afternoon Snack',
//     'Dinner',
//     'Evening Snack'
// ];

// export default function NutritionPlanningForm() {
//     const [formData, setFormData] = useState<FormData>({});
//     const [errors, setErrors] = useState<Record<string, string>>({});
//     const [activeStep, setActiveStep] = useState(0);
//     const [foodDiary, setFoodDiary] = useState<DayEntry[]>([
//         { day: 1, meals: Array(6).fill({ time: '', food: '', symptoms: '' }) },
//         { day: 2, meals: Array(6).fill({ time: '', food: '', symptoms: '' }) },
//         { day: 3, meals: Array(6).fill({ time: '', food: '', symptoms: '' }) }
//     ]);

//     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//         const { name, value } = event.target;
//         logger.log('Input changed:', name, value);
//         setFormData(prev => ({
//             ...prev,
//             [name]: value // Ensure formData is updated correctly
//         }));
//     };

//     const handleSelectChange = (event: SelectChangeEvent<string>) => {
//         const { name, value } = event.target;
//         setFormData(prev => ({ ...prev, [name]: value }));
//     };

//     const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
//         setFormData(prev => ({ ...prev, [name]: event.target.checked }));
//     };

//     const handleFoodDiaryChange = (day: number, mealIndex: number, field: string, value: string) => {
//         setFoodDiary(prev => {
//             const newDiary = [...prev];
//             newDiary[day - 1].meals[mealIndex] = {
//                 ...newDiary[day - 1].meals[mealIndex],
//                 [field]: value
//             };
//             return newDiary;
//         });
//     };

//     const validateForm = () => {
//         const newErrors: Record<string, string> = {};
//         const requiredFields = ['name', 'dob', 'email', 'phone'];

//         requiredFields.forEach(field => {
//             if (!formData[field]) {
//                 newErrors[field] = ${field.charAt(0).toUpperCase() + field.slice(1)} is required;
//             }
//         });

//         setErrors(newErrors);
//         return Object.keys(newErrors).length === 0;
//     };

//     const handleNext = () => {
//         if (validateForm()) {
//             setActiveStep(prev => prev + 1);
//             window.scrollTo(0, 0);
//         }
//     };

//     const handleBack = () => {
//         setActiveStep(prev => prev - 1);
//         window.scrollTo(0, 0);
//     };

//     const handleSubmit = (event: React.FormEvent) => {
//         event.preventDefault();
//         if (validateForm()) {
//             // Submit form data
//         }
//     };

//     // Form sections components
//     const PersonalInfo = () => (
//         <Stack spacing={3}>
//             <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                 <User size={24} />
//                 Personal Information
//             </Typography>
//             <Grid container spacing={3}>
//                 <Grid item xs={12}>
//                     <Grid container spacing={3}>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 fullWidth
//                                 label="Full Name"
//                                 name="name"
//                                 value={formData.name || ''}
//                                 onChange={handleInputChange}
//                                 error={!!errors.name}
//                                 helperText={errors.name}
//                                 required
//                                 InputProps={{
//                                     startAdornment: <User size={20} className="text-gray-400 mr-2" />
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                 <DatePicker
//                                     label="Date of Birth"
//                                     value={formData.dob ? dayjs(formData.dob) : null}
//                                     onChange={(date) => setFormData(prev => ({ ...prev, dob: date ? date.toDate() : null }))}
//                                     slotProps={{
//                                         textField: {
//                                             fullWidth: true,
//                                             required: true,
//                                             error: !!errors.dob,
//                                             helperText: errors.dob
//                                         }
//                                     }}
//                                 />
//                             </LocalizationProvider>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12}>
//                     <Grid container spacing={3}>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 fullWidth
//                                 label="Height"
//                                 name="height"
//                                 value={formData.height || ''}
//                                 onChange={handleInputChange}
//                                 InputProps={{
//                                     startAdornment: <User size={20} className="text-gray-400 mr-2" />
//                                 }}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 fullWidth
//                                 label="Current Weight"
//                                 name="currentWeight"
//                                 value={formData.currentWeight || ''}
//                                 onChange={handleInputChange}
//                                 InputProps={{
//                                     startAdornment: <Weight size={20} className="text-gray-400 mr-2" />
//                                 }}
//                             />
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12}>
//                     <TextField
//                         fullWidth
//                         multiline
//                         rows={3}
//                         label="Address"
//                         name="address"
//                         value={formData.address || ''}
//                         onChange={handleInputChange}
//                         InputProps={{
//                             startAdornment: <Home size={20} className="text-gray-400 mr-2" />
//                         }}
//                     />
//                 </Grid>
//             </Grid>
//         </Stack>
//     );

//     const HealthHistory = () => (
//         <Stack spacing={3}>
//             <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                 <History size={24} />
//                 Health History
//             </Typography>
//             <TextField
//                 fullWidth
//                 multiline
//                 rows={4}
//                 label="Childhood Health History"
//                 name="childHealthHistory"
//                 value={formData.childHealthHistory || ''}
//                 onChange={handleInputChange}
//                 placeholder="Include information about being healthy, frequently sick, ear infections, emotional trauma"
//             />
//             <TextField
//                 fullWidth
//                 multiline
//                 rows={4}
//                 label="Antibiotic History"
//                 name="antibioticHistory"
//                 value={formData.antibioticHistory || ''}
//                 onChange={handleInputChange}
//                 placeholder="Describe frequency of antibiotic use throughout your life"
//             />
//         </Stack>
//     );

//     const DietaryInfo = () => (
//         <Stack spacing={3}>
//             <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                 <Apple size={24} />
//                 Dietary Information
//             </Typography>

//             <Paper elevation={1} sx={{ p: 2 }}>
//                 <Typography variant="subtitle1" gutterBottom>Special Diets</Typography>
//                 <Grid container spacing={2}>
//                     {SPECIAL_DIETS.map(diet => (
//                         <Grid item xs={12} sm={6} md={4} key={diet}>
//                             <FormControlLabel
//                                 control={
//                                     <Checkbox
//                                         checked={formData[diet_${diet}] || false}
//                                         onChange={handleCheckboxChange(diet_${diet})}
//                                     />
//                                 }
//                                 label={diet}
//                             />
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Paper>

//             <Grid container spacing={3}>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         fullWidth
//                         label="Fish Consumption"
//                         name="fishConsumption"
//                         value={formData.fishConsumption || ''}
//                         onChange={handleInputChange}
//                         placeholder="How often do you eat fish? What type?"
//                     />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <TextField
//                         fullWidth
//                         label="Bone Broth Consumption"
//                         name="boneBrothConsumption"
//                         value={formData.boneBrothConsumption || ''}
//                         onChange={handleInputChange}
//                         placeholder="Do you consume bone broth? How often?"
//                     />
//                 </Grid>
//             </Grid>
//         </Stack>
//     );

//     const FoodDiary = () => (
//         <Stack spacing={3}>
//             <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                 <BookOpen size={24} />
//                 Food Diary
//             </Typography>

//             {foodDiary.map((day) => (
//                 <Paper key={day.day} elevation={1} sx={{ p: 2 }}>
//                     <Typography variant="h6" gutterBottom>Day {day.day}</Typography>
//                     <Stack spacing={2}>
//                         {MEAL_TIMES.map((meal, index) => (
//                             <Grid container spacing={2} key={meal}>
//                                 <Grid item xs={12} sm={3}>
//                                     <TextField
//                                         fullWidth
//                                         type="time"
//                                         label="Time"
//                                         value={day.meals[index].time}
//                                         onChange={(e) => handleFoodDiaryChange(day.day, index, 'time', e.target.value)}
//                                         InputProps={{
//                                             startAdornment: <Clock size={20} className="text-gray-400 mr-2" />
//                                         }}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={5}>
//                                     <TextField
//                                         fullWidth
//                                         label="Food/Supplements"
//                                         value={day.meals[index].food}
//                                         onChange={(e) => handleFoodDiaryChange(day.day, index, 'food', e.target.value)}
//                                         placeholder={meal}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12} sm={4}>
//                                     <TextField
//                                         fullWidth
//                                         label="Symptoms/Mood"
//                                         value={day.meals[index].symptoms}
//                                         onChange={(e) => handleFoodDiaryChange(day.day, index, 'symptoms', e.target.value)}
//                                         placeholder="How did you feel?"
//                                     />
//                                 </Grid>
//                             </Grid>
//                         ))}
//                     </Stack>
//                 </Paper>
//             ))}
//         </Stack>
//     );

//     const DigestiveHealth = () => (
//         <Stack spacing={3}>
//             <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//                 <Activity size={24} />
//                 Digestive Health
//             </Typography>

//             <Grid container spacing={3}>
//                 <Grid item xs={12} md={6}>
//                     <FormControl fullWidth>
//                         <InputLabel>Frequent Gas</InputLabel>
//                         <Select
//                             name="frequentGas"
//                             value={formData.frequentGas || ''}
//                             onChange={handleSelectChange}
//                             label="Frequent Gas"
//                         >
//                             <MenuItem value="">Select...</MenuItem>
//                             <MenuItem value="yes">Yes</MenuItem>
//                             <MenuItem value="no">No</MenuItem>
//                             <MenuItem value="sometimes">Sometimes</MenuItem>
//                         </Select>
//                     </FormControl>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <FormControl fullWidth>
//                         <InputLabel>Bloating</InputLabel>
//                         <Select
//                             name="bloating"
//                             value={formData.bloating || ''}
//                             onChange={handleSelectChange}
//                             label="Bloating"
//                         >
//                             <MenuItem value="">Select...</MenuItem>
//                             <MenuItem value="yes">Yes</MenuItem>
//                             <MenuItem value="no">No</MenuItem>
//                             <MenuItem value="sometimes">Sometimes</MenuItem>
//                         </Select>
//                     </FormControl>
//                 </Grid>
//             </Grid>
//         </Stack>
//     );

//     const getStepContent = (step: number) => {
//         switch (step) {
//             case 0:
//                 return <PersonalInfo />;
//             case 1:
//                 return <HealthHistory />;
//             case 2:
//                 return <DietaryInfo />;
//             case 3:
//                 return <FoodDiary />;
//             case 4:
//                 return <DigestiveHealth />;
//             default:
//                 return 'Unknown step';
//         }
//     };

//     return (
//         <Container maxWidth="lg" sx={{ my: 4, pb: 10 }}> {/* Added pb (padding-bottom) to avoid overlap with fixed progress bar */}
//             <Card>
//                 <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}> {/* Add padding to CardContent */}
//                     <Box sx={{ mb: 4 }}>
//                         <Grid container justifyContent="space-between" alignItems="center">
//                             <Grid item>
//                                 <Typography variant="h4" component="h1" gutterBottom>
//                                     BioIndividual Nutrition Intake Form
//                                 </Typography>
//                             </Grid>
//                             <Grid item>
//                                 <Typography color="textSecondary">
//                                     Practitioner: Alvina Foo
//                                     <br />
//                                     Email: info@alvinafoo.com
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </Box>

//                     <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
//                         {steps.map((step, index) => (
//                             <Step key={step.label}>
//                                 <StepLabel
//                                     StepIconComponent={() => (
//                                         <Box sx={{
//                                             width: 40,
//                                             height: 40,
//                                             borderRadius: '50%',
//                                             bgcolor: activeStep >= index ? 'primary.main' : 'grey.300',
//                                             display: 'flex',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                             color: 'white'
//                                         }}>
//                                             {step.icon}
//                                         </Box>
//                                     )}
//                                 >
//                                     {step.label}
//                                 </StepLabel>
//                             </Step>
//                         ))}
//                     </Stepper>

//                     <Box sx={{ mt: 4 }}>
//                         {activeStep === steps.length ? (
//                             <Stack spacing={3} alignItems="center">
//                                 <Typography variant="h5" gutterBottom>
//                                     Thank you for completing the form!
//                                 </Typography>
//                                 <Check size={64} color="green" />
//                                 <Typography color="textSecondary" align="center">
//                                     Your nutrition intake information has been submitted successfully.
//                                     We will review your information and contact you soon.
//                                 </Typography>
//                                 <Button
//                                     variant="outlined"
//                                     onClick={() => setActiveStep(0)}
//                                     startIcon={<History size={20} />}
//                                 >
//                                     Start New Form
//                                 </Button>
//                             </Stack>
//                         ) : (
//                             <form onSubmit={handleSubmit}>
//                                 <Stack spacing={4}>
//                                     {/* Alert for required fields */}
//                                     {Object.keys(errors).length > 0 && (
//                                         <Alert severity="error" sx={{ mb: 2 }}>
//                                             Please fill in all required fields before proceeding
//                                         </Alert>
//                                     )}

//                                     {/* Form Content */}
//                                     {getStepContent(activeStep)}

//                                     {/* Navigation Buttons */}
//                                     <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
//                                         <Button
//                                             color="inherit"
//                                             disabled={activeStep === 0}
//                                             onClick={handleBack}
//                                             startIcon={<ChevronLeft size={20} />}
//                                         >
//                                             Back
//                                         </Button>
//                                         <Button
//                                             variant="contained"
//                                             onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
//                                             endIcon={activeStep === steps.length - 1 ? <Check size={20} /> : <ChevronRight size={20} />}
//                                         >
//                                             {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
//                                         </Button>
//                                     </Box>
//                                 </Stack>
//                             </form>
//                         )}
//                     </Box>
//                 </CardContent>
//             </Card>

//             {/* Progress indicator */}
//             <Paper
//                 sx={{
//                     position: 'fixed',
//                     bottom: 0,
//                     left: 0,
//                     right: 0,
//                     padding: 2,
//                     backgroundColor: 'background.paper',
//                     zIndex: 1000
//                 }}
//                 elevation={3}
//             >
//                 <Container maxWidth="lg">
//                     <Stack spacing={1}>
//                         <LinearProgress
//                             variant="determinate"
//                             value={(activeStep / (steps.length - 1)) * 100}
//                             sx={{ height: 8, borderRadius: 4 }}
//                         />
//                         <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                             <Typography variant="body2" color="textSecondary">
//                                 {steps[activeStep]?.label}
//                             </Typography>
//                             <Typography variant="body2" color="textSecondary">
//                                 Step {activeStep + 1} of {steps.length}
//                             </Typography>
//                         </Box>
//                     </Stack>
//                 </Container>
//             </Paper>
//         </Container>
//     );
// }

// // Types for form data validation
// interface ValidationRules {
//     [key: string]: {
//         required?: boolean;
//         pattern?: RegExp;
//         message?: string;
//         validate?: (value: any) => boolean | string;
//     };
// }

// const validationRules: ValidationRules = {
//     name: {
//         required: true,
//         message: 'Full name is required'
//     },
//     email: {
//         required: true,
//         pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//         message: 'Invalid email address'
//     },
//     phone: {
//         required: true,
//         pattern: /^\+?[\d\s-]{10,}$/,
//         message: 'Invalid phone number'
//     },
//     dob: {
//         required: true,
//         validate: (value) => {
//             if (!value) return 'Date of birth is required';
//             const date = dayjs(value);
//             if (date.isAfter(dayjs())) return 'Date of birth cannot be in the future';
//             return true;
//         }
//     }
// };

// // Helper function for form validation
// const validateField = (name: string, value: any): string => {
//     const rules = validationRules[name];
//     if (!rules) return '';

//     if (rules.required && !value) {
//         return rules.message || ${name} is required;
//     }

//     if (rules.pattern && !rules.pattern.test(value)) {
//         return rules.message || Invalid ${name};
//     }

//     if (rules.validate) {
//         const result = rules.validate(value);
//         if (typeof result === 'string') {
//             return result;
//         }
//         if (!result) {
//             return rules.message || Invalid ${name};
//         }
//     }

//     return '';
// };

// export { NutritionPlanningForm };